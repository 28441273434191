import React, { useEffect, useMemo, useState } from "react"
import { Table, Row, Col, Card, CardBody, Form, Label, Modal } from "reactstrap"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import NewPagination from "components/Common/Newpagination"
import { LeaveManagementColumns } from "utils/columns/Human Resource/payrollColumns"
import { DashboardSwipesColumns } from "utils/columns/Dashboard/dashboardColumns"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Swal from "sweetalert2"
import AddIssues from "../../../../assets/images/Human Resource/addIssues.jpg"
import leaveImg2 from "../../../../assets/images/LeaveDetails.jpg"
import moment from "moment"
function TableContainer({
  leavesColumns,
  issueColumns,
  data,
  allData,
  setData,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const [columns, setColumns] = useState([])
  const [status, setStatus] = useState("1")
  useEffect(() => {
    setColumns(leavesColumns)
  }, [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  return (
    <div>
      <Row className="mb-2">
        <Col>
          <div className="d-flex align-items-center gap-2">
            <a
              className={`d-flex gap-3 align-items-center general-box ${
                status == 1 ? "bg-purple" : ""
              }`}
              onClick={() => {
                setStatus("1")
                setColumns(leavesColumns)
                setData(allData?.leaves)
              }}
            >
              <h5 className="text-purple mb-0">Applied Leaves : </h5>
              <h5 className="text-primary mb-0">{allData?.leaves?.length}</h5>
            </a>
            <a
              className={`d-flex gap-3 align-items-center general-box ${
                status == 2 ? "bg-purple" : ""
              }`}
              onClick={() => {
                setStatus("2")
                setColumns(issueColumns)
                setData(allData?.issues)
              }}
            >
              <h5 className="text-purple mb-0">Swipe & Issues : </h5>
              <h5 className="text-primary mb-0">{allData?.issues?.length}</h5>
            </a>
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <div className="">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 10 ? "fixed-leave-mng-header" : ""
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        {index === 0 && <span></span>}
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                        </span>
                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-leave-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const { did_col: did, pwd_col: pwd } = row.original
              const status = row.original.status_name

              let backgroundStyle = {}

              const empid = row.original.id

              return (
                <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} ${
                        empid == 4 ? "unApproved" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
        {data?.length == 0 ? (
          <div className="text-center text-purple fw-bold mt-2">
            All requests have been processed and fulfilled.
          </div>
        ) : null}
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const LeaveManagement = ({ getLength }) => {
  const [leaveDetails, setLeaveDetails] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [issuePopup, setIssuesPopup] = useState(false)
  const [issuePopupUnApprove, setIssuesPopupUnApprove] = useState(false)
  const [leave_type, setLeaveType] = useState("")
  const [id, setID] = useState("")
  const [issueReason, setIssueReason] = useState("")
  const [amount, setAmount] = useState("")
  const [staffDetail, setStaffDetail] = useState({})
  const [showHistory, setShowHistory] = useState(false)
  const [reasons, setReasons] = useState("")
  const [leaveId, setLeaveId] = useState("")
  const [staffId, setStaffId] = useState("")
  const [leaveSlots, setLeaveSlots] = useState([])
  const [allLeave, setAllLeave] = useState([])
  const [reason, setReason] = useState("")
  const [details, setDetails] = useState({})
  const [slotId, setSlotId] = useState("")
  const [addReasons, setAddReasons] = useState(false)

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.85)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") {
      if (setLeaveDetails) {
        setLeaveDetails(false)
      }
    }
  })

  const toggleLoading = loading => {
    setLoading(loading)
  }

  const onDisapporve = (leave_type, id) => {
    setID(id)
    setLeaveType(leave_type)
    setIssuesPopupUnApprove(true)
  }
  const onApprove = (leave_type, id) => {
    console.log(id)
    setID(id)
    setLeaveType(leave_type)
    if (leave_type == 2) {
      setIssuesPopup(true)
    } else {
      onHandleApprove()
    }
  }

  const onHandleDisapprove = async e => {
    e.preventDefault()
    const auth = secureLocalStorage.getItem("auth_id")
    const entries = {
      user_id: localStorage.getItem("auth_id"),
      issue_type: 0,
      id: id,
      approve_reason: issueReason,
      status: 2,
    }
    const response = await FetchPostData(
      toggleLoading,
      "approveSwipeData",
      auth,
      entries
    )
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: `${leave_type == 1 ? "Swipes" : "Issue"} UnApporved Successfully`,
      }).then(() => {
        setIssuesPopupUnApprove(false)
        setIssueReason("")
        fetchLeaves(2)
      })
    }
  }

  const onHandleApprove = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Approve this ${leave_type == 2 ? "Issue" : "Swipe"}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      cancelButtonText: "No, cancel!",
    }).then(async result => {
      if (result.isConfirmed) {
        const auth = secureLocalStorage.getItem("auth_id")
        console.log(id)
        const entries = {
          user_id: localStorage.getItem("auth_id"),
          issue_type: 0,
          id: id,
          approve_reason: issueReason,
          status: 1,
          final_amount: leave_type == 2 ? amount : "",
        }
        const response = await FetchPostData(
          toggleLoading,
          "approveSwipeData",
          auth,
          entries
        )
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            text: `${
              leave_type == 1 ? "Swipes" : "Issue"
            } Approved Successfully`,
          }).then(() => {
            setIssuesPopup(false)
            setIssueReason("")
            fetchLeaves(2)
          })
        }
      }
    })
  }

  const onLeaveDetail = async (id, staff_id, obj) => {
    const res = await fetchData(toggleLoading, `LeaveSlots/${id}`, "")
    if (res?.success) {
      setStaffDetail(obj)
      setLeaveDetails(true)
      setLeaveId(id)
      setDetails(res?.data)
      setReason(res?.data?.leaveslots[0]?.comment)
      setLeaveSlots(res?.data?.leaveslots)
      setAllLeave(res?.data?.leaveslots)
      setStaffId(staff_id)
    }
  }

  const leavesColumns = useMemo(() => LeaveManagementColumns(onLeaveDetail), [])
  const issueColumns = useMemo(
    () => DashboardSwipesColumns(onDisapporve, onApprove),
    []
  )
  const fetchLeaves = async type => {
    const res = await fetchData(toggleLoading, "leavesSwipeData", token)
    if (res?.success) {
      if (type == 2) {
        setData(res?.data?.issues)
      } else {
        setData(res?.data?.leaves)
      }
      setAllData(res?.data)
      getLength(res?.data?.issues + res?.data?.leaves)
    }
  }
  useEffect(() => {
    fetchLeaves()
  }, [])

  const onGetHisory = async () => {
    const res = await fetchData(toggleLoading, `LeaveHistory/${staffId}`, "")
    if (res?.success) {
      setLeaveSlots(res?.data?.leaveslots)
      setShowHistory(true)
    }
  }

  const onChangeStatus = async (id, status, disapprove) => {
    const formEnties = {
      data_id: id,
      status: status,
      type: 1,
      leave_reason: disapprove ? reasons : "",
    }
    const res = await FetchPostData(
      toggleLoading,
      `approveList`,
      token,
      formEnties
    )
    if (res?.data?.success) {
      setAddReasons(false)
      setReasons("")
      Swal.fire({
        icon: "success",
        text: `Leave ${
          status == "Cancel" ? "Cancelled" : "Approved"
        }  Successfully`,
      }).then(async data => {
        const response = await fetchData(
          toggleLoading,
          `LeaveSlots/${leaveId}`,
          ""
        )
        if (response?.success) {
          setLeaveSlots(response?.data?.leaveslots)
          setAllLeave(response?.data?.leaveslots)
          fetchLeaves()
        }
      })
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <TableContainer
                leavesColumns={leavesColumns}
                issueColumns={issueColumns}
                data={data}
                allData={allData}
                boxWidth={boxWidth}
                setBoxWidth={setBoxWidth}
                setData={setData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* issue Approve Popup  */}
      <Modal isOpen={issuePopup} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Approve Issue</h5>
          <button
            type="button"
            onClick={() => {
              setIssuesPopup(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            className="row d-flex justify-content-center align-items-center"
            onSubmit={e => {
              e.preventDefault()
              onHandleApprove()
            }}
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12">
                <div className="col-4 mb-3">
                  <Label>
                    Final Amount / Days <span className="text-danger"> *</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter  Amount / Days"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    required
                  ></input>
                </div>

                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Reason <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    placeholder="Add Reason Here.."
                    className="form-control"
                    style={{ height: "150px" }}
                    value={issueReason}
                    onChange={e => setIssueReason(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  className=" btn ms-md-2 btn-red btn-label"
                  onClick={() => setIssuesPopup(false)}
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>

            {/* </div> */}
          </Form>
        </div>
      </Modal>

      {/* issue Un-Approve Popup  */}
      <Modal isOpen={issuePopupUnApprove} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Disapproved Issue</h5>
          <button
            type="button"
            onClick={() => {
              setIssuesPopupUnApprove(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body pe-4">
          <Form
            className="row d-flex justify-content-center align-items-center"
            onSubmit={onHandleDisapprove}
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Reason <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  placeholder="Add Reason Here.."
                  className="form-control"
                  rows={5}
                  value={issueReason}
                  onChange={e => setIssueReason(e.target.value)}
                  required
                />
              </div>
              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  className=" btn ms-md-2 btn-red btn-label"
                  onClick={() => setIssuesPopupUnApprove(false)}
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={addReasons} size="md" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Reason</h5>
          <button
            type="button"
            onClick={() => {
              setAddReasons(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body pe-4 ">
          <form
            className=""
            onSubmit={e => {
              e.preventDefault()
              onChangeStatus(slotId, "Unapproved", true)
            }}
          >
            <div className="col-md-12 ">
              <label>Reason</label>
              <textarea
                className="form-control"
                placeholder="Reason for Disapproval"
                value={reasons}
                onChange={e => setReasons(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3 mt-3">
              <button className=" btn btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i> save
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal isOpen={leaveDetails} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <div className="d-flex gap-3 col-md-9 justify-content-between">
            <h5 className="modal-title mt-0">Leave Details</h5>
            <div className="d-flex gap-3">
              <h5 className="text-purple fw-bold mb-0">
                <i className="bx bxs-user me-2"></i> {staffDetail?.name}
              </h5>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              setLeaveDetails(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4 my-3">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className="col-md-5 d-flex flex-column justify-content-end align-items-center ps-3 gap-4">
              <div className="text-center">
                <img src={leaveImg2} alt="Addepe" style={{ width: "70%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column justify-content-start  gap-3">
              <div className="col-md-12 d-flex flex-row mb-3 mt-2 gap-3">
                <div className="col-md-12 d-flex justify-content-start align-items-center gap-3">
                  <h5 className="mb-0">Leave Balance :</h5>
                  <div className="d-flex justify-content-between align-items-center gap-3 col-md-9">
                    <div className="d-flex gap-3 justify-content-start align-items-center">
                      <span className="mb-0 px-3 py-1  bg-primary rounded text-light">
                        <b>
                          EL : <span>{details?.earned_leave}</span>
                        </b>
                      </span>
                      <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                        <b>
                          CL : <span>{details?.casual_leave}</span>
                        </b>
                      </span>
                      <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                        <b>
                          SL : <span>{details?.sick_leave}</span>
                        </b>
                      </span>
                    </div>
                    <div>
                      <span className="mb-0 px-3 py-1 rounded  bg-primary text-light me-2">
                        <b>
                          Total : <span>{leaveSlots?.length}</span>
                        </b>
                      </span>
                      <span
                        className="mb-0 px-3 py-1 rounded  bg-success text-light cursor-pointer"
                        onClick={() => {
                          if (showHistory) {
                            setLeaveSlots(allLeave)
                            setShowHistory(false)
                          } else {
                            onGetHisory()
                          }
                        }}
                      >
                        <b>
                          <i
                            className={
                              showHistory ? "fab fa-rev" : "fa fa-history"
                            }
                          ></i>
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {showHistory == false && (
                <div className="col-12 mb-3">
                  <label className="">Reasons</label>
                  <textarea className="form-control" value={reason} disabled />
                </div>
              )}
              <div className="d-flex flex-column gap-1">
                <h5 className="mb-0 ps-1 mb-2">
                  {showHistory ? "Leave History" : "Leave Slots"} :{" "}
                </h5>
                <div
                  className={`table-responsive col-md-12 ${
                    showHistory ? "leave-history-slots" : "leave-history-table"
                  }`}
                >
                  <Table className="table mb-0">
                    <thead
                      className="bg-white"
                      style={{ position: "sticky", top: 0 }}
                    >
                      <tr className=" ">
                        <th className="header text-center col-1">Leave Date</th>
                        <th className="header col-1">Type</th>
                        <th className="header col-1">Status</th>
                        <th
                          className="text-center header"
                          style={{ width: "10%" }}
                        >
                          {showHistory ? "Reason" : "Action"}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {leaveSlots?.map((obj, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row" className="text-center">
                              {moment(obj?.leave_dates).format("DD-MM-YYYY")}
                            </th>
                            <td>
                              {obj?.leave_name}{" "}
                              {obj?.medical_certificate != null ? (
                                <a
                                  className="ms-1"
                                  href={obj?.medical_certificate}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fa fa-download"></i>
                                </a>
                              ) : null}
                            </td>
                            <td>{obj?.leave_status}</td>
                            <td
                              className="word-wrap text-center"
                              style={{ width: "10%" }}
                            >
                              {showHistory ? (
                                obj?.comment
                              ) : (
                                <>
                                  {obj?.status != 1 ? (
                                    <>
                                      <a
                                        className="btn btn-sm btn-outline-primary me-1 lh-1 p-1"
                                        title="Approve"
                                        onClick={() =>
                                          onChangeStatus(
                                            obj?.slots_id,
                                            "Approved"
                                          )
                                        }
                                      >
                                        <i className="fas fa-thumbs-up "></i>
                                      </a>
                                      <a
                                        className="btn btn-sm btn-outline-danger me-1 lh-1 p-1"
                                        title="Disapprove"
                                        onClick={() => {
                                          setSlotId(obj?.slots_id)
                                          setAddReasons(true)
                                        }}
                                      >
                                        <i className="fas fa-thumbs-down "></i>
                                      </a>
                                      <a
                                        className="btn btn-sm btn-outline-secondary me-1 lh-1 p-1"
                                        title="Approve"
                                        onClick={() =>
                                          onChangeStatus(
                                            obj?.slots_id,
                                            "Cancel"
                                          )
                                        }
                                      >
                                        <i className="fas fa-times"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <>{obj?.leave_reason || "-"}</>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default LeaveManagement
