import React, { useEffect, useState } from "react"
import zithasLogo from "../../../../../assets/images/main-zithas.png"
import { Card, Col, Row } from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Dropzone from "react-dropzone"
import secureLocalStorage from "react-secure-storage"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import axios from "axios"
import configure from "configure"
import moment from "moment"

const TicketReply = ({ verticalActiveTab, ticketDetails, replyTiket }) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const token = secureLocalStorage.getItem("auth_token")
  const [description, setDescription] = useState("")
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const [selectedFiles1, setselectedFiles1] = useState([])
  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetchData(
        toggleLoading,
        `ticketReplyData/${ticketDetails?.id}`,
        token
      )
      if (res?.success) {
        setData(res?.data)
      }
    }
    if (verticalActiveTab == 1 && replyTiket) {
      fetchProjects()
    }
  }, [verticalActiveTab, replyTiket])

  const onAddReply = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("support_id", ticketDetails?.id)
    formData.append("description", description)
    if (selectedFiles1?.length != 0) {
      for (let i = 0; i < selectedFiles1.length; i++) {
        formData.append("files[]", selectedFiles1[i])
      }
    }
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/addTicketReply`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Reply Added Successfully",
        }).then(data => {
          setData(res?.data?.data)
          setDescription("")
          setselectedFiles1([])
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  return (
    <div>
      {isLoading && <Loader />}
      <div className="d-flex ">
        <div className="col-md-6">
          <div className="d-flex justify-space-between align-items-center ms-4">
            <h5 className="text-dark mb-2">Reply History</h5>
          </div>
          <div className="px-3 bg-white ">
            <div className="mt-4 ticket-history-box">
              <ul className="verti-timeline list-unstyled notes-box">
                {data?.map((obj, i) => {
                  const attachment = JSON.parse(obj?.files)
                  return (
                    <li className="event-list position-relative" key={i}>
                      <div className="event-timeline-dot connected-dot">
                        <img
                          src={`${
                            obj?.avatar != null
                              ? `${configure.imgUrl}${obj?.avatar}`
                              : zithasLogo
                          }`}
                          className="msgUserImg me-3"
                          alt="User Avatar"
                          title={obj?.alias_name}
                        />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <h6 className="d-inline me-2">
                                Support Ticket Replied by {obj?.alias_name}
                              </h6>
                              at{" "}
                              {moment(obj?.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )}
                            </div>
                          </div>
                          <i className="bi bi-triangle-fill note-icon"></i>
                          <div
                            className="Features col-12 my-3"
                            dangerouslySetInnerHTML={{ __html: obj?.reply }}
                          />
                          {attachment?.length > 0 ? (
                            <p className="mb-1">
                              Attachment:
                              {attachment?.map(obj => {
                                return (
                                  <a
                                    key={obj}
                                    className="text-light badge bg-primary ms-2"
                                    title="Click To Open"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={obj}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    Click here
                                  </a>
                                )
                              })}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              {data?.length == 0 ? (
                <p className="text-purple fw-bold">No Reply Added Yet</p>
              ) : null}
            </div>
          </div>
        </div>
        <form className="col-md-6 pe-3" onSubmit={onAddReply}>
          <div className="d-flex justify-space-between align-items-center mb-4">
            <h5 className="text-dark mb-0">Add Reply</h5>
            {/* <h5 className="text-purple mb-2 me-3">Reopens : 15</h5> */}
            <button className=" btn btn-save btn-label">
              <i className="fas fa-save me-2 label-icon"></i>
              Submit
            </button>
          </div>
          <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
            <div className="col-12">
              <label htmlFor="">Description</label>
              <div className="no-drag">
                <CKEditor
                  editor={ClassicEditor}
                  data={description || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 d-flex">
              <div className="col-md-8 d-flex justify-content-start align-items-center gap-3">
                <div className="col-md-8 col-sm-6 mt-3">
                  <label htmlFor="">Attachment</label>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles1(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dz-message needsclick p-0"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex px-2 custom-file-upload align-items-center">
                          <div className="">
                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5 className="mb-0 ms-2">Upload Files</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div
                  className={`mt-3 dropzone-previews ${
                    selectedFiles1.length > 0 ? "d-block" : "d-none"
                  }`}
                  id="file-previews"
                >
                  <label className="mb-1">Uploaded Files</label>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-0">
                      <Row className="align-items-center justify-content-evenly">
                        {selectedFiles1?.map((f, i) => {
                          return (
                            <Col key={i} className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TicketReply
