const configure = {
  apiUrl: "https://api.zithas.com/api",
  imgUrl: "https://mars.zithas.com/application/public",   // Live
  type: 2, // Earth

  // imgUrl: "https://mars.ztpl.net/application/public",
  // apiUrl: "https://mars.ztpl.net/api",                   // Ztpl

  // apiUrl: "https://edemoapi.zithas.com/api",
  // imgUrl: "https://edemoapi.zithas.com/application/public",              // demo Ztpl

  appUrl: "/",

  // type: 1, // 192
  //  apiUrl: "http://192.168.0.2/earthbms/admin/api",
}

export default configure
