import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Table, Row, Col, Card, CardBody } from "reactstrap";
import NewPagination from "components/Common/Newpagination";
import Loader from "pages/Separate/Loader";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import { testCasesColumns } from "utils/columns/Support/projectTicketColumn";
import ViewCase from "./ViewCase";
import CreateTestCase from "../Cases/CreateTestCase";
import Swal from "sweetalert2";
import ReplyIndex from "../../Tickets/Reply/ReplyIndex";

function TableContainer({
  columns,
  data,
  boxViewWidth,
  toggleLoading,
  allData,
  verticalActiveTab,
  roundDetail,
  setData,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;
  useEffect(() => setPageSize(50), []);
  const [addCase, setAddCase] = useState(false);
  const params = useParams();
  const [taskOption, setTaskOption] = useState([]);
  const token = secureLocalStorage.getItem("auth_token");
  const [status, setStatus] = useState("");

  const getTasks = async () => {
    const userId = localStorage.getItem("auth_id");
    const res = await fetchData(
      toggleLoading,
      `taskData/${userId}/${params?.id}`,
      token
    );
    if (res) {
      const data = res?.map((obj) => ({
        label: obj?.title,
        value: obj?.id,
      }));
      setTaskOption(data);
      setAddCase(true);
    }
  };
  const closeModal = (modal) => {
    setAddCase(modal);
  };

  const onCompeteRound = () => {
    const allStatus = allData.filter((obj) => obj.status == "0")?.length;
    if (allStatus == 0) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you want to complete this round, considering that you will not be able to take any action on the test cases afterward?",
      }).then(async (data) => {
        if (data.isConfirmed) {
          const formEntries = {
            round: verticalActiveTab,
            project_id: params?.id,
            task_id: roundDetail?.task_id,
          };
          const res = await FetchPostData(
            toggleLoading,
            "completeRound",
            token,
            formEntries
          );
          if (res?.data?.success) {
            Swal.fire({
              icon: "success",
              title: "Round Completed Successfully",
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please Check & Update all the test cases",
      });
    }
  };

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-4 align-items-center">
        <Col md={8} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${
                  status == "pass" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  if (status == "pass") {
                    setStatus("");
                    setData(allData);
                  } else {
                    setStatus("pass");
                    setData(allData.filter((obj) => obj.status == "1"));
                  }
                }}
              >
                <h4>{allData.filter((obj) => obj.status == "1")?.length}</h4>
                <h4>Pass</h4>
              </a>
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${
                  status == "fail" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  if (status == "fail") {
                    setStatus("");
                    setData(allData);
                  } else {
                    setStatus("fail");
                    setData(allData.filter((obj) => obj.status == "2"));
                  }
                }}
              >
                <h4> {allData.filter((obj) => obj.status == "2")?.length}</h4>
                <h4>Fail</h4>
              </a>
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${
                  status == "pending" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  if (status == "pending") {
                    setStatus("");
                    setData(allData);
                  } else {
                    setStatus("pending");
                    setData(allData.filter((obj) => obj.status == "0"));
                  }
                }}
              >
                <h4> {allData.filter((obj) => obj.status == "0")?.length}</h4>
                <h4>Pending</h4>
              </a>
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${
                  status == "ticket" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  if (status == "ticket") {
                    setStatus("");
                    setData(allData);
                  } else {
                    setStatus("ticket");
                    setData(allData.filter((obj) => obj.ticket_id != null));
                  }
                }}
              >
                <h4>
                  {" "}
                  {allData.filter((obj) => obj.ticket_id != null)?.length}
                </h4>
                <h4>Tickets</h4>
              </a>
            </div>
          </div>
        </Col>
        {roundDetail?.status == 0 ? (
          <Col md={4} className="text-end d-flex gap-2 justify-content-end">
            <a
              className=" btn text-white me-0 btn-save btn-label "
              onClick={() => getTasks()}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i> Test
              Case
            </a>
            <button
              className="s btn text-white me-0 btn-success ms-2"
              onClick={() => onCompeteRound()}
            >
              Complete Round
            </button>
          </Col>
        ) : null}
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center gap-2">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label mb-0">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead className={`fix-project-header`}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowColor = row.original.status == 1;
              return (
                <tr
                  className={`row-hover ${rowColor ? "bg-green" : ""}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      <div className={`note-sticky-form ${addCase ? "d-block" : "d-none"}`}>
        <Draggable handle=".modal-header" cancel=".modal-body">
          <ResizableBox
            width={boxViewWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Create Test Case</h5>
              <button
                type="button"
                onClick={() => {
                  setAddCase(false);
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <CreateTestCase
              type={"add"}
              task={true}
              taskOption={taskOption}
              closeModal={closeModal}
            />
          </ResizableBox>
        </Draggable>
      </div>
    </div>
  );
}

const AllTestCase = ({ verticalActiveTab, roundDetail }) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [modal_Access, setAccessDenied] = useState(false);
  const [closeTicket, setCloseTicket] = useState(false);
  const [addTicket, setAddTicket] = useState(false);
  const token = secureLocalStorage.getItem("auth_token");
  const [viewCase, setViewCase] = useState(false);
  const [boxWidth, setBoxWidth] = useState(0);
  const [replyWidth, setReplyWidth] = useState(0);
  const [boxViewWidth, setBoxViewWidth] = useState(0);
  const [caseDetails, setCaseDetails] = useState({});
  const [type, setType] = useState(false);
  const params = useParams();
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth;
      setBoxWidth(viewportWidth * 0.72);
      setBoxViewWidth(viewportWidth * 0.9);
      setReplyWidth(viewportWidth * 0.98);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  const onReply = (obj) => {
    setCaseDetails(obj);
    setAddTicket(true);
  };
  const onViewCase = (obj) => {
    setType(true);
    setCaseDetails(obj);
    setViewCase(true);
  };

  const onChangeStatus = async (e, obj) => {
    if (e == "2") {
      setViewCase(true);
    } else if (e == "1") {
      setCloseTicket(true);
    }
    setCaseDetails(obj);
  };
  const columns = useMemo(
    () => testCasesColumns(onReply, onViewCase, onChangeStatus, roundDetail),
    [roundDetail]
  );

  useEffect(() => {
    const fetchTestCases = async () => {
      const res = await fetchData(
        toggleLoading,
        `devCompleteTestCases/${params?.id}/${roundDetail?.round}`,
        token
      );
      if (res.success) {
        setData(res?.data);
        setAllData(res?.data);
      }
    };
    if (verticalActiveTab != 0) {
      fetchTestCases();
    }
  }, [verticalActiveTab, roundDetail]);

  const closeModal = (modal) => {
    setViewCase(false);
  };
  const updateData = (datas) => {
    setData(datas);
    setAllData(datas);
  };

  const onPassCase = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You are seeking a pass, and if any tickets exist, they will be closed?",
    }).then(async (result) => {
      if (result?.isConfirmed) {
        const formEntries = {
          status: 1,
          ticket_id: caseDetails?.ticket_id || 0,
          testcase_id: caseDetails?.id,
          round: verticalActiveTab,
          project_id: params?.id,
          detail_id: caseDetails?.detail_id,
        };
        const res = await FetchPostData(
          toggleLoading,
          "changeTestCaseStatus",
          token,
          formEntries
        );
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Test case Passed Successfully",
          }).then((data) => {
            setData(res?.data?.data);
            setAllData(res?.data?.data);
            setCloseTicket(false);
          });
        }
      }
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      allData={allData}
                      setAccessDenied={setAccessDenied}
                      boxViewWidth={boxViewWidth}
                      toggleLoading={toggleLoading}
                      verticalActiveTab={verticalActiveTab}
                      roundDetail={roundDetail}
                      setData={setData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`note-sticky-form ${addTicket ? "d-block" : "d-none"}`}>
        <Draggable
        // handle={`${noDrag ? ".modal-header" : ""}`}
        // cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={replyWidth}
            style={{
              position: "fixed",
              top: "5%",
              right: "0.5%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="modal-header pe-4 mb-3">
              <h5 className="modal-title mt-0">Reply Ticket </h5>
              <span
                aria-hidden="true"
                className="btn-modal-span cursor-pointer"
                onClick={() => setAddTicket(false)}
              >
                &times;
              </span>
            </div>
            <div className="modal-body pe-4 ">
              <ReplyIndex
                ticketDetails={{
                  id: caseDetails?.ticket_id,
                  testcase_id: caseDetails?.id,
                }}
                replyTiket={addTicket}
              />
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <div className={`note-sticky-form ${viewCase ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={boxViewWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">View Test Case</h5>
              <button
                type="button"
                onClick={() => {
                  setViewCase(false);
                  setType(false);
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <ViewCase
              verticalActiveTab={verticalActiveTab}
              caseDetails={caseDetails}
              updateData={updateData}
              closeModal={closeModal}
              addType={type}
            />
          </ResizableBox>
        </Draggable>
      </div>

      <div className={`note-sticky-form ${closeTicket ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Close Ticket</h5>
              <button
                type="button"
                onClick={() => {
                  setCloseTicket(false);
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body pe-4">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-12 ">
                  <div
                    className="col-md-12 d-flex flex-column pe-3 "
                    style={{
                      scrollbarWidth: "none",
                      maxHeight: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div className="mt-3 mb-2">
                      <div className="d-flex justify-content-between">
                        <div className="col-10">
                          <label htmlFor="">Test Case Description</label>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            {caseDetails?.description}
                          </p>
                        </div>
                        <div className="my-2 text-end">
                          <button
                            className=" btn btn-success btn-label"
                            onClick={() => onPassCase()}
                          >
                            <i className="fas fa-check me-2 label-icon"></i>
                            Pass Test Case
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="">
                        <label htmlFor="">Preconditions</label>
                        {caseDetails?.preconditions
                          ?.split("@#")
                          ?.map((obj, i) => {
                            return (
                              <p className="mb-1" key={i}>
                                <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                {obj}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <div className="">
                          <label htmlFor="">Test Steps</label>
                          {caseDetails?.test_steps
                            ?.split("@#")
                            ?.map((obj, i) => {
                              return (
                                <p className="mb-1" key={i}>
                                  <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                  {obj}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="">
                          <label htmlFor="">Test Data</label>
                          {caseDetails?.test_data_field
                            ?.split("@#")
                            ?.map((obj, i) => {
                              const values =
                                caseDetails?.test_data_value?.split("@#");
                              return (
                                <p className="mb-1" key={i}>
                                  <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                  {obj} - {values[i]},
                                </p>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default AllTestCase;
