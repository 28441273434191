import React, { useEffect, useState } from 'react'
import TicketHelp from './TicketHelp'
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import Bug from "../../../../assets/images/flags/bug-icon.png"

const Help = () => {
    const [ticket, setTicket] = useState(false)
    const [boxWidth, setBoxWidth] = useState(0)
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.58)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])
    const closeModal = (modal) => {
        setTicket(modal)
    }
    return (
        <div>
            <a className='help-btn' onClick={() => setTicket(!ticket)}>
                <img src={Bug} alt='Bug' width={35} height={35} />
            </a>
            <div className={`note-sticky-form ${ticket ? "d-block" : "d-none"}`}>
                <Draggable>
                    <ResizableBox
                        width={boxWidth}
                        style={{
                            position: "fixed",
                            top: "16%",
                            right: "16%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                            maxHeight: "fit-content",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <div className="modal-header pe-4">
                            <h5 className="modal-title mt-0">Report an Issue or Provide Feedback</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setTicket(false)
                                }}
                                className="btn-modal-close"
                            >
                                <span aria-hidden="true" className="btn-modal-span">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <TicketHelp closeModal={closeModal} />
                    </ResizableBox>
                </Draggable>
            </div>
        </div>
    )
}

export default Help
