import React, { Fragment, useEffect, useState } from "react"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import Loader from "pages/Separate/Loader"
// Hover card
import axios from "axios"
import configure from "configure"
import Pagi2 from "components/Common/Pagi2"
import Swal from "sweetalert2"
import NewPagination from "components/Common/Newpagination"

const StaffDocApprovel = () => {
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [convertBtn, setConvertBtn] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/allLetters`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const filterData = response?.data.data?.filter(ob => ob.approve == 0)
        setDatas(filterData)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [name_modal, setNameModal] = useState(false)
  //code for fetching lead data

  //meta title
  document.title = "Dashboard | Zithas Technologies"

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Staff Name",
        accessor: "name",
        isInfoIcon: true,
        className: "text-center cursor-pointer px-1 w-25",
      },
      {
        Header: "Document Name",
        accessor: "requirement_type",
        isInfoIcon: true,
        className: "text-center cursor-pointer px-1 w-25",
      },
      {
        Header: "Created at",
        accessor: "created_at",
        isInfoIcon: true,
        className: "text-center cursor-pointer px-1",
      },

      {
        Header: "Action",
        accessor: "phone",
        className: "text-center cursor-pointer px-0",
        isInfoIcon: true,
        Cell: cellProps => {
          const handleApproveDoc = async letterId => {
            
            Swal.fire({
              icon: "warning",
              title: "Are You Sure ?",
              text: "You want to approve this document.",
              showCancelButton: true,
            }).then(async willOkay => {
              if (willOkay.isConfirmed) {
                setLoading(true)
                try {
                  const response = await axios({
                    method: "get",
                    url: `${configure.apiUrl}/approveLetter/${letterId}`,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Document approved successfully.",
                    }).then(() => {
                      const filterData = response?.data.data?.filter(
                        ob => ob.approve == 0
                      )
                      setDatas(filterData)
                    })
                  }
                } catch (error) {
                  console.log(error)
                  setLoading(false)
                }
              }
            })
          }
          return (
            <span>
              <a
                href={`${configure.appUrl}viewapprovedoc/${cellProps.row.original.id}`}
                target="_blank"
                rel="noreferrer"
                title="View Document"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fa fa-eye pr-2"></i>
              </a>
              <button
                // href={item.signature}
                title="Approve Document"
                className="btn btn-sm btn-outline-secondary me-1"
                onClick={() => handleApproveDoc(cellProps.row.original.id)}
              >
                <i className="fa fa-check pr-2"></i>
              </button>
            </span>
          )
        },
      },
    ],
    [convertBtn]
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const [title, setTitle] = useState("")

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mt-3">
        <Row>
          <Col lg={12} className="">
            <Card>
              <CardBody className="">
                <div>
                  <p className="text-purple fs-5 ps-3 pt-2 fw-bold">
                    Document Approvel
                  </p>
                </div>

                <div className="responsive-table">
                  <div>
                    <div className="position-relative shadow-sm table-responsive">
                      <Table
                        className="table table-bordered mt-md-4"
                        {...getTableProps()}
                      >
                        <thead
                          // className={`${
                          //   fixedContent ? "fix-header" : ""
                          // } fix-lead-header`}
                          className="fix-lead-header"
                        >
                          {headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, index) => (
                                <th
                                  key={index}
                                  className={`t-col-${index + 1} ${
                                    column.className
                                  }`}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                  {column.isInfoIcon ? (
                                    <a
                                      onClick={() => {
                                        setNameModal(true)
                                        setTitle(column.Header)
                                      }}
                                    >
                                      <i
                                        title="info"
                                        className={`bx bx-info-circle ms-1 info-icon assign-${index}`}
                                      ></i>
                                    </a>
                                  ) : null}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody className="" {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row)
                            let backColor
                            if (row.original.lead_type == "Hot") {
                              backColor = "bd-hot"
                            }
                            return (
                              <tr
                                className={`row-hover ${backColor}`}
                                {...row.getRowProps()}
                                key={i}
                              >
                                {row.cells.map((cell, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className={`t-col-${index + 1} ${
                                        cell.column.className
                                      }`}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                          {page.length == 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                No Document Available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {/* {data.length == 0 ? (
                        <p className="text-center">No Document Available</p>
                      ) : null} */}
                    </div>
                    <Row className="justify-content-md-end justify-content-center align-items-center">
                      <NewPagination
                        gotoPage={gotoPage}
                        canPreviousPag={canPreviousPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        page={page}
                        data={data}
                      />
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default StaffDocApprovel
