import React, { useEffect, useState } from "react"
import { Card, Col, Form, Input, Label, Row } from "reactstrap"
import { issueType, priorityOption } from "utils/common-options"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { formatBytes } from "utils/common-helpers"
import Dropzone from "react-dropzone"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import axios from "axios"
import configure from "configure"
import { useParams } from "react-router-dom"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import EmptyImg from "../../../../../assets/images/Inventory/addSubInvImg.jpg"

const ViewCase = ({
  verticalActiveTab,
  caseDetails,
  closeModal,
  updateData,
  addType,
}) => {
  const [selectedFiles1, setselectedFiles1] = useState([])
  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }
  const [caseResult, setCaseResult] = useState("")
  const [priority, setPriority] = useState([])
  const [platform, setPlatform] = useState("")
  const [comments, setComments] = useState("")
  const [title, setTitle] = useState("")
  const [type, setType] = useState([])
  const [date, setDate] = useState("")
  const [unitTest, setUnitTest] = useState(false)
  const [description, setDescription] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [details, setDetails] = useState({})
  const params = useParams()
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const onFailCase = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("status", 2)
    formData.append("priority", priority?.value)
    formData.append("actual_result", caseResult)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("platform", platform)
    formData.append("date_execution", date)
    formData.append("comment", comments)
    formData.append("project_id", params?.id)
    formData.append("task_id", caseDetails?.task_id)
    formData.append("subject", title)
    formData.append("description", description)
    formData.append("issue_type", type?.value)
    formData.append("unit_test", unitTest ? 1 : 0)
    formData.append("round", verticalActiveTab)
    formData.append("detail_id", caseDetails?.detail_id)
    formData.append("testcase_id", caseDetails?.id)
    if (selectedFiles1?.length != 0) {
      for (let i = 0; i < selectedFiles1.length; i++) {
        formData.append("files[]", selectedFiles1[i])
      }
    }
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/changeTestCaseStatus`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Test Case Updated & Ticket Generated Successfully",
        }).then(data => {
          updateData(res?.data?.data)
          closeModal(true)
          setCaseResult("")
          setPlatform("")
          setComments("")
          setTitle("")
          setType([])
          setPriority([])
          setDescription("")
          setselectedFiles1([])
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchDetails = async () => {
      const res = await fetchData(
        toggleLoading,
        `testCaseData/${caseDetails?.id}`,
        token
      )
      if (res?.success) {
        const detail = res?.data[0]
        setTitle(detail?.ticket_id?.subject)
        setCaseResult(detail?.actual_result)
        setPlatform(detail?.platform)
        setComments(detail?.comment)
        setDate(detail?.date_execution)
        setType(issueType?.find(obj => obj?.value == detail?.issue_type))
        setPriority(priorityOption?.find(obj => obj?.value == detail?.priority))
        setDescription(detail?.ticket_id?.description)
        setUnitTest(detail?.ticket_id?.unit_test == 1)
        setFiles(detail?.ticket_files)
        setDetails(detail)
      }
    }
    if (addType) {
      fetchDetails()
    }
  }, [addType, caseDetails])


  return (
    <div>
      {isLoading && <Loader />}
      <div className="modal-body pe-3">
        <form onSubmit={onFailCase}>
          <div className="d-flex justify-content-between mt-3 col-md-12">
            <div className="test-case-width ">
              <div
                className="col-md-12 d-flex flex-column pe-3 "
                style={{
                  scrollbarWidth: "none",
                  maxHeight: "550px",
                  overflow: "auto",
                }}
              >
                <div className="mt-3 mb-2">
                  <div className="col-12">
                    <label htmlFor="">Test Case Description</label>
                    <p>
                      <i className="mdi mdi-chevron-right text-primary me-1"></i>
                      {caseDetails?.description}
                    </p>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="">
                    <label htmlFor="">Preconditions</label>
                    {caseDetails?.preconditions?.split("@#")?.map((obj, i) => {
                      return (
                        <p className="mb-1" key={i}>
                          <i className="mdi mdi-chevron-right text-primary me-1"></i>
                          {obj}
                        </p>
                      )
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <div className="">
                      <label htmlFor="">Test Steps</label>
                      {caseDetails?.test_steps?.split("@#")?.map((obj, i) => {
                        return (
                          <p className="mb-1" key={i}>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            {obj}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6">
                    <div className="">
                      <label htmlFor="">Test Data</label>
                      {caseDetails?.test_data_field
                        ?.split("@#")
                        ?.map((obj, i) => {
                          const values =
                            caseDetails?.test_data_value?.split("@#")
                          return (
                            <p className="mb-1" key={i}>
                              <i className="mdi mdi-chevron-right text-primary me-1"></i>
                              {obj} - {values[i]},
                            </p>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-md-8 mb-4">
                  <Label htmlFor="formrow-firstname-Input">
                    Case Actual Results <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  Case Actual Results"
                      required
                      value={caseResult}
                      onChange={e => setCaseResult(e.target.value)}
                      disabled={addType == true}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <Label htmlFor="formrow-firstname-Input">
                    Platform <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Platform"
                      value={platform}
                      onChange={e => setPlatform(e.target.value)}
                      required
                      disabled={addType == true}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">
                    Comments/Notes <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter Comments/Notes"
                      required
                      value={comments}
                      onChange={e => setComments(e.target.value)}
                      disabled={addType == true}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="info_box_1"></div>
            <div className="test-case-width ">
              {details?.ticket_id != null || addType == false ? (
                <>
                  <div className="row">
                    <div className="col-md-9 mb-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                          disabled={addType == true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <Label htmlFor="formrow-firstname-Input">Type</Label>
                      <Select
                        placeholder="Select Type"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={issueType}
                        value={type}
                        onChange={e => setType(e)}
                        isDisabled={addType == true}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={priorityOption}
                        value={priority}
                        onChange={e => setPriority(e)}
                        isDisabled={addType == true}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Date of Execution <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Platform"
                          required
                          value={date}
                          onChange={e => setDate(e.target.value)}
                          disabled={addType == true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 me-2 ms-4">
                      <label
                        htmlFor="formrow-firstname-Input"
                        className="form-label mb-3"
                      >
                        Missing Unit Test
                      </label>
                      <div className="form-switch form-switch-md mb-2 ps-5  ">
                        <input
                          title="Intial Days"
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemd"
                          disabled={addType == true}
                          // checked={unitTest}
                          onChange={e => setUnitTest(!unitTest)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <div className="no-drag">
                        <CKEditor
                          style={{ height: "500px" }}
                          editor={ClassicEditor}
                          data={description || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-space-between align-items-center">
                      {!addType ? (
                        <div className="col-md-11 d-flex justify-content-start align-items-center gap-3">
                          <div className="col-md-5 col-sm-12 mt-3">
                            <label htmlFor="">Attachment</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles1(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className="dz-message needsclick p-0"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="d-flex px-2 custom-file-upload align-items-center">
                                    <div className="">
                                      <i className="display-6 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h5 className="mb-0 ms-2">Upload Files</h5>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            className={`mt-3 dropzone-previews ${
                              selectedFiles1.length > 0 ? "d-block" : "d-none"
                            }`}
                            id="file-previews"
                          >
                            <label className="mb-1">Uploaded Files</label>
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-0">
                                <Row className="align-items-center justify-content-evenly">
                                  {selectedFiles1?.map((f, i) => {
                                    return (
                                      <Col key={i} className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                    )
                                  })}
                                </Row>
                              </div>
                            </Card>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-12 ps-2">
                            <label htmlFor="">Attachments</label>
                            {files?.map((obj, i) => (
                              <a
                                className="text-light badge bg-primary ms-2"
                                title="Click To Open"
                                target="_blank"
                                href={obj?.file_name}
                                rel="noreferrer"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.8rem",
                                }}
                                key={i}
                              >
                                Click here
                              </a>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <img
                    src={EmptyImg}
                    alt="No Img"
                    width={300}
                    className="text-center"
                  />
                  <p className="text-center mt-3 text-purple fw-bold">
                    Ticket is not Added yet !
                  </p>
                </div>
              )}
            </div>
          </div>
          {!addType ? (
            <div className="my-2 text-end">
              <button className=" btn btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>Save
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  )
}

export default ViewCase
