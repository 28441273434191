import moment from "moment";
import React, { useState } from "react";
import ZithasLogo from "../../../assets/images/main-zithas.png"
import { Link } from "react-router-dom";
import configure from "configure";
import { useSelector } from "react-redux";
import { checkPermissionsByModule } from "utils/common-helpers";

export const LeaveManagementColumns = (onLeaveDetail) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div className="text-center">{cellProps?.row?.original?.id}</div>
            )
        }
    },
    {
        Header: "Applied On",
        accessor: "applied_on",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lapplied_on_box",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Employee Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_name_box",
    },
    {
        Header: "Employee Code",
        accessor: "emp_id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_code-box text-center",
    },
    {
        Header: "Punch ID",
        accessor: "punch_id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lpunch_id_box text-center",
    },
    {
        Header: "Reason",
        accessor: "leave_reason",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lreason_box",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "text-center laction",
        Cell: cellProps => {
            const role = useSelector((state) => state?.Roles?.apiData)
            const permission = checkPermissionsByModule(role, 40, 5)
            return (
                <div className="text-center">
                    {permission ? (
                        <a
                            title="Leave Details"
                            className="btn btn-sm btn-outline-secondary me-1"
                            onClick={() => onLeaveDetail(cellProps?.row?.original?.id, cellProps?.row?.original?.employee, cellProps?.row?.original, 1)}
                        >
                            <i className="bi bi-info-lg"></i>
                        </a>
                    ) : null}
                    <a
                        title="View Details"
                        className="btn btn-sm btn-outline-secondary me-1"
                        onClick={() => onLeaveDetail(cellProps?.row?.original?.id, cellProps?.row?.original?.employee, cellProps?.row?.original, 0)}
                    >
                        <i className="bi bi-eye"></i>
                    </a>

                </div>
            )
        },
    },
]

export const punchDetailsColumns = () => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
    },
    {
        Header: "Employee",
        accessor: "emply",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center ",
        Cell: cellProps => {
            return (
                <div className="d-flex gap-2 align-items-center">
                    <img src={ZithasLogo} alt="No img" width={25} height={25} className="object-fit-cover rounded-circle" />
                    <span>Zainul Abedin Marchawala - 1991 </span>
                </div>
            )
        }

    },
    {
        Header: "Approved Leaves",
        accessor: "approve",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>0</div>
            )
        }
    },
    {
        Header: "UnApproved Leaves",
        accessor: "unapprove",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>0</div>
            )
        }
    },
    {
        Header: "Total Leaves",
        accessor: "total_leaves",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>0</div>
            )
        }
    },
    {
        Header: "Late Punch",
        accessor: "punch",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>0</div>
            )
        }
    },
    {
        Header: "No. working Days",
        accessor: "working_days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>25</div>
            )
        }
    },
    {
        Header: "Productivity Hours",
        accessor: "hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
        Cell: cellProps => {
            return (
                <div>250/500</div>
            )
        }
    },
]

export const salarySlipColumns = () => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center salary-slip-id",
    },

    {
        Header: "Employee Code",
        accessor: "employee_code",
        isInfoIcon: false,
        isShortIcon: true,
        className: "salary-slip-empcode",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.employee_code || '-'}</div>
            )
        }
    },

    {
        Header: "Employee Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "salary-slip-empname",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.name || '-'}</div>
            )
        }
    },
    {
        Header: "Department",
        accessor: "department_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "salary-slip-department",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.department_name || '-'}</div>
            )
        }
    },
    {
        Header: "Designation",
        accessor: "designation",
        isInfoIcon: false,
        isShortIcon: true,
        className: "salary-slip-designation",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.designation || '-'}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "text-center salary-slip-action",
        Cell: cellProps => {
            return (
                <div>
                    <Link
                        to={`${configure.appUrl}generate-salary-slip/${cellProps?.row?.original?.id}`}
                        title="view"
                        className="btn btn-sm btn-outline-secondary me-1"
                    >
                        <i className="fas fa-eye "></i>
                    </Link>
                </div>
            )
        },
    },
]

export const salarySheetColumns = (statusChange) => [
    {
        Header: "",
        accessor: "select",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: false,
        isSelect: true,
        className: "text-center s_select",
        Cell: cellProps => {
            return (
                <div className="text-center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        defaultChecked={cellProps.row.original.pending_status == "0"}
                        onChange={e =>
                            statusChange(
                                cellProps.row.original.id,
                            )
                        }
                    />
                </div>
            )
        },
    },
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center salary-sr",
    },

    {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "salary-name",
    },

    {
        Header: "Total Days",
        accessor: "days",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-days",
    },
    {
        Header: "ASL",
        accessor: "sick_leave",
        title: "Approved Sick Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-asl",
    },
    {
        Header: "USL",
        accessor: "dsl",
        title: "Unapproved Sick Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-usl",
    },
    {
        Header: "AEL",
        accessor: "approved_leave",
        title: "Approved Earned Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-ael",
    },
    {
        Header: "UEL",
        accessor: "unapproved_leave",
        title: "Unapproved Earned Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-uel",
    },
    {
        Header: "ACL",
        accessor: "emergency_leave",
        title: "Approved Casual Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-acl",
    },
    {
        Header: "UCL",
        accessor: "dcl",
        title: "Unapproved Casual Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-ucl",
    },
    {
        Header: "UIL",
        accessor: "uninformed_leave",
        title: "Uninformed Leave",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-uil",
    },
    {
        Header: "LP",
        accessor: "late_punch",
        title: "Late Punch",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-lp",
    },
    {
        Header: "Total Leaves",
        accessor: "total_leave",
        title: "Late Punch",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-total_leaves",
    },
    {
        Header: "Worked Days",
        accessor: "gwd",
        title: "Worked Days",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-worked-days",
    },
    {
        Header: "Amount",
        accessor: "salary",
        title: "Amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center salary-amount",
        Cell: cellProps => {
            return (
                <div>{Math.floor(cellProps?.row?.original?.salary).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}</div>
            )
        }
    },
]