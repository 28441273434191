import React, { useEffect, useMemo, useState } from "react"
import { Form, Label, Modal, Table } from "reactstrap"
import { DigitalTaskColumns } from "utils/columns/Dashboard/dashboardColumns"
import { CommonTableContainer } from "utils/CommonTableContainer"
import { DigitalServiceOptions, TimeOption } from "utils/common-options"
import AuditReport from "pages/Digital Marketing/AuditReport"
import Select from "react-select"
import ReactSelect from "react-select"
import AddTime from "../../../../assets/images/AddTime.jpg"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import configure from "configure"
import moment from "moment"
import { Tooltip } from "antd"
import { sumTimes } from "utils/common-helpers"
import ResizableTable from "utils/ResizableTable"

const DmTodaysTask = ({ verticalActiveTab, activitiesData }) => {
  const [allActivity, setAllActivity] = useState(false)
  const [checkingActivity, setCheckingActivity] = useState(false)
  const [ViewInfo, setViewInfo] = useState(false)
  const [timeTaken, setTimeTaken] = useState([])
  const [checkingActivityReport, setCheckingActivityReport] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem('auth_token')
  const [activityDetails, setActivityDetails] = useState({})
  const [projectName, setProjectName] = useState('')
  const [activity, setActivity] = useState('')
  const [activityColumns, setActivityColumns] = useState([])
  const [rowsData, setRowsData] = useState([]);
  const [columsnIds, setColumnsIds] = useState(null)
  const [timeList, setTimeList] = useState([])
  const [allTimeList, setAllTimeList] = useState([])
  const [reportProjectOption, setReportProjectOption] = useState([])
  const [activityOptions, setActivityOptions] = useState([])
  const [activityDataOptions, setActivityDataOptions] = useState([])
  const [mainActivity, setMainActivity] = useState({})
  const [activityId, setActivityID] = useState('')
  const [isCheckingbuton, setCheckingButton] = useState(false)
  const toggleLoading = (load) => {
    setLoading(load)
  }
  const onGetDetails = async (id, obj) => {
    setActivity(obj)
    setActivityID(id)
    const outputString = obj?.project_name.split(" ").map(word => word[0]).join("");
    setProjectName(outputString)
    if (obj?.activity_type == 'checking-report') {
      const userId = localStorage.getItem('auth_id')
      const res = await fetchData(toggleLoading, `getDigitalProjectActivityColumns/${userId}`, token);
      if (res?.data) {
        setReportProjectOption(res?.data?.map((obj) => ({
          value: obj?.project?.id,
          label: obj?.project?.project_name,
          activites: obj?.activities
        })))
        setCheckingActivity(true)
      }
    } else {
      getColumnsData(id, obj)
    }
  }
  const getColumnsData = async (id, obj) => {
    const formEntries = {
      activity_id: obj?.activity_id,
      task_id: obj?.id,
      project_id: obj?.project_id
    }
    const res = await FetchPostData(toggleLoading, `getActivityById`, token, formEntries);
    if (res?.data?.success) {
      const activityData = res?.data?.data
      setCheckingButton(activityData?.activity_data?.checking_report)
      const result = activityData?.columns?.map(item => {
        const parsedColumns = JSON.parse(item?.columns);
        parsedColumns.id = item?.id;
        parsedColumns.statusActivity = item?.status === 1;
        parsedColumns.link = item?.link;
        parsedColumns.remark = item?.remark;
        return parsedColumns;
      });
      setMainActivity(obj)
      setAllTimeList(activityData?.total_time)
      setActivityDetails(activityData)
      setAllActivity(true)
      const columns = activityData?.activity_data?.cloumns?.split(',') || [];
      setActivityColumns(columns)
      const rows = new Array(Number(obj?.quantity)).fill(null).map(() => {
        return columns.reduce((acc, col) => {
          acc[col] = '';
          return acc;
        }, {
          statusActivity: false
        });
      });
      if (activityData?.columns?.length != 0) {
        setRowsData(result)
        setColumnsIds(activityData?.columns?.map((obj) => obj?.id)?.join(','))
      } else {
        if (columns?.length == 0) {
          setRowsData([])
        } else {
          setRowsData(rows)
        }
        setColumnsIds(null)
      }
    }
  }

  const column1 = useMemo(
    () =>
      DigitalTaskColumns(
        onGetDetails
      ),
    []
  )
  const [data, setData] = useState([])

  // popup upate width
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.98)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  useEffect(() => {
    setData(activitiesData)
  }, [verticalActiveTab])

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const [selectedActivity, setSelectedActivity] = useState()
  const [selectedProject, setSelectedProject] = useState()
  const [selectedColumn, setSelectedColumn] = useState()
  const [allTypeActivity, setAllTypeActivity] = useState(false)
  const [columnListId, setColumnListId] = useState('')

  const Activity_Options = [
    { label: "Checking Blogs", value: "Checking Blogs" },
    { label: "Checking SEO Post", value: "Checking SEO Post" },
    { label: "Checking SMM Post", value: "Checking SMM Post" },
    { label: "Checking SEO Report", value: "Checking SEO Report" },
    { label: "Checking SEO Blogs", value: "Checking SEO Blogs" },
  ]
  const Project_Options = [
    { label: "Earth Developemnt + ZAI", value: "Earth Developemnt + ZAI" },
    {
      label: "Earth Developemnt - Marketing Module",
      value: "Earth Developemnt - Marketing Module",
    },
    { label: "Jean Theraphy", value: "Jean Theraphy" },
    { label: "W-Hensons", value: "W-Hensons" },
    { label: "Din Dayal", value: "Din Dayal" },
  ]


  const handleInputChange = (rowIndex, colName, value) => {
    const updatedRows = [...rowsData];
    updatedRows[rowIndex][colName] = value;
    setRowsData(updatedRows);
  };

  const handleCheckboxChange = (rowIndex) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData[rowIndex].statusActivity = !updatedRowsData[rowIndex].statusActivity;
    setRowsData(updatedRowsData);
  };

  const onSaveColumns = async (e) => {
    const result = rowsData.map(item => JSON.stringify(item)).join('@#~+')
    e.preventDefault();
    const formEntries = {
      columns: result,
      task_id: activity?.id,
      project_id: activity?.project_id,
      activity_id: activity?.activity_id,
      status: rowsData.map((row) => (row.statusActivity ? '1' : '0')).join(','),
      column_id: columsnIds != null ? columsnIds : 'N/A'
    }
    const res = await FetchPostData(toggleLoading, 'addDigitalActivityColumns', token, formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Activity Data Added Successfully ",
      }).then(() => {
        onGetDetails(activityId, activity)
      })

    }
  }

  const addTime = async (e, type, id) => {
    e.preventDefault()
    if (timeTaken?.length != 0 || type != 1) {
      const formEntries = {
        column_id: type == 2 ? id : columnListId,
        time: timeTaken?.value,
        activity_id: activity?.activity_id,
        task_id: activity?.id,
        project_id: activity?.project_id,
        user_id: localStorage.getItem('auth_id'),
        type: type,
      }
      const res = await FetchPostData(toggleLoading, "addDigitalActivityColumnsTimer", token, formEntries);
      if (res?.data?.success) {
        setTimeTaken([])
        if (type == 1) {
          Swal.fire({
            icon: "success",
            text: "Time Added Successfully",
          }).then(() => {
            setTimeList(res?.data?.data?.total_time)
            setAllTimeList(res?.data?.data?.all_total_time)
          })
        } else {
          setTimeList(res?.data?.data?.total_time)
          setAllTimeList(res?.data?.data?.all_total_time)
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Add Time",
      })
    }
  }

  const getTiming = async (id) => {
    setTimeList(allTimeList?.filter((obj) => obj?.column_id == id))
    setViewInfo(true)
  }

  const [approveLink, setApproveLink] = useState('')
  const [remark, setRemark] = useState('')
  const [reportColumnId, setReportColumnId] = useState('')
  const [approveData, setApproveData] = useState([])
  const onAddApprove = async (e) => {
    e.preventDefault();
    const formEntries = {
      approve_link: approveLink,
      column_id: reportColumnId?.value,
      remark: remark,
      user_id: localStorage.getItem('auth_id')
    }
    const res = await FetchPostData(toggleLoading, 'updateDigitalActivityColumnsLink', token, formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: 'success',
        text: 'Approved Link Added Successfully',
      }).then(() => {
        setApproveLink('')
        setRemark('')
        setSelectedColumn([])
        setAllTypeActivity(false)
        setCheckingActivity(false)
      })
    }
  }

  const transformData = (data) => {
    return data.map(item => {
      const columns = JSON.parse(item.columns);
      const labelKey = Object.keys(columns).find(key => key !== 'statusActivity' && key !== 'id');
      const label = columns[labelKey];
      const transformedItem = { value: item.id, label, link: item?.link, remark: item?.remark };
      Object.keys(columns).forEach(key => {
        if (key != 'statusActivity' && key != 'id') {
          transformedItem[key] = columns[key];
        }
      });

      return transformedItem;
    });
  };

  const getApprovLinks = async (check) => {
    if (approveData?.length == 0) {
      const res = await fetchData(toggleLoading, `getDigitalActivityColumnsLink`, token);
      if (res?.data) {
        setApproveData(res?.data)
        setAllTypeActivity(true)
      }
    } else {
      setAllTypeActivity(true)
    }
  }
  const onApprovData = (call) =>{
    if(call){
      onGetDetails(activityId , activity)
    }
  }

  return (
    <div className="splan-right-tab">
      {isLoading && (<Loader />)}
      <Form className="row d-flex justify-content-center align-items-center mt-3">
        {verticalActiveTab != 4 ? (
          <div className="d-flex flex-column pln_Day_popup">
            <div className="ps-3 mb-4 ">
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-3">
                  {DigitalServiceOptions?.map((obj, i) => {
                    return (
                      <a key={i} className={`fs-6 ${obj?.text}-color`}>
                        <i className={`${obj?.icon} ${obj?.text}-color`}></i> -{" "}
                        {obj?.text}
                      </a>
                    )
                  })}
                </div>
                <h5 className="text-end mb-3">
                  Total Estimated Time : {sumTimes(data, 'dev_hrs')} hrs
                </h5>
              </div>
              <CommonTableContainer
                columns={column1}
                data={data}
                status={true}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="ps-3 mb-4 ">
              <AuditReport />
            </div>
          </div>
        )}
      </Form>

      {/* All Activity popup  */}
      <Modal
        isOpen={allActivity}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
        className=" modal-fullscreen"
      >
        {isLoading && (<Loader />)}
        <div className="modal-header">
          <h5 className="modal-title mt-0">All Activity</h5>
          <button
            type="button"
            onClick={() => {
              setAllActivity(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <form className="row d-flex justify-content-center align-items-center"
            onSubmit={(e) => onSaveColumns(e)}
          >
            <div className="my-2 d-flex justify-space-between align-items-center">
              <div className="col-8 d-flex justify-content-start align-items-center gap-3">
                <div className="d-flex justify-content-start align-items-center">
                  <h5 title={activity?.project_name} className="text-uppercase">{projectName} - </h5>
                  <i className={`${DigitalServiceOptions?.find((obj) => obj.id == activity?.type)?.icon} ms-2 mb-2 fs-4 ${DigitalServiceOptions?.find((obj) => obj.id == activity?.type)?.text}-color`}></i>
                </div>
                <div>
                  <h5>
                    {activityDetails?.activity_data?.activity}
                  </h5>
                </div>
                <div className="ms-4">
                  <h5 className="text-purple">
                    Plan QTY : <span className="text-primary fw-bold">{mainActivity?.quantity}</span>
                  </h5>
                </div>
                <div className="ms-4">
                  <h5 className="text-purple">
                    Total Time Taken :
                    <span className="text-primary fw-bold">{sumTimes(allTimeList, "time")} hrs</span>
                  </h5>
                </div>
              </div>

              <div className="col-4 text-end">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save Data
                </button>
              </div>
            </div>
            <div
              className="d-flex flex-column new-task-modal mt-2 all_activity_box"
              style={{
                maxHeight: "400px",
                overflowX: "auto",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {/* <table className="col-12 table table-bordered all_activity_table w-100"
              style={{tableLayout:"fixed"}}>
                <thead className="col-12">
                  <tr>
                    <th scope="col" className="act_id text-center">
                      id
                    </th>
                    {activityColumns?.map((obj, i) => {
                      return (
                        <th scope="col" className="act_col text-center" key={i}>
                          {obj}
                        </th>
                      )
                    })}
                    <th scope="col" className="act_id text-center">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rowsData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        <td scope="row" className="text-center">
                          {rowIndex + 1}
                        </td>
                        {activityColumns.map((col) => (
                          <td key={col}>
                            <input
                              type="text"
                              value={row[col]}
                              className="form-control"
                              onChange={(e) => handleInputChange(rowIndex, col, e.target.value)}
                              placeholder={`Enter ${col}`}
                            />
                          </td>
                        ))}
                        <td className="d-flex justify-content-center align-items-center">
                          <div className="custim-width contact-btns d-flex justify-content-center align-items-center mb-md-0 mt-3">
                            <div className="custom_info_box">
                              <button
                                title="Add Hours"
                                className="btn btn-sm btn-outline-secondary me-1"
                                onClick={() => {
                                  setColumnListId(row?.id)
                                  getTiming(row?.id)
                                }}
                                type="button"
                                disabled={row?.id == undefined || row?.id == ""}
                              >
                                <i className="bi bi-alarm-fill"></i>
                              </button>
                            </div>
                            <div className="text-center" id="customCheckbox">
                              <input
                                type="checkbox"
                                className="form-check-input custom-checkbox mt-0 ms-2"
                                id="customCheck-outlinecolor2"
                                checked={row?.statusActivity ? true : false}
                                onClick={e => {
                                  const isAddedTime = allTimeList?.filter((obj) => obj?.column_id == row?.id)?.length
                                  if (isAddedTime == 0) {
                                    Swal.fire({
                                      icon: "warning",
                                      title: "No time added for this column",
                                    })
                                  } else {
                                    handleCheckboxChange(rowIndex)
                                    addTime(e, 2, row?.id)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table> */}
              <ResizableTable
                activityColumns={activityColumns}
                rowsData={rowsData}
                handleInputChange={handleInputChange}
                setColumnListId={setColumnListId}
                getTiming={getTiming}
                handleCheckboxChange={handleCheckboxChange}
                addTime={addTime}
                allTimeList={allTimeList}
                onApprovData={onApprovData}
                isCheckingbuton={isCheckingbuton}
              />
            </div>
          </form>
        </div>
      </Modal>

      {/* Checking Activity popup  */}
      <Modal
        isOpen={checkingActivity}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
        className="custom_checking_popup"
      >
        {isLoading && (<Loader />)}
        <div className="modal-header">
          <h5 className="modal-title mt-0">Checking Activity</h5>
          <button
            type="button"
            onClick={() => {
              setCheckingActivity(false)
              setSelectedColumn([])
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddApprove}>
            <div className=" col-12 my-2 d-flex justify-content-end align-items-center">
              <div className="col-6 d-flex justify-content-start align-items-center gap-3">
                <div className="d-flex justify-content-start align-items-center">
                  <h5 title={activity?.project_name} className="text-uppercase">{projectName} - </h5>
                  <i className={`${DigitalServiceOptions?.find((obj) => obj.id == activity?.type)?.icon} ms-2 mb-2 fs-4 ${DigitalServiceOptions?.find((obj) => obj.id == activity?.type)?.text}-color`}></i>
                </div>
                <div>
                  <h5>
                    {activity?.name}
                  </h5>
                </div>
              </div>

              <div className="col-6 d-flex justify-content-end align-items-center">
                <div className="d-flex  justify-content-center align-items-center mt-0 me-3">
                  <p className="text-gray mt-0 mb-0 pe-2">
                    Today's Approved Data
                  </p>
                  <div className="form-switch form-switch-md ps-4">
                    <input
                      title="Intial Days"
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      defaultChecked={allTypeActivity}
                      onChange={() => {
                        if (allTypeActivity) {
                          setAllTypeActivity(false)
                        } else {

                          getApprovLinks()

                        }
                      }}
                      style={{
                        width: "40px",
                        height: "20px",
                        transform: "scale(0.8)",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column new-task-modal mt-2"
              style={{
                maxHeight: "500px",
                overflowX: "auto",
                overflowY: "auto",
                height: "100%",
              }}
            >
              <div className="col-12 parent_box_checkActivity py-4 ">
                <div className="col-12 d-flex justify-content-start align-items-lg-center gap-3 h-100 border-bottom pb-4">
                  <div className="col-3">
                    <Label>
                      Select Project <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      placeholder="Select Project"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={reportProjectOption}
                      value={selectedProject}
                      onChange={e => {
                        setActivityOptions(e?.activites?.map((obj) => ({
                          label: obj?.activity?.name,
                          value: obj?.activity?.activity_id,
                          columns: obj?.columns
                        })))
                        setSelectedColumn([])
                      }
                      }
                    />
                  </div>
                  <div className="col-4">
                    <Label>
                      Select Activity <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      placeholder="Select Activity"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={activityOptions}
                      value={selectedActivity}
                      onChange={e => {
                        setActivityDataOptions(transformData(e?.columns).filter((obj) => obj?.label != ""))
                        setSelectedColumn([])
                      }
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label>Activity Data</Label>
                    <Select
                      placeholder="Select"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={activityDataOptions}
                      value={selectedColumn}
                      onChange={e => {
                        setRemark(e.remark != null ? e.remark : "")
                        setApproveLink(e.link != null ? e.link : "")
                        setReportColumnId(e)
                        setSelectedColumn(e)
                      }
                      }
                    />
                  </div>
                </div>

                {selectedColumn?.value ? (
                  <div className="col-12 d-flex justify-content-start align-items-start gap-3 my-4">
                    <div className="col-6 d-flex flex-column justify-content-start align-items-start ">
                      <h3 className="text-primary mb-4">Info :</h3>
                      <div className=" col-12 d-flex justify-content-start align-items-start gap-3">
                        <div className="col-12 d-flex flex-column justify-content-start align-items-start gap-2 ">
                          {Object.keys(selectedColumn).map((key) => {
                            if (key !== 'value' && key !== 'label') {
                              return (
                                <div className="d-flex gap-2" key={key}>
                                  <span>
                                    <i className="bi bi-arrow-right text-purple"></i>
                                  </span>
                                  <span className="text-purple">{key} :</span>
                                  <span className="text-primary">{selectedColumn[key]}</span>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 d-flex justify-content-end align-items-start gap-2 check_info_box">
                      <div className="col-12 d-flex flex-column justify-content-center align-items-start gap-3">
                        <div className="col-10 flex-column">
                          <Label>Approved Link</Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Approved Link"
                            value={approveLink}
                            onChange={(e) => setApproveLink(e.target.value)}
                          ></input>
                        </div>
                        <div className="col-10 d-flex flex-column">
                          <Label>Remark</Label>
                          <textarea
                            cols="20"
                            rows="5"
                            className="form-control"
                            placeholder="Enter Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="d-flex flex-column">
                          <button className="btn btn-success btn-label text-end">
                            <i className="fas fa-check me-2 label-icon"></i>
                            Approved
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {allTypeActivity ? (
                  <div className="border-top pt-4">
                    <h4>Today's Approved List</h4>
                    <div
                      className="d-flex flex-column new-task-modal mt-4 custom_all_approved_box"
                      style={{
                        maxHeight: "400px", // Adjust the height as per your requirement
                        overflowX: "auto",
                        overflowY: "auto",
                        width: "100%", // Ensures the container takes full width
                        border: "1px solid #ddd", // Optional, adds a border to the container
                      }}
                    >
                      <table
                        className="col-12 table table-bordered custom_all_approved_tabel"
                        style={{
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <thead className="col-12">
                          <tr className="">
                            <th scope="col" className="text-center check_id">
                              ID
                            </th>
                            <th
                              scope="col"
                              className=" text-center check_activity_name"
                            >
                              Column Label
                            </th>
                            <th
                              scope="col"
                              className="check_approve_link text-center"
                            >
                              Approved Link
                            </th>
                            <th
                              scope="col"
                              className="check_remark_col text-center"
                            >
                              Remark
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {approveData?.map((obj, i) => {
                            const data = JSON.parse(obj.columns)
                            const label = Object.values(data)[1]
                            return (
                              <tr key={i}>
                                <td scope="row" className="text-center">
                                  {i + 1}
                                </td>
                                <td>
                                  {label}
                                </td>
                                <td>
                                  {obj?.link}
                                </td>
                                <td>
                                  {obj?.remark}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Time taking Popup  */}
      <Modal
        isOpen={ViewInfo}
        toggle={() => {
          tog_center()
        }}
        centered
        size="lg"
        className="custom_checking_report_popup"
      >
        {isLoading && (<Loader />)}
        <div className="modal-header">
          <h5 className="modal-title mt-0">Time Taken</h5>
          <button
            type="button"
            onClick={() => {
              setViewInfo(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row d-flex justify-content-center align-items-center" onSubmit={(e) => addTime(e, 1)}>
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={AddTime} alt="Addepe" style={{ width: "100%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column justify-space-between  gap-5 mb-4">
              <div className="col-12 d-flex justify-space-between align-items-center">
                <div className="col-4 d-flex flex-column  flex-wrap">
                  <label htmlFor="">
                    Select Time <span className="text-danger">*</span>
                  </label>

                  <ReactSelect
                    placeholder="Select Time"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={TimeOption}
                    value={timeTaken}
                    onChange={selectedOption => setTimeTaken(selectedOption)}
                  />
                </div>
                <div className="text-end mt-4">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                </div>
              </div>

              <div className="table-responsive col-md-12 time-taken-table">
                <Table className="table table-bordered mb-0">
                  <thead
                    className="bg-white"
                    style={{ position: "sticky", top: 0 }}
                  >
                    <tr>
                      <th className="time_taken_sr">Sr. No</th>
                      <th className="time_taken_user">User</th>
                      <th className="time_taken_date">Date</th>
                      <th className="time_taken_timeCol">Time Taken</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeList?.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-center">{i + 1}</td>
                          <td className="text-center">
                            <Tooltip color={"#752b97"} title={obj?.alias_name}>
                              <img
                                src={`${configure.imgUrl}${obj?.avatar}`}
                                className="img-fluid"
                                style={{ width: "25px", borderRadius: "100%" }}
                              />
                            </Tooltip>
                          </td>
                          <td className="text-center">{moment(obj?.created_at).format('DD-MM-YYYY')}</td>
                          <td className="text-center">{obj?.time}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                {timeList?.length == 0 ? (
                  <p className="text-center text-purple fw-bold mt-3">Timing is not added yet</p>
                ) : null}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Checking Activity popup  */}
      <Modal
        isOpen={checkingActivityReport}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
        className="custom_checking_popup"
      // className="modal-fullscreen"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Checking Report</h5>
          <button
            type="button"
            onClick={() => {
              setCheckingActivityReport(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className=" col-12 my-2 d-flex justify-content-end align-items-center">
              <div className="col-6 d-flex justify-content-start align-items-center gap-3">
                <div className="d-flex justify-content-start align-items-center">
                  <h5>EDZ - </h5>
                  <i className="bx bxs-megaphone ms-2 mb-2 fs-4 SMM-color"></i>
                </div>
                <div>
                  <h5>Checking Social Media Post</h5>
                </div>
                {/* <div className="ms-4">
                  <h5 className="text-purple">
                    Plan QTY : <span className="text-primary fw-bold">5</span>
                  </h5>
                </div> */}
              </div>

              <div className="col-6 d-flex justify-content-end align-items-center">


                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>

                <a
                  onClick={() => setCheckingActivityReport(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
            <div
              className="d-flex flex-column new-task-modal mt-2"
              style={{
                maxHeight: "500px",
                overflowX: "auto",
                overflowY: "auto",
                height: "100%",
                // borderTop: "1px solid #ddd",
              }}
            >
              <div className="col-12 parent_box_checkActivity py-4 ">
                <div className="col-12 d-flex justify-content-start align-items-lg-center gap-3 h-100 border-bottom pb-4">
                  {/* {
                    selectedActivity?.value ? ( */}
                  <div className="col-3">
                    <Label>
                      Select Project <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      placeholder="Select Project"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={Project_Options}
                      value={selectedProject}
                      onChange={selectedOption =>
                        setSelectedProject(selectedOption)
                      }
                    // style ={{width : "80%"}}
                    />
                  </div>

                  <div className="col-4">
                    <Label>
                      Select Activity <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      placeholder="Select Activity"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={Activity_Options}
                      value={selectedActivity}
                      onChange={selectedOption =>
                        setSelectedActivity(selectedOption)
                      }
                    // style ={{width : "80%"}}
                    />
                  </div>

                  {/* <div className="col-3">
                    {
                    selectedActivity ? (
                      <Label>{selectedActivity?.value.split(" ").slice(1).join(" ")} <span className="text-danger"> *</span></Label>
                    ): (<Label> </Label>)
                    }
                      <Select
                          placeholder="Select"
                          className="basic-multi-select border-radius"
                          classNamePrefix="select"
                          styles={colourStyles}
                          options={Column_Options}
                          value={selectedColumn}
                          onChange={selectedOption => setSelectedColumn(selectedOption)}
                         
                      />
                  </div>               */}
                </div>

                {selectedActivity?.value ? (
                  <div className="border-top pt-4">
                    <h4>Activity Report</h4>
                    <div
                      className="d-flex flex-column new-task-modal mt-4 custom_all_approved_box"
                      style={{
                        maxHeight: "400px", // Adjust the height as per your requirement
                        overflowX: "auto",
                        overflowY: "auto",
                        width: "100%", // Ensures the container takes full width
                        border: "1px solid #ddd", // Optional, adds a border to the container
                      }}
                    >
                      <table
                        className="col-12 table table-bordered custom_all_approved_tabel"
                        style={{
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <thead className="col-12">
                          <tr className="">
                            <th
                              scope="col"
                              className="text-center check_report_id"
                            >
                              Sr.No
                            </th>
                            <th
                              scope="col"
                              className="text-center check_report_title"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="check_report_remark_col text-center"
                            >
                              Likes
                            </th>
                            <th
                              scope="col"
                              className="check_report_remark_col text-center"
                            >
                              Views
                            </th>
                            <th
                              scope="col"
                              className="check_report_remark_col text-center"
                            >
                              Comments
                            </th>
                            <th
                              scope="col"
                              className="check_report_remark_col text-center"
                            >
                              Reach
                            </th>
                            {/* <th scope="col" className="check_report_action_col text-center">
                        Action
                      </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row" className="text-center">
                              1
                            </td>
                            <td scope="row" className="">
                              Reviewing Social Media post and analyzing the
                              statastics.
                            </td>

                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="5500"></input>): "5500"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="5500"
                              ></input>
                            </td>
                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="10400"></input>)
                          : "10400"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="10400"
                              ></input>
                            </td>
                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="1200"></input>)
                          : "1200"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="1200"
                              ></input>
                            </td>
                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="20580"></input>)
                          : "20580"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="20580"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              2
                            </td>
                            <td scope="row" className="">
                              Social Media Post Audit analyzing the statastics
                              and generating report.
                            </td>

                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="5500"></input>): "5500"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="5500"
                              ></input>
                            </td>
                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="10400"></input>)
                          : "10400"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="10400"
                              ></input>
                            </td>

                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="1200"></input>)
                          : "1200"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="1200"
                              ></input>
                            </td>

                            <td className="text-center">
                              {/* {
                          isReportEdit ? (
                          <input type="text" className="form-control" defaultValue="20580"></input>)
                          : "20580"
                        } */}
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="20580"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              3
                            </td>
                            <td scope="row" className="">
                              Post Quality Check Audit analyzing the statastics
                              and generating report.
                            </td>

                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              4
                            </td>
                            <td scope="row" className="">
                              Content Verification for Social Media Post Quality
                              Check Audit analyzing the statastics and
                              generating report.
                            </td>

                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              5
                            </td>
                            <td scope="row" className="">
                              Assessing Social Posts Content Verification for
                              Social Media Post Quality Check Audit analyzing
                              the statastics and generating report.
                            </td>

                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              6
                            </td>
                            <td scope="row" className="">
                              Social Media Check-Up Assessing Social Posts
                              Content Verification for Social Media Post Quality
                              Check Audit analyzing the statastics and
                              generating report.
                            </td>

                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" className="text-center">
                              7
                            </td>
                            <td scope="row" className="">
                              Post Inspection Social Media Check-Up Assessing
                              Social Posts Content Verification for Social Media
                              Post Quality Check Audit analyzing the statastics
                              and generating report.
                            </td>

                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                            <td className="text-center">
                              <input
                                type="text"
                                className="form-control"
                              ></input>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default DmTodaysTask
