import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import secureLocalStorage from "react-secure-storage"
import { Container } from "reactstrap"
import { fetchData } from "utils/common-fetchData"

const TestScenario = ({ verticalActiveTab, caseId }) => {
  const [isLoading, setLoading] = useState(false)
  const [testCaseDetail, setTestCaseDetail] = useState({})
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const token = secureLocalStorage.getItem("auth_token")
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetchData(
        toggleLoading,
        `testCaseData/${caseId}`,
        token
      )
      if (res?.success) {
        setTestCaseDetail(res?.data[0])
      }
    }
    if (verticalActiveTab == 4) {
      fetchProjects()
    }
  }, [verticalActiveTab, caseId])


  return (
    <div>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          <div>
            <h5 className="text-dark mb-2">Test Scenario</h5>
            <div className="d-flex gap-3 ">
              <p>
                <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                <span className="text-purple fw-bold">
                  Case Expected Results{" "}
                </span>{" "}
                : {testCaseDetail?.expected_result}
              </p>
              <p>
                <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                <span className="text-purple fw-bold">Severity </span> :{" "}
                {testCaseDetail?.severity}
              </p>
            </div>
            <p className="mb-3 border-bottom pb-4">
              <i className="mdi mdi-chevron-right text-primary me-1 "></i>{" "}
              <span className="text-purple fw-bold">Comments / Notes </span> :
              {testCaseDetail?.comment}
            </p>
            <div className="row justify-contente-between mt-4">
              <div className="mb-3 col-md-6">
                <div className="">
                  <p className="text-purple fw-bold">
                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                    Preconditions
                  </p>
                  {testCaseDetail?.preconditions?.split("@#")?.map((obj, i) => {
                    return (
                      <p className="mb-1" key={i}>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        {obj}
                      </p>
                    )
                  })}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <div className="">
                  <p className="text-purple fw-bold">
                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                    Test Steps
                  </p>
                  {testCaseDetail?.test_steps?.split("@#")?.map((obj, i) => (
                    <p className="mb-1" key={i}>
                      <i className="mdi mdi-chevron-right text-primary me-1"></i>
                      {obj}
                    </p>
                  ))}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <div className="">
                  <p className="text-purple fw-bold">
                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                    Test Data
                  </p>
                  {testCaseDetail?.test_data_field
                    ?.split("@#")
                    ?.map((obj, i) => {
                      const values = testCaseDetail?.test_data_value
                      return (
                        <p className="mb-1" key={i}>
                          <i className="mdi mdi-chevron-right text-primary me-1"></i>
                          {obj} -{values[i]}
                        </p>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default TestScenario
