import configure from "configure"
import React from "react"
import { Link } from "react-router-dom"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import bug from "../../../assets/images/flags/bug-icon.png"
import Suggestion from "../../../assets/images/flags/sugg-icon.png"
import zithasLogo from "../../../assets/images/main-zithas.png"
import { developmentTaskStatus, ticketStatus } from "utils/common-options"
import moment from "moment"
import { trimProjectName } from "utils/common-helpers"
import Swal from "sweetalert2"
export const projectTicketsColumns = () => [
  {
    Header: "ID",
    accessor: "id",
    isInfoIcon: false,
    isShortIcon: true,
    className: "s_id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stask_name_box",
  },
  {
    Header: "Total Hours",
    accessor: "total_hrs",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Not Answered",
    accessor: "notAnswered",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Answered",
    accessor: "answered",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Closed",
    accessor: "closed",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Balance",
    accessor: "balance",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Action",
    accessor: "action",
    className: "msupport_action text-center",
    Cell: cellProps => {

      return (
        <div className="d-flex justify-content-center">
          <Link
            className="btn btn-sm btn-outline-secondary me-1"
            title="Project Tickets"
            to={`${configure.appUrl}support/tickets/${cellProps.row.original?.id}`}
          >
            <i className="bi bi-eye"></i>
          </Link>
        </div>
      )
    },
  },
]
export const TestCasesColumns = () => [
  {
    Header: "ID",
    accessor: "id",
    isInfoIcon: false,
    isShortIcon: true,
    className: "s_id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stask_name_box",
  },
  {
    Header: "Total Hours",
    accessor: "total_hrs",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Test Cases",
    accessor: "testcase",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Not Answered",
    accessor: "notAnswered",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Answered",
    accessor: "answered",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Total Tickets",
    accessor: "total",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box text-center",
  },
  {
    Header: "Action",
    accessor: "action",
    className: "msupport_action text-center",
    Cell: cellProps => {

      return (
        <div className="d-flex justify-content-center">
          <Link
            className="btn btn-sm btn-outline-secondary me-1"
            title="Test Cases"
            to={`${configure.appUrl}support/test-cases/${cellProps.row.original?.id}`}
          >
            <i className="fas fa-clipboard-list "></i>
          </Link>
          <Link
            className="btn btn-sm btn-outline-secondary me-1"
            title="Test Project"
            to={`${configure.appUrl}support/project-test/${cellProps.row.original?.id}`}
          >
            <i className="fas fa-project-diagram "></i>
          </Link>
        </div>
      )
    },
  },
]

export const ticketsColumns = (onReply, onEditTicket, onChangeStatus, onDelete) => [
  // {
  //   Header: "",
  //   accessor: "select",
  //   title: "ID",
  //   isInfoIcon: false,
  //   isShortIcon: false,
  //   isSelect: true,
  //   className: "text-center s_select",
  //   Cell: cellProps => {
  //     return (
  //       <div className="text-center">
  //         <input
  //           type="checkbox"
  //         // checked={selectToMove}
  //         // onChange={() => handleChange()}
  //         />
  //       </div>
  //     )
  //   },
  // },
  {
    Header: "ID",
    accessor: "id",
    title: "ID",
    isInfoIcon: false,
    isShortIcon: true,
    className: "s_id",
  },
  {
    Header: "Assign",
    title: "assign",
    accessor: "assign_name",
    isInfoIcon: false,
    isShortIcon: true,
    className: "assign-widths",
    Cell: cellProps => {
      const assigne = cellProps?.row?.original
      return (
        <div className="text-center d-flex gap-1 flex-wrap justify-content-center align-items-center">
          <img
            src={`${assigne?.avatar != null ? `${configure.imgUrl}/${assigne?.avatar}` : zithasLogo} `}
            alt=""
            className="rounded-circle object-fit-cover"
            width={25}
            height={25}
            title={assigne?.alias_name}
          />
        </div>
      )
    },
  },
  {
    Header: "Type",
    title: "type_name",
    accessor: "type_name",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stype_name_box",
    Cell: cellProps => {
      const icon = cellProps.row?.original?.type == 0 ? bug : Suggestion
      return (
        <div className="text-center d-flex gap-1 flex-wrap justify-content-center align-items-center type_img">
          {icon != null ? (
            <img src={icon} alt="" className="img-fluid assignee-img ms-2" ></img>
          ) : '-'}
        </div>
      )
    },
  },
  {
    Header: "Title",
    title: "Task Name",
    accessor: "subject",
    isInfoIcon: false,
    isShortIcon: true,
    className: "stask_name_box",
  },

  {
    Header: "Status",
    title: "Status",
    accessor: "status_col",
    isInfoIcon: false,
    isShortIcon: true,
    className: "sstatus_box",
    Cell: cellProps => {
      return (
        <div className="custom-select-wrapper">
          <select
            className={`form-select custom-select`}
            value={cellProps.row?.original?.status}
            onChange={(e) => onChangeStatus(e.target.value, cellProps.row?.original?.id)}
          >
            {ticketStatus?.map((obj, i) => {
              return (
                <option value={obj?.value} className="" key={i}>
                  {obj?.label}
                </option>
              )
            })}
          </select>
        </div>
      )
    },
  },
  {
    Header: "Priority",
    title: "Priority",
    accessor: "priority",
    isInfoIcon: false,
    isShortIcon: true,
    className: "prioritys_col_box ",
    Cell: cellProps => {
      const cellValue = cellProps.value
      let style = {}

      if (cellValue === "high") {
        style = { color: "red" }
      } else if (cellValue === "medium") {
        style = { color: "blue" }
      } else if (cellValue === "low") {
        style = { color: "green" }
      }

      return (
        <div style={style} className="text-capitalize">
          {cellValue}
        </div>
      )
    },
  },
  {
    Header: "Created At",
    title: "created at",
    accessor: "created_at",
    isInfoIcon: false,
    isShortIcon: true,
    className: "screated_at_col-box",
    Cell: cellProps => {
      return (
        <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
      )
    }
  },
  {
    Header: "Last Reply",
    title: "Last Reply",
    accessor: "last_reply_col",
    isInfoIcon: false,
    isShortIcon: true,
    className: "slast_reply_col-box ",
    Cell: cellProps => {
      return (
        <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
      )
    }
  },

  {
    Header: "Action",
    accessor: "action",
    className: "msupport_action text-center",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-center">
          <a
            onClick={() => onReply(cellProps?.row?.original)}
            className="btn btn-sm btn-outline-secondary me-1"
            title="Reply Ticket"
          >
            <i className="bi bi-reply-fill"></i>
          </a>
          <a
            onClick={() => onEditTicket(cellProps?.row?.original)}
            className="btn btn-sm btn-outline-secondary me-1"
            title="Edit Ticket"
          >
            <i className="fas fa-pen "></i>
          </a>
          <UncontrolledDropdown
            className="dropdown"
            style={{ width: "29px" }}
          >
            <DropdownToggle
              className="btn btn-sm btn-outline-secondary w-100"
              color="white"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <a className="dropdown-item" title="Delete Ticket" onClick={() => onDelete(cellProps?.row?.original?.id)}>
                <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                Delete Ticket
              </a>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    },
  },
]


export const taskCaseColumns = (onVeiwTask, onCreateTask) => [
  {
    Header: "Sr No.",
    accessor: "id",
    title: "ID",
    isInfoIcon: false,
    className: "text-center task_id",
  },
  {
    Header: "Task Name",
    title: "Task Name",
    accessor: "title",
    isInfoIcon: false,
    className: "task_name_box",
    Cell: cellProps => {
      return (
        <div className="d-flex gap-2 align-items-center">
          {cellProps?.row?.original?.title}
        </div>
      )
    }
  },
  {
    Header: "Milestone",
    title: "Milestone",
    accessor: "milestone",
    isInfoIcon: false,
    className: "milestone_box text-center",
    Cell: cellProps => {
      return (
        <div>Milestone - {cellProps?.row?.original?.milestone_no}</div>
      )
    }
  },
  {
    Header: "Status",
    title: "Status",
    accessor: "status_col",
    isInfoIcon: false,
    className: "status_box text-center",
    Cell: cellProps => {
      return (
        <div className="custom-select-wrapper">
          {developmentTaskStatus?.find((obj) => obj?.value == cellProps?.row?.original?.status)?.label}
        </div>
      )
    },
  },
  {
    Header: "Priority",
    accessor: "priority",
    isInfoIcon: false,
    className: "days_box text-center",
    Cell: cellProps => {
      return (
        <div className="text-capitalize">{cellProps?.row?.original?.priority || 'Medium'}</div>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    className: "task_action text-center",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-sm btn-outline-secondary me-1"
            title="Task History"
            onClick={() => onVeiwTask(cellProps?.row?.original)}
          >
            <i className="fas fa-eye "></i>
          </a>
          <a
            className="btn btn-sm btn-outline-secondary me-1"
            title="View Task"
            onClick={() => onCreateTask(cellProps?.row?.original)}
          >
            <i className="fas fa-plus"></i>
          </a>
        </div>
      )
    },
  },
]

export const testCasesColumns = (onReply, onViewCase, onChangeStatus, roundDetail) => [
  {
    Header: "Sr No.",
    accessor: "id",
    title: "ID",
    isInfoIcon: false,
    className: "text-center task_id",
  },
  {
    Header: "Task Name",
    title: "task_name",
    accessor: "title",
    isInfoIcon: false,
    className: "task_name_box",
    Cell: cellProps => {
      return (
        <div className="d-flex">
          {cellProps?.row?.original?.task_id} - {cellProps?.row?.original?.title}
        </div>
      )
    }
  },
  {
    Header: "Expected Result",
    accessor: "expected_result",
    isInfoIcon: false,
    className: "task_name_box ",
  },
  {
    Header: "Severity",
    accessor: "severity",
    isInfoIcon: false,
    className: "milestone_box text-center text-capitalize",
  },
  {
    Header: "Status",
    title: "Last Updated Days",
    accessor: "days_col",
    isInfoIcon: false,
    className: "days_box text-center",
    Cell: cellProps => {
      return (
        <div>
          <div className="custom-select-wrapper">
            <select className={`form-select custom-select`}
              onChange={(e) => onChangeStatus(e.target.value, cellProps.row.original)}
              value={cellProps.row.original?.status}
              disabled={roundDetail?.status == 1}
            >
              <option value="0" className="">
                Pending
              </option>
              <option value="1" className="">
                Pass
              </option>
              <option value="2" className="">
                Fail
              </option>
            </select>
          </div>
        </div>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    className: "task_action text-center",
    Cell: cellProps => {
      const onViewmainCase = () => {
        onViewCase(cellProps?.row?.original)
      }
      return (
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-sm btn-outline-secondary me-1"
            title="Task History"
            onClick={() => onViewmainCase(cellProps?.row?.original)}
          >
            <i className="fas fa-eye "></i>
          </a>
          {cellProps?.row?.original?.ticket_id != null ? (
            <a
              className="btn btn-sm btn-outline-secondary me-1"
              title="Task History"
              onClick={() => onReply(cellProps?.row?.original)}
              disabled={roundDetail?.status == 1}
            >
              <i className="fas fa-reply  "></i>
            </a>
          ) : null}

        </div>
      )
    },
  },
]


export const initialTestCases = (onEditCase, onDeleteCase) => [
  {
    Header: "Sr No.",
    accessor: "id",
    title: "ID",
    isInfoIcon: false,
    className: "text-center task_id",
  },
  {
    Header: "Task Name",
    title: "task_name",
    accessor: "title",
    isInfoIcon: false,
    className: "task_name_box",
    Cell: cellProps => {
      return (
        <div className="d-flex">
          {cellProps?.row?.original?.task_id} - {cellProps?.row?.original?.title}
        </div>
      )
    }
  },
  {
    Header: "Expected Result",
    accessor: "expected_result",
    isInfoIcon: false,
    className: "task_name_box text-center",
  },
  {
    Header: "Severity",
    accessor: "severity",
    isInfoIcon: false,
    className: "milestone_box text-center text-capitalize",
  },
  {
    Header: "Action",
    accessor: "action",
    className: "task_action text-center",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-sm btn-outline-secondary me-1"
            title="Edit Test Case"
            onClick={() => onEditCase(cellProps?.row?.original)}
          >
            <i className="fas fa-pen "></i>
          </a>
          <a
            className="btn btn-sm btn-outline-danger me-1"
            title="Delete Test Case"
            onClick={() => onDeleteCase(cellProps?.row?.original?.id)}
          >
            <i className="fas fa-trash "></i>
          </a>
        </div>
      )
    },
  },
]

export const supportTaskColumns = (onAddTime, changeStatus) => [
  {
    Header: "Task ID",
    accessor: "id",
    title: "ID",
    isInfoIcon: false,
    className: "project_id text-center"
  },
  {
    Header: "Project Name",
    accessor: "project_name",
    isInfoIcon: false,
    className: "col-3",
  },
  {
    Header: "Task Name",
    accessor: "task_name",
    isInfoIcon: false,
    className: "col-4",
  },
  {
    Header: "Status",
    accessor: "status",
    isInfoIcon: false,
    className: "col-2 text-center",
    Cell: cellProps => {
      return (
        <div className="custom-select-wrapper">
          <select
            className={`form-select custom-select`}
            onChange={e => changeStatus(e.target.value, cellProps.row.original.id)}
            value={cellProps.row.original.status}
          >
            <option value="0" className="">
              Pending
            </option>
            <option value="1" className="">
              Completed
            </option>
          </select>
        </div>
      )
    }
  },
  {
    Header: "Est. Time",
    title: "Last Updated Days",
    accessor: "hours",
    isInfoIcon: false,
    className: "col-1 text-center",
  },
  {
    Header: "Created At",
    title: "Last Updated Days",
    accessor: "date",
    isInfoIcon: false,
    className: "col-1 text-center",
    Cell: cellProps => {
      return (
        <div>
          {moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}
        </div>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    className: "col-1",
    Cell: cellProps => {
      const data = cellProps?.row?.original
      return (
        <div className="d-flex justify-content-center">
          <a className="btn btn-sm btn-outline-primary me-1" title="Add Time"
            onClick={() => onAddTime(data)}
          >
            <i className="fa fa-stopwatch "></i>
          </a>
        </div>
      )
    },
  },
]

