import React, { useEffect, useState } from "react"
import {
  Container,
  Form,
  Label,
  Input,
} from "reactstrap"
import { Link, } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
} from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import NewPagination from "components/Common/Newpagination"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import secureLocalStorage from "react-secure-storage"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import { developmentHours, priorityOption } from "utils/common-options"
import Swal from "sweetalert2"
import { initialTaskColumns } from "utils/columns/Developement/planningColumns"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import Loader from "pages/Separate/Loader"


function TableContainer({
  columns,
  data,
  setAddTask,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  return (
    <div>
      <div className="col-12 d-flex mb-3">
        <div className="col-6 d-flex justify-content-start align-items-center gap-4">
          <div className="col-2 d-flex justify-content-start align-items-center resp_show">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-8 d-flex justify-content-start align-items-center mt-2">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 d-flex justify-content-end align-items-center">
          <a
            onClick={() => setAddTask(true)}
            className="s btn text-white me-2 btn-save btn-label"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Task
          </a>
        </div>
      </div>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${(fixedContent && page.length >= 10) ? "fixed_addTask_headers" : "fixed-table-header"
              }`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames} customShortIcon custom-text`}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${column.isSorted &&
                              !column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                        </span>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>


          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className=""
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const AddTask = ({ verticalActiveTab, projectDetails, allTasks, onTaskUpdate }) => {
  const [allData, setAllData] = useState([])
  const [data, setData] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [addTask, setAddTask] = useState(false)
  const [EditTask, setEditTask] = useState(false)
  const token = secureLocalStorage.getItem('auth_token')
  const userId = localStorage.getItem('auth_id')
  const roles = useSelector(state => state?.Roles?.apiData)
  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setAddTask(false)
  })
  const [noDrag, setNoDrag] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [loading, setLoading] = useState()
  const [description, setDescription] = useState("")
  const [title, setTitle] = useState('')
  const [priority, setPriority] = useState([])
  const [est_hour, setEstHours] = useState('')
  const [probability, setProbability] = useState('')
  const [taskId, setTaskId] = useState('')

  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.65)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const onEdit = (obj) => {
    setTaskId(obj.id)
    setTitle(obj.title)
    setDescription(obj.description)
    setPriority(priorityOption.find(item => item.value == obj.priority))
    setEstHours(developmentHours?.find((item) => item?.value == obj.estimation_days))
    setProbability(obj.probability)
    setEditTask(true)
  }

  const onDeleteTask = id => {
    const permission = checkPermissionsByModule(roles, 88, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Task!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const formDatas = {
            task_id: id,
            project_id: projectDetails?.id,
            user_id: localStorage?.getItem("auth_id"),
            type: 1
          }
          const response = await FetchPostData(
            toggleLoading,
            "deleteTask",
            token,
            formDatas
          )
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Task deleted successfully",
            })
            setData(response?.data?.data?.filter((obj) => obj.milestone == verticalActiveTab))
            onTaskUpdate(response?.data?.data)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }

  const columns = useMemo(
    () => initialTaskColumns(onEdit, onDeleteTask),
    [developmentHours, verticalActiveTab]
  )

  useEffect(() => {
    setData(allTasks?.filter((obj) => obj.milestone == verticalActiveTab))
    setAllData(allTasks)
  }, [allTasks, verticalActiveTab])

  const onAddNewTask = async (e) => {
    e.preventDefault();
    if (description?.length >= 50) {
      if (priority?.length != 0 && est_hour?.length != 0) {
        const formEntries = {
          project_id: projectDetails?.id,
          user_id: userId,
          title: title,
          milestone: verticalActiveTab || "",
          estimation_hrs: est_hour?.value || "",
          probability: probability,
          priority: priority?.value || "",
          description: description,
          type: 1
        }
        const res = await FetchPostData(toggleLoading, 'addTask', token, formEntries);
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            title: "Task Added Successfully",
          }).then(() => {
            setTitle('')
            setEstHours([])
            setProbability('')
            setPriority([])
            setDescription('')
            setAddTask(false)
            setData(res?.data?.data?.filter((obj) => obj.milestone == verticalActiveTab))
            onTaskUpdate(res?.data?.data)
          })
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Please Fill all the Fields",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      })
    }
  }

  const onEditTask = async (e) => {
    e.preventDefault();
    if (description?.length >= 50) {
      if (priority?.length != 0 && est_hour?.length != 0) {
        const formEntries = {
          task_id: taskId,
          project_id: projectDetails?.id,
          user_id: userId,
          title: title,
          milestone: verticalActiveTab || "",
          estimation_hrs: est_hour?.value || "",
          probability: probability,
          priority: priority?.value || "",
          description: description,
          type: 1
        }
        const res = await FetchPostData(toggleLoading, 'editTask', token, formEntries);
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            title: "Task Edited Successfully",
          }).then(() => {
            setTitle('')
            setEstHours([])
            setProbability('')
            setPriority([])
            setDescription('')
            setEditTask(false)
            setData(res?.data?.data?.filter((obj) => obj.milestone == verticalActiveTab))
            onTaskUpdate(res?.data?.data)
          })
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Please Fill all the Fields",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      })
    }
  }


  return (
    <React.Fragment>
      {loading && (<Loader />)}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="responsive-table">
                <TableContainer
                  columns={columns}
                  data={data}
                  setData={setData}
                  setAddTask={setAddTask}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Task  */}
      <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "16%",
              right: "18%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)"
            }}
          >
            <form className="" onSubmit={onAddNewTask} >
              <div className="modal-header mb-2 justify-content-between">
                <h5 className="modal-title mt-0">Add Task</h5>
                <div className=" col-8 my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setAddTask(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="col-12 d-flex flex-column new-task-modal ">
                  <div className="col-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={title}
                          onChange={e => {
                            setTitle(e.target.value)
                          }}
                          onDoubleClick={() => setNoDrag(true)}
                          onMouseLeave={() => setNoDrag(false)}
                          onMouseEnter={() => setNoDrag(true)}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 row mb-4 justify-content-start align-items-end">
                    <div className="col-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={priorityOption}
                        value={priority}
                        onChange={e => setPriority(e)}
                      />
                    </div>
                    <div className="col-3 ">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={developmentHours}
                        value={est_hour}
                        onChange={e => setEstHours(e)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                    </div>

                    <div className="col-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="number"
                        className="form-control "
                        placeholder="Probability"
                        value={probability}
                        onChange={(e) => setProbability(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <div
                        className="no-drag"
                        onDoubleClick={() => setNoDrag(true)}
                        onMouseLeave={() => setNoDrag(false)}
                        onMouseEnter={() => setNoDrag(true)}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={description || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Edit Task  */}
      <div className={`note-sticky-form ${EditTask ? "d-block" : "d-none"}`}>
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "16%",
              right: "18%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)"
            }}
          >
            <form className="" onSubmit={onEditTask} >
              <div className="modal-header mb-2 justify-content-between">
                <h5 className="modal-title mt-0">Edit Task</h5>
                <div className=" col-8 my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => {
                      setEditTask(false)
                      setTitle('')
                      setDescription('')
                      setPriority([])
                      setEstHours([])
                      setProbability('')
                    }}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </div>

              <div className="modal-body">

                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={title}
                          onChange={e => {
                            setTitle(e.target.value)
                          }}
                          onDoubleClick={() => setNoDrag(true)}
                          onMouseLeave={() => setNoDrag(false)}
                          onMouseEnter={() => setNoDrag(true)}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 row mb-4 justify-content-start align-items-end">
                    <div className="col-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={priorityOption}
                        value={priority}
                        onChange={e => setPriority(e)}
                      />
                    </div>
                    <div className="col-3 ">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={developmentHours}
                        value={est_hour}
                        onChange={e => setEstHours(e)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                    </div>

                    <div className="col-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="number"
                        className="form-control "
                        placeholder="Probability"
                        value={probability}
                        required
                        onChange={(e) => setProbability(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <div
                        className="no-drag"
                        onDoubleClick={() => setNoDrag(true)}
                        onMouseLeave={() => setNoDrag(false)}
                        onMouseEnter={() => setNoDrag(true)}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={description || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default AddTask
