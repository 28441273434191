import axios from "axios"
import React, { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import AccessDenied from "./AccessDenied"
import HorizontalLayout from "../components/HorizontalLayout/"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const Authenticate = props => {
  const [roles, setRole] = useState([])
  useEffect(() => {
    RolesPermission()
  }, [])

  const RolesPermission = async e => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", props.module)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
      if (response.data.success) {
        setRole([response.data.data])
      } else {
        const modifyDefaultArray = [
          {
            india: ["0", "0", "0", "0", "0", "0"],
            uk: ["0", "0", "0", "0", "0", "0"],
          },
        ]
        setRole(modifyDefaultArray)
      }
    } catch (err) {
      console.log(err)
    }
  }

  if (
    (props.type == "view" &&
      (roles[0]?.india[2] == 1 || roles[0]?.uk[2] == 1)) ||
    (props.type == "add" &&
      (roles[0]?.india[3] == 1 || roles[0]?.uk[3] == 1)) ||
    (props.type == "edit" && (roles[0]?.india[4] == 1 || roles[0]?.uk[4] == 1))
  ) {
    return <React.Fragment>{props.children}</React.Fragment>
  } else if (
    (props.type == "view" && roles[0]?.india[2] == 0 && roles[0]?.uk[2] == 0) ||
    (props.type == "add" && roles[0]?.india[3] == 0 && roles[0]?.uk[3] == 0) ||
    (props.type == "edit" && roles[0]?.india[4] == 0 && roles[0]?.uk[4] == 0)
  ) {
    return (
      <Navigate
        to={{
          pathname: `${configure.appUrl}access-denied`,
          state: { from: props.location },
        }}
      />
    )
  } else {
    return (
      <HorizontalLayout>
        <Loader />{" "}
      </HorizontalLayout>
    )
  }
}

export default Authenticate

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";
// import AccessDenied from "./AccessDenied";
// import HorizontalLayout from "../components/HorizontalLayout/";
// import Loader from "pages/Separate/Loader";
// import configure from "configure";
// import { useSelector } from "react-redux";
// import { getPermissionsByModule, hasIndiaPermission } from "utils/common-helpers";

// const Authenticate = props => {
//   const [roles, setRole] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const role = useSelector((state) => state?.Roles?.apiData)
//   useEffect(() => {
//     const fetchRoles = async () => {
//       try {
//         setLoading(true)
//         const permission = await getPermissionsByModule(role, props.module)
//         setRole([permission]);
//         setLoading(false);

//       } catch (err) {
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRoles();
//   }, [props.module]);
//   const indiaPermissions = roles[0]?.india || [];
//   const ukPermissions = roles[0]?.uk || [];

//   const hasIndiaPermission = index =>
//     indiaPermissions[index] !== undefined && indiaPermissions[index] === 1;
//   const hasUkPermission = index =>
//     ukPermissions[index] !== undefined && ukPermissions[index] === 1;
//   const hasPermission =
//     (props.type === "view" && (hasIndiaPermission(2) || hasUkPermission(2))) ||
//     (props.type === "add" && (hasIndiaPermission(3) || hasUkPermission(3))) ||
//     (props.type === "edit" && (hasIndiaPermission(4) || hasUkPermission(4)));
//   if (loading) {
//     return (
//       <HorizontalLayout>
//         <Loader />
//       </HorizontalLayout>
//     );
//   }

//   if (hasPermission) {
//     return <React.Fragment>{props.children}</React.Fragment>;
//   } else {
//     return (
//       <Navigate
//         to={{
//           pathname: `${configure.appUrl}access-denied`,
//           state: { from: props.location },
//         }}
//       />
//     );
//   }
// };

// export default Authenticate;
