import React, { useEffect, useState } from "react"
import { Container, Form, Label } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import NewPagination from "components/Common/Newpagination"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import secureLocalStorage from "react-secure-storage"
import { initialTestCases } from "utils/columns/Support/projectTicketColumn"
import CreateTestCase from "./CreateTestCase"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Swal from "sweetalert2"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"

const fetchTasks = async (id, toggleLoading) => {
  const userId = localStorage.getItem("auth_id")
  const token = secureLocalStorage.getItem("auth_token")
  const res = await fetchData(toggleLoading, `taskData/${userId}/${id}`, token)
  if (res) {
    return { success: true, data: res }
  }
}

function TableContainer({
  columns,
  data,
  boxWidth,
  toggleLoading,
  setData,
  allData,
  setAllData,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const [addCase, setAddCase] = useState(false)
  const [status, setStatus] = useState("all")
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  const params = useParams()
  const [taskOption, setTaskOption] = useState([])

  const closeModal = modal => {
    setAddCase(modal)
  }

  const getTasks = async () => {
    const res = await fetchTasks(params?.id, toggleLoading)
    if (res?.success) {
      const data = res?.data?.map(obj => ({
        label: obj?.title,
        value: obj?.id,
      }))
      setTaskOption(data)
      setAddCase(true)
    }
  }
  const updatedData = allData => {
    setData(allData)
    setAllData(allData)
  }

  return (
    <div>
      <Row className="mb-4 align-items-center justify-content-between">
        <Col md={9} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-2 rounded proposal-status ${status == "all" ? 'activeProjectTab' : ''} `}
                onClick={() => {
                  setStatus("all")
                  setData(allData)
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded proposal-status ${status == "high" ? 'activeProjectTab' : ''}`}
                onClick={() => {
                  setStatus("high")
                  setData(allData.filter(obj => obj.severity == "high"))
                }}
              >
                <h4>{allData.filter(obj => obj.severity == "high")?.length}</h4>
                <h4>High</h4>
              </a>
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${status == "medium" ? 'activeProjectTab' : ''}`}
                onClick={() => {
                  setStatus("medium")
                  setData(allData.filter(obj => obj.severity == "medium"))
                }}
              >
                <h4>
                  {allData.filter(obj => obj.severity == "medium")?.length}
                </h4>
                <h4>Medium</h4>
              </a>
              <a
                className={`text-yellow pt-2 px-2 rounded proposal-status ${status == "low" ? 'activeProjectTab' : ''}`}
                onClick={() => {
                  setStatus("low")
                  setData(allData.filter(obj => obj.severity == "low"))
                }}
              >
                <h4> {allData.filter(obj => obj.severity == "low")?.length}</h4>
                <h4>Low</h4>
              </a>
            </div>
          </div>
        </Col>
        <Col md={3} className="text-end ">
          <a
            className="s btn text-white me-0 btn-save btn-label "
            onClick={() => getTasks()}
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i> Test Case
          </a>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead className={`fix-project-header`}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowColor
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green"
                  break
                case "Awaiting Feedback":
                  rowColor = "bg-red"
                  break
                case "Complete":
                  rowColor = "bg-yellow"
                  break
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div className={`note-sticky-form ${addCase ? "d-block" : "d-none"}`}>
        <Draggable handle=".modal-header" cancel=".modal-body">
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Create Test Case</h5>
              <button
                type="button"
                onClick={() => {
                  setAddCase(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <CreateTestCase
              type={"add"}
              task={true}
              taskOption={taskOption}
              closeModal={closeModal}
              updatedData={updatedData}
            />
          </ResizableBox>
        </Draggable>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const TestCase = ({ verticalActiveTab }) => {
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [editCase, setEditCase] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const param = useParams()
  const [caseDetail, setCaseDetail] = useState({})
  const [boxWidth, setBoxWidth] = useState(0)
  const roles = useSelector(state => state?.Roles?.apiData)
  const [taskOption, setTaskOption] = useState([])
  const toggloading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.9)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const onEditCase = obj => {
    setCaseDetail(obj)
    getTasks()
  }
  const onDeleteCase = id => {
    const permission = checkPermissionsByModule(roles, 25, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Test case!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const formEntries = {
            testcase_id: id,
            project_id: param?.id,
          }
          const response = await FetchPostData(
            toggloading,
            `deleteTestCases`,
            token,
            formEntries
          )
          if (response?.data.success) {
            Swal.fire({
              icon: "success",
              text: "Test Cases Deleted Successfully",
            }).then(data => {
              setData(response?.data.data)
              setAllData(response?.data.data)
            })
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const columns = useMemo(() => initialTestCases(onEditCase, onDeleteCase), [])

  useEffect(() => {
    const fetchTestCases = async () => {
      const res = await fetchData(
        toggleLoading,
        `testCasesData/${param?.id}`,
        token
      )
      if (res?.success) {
        setData(res?.data)
        setAllData(res?.data)
      }
    }
    if (verticalActiveTab == 1) {
      fetchTestCases()
    }
  }, [verticalActiveTab])
  const closeModal = modal => {
    setEditCase(modal)
  }
  const updatedData = allData => {
    setData(allData)
    setAllData(allData)
  }

  const getTasks = async () => {
    const res = await fetchTasks(param?.id, toggleLoading)
    if (res?.success) {
      const data = res?.data?.map(obj => ({
        label: obj?.title,
        value: obj?.id,
      }))
      setTaskOption(data)
      setEditCase(true)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      boxWidth={boxWidth}
                      toggleLoading={toggleLoading}
                      setData={setData}
                      allData={allData}
                      setAllData={setAllData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`note-sticky-form ${editCase ? "d-block" : "d-none"}`}>
        <Draggable handle=".modal-header" cancel=".modal-body">
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Create Test Case</h5>
              <button
                type="button"
                onClick={() => {
                  setEditCase(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <CreateTestCase
              type={"edit"}
              task={true}
              caseDetail={caseDetail}
              closeModal={closeModal}
              taskOption={taskOption}
              updatedData={updatedData}
            />
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default TestCase
