import classNames from "classnames";
import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ResourceSelection from "./ResourceSelection";
import ResourcePlanning from "./ResourcePlanning";
import LetsStart from "./LetStart";
import { Tooltip } from "antd";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Loader from "pages/Separate/Loader";
import moment from "moment";
import Swal from "sweetalert2";
import configure from "configure";


const StepNavigationStep = ({ step, currentStep, onStepChange }) => {
    const classes = classNames({
        active: step.id === currentStep,
        complete: currentStep > step.id,
    });

    return (
        <li className={classes} onClick={() => {
            if (currentStep >= step.id) {
                onStepChange(step.id)
            }
        }}>
            <div className="step">
                <i className={step.icon_class}></i>
            </div>
            <div className="caption hidden-xs hidden-sm">
                <span className="fs-5">{step.title}</span>
            </div>
        </li>
    );
};

const StepNavigation = ({ steps, currentStep, onStepChange }) => {
    return (
        <ol className="step-indicator ">
            {steps.map(step => (
                <StepNavigationStep
                    key={step.id}
                    step={step}
                    currentStep={currentStep}
                    onStepChange={onStepChange}
                />
            ))}
        </ol>
    );
};



const ProjectResourcePlanIndex = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const token = secureLocalStorage.getItem('auth_token')
    const [projectDetail, setProjectDetail] = useState({})
    const [allStaff, setAllStaff] = useState([])
    const [approveStatus, setApproveStatus] = useState('')
    const params = useParams();
    const steps = [
        { id: 1, title: "Resource Selection", icon_class: "bi bi-1-circle-fill" },
        { id: 2, title: "Resource Planning", icon_class: "bi bi-2-circle-fill" },
        { id: 3, title: "Let's Start", icon_class: "bi bi-3-circle-fill" },
    ]
    const stepChanged = step => {
        setCurrentStep(step)
    }
    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    const onViewPorject = async obj => {
        const res = await fetchData(toggleLoading, `getProjectDetailsById/${params.id}`, token)
        if (res?.success) {
            setApproveStatus(res?.data?.send_for_approve)
            setProjectDetail(res?.data?.details[0])
            setAllStaff(res?.data?.developers?.map((item) => ({
                value: item?.id,
                label: item?.alias_name
            })))
        }
    }
    useEffect(() => {
        onViewPorject()
    }, [])
    const onCommonChange = async (type) => {
        const response = await fetchData(
            toggleLoading,
            `sendTasksForApprove/${params?.id}/${type}`,
            secureLocalStorage?.getItem("auth_token")
        )
        return response?.success
    }

    const onSendforRole = async (type) => {
        Swal.fire({
            title: "Are you sure?",
            text: `${type == 1 ? 'You Want to Send for Approval ?' : 'You want to start the project ?'}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, send it!",
            cancelButtonText: "No, cancel!",
        }).then(async result => {
            if (result.isConfirmed) {
                const res = await onCommonChange(type);
                if (res) {
                    Swal.fire({
                        icon: 'success',
                        text: `${type == 1 ? 'Project sent for Approval successfully' : 'Project start Successfully'} `
                    }).then(() => {
                        if (type == 2) {
                            navigate(`${configure.appUrl}work/projects`)
                        } else {
                            onViewPorject()
                        }
                    })
                }
            }
        })
    }

    const [eligible, setEligible] = useState('')
    const onNextStep = () => {
        if (currentStep == 1) {
            if (eligible == 0) {
                setCurrentStep(2)
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please Assigne Members for All Allocation Type',
                })
            }
        } else if (currentStep == 2) {
            setCurrentStep(3)
        }
    }
    const resourceLength = (length) => {
        setEligible(length || 0)
    }


    return (
        <React.Fragment>
            {loading && (<Loader />)}
            <div className="page-content mb-4">
                <Container fluid>
                    <Breadcrumb title="Work" breadcrumbItem="Project Plan" />
                    <Row>
                        <Col lg={12} className="p-0">
                            <Card className="mb-3">
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center wizard'>
                                        <h5 className="fw-bold text-purple me-4">
                                            {projectDetail?.project_name}
                                        </h5>
                                        <h5 className='mb-0 fw-bold  d-flex justify-content-start align-items-center gap-3 f-15'>
                                            <span className='text-purple'>Total Hours: {projectDetail?.total_hrs}</span>
                                            <span className=''>|</span>
                                            <span className='text-success'>Start Date:  {moment(projectDetail?.startdate).format('DD-MM-YYYY')}</span>
                                            <span className=''>-</span>
                                            <span className='text-danger'>End Date: {moment(projectDetail?.enddate).format('DD-MM-YYYY')}</span>
                                        </h5>

                                        <div className='col-4 d-flex gap-2 justify-content-end align-items-center'>
                                            {currentStep == 3 ? (
                                                <>
                                                    {approveStatus == 0 ? (
                                                        <button type="submit" className=" btn ms-md-2 btn-success btn-label" onClick={() => onSendforRole(1)}>
                                                            <i className="fas fa-check-circle  me-1 label-icon"></i>
                                                            Submit for Approval
                                                        </button>
                                                    ) : null}

                                                    {(localStorage.getItem('auth_id') == 1 || localStorage.getItem('auth_id') == 4 || localStorage.getItem('auth_id') == 46) && approveStatus == 1 ? (
                                                        <button type="submit" className=" btn ms-md-2 btn-success btn-label" onClick={() => onSendforRole(2)}>
                                                            <i className="fas fa-check-circle  me-1 label-icon"></i>
                                                            Let's Start
                                                        </button>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <button type="submit" className=" btn ms-md-2 btn-save btn-label" onClick={() => onNextStep()}>
                                                    <i className="fas fa-save me-1 label-icon"></i>
                                                    Save & Next
                                                </button>
                                            )}


                                            <a onClick={() => navigate(-1)} className=" btn ms-md-2 btn-red btn-label">
                                                <i className='dripicons-cross me-2 icon-center label-icon'></i>CANCEL
                                            </a>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <StepNavigation steps={steps} currentStep={currentStep} onStepChange={stepChanged} />
                                    </div>
                                </CardBody>
                            </Card>
                            {currentStep == 1 ? (
                                <ResourceSelection projectDetail={projectDetail} allStaff={allStaff} currentStep={currentStep}
                                    resourceLength={resourceLength} />
                            ) : null}
                            {currentStep == 2 ? (
                                <ResourcePlanning projectDetail={projectDetail} currentStep={currentStep} />
                            ) : null}
                            {currentStep == 3 ? (
                                <LetsStart projectDetail={projectDetail} approveStatus={approveStatus} />
                            ) : null}

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProjectResourcePlanIndex
