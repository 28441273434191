import React, { useEffect, useState } from "react";
import { Container, Table } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Form } from "reactstrap";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "pages/Separate/Loader";
import ReactSelect from "react-select";
import secureLocalStorage from "react-secure-storage";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import { developmentProjectType, ResourceType } from "utils/common-options";
import Swal from "sweetalert2";
import configure from "configure";

const BasicProjectDetails = ({ verticalActiveTab, projectDetails }) => {
  //   All useState
  const [locationOption, setLocationOption] = useState([]);
  const [sourceOption, setSourceOption] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const userId = localStorage.getItem("auth_id");
  const token = secureLocalStorage.getItem("auth_token");
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([]);
  const [contractOption, setContractOption] = useState([]);

  const handleDBDateChange = (e) => {
    const newDBDate = e.target.value;
    if (endDate && new Date(newDBDate) < new Date(endDate)) {
      setDeliveryDate(newDBDate);
    } else {
      setDeliveryDate(newDBDate);
    }
  };
  // Api Integration
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([]);
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([]);
  const [projectScrumOptions, setProjectScrumOptions] = useState([]);
  const [projectMembersOptions, setProjectMembersOptions] = useState([]);

  // Basic Details
  const [location, setLocation] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState([]);
  const [projectSource, setProjectSource] = useState([]);
  const [projectReference, setProjectReference] = useState([]);
  const [projectResourceType, setProjectResourceType] = useState([]);
  const [projectCustomer, setProjectCustomer] = useState([]);
  const [projectIndustry, setProjectIndustry] = useState([]);
  const [projectDescription, setProjectDescription] = useState("");
  const [contract, setContract] = useState([]);

  // Member variables
  const [projectKeyManager, setProjectKeyManager] = useState([]);
  const [projectScrumMaster, setProjectScrumMaster] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [projectEstDays, setProjectEstDays] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [projectMembers, setProjectMembers] = useState([]);
  const [projectTotalHours, setProjectTotalHours] = useState("");
  const [hoursPerWeek, setHoursPerWeek] = useState("");

  // Hours Allocation
  const [kamHours, setKamHours] = useState(0);
  const [frontHours, setFrontHours] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [testHours, setTestHours] = useState(0);
  const [deployHours, setDeployHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const SumTotalHours =
    Number(kamHours) +
    Number(frontHours) +
    Number(endHours) +
    Number(testHours) +
    Number(deployHours);
  const isValid = totalHours >= SumTotalHours;

  useEffect(() => {
    if (startDate && projectEstDays) {
      const startDates = new Date(startDate);
      const numDays = parseInt(projectEstDays, 10);
      const endDate = addBusinessDays(startDates, numDays);
      setEndDate(endDate.toISOString().split("T")[0]);
    } else {
      setEndDate("");
    }
  }, [startDate, projectEstDays]);

  function addBusinessDays(startDate, numDays) {
    const resultDate = new Date(startDate);
    let daysAdded = 0;

    while (daysAdded < numDays) {
      resultDate.setDate(resultDate.getDate() + 1);
      const dayOfWeek = resultDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }
    return resultDate;
  }

  const toggleLoading = (loading) => {
    setLoading(loading);
  };

  const fetchProjectData = async () => {
    const res = await fetchData(toggleLoading, "projectRelatedData", token);
    if (res?.success) {
      const { data } = res;
      const {
        location,
        source,
        customer,
        introducer,
        industry,
        keymanager,
        scrum,
        developers,
        contract,
      } = data;
      const createOptions = (items, label, value) =>
        items?.map((item) => ({ label: item?.[label], value: item?.[value] }));

      setLocationOption(createOptions(location, "location", "id"));
      const sources = source?.map((obj) => ({
        value: obj?.id,
        label: obj?.leadname,
        customerOption: createOptions(customer, "company", "id"),
        introducerOption: createOptions(introducer, "company", "id"),
      }));
      setSourceOption(sources);
      setKamHours(projectDetails?.planning);
      setFrontHours(projectDetails?.designing);
      setEndHours(projectDetails?.development);
      setTestHours(projectDetails?.testing);
      setDeployHours(projectDetails?.deployment);
      setCustomerOptions(createOptions(customer, "company", "id"));
      setAllKeyIndustryOption(createOptions(industry, "name", "id"));
      setProjectKeyManagerOptions(createOptions(keymanager, "name", "id"));
      setProjectScrumOptions(createOptions(scrum, "name", "id"));
      setProjectMembersOptions(createOptions(developers, "name", "id"));
      setProjectName(projectDetails?.project_name);
      setLocation(
        createOptions(location, "location", "id")?.find(
          (obj) => obj?.value == projectDetails?.projectbelogsto
        )
      );
      setProjectType(
        developmentProjectType?.find(
          (obj) => obj?.value == projectDetails?.project_type
        )
      );
      const projecrSrc = sources?.find(
        (obj) => obj?.value == projectDetails?.source
      );
      setProjectSource(projecrSrc);
      const SourceRefer =
        projecrSrc?.label?.toLowerCase() == "customer"
          ? projecrSrc?.customerOption
          : projecrSrc?.label?.toLowerCase() == "introducer"
          ? projecrSrc?.introducerOption
          : [];
      setProjectReferenceOptions(SourceRefer);
      setProjectReference(
        SourceRefer?.find((obj) => obj?.value == projectDetails?.source_name)
      );
      setProjectResourceType(
        ResourceType?.find(
          (obj) => obj?.value == projectDetails?.billingtype
        )
      );
      setProjectCustomer(
        createOptions(customer, "company", "id")?.find(
          (obj) => obj?.value == projectDetails?.customer
        )
      );
      setProjectIndustry(
        createOptions(industry, "name", "id")?.find(
          (obj) => obj?.value == projectDetails?.industry
        )
      );
      setProjectDescription(projectDetails?.description || "");
      setProjectKeyManager(
        createOptions(keymanager, "name", "id")?.find(
          (obj) => obj?.value == projectDetails?.keyaccountmanager
        )
      );
      setProjectScrumMaster(
        createOptions(scrum, "name", "id")?.find(
          (obj) => obj?.value == projectDetails?.scrum_master
        )
      );
      setStartDate(projectDetails?.startdate);
      setEndDate(projectDetails?.enddate);
      setProjectEstDays(projectDetails?.no_of_weeks);
      setTotalHours(Number(projectDetails?.no_of_weeks) * 5 * 7);
      setDeliveryDate(projectDetails?.deliverydatebefore);
      setProjectMembers(
        createOptions(developers, "name", "id")?.filter((obj) =>
          projectDetails?.member?.split(",")?.includes(obj?.value?.toString())
        )
      );
      const contrat = contract?.map((obj) => ({
        value: obj?.id,
        label: `${obj?.id}-${obj?.company_name}-${obj?.service}`,
      }));
      setContractOption(contrat);
      setContract(
        contrat?.find((obj) => obj?.value == projectDetails?.contract_id)
      );
    }
  };

  const onEditProject = async (e) => {
    e.preventDefault();
    const formEntries = {
      user_id: userId,
      project_id: params?.id,
      location: location?.value || "",
      project_name: projectName,
      project_type: projectType?.value || "",
      source: projectSource?.value || "",
      source_name: projectReference?.value || "",
      resource_type: projectResourceType?.value || "",
      customer: projectCustomer?.value || "",
      industry: projectIndustry?.value || "",
      description: projectDescription,
      contract_id: contract?.value || "",

      keyaccountmanager: projectKeyManager?.value || "",
      scrum_master: projectScrumMaster?.value || "",
      member: projectMembers?.map((obj) => obj?.value)?.join(","),
      total_hrs: projectTotalHours,
      hour_per_week: hoursPerWeek,
      start_date: startDate,
      end_date: endDate,
      no_of_weeks: projectEstDays,
      deliverydatebefore: deliveryDate,

      total_hrs: totalHours,
      planning: kamHours,
      designing: frontHours,
      development: endHours,
      testing: testHours,
      deployment: deployHours,
    };
    const res = await FetchPostData(
      toggleLoading,
      "edit_project",
      token,
      formEntries
    );
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Project Edited successfully.",
      }).then(() => {
        navigate(`${configure.appUrl}work/projects`);
      });
    }
  };

  useEffect(() => {
    if (verticalActiveTab == 7) {
      fetchProjectData();
    }
  }, [verticalActiveTab]);

  useEffect(() => {
    if (startDate && projectEstDays) {
      setEndDate(calculateEndDate(startDate, projectEstDays));
    } else {
      setEndDate("");
    }
  }, [startDate, projectEstDays]);

  function calculateEndDate(startDate, numWeeks) {
    const resultDate = new Date(startDate);
    let totalBusinessDays = numWeeks * 5;
    let fullWeeks = Math.floor(totalBusinessDays / 5);
    resultDate.setDate(resultDate.getDate() + fullWeeks * 7);
    totalBusinessDays -= fullWeeks * 5;
    while (totalBusinessDays > 0) {
      resultDate.setDate(resultDate.getDate() + 1);
      const dayOfWeek = resultDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        totalBusinessDays--;
      }
    }
    return resultDate.toISOString().split("T")[0];
  }

  const calculatePercentage = (hours, totalHours) => {
    return totalHours > 0
      ? Math.floor((Number(hours) / Number(totalHours)) * 100)
      : 0;
  };

  const isBlinking = (percentage, threshold) => percentage > threshold;

  const handleHoursChange = (e, setHours, fields) => {
    const newHours = e.target.value;
    setHours(newHours);

    const total = Object.values(fields).reduce(
      (acc, hours) => acc + Number(hours),
      0
    );
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          <Form className="px-0" onSubmit={onEditProject}>
            <Row className="">
              <Row className="bg-white py-4 shadow mb-3 px-2">
                <div className="col-md-12 d-flex justify-content-between">
                  <div className="col-md-6">
                    <h5 className="mb-3 text-dark">Project Basic Details</h5>
                  </div>
                  <div className="col-md-6 mb-3 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>

                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">
                    Project Belongs To <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Project Belongs To"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={locationOption}
                    value={location}
                    onChange={(e) => setLocation(e)}
                  />
                </div>

                <div className="col-md-5 mt-2 mb-3">
                  <label htmlFor="">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Project Name"
                    className="form-control "
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mt-2 mb-3">
                  <label htmlFor="">
                    Project Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Project Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={developmentProjectType}
                    value={projectType}
                    onChange={(e) => setProjectType(e)}
                  />
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Source <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={sourceOption}
                      value={projectSource}
                      onChange={(e) => {
                        setProjectSource(e);
                        if (e.label?.toLowerCase() == "introducer") {
                          setProjectReferenceOptions(e.introducerOption);
                        } else if (e.label?.toLowerCase() == "customer") {
                          setProjectReferenceOptions(e.customerOption);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Select {projectSource?.label || ""}
                    </label>
                    <Select
                      placeholder={`Select ${projectSource?.label || ""}`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={projectReferenceOptions}
                      value={projectReference}
                      onChange={(e) => setProjectReference(e)}
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Resource Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Resource Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={ResourceType}
                      value={projectResourceType}
                      onChange={(selectedOption) =>
                        setProjectResourceType(selectedOption)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Customer <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Customer"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={customerOptions}
                      value={projectCustomer}
                      onChange={(e) => setProjectCustomer(e)}
                    />
                  </div>

                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Project Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={allKeyIndustryOption}
                      value={projectIndustry}
                      onChange={(selectedOption) =>
                        setProjectIndustry(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-5 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Select Contract <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Contract"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={contractOption}
                      value={contract}
                      onChange={(e) => setContract(e)}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={projectDescription}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setProjectDescription(data);
                    }}
                  />
                </div>
              </Row>

              {/* Project Basic Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Members</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Key Account Manager</label>
                      <Select
                        placeholder="Select KAM"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={projectKeyManagerOptions}
                        value={projectKeyManager}
                        onChange={(e) => setProjectKeyManager(e)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Scrum Master</label>
                      <Select
                        placeholder="Select Scrum Master"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={projectScrumOptions}
                        value={projectScrumMaster}
                        onChange={(selectedOption) =>
                          setProjectScrumMaster(selectedOption)
                        }
                      />
                    </div>
                    {projectType?.value == 2 ? (
                      <>
                        <div className="col-md-12 mt-2 mb-3">
                          <label htmlFor="">Members</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={projectMembersOptions}
                            isMulti="true"
                            closeMenuOnSelect={false}
                            value={projectMembers}
                            onChange={(e) => setProjectMembers(e)}
                          />
                        </div>
                        <div className="col-md-6 mt-2 mb-3">
                          <label htmlFor="">
                            Total Hours<span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="number"
                            placeholder="Enter Total Hours"
                            className="form-control "
                            value={projectTotalHours}
                            onChange={(e) =>
                              setProjectTotalHours(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6 mt-2 mb-3">
                          <label htmlFor="">
                            Hours per Week<span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="number"
                            placeholder="Enter Total Hours"
                            className="form-control "
                            value={hoursPerWeek}
                            onChange={(e) => setHoursPerWeek(e.target.value)}
                          />
                        </div>
                      </>
                    ) : null}

                    <h5 className="mb-3 mt-4">Estimatation</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Number of Weeks <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="Enter Number of Weeks"
                        className="form-control "
                        value={projectEstDays}
                        onChange={(e) => {
                          setProjectEstDays(e.target.value);
                          setTotalHours(Number(e.target.value) * 5 * 7);
                        }}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">Estimated End Date</label>
                      <input
                        disabled
                        type="date"
                        className="form-control "
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">
                        Delivery Before Date
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={deliveryDate}
                        onChange={(e) => {
                          handleDBDateChange(e);
                        }}
                      />
                    </div>
                    {endDate > deliveryDate && (
                      <div className="mt-3 text-danger">
                        The Delivery Before Date must be after the Estimated End
                        Date.
                      </div>
                    )}
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  {projectType?.value != 2 ? (
                    <Row className="bg-white px-2 py-4 shadow mb-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-3">Actual Hours Details</h5>
                      </div>
                      <div className="responsive-table">
                        <Table
                          className={`table table-bordered mb-0 mt-3 table-hover`}
                        >
                          <thead>
                            <tr>
                              <th>Allocation Name</th>
                              <th>Approx %</th>
                              <th>Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-light-purple">
                              <td className="col-6">
                                <strong className="text-purple fs-5">
                                  Total Hours
                                </strong>
                              </td>
                              <td className="col-1 text-center">100 %</td>
                              <td className="col-2">
                                <div className={`${isValid ? "" : "blinking"}`}>
                                  <input
                                    required
                                    type="number"
                                    placeholder="Hours.."
                                    className="form-control"
                                    name="estimatedtime"
                                    value={totalHours}
                                    disabled
                                  />
                                </div>
                              </td>
                            </tr>
                            {[
                              {
                                name: "Planning",
                                label: projectKeyManager?.label,
                                value: kamHours,
                                setValue: setKamHours,
                                threshold: 10,
                                key: "kamHours",
                              },
                              {
                                name: "Designing",
                                value: frontHours,
                                setValue: setFrontHours,
                                threshold: 50,
                                key: "frontHours",
                              },
                              {
                                name: "Development",
                                value: endHours,
                                setValue: setEndHours,
                                threshold: 70,
                                key: "endHours",
                              },
                              {
                                name: "Testing",
                                value: testHours,
                                setValue: setTestHours,
                                threshold: 25,
                                key: "testHours",
                              },
                              {
                                name: "Deployment",
                                value: deployHours,
                                setValue: setDeployHours,
                                threshold: 10,
                                key: "deployHours",
                              },
                            ].map(
                              ({
                                name,
                                label,
                                value,
                                setValue,
                                threshold,
                                key,
                              }) => {
                                const percentage = calculatePercentage(
                                  value,
                                  totalHours
                                );
                                return (
                                  <tr
                                    key={key}
                                    className={
                                      isBlinking(percentage, threshold)
                                        ? "blinking-background"
                                        : ""
                                    }
                                  >
                                    <td className="col-6">
                                      {name}
                                      {label && ` (${label})`}
                                    </td>
                                    <td className="col-1 text-center">
                                      {percentage} %
                                    </td>
                                    <td className="col-2">
                                      <input
                                        required
                                        type="number"
                                        placeholder="Hours.."
                                        className="form-control"
                                        name="estimatedtime"
                                        value={value}
                                        onChange={(e) =>
                                          handleHoursChange(e, setValue, {
                                            kamHours,
                                            frontHours,
                                            endHours,
                                            testHours,
                                            deployHours,
                                          })
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  ) : null}
                </div>
              </Row>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BasicProjectDetails;
