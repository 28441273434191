import React, { useEffect, useState } from "react"
import { Container, Table, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import {
  colourStyles,
  developmentProjectType,
  ResourceType,
} from "utils/common-options"
import { fetchData, FetchPostData } from "utils/common-fetchData"

const AddProject = () => {
  const token = secureLocalStorage.getItem("auth_token")
  const userId = localStorage.getItem("auth_id")
  const [isLoading, setLoading] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)
  // All Options
  const [locationOption, setLocationOption] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])
  const [detailsFromCred2, setDetailsFromCred2] = useState(null)
  const [allZtplCredOption, setAllZtplCredOption] = useState([])
  const [all192CredOption, setAll192CredOption] = useState([])
  const [sourceOption, setSourceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [projectScrumOptions, setProjectScrumOptions] = useState([])
  const [projectMembersOptions, setProjectMembersOptions] = useState([])
  const [projectFromGitOption, setProjectFromGitOption] = useState([])
  const [contractOption, setContractOption] = useState([])

  // Hours Allocation
  const [kamHours, setKamHours] = useState(0)
  const [frontHours, setFrontHours] = useState(0)
  const [endHours, setEndHours] = useState(0)
  const [testHours, setTestHours] = useState(0)
  const [deployHours, setDeployHours] = useState(0)
  const [totalHours, setTotalHours] = useState(0)
  const toggleLoading = loading => {
    setLoading(loading)
  }

  // Basic Details
  const [location, setLocation] = useState([])
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState([])
  const [projectSource, setProjectSource] = useState([])
  const [projectReference, setProjectReference] = useState([])
  const [projectResourceType, setProjectResourceType] = useState([])
  const [projectCustomer, setProjectCustomer] = useState([])
  const [projectIndustry, setProjectIndustry] = useState([])
  const [projectDescription, setProjectDescription] = useState("")
  const [contract, setContract] = useState([])

  // Member variables
  const [projectKeyManager, setProjectKeyManager] = useState([])
  const [projectScrumMaster, setProjectScrumMaster] = useState([])
  const [startDate, setStartDate] = useState("")
  const [projectEstDays, setProjectEstDays] = useState("")
  const [endDate, setEndDate] = useState("")
  const [deliveryDate, setDeliveryDate] = useState("")
  const [projectMembers, setProjectMembers] = useState([])
  const [projectTotalHours, setProjectTotalHours] = useState("")
  const [hoursPerWeek, setHoursPerWeek] = useState("")

  // Server Details
  const [serverFolderName, setServerFolderName] = useState("")
  const [server192Link, setServer192Link] = useState("")
  const [serverZTPLLink, setServerZTPLLink] = useState("")

  // Git Details
  const [custType, setCustType] = useState("")
  const [gitProject, setGitProject] = useState("New")
  const [projectFromGit, setProjectFromGit] = useState([])
  const [projectFromGitName, setProjectFromGitName] = useState("")
  const [gitRepositoryLink, setGitRepositoryLink] = useState("")
  const [gitDemoLink, setGitDemoLink] = useState("")
  const [gitIcodeLink, setGitIcodeLink] = useState("")

  // ZTPL details
  const [detailsFromCred, setDetailsFromCred] = useState("")
  const [ztplDetails, setZtplDetails] = useState("New")
  const [zhost, setZhost] = useState("ftp.ztpl.net")
  const [zusername, setZusername] = useState("")
  const [zpassword, setZpassword] = useState("")
  const [zdatabasename, setZdatabasename] = useState("")
  const [zdbusername, setZdbusername] = useState("")
  const [zdbpassword, setZdbpassword] = useState("")

  // 192 Details
  const [localServer, setLocalServer] = useState("New")
  const [host, setHost] = useState("192.168.0.2")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [databasename, setDatabasename] = useState("")
  const [dbusername, setDbusername] = useState("")
  const [dbpassword, setDbpassword] = useState("")

  const SumTotalHours =
    Number(kamHours) +
    Number(frontHours) +
    Number(endHours) +
    Number(testHours) +
    Number(deployHours)
  const isValid = totalHours >= SumTotalHours

  // For Client information
  const navigate = useNavigate()

  document.title = "Add Project | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 360) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const handleNew192 = e => {
    setLocalServer(e.target.value)
    setDetailsFromCred2(null)
    setHost("192.168.0.2")
    setUsername("")
    setPassword("")
    setDatabasename("")
    setDbusername("")
    setDbpassword("")
  }

  const handleNewZtpl = e => {
    setZtplDetails(e.target.value)
    setDetailsFromCred(null)
    setZhost("192.168.0.2")
    setZusername("")
    setZpassword("")
    setZdatabasename("")
    setZdbusername("")
    setZdbpassword("")
  }

  const handleZtplDfc = e => {
    setDetailsFromCred(e)
    setZusername(e.FTPUser ? e.FTPUser : "")
    setZpassword(e.FTPPassword ? e.FTPPassword : "")
    setZdatabasename(e.DBName ? e.DBName : "")
    setZdbusername(e.DBUsername ? e.DBUsername : "")
    setZdbpassword(e.DBPassword ? e.DBPassword : "")
  }

  const handle192Dfc = e => {
    setDetailsFromCred2(e)
    setUsername(e.FTPUser ? e.FTPUser : "")
    setPassword(e.FTPPassword ? e.FTPPassword : "")
    setDatabasename(e.DBName ? e.DBName : "")
    setDbusername(e.DBUsername ? e.DBUsername : "")
    setDbpassword(e.DBPassword ? e.DBPassword : "")
  }

  useEffect(() => {
    if (startDate && projectEstDays) {
      setEndDate(calculateEndDate(startDate, projectEstDays))
    } else {
      setEndDate("")
    }
  }, [startDate, projectEstDays])

  function calculateEndDate(startDate, numWeeks) {
    const resultDate = new Date(startDate)
    let totalBusinessDays = numWeeks * 5
    let fullWeeks = Math.floor(totalBusinessDays / 5)
    resultDate.setDate(resultDate.getDate() + fullWeeks * 7)
    totalBusinessDays -= fullWeeks * 5
    while (totalBusinessDays > 0) {
      resultDate.setDate(resultDate.getDate() + 1)
      const dayOfWeek = resultDate.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        totalBusinessDays--
      }
    }
    return resultDate.toISOString().split("T")[0]
  }

  const fetchProjectData = async () => {
    const res = await fetchData(toggleLoading, "projectRelatedData", token)
    if (res?.success) {
      const { data } = res
      const {
        location,
        source,
        customer,
        introducer,
        industry,
        keymanager,
        scrum,
        projects,
        ztpl,
        data_192,
        developers,
        contract,
      } = data
      const createOptions = (items, label, value) =>
        items?.map(item => ({ label: item?.[label], value: item?.[value] }))

      setLocationOption(createOptions(location, "location", "id"))
      setSourceOption(
        source?.map(obj => ({
          value: obj?.id,
          label: obj?.leadname,
          customerOption: createOptions(customer, "company", "id"),
          introducerOption: createOptions(introducer, "company", "id"),
        }))
      )
      setCustomerOptions(createOptions(customer, "company", "id"))
      setAllKeyIndustryOption(createOptions(industry, "name", "id"))
      setProjectKeyManagerOptions(createOptions(keymanager, "name", "id"))
      setProjectScrumOptions(createOptions(scrum, "name", "id"))
      setProjectFromGitOption(createOptions(projects, "project_name", "id"))
      setAllZtplCredOption(createOptions(ztpl, "Project", "id"))
      setAll192CredOption(createOptions(data_192, "Project", "id"))
      setProjectMembersOptions(createOptions(developers, "name", "id"))
      setContractOption(
        contract?.map(obj => ({
          value: obj?.id,
          label: `${obj?.id}-${obj?.company_name}-${obj?.service}`,
        }))
      )
    }
  }

  useEffect(() => {
    fetchProjectData()
  }, [])

  const onAddProject = async e => {
    e.preventDefault();
    if(location?.length != 0 && projectType?.length !=0 && projectResourceType?.length != 0 && projectCustomer?.length != 0 && projectIndustry?.length != 0 && contract?.length !=0){}
    const formEntries = {
      user_id: userId,
      location: location?.value || "",
      project_name: projectName,
      project_type: projectType?.value || "",
      source: projectSource?.value || "",
      source_name: projectReference?.value || "",
      resource_type: projectResourceType?.value || "",
      customer: projectCustomer?.value || "",
      industry: projectIndustry?.value || "",
      description: projectDescription,
      contract_id:contract?.value || "",

      keyaccountmanager: projectKeyManager?.value || "",
      scrum_master: projectScrumMaster?.value || "",
      member: projectMembers?.map(obj => obj?.value)?.join(","),
      total_hrs: projectTotalHours,
      hour_per_week: hoursPerWeek,
      start_date: startDate,
      end_date: endDate,
      no_of_weeks: projectEstDays,
      deliverydatebefore: deliveryDate,

      folder_name: serverFolderName,
      ztpl_link: serverZTPLLink,
      link_192: server192Link,

      gitrepositorylink: gitRepositoryLink,
      demo_link: gitDemoLink,
      icode_link: gitIcodeLink,
      git_project_id: projectFromGit?.value || "",

      zhost: zhost,
      zusername: zusername,
      zpassword: zpassword,
      zdatabasename: zdatabasename,
      zdbusername: zdbusername,
      zdbpassword: zdbpassword,

      host: host,
      username: username,
      password: password,
      databasename: databasename,
      dbusername: dbusername,
      dbpassword: dbpassword,
      ztpl_details: ztplDetails,
      details_192: localServer,

      total_hrs: totalHours,
      planning: kamHours,
      designing: frontHours,
      development: endHours,
      testing: testHours,
      deployment: deployHours,
    }
    const res = await FetchPostData(
      toggleLoading,
      "add_project",
      token,
      formEntries
    )
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Project Added successfully.",
      }).then(() => {
        navigate(`${configure.appUrl}work/projects`)
      })
    }
  }

  const calculatePercentage = (hours, totalHours) => {
    return totalHours > 0
      ? Math.floor((Number(hours) / Number(totalHours)) * 100)
      : 0
  }

  const isBlinking = (percentage, threshold) => percentage > threshold

  const handleHoursChange = (e, setHours, fields) => {
    const newHours = e.target.value
    setHours(newHours)

    const total = Object.values(fields).reduce(
      (acc, hours) => acc + Number(hours),
      0
    )
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Form className="px-0" onSubmit={onAddProject}>
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              <Row className="bg-white py-4 shadow mb-3 px-2">
                <div className="col-md-12 d-flex justify-content-end">
                  <div className="col-md-6 mb-3 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        navigate(-1)
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>
                <h5 className="mb-3">Project Basic Details</h5>
                <div className="col-md-2 mt-2 mb-3">
                  <label htmlFor="">
                    Project Belongs To <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Project Belongs To"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={locationOption}
                    value={location}
                    onChange={e => setLocation(e)}
                  />
                </div>

                <div className="col-md-6 mt-2 mb-3">
                  <label htmlFor="">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Project Name"
                    className="form-control "
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mt-2 mb-3">
                  <label htmlFor="">
                    Project Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Project Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={developmentProjectType}
                    value={projectType}
                    onChange={selectedOption => setProjectType(selectedOption)}
                  />
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Source 
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={sourceOption}
                      value={projectSource}
                      onChange={e => {
                        setProjectSource(e)
                        if (e.label?.toLowerCase() == "introducer") {
                          setProjectReferenceOptions(e.introducerOption)
                        } else if (e.label?.toLowerCase() == "customer") {
                          setProjectReferenceOptions(e.customerOption)
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Select {projectSource?.label || ""}
                    </label>
                    <Select
                      placeholder={`Select ${projectSource?.label || ""}`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={projectReferenceOptions}
                      value={projectReference}
                      onChange={selectedOption =>
                        setProjectReference(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Resource Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Resource Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={ResourceType}
                      value={projectResourceType}
                      onChange={selectedOption =>
                        setProjectResourceType(selectedOption)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Customer <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Customer"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={customerOptions}
                      value={projectCustomer}
                      onChange={selectedOption =>
                        setProjectCustomer(selectedOption)
                      }
                    />
                  </div>

                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Project Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={allKeyIndustryOption}
                      value={projectIndustry}
                      onChange={selectedOption =>
                        setProjectIndustry(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Select Contract <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Contract"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={contractOption}
                      value={contract}
                      onChange={e => setContract(e)}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setProjectDescription(data)
                    }}
                  />
                </div>
              </Row>

              {/* Project Basic Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Members</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Key Account Manager</label>
                      <Select
                        placeholder="Select Key Account Manager"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={projectKeyManagerOptions}
                        value={projectKeyManager}
                        onChange={e => setProjectKeyManager(e)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Scrum Master</label>
                      <Select
                        placeholder="Select Scrum Master"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={projectScrumOptions}
                        value={projectScrumMaster}
                        onChange={e => setProjectScrumMaster(e)}
                      />
                    </div>
                    {projectType?.value == 2 ? (
                      <>
                        <div className="col-md-12 mt-2 mb-3">
                          <label htmlFor="">Members</label>
                          <ReactSelect
                            placeholder="Select Members"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={projectMembersOptions}
                            isMulti="true"
                            closeMenuOnSelect={false}
                            value={projectMembers}
                            onChange={e => setProjectMembers(e)}
                          />
                        </div>
                        <div className="col-md-6 mt-2 mb-3">
                          <label htmlFor="">
                            Total Hours<span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="number"
                            placeholder="Enter Total Hours"
                            className="form-control "
                            value={projectTotalHours}
                            onChange={e => setProjectTotalHours(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 mt-2 mb-3">
                          <label htmlFor="">
                            Hours per Week<span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            type="number"
                            placeholder="Enter Total Hours"
                            className="form-control "
                            value={hoursPerWeek}
                            onChange={e => setHoursPerWeek(e.target.value)}
                          />
                        </div>
                      </>
                    ) : null}

                    <h5 className="mb-3 mt-4">Estimatation</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Number Of Weeks<span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="number"
                        placeholder="Enter Number Of Weeks"
                        className="form-control "
                        value={projectEstDays}
                        onChange={e => {
                          setProjectEstDays(e.target.value)
                          setTotalHours(Number(e.target.value) * 5 * 7)
                        }}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 pb-5">
                      <label htmlFor="">Estimated End Date</label>
                      <input
                        disabled
                        type="date"
                        className="form-control "
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 pb-5">
                      <label htmlFor="">
                        Delivery Before Date
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={deliveryDate}
                        onChange={e => {
                          const newDBDate = e.target.value
                          if (
                            endDate &&
                            new Date(newDBDate) < new Date(endDate)
                          ) {
                            Swal.fire({
                              icon: "warning",
                              title: "Warning",
                              text: "The Delivery Before Date cannot be before the Estimated End Date.",
                            })
                          } else {
                            setDeliveryDate(newDBDate)
                          }
                        }}
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  {projectType?.value != 2 ? (
                    <Row className="bg-white px-2 py-4 shadow mb-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-3">Actual Hours Details</h5>
                      </div>
                      <div className="responsive-table">
                        <Table
                          className={`table table-bordered mb-0 mt-3 table-hover`}
                        >
                          <thead>
                            <tr>
                              <th>Allocation Name</th>
                              <th>Approx %</th>
                              <th>Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-light-purple">
                              <td className="col-6">
                                <strong className="text-purple fs-5">
                                  Total Hours
                                </strong>
                              </td>
                              <td className="col-1 text-center">100 %</td>
                              <td className="col-2">
                                <div className={`${isValid ? "" : "blinking"}`}>
                                  <input
                                    required
                                    type="number"
                                    placeholder="Hours.."
                                    className="form-control"
                                    name="estimatedtime"
                                    value={totalHours}
                                    disabled
                                  />
                                </div>
                              </td>
                            </tr>
                            {[
                              {
                                name: "Planning",
                                label: projectKeyManager?.label,
                                value: kamHours,
                                setValue: setKamHours,
                                threshold: 10,
                                key: "kamHours",
                              },
                              {
                                name: "Designing",
                                value: frontHours,
                                setValue: setFrontHours,
                                threshold: 50,
                                key: "frontHours",
                              },
                              {
                                name: "Development",
                                value: endHours,
                                setValue: setEndHours,
                                threshold: 70,
                                key: "endHours",
                              },
                              {
                                name: "Testing",
                                value: testHours,
                                setValue: setTestHours,
                                threshold: 25,
                                key: "testHours",
                              },
                              {
                                name: "Deployment",
                                value: deployHours,
                                setValue: setDeployHours,
                                threshold: 10,
                                key: "deployHours",
                              },
                            ].map(
                              ({
                                name,
                                label,
                                value,
                                setValue,
                                threshold,
                                key,
                              }) => {
                                const percentage = calculatePercentage(
                                  value,
                                  totalHours
                                )
                                return (
                                  <tr
                                    key={key}
                                    className={
                                      isBlinking(percentage, threshold)
                                        ? "blinking-background"
                                        : ""
                                    }
                                  >
                                    <td className="col-6">
                                      {name}
                                      {label && ` (${label})`}
                                    </td>
                                    <td className="col-1 text-center">
                                      {percentage} %
                                    </td>
                                    <td className="col-2">
                                      <input
                                        required
                                        type="number"
                                        placeholder="Hours.."
                                        className="form-control"
                                        name="estimatedtime"
                                        value={value}
                                        onChange={e =>
                                          handleHoursChange(e, setValue, {
                                            kamHours,
                                            frontHours,
                                            endHours,
                                            testHours,
                                            deployHours,
                                          })
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  ) : null}
                </div>
              </Row>

              {/* // Git and Server Details Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">
                      Git Details (if yes then it will update on git)
                    </h5>
                    <div className="col-12 mb-3 mb-md-0">
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitYes"
                          onInput={e => {
                            setCustType("Yes")
                            setGitProject("New")
                            setProjectFromGit(null)
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="Yes"
                        />
                        <label htmlFor="gitYes" className="ms-2">
                          Yes
                        </label>
                      </span>
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitNo"
                          defaultChecked={true}
                          onInput={e => {
                            setProjectFromGitName("")
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="No"
                        />
                        <label htmlFor="gitNo" className="ms-2">
                          No
                        </label>
                      </span>
                    </div>
                    {custType === "Yes" ? (
                      <div className="col-12 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            type="radio"
                            id="newCheck"
                            defaultChecked={true}
                            onInput={e => {
                              setProjectFromGit(null)
                              setGitProject(e.target.value)
                            }}
                            name="type1"
                            value="New"
                          />
                          <label htmlFor="newCheck" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => {
                              setProjectFromGitName("")
                              setGitProject(e.target.value)
                            }}
                            id="existing"
                            name="type1"
                            value="Existing"
                          />
                          <label htmlFor="existing" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {custType === "Yes" && gitProject === "New" ? (
                      <div className="col-md-6 mt-2 mb-3 mb-md-0">
                        <label htmlFor="">
                          Project Name (for git){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Project Name"
                          className="form-control "
                          value={projectFromGitName}
                          onChange={e => setProjectFromGitName(e.target.value)}
                          disabled={gitProject == "New"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {custType === "Yes" && gitProject === "Existing" && (
                      <div className="col-md-6 mt-2 mb-3 mb-md-0">
                        <Label htmlFor="formrow-firstname-Input">
                          Project from Git{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Project"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={projectFromGitOption}
                          value={projectFromGit}
                          onChange={e => {
                            setProjectFromGit(e)
                            setGitRepositoryLink(e.gitreposatirylink)
                          }}
                        />
                      </div>
                    )}

                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Links</h5>
                      <div className="col-md-12 d-flex flex-column gap-3">
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Git Repository Link</label>
                          <input
                            type="text"
                            placeholder="Git Repository Link"
                            className="form-control "
                            value={gitRepositoryLink}
                            onChange={e => setGitRepositoryLink(e.target.value)}
                            disabled={gitProject == "New"}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Demo Link</label>
                          <input
                            type="text"
                            placeholder="Enter Demo Link"
                            className="form-control "
                            value={gitDemoLink}
                            onChange={e => setGitDemoLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Icode Link</label>
                          <input
                            type="text"
                            placeholder="Enter Icode Link"
                            className="form-control "
                            value={gitIcodeLink}
                            onChange={e => setGitIcodeLink(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3 pb-4">
                  <Row className="bg-white d-flex flex-column gap-4 px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-space-between align-items-end">
                      <div className="col-md-12 mt-2  mb-md-0 d-flex justify-content-start align-items-start">
                        <h5 className="mb-3">Server Details</h5>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        Enter Folder Name for creating folder on 192 & ZTPL{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Folder Name"
                        className="form-control "
                        value={serverFolderName}
                        onChange={e => {
                          setServerFolderName(e.target.value)
                          setServer192Link(
                            `http://192.168.0.2/${e.target.value}`
                          )
                          setServerZTPLLink(
                            `https://ztpl.net/${e.target.value}`
                          )
                        }}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        192 server Link <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="192 server Link"
                        className="form-control"
                        disabled
                        value={server192Link}
                        onChange={e => setServer192Link(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        ZTPL Link <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="ZTPL Link"
                        className="form-control"
                        disabled
                        value={serverZTPLLink}
                        onChange={e => setServerZTPLLink(e.target.value)}
                      />
                    </div>{" "}
                  </Row>
                </div>
              </Row>

              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">ZTPL Details</h5>
                    <div className="col-md-12 mb-2 d-flex">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            defaultChecked={true}
                            type="radio"
                            id="new"
                            onInput={e => {
                              handleNewZtpl(e)
                            }}
                            name="ztpl"
                            value="New"
                          />
                          <label htmlFor="new" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => setZtplDetails(e.target.value)}
                            id="existing2"
                            name="ztpl"
                            value="existing"
                          />
                          <label htmlFor="existing2" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>

                      {ztplDetails === "existing" && (
                        <div className="col-md-6 mb-md-0">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="">
                              Details from credentials{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <Select
                              placeholder="Select Details"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              styles={colourStyles}
                              options={allZtplCredOption}
                              value={detailsFromCred}
                              onChange={e => handleZtplDfc(e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 border-end">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Host Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Enter Host Link"
                          className="form-control "
                          value={zhost}
                          onChange={e => setZhost(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                      {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zusername}
                          onChange={e => setZusername(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={zpassword}
                          onChange={e => setZpassword(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={zdatabasename}
                          onChange={e => setZdatabasename(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zdbusername}
                          onChange={e => setZdbusername(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={zdbpassword}
                          onChange={e => setZdbpassword(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">192 Details</h5>
                    <div className="col-md-12 mb-2 d-flex">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            type="radio"
                            id="new1"
                            defaultChecked={true}
                            onInput={e => {
                              handleNew192(e)
                            }}
                            name="local"
                            value="New"
                          />
                          <label htmlFor="new1" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => setLocalServer(e.target.value)}
                            id="existing3"
                            name="local"
                            value="existing"
                          />
                          <label htmlFor="existing3" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>

                      {localServer === "existing" && (
                        <div className="col-md-6 mb-md-0">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="">
                              Details from credentials{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <Select
                              placeholder="Select Details"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              styles={colourStyles}
                              options={all192CredOption}
                              value={detailsFromCred2}
                              onChange={selectedOption =>
                                handle192Dfc(selectedOption)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 border-end">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Host Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Enter Host Link"
                          className="form-control "
                          value={host}
                          onChange={e => setHost(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                      {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={databasename}
                          onChange={e => setDatabasename(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={dbusername}
                          onChange={e => setDbusername(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={dbpassword}
                          onChange={e => setDbpassword(e.target.value)}
                          disabled={localServer == "New"}
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProject
