import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ProjectView from "pages/Gantt Chart/ProjectView"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"

const ProjectOverviews = () => {
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const [projectOption, setProjectOption] = useState([])
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const fetchProjects = async () => {
      const userId = localStorage.getItem("auth_id")
      const res = await fetchData(
        toggleLoading,
        `allProjectsByUserId/${userId}`,
        token
      )
      if (res?.success) {
        setProjectOption(
          res?.data?.project2?.map(obj => ({
            label: obj?.project_name,
            value: obj?.id,
          }))
        )
      }
    }
    fetchProjects()
  }, [])
  return (
    <div>
      {isLoading && <Loader />}
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="mb-3 text-purple fs-5  fw-bold">
                  Project Overview
                </div>
                <ProjectView
                  handleClose={false}
                  projectOptions={projectOption}
                  projectId={[]}
                  projectView={true}
                  element={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </div>
  )
}

export default ProjectOverviews
