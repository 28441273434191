import React, { useEffect, useState } from "react"
import DeveloperTickets from "./DeveloperTickets"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"

const TimerTickets = () => {
  const [projectOption, setProjectOption] = useState([])
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const fetchProjects = async () => {
    const userId = localStorage.getItem("auth_id")
    const res = await fetchData(
      toggleLoading,
      `allProjectsByUserId/${userId}`,
      token
    )
    if (res?.success) {
      setProjectOption(
        res?.data?.project2?.map(obj => ({
          label: obj?.project_name,
          value: obj?.id,
        }))
      )
    }
  }
  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <div>
      {isLoading && <Loader />}
      <DeveloperTickets projectOption={projectOption} />
    </div>
  )
}

export default TimerTickets
