import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import moment from "moment"
import { useParams } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import { ResourceType } from "utils/common-options"
import { getDaysBetweenDates } from "utils/common-helpers"
const TestOverview = ({ verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const [projectDetail, setProjectDetails] = useState({})
  const params = useParams()
  const toggleLoading = loading => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchProjectOverview = async () => {
      const res = await fetchData(
        toggleLoading,
        `projectView/${params?.id}`,
        token
      )
      if (res?.success) {
        setProjectDetails(res?.data?.project[0])
      }
    }
    if (verticalActiveTab == 2) {
      fetchProjectOverview()
    }
  }, [verticalActiveTab])
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          <div className="row">
            <div className="col-lg-12 ps-0">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-purple fw-bold font-size-18">
                        {projectDetail?.project_name}
                      </h5>
                      <p>
                        <strong>Customer</strong> : {projectDetail?.customer1}
                      </p>
                    </div>
                  </div>  
                  <h5 className="font-size-15 mt-4">Project Description :</h5>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: projectDetail?.description,
                    }}
                  />

                  <div className="d-flex gap-4 mt-5 ">
                    <div className="text-muted  ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Project Belongs
                        </span>{" "}
                        : {projectDetail?.location}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        <span className="text-purple fw-bold">
                          Project Industry
                        </span>{" "}
                        : {projectDetail?.industry_name}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Key Account Manager
                        </span>{" "}
                        : {projectDetail?.kam_name}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Scrum Master
                        </span>
                        : {projectDetail?.scrum_mastername}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Total Test Cases
                        </span>{" "}
                        : {projectDetail?.testcase}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Total Tickets
                        </span>
                        : {projectDetail?.total}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Not Answered
                        </span>{" "}
                        : {projectDetail?.notAnswered}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Answered</span>:
                        {projectDetail?.answered}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Team Members
                        </span>
                        :{" "}
                        {projectDetail?.developers?.map(obj => {
                          return (
                            <span key={obj.alias_name}>{obj.alias_name}, </span>
                          )
                        })}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Testing Allocated Hours
                        </span>
                        : {projectDetail?.testing} Hrs
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Testing Used Hours
                        </span>
                        : {projectDetail?.qa_hrs} Hrs
                      </p>
                    </div>
                  </div>

                  <div className="task-dates row mt-4">
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar me-1 text-primary"></i>{" "}
                          Start Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.startdate).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Est. End Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.enddate).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          No. Of Days
                        </h5>
                        <p className="text-muted mb-0">
                          {getDaysBetweenDates(
                            projectDetail?.startdate,
                            projectDetail?.enddate
                          )}{" "}
                          Days
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3 ps-0">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Delivery Before Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.deliverydatebefore).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestOverview
