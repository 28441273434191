import React, { useState, useEffect } from "react"
import { Container, Modal } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import { Editor } from "@tinymce/tinymce-react"
// copy
import { copyToClipBoard } from "pages/Separate/copyText"
import html2pdf from "html2pdf.js"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import indiaFlag from "../../../assets/images/flags/india.png"
import UkFlag from "../../../assets/images/flags/uk.png"
// assets
import "../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import configure from "configure"
import Swal from "sweetalert2"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"

const EditContract = () => {
  const [fixButtons, setFixButtons] = useState(false)
  const [modal_large, setmodal_large] = useState(false)

  const navigate = useNavigate()

  document.title = "Edit Contract | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const service_option = [
    {
      label: "Website Designing and Development",
      value: "Website Designing and Development",
    },
    { label: "Website Redesigning", value: "Website Redesigning" },
    {
      label: "E-Commerce Website Design and Development",
      value: "E-Commerce Website Design and Development",
    },
    {
      label: "Mobile Application Development",
      value: "Mobile Application Development",
    },
    { label: "Customise CRM Development", value: "Customise CRM Development" },
    { label: "Customise ERP Development", value: "Customise ERP Development" },
    {
      label: "Custom Development of Website and CRM",
      value: "Custom Development of Website and CRM",
    },
    {
      label: "Custom Development of Website and CRM and Mobile Application",
      value: "Custom Development of Website and CRM and Mobile Application",
    },
    { label: "SEO", value: "SEO" },
    { label: "Digital Marketing", value: "Digital Marketing" },
    { label: "Dedicated Resource", value: "Dedicated Resource" },
    { label: "Graphics Designing", value: "Graphics Designing" },
    {
      label: "Maintenance Contract - Website",
      value: "Maintenance Contract - Website",
    },
    {
      label: "Maintenance Contact - Custom System",
      value: "Maintenance Contact - Custom System",
    },
    {
      label: "Maintenance Contract - Mobile Apps",
      value: "Maintenance Contract - Mobile Apps",
    },
  ]

  const related = [
    { label: "Lead", value: "lead" },
    { label: "Customer", value: "customer" },
    { label: "Introducer", value: "introducer" },
  ]

  const [location_option, setLocationOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [currency_option, setCurrencyOption] = useState([])
  const [contract_type_option, setContTypeOption] = useState([])
  const [contact_option, setContactOption] = useState([])
  const [template_option, setTemplateOption] = useState([])
  const [source_option, setSourOption] = useState([])
  const [location, setLocation] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [relatedTo, setRelatedTo] = useState([])
  const [source_name, setSourceName] = useState([])
  const [contact_person, setContactPerson] = useState([])
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")
  const [currency, setCurrency] = useState("")
  const [service, setService] = useState([])
  const [template, setTemplate] = useState([])
  const [assign, setAssign] = useState([])
  const [description, setDescription] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [proposalOption, setProposalOption] = useState([])
  const [proposal, setProposal] = useState([])
  const [formRows, setFormRows] = useState([{}])
  const [paymentFormRows, setPaymentFormRows] = useState([{}])
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const params = useParams()

  useEffect(() => {
    FetchContractData()
  }, [])

  const FetchContractData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("contract_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setContractValue(response.data.data[0]?.value)
        setService(
          service_option.filter(
            obj => obj.value == response.data.data[0]?.service
          )[0]
        )
        setStartDate(response.data.data[0]?.startDate)
        setEndDate(response.data.data[0]?.endDate)
        setNote(response.data.data[0]?.subject)
        setRelatedTo(
          related.filter(obj => obj.value == response.data.data[0].related)[0]
        )
        if (
          response.data.data[0]?.old_template === null ||
          response.data.data[0]?.old_template === ""
        ) {
          setDescription(response.data.data[0]?.template_formate)
        } else {
          setDescription(response.data.data[0]?.old_template)
        }
        const payment = response.data.data[0]?.payment_schedule?.split('@#')
        const percentage = response.data.data[0]?.payment_percent?.split(',')
        const scope = response.data.data[0]?.project_scope?.split('@#')
        setFormRows(scope ? scope?.map((obj) => ({
          description: obj
        })) : [{}])
        setPaymentFormRows(payment ? payment?.map((obj, i) => ({
          name: obj,
          percentage: percentage[i]
        })) : [{}])
        const formDatas = new FormData()
        formDatas.append("country_id", response.data.data[0]?.location)

        const res3 = await axios({
          method: "post",
          url: `${configure.apiUrl}/lead_belong`,
          data: formDatas,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
          setAssignOption(option)
          setAssign(
            option.filter(obj => obj.value == response.data.data[0].assign)[0]
          )
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/locations`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data?.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
          setLocationOption(option)
          setLocation(response.data.data[0].location)
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/currency`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.currency?.map(item => ({
            label: item.symbol + "-" + item.currency,
            value: item.id,
          }))
          setCurrencyOption(option)
          setCurrency(response.data.data[0].currency)
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/contract_templated`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.map(item => ({
            label: item.name,
            value: item.id,
            description: item.description,
          }))
          setTemplateOption(option)
          setTemplate(
            option.filter(
              obj => obj.value == response.data.data[0]?.template
            )[0]
          )
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/contract_type`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res2 => {
          const option = res2.data.data.map(item => ({
            label: item.contract_type,
            value: item.id,
          }))
          setContTypeOption(option)
          setContractType(
            option.filter(obj => obj.value == response.data.data[0]?.type)[0]
          )
        })

        const fromData = new FormData()
        fromData.append("related", response.data.data[0]?.related)
        fromData.append("location", response.data.data[0]?.location)
        await axios({
          method: "post",
          url: `${configure.apiUrl}/related`,
          data: fromData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data?.map(obj => ({
            value: obj.id,
            label: obj.company + " - " + obj?.id,
            personal_details: obj.person_details,
          }))



          setSourOption(option)

          const option2 = option
            ?.filter(obj => obj.value == response.data.data[0]?.source_name)[0]
            ?.personal_details.map(obj => ({
              value: obj?.person_name?.trim(),
              label: obj?.person_name?.trim(),
            }))

          setContactOption(option2)

          const findValue = option2?.filter(
            obj => obj?.value?.trim() == response.data.data[0]?.contact_person
          )[0]

          // setContactPerson(
          //   option2?.filter(
          //     obj => obj?.value?.trim() == response.data.data[0]?.contact_person
          //   )[0]
          // )

          setContactPerson(findValue)


          const dataasas = option?.filter(
            obj => obj?.value == response.data.data[0]?.source_name
          )[0]


          setSourceName(
            option?.filter(
              obj => obj?.value == response.data.data[0]?.source_name
            )[0]
          )
        })
        const formEntries = {
          user_id: localStorage.getItem('auth_id')
        };
        const res = await FetchPostData(toggleLoading, 'proposal', secureLocalStorage.getItem('auth_token'), formEntries);
        if (res?.data?.success) {
          const options = (res.data.data.proposal?.map((obj) => ({
            label: `${obj.proposaltitile} - ${obj?.companyname}`,
            value: obj.id,
            companyname: obj?.companyname,
            scope: obj?.costbreakdown
          })))
          setProposalOption(options)
          setProposal(options?.find((obj) => obj?.value == response.data.data[0]?.proposal_id))
        }

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onChangeCountry = async e => {
    setLoading(true)
    setLocation(e.target.value)
    const fromData = new FormData()
    fromData.append("country_id", e.target.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }


  const changeRelated = async e => {
    setRelatedTo(e)
    setSourceName([])
    setContactPerson("")
    setLoading(true)
    const fromData = new FormData()
    fromData.append("related", e.value)
    fromData.append("location", location)

    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/related`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setSourOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.company,
            personal_details: obj.person_details,
          }))
        )
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [editor, setEditor] = useState(description)
  const [error, setError] = useState(null)

  const onEditContract = async e => {
    e.preventDefault()
    const Description = editor?.getContent()
    if (start_date?.length != 0 && note?.length != 0) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("contract_id", params.id)
      formData.append("location", location)
      formData.append("assign", assign?.value)
      formData.append("service", service?.value)
      formData.append("type", contract_type?.value)
      formData.append("template", template?.value)
      formData.append("related", relatedTo?.value)
      formData.append("source_name", source_name?.value)
      formData.append(
        "contact_person",
        contact_person?.value ? contact_person?.value : contact_person
      )
      formData.append("currency", currency)
      formData.append("value", contract_value)
      formData.append("template_formate", Description)
      formData.append("startdate", start_date)
      formData.append("enddate", end_date)
      formData.append("subject", note)
      formData.append("proposal_id", proposal?.value || '')
      formData.append("project_scope", formRows?.map((obj) => obj?.description)?.join('@#'))
      formData.append("payment_schedule", paymentFormRows?.map((obj) => obj?.name)?.join('@#'))
      formData.append("payment_percent", paymentFormRows?.map((obj) => obj?.percentage)?.join(','))
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/edit_contract`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Contract Edited Successfully",
          }).then(data => {
            navigate(`${configure.appUrl}sales/contract`)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setError(true)
    }
  }

  const [collapse, setCollapse] = useState(false)

  const handleEditorChange = (content, editor) => {
    setEditor(editor)
  }

  const handleClick = () => {
    setLoading(true)
    const Description = editor?.getContent()

    const pdfOptions = {
      margin: [15, 10, 10, 10],
      filename: "Sample Contract.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: { mode: "css", before: ".page-break" },
    }

    html2pdf()
      .from(Description)
      .set(pdfOptions)
      .toPdf()
      .get("pdf")
      .then(pdf => {
        var totalPages = pdf.internal.getNumberOfPages()
        // console.log(totalPages)

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i)
          // pdf.text(10, 10, "<p>header</p>")
          // pdf.text(280, 10, "<p>footer</p>")

          // const header = document.createElement("div");
          // header.innerHTML = "<p>This is the header.</p>";
          // pdf.text(10,10, 'header');
          pdf.addImage(indiaFlag, "PNG", 5, 5, 200, 8)
          pdf.addImage(indiaFlag, "PNG", 5, 200, 200, 8)

          // Add HTML footer
          // const footer = document.createElement("div");
          // footer.innerHTML = "<p>This is the footer.</p>";
          // pdf.text(footer, 10, pdf.internal.pageSize.height - 10);
        }
      })
      .save()
    setLoading(false)
  }

  // Adding scope
  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }
  const onUpdateFormRow = (index, newValue) => {
    const updatedFormRows = [...formRows];
    updatedFormRows[index].description = newValue;
    setFormRows(updatedFormRows);
  };
  const onUpdatePaymentFormRow = (index, field, newValue) => {
    const updatedPaymentFormRows = [...paymentFormRows];
    updatedPaymentFormRows[index][field] = newValue;
    setPaymentFormRows(updatedPaymentFormRows); // Assuming you're using useState to manage paymentFormRows
  };

  const onAddPaymentFormRow = () => {
    setPaymentFormRows([...paymentFormRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }
  const onDeletePaymentFormRow = index => {
    const newInputs = [...paymentFormRows]
    newInputs.splice(index, 1)
    setPaymentFormRows(newInputs)
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Edit Contract"} />
          <Row className="px-1">
            <Form onSubmit={onEditContract}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons pt-2 ${fixButtons ? "d-flex" : "d-none"
                  }`}
              >
                <button
                  type="submit"
                  className="btn btn-save fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 label-icon"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </a>
              </div>

              <Row>
                <div
                  className={`tramsition-collapse ${collapse == true ? "d-none" : "col-6"
                    } `}
                >
                  <div className=" ">
                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">
                      <div className="col-md-4 mb-2">
                        <label htmlFor="">
                          Location <span className="text-danger">*</span>
                        </label>
                        <div className="row" style={{ marginLeft: "0px" }}>
                          {location_option.map((item, i) => {
                            return (
                              <div className="form-check mb-2 col-md-5" key={i}>
                                <input
                                  className="form-check-input input-location"
                                  type="radio"
                                  name="exampleRadios"
                                  id={`location${i}`}
                                  value={item.value}
                                  defaultChecked={location == item.value}
                                  onChange={e => {
                                    onChangeCountry(e)
                                  }}
                                />
                                <label
                                  className="form-check-label pl-10"
                                  htmlFor={`location${i}`}
                                >
                                  {item.value == 1 ? (
                                    <img
                                      src={indiaFlag}
                                      alt="no img"
                                      width="28"
                                      height="18"
                                      style={{ marginTop: "2px" }}
                                    />
                                  ) : (
                                    <img
                                      src={UkFlag}
                                      alt="no img"
                                      width="28"
                                      height="18"
                                      style={{ marginTop: "2px" }}
                                    />
                                  )}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="col-md-4 mb-2">
                        <label htmlFor="">
                          Assign <span className="text-danger">*</span>
                        </label>
                        <Select
                          placeholder="Select Assign"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={assign_option}
                          value={assign}
                          onChange={e => setAssign(e)}
                        />
                      </div>
                    </Row>

                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">
                      <div className="col-md-4 mb-3 ">
                        <label htmlFor="">
                          Related <span className="text-danger">*</span>
                        </label>
                        <Select
                          placeholder="Select "
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={related}
                          value={relatedTo}
                          onChange={changeRelated}
                        />
                      </div>

                      {/* dynamic related fields */}
                      {relatedTo?.length != 0 ? (
                        <>
                          <div className="col-md-4 mb-3">
                            <label htmlFor="" className="text-capitalize">
                              {relatedTo?.value}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              placeholder="Select Service"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              styles={colourStyles}
                              options={source_option}
                              value={source_name}
                              onChange={e => {
                                setSourceName(e)
                                setContactOption(
                                  e.personal_details?.map(obj => ({
                                    value: obj.person_name,
                                    label: obj.person_name,
                                  }))
                                )
                              }}
                            />
                          </div>
                          {relatedTo?.value == "introducer" ? (
                            <div className="col-md-4 mb-3">
                              <label htmlFor="">
                                Contact Person{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Contact Person"
                                className="form-control "
                                value={contact_person}
                                onChange={e => setContactPerson(e.target.value)}
                              />
                            </div>
                          ) : (
                            <div className="col-md-4 mb-3">
                              <label htmlFor="">
                                Contact Person{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                placeholder="Select Service"
                                className="basic-multi-select"
                                classNamePrefix=" select"
                                styles={colourStyles}
                                options={contact_option}
                                value={contact_person}
                                onChange={e => setContactPerson(e)}
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                    </Row>

                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">
                      <div className="col-md-8 mb-3">
                        <label htmlFor="">Contract Type</label>
                        <Select
                          placeholder="Select Type"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={contract_type_option}
                          value={contract_type}
                          onChange={e => setContractType(e)}
                        />
                      </div>
                      <div className="col-md-4 mb-3 ">
                        <label htmlFor="">Contract Value</label>
                        <div className="d-flex">
                          <div
                            className="col-md-2 mb-3"
                            style={{ width: "19%" }}
                          >
                            <select
                              className="form-control  border-right-none"
                              placeholder="symbol"
                              onChange={e => setCurrency(e.target.value)}
                              style={{ backgroundColor: "#eff2f7" }}
                            >
                              {currency_option?.map((obj, i) => {
                                return (
                                  <option
                                    key={i}
                                    selected={currency == obj.value}
                                    value={obj.value}
                                  >
                                    {obj.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Value"
                              className="form-control  border-left-none"
                              value={contract_value}
                              onChange={e => setContractValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 mb-3">
                        <label htmlFor="">Proposal</label>
                        <Select
                          placeholder="Select Proposal"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          value={proposal}
                          options={proposalOption}
                          onChange={(e) => {
                            setFormRows(e.scope != null ? JSON.parse(e.scope) : [{}])
                            setProposal(e)
                          }}
                        />
                      </div>
                    </Row>

                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="">
                          Select Service <span className="text-danger">*</span>
                        </label>
                        <Select
                          placeholder="Select Service"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={service_option}
                          value={service}
                          onChange={e => setService(e)}
                        />
                      </div>

                      <div className="col-md-4 mb-3">
                        <label htmlFor="">Select Templated</label>
                        <Select
                          placeholder="Select"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={template_option}
                          value={template}
                          onChange={e => {
                            setTemplate(e)
                            setDescription(e.description)
                          }}
                        />
                      </div>

                      <div className="col-md-4 mb-3 ">
                        <label htmlFor="">
                          Start Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control "
                          value={start_date}
                          onChange={e => setStartDate(e.target.value)}
                        />
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            paddingTop: "7px",
                          }}
                        >
                          {(start_date?.length == 0) == error
                            ? "Please select Start Date"
                            : null}
                        </span>
                      </div>

                      <div className="col-md-4 mb-md-0 mb-3 ">
                        <label htmlFor="">End Date</label>
                        <input
                          type="date"
                          className="form-control "
                          value={end_date}
                          onChange={e => setEndDate(e.target.value)}
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <label htmlFor="">
                          Note <span className="text-danger">*</span>
                        </label>
                        <textarea
                          placeholder="Enter Note"
                          className="form-control "
                          value={note}
                          onChange={e => setNote(e.target.value)}
                        ></textarea>
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            paddingTop: "7px",
                          }}
                        >
                          {(note?.length == 0) == error
                            ? "Please add Note"
                            : null}
                        </span>
                      </div>
                    </Row>
                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">

                      <div className="col-12 mb-3">

                        <div className="d-flex justify-content-between align-items-center px-0 mb-3">
                          <label htmlFor="">
                            Add Scope <span className="text-danger">*</span>
                          </label>
                          <div className="text-end ">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onAddFormRow()
                              }}
                              className="btn btn-purple "
                            >
                              <i className="fas fa-plus me-2"></i>
                              Scope
                            </button>
                          </div>
                        </div>

                        {formRows?.map((person, key) => {
                          return (
                            <div key={key} className="mb-3">
                              <div className="d-flex align-items-center gap-2 justify-content-between" >
                                <div className="contact-form d-flex col-md-11">
                                  <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                    <input
                                      type="text"
                                      name="scope"
                                      placeholder="Enter Scope"
                                      className="form-control "
                                      value={person?.description}
                                      onChange={(e) => onUpdateFormRow(key, e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="contact-btns  d-flex justify-content-end align-items-end mb-md-0">
                                  <div className="text-end">
                                    <a className="btn btn-sm btn-outline-danger me-1" onClick={e => {
                                      e.preventDefault()
                                      onDeleteFormRow(key)
                                    }}>
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Row>
                    <Row className="pt-4 pb-2 px-2 mb-3 bg-white">
                      <div className="col-12 mb-3">
                        <div className="d-flex justify-content-between align-items-center px-0 mb-3">
                          <label htmlFor="">
                            Add Payment Schedule <span className="text-danger">*</span>
                          </label>
                          <div className="text-end ">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onAddPaymentFormRow()
                              }}
                              className="btn btn-purple "
                            >
                              <i className="fas fa-plus me-2"></i>
                              Payment Schedule
                            </button>
                          </div>
                        </div>

                        {paymentFormRows?.map((person, key) => {
                          return (
                            <div key={key} className="mb-3">
                              <div className="d-flex align-items-center gap-2 justify-content-between" >
                                <div className="contact-form d-flex col-md-9">
                                  <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                    <input
                                      type="text"
                                      name="scope"
                                      placeholder="Enter Payment Name"
                                      className="form-control "
                                      value={person?.name || ''}
                                      onChange={(e) => onUpdatePaymentFormRow(key, "name", e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="contact-form d-flex col-md-2">
                                  <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                    <input
                                      type="number"
                                      name="scope"
                                      placeholder="Enter %"
                                      className="form-control "
                                      value={person?.percentage || ''}
                                      onChange={(e) => onUpdatePaymentFormRow(key, "percentage", e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="contact-btns  d-flex justify-content-end align-items-end mb-md-0">
                                  <div className="text-end">
                                    <a className="btn btn-sm btn-outline-danger me-1" onClick={e => {
                                      e.preventDefault()
                                      onDeletePaymentFormRow(key)
                                    }}>
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Row>
                  </div>
                </div>

                <div
                  className={
                    collapse == true ? "col-12 " : "col-6 ps-md-4 pe-md-0"
                  }
                >
                  <div className="h-100 pb-3">
                    <Row className="bg-white h-100 px-2 py-4 shadow mb-3">
                      <div className="col-3">
                        <a
                          className="btn btn-primary"
                          onClick={() => setCollapse(!collapse)}
                          style={{ lineHeight: "0" }}
                        >
                          <i
                            className={
                              collapse ? "bx bx-collapse" : "bx bx-expand"
                            }
                            style={{ fontSize: "15px", paddingRight: "5px" }}
                          ></i>
                          {collapse ? "Collapse" : "Expand"}
                        </a>
                      </div>
                      <div className="col-9 text-end mb-3">
                        <a className="btn btn-info me-2 " onClick={handleClick}>
                          <i className="fas fa-download"></i>
                        </a>
                        <a
                          onClick={() => {
                            setmodal_large(true)
                          }}
                          className="btn btn-save me-2 "
                        >
                          dynamic fields
                        </a>
                        <button className="btn btn-save me-2 btn-label">
                          <i className="fas fa-save me-1 label-icon"></i> save
                        </button>
                        <a
                          onClick={() => {
                            navigate(-1)
                          }}
                          className=" btn btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </a>
                      </div>
                      <div className="col-12 mb-md-0 mb-3 contract-editor">
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={description != null ? description : ""}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                          }}
                        /> */}
                        <Editor
                          initialValue={description}
                          apiKey="w6fz9u4rgsouz7jqcyy4lflpolp0tjwkx1bzoc70ntetxwys"
                          init={{
                            height: 800,
                            menubar: true,
                            plugins: [
                              "a11ychecker",
                              "accordion",
                              "advlist",
                              "advcode",
                              "advtable",
                              "autolink",
                              "checklist",
                              "export",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "powerpaste",
                              "fullscreen",
                              "formatpainter",
                              "insertdatetime",
                              "media",
                              "table",
                              "wordcount",
                              "code",
                              "pagebreak",
                              "quickbars",
                              "codesample",
                              "textcolor",
                            ],
                            toolbar:
                              "undo redo | casechange blocks | bold italic backcolor | fontselect fontsizeselect |" +
                              "alignleft aligncenter alignright alignjustify | " +
                              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table | exportpdf |",
                            fontsize_formats:
                              "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>

      {/* Show dynamic field modal */}
      <Modal size="lg" isOpen={modal_large} centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dynamic Fields</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_large(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <div className="d-flex flex-wrap position-relative">
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2 ">
                <label htmlFor="" className="col-4 mb-0">
                  Company Name
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$client_company}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(0)}
                  value="{$client_company}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn btn-primary  btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  Client Name
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$client_name}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(1)}
                  value="{$client_name}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn-sm btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  Address
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$client_address}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(2)}
                  value="{$client_address}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  State
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$client_state}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(3)}
                  value="{$client_state}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  Contract Value
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$contract_value}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(4)}
                  value="{$contract_value}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  Current Date
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$current_date}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(5)}
                  value="{$current_date}"
                  className="p-2 fas fa-copy copyBtn h-100 col-2 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  Start Date
                </label>
                <div className="p-2 me-3 col-5 bg-dynamic-field">
                  {"{$start_date}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(6)}
                  value="{$start_date}"
                  className="p-2 fas fa-copy copyBtn  h-100 col-2 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="col-6 px-3 align-items-center d-flex justify-content-center px-1 mb-2">
                <label htmlFor="" className="col-4 mb-0">
                  End Date
                </label>
                <div className="p-2 col-5 me-3 bg-dynamic-field">
                  {"{$end_date}"}
                </div>
                <button
                  onClick={() => copyToClipBoard(7)}
                  value="{$end_date}"
                  className="p-2 col-2 fas fa-copy copyBtn h-100 btn btn-primary btn-sm"
                ></button>
              </div>
              <div className="copy-modal-line"></div>
            </div>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default EditContract
