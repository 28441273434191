import PropTypes, { number } from "prop-types"
import React, { useState, useEffect } from "react"
import { Collapse, Modal, Table, Row, Col } from "reactstrap"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"
import "../../assets/css/Layout/Header.css"
//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import axios from "axios"
import configure from "configure"
import Select from "react-select"
import { isNaN } from "lodash"
import Loader from "pages/Separate/Loader"
import ReactSelect from "react-select"

const Navbar = props => {
  const [expense, setExpense] = useState(false)
  const [margin, setMargin] = useState({ value: 1.5, label: 1.5 })
  const [marginOption, setMarginOption] = useState([
    {
      value: 1.5,
      label: 1.5,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 2.5,
      label: 2.5,
    },
  ])
  const [dashboard, setdashboard] = useState(false)
  const [setting, setSetting] = useState(false)
  const [allcurrency, setAllCurrency] = useState([])
  const [allTechData, setAllTechData] = useState([])
  const [currency, setCurrency] = useState({
    label: "INR",
    value: "INR",
    symbol: "₹",
  })
  const [addCallingModal, setAddCallingModal] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  const [subTotal, setSubTotal] = useState(0)
  const [profit, setProfit] = useState("")
  const [finalCost, setFinalCost] = useState(0)

  useEffect(() => {
    RolesPermission()
    // fetchCurrency()
  }, [])
  const handleChange = selectedOption => {
    setMargin(selectedOption)
  }

  useEffect(() => {
    const tempData = [...allTechData]
    setAllTechData(
      tempData.map(tech => ({
        ...tech,
        estimate_cost: tech.days * tech[currency.value],
      }))
    )
  }, [currency, margin])

  useEffect(() => {
    setSubTotal(allTechData.reduce((acc, curr) => acc + curr.estimate_cost, 0))
  }, [allTechData])

  useEffect(() => {
    setFinalCost(subTotal + (subTotal * profit) / 100)
  }, [subTotal])

  const fetchCurrency = async () => {
    setLoading(true)
    setAddCallingModal(true)

    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/currency`,

        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setAllCurrency(
          res.data.data.currency.map(curr => ({
            label: curr.currency,
            value: curr.currency,
            symbol: curr.symbol,
          }))
        )

        // call show technologies api
        showTechnologiesData()
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const showTechnologiesData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/show_technologies`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        if (response.data.data.length > 0) {
          const datas = JSON.parse(response.data.data[0].currency_value)

          setAllTechData(
            datas.map(data => ({
              ...data,
              days: "",
              estimate_cost: 0,
            }))
          )
        }
        setAddCallingModal(true)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeDays = (value, index) => {
    const tempData = [...allTechData]

    setAllTechData(
      tempData.map((tech, i) => {
        if (i == index) {
          tech.days = value
          tech.estimate_cost = value * tech[currency.value]
        }
        return tech
      })
    )
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const RolesPermission = async e => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staff_roles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        setRoles(response.data.data.map(item => JSON.parse(item.permission)))
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }


  function filterInnerArraysById(id) {
    return roles.map(innerArray => {
      return innerArray.filter(element => {
        const parts = element.split(",")
        const elementId = parseInt(parts[0])
        return elementId === id
      })
    })
  }

  const ids = [3, 24, 4, 5, 6]

  const output = roles.map(innerArray =>
    innerArray.filter(element => {
      const parts = element.split(",")
      const elementId = parseInt(parts[0])
      return ids.includes(elementId)
    })
  )
  const output2 = output.map(innerArray => {
    const element = innerArray[1] // Get the element at index 2 (0-based indexing)
    const parts = element.split(",")
    return parts.map(part => parseInt(part)) // Convert to integers
  })

  const calenderUrl = useLocation();


  const calender3 = calenderUrl.pathname
  const usepara = useParams()
  const filterRole =
    localStorage.getItem("auth_role") == 1 ||
      localStorage.getItem("auth_role") == 11 ||
      localStorage.getItem("auth_role") == 4
      ? true
      : false


  return (
    <React.Fragment>
      <div
        className={`topnav ps-1 pe-0 ${calender3 === `${configure.appUrl}calender` ||
          calender3 === `${configure.appUrl}calender/${usepara.id}`
          ? "d-none"
          : ""
          }`}
        style={{ zIndex: "1001" }}
      >
        <div className="container-fluid px-0">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav d-flex justify-content-between w-100">
                <div className="d-flex">
                  {/* dashboard */}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${configure.appUrl}dashboard`}
                    >
                      <i className="bx bx-home-circle me-2 "></i>
                      {props.t("Dashboard")} {props.menuOpen}
                    </Link>
                  </li>

                  {/* sales */}
                  {/* <li className="nav-item dropdown">
                    {filterInnerArraysById(3)
                      .map(item => item[0]?.split(",").map(Number)[2])
                      .includes(1) == true ||
                      filterInnerArraysById(24)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ||
                      filterInnerArraysById(4)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ||
                      filterInnerArraysById(5)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ||
                      filterInnerArraysById(6)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to="#"
                      >
                        <i className="bx bx-tone me-2"></i>
                        {props.t("Sales")} {props.menuOpen}
                      </Link>
                    ) : (
                      ""
                    )}

                    <div className="dropdown-menu" id="sales-dropdown">
                      {filterInnerArraysById(3)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <Link
                          to={`${configure.appUrl}sales/lead`}
                          className="dropdown-item"
                        >
                          Lead
                        </Link>
                      ) : null}

                      {filterInnerArraysById(24)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <Link
                          to={`${configure.appUrl}sales/Introducer`}
                          className="dropdown-item"
                        >
                          Introducer
                        </Link>
                      ) : null}

                      {filterInnerArraysById(4)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <Link
                          to={`${configure.appUrl}sales/proposal`}
                          className="dropdown-item"
                        >
                          Proposal
                        </Link>
                      ) : null}

                      {filterInnerArraysById(5)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <Link
                          to={`${configure.appUrl}sales/contract`}
                          className="dropdown-item"
                        >
                          Contract
                        </Link>
                      ) : null}

                      {filterInnerArraysById(6)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <Link
                          to={`${configure.appUrl}sales/customer`}
                          className="dropdown-item"
                        >
                          Customers
                        </Link>
                      ) : null}
                    </div>
                  </li> */}
                  {filterInnerArraysById(2)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to="#"
                        onClick={() => toggleMenu(1)}
                      >
                        <i className="bx bx-tone me-2"></i>
                        {props.t("Sales")} {props.menuOpen}
                      </Link>

                      <div
                        className={`dropdown-menu`}
                        id="sales-dropdown"
                      >
                        {filterInnerArraysById(17)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true || filterInnerArraysById(17)
                            .map(item => item[0]?.split(",").map(Number)[1])
                            .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}sales/lead`}
                            className="dropdown-item"
                          >
                            Lead
                          </Link>
                        ) : null}

                        {filterInnerArraysById(18)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}sales/introducer`}
                            className="dropdown-item"
                          >
                            Introducer
                          </Link>
                        ) : null}

                        {filterInnerArraysById(19)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}sales/proposal`}
                            className="dropdown-item"
                          >
                            Proposal
                          </Link>
                        ) : null}

                        {filterInnerArraysById(20)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}sales/contract`}
                            className="dropdown-item"
                          >
                            Contract
                          </Link>
                        ) : null}

                        {filterInnerArraysById(21)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}sales/customer`}
                            className="dropdown-item"
                          >
                            Customers
                          </Link>
                        ) : null}
                      </div>
                    </li>
                  ) : null}


                  {/* Work MODULE  */}
                  {filterInnerArraysById(4)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        id="topnav-more"
                        type="button"
                      >
                        <i className="bx bx-task me-2"></i>
                        <span key="t-extra-pages">Work</span>
                      </a>
                      <div
                        className={`dropdown-menu active`}
                        aria-labelledby="topnav-more"
                      >
                        {filterInnerArraysById(25)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <div className="dropdown">

                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              id="topnav-auth"
                              role="button"
                            >
                              <span >Development Project</span>
                              <div className="arrow-down"></div>

                            </Link>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="topnav-form"
                              style={{ overflow: "hidden" }}
                            >
                              {filterInnerArraysById(25)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  to={`${configure.appUrl}weekly-plan/development`}
                                  id="topnav-auth"
                                  role="button"
                                >
                                  <span >Weekly Plan</span>
                                </Link>
                              ) : null}

                              {filterInnerArraysById(88)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}initial-project-plan/development`}
                                  className="dropdown-item"

                                  style={{ overflow: "hidden" }}
                                >
                                  Initial Project Plan
                                </Link>
                              ) : null}

                              {filterInnerArraysById(25)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}work/projects`}
                                  className="dropdown-item"
                                  style={{ overflow: "hidden" }}
                                >
                                  Projects
                                </Link>
                              ) : null}

                            </div>
                          </div>
                        ) : null}


                        {filterInnerArraysById(97)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              id="topnav-auth"
                              role="button"
                            >
                              <span key="t-work">Digital Marketing</span>
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="topnav-form"
                              style={{ overflow: "hidden" }}
                            >
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to={`${configure.appUrl}weekly-plan/digital-marketing`}
                                id="topnav-auth"
                                role="button"
                              >
                                <span key="t-work">My Weekly Plan</span>
                              </Link>
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to={`${configure.appUrl}monthly-planning`}
                                id="topnav-auth"
                                role="button"
                              >
                                <span>Monthly Planning</span>
                              </Link>
                              {filterInnerArraysById(105)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  to={`${configure.appUrl}monthly-approval`}
                                  id="topnav-auth"
                                  role="button"
                                >
                                  <span>Monthly Approval</span>
                                </Link>
                              ) : null}
                              {filterInnerArraysById(98)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  to={`${configure.appUrl}teams-weekly-plan/digital-marketing`}
                                  id="topnav-auth"
                                  role="button"
                                >
                                  <span key="t-work">Teams Weekly Plan</span>
                                </Link>
                              ) : null}
                              {filterInnerArraysById(99)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  to={`${configure.appUrl}project-weekly-plan/digital-marketing`}
                                  id="topnav-auth"
                                  role="button"
                                >
                                  <span key="t-work">Project Weekly Plan</span>
                                </Link>
                              ) : null}
                              {filterInnerArraysById(100)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}initial-project-plan/digital-marketing`}
                                  className="dropdown-item"

                                  style={{ overflow: "hidden" }}
                                >
                                  Initial Project Plan
                                </Link>
                              ) : null}
                              {filterInnerArraysById(101)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}work/digital_marketing`}
                                  className="dropdown-item"
                                  style={{ overflow: "hidden" }}
                                >
                                  Projects
                                </Link>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {filterInnerArraysById(107)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <div className="dropdown">
                            <a
                              className="dropdown-item dropdown-toggle arrow-none"
                              id="topnav-auth"
                              role="button"
                            >
                              <span >Support</span>
                              <div className="arrow-down"></div>
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="topnav-form"
                              style={{ overflow: "hidden" }}
                            >
                              <Link
                                className="dropdown-item dropdown-toggle arrow-none"
                                to={`${configure.appUrl}support/ticket`}
                                id="topnav-auth"
                                role="button"
                              >
                                <span >Tickets</span>
                              </Link>
                              <Link
                                to={`${configure.appUrl}support/test-cases`}
                                className="dropdown-item"

                                style={{ overflow: "hidden" }}
                              >
                                Test Cases
                              </Link>
                            </div>
                          </div>
                        ) : null}

                        {filterInnerArraysById(26)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to={`${configure.appUrl}work/activities`}
                            id="topnav-utility"
                            role="button"
                          >
                            <span key="t-work">Activities</span>
                          </Link>
                        ) : null}

                      </div>
                    </li>
                  ) : null}

                  {/* Human Resource */}
                  {filterInnerArraysById(7)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="#"
                        id="topnav-more"
                        role="button"
                        onClick={() => toggleMenu(3)}
                      >
                        <i
                          className="fa fa-user me-2"
                          style={{ fontSize: "12px" }}
                        ></i>
                        <span key="t-extra-pages">Human Resources</span>
                      </Link>
                      <div
                        className={`dropdown-menu `}
                        aria-labelledby="topnav-more"
                        style={{ left: "0%" }}
                      >
                        {filterInnerArraysById(66)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to={`${configure.appUrl}staff`}
                            id="topnav-invoice"
                            role="button"
                          >
                            <span key="t-invoices">Staff</span>
                          </Link>
                        ) : null}

                        {filterInnerArraysById(40)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              id="topnav-form"
                              role="button"
                              style={{ overflow: "hidden" }}
                            >
                              <span >Payroll Management</span>
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="topnav-form"
                              style={{ overflow: "hidden" }}
                            >
                              {filterInnerArraysById(40)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}payroll-management/leave-management`}
                                  className="dropdown-item"
                                  style={{ overflow: "hidden" }}
                                >
                                  Leave Management
                                </Link>
                              ) : null}
                              {filterInnerArraysById(108)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}payroll-management/salary-slip`}
                                  className="dropdown-item"
                                  style={{ overflow: "hidden" }}
                                >
                                  Salary Slip
                                </Link>
                              ) : null}

                              {filterInnerArraysById(109)
                                .map(item => item[0]?.split(",").map(Number)[2])
                                .includes(1) == true ? (
                                <Link
                                  to={`${configure.appUrl}payroll-management/salary-process`}
                                  className="dropdown-item"
                                  style={{ overflow: "hidden" }}
                                >
                                  Salary Process
                                </Link>
                              ) : null}
                              <Link
                                to={`${configure.appUrl}payroll_management/swipe_issues`}
                                className="dropdown-item"
                                style={{ overflow: "hidden" }}
                              >
                                Swipe & Issues
                              </Link>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </li>
                  ) : null}

                  {/* Marketing */}

                  {filterInnerArraysById(3)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none text-dark"
                        to="#"
                        onClick={e => {
                          e.preventDefault()
                          setExpense(!expense)
                          toggleMenu(2)
                        }}
                      >
                        <i className="fas fa-bullhorn me-2"></i>
                        {props.t("Marketing")}
                      </Link>
                      <div
                        className={`dropdown-menu `}
                      >
                        {filterInnerArraysById(22)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}marketing/opportunity`}
                            className="dropdown-item arrow-none"
                          >
                            {props.t("Opportunity")}{" "}
                          </Link>
                        ) : null}

                        {filterInnerArraysById(24)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}marketing/cold-calling`}
                            className="dropdown-item"
                          >
                            Cold Calling
                          </Link>
                        ) : null}

                        {filterInnerArraysById(87)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}marketing/all-bookings`}
                            className="dropdown-item arrow-none"
                          >
                            {props.t("Bookings")}{" "}
                          </Link>
                        ) : null}
                      </div>
                    </li>
                  ) : null}

                  {filterInnerArraysById(8)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        id="topnav-more"
                        type="button"
                        onClick={() => toggleMenu(5)}
                      >
                        <i className="bi bi-bank me-2"></i>
                        <span key="t-extra-pages">Finance</span>
                      </a>

                      <div
                        className={`dropdown-menu active `}
                        aria-labelledby="topnav-more"
                      >
                        {filterInnerArraysById(41)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              href="#"
                              id="topnav-form"
                              role="button"
                            >
                              <span key="t-forms">Expenses</span>
                              <div className="arrow-down"></div>
                            </Link>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="topnav-form"
                              style={{ overflow: "hidden" }}
                            >
                              <Link
                                to={`${configure.appUrl}expense/budget-expense`}
                                className="dropdown-item"
                              >
                                Budget Expense
                              </Link>
                              <Link
                                to={`${configure.appUrl}expense/expense`}
                                className="dropdown-item"
                              >
                                Expense
                              </Link>
                              <Link
                                to={`${configure.appUrl}expense/vendorspay`}
                                className="dropdown-item"
                                key="t-form-layouts"
                              >
                                Vendor Pay
                              </Link>
                            </div>
                          </div>
                        ) : null}
                        {/* // Credit Card  */}
                        {filterInnerArraysById(43)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to={`${configure.appUrl}creditcard`}
                            id="topnav-invoice"
                            role="button"
                          >
                            <span key="t-invoices">Credit Card</span>
                          </Link>
                        ) : null}
                        {filterInnerArraysById(103)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <Link
                            to={`${configure.appUrl}finance/overdraft`}
                            className="dropdown-item"
                          >
                            OverDraft
                          </Link>
                        ) : null}
                      </div>
                    </li>
                  ) : null}

                  {filterInnerArraysById(6)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">

                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        id="topnav-more"
                        type="button"
                        onClick={() => toggleMenu(6)}
                      >
                        <i className="bi bi-file-earmark-text me-2"></i>
                        <span key="t-extra-pages">Reports</span>
                      </a>
                      {filterInnerArraysById(96)
                        .map(item => item[0]?.split(",").map(Number)[2])
                        .includes(1) == true ? (
                        <div
                          className={`dropdown-menu active `}
                          aria-labelledby="topnav-more"
                        >
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to={`${configure.appUrl}flaws-gap`}
                            id="topnav-invoice"
                            role="button"
                          >
                            <span key="t-invoices">Flaws & Gaps</span>
                          </Link>
                        </div>
                      ) : null}
                    </li>
                  ) : null}


                </div>

                {/* end modules  */}
                <div className="d-flex align-items-center">
                  {/* setting  */}
                  {filterInnerArraysById(13)
                    .map(item => item[0]?.split(",").map(Number)[2])
                    .includes(1) == true ? (
                    <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle arrow-none text-dark me-3 mt-1 p-0">
                        <i className="bx bx-cog setting-icon"></i>
                      </Link>
                      <div
                        className={classname("dropdown-menu ", {
                          show: setting,
                        })}
                      >
                        {filterInnerArraysById(83)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <li>
                            <Link
                              to={`${configure.appUrl}setting/industry`}
                              className="dropdown-item"
                            >
                              {props.t("Industry")}
                            </Link>
                          </li>
                        ) : null}
                        {filterInnerArraysById(73)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <li>
                            <Link
                              to={`${configure.appUrl}setting/generalsetting`}
                              className="dropdown-item"
                            >
                              {props.t("General Setting")}
                            </Link>
                          </li>
                        ) : null}
                        {filterInnerArraysById(81)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <li>
                            <Link
                              to={`${configure.appUrl}setting/knowledge-base`}
                              className="dropdown-item"
                            >
                              {props.t("Knowledge Base")}
                            </Link>
                          </li>
                        ) : null}
                        {filterInnerArraysById(79)
                          .map(item => item[0]?.split(",").map(Number)[2])
                          .includes(1) == true ? (
                          <li>
                            <Link
                              to={`${configure.appUrl}setting/category`}
                              className="dropdown-item"
                            >
                              {props.t("Category")}
                            </Link>
                          </li>
                        ) : null}
                        <li>
                          {filterInnerArraysById(69)
                            .map(item => item[0]?.split(",").map(Number)[2])
                            .includes(1) == true ? (
                            <Link
                              to={`${configure.appUrl}roles`}
                              className="dropdown-item"
                            >
                              {props.t("Roles & Permission")}
                            </Link>
                          ) : null}
                        </li>
                        <li>
                          {filterInnerArraysById(70)
                            .map(item => item[0]?.split(",")?.map(Number)[2])
                            .includes(1) == true ? (
                            <Link
                              to={`${configure.appUrl}setting/companysetting`}
                              className="dropdown-item"
                            >
                              {props.t("Company Setting")}
                            </Link>
                          ) : null}
                        </li>
                        <li>
                          {filterInnerArraysById(83)
                            .map(item => item[0]?.split(",").map(Number)[2])
                            .includes(1) == true ? (
                            <Link
                              to={`${configure.appUrl}setting/digital_marketing`}
                              className="dropdown-item"
                            >
                              {props.t("Digital Marketing")}
                            </Link>
                          ) : null}
                        </li>
                        <li>
                          {filterInnerArraysById(104)
                            .map(item => item[0]?.split(",").map(Number)[2])
                            .includes(1) == true ? (
                            <Link
                              to={`${configure.appUrl}setting/overdraft`}
                              className="dropdown-item"
                            >
                              {props.t("OverDraft")}
                            </Link>
                          ) : null}
                        </li>
                      </div>
                    </li>
                  ) : null}

                  {/* credentials  */}
                  <li className="nav-item dropdown">
                    {filterInnerArraysById(10)
                      .map(item => item[0]?.split(",")?.map(Number)[2])
                      .includes(1) == true ? (
                      <Link
                        to={`${configure.appUrl}credentials`}
                        className="border-radius border-0 btn text-dark fs-5 ps-0 mt-1"
                        title="Credentials"
                      >
                        <i className="fa fa-address-card"></i>
                      </Link>
                    ) : null}
                  </li>

                  <li className="nav-item dropdown">
                    {filterInnerArraysById(11)
                      .map(item => item[0]?.split(",")?.map(Number)[2])
                      .includes(1) == true ? (
                      <button
                        onClick={() => {
                          fetchCurrency()
                        }}
                        className="border-radius border-0 btn text-dark fs-5 ps-0 mt-1"
                      >
                        <i className="mdi mdi-calculator"></i>
                      </button>
                    ) : null}
                  </li>
                </div>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>

      <Modal
        isOpen={addCallingModal}
        size="xl"
        centered
        id="ResourceEstimation"
      >
        <div
          className="modal-header border-bottom-0"
          style={{ backgroundColor: "#730077" }}
        >
          <div className="col-6 ">
            <h5 className="modal-title mt-0 text-white">Resource Estimation</h5>
          </div>
          <div className="col-5 d-flex justify-content-end">
            <div className="col-6 d-flex justify-content-end">
              <Select
                className="basic-multi-select w-50"
                classNamePrefix="border-radius select"
                styles={colourStyles}
                options={allcurrency}
                value={currency}
                onChange={e => setCurrency(e)}
              />
            </div>
            <div className="">
              <button
                className="btn btn-red ms-3 btn-label"
                onClick={() => {
                  setCurrency({ label: "INR", value: "INR", symbol: "₹" })
                  setAllTechData(
                    allTechData.map(data => ({
                      ...data,
                      days: "",
                      estimate_cost: 0,
                    }))
                  )
                  setProfit("")
                }}
              >
                {" "}
                <i className="bi bi-x-circle label-icon"></i> Clear
              </button>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setAddCallingModal(false)
              }}
              className="btn-modal-close "
            >
              <span className="btn-modal-span fs-2">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body pt-1">
          <div>
            {/* fixed buttons */}
            <div
              className={`flex-column fixed-buttons ${fixedContent ? "d-flex" : "d-none"
                }`}
            >
              <button
                onClick={() => navigate(-1)}
                className="btn btn-red border-radius fix-btn"
              >
                <i className="dripicons-cross me-2 icon-center"></i>
                <span>Cancel</span>
              </button>
            </div>
            <Row className="">
              <Col col={12}>
                <div className="d-flex justify-content-end">
                  <div className=""></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end col-md-6"></Col>
              <Col className="d-flex justify-content-end col-md-6">
                <div className=""></div>
              </Col>
            </Row>
            <div
              className="position-relative flow-scroll"
              style={{
                height: "420px",
                overflow: "auto",
                scrollbarWidth: "thin",
              }}
            >
              <Table className="table table-bordered">
                <thead className="position-sticky top-0 left-0 right-0 shadow bg-white">
                  <tr>
                    <th className="col-1 text-center">
                      Id
                      {/* <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i> */}
                    </th>
                    <th className="col-4">
                      Name
                      {/* <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i> */}
                    </th>
                    <th className="col-2 text-center d-none">
                      Per Day
                      {/* <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i> */}
                    </th>
                    <th className="col-1 text-center">
                      Days
                      {/* <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i> */}
                    </th>
                    <th className="col-2 text-center">
                      Estimate Cost
                      {/* <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="fix-databank-header">
                  {allTechData.length === 0 && <Loader />}
                  {allTechData.map((tech, index) => {
                    return (
                      <tr className="row-hover" key={index}>
                        <td className="text-center py-1">{index + 1}</td>
                        <td className="py-1">{tech.Technology}</td>
                        <td className="text-center d-none">
                          {
                            tech[
                            Object.keys(tech).filter(
                              curr => curr == currency.value
                            )
                            ]
                          }
                        </td>
                        <td className="py-1 d-flex justify-content-center">
                          <input
                            className="form-control edit_row_m w-75"
                            id="days1"
                            type="text"
                            value={tech.days}
                            onChange={e =>
                              handleChangeDays(e.target.value, index)
                            }
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key))
                                event.preventDefault()
                            }}
                          />
                        </td>
                        <td className="text-center py-1">
                          {tech.estimate_cost.toFixed(2)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div
          className="modal-footer p-1 border-top-0"
          style={{ backgroundColor: "#730077" }}
        >
          <div className="w-100">
            <Table className="table table-bordered mb-0">
              <tbody className="fix-databank-header">
                <tr className="">
                  <td className="col-1 fw-bold bg-white text-end">Times</td>
                  <td className=" text-center" style={{ width: "10%" }}>
                    <div className="">
                      <ReactSelect
                        placeholder="Times"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={marginOption}
                        value={margin}
                        // onChange={e =>
                        //   setMargin({ value: e.value, label: e.value })
                        // }
                        onChange={handleChange}
                        menuPlacement="top"
                      />
                    </div>
                  </td>
                  <td
                    className="fw-bold bg-white text-end"
                    style={{ width: "10%" }}
                  >
                    Capital Cost
                  </td>
                  <td className="text-center" style={{ width: "12%" }}>
                    {currency.symbol + " " + subTotal.toFixed(2) * margin.value}
                  </td>

                  <td className="col-2 fw-bold bg-white text-end">
                    Profit (%)
                  </td>
                  <td className="col-1 text-center">
                    {" "}
                    <input
                      className="form-control border-1 border-secondary edit_row_m"
                      id="days1"
                      type="text"
                      placeholder="%"
                      value={profit}
                      onChange={e => {
                        setProfit(e.target.value)
                        setFinalCost(
                          subTotal + (subTotal * e.target.value) / 100
                        )
                      }}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) event.preventDefault()
                      }}
                    />
                  </td>

                  <td
                    className="col-2 fw-bold bg-white text-end fs-5"
                    style={{ color: "#981f77" }}
                  >
                    Total Cost
                  </td>
                  <td
                    className="col-2 text-center fs-5"
                    style={{ color: "#981f77" }}
                  >
                    {currency.symbol +
                      " " +
                      (finalCost * margin.value).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
