import NewPagination from 'components/Common/Newpagination'
import Loader from 'pages/Separate/Loader'
import React, { useEffect, useMemo, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import { fetchData, FetchPostData } from 'utils/common-fetchData'
import { calculateCompleteTime, calculateCompleteTimeQty } from 'utils/common-helpers'
import { monthlyApprovalColumn } from 'utils/columns/Digital Marketing/approvalColumns'
import Breadcrumb from 'components/Common/Breadcrumb'
import Select from 'react-select'
import { DigitalServiceOptionsDrop } from 'utils/common-options'
import Swal from 'sweetalert2'

function TableContainer({
    columns,
    data,
    packageActivity
}) {
    const [fixedContent, setFixedContent] = useState(false)

    document.addEventListener("scroll", () => {
        if (window.scrollY > 68) {
            setFixedContent(true)
        } else {
            setFixedContent(false)
        }
    })

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => setPageSize(50), [])

    const output = Object.values(data.reduce((acc, { staff_id, alias_name, est_hrs }) => {
        const [hours, minutes] = est_hrs.split(":").map(Number);
        if (!acc[staff_id]) {
            acc[staff_id] = { alias_name, staff_id, totalMinutes: 0 };
        }

        acc[staff_id].totalMinutes += hours * 60 + minutes;

        return acc;
    }, {}))
        .map(({ alias_name, totalMinutes, staff_id }) => {
            const totalHours = Math.floor(totalMinutes / 60);
            const remainingMinutes = totalMinutes % 60;
            return {
                alias_name,
                totalTime: `${String(totalHours).padStart(2, "0")} Hrs ${String(remainingMinutes).padStart(2, "0")} min`,
                staff_id
            };
        });


    return (
        <div>
            <div className="col-12 d-flex mb-3">
                <div className="col-10 d-flex justify-content-start align-items-center gap-3">
                    <div className=" d-flex justify-content-start align-items-center">
                        <select
                            className="form-select"
                            style={{ width: "100px" }}
                            value={pageSize}
                            onChange={e => setPageSize(e.target.value)}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-10 gap-3 d-flex justify-content-start align-items-center'>
                        <div className="d-flex gap-2 align-items-center general-box ">
                            <h5 className="text-purple mb-0">Total Package Hours : </h5>
                            <h5 className="text-primary mb-0">
                                {calculateCompleteTimeQty(packageActivity, "est_time", 'quantity')}
                            </h5>
                        </div>
                        <div className="d-flex gap-2 align-items-center general-box ">
                            <h5 className="text-purple mb-0">Total Hours : </h5>
                            <h5 className="text-primary mb-0">
                                {calculateCompleteTime(data, "est_hrs")}
                            </h5>
                        </div>
                        {output?.map((obj, i) => {
                            return (
                                <div className="d-flex gap-2 align-items-center general-box " key={i}>
                                    <h5 className="text-purple mb-0">{obj?.alias_name} : </h5>
                                    <h5 className="text-primary mb-0">
                                        {obj?.totalTime}
                                    </h5>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="col-2 d-flex justify-content-end align-items-center">
                    <div className="col-12 d-flex justify-content-end align-items-center mt-2">
                        <div className="search-box">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={e => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="s form-control rounded "
                                        placeholder={` Search...`}
                                        value={globalFilter || ""}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="position-relative">
                <Table className="table table-bordered" {...getTableProps()}>
                    <thead
                        className={``}
                    >
                        {headerGroups.map((headerGroup, i) => {

                            return (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => {
                                        const classNames = column.className
                                        return (
                                            <th
                                                key={index}
                                                className={` col-t-${index + 1
                                                    } ${classNames} customShortIcon custom-text`}
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                            >
                                                {column.render("Header")}

                                                {column.isShortIcon && (
                                                    <span
                                                        onClick={() =>
                                                            column.getSortByToggleProps().onClick()
                                                        }
                                                        className="ms-2 cursor-pointer customApprovalIcon"
                                                    >
                                                        <i
                                                            title="Ascending"
                                                            className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                                ? "text-dark"
                                                                : ""
                                                                }`}
                                                        ></i>
                                                        <i
                                                            title="Descending"
                                                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                ? "text-dark"
                                                                : ""
                                                                }`}
                                                        ></i>
                                                    </span>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            )
                        }
                        )}
                    </thead>

                    <tbody className="fix-table-body" {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            const packageQty = packageActivity?.find((obj) => obj?.id == row?.original?.activity_id)?.quantity || false
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    key={i}
                                    className={`${packageQty === false ? "bg-orange" : ""}`}
                                >
                                    {row.cells.map((cell, index) => (
                                        <td
                                            key={index}
                                            className={`t-col-${index + 1} ${cell.column.className}`}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                        {page.length == 0 && (
                            <tr style={{ backgroundColor: "unset" }}>
                                <td className="text-center" colSpan={10}>
                                    No Data Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <NewPagination
                    gotoPage={gotoPage}
                    canPreviousPag={canPreviousPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    page={page}
                    data={data}
                />
            </Row>
        </div>
    )
}


const DmMonthlyApproval = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const token = secureLocalStorage.getItem('auth_token')
    const [projectOptions, setProjectOptions] = useState([])
    const [serviceOptions, setServiceOption] = useState([])
    const [projectId, setProjectId] = useState([])
    const [service, setService] = useState([])
    const [packageActivity, setPackageActivity] = useState([])
    const toggleLoading = (load) => {
        setLoading(load)
    }
    const columns1 = useMemo(
        () => monthlyApprovalColumn(packageActivity),
        [packageActivity]
    )
    const fetchProjectList = async () => {
        const userId = localStorage.getItem('auth_id')
        const res = await fetchData(toggleLoading, `digitalProjectByUserIdDate/${20}/2`, token);
        if (res?.success) {
            const transformData = (array) => {
                return array.map(project => {
                    const projectTypes = project.project_type.split(',');

                    const serviceList = DigitalServiceOptionsDrop.filter(service =>
                        projectTypes.includes(service.value)
                    );
                    return {
                        value: String(project.id),
                        label: project.project_name,
                        service: serviceList
                    };
                });
            };
            setProjectOptions(transformData(res?.data))
        }
    }
    useEffect(() => {
        fetchProjectList()
    }, [])

    const onGetActivities = async (e) => {
        if (service?.length != 0 && projectId?.length != 0) {
            const formEntries = {
                project_id: projectId?.value,
                type: service?.value
            }
            const res = await FetchPostData(toggleLoading, `digitalProjectUnapprovedTasks`, token, formEntries);
            if (res?.data?.success) {
                const details = res?.data?.data
                setData(details?.tasks)
                setPackageActivity(details?.activities)

            }
        } else {
            Swal.fire({
                icon: "warning",
                title: "Please select Mandatory Field",
            })
        }
    }
    const onApprove = async () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "We assume that you have reviewed all the planned activities before giving your approval!",
            icon: 'warning',
            showCancelButton: true,
        }).then(async (isOkay) => {
            if (isOkay?.isConfirmed) {
                const res = await fetchData(toggleLoading, `approveDigitalTasks/${projectId?.value}/${service?.value}`, token);
                if (res?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Approved Successfully",
                    }).then(() => {
                        setData([]);
                        setService([])
                    })
                }
            }
        })
    }
    return (
        <div>
            {isLoading && <Loader />}
            <div>
                <div className="page-content mb-4">
                    <Container fluid>
                        <Breadcrumb title="Digital Marketing" breadcrumbItem="Monthly Approval" />
                        <Row>
                            <Col lg={12} className="p-0">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex gap-2 col-md-12 align-items-center justify-space-between mb-3">
                                            <div className='col-md-8 d-flex gap-2 align-items-center justify-content-start'>
                                                <div className="col-4">
                                                    <Select
                                                        placeholder="Select Project "
                                                        className="basic-multi-select "
                                                        classNamePrefix="select"
                                                        options={projectOptions}
                                                        value={projectId}
                                                        onChange={(e) => {
                                                            setProjectId(e)
                                                            setService([])
                                                            setServiceOption(e.service)
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-2 col-2">
                                                    <Select
                                                        placeholder="Select Service "
                                                        className="basic-multi-select "
                                                        classNamePrefix="select"
                                                        options={serviceOptions}
                                                        value={service}
                                                        onChange={(e) => setService(e)}
                                                    />
                                                </div>
                                                <button className="btn text-white me-1 bg-primary"
                                                    onClick={(e) => onGetActivities()}>
                                                    <i className="fas fa-calendar-alt  search-icon label-icon"></i>
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn text-white me-0 btn-success btn-label"
                                                    disabled={data?.length == 0}
                                                    onClick={() => onApprove()}
                                                >
                                                    <i className="bx bx-check-double me-2 icon-size label-icon"></i>Approve Activities
                                                </button>
                                            </div>
                                        </div>
                                        <div className="responsive-table">
                                            <TableContainer
                                                columns={columns1}
                                                data={data}
                                                packageActivity={packageActivity}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div >
    )
}

export default DmMonthlyApproval
