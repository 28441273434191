import React, { useEffect, useState } from 'react'
import { Form, Input, Label, Row } from 'reactstrap'
import { colourStyles, developmentHours, milestoneType, priorityOption, TimeOption } from 'utils/common-options'
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from 'react-select'
import { fetchData, FetchPostData } from 'utils/common-fetchData'
import secureLocalStorage from 'react-secure-storage'
import Loader from 'pages/Separate/Loader'
import Swal from 'sweetalert2'
import moment from 'moment'

const AddTask = ({ projectId }) => {
    const [formRows, setFormRows] = useState([{}])
    const [isLoading, setLoading] = useState(false)
    const onAddFormRow = () => {
        setFormRows([...formRows, {}])
    }
    const onDeleteFormRow = index => {
        const newInputs = [...formRows]
        newInputs.splice(index, 1)
        setFormRows(newInputs)
    }
    const token = secureLocalStorage.getItem('auth_token');
    const userId = localStorage.getItem('auth_id');
    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    const [title, setTitle] = useState('')
    const [milestone, setMilestone] = useState([]);
    const [milestoneOption, setMilestoneOption] = useState([]);
    const [estTime, setEstTime] = useState([])
    const [probability, setProbability] = useState('');
    const [priority, setPriority] = useState([]);
    const [staffOption, setStaffOption] = useState([]);
    const [assign, setAssign] = useState([]);
    const [description, setDescription] = useState('')
    const onChangeFormRow = (index, newValue) => {
        const updatedRows = [...formRows];
        updatedRows[index].value = newValue;
        setFormRows(updatedRows);
    };

    const onAddNewTask = async (e) => {
        e.preventDefault();
        if (description?.length >= 50) {
            if (priority?.length != 0 && estTime?.length != 0 && milestone?.length != 0 && assign?.length != 0) {
                const now = new Date()
                const formEntries = {
                    project_id: projectId,
                    user_id: userId,
                    title: title,
                    milestone: milestone?.value || "",
                    estimation_hrs: estTime?.value || "",
                    probability: probability,
                    priority: priority?.value || "",
                    assign: assign?.value || "",
                    description: description,
                    subtitle: formRows?.map((obj) => obj?.value)?.join('@#'),
                    type: 2,
                    date: moment(now).format('YYYY-MM-DD')
                }
                const res = await FetchPostData(toggleLoading, 'addTask', token, formEntries);
                if (res?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Task Added Successfully",
                    }).then(() => {
                        setFormRows([{}])
                        setTitle('')
                        setMilestone([])
                        setEstTime([])
                        setProbability('')
                        setPriority([])
                        setAssign([])
                        setDescription('')

                    })
                }
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "Please Fill all the Fields",
                })
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
            })
        }
    }

    const getMilestoneMembers = async () => {
        const res = await fetchData(toggleLoading, `getMilestonesOfProject/${projectId}`, token);
        if (res?.success) {
            const mileOption = res?.data?.milestones?.original?.data
            setStaffOption(res?.data?.resources?.map((obj) => ({
                label: obj?.alias_name,
                value: obj?.user_id,
                type: obj?.type
            })))
            setMilestoneOption(mileOption?.map((obj) => ({
                value: obj.id,
                label: `Milestone - ${obj?.milestone_no} - ${milestoneType?.find((mile) => mile?.value == obj.milestone_type)?.type}`,
                type: obj.milestone_type
            })))
        }
    }

    useEffect(() => {
        if (projectId) {
            getMilestoneMembers()
        }
    }, [projectId])

    return (
        <div>
            {isLoading && <Loader />}
            <div className="modal-body pe-4">
                <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddNewTask}>
                    <div className="my-2 text-end">
                        <button className=" btn btn-save btn-label">
                            <i className="fas fa-save me-2 label-icon"></i>
                            Save
                        </button>
                    </div>
                    <div className="col-md-12 d-flex flex-column new-task-modal ">
                        <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                            <div className="col-md-12  ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Title <span className="text-danger">*</span>
                                </Label>
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                            <div className="col-md-3 ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Milestone <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    placeholder="Select Milestone"
                                    className="basic-multi-select"
                                    classNamePrefix=" select"
                                    options={milestoneOption}
                                    value={milestone}
                                    onChange={(e) => setMilestone(e)}
                                />
                            </div>
                            <div className="col-md-3 ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Estimation Hours <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    placeholder="Select Estimate time"
                                    className="basic-multi-select"
                                    classNamePrefix=" select"
                                    options={developmentHours}
                                    value={estTime}
                                    onChange={e => setEstTime(e)}
                                />
                            </div>
                            <div className="col-md-3 ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Probability (%) <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Probability in (%)"
                                    value={probability}
                                    required
                                    onChange={(e) => setProbability(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Priority <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    placeholder="Select Priority"
                                    className="basic-multi-select"
                                    classNamePrefix=" select"
                                    options={priorityOption}
                                    value={priority}
                                    onChange={(e) => setPriority(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                            <div className="col-md-12 ps-2">
                                <Label htmlFor="formrow-firstname-Input">
                                    Assigned <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    placeholder="Select Staff"
                                    className="basic-multi-select "
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={staffOption}
                                    // isMulti="true"
                                    value={assign}
                                    onChange={(e) => setAssign(e)}
                                />
                            </div>
                            <div className="col-12 ps-2">
                                <label htmlFor="">Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={description || ''}
                                    onReady={editor => { }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setDescription(data)
                                    }}
                                />
                            </div>
                        </div>
                        <Row className="bg-white px-2 py-2 shadow mb-3">
                            <div className="d-flex justify-content-end align-items-end px-0">
                                <div className="text-end mb-3">
                                    <button
                                        onClick={e => {
                                            e.preventDefault()
                                            onAddFormRow()
                                        }}
                                        className="btn btn-purple  "
                                    >
                                        <i className="fas fa-plus me-2"></i>
                                        Add Subtask
                                    </button>
                                </div>
                            </div>
                            {formRows?.map((person, key) => {
                                return (
                                    <div key={key} className="">
                                        <div className="d-flex">
                                            <div className="contact-form d-flex col-md-11">
                                                <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                                    <label htmlFor="">Subtask Name</label>
                                                    <input
                                                        type="text"
                                                        name="task_name"
                                                        placeholder="Enter Subtask Name"
                                                        className="form-control "
                                                        value={person?.value}
                                                        onChange={(e) => onChangeFormRow(key, e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                                                <div className="text-end">
                                                    <button
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            onDeleteFormRow(key)
                                                        }}
                                                        className="btn btn-red"
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {key !== formRows.length - 1 ? (
                                            <div className="border-bottom my-3"></div>
                                        ) : null}
                                    </div>
                                )
                            })}
                        </Row>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default AddTask
