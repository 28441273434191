import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/css/RolesResponse/Roles.css"
import Select from "react-select"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import secureLocalStorage from "react-secure-storage"

const AddRolesDynamic = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("0")
  const [fixedContent, setFixedContent] = useState(false)
  const [fixedSideTab, setFixedSideTab] = useState(false)

  const [global_modal, setGlobalModal] = useState(false)
  const [view_modal, setViewModal] = useState(false)
  const [add_modal, setAddModal] = useState(false)
  const [edit_modal, setEditModal] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setGlobalModal(false)
    setViewModal(false)
    setAddModal(false)
    setEditModal(false)
    setDeleteModal(false)
    removeBodyCss()
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 230) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()
  const params = useParams()

  // title
  document.title = "Roles & Permission  | Zithas Crm"
  useEffect(() => {
    fetch()
    fetch2()
    fetch3()
  }, [])

  const [all_module, setModules] = useState([])
  const [locations, setLocations] = useState([])

  const fetch = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/allModules`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setModules(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const fetch2 = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLocations(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [dashboardList, setDashboardList] = useState([])
  const fetch3 = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/dashboardList`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setDashboardList(options)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [isLoading, setLoading] = useState(false)
  const [role_name, setRoleName] = useState("")
  const [default_list, setDefaultList] = useState([])
  const [default_dashboard, setDefaultDashboard] = useState([])
  const [dashboard, setDashboard] = useState([])

  const onAddRoles = async e => {
    e.preventDefault()
    var x1 = document.querySelectorAll(".first_global")
    var x2 = document.querySelectorAll(".second_view")
    var x3 = document.querySelectorAll(".third_add")
    var x4 = document.querySelectorAll(".fourth_edit")
    var x5 = document.querySelectorAll(".fifth_delete")
    var role = document.querySelectorAll("#module_id")

    var module_id = []
    for (var i = 0; i < role.length; i++) {
      module_id.push(parseInt(role[i].value))
    }

    var one = 0
    for (var i = 0; i < x1.length; i++) {
      if (x1[i].checked == true) {
        one = one + "1"
      } else {
        one = one + "0"
      }
    }
    var second = 0
    for (var i = 0; i < x2.length; i++) {
      if (x2[i].checked == true) {
        second = second + "1"
      } else {
        second = second + "0"
      }
    }
    var third = 0
    for (var i = 0; i < x3.length; i++) {
      if (x3[i].checked == true) {
        third = third + "1"
      } else {
        third = third + "0"
      }
    }
    var fourth = 0
    for (var i = 0; i < x4.length; i++) {
      if (x4[i].checked == true) {
        fourth = fourth + "1"
      } else {
        fourth = fourth + "0"
      }
    }
    var five = 0
    for (var i = 0; i < x5.length; i++) {
      if (x5[i].checked == true) {
        five = five + "1"
      } else {
        five = five + "0"
      }
    }

    var gloabal = one.substring(1).split("").map(Number)
    var view = second.substring(1).split("").map(Number)
    var add = third.substring(1).split("").map(Number)
    var edit = fourth.substring(1).split("").map(Number)
    var delte = five.substring(1).split("").map(Number)

    const array = [module_id, gloabal, view, add, edit, delte]

    const newArray = []
    const subarrayLength = array[0].length

    for (let i = 0; i < subarrayLength; i++) {
      const subarray = array.map(sub => sub[i])
      newArray.push(subarray)
    }

    const StringArray = newArray.map(subarray => subarray.join(","))
    const result = dashboard.map(obj => obj.value).join(",")

    setLoading(true)
    const formData = new FormData()
    formData.append("role_name", role_name)
    formData.append("country_id", params.slug)
    formData.append("permissions", JSON.stringify(StringArray))
    formData.append("dashboard", result)
    formData.append("defaultdashboard", default_dashboard)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addRoles`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Role Added Successfully",
        }).then(data => {
          navigate(`${configure.appUrl}roles`)
        })
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Roles"} />
          {/* Input fields */}
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white ${
                  fixedSideTab ? "fixed-side-tab" : ""
                } h-100`}
              >
                {locations.map((item, index) => {
                  return (
                    <NavItem key={index} className="bd-active-tab">
                      <Link
                        to={
                          item.id != "1"
                            ? `${configure.appUrl}addroles/${item.id}`
                            : `${configure.appUrl}addroles`
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className={
                            params.slug == item.id
                              ? "mb-2 nav-side-item active"
                              : "mb-2 nav-side-item"
                          }
                        >
                          <a>{item.location}</a>
                        </div>
                      </Link>
                    </NavItem>
                  )
                })}
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className="card p-4 bg-white">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  {/* fixed buttons */}
                  <form onSubmit={onAddRoles}>
                    <div
                      className={`flex-column fixed-buttons ${
                        fixedContent ? "d-flex" : "d-none"
                      }`}
                    >
                      <button className="btn btn-save border-radius fix-btn btn-label pe-0">
                        <i className="fas fa-save me-2 label-icon"></i>
                        <span>Save</span>
                      </button>
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-red border-radius fix-btn btn-label pe-0"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        <span>Cancel</span>
                      </button>
                    </div>
                    <Row className="align-items-end justify-space-between">
                      <div className="col-md-12">
                        {locations.map((item, index) => {
                          if (item.id == params.slug) {
                            return (
                              <div
                                className="d-flex justify-content-between"
                                key={index}
                              >
                                <h4 className="mb-3 text-dark">
                                  <img
                                    src={item.Link}
                                    alt="india flag"
                                    width="25px"
                                    height="18px"
                                  />{" "}
                                  {item.location}
                                </h4>
                                <div className="col-md-3 mb-3 text-md-end">
                                  <button className="border-radius btn me-md-0 me-2 btn-save btn-label">
                                    <i className="fas fa-save me-2 label-icon"></i>
                                    SAVE
                                  </button>
                                  <button
                                    onClick={() => navigate(-1)}
                                    className="border-radius btn ms-md-2 btn-red btn-label"
                                  >
                                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                    CANCEL
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div className="col-md-3 mt-2 mb-3">
                        <div className="form-group">
                          <label>Role Name</label>
                          <input
                            type="text"
                            placeholder="Add role"
                            className="form-control form-control-solid form-control-md"
                            name="role_name"
                            onChange={e => setRoleName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-2 mb-3">
                        <div className="">
                          <label>Dashboard Elements</label>
                          <Select
                            placeholder="Select Dashboard "
                            className="basic-multi-select"
                            classNamePrefix="border-radius select"
                            isMulti
                            options={dashboardList}
                            onChange={e => {
                              setDefaultList(e)
                              setDashboard(e)
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-3 mt-2 mb-3">
                        <div className="form-group">
                          <label>Default Dashboard</label>
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="border-radius select"
                            options={default_list}
                            onChange={e => setDefaultDashboard(e.value)}
                          />
                        </div>
                      </div> */}

                      {/* <div className="col-md-9 mt-2 text-md-end">
                        <button className="border-radius btn me-md-0 me-2 btn-purple">
                          <i className="fas fa-save me-2"></i>SAVE
                        </button>
                        <a
                          onClick={() => navigate(-1)}
                          className="border-radius btn ms-md-2 btn-red"
                        >
                          <i className="dripicons-cross me-2 icon-center"></i>
                          CANCEL
                        </a>
                      </div> */}
                    </Row>
                    <div className="responsive-table mt-2 overflow-auto shadow">
                      <Table className="table table-bordered m-0 text-center mt-4 role-table">
                        <thead
                          className={`${
                            fixedContent ? "fixed-header pt-1" : ""
                          }`}
                        >
                          <tr>
                            <th className="text-start ps-4">Module</th>
                            <th className="cell-width">
                              Global
                              <a onClick={() => setGlobalModal(true)}>
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon "
                                ></i>
                              </a>
                            </th>
                            <th className="cell-width">
                              View
                              <a onClick={() => setViewModal(true)}>
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon"
                                ></i>
                              </a>
                            </th>
                            <th className="cell-width">
                              Add
                              <a onClick={() => setAddModal(true)}>
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon"
                                ></i>
                              </a>
                            </th>
                            <th className="cell-width">
                              Update
                              <a onClick={() => setEditModal(true)}>
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon"
                                ></i>
                              </a>
                            </th>
                            <th className="cell-width">
                              Delete
                              <a onClick={() => setDeleteModal(true)}>
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon"
                                ></i>
                              </a>
                            </th>
                            <th className="cell-width">More</th>
                          </tr>
                        </thead>
                        <tbody>
                          {all_module.map((module, i) => {
                            const checkboxsGlobal =
                              document.getElementById("Dashboardglobal")
                            const checkboxsView =
                              document.getElementById("Dashboardview")
                            const checkboxsAdd =
                              document.getElementById("Dashboardadd")
                            const checkboxsEdit =
                              document.getElementById("Dashboardedit")
                            const checkboxsDelete =
                              document.getElementById("Dashboarddelete")
                            if (checkboxsGlobal != null) {
                              checkboxsGlobal.setAttribute("checked", "true")
                              checkboxsGlobal.setAttribute("disabled", "true")
                            }
                            if (checkboxsView != null) {
                              checkboxsView.setAttribute("checked", "true")
                              checkboxsView.setAttribute("disabled", "true")
                            }
                            if (checkboxsAdd != null) {
                              checkboxsAdd.setAttribute("checked", "true")
                              checkboxsAdd.setAttribute("disabled", "true")
                            }
                            if (checkboxsEdit != null) {
                              checkboxsEdit.setAttribute("checked", "true")
                              checkboxsEdit.setAttribute("disabled", "true")
                            }
                            if (checkboxsDelete != null) {
                              checkboxsDelete.setAttribute("checked", "true")
                              checkboxsDelete.setAttribute("disabled", "true")
                            }
                            const hasSubmodules = module.submodules.length > 0
                            return (
                              <tr key={i} className="">
                                <td className="p-0" colSpan={7}>
                                  <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                  >
                                    <div className="accordion-item accordian-box">
                                      <div
                                        className="accordion-header"
                                        id="panelsStayOpen-headingOne"
                                        // id={`panelsStayOpen-heading${i}`}
                                      >
                                        <button
                                          className={
                                            module.submodules.length == 0
                                              ? "accordion-button collapsed accordian-btn no-after bd-accordian-btn"
                                              : "accordion-button collapsed accordian-btn bd-accordian-btn"
                                          }
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={
                                            module.submodules.length == 0
                                              ? ``
                                              : `#panelsStayOpen-collapse${i}`
                                          }
                                          aria-expanded="true"
                                          aria-controls={`panelsStayOpen-collapse${i}`}
                                          // className={
                                          //   hasSubmodules
                                          //     ? "accordion-button collapsed accordian-btn bd-accordian-btn"
                                          //     : "accordion-button collapsed accordian-btn no-after bd-accordian-btn"
                                          // }
                                          // type="button"
                                          // data-bs-toggle={
                                          //   hasSubmodules ? "collapse" : ""
                                          // }
                                          // data-bs-target={
                                          //   hasSubmodules
                                          //     ? `#panelsStayOpen-collapse${i}`
                                          //     : ""
                                          // }
                                          // aria-expanded={
                                          //   hasSubmodules ? "false" : "true"
                                          // }
                                          // aria-controls={
                                          //   hasSubmodules
                                          //     ? `panelsStayOpen-collapse${i}`
                                          //     : ""
                                          // }
                                        >
                                          {module.module_name}
                                        </button>
                                        <input
                                          id="module_id"
                                          value={module.module_id}
                                          readOnly
                                          className="display-none bd-text-center"
                                        />
                                        <div className="outer-toggle-btns">
                                          <div className="form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input first_global"
                                              id={
                                                module.module_name.replace(
                                                  " ",
                                                  "_"
                                                ) + "global"
                                              }
                                            />
                                          </div>
                                          <div className="form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className={`form-check-input second_view`}
                                              id={
                                                module.module_name.replace(
                                                  " ",
                                                  "_"
                                                ) + "view"
                                              }
                                              // defaultChecked="true"
                                            />
                                          </div>
                                          <div className="form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className={`form-check-input third_add ${
                                                module.module_id === 1
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                              id={
                                                module.module_name.replace(
                                                  " ",
                                                  "_"
                                                ) + "add"
                                              }
                                              // defaultChecked="true"
                                            />
                                          </div>
                                          <div className="form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input fourth_edit"
                                              id={
                                                module.module_name.replace(
                                                  " ",
                                                  "_"
                                                ) + "edit"
                                              }
                                              // defaultChecked="true"
                                            />
                                          </div>
                                          <div className="form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className={`form-check-input fifth_delete ${
                                                module.module_id == 1
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                              id={
                                                module.module_name.replace(
                                                  " ",
                                                  "_"
                                                ) + "delete"
                                              }
                                              // defaultChecked="true"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        id={`panelsStayOpen-collapse${i}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingOne"
                                      >
                                        <div className="accordion-body p-0 ">
                                          <Table className="mb-0 bd-hover-inner bg-grey">
                                            {module.submodules.map(
                                              (innerModule, index) => {
                                                if (module.module_name) {
                                                  var one = 0
                                                  var two = 0
                                                  var three = 0
                                                  var four = 0
                                                  var five = 0
                                                  var x1 =
                                                    document.querySelectorAll(
                                                      "#" +
                                                        module.module_name.replace(
                                                          " ",
                                                          "_"
                                                        ) +
                                                        "one"
                                                    )
                                                  var x2 =
                                                    document.querySelectorAll(
                                                      "#" +
                                                        module.module_name.replace(
                                                          " ",
                                                          "_"
                                                        ) +
                                                        "two"
                                                    )
                                                  var x3 =
                                                    document.querySelectorAll(
                                                      "#" +
                                                        module.module_name.replace(
                                                          " ",
                                                          "_"
                                                        ) +
                                                        "three"
                                                    )
                                                  var x4 =
                                                    document.querySelectorAll(
                                                      "#" +
                                                        module.module_name.replace(
                                                          " ",
                                                          "_"
                                                        ) +
                                                        "four"
                                                    )
                                                  var x5 =
                                                    document.querySelectorAll(
                                                      "#" +
                                                        module.module_name.replace(
                                                          " ",
                                                          "_"
                                                        ) +
                                                        "five"
                                                    )
                                                  // Particular Loop for One
                                                  for (
                                                    var i = 0;
                                                    i < x1.length;
                                                    i++
                                                  ) {
                                                    if (x1[i].checked == true) {
                                                      one = one + "1"
                                                    } else {
                                                      one = one + "0"
                                                    }
                                                  }
                                                  // Particular Loop for two
                                                  for (
                                                    var i = 0;
                                                    i < x2.length;
                                                    i++
                                                  ) {
                                                    if (x2[i].checked == true) {
                                                      two = two + "1"
                                                    } else {
                                                      two = two + "0"
                                                    }
                                                  }
                                                  // Particular Loop for Three
                                                  for (
                                                    var i = 0;
                                                    i < x3.length;
                                                    i++
                                                  ) {
                                                    if (x3[i].checked == true) {
                                                      three = three + "1"
                                                    } else {
                                                      three = three + "0"
                                                    }
                                                  }
                                                  // Particular Loop for Four
                                                  for (
                                                    var i = 0;
                                                    i < x4.length;
                                                    i++
                                                  ) {
                                                    if (x4[i].checked == true) {
                                                      four = four + "1"
                                                    } else {
                                                      four = four + "0"
                                                    }
                                                  }
                                                  // Particular Loop for Five
                                                  for (
                                                    var i = 0;
                                                    i < x5.length;
                                                    i++
                                                  ) {
                                                    if (x5[i].checked == true) {
                                                      five = five + "1"
                                                    } else {
                                                      five = five + "0"
                                                    }
                                                  }

                                                  let percentage
                                                  let Twopercentage
                                                  let Threepercentage
                                                  let Fourpercentage
                                                  let Fivepercentage
                                                  if (one.length > 0) {
                                                    const onesLength = (
                                                      one
                                                        .substring(1)
                                                        .match(/1/g) || []
                                                    ).length
                                                    const towLength = (
                                                      two
                                                        .substring(1)
                                                        .match(/1/g) || []
                                                    ).length
                                                    const threeLength = (
                                                      three
                                                        .substring(1)
                                                        .match(/1/g) || []
                                                    ).length
                                                    const fourLength = (
                                                      four
                                                        .substring(1)
                                                        .match(/1/g) || []
                                                    ).length
                                                    const fiveLength = (
                                                      five
                                                        .substring(1)
                                                        .match(/1/g) || []
                                                    ).length
                                                    percentage =
                                                      (onesLength /
                                                        module.submodules
                                                          .length) *
                                                      100
                                                    Twopercentage =
                                                      (towLength /
                                                        module.submodules
                                                          .length) *
                                                      100
                                                    Threepercentage =
                                                      (threeLength /
                                                        module.submodules
                                                          .length) *
                                                      100
                                                    Fourpercentage =
                                                      (fourLength /
                                                        module.submodules
                                                          .length) *
                                                      100
                                                    Fivepercentage =
                                                      (fiveLength /
                                                        module.submodules
                                                          .length) *
                                                      100
                                                  }

                                                  if (percentage <= 25) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.add("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("green")

                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = false
                                                  } else if (
                                                    percentage >= 25 &&
                                                    percentage <= 50
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.add("yellow")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("green")

                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    percentage >= 50 &&
                                                    percentage <= 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.add(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("green")

                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (percentage > 75) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.add("green")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )

                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "global"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  }

                                                  if (Twopercentage <= 25) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.add("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = false
                                                  } else if (
                                                    Twopercentage >= 25 &&
                                                    Twopercentage <= 50
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.add("yellow")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Twopercentage >= 50 &&
                                                    Twopercentage <= 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.add(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Twopercentage > 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.add("green")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "view"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  }

                                                  if (Threepercentage <= 25) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.add("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = false
                                                  } else if (
                                                    Threepercentage >= 25 &&
                                                    Threepercentage <= 50
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.add("yellow")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Threepercentage >= 50 &&
                                                    Threepercentage <= 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.add(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Threepercentage > 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.add("green")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "add"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  }

                                                  if (Fourpercentage <= 25) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.add("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = false
                                                  } else if (
                                                    Fourpercentage >= 25 &&
                                                    Fourpercentage <= 50
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.add("yellow")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Fourpercentage >= 50 &&
                                                    Fourpercentage <= 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.add(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Fourpercentage > 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.add("green")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "edit"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  }

                                                  if (Fivepercentage <= 25) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.add("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = false
                                                  } else if (
                                                    Fivepercentage >= 25 &&
                                                    Fivepercentage <= 50
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.add("yellow")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Fivepercentage >= 50 &&
                                                    Fivepercentage <= 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.add(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("green")
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  } else if (
                                                    Fivepercentage > 75
                                                  ) {
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.add("green")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove("red")
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "ligth-green"
                                                      )
                                                    document
                                                      .getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                      .classList.remove(
                                                        "yellow"
                                                      )
                                                    const checkbox =
                                                      document.getElementById(
                                                        `${
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) + "delete"
                                                        }`
                                                      )
                                                    checkbox.defaultChecked = true
                                                  }
                                                }

                                                return (
                                                  <tr key={index}>
                                                    <td className="border text-start ps-4 text-black">
                                                      <i
                                                        className="bx bx-chevrons-right"
                                                        style={{
                                                          padding:
                                                            "0px 5px 0px 0px",
                                                        }}
                                                      ></i>
                                                      <input
                                                        id="module_id"
                                                        value={
                                                          innerModule.module_id
                                                        }
                                                        className="display-none"
                                                        readOnly
                                                      />
                                                      {innerModule.module_name}
                                                    </td>
                                                    <td className="border cell-width ">
                                                      <div className=" form-switch form-switch-md text-center">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input first_global"
                                                          id={
                                                            module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "one"
                                                          }
                                                          // defaultChecked="true"
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="border cell-width ">
                                                      <div className="form-switch form-switch-md">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input second_view"
                                                          id={
                                                            module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "two"
                                                          }
                                                          // defaultChecked="true"
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="border cell-width">
                                                      <div className="form-switch form-switch-md">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input third_add"
                                                          id={
                                                            module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "three"
                                                          }
                                                          // defaultChecked="true"
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="border cell-width">
                                                      <div className=" form-switch form-switch-md">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input fourth_edit"
                                                          id={
                                                            module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "four"
                                                          }
                                                          // defaultChecked="true"
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="border cell-width">
                                                      <div className=" form-switch form-switch-md">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input fifth_delete"
                                                          id={
                                                            module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "five"
                                                          }
                                                          // defaultChecked="true"
                                                        />
                                                      </div>
                                                    </td>
                                                    <td className="cell-width"></td>
                                                  </tr>
                                                )
                                              }
                                            )}
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </form>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Global Modal */}
      <Modal
        isOpen={global_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Global Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setGlobalModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* View Modal */}
      <Modal
        isOpen={view_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            View Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setViewModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Add Modal */}
      <Modal
        isOpen={add_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Add Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setAddModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={edit_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Update Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setEditModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={delete_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Delete Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setDeleteModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AddRolesDynamic
