import Loader from 'pages/Separate/Loader';
import React, { useState, useRef } from 'react';
import { Form, Label, Modal } from 'reactstrap';
import Swal from 'sweetalert2';
import { FetchPostData } from './common-fetchData';
import secureLocalStorage from 'react-secure-storage';

const ResizableTable = ({ activityColumns, rowsData, handleInputChange, setColumnListId, getTiming, handleCheckboxChange, addTime, allTimeList, onApprovData, isCheckingbuton }) => {
  const [columnsWidth, setColumnsWidth] = useState(Array(activityColumns.length).fill(300)); // Initial width 150px for all columns
  const resizers = useRef([]);
  const [tooltip, setTooltip] = useState(null);

  // Mouse down event when user starts resizing
  const onMouseDown = (index, e) => {
    const startX = e.clientX;
    const startWidth = columnsWidth[index];

    const onMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      setColumnsWidth((prevWidths) => {
        const newWidths = [...prevWidths];
        newWidths[index] = Math.max(newWidth, 50); // Prevent resizing smaller than 50px
        return newWidths;
      });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const copyToClipboard = (text, colIndex, rowIndex) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setTooltip({ colIndex, message: 'Copied!', rowIndex });
    setTimeout(() => {
      setTooltip(null);
    }, 1000);
  };

  const [approveLink, setApproveLink] = useState('')
  const [remark, setRemark] = useState('')
  const [reportColumnId, setReportColumnId] = useState('')
  const [checkingActivity, setCheckingActivity] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const onAddApprove = async (e) => {
    e.preventDefault();
    const formEntries = {
      approve_link: approveLink,
      column_id: reportColumnId,
      remark: remark,
      user_id: localStorage.getItem('auth_id')
    }
    const res = await FetchPostData(toggleLoading, 'updateDigitalActivityColumnsLink', secureLocalStorage.getItem('auth_token'), formEntries);
    if (res?.data?.success) {
      Swal.fire({
        icon: 'success',
        text: 'Approved Link Added Successfully',
      }).then(() => {
        setApproveLink('')
        setRemark('')
        onApprovData(true)
        setCheckingActivity(false)
      })
    }
  }

  return (
    <div style={{ overflowX: 'auto', scrollbarWidth: "thin" }}>
      <table className="col-12 table table-bordered all_activity_table w-100" style={{ tableLayout: 'fixed' }}>
        <thead className="col-12">
          <tr>
            <th scope="col" className="col-1 text-center" style={{ width: "30px" }}>
              ID
            </th>
            {activityColumns?.map((obj, i) => (
              <th
                scope="col"
                className=" text-center"
                key={i}
                style={{ width: `${columnsWidth[i]}px`, position: 'relative' }}
              >
                {obj}
                <div
                  className="resizer"
                  onMouseDown={(e) => onMouseDown(i, e)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '10px',
                    height: '100%',
                    cursor: 'col-resize',
                    zIndex: 1,
                  }}
                />
              </th>
            ))}
            <th scope="col" className="act_id text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td scope="row" className="text-center">
                {rowIndex + 1}
              </td>
              {activityColumns.map((col, colIndex) => (
                <td key={col}>
                  <div className="input-container" style={{ position: 'relative' }}>
                    <input
                      type="text"
                      value={row[col]}
                      className="form-control"
                      onChange={(e) => handleInputChange(rowIndex, col, e.target.value)}
                      placeholder={`Enter ${col}`}
                    />
                    <span className='copy-input'>
                      <i
                        className="bi bi-clipboard-fill"
                        onClick={() => copyToClipboard(row[col], colIndex, rowIndex)}
                      />
                    </span>
                    {tooltip && tooltip.colIndex == colIndex && tooltip.rowIndex == rowIndex && (
                      <div
                        className="copy-tooltip"
                      >
                        {tooltip.message}
                      </div>
                    )}
                  </div>
                </td>
              ))}
              <td className="d-flex justify-content-center align-items-center">
                <div className="custim-width contact-btns d-flex justify-content-center align-items-center mb-md-0 mt-3">
                  <div className="custom_info_box">
                    <button
                      title="Add Hours"
                      className="btn btn-sm btn-outline-secondary me-1"
                      onClick={() => {
                        setColumnListId(row?.id);
                        getTiming(row?.id);
                      }}
                      type="button"
                      disabled={row?.id == undefined || row?.id == ''}
                    >
                      <i className="bi bi-alarm-fill"></i>
                    </button>
                  </div>
                  <div className="text-center" id="customCheckbox">
                    <input
                      type="checkbox"
                      className="form-check-input custom-checkbox mt-0 ms-2"
                      id="customCheck-outlinecolor2"
                      checked={row?.statusActivity ? true : false}
                      style={{ marginRight: "16px" }}
                      onClick={(e) => {
                        const isAddedTime = allTimeList?.filter((obj) => obj?.column_id == row?.id)?.length;
                        if (isAddedTime == 0) {
                          Swal.fire({
                            icon: 'warning',
                            title: 'No time added for this column',
                          });
                        } else {
                          handleCheckboxChange(rowIndex);
                          addTime(e, 2, row?.id);
                        }
                      }}
                    />
                  </div>
                  {isCheckingbuton ? (
                    <button className="btn btn-sm btn-outline-secondary mb-2" title="Checking Report"
                      onClick={() => {
                        setReportColumnId(row?.id)
                        setRemark(row?.remark)
                        setApproveLink(row?.link)
                        setCheckingActivity(true)
                      }}
                      disabled={row?.id == undefined || row?.id == ''}
                      type="button" >
                      <i className="fas fa-file-contract "></i>
                    </button>
                  ) : null}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={checkingActivity}
        toggle={() => {
          setCheckingActivity(false)
        }}
        centered
        size="md"
        className=""
      >
        {isLoading && <Loader />}
        <div className="modal-header">
          <h5 className="modal-title mt-0">Checking Activity</h5>
          <button
            type="button"
            onClick={() => {
              setCheckingActivity(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row d-flex justify-content-center align-items-center" >
            <div
              className="d-flex flex-column new-task-modal mt-2"
            >
              <div className="col-12  py-2 ">
                <div className="col-12 d-flex flex-column justify-content-center align-items-start gap-3">
                  <div className="col-12 flex-column">
                    <Label>Approved Link</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Approved Link"
                      value={approveLink}
                      onChange={(e) => setApproveLink(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-12 d-flex flex-column">
                    <Label>Remark</Label>
                    <textarea
                      cols="20"
                      rows="5"
                      className="form-control"
                      placeholder="Enter Remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="d-flex flex-column">
                    <button className="btn btn-success btn-label text-end"
                      type='button'
                      onClick={onAddApprove}
                    >
                      <i className="fas fa-check me-2 label-icon"></i>
                      Approved
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResizableTable;
