import React, { useState } from "react"
import { Navigate } from "react-router-dom"

// Profile
import Reports from "pages/Reports/Reports/index"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Teams
import Roles from "pages/RolesRespons"
import EditRoles from "pages/RolesRespons/EditRoles"
import AddRoles from "pages/RolesRespons/AddRoles"
import Staff from "pages/Staff/index"
import AddStaff from "pages/Staff/AddStaff"
import EditStaff from "pages/Staff/EditStaff/EditStaff"

// setting Module
import CompanySettingIndex from "pages/Setting/CompanySettings"
import GeneralSettingIndex from "pages/Setting/GeneralSettings"
import CategoryIndex from "pages/Setting/Category"
import BiddingIndex from "pages/Setting/Binding"

// Sales Module > Lead
import Lead from "pages/Sales/Lead"
import AddLead from "pages/Sales/Lead/AddLead"
import EditLead from "pages/Sales/Lead/EditLead/EditLead"

// Sales Module > Introducer
import Introducer from "pages/Sales/Introducer"
import AddIntroducer from "pages/Sales/Introducer/AddIntroducer"
import EditIntroducer from "pages/Sales/Introducer/EditIntroducer/EditIntroducer"

// Sales Module > Proposal
import Proposal from "pages/Sales/Proposal"
import AddProposal from "pages/Sales/Proposal/AddProposal"
import EditProposal from "pages/Sales/Proposal/EditProposal/EditProposal"

// Sales Module > Contract
import Contract from "pages/Sales/Contract"
import AddContract from "pages/Sales/Contract/AddContract"
import EditContract from "pages/Sales/Contract/EditContract"
import ViewContract from "pages/Sales/Contract/ViewContract"

// Sales Module > Customers
import Customers from "pages/Sales/Customer"
import AddCustomer from "pages/Sales/Customer/AddCustomer"
import EditCustomer from "pages/Sales/Customer/EditCustomer/EditCustomer"
import AddContact from "pages/Sales/Customer/EditCustomer/AddContact"

// setting
import AddTemplate from "pages/Setting/Introducer/AddTemplate"
import EditTemplate from "pages/Setting/Introducer/EditTemplate"
import ContractIndex from "pages/Setting/Contract"
import ContractAddTemplate from "pages/Setting/Contract/ContractAddTemplate"
import ContractEditTemplate from "pages/Setting/Contract/ContractEditTemplate"
import ProposalIndex from "pages/Setting/Proposal"
import IcodeIndex from "pages/Setting/Icode"
import IndustryIndex from "pages/Setting/Industry"
import HostingIndex from "pages/Setting/Hosting"
import LeadIndex from "pages/Setting/Lead"
import IntroducerIndex from "pages/Setting/Introducer"
import AddRolesDynamic from "pages/RolesRespons/AddRolesDynamic"
import Payment from "pages/Finance/Expense/Payment"
import VendorPay from "pages/Finance/Expense/VendorPay"
import BankAccount from "pages/Finance/BankAccount/BankAccount"
import CreditCard from "pages/Finance/Credit Card/CreditCard"
import GstIndex from "pages/Finance/GST/GstIndex"
import TdsIndex from "pages/Finance/TDS/TdsIndex"
import ZtplIndex from "pages/Finance/Performa Invoice/Ztpl/ZtplIndex"
import AddPrfInvoice from "pages/Finance/Performa Invoice/Ztpl/AddPrfInvoice"
import EditPrfInvoice from "pages/Finance/Performa Invoice/Ztpl/EditPrfInvoice"
import ViewPrfInvoice from "pages/Finance/Performa Invoice/Ztpl/ViewPrfInvoice"
import TsgIndex from "pages/Finance/Performa Invoice/TSG/TsgIndex"
import AddTsgInvoice from "pages/Finance/Performa Invoice/TSG/AddTsgInvoice"
import ViewTsgInvoice from "pages/Finance/Performa Invoice/TSG/ViewTsgInvoice"
import EditTsgInvoice from "pages/Finance/Performa Invoice/TSG/EditTsgInvoice"
import ZithasTechIndex from "pages/Finance/Invoice/Ztpl/ZithasTechIndex"
import ViewZithasTechInvoice from "pages/Finance/Invoice/Ztpl/ViewZithasTechInvoice"
import ZithasInvoiceIndex from "pages/Finance/Invoice/Zithas/ZithasInvoiceIndex"
import ViewZithasInvoice from "pages/Finance/Invoice/Zithas/ViewZithasInvoice"
import AddZithasInvoice from "pages/Finance/Invoice/Zithas/AddZithasInvoice"
import EditZithasInvoice from "pages/Finance/Invoice/Zithas/EditZithasInvoice"
import CloneZithasInvoice from "pages/Finance/Invoice/Zithas/CloneZithasInvoice"
import StaffGuruIndex from "pages/Finance/Invoice/Staff Guru/StaffGuruIndex"
import ViewStaffGuruInvoice from "pages/Finance/Invoice/Staff Guru/ViewStaffGuruInvoice"
import ZithasPInvoiceIndex from "pages/Finance/Performa Invoice/Zithas/ZithasPInvoiceIndex"
import ColdCallingIndex from "pages/Marketing/Cold Calling/ColdCallingIndex"
import AddColdCalling from "pages/Marketing/Cold Calling/AddColdCalling"
import EditColdCalling from "pages/Marketing/Cold Calling/EditColdcalling/EditColdCalling"
import MarketBiddingIndex from "pages/Marketing/Bidding/MarketBiddingIndex"
import EditBidding from "pages/Marketing/Bidding/EditBidding/EditBidding"
import DataBankIndex from "pages/Marketing/Opportunity/Data Bank/DataBankIndex"
import ViewDataBank from "pages/Marketing/Opportunity/Data Bank/ViewDataBank"
import AccessDenied from "./AccessDenied"
import EditContact from "pages/Sales/Customer/EditCustomer/EditContact"
import GeneratedContract from "pages/Sales/Customer/EditCustomer/GeneratedContract"
import GenerateLinkContract from "pages/Sales/Contract/GenerateContract"
import configure from "configure"
import EditForm from "pages/Marketing/Opportunity/Data Bank/EditForm/EditForm"
import ViewColdCalling from "pages/Marketing/Cold Calling/ViewColdCalling"
import ResourcePlanIndex from "pages/Work/ResourcePlan/ResourcePlanIndex"
import Booking from "pages/Marketing/Booking/Booking"
import CurrentOpening from "pages/Planet/CurrentOpening/CurrentOpening"
import AddCurrentOpening from "pages/Planet/CurrentOpening/AddCurrentOpening"
import EditCurrentOpening from "pages/Planet/CurrentOpening/EditCurrentOpening"
import Testimonial from "pages/Planet/Testimonial/Testimonial"
import AddTestimonial from "pages/Planet/Testimonial/AddTestimonial"
import EditTestimonial from "pages/Planet/Testimonial/EditTestimonial"
import CalenderIndex2 from "pages/Calender/CalenderIndex2"
import ImportLogs from "pages/Marketing/Opportunity/ImportLogs"
import Crendential from "pages/Credentials/Crendential"
import EditCredential from "pages/Setting/GeneralSettings/EditCredentials/EditIndex"
import Profile from "pages/Profile/Profile"

import ViewDocument from "pages/Profile/ViewDocument"

import RolesPermission from "pages/AuditLog/RolesPermission"
import KnowledgeBase from "pages/KnowledgeBase/KnowledgeBase"
import AddCredential from "pages/Setting/GeneralSettings/AddCredentials/AddIndex"
import EditTopic from "pages/KnowledgeBase/EditTopic/EditTopic"
import AddTopic from "pages/KnowledgeBase/AddTopic/AddTopic"
import ViewTopic from "pages/KnowledgeBase/ViewTopic/ViewTopic"
import EditDocument from "pages/Profile/EditDocument"
import KnowledgeIndex from "pages/Setting/Knowledge Base"
import ProjectIndex from "pages/WorkModule/Projects/ProjectIndex"

import AddProject from "pages/WorkModule/Projects/AddProject"
import EditProject from "pages/WorkModule/Projects/EditProject/EditProject"
import ViewProject from "pages/WorkModule/Projects/ViewProject/ViewProject"
import EditDedicatedProject from "pages/WorkModule/Projects/EditProject/EditDedicatedProject"
import EditMaintenanceProject from "pages/WorkModule/Projects/EditProject/EditMainenanceProject"
import VendorsPay from "pages/FinanceModule/Expence/VendorsPay"
import Expense from "pages/FinanceModule/Expence/Expense"
import BudgetExpense from "pages/FinanceModule/Expence/Budget"
import CrediCardSection from "pages/FinanceModule/Expence/CreditCardSection"

// Work -> General Settings
import GeneralTask from "pages/WorkModule/GeneralTask/GeneralTask"
import SwipeAndIssuesIndex from "pages/HumanResources/Swipe_and_Issues/SwipeAndIssuesIndex"
import ViewApproveDoc from "pages/Dashboard/ViewApproveDoc"
import DragDashboard from "pages/Dashboard/DragDashboard"
import NotificationPage from "pages/Setting/NotificationPage"
import MainDmResourcePlan from "pages/WorkModule/Resource/MainDmResourcePlan"
import TeamsWeeklyPlan from "pages/WorkModule/Resource/TeamsWeeklyPlan"
import ProjectWeeklyPlan from "pages/WorkModule/Resource/ProjectWeeklyPlan"
import DigitalPlanIndex from "pages/WorkModule/Project Plan/DigitalPlanIndex"
import MainDmProjectPlan from "pages/WorkModule/Project Plan/MainDmProjectPlan"
import DigitalMarketingIndex from "pages/WorkModule/Digital Marketing/DigitalMarketingIndex"
import DmAddProject from "pages/WorkModule/Digital Marketing/DmAddProject"
import EditProjectIndex from "pages/WorkModule/Digital Marketing/EditProject/EditProjectIndex"
import DmSharedNotes from "pages/WorkModule/Digital Marketing/ViewProject/DmSharedNotes"
import DigitalMarketingSeting from "pages/Setting/Digital Marketing/DigitalMarketingSeting"
import CompleteSprint from "pages/WorkModule/Project Plan/CompleteSprint"
import OverDraft from "pages/Finance/Expense/Overdraft/OverDraft"
import OverdraftIndex from "pages/Setting/Finance/Overdraft/OverdraftIndex"
import DmMonthlyApproval from "pages/WorkModule/Project Plan/Monthly Approval/DmMonthlyApproval"
import MonthlyPlanning from "pages/WorkModule/Project Plan/Monthly planning/MonthlyPlanning"
import ProjectResourcePlanIndex from "pages/WorkModule/Projects/ProjectPlan/ProjectPlanIndex"
import ProjectSprintIndex from "pages/WorkModule/Projects/ProjectPlan/ProjectSprintIndex"
import ResourceIndex from "pages/WorkModule/Resource/ResourceIndex"
import PmResourcePlanIndex from "pages/WorkModule/Resource/PmResourcePlanIndex"
import GanttChart from "pages/Gantt Chart/GanttChart"
import ProjectTickets from "pages/WorkModule/Support/Tickets/ProjectTickets"
import SupportIndex from "pages/WorkModule/Support/Tickets/SupportIndex"
import TestCaseIndex from "pages/WorkModule/Support/Test Cases/TestCaseIndex"
import CaseIndex from "pages/WorkModule/Support/Test Cases/Cases/CaseIndex"
import ProjectTestIndex from "pages/WorkModule/Support/Test Cases/Project Test/ProjectTestIndex"
import SalaryIndex from "pages/HumanResources/Payroll Management/SalaryProcess/SalaryIndex"
import SalarySlip from "pages/HumanResources/Payroll Management/SalarySlip/SalarySlip"
import GeneratedSalarySlip from "pages/HumanResources/Payroll Management/SalarySlip/GeneratedSalarySlip"
import LeaveManagement from "pages/HumanResources/Payroll Management/LeaveManagement/LeaveManagement"

const authProtectedRoutes = [
  {
    path: `${configure.appUrl}dashboard`,
    component: <DragDashboard />,
    // component: <Dashboard />,
  },
  {
    path: `${configure.appUrl}dashboard/drag_drop`,
    component: <Dashboard />,
    // component: <DragDashboard />,
  },
  {
    path: `${configure.appUrl}profile`,
    component: <Profile />,
  },
  {
    path: `${configure.appUrl}roles`,
    component: <Roles />,
    module_id: "69",
    type: "view",
  },
  {
    path: `${configure.appUrl}editroles/:id`,
    component: <EditRoles />,
    module_id: "69",
    type: "edit",
  },
  {
    path: `${configure.appUrl}addroles`,
    component: <AddRoles />,
    module_id: "69",
    type: "add",
  },
  {
    path: `${configure.appUrl}addroles/:slug`,
    component: <AddRolesDynamic />,
    module_id: "69",
    type: "add",
  },
  {
    path: `${configure.appUrl}staff`,
    component: <Staff />,
    module_id: "66",
    type: "view",
  },
  {
    path: `${configure.appUrl}addstaff`,
    component: <AddStaff />,
    module_id: "66",
    type: "add",
  },
  {
    path: `${configure.appUrl}edit-staff/:id`,
    component: <EditStaff />,
    module_id: "66",
    type: "edit",
  },
  {
    path: `${configure.appUrl}calender`,
    component: <CalenderIndex2 />,
    module_id: "85",
    type: "view",
  },
  {
    path: `${configure.appUrl}calender/:id`,
    component: <CalenderIndex2 />,
    module_id: "85",
    type: "view",
  },
  {
    path: `${configure.appUrl}marketing/opportunity/logs`,
    component: <ImportLogs />,
  },
  {
    path: `${configure.appUrl}viewapprovedoc/:id`,
    component: <ViewApproveDoc />,
    module_id: "66",
    type: "edit",
  },


  // Sales Module
  {
    path: `${configure.appUrl}setting/lead`,
    component: <LeadIndex />,
    moduleid: "3",
  },
  {
    path: `${configure.appUrl}setting/introducer`,
    component: <IntroducerIndex />,
  },
  {
    path: `${configure.appUrl}setting/add-template`,
    component: <AddTemplate />,
  },
  {
    path: `${configure.appUrl}setting/edit-template`,
    component: <EditTemplate />,
  },
  { path: `${configure.appUrl}setting/contract`, component: <ContractIndex /> },
  {
    path: `${configure.appUrl}setting/add-contract-template`,
    component: <ContractAddTemplate />,
  },
  {
    path: `${configure.appUrl}setting/edit-contract-template`,
    component: <ContractEditTemplate />,
  },
  { path: `${configure.appUrl}setting/proposal`, component: <ProposalIndex /> },

  // setting module
  {
    path: `${configure.appUrl}setting/companysetting`,
    component: <CompanySettingIndex />,
    moduleid: "70",
    type: "view",
  },
  {
    path: `${configure.appUrl}setting/generalsetting`,
    component: <GeneralSettingIndex />,
    module_id: "73",
    type: "view",
  },
  {
    path: `${configure.appUrl}setting/generalsetting/:id`,
    component: <EditCredential />,
  },
  {
    path: `${configure.appUrl}setting/generalsetting/addtab`,
    component: <AddCredential />,
  },
  {
    path: `${configure.appUrl}setting/category`,
    component: <CategoryIndex />,
    module_id: "79",
    type: "view",
  },
  { path: `${configure.appUrl}setting/bidding`, component: <BiddingIndex /> },

  // Sales Module > Lead
  {
    path: `${configure.appUrl}sales/lead`,
    component: <Lead />,
    module_id: "17",
    type: "view",
  },
  {
    path: `${configure.appUrl}sales/add-lead`,
    component: <AddLead />,
    module_id: "17",
    type: "add",
  },
  {
    path: `${configure.appUrl}sales/edit-lead/:id`,
    component: <EditLead />,
    module_id: "17",
    type: "edit",
  },
  // Sales Module > Introducer
  {
    path: `${configure.appUrl}sales/introducer`,
    component: <Introducer />,
    module_id: "18",
    type: "view",
  },
  {
    path: `${configure.appUrl}sales/add-introducer`,
    component: <AddIntroducer />,
    module_id: "18",
    type: "add",
  },
  {
    path: `${configure.appUrl}sales/edit-introducer/:id`,
    component: <EditIntroducer />,
    module_id: "18",
    type: "edit",
  },
  // Sales Module > Proposal
  {
    path: `${configure.appUrl}sales/proposal`,
    component: <Proposal />,
    module_id: "19",
    type: "view",
  },
  {
    path: `${configure.appUrl}sales/add-proposal`,
    component: <AddProposal />,
    module_id: "19",
    type: "add",
  },
  {
    path: `${configure.appUrl}sales/edit-proposal/:id`,
    component: <EditProposal />,
    module_id: "19",
    type: "edit",
  },

  // Sales Module > Contract
  {
    path: `${configure.appUrl}sales/contract`,
    component: <Contract />,
    module_id: "20",
    type: "view",
  },
  {
    path: `${configure.appUrl}sales/add-contract`,
    component: <AddContract />,
    module_id: "20",
    type: "add",
  },
  {
    path: `${configure.appUrl}sales/edit-contract/:id`,
    component: <EditContract />,
    module_id: "20",
    type: "edit",
  },
  {
    path: `${configure.appUrl}sales/view-contract/:id`,
    component: <ViewContract />,
    module_id: "20",
    type: "view",
  },

  // Sales Module > Customer
  {
    path: `${configure.appUrl}sales/customer`,
    component: <Customers />,
    module_id: "21",
    type: "view",
  },
  {
    path: `${configure.appUrl}sales/add-customer`,
    component: <AddCustomer />,
    module_id: "21",
    type: "add",
  },
  {
    path: `${configure.appUrl}sales/edit-customer/:id`,
    component: <EditCustomer />,
    module_id: "21",
    type: "edit",
  },

  // 
  {
    path: `${configure.appUrl}sales/add-contact/:id`,
    component: <AddContact />,
    module_id: "20",
    type: "edit",
  },
  {
    path: `${configure.appUrl}sales/edit-contact/:id`,
    component: <EditContact />,
    module_id: "20",
    type: "edit",
  },

  // setting module
  { path: `${configure.appUrl}setting/icode`, component: <IcodeIndex /> },
  {
    path: `${configure.appUrl}setting/knowledge-base`,
    component: <KnowledgeIndex />,
    module_id: "81",
    type: "view",
  },
  {
    path: `${configure.appUrl}setting/industry`,
    component: <IndustryIndex />,
    module_id: "83",
    type: "view",
  },
  { path: `${configure.appUrl}setting/hosting`, component: <HostingIndex /> },

  // Finance Module
  { path: `${configure.appUrl}finance/payment`, component: <Payment /> },
  { path: `${configure.appUrl}finance/vendor-pay`, component: <VendorPay /> },
  {
    path: `${configure.appUrl}finance/bank-account`,
    component: <BankAccount />,
  },
  { path: `${configure.appUrl}finance/credit-card`, component: <CreditCard /> },
  { path: `${configure.appUrl}finance/gst`, component: <GstIndex /> },
  { path: `${configure.appUrl}finance/tds`, component: <TdsIndex /> },

  // Performa Invoice
  // ztpl
  {
    path: `${configure.appUrl}finance/ztpl-pinvoice`,
    component: <ZtplIndex />,
  },
  {
    path: `${configure.appUrl}finance/ztpl-add-pinvoice`,
    component: <AddPrfInvoice />,
  },
  {
    path: `${configure.appUrl}finance/ztpl-edit-pinvoice`,
    component: <EditPrfInvoice />,
  },
  {
    path: `${configure.appUrl}finance/ztpl-view-pinvoice`,
    component: <ViewPrfInvoice />,
  },

  // Zithas
  {
    path: `${configure.appUrl}finance/zithas-pinvoice`,
    component: <ZithasPInvoiceIndex />,
  },

  // TSG
  {
    path: `${configure.appUrl}finance/tsg-performa-invoice`,
    component: <TsgIndex />,
  },
  {
    path: `${configure.appUrl}finance/tsg-add-invoice`,
    component: <AddTsgInvoice />,
  },
  {
    path: `${configure.appUrl}finance/tsg-edit-pinvoice`,
    component: <EditTsgInvoice />,
  },
  {
    path: `${configure.appUrl}finance/tsg-view-invoice`,
    component: <ViewTsgInvoice />,
  },
  // ==============

  // Invoice
  // ztpl
  {
    path: `${configure.appUrl}finance/zithas-technology-invoice`,
    component: <ZithasTechIndex />,
  },
  {
    path: `${configure.appUrl}finance/zithastech-view-invoice`,
    component: <ViewZithasTechInvoice />,
  },

  // zithas
  {
    path: `${configure.appUrl}finance/zithas-invoice`,
    component: <ZithasInvoiceIndex />,
  },
  {
    path: `${configure.appUrl}finance/zithas-view-invoice`,
    component: <ViewZithasInvoice />,
  },
  {
    path: `${configure.appUrl}finance/zithas-add-invoice`,
    component: <AddZithasInvoice />,
  },
  {
    path: `${configure.appUrl}finance/zithas-edit-invoice`,
    component: <EditZithasInvoice />,
  },
  {
    path: `${configure.appUrl}finance/zithas-clone-invoice`,
    component: <CloneZithasInvoice />,
  },

  // staffguru
  {
    path: `${configure.appUrl}finance/tsg-invoice`,
    component: <StaffGuruIndex />,
  },
  {
    path: `${configure.appUrl}finance/staffguru-view-invoice`,
    component: <ViewStaffGuruInvoice />,
  },

  // Marketing Module
  {
    path: `${configure.appUrl}marketing/opportunity`,
    component: <DataBankIndex />,
    module_id: "22",
    type: "view",
  },
  {
    path: `${configure.appUrl}marketing/Getindustrydetails/:industry_id`,
    component: <ViewDataBank />,
  },
  {
    path: `${configure.appUrl}marketing/Formshow/:company_id`,
    component: <EditForm />,
  },

  {
    path: `${configure.appUrl}marketing/bidding`,
    component: <MarketBiddingIndex />,
  },
  {
    path: `${configure.appUrl}marketing/edit-bidding`,
    component: <EditBidding />,
  },

  {
    path: `${configure.appUrl}marketing/cold-calling`,
    component: <ColdCallingIndex />,
    module_id: "24",
    type: "view",
  },
  // {
  //   path: `${configure.appUrl}marketing/add-coldcalling`,
  //   component: <AddColdCalling />,
  // },
  // {
  //   path: `${configure.appUrl}marketing/edit-coldcalling`,
  //   component: <EditColdCalling />,
  // },

  // {
  //   path: `${configure.appUrl}marketing/cold-calling`,
  //   component: <ColdCallingIndex />,
  // },
  {
    path: `${configure.appUrl}marketing/all-bookings`,
    component: <Booking />,
    module_id: "87",
    type: "view",
  },
  {
    path: `${configure.appUrl}marketing/add-coldcalling`,
    component: <AddColdCalling />,
    module_id: "24",
    type: "add",
  },
  {
    path: `${configure.appUrl}marketing/edit-coldcalling/:company_id`,
    component: <EditColdCalling />,
    module_id: "24",
    type: "edit",
  },
  {
    path: `${configure.appUrl}marketing/view-coldcalling`,
    component: <ViewColdCalling />,
    module_id: "24",
    type: "view",
  },

  // work module
  {
    path: `${configure.appUrl}work/resource-plan`,
    component: <ResourcePlanIndex />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: `${configure.appUrl}`,
    exact: true,
    component: <Navigate to={`${configure.appUrl}dashboard`} />,
  },
  // Planet
  { path: `${configure.appUrl}currentopening`, component: <CurrentOpening /> },
  {
    path: `${configure.appUrl}addcurrentopening`,
    component: <AddCurrentOpening />,
  },
  {
    path: `${configure.appUrl}editcurrentopening`,
    component: <EditCurrentOpening />,
  },
  {
    path: `${configure.appUrl}testimonial`,
    component: <Testimonial />,
  },
  {
    path: `${configure.appUrl}addtestimonial`,
    component: <AddTestimonial />,
  },
  {
    path: `${configure.appUrl}edittestimonial`,
    component: <EditTestimonial />,
  },
  {
    path: `${configure.appUrl}credentials`,
    component: <Crendential />,
    module_id: "10",
    type: "view",
  },
  {
    path: `${configure.appUrl}viewdocument/:id`,
    component: <ViewDocument />,
  },
  {
    path: `${configure.appUrl}editdocument/:id`,
    component: <EditDocument />,
  },
  {
    path: `${configure.appUrl}audit/roles`,
    component: <RolesPermission />,
  },
  // {
  //   path: `${configure.appUrl}settingcredentials`,
  //   // component: <GeneralSettingIndex />,
  //   component: <Settingcredentials />,
  // },
  // {
  //   path: `${configure.appUrl}editcredentials/:id`,
  //   component: <EditIndex />,
  // },

  // Audit Logs route
  {
    path: `${configure.appUrl}audit-log/rolespermission`,
    component: <RolesPermission />,
  },

  // Knowledge Base Module
  {
    path: `${configure.appUrl}knowledgebase`,
    component: <KnowledgeBase />,
    module_id: "95",
    type: "view",
  },
  {
    path: `${configure.appUrl}edittopic`,
    component: <EditTopic />,
  },
  {
    path: `${configure.appUrl}addtopic`,
    component: <AddTopic />,
  },
  {
    path: `${configure.appUrl}viewtopic`,
    component: <ViewTopic />,
  },
  // Finance route
  {
    path: `${configure.appUrl}expense/budget-expense`,
    component: <BudgetExpense />,
    module_id: "41",
    type: "view",
  },
  {
    path: `${configure.appUrl}expense/expense`,
    component: <Expense />,
    module_id: "41",
    type: "view",
  },
  {
    path: `${configure.appUrl}expense/vendorspay`,
    component: <VendorsPay />,
    module_id: "41",
    type: "view",
  },
  {
    path: `${configure.appUrl}creditcard`,
    component: <CrediCardSection />,
    module_id: "43",
    type: "view",
  },

  // Work Module 
  {
    path: `${configure.appUrl}work/activities`,
    component: <GeneralTask />,
    module_id: "26",
    type: "view",
  },

  // Human Resource

  {
    path: `${configure.appUrl}payroll_management/swipe_issues`,
    component: <SwipeAndIssuesIndex />,
  },
  {
    path: `${configure.appUrl}flaws-gap`, component: <Reports />,
    module_id: "96",
    type: "view",
  },
  {
    path: `${configure.appUrl}notifications`,
    component: <NotificationPage />
  },

  // Digital Marketing Project

  {
    path: `${configure.appUrl}weekly-plan/digital-marketing`,
    component: <MainDmResourcePlan />,
    type: "view",
  },
  {
    path: `${configure.appUrl}teams-weekly-plan/digital-marketing`,
    component: <TeamsWeeklyPlan />,
    module_id: "98",
    type: "view",
  },
  {
    path: `${configure.appUrl}project-weekly-plan/digital-marketing`,
    component: <ProjectWeeklyPlan />,
    module_id: "99",
    type: "view",
  },
  {
    path: `${configure.appUrl}initial-project-plan/digital-marketing`,
    component: <DigitalPlanIndex />,
    module_id: "100",
    type: "view",
  },
  {
    path: `${configure.appUrl}initial-project-plan/:id/:seo`,
    component: <MainDmProjectPlan />,
    module_id: "100",
    type: "view",
  },
  {
    path: `${configure.appUrl}work/digital_marketing`,
    component: <DigitalMarketingIndex />,
    module_id: "101",
    type: "view",
  },
  {
    path: `${configure.appUrl}digital_marketing/add_project`,
    component: <DmAddProject />,
    module_id: "101",
    type: "add",
  },
  {
    path: `${configure.appUrl}digital_marketing/edit_project/:id`,
    component: <EditProjectIndex />,
    module_id: "101",
    type: "edit",
  },
  {
    path: `${configure.appUrl}work/digital_marketing/notes/:noteId`,
    component: <DmSharedNotes />,
    module_id: "101",
    type: "edit",
  },
  {
    path: `${configure.appUrl}setting/digital_marketing`,
    component: <DigitalMarketingSeting />,
    module_id: "102",
    type: "add",
  },
  {
    path: `${configure.appUrl}complete_sprint`,
    component: <CompleteSprint />,
    type: "view",
  },
  {
    path: `${configure.appUrl}monthly-planning`,
    component: <MonthlyPlanning />,
    type: "view",
  },
  {
    path: `${configure.appUrl}monthly-approval`,
    component: <DmMonthlyApproval />,
    module_id: "105",
    type: "view",
  },

  // Settings for overdraft
  {
    path: `${configure.appUrl}setting/overdraft`,
    component: <OverdraftIndex />,
    module_id: "104",
    type: "view",
  },

  // Finance
  {
    path: `${configure.appUrl}finance/overdraft`,
    component: <OverDraft />,
    module_id: "103",
    type: "view",
  },

  // Project Section 
  {
    path: `${configure.appUrl}work/projects`,
    component: <ProjectIndex />,
    module_id: "25",
    type: "view",
  },
  {
    path: `${configure.appUrl}work/projects-plan/:id`,
    component: <ProjectResourcePlanIndex />,
    module_id: "25",
    type: "view",
  },
  {
    path: `${configure.appUrl}work/add-project`,
    component: <AddProject />,
    module_id: "25",
    type: "add",
  },
  {
    path: `${configure.appUrl}work/edit-project/:id`,
    component: <EditProject />,
    module_id: "25",
    type: "edit",
  },
  {
    path: `${configure.appUrl}complete_sprint/development`,
    component: <ProjectSprintIndex />,
    module_id: "25",
    type: "view",
  },
  {
    path: `${configure.appUrl}initial-project-plan/development`,
    component: <ResourceIndex />,
    module_id: "88",
    type: "view",
  },
  {
    path: `${configure.appUrl}weekly-plan/development`,
    component: <PmResourcePlanIndex />,
    module_id: "88",
    type: "view",
  },

  // Gantt Chart
  {
    path: `${configure.appUrl}gantt-chart`,
    component: <GanttChart />,
    module_id: "106",
    type: "view",
  },

  // // Support Section
  {
    path: `${configure.appUrl}support/ticket`,
    component: <ProjectTickets />,
    module_id: "27",
    type: "view",
  },
  {
    path: `${configure.appUrl}support/tickets/:id`,
    component: <SupportIndex />,
    module_id: "27",
    type: "view",
  },
  {
    path: `${configure.appUrl}support/test-cases`,
    component: <TestCaseIndex />,
    module_id: "107",
    type: "view",
  },
  {
    path: `${configure.appUrl}support/test-cases/:id`,
    component: <CaseIndex />,
    module_id: "107",
    type: "view",
  },
  {
    path: `${configure.appUrl}support/project-test/:id`,
    component: <ProjectTestIndex />,
    module_id: "107",
    type: "view",
  },

  // Human Resource

  {
    path: `${configure.appUrl}payroll-management/salary-process`,
    component: <SalaryIndex />,
    module_id:109,
    type: "view",
  },
  {
    path: `${configure.appUrl}payroll-management/salary-slip`,
    component: <SalarySlip />,
    module_id:108,
    type: "view",
  },
  {
    path: `${configure.appUrl}generate-salary-slip/:id`,
    component: <GeneratedSalarySlip />,
    module_id:108,
    type: "view",
  },
  {
    path: `${configure.appUrl}payroll-management/leave-management`,
    component: <LeaveManagement />,
    module_id: 40,
    type: "view",
  },

]


const publicRoutes = [
  { path: `${configure.appUrl}logout`, component: <Logout /> },
  { path: `${configure.appUrl}login`, component: <Login /> },
  { path: `${configure.appUrl}forgot-password`, component: <ForgetPwd /> },
  { path: `${configure.appUrl}register`, component: <Register /> },
  { path: `${configure.appUrl}access-denied`, component: <AccessDenied /> },
  {
    path: `${configure.appUrl}introducer/:id`,
    component: <GeneratedContract />,
  },
  {
    path: `${configure.appUrl}sales/contract/:id`,
    component: <GenerateLinkContract />,
  },
]

export { authProtectedRoutes, publicRoutes }
