import React, { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"
import { useParams } from "react-router-dom"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const LetsStart = ({approveStatus}) => {
    // meta title
    document.title = "Weekly Planning | Zithas Crm"
    const [eventsList, setEvents] = useState([])
    const [isLoading, setLoading] = useState(false)
    const token = secureLocalStorage?.getItem('auth_token');
    const params = useParams();
    const toggleLoading = (loading) => {
        setLoading(loading);
    }
    useEffect(() => {
        const fetchAllTask = async () => {
            const res = await fetchData(toggleLoading, `allAssignedTask/${params?.id}/${approveStatus == 0 ? 1 : 2}`, token);
            if (res?.success) {
                setEvents(res?.data)
            }
        }
        fetchAllTask()
    }, [])

    return (
        <React.Fragment>
            {isLoading && (<Loader />)}
            <Card className="mb-3">
                <CardBody>
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col lg={12}>
                                    <FullCalendar
                                        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        slotDuration={"00:15:00"}
                                        handleWindowResize={true}
                                        themeSystem="bootstrap"
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: "title",
                                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                                        }}
                                        events={eventsList}
                                        eventContent={(arg) => {
                                            const { event } = arg;
                                            return (
                                                <div className="d-flex justify-content-center align-items-start">
                                                    <div>
                                                        <img
                                                            src={`${configure.imgUrl}${event.extendedProps.avatar}`}
                                                            alt="User Icon"
                                                            className="me-1"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                borderRadius: "50%",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="text-start "
                                                        style={{
                                                            wordWrap: "break-word",
                                                            wordBreak: "break-word",
                                                            whiteSpace: "normal",
                                                            maxWidth: "100%",
                                                        }}
                                                    >
                                                        {event.title} - <span className="badge bg-light text-dark ms-2">{event?.extendedProps?.estimate_dev_days}</span>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        editable={false}
                                        droppable={false}
                                        selectable={false}
                                    />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}



export default LetsStart
