export const layoutConfig = [
    {
        id: 1,
        i: "welcomeComp",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 2,
        i: "favoriteLead",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 24,
    },
    {
        id: 3,
        i: "absentees",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 5,
        i: "activityComponent",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 24,
    },
    {
        id: 6,
        i: "swipeAndIssues",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 24,
    },
    {
        id: 7,
        i: "projectOverviews",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 24,
    },
    {
        id: 8,
        i: "leadPotential",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 9,
        i: "documentApproval",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 25,
    },
    {
        id: 10,
        i: "generalTask",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 28,
    },
    {
        id: 11,
        i: "appliedLeaves",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 24,
        maxW: 12,
    },
    {
        id: 12,
        i: "workduration",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 13,
        i: "gitPush",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 14,
        i: "clearlogin",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 15,
        i: "flawGap",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 10,
    },
    {
        id: 16,
        i: "notify",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
    },
    {
        id: 17,
        i: "flawList",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 18,
        i: "overdraft",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 19,
        i: "timerApproval",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 20,
        i: "projectTickets",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 21,
        i: "inTestingProjects",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 22,
        i: "queriesTicket",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 23,
        i: "earthTickets",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
    {
        id: 24,
        i: "estimatedSalary",
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minW: 1,
        minH: 2,
        maxH: 40,
    },
]

export const calculateLayout = layoutConfigs => {
    let updatedLayout = [...layoutConfigs]
    let lastX = 0
    let lastY = 0

    updatedLayout.forEach((item, index) => {
        if (index > 0) {
            if (lastX + item.w > 12) {
                lastX = 0
                lastY += item.h
            }
            item.x = lastX
            item.y = lastY

            lastX += item.w
        } else {
            item.x = 0
            item.y = 0
            lastX = item.w
        }
    })

    return updatedLayout
}

export const allElements = [
    {
        name: "Sales",
        id: 1,
        inner: [
            {
                id: 2,
                name: "Favorite Leads",
                checked: false,
            },
            {
                id: 8,
                name: "Lead Potentials",
                checked: false,
            },

        ],
    },
    {
        name: "Human Resources",
        id: 2,
        inner: [
            {
                id: 1,
                name: "Profile",
                checked: false,
            },
            {
                id: 3,
                name: "Absentees",
                checked: false,
            },
            {
                id: 9,
                name: "Document Approvel",
                checked: false,
            },
            {
                id: 6,
                name: "Swipe & Issues",
                checked: false,
            },
            {
                id: 11,
                name: "Leaves Approval",
                checked: false,
            },
            {
                id: 14,
                name: "Clear Login",
                checked: false,
            },
            {
                id: 15,
                name: "Flaws & Gaps",
                checked: false,
            },
            {
                id: 17,
                name: "F&Gs List",
                checked: false,
            },
            {
                id: 24,
                name: "Estimated Salary",
                checked: false,
            },
        ],
    },
    {
        name: "Support",
        id: 6,
        inner: [
            {
                id: 22,
                name: "Queries Tickets",
                checked: false,
            },
            {
                id: 23,
                name: "Earth ZAI Tickets",
                checked: false,
            },
        ],
    },
    {
        name: "Work",
        id: 3,
        inner: [
            {
                id: 10,
                name: "My Activity",
                checked: false,
            },
            {
                id: 5,
                name: "Teams Activity",
                checked: false,
            },
            {
                id: 12,
                name: "Work Duration",
                checked: false,
            },
            {
                id: 13,
                name: "Git Pushed Pending",
                checked: false,
            },
            {
                id: 19,
                name: "Timer Approval",
                checked: false,
            },
            {
                id: 7,
                name: "Project Overview",
                checked: false,
            },
            {
                id: 20,
                name: "Project Coins",
                checked: false,
            },
            {
                id: 21,
                name: "QA Task Allotment",
                checked: false,
            },

        ],
    },
    {
        name: "Settings",
        id: 4,
        inner: [
            {
                id: 16,
                name: "Notifications",
                checked: false,
            },
        ],
    },
    {
        name: "Finance",
        id: 5,
        inner: [
            {
                id: 18,
                name: "Overdraft Report",
                checked: false,
            },
        ],
    },
]