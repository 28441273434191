import Select from "react-select"
import React, { useEffect, useState } from 'react'
import "../../../../assets/css/Sales/Sales.css"
import { allocationType, StaffOption } from "utils/common-options"
import { ResizableBox } from "react-resizable"
import Draggable from "react-draggable"
import { Card, CardBody, Label, Table } from "reactstrap"
import Swal from "sweetalert2"
import moment from "moment"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import { useParams } from "react-router-dom"
import { calculateCompleteTime, getTypesWithLessHours } from "utils/common-helpers"
import configure from "configure"

const ResourceSelection = ({ projectDetail, allStaff, currentStep, resourceLength }) => {
    const [allocation, setAllocation] = useState([])
    const [resourceDetail, setResourceDetail] = useState(false)
    const [switchResource, setSwitchResource] = useState(false)
    const [noDrag, setNoDrag] = useState(false)
    const [boxWidth, setBoxWidth] = useState(0)
    const [boxWidth2, setBoxWidth2] = useState(0)
    const [member, setMember] = useState([])
    const [isLoading, setLoading] = useState(false)
    const token = secureLocalStorage.getItem('auth_token')
    const params = useParams();
    const [projectAvailability, setProjectAvailability] = useState([])
    const [resourceHours, setResourceHours] = useState('')
    const [data, setData] = useState([])
    const [allocatedHrs, setAllocatedHrs] = useState({})
    const [allocatedOptions, setAllocatedOptions] = useState([])
    const [newStaff, setNewStaff] = useState([])


    function calculateHoursByType(array) {
        const result = {
            designing: 0,
            development: 0,
            testing: 0,
            deployment: 0
        };
        const typeMap = allocationType.reduce((map, item) => {
            map[item.value] = item.label.toLowerCase();
            return map;
        }, {});
        array.forEach(entry => {
            const label = typeMap[entry.type];
            if (label) {
                result[label] += parseInt(entry.days, 10);
            }
        });

        return result;
    }

    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.85)
            setBoxWidth2(viewportWidth * 0.35)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])
    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    const onGetAvailability = async () => {
        if (allocation?.length != 0 && member?.length != 0) {
            const formEntries = {
                start_date: projectDetail?.startdate,
                end_date: projectDetail?.enddate,
                staff_id: member?.value || '',
            }
            const res = await FetchPostData(toggleLoading, 'projectTaskAvailabityByUser', token, formEntries);
            if (res?.data?.success) {
                setProjectAvailability(res?.data?.data)
                if (res?.data?.data?.length == 0) {
                    const hrs = allocation?.value == 1 ? projectDetail?.designing : allocation?.value == 2 ? projectDetail?.development : allocation?.value == 3 ? projectDetail?.testing : allocation?.value == 4 ? projectDetail?.deployment : 0
                    setResourceHours(hrs)
                } else {
                    setResourceHours(calculateCompleteTime(res?.data?.data, 'pending_hrs'))
                }
                setResourceDetail(true)

            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please Select Mandatory Fields",
            })
        }
    }

    const onAssignMember = async () => {
        Swal.fire({
            icon: "warning",
            title: "Are You Sure ?",
            text: "You want to Assigne this Resource",
            showCancelButton: true,
        }).then(async (assigne) => {
            if (assigne?.isConfirmed) {
                const formEntries = {
                    allocation_type: allocation?.value || '',
                    staff_id: member?.value || '',
                    project_id: params?.id,
                    hrs: resourceHours
                }
                const res = await FetchPostData(toggleLoading, 'assignMembersToProject', token, formEntries);
                if (res?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Resource Assigned Successfully",
                    }).then(() => {
                        setResourceDetail(false)
                        setData(res?.data?.data)
                        setAllocatedHrs(calculateHoursByType(res?.data?.data))
                        setAllocation([])
                        setMember([])
                    })
                } else {
                    Swal.fire({
                        icon: "warning",
                        text: "Oops! This Resource is already assigned for this Allocation Type",
                    }).then(() => {
                        setResourceDetail(false)
                    })
                }
            }
        })

    }

    useEffect(() => {
        const fetchAssignMembers = async () => {
            const res = await fetchData(toggleLoading, `estimateData/${params?.id}`, token);
            if (res?.success) {
                setData(res?.data)
                const allocated = calculateHoursByType(res?.data)
                setAllocatedHrs(allocated)


            }
        }
        if (currentStep == 1) {
            fetchAssignMembers()
        }
    }, [currentStep])

    useEffect(() => {
        const updatedAllocationType = allocationType.filter(item => {
            const category = item.label.toLowerCase();
            return allocatedHrs[category] < projectDetail[category];
        });
        setAllocatedOptions(updatedAllocationType)
        resourceLength(updatedAllocationType?.length)
    }, [allocatedHrs, projectDetail])

    const onDeleteMembers = (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are You Sure ?",
            text: "You want to Remove this Resource",
            showCancelButton: true,
        }).then(async (assigne) => {
            if (assigne?.isConfirmed) {
                const res = await fetchData(toggleLoading, `removeMembersToProject/${id}`, token);
                if (res?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Resource Removed Successfully",
                    }).then(() => {
                        setData(res?.data)
                        setAllocatedHrs(calculateHoursByType(res?.data))
                    })
                }
            }
        })
    }

    const switchProject = async (project_id) => {
        const formEntries = {
            new_staff_id: newStaff?.value,
            old_staff_id: member?.value,
            project_id: project_id
        }
        const res = await FetchPostData(toggleLoading, 'switchProjectMemeber', token, formEntries);
        if (res?.data?.success) {
            Swal.fire({
                icon: "success",
                text: "Resource Switched Successfully",
            }).then(() => {
                setResourceDetail(false)
                setSwitchResource(false)
            })
        }
    }
    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="d-flex gap-3 justify-content-center  mb-3" style={{ backgroundColor: "#ffe3cf" }}>
                <div className='text-black fw-bold general-box bg-transparent'>Designing ( Total : {projectDetail?.designing} Hrs & Assigned : {allocatedHrs?.designing} Hrs )</div>
                <div className='text-black fw-bold general-box bg-transparent'>Development ( Total : {projectDetail?.development} Hrs & Assigned : {allocatedHrs?.development} Hrs )</div>
                <div className='text-black fw-bold general-box bg-transparent'>Testing ( Total : {projectDetail?.testing} Hrs & Assigned : {allocatedHrs?.testing} Hrs )</div>
                <div className='text-black fw-bold general-box bg-transparent'>Deployment ( Total : {projectDetail?.deployment} Hrs & Assigned : {allocatedHrs?.deployment} Hrs )</div>
            </div>
            <Card>
                <CardBody>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className="d-flex gap-3 align-items-center col-md-7">
                            <div className="col-md-4">
                                <Select
                                    placeholder="Select Allocation Type"
                                    className="basic-multi-select "
                                    classNamePrefix=" select"
                                    options={allocatedOptions}
                                    value={allocation}
                                    onChange={(e) => setAllocation(e)}
                                />
                            </div>
                            <div className=" col-md-4">
                                <Select
                                    placeholder="Select Members"
                                    className="basic-multi-select "
                                    classNamePrefix=" select"
                                    options={allStaff}
                                    value={member}
                                    onChange={(e) => setMember(e)}
                                />
                            </div>
                        </div>
                        <div className="col-2 d-flex gap-2 justify-content-end align-items-center">
                            <button className=" btn ms-md-2 btn-save btn-label"
                                onClick={(e) => onGetAvailability()}><i className="fas fa-search me-1 label-icon"></i> Search</button>
                            <a className=" btn ms-md-2 btn-red btn-label"><i className="dripicons-cross me-2 icon-center label-icon"></i>Clear</a>
                        </div>
                    </div>
                    <div className="mt-5">
                        <Table className="table table-bordered mb-0 mt-3">
                            <thead className="bg-white">
                                <tr>
                                    <th className="header ">Id</th>
                                    <th className=" header col-4">Assignee</th>
                                    <th className="text-center header">Allocation Type</th>
                                    <th className="text-center header">Allocation Hours</th>
                                    <th className="text-center header">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((obj, i) => {
                                    return (
                                        <tr key={i + "assignee"}>
                                            <td className="text-center">{i + 1}</td>
                                            <td className="">
                                                <img src={`${configure.imgUrl}${obj?.avatar}`} alt="" className="img-fluid assignee-img me-3" />
                                                {obj?.alias_name}
                                            </td>
                                            <td className="text-center">{allocationType?.find((item) => item?.value == obj?.type)?.label}</td>
                                            <td className="text-center">{obj?.days}</td>
                                            <td className="text-center">
                                                <a className="btn btn-sm btn-outline-danger me-1" title="View Activity" onClick={() => onDeleteMembers(obj?.id)}>
                                                    <i className="fas fa-trash "></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {data?.length == 0 ? (
                            <p className="text-center fw-bold mt-3"> No Member Assigned Yet</p>
                        ) : null}
                    </div>
                </CardBody>
            </Card>

            <div className={`note-sticky-form ${resourceDetail ? "d-block" : "d-none"}`}>
                <Draggable
                    handle={`${noDrag ? ".modal-header" : ""}`}
                    cancel={`${noDrag ? ".modal-body" : ""}`}
                >
                    <ResizableBox
                        width={boxWidth}
                        style={{
                            position: "fixed",
                            top: "16%",
                            right: "10%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                            maxHeight: "fit-content",
                            transform: "translate(-50%, -50%)"
                        }}
                    >
                        <div className="modal-header mb-4">
                            <h5 className="modal-title mt-0">Resource Detail</h5>
                            <div>
                                <span className='text-success me-2 fw-bold'>Start Date:  {moment(projectDetail?.startdate).format('DD-MM-YYYY')}</span>
                                <span className=''>-</span>
                                <span className='text-danger ms-2 fw-bold'>End Date: {moment(projectDetail?.enddate).format('DD-MM-YYYY')}</span>
                            </div>
                            <a onClick={() => setResourceDetail(false)}><i className="dripicons-cross text-danger"></i></a>
                        </div>

                        <div className="modal-body">
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex gap-3">
                                        <div className='text-purple fw-bold'>Total Hours:
                                            {allocation?.value == 1 ? projectDetail?.designing : allocation?.value == 2 ? projectDetail?.development : allocation?.value == 3 ? projectDetail?.testing : allocation?.value == 4 ? projectDetail?.deployment : 0} Hrs</div>
                                        <div>|</div>
                                        <div className='text-success fw-bold'>Available Hours for this project of this Resource:{resourceHours} Hrs</div>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            type="number"
                                            className="form-control "
                                            placeholder="Available hours"
                                            value={resourceHours}
                                            onChange={(e) => setResourceHours(e.target.value)}
                                        />
                                    </div>
                                </div>


                                <Table className="table table-bordered mb-0 mt-3">
                                    <thead className="bg-white">
                                        <tr>
                                            <th className="header ">Id</th>
                                            <th className=" header col-6">Project Name</th>
                                            <th className="text-center header col-1">Start Date</th>
                                            <th className="text-center header col-1">End Date</th>
                                            <th className="text-center header col-2">Pending Hours</th>
                                            <th className="text-center header col-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectAvailability?.map((obj, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{obj?.id}</td>
                                                    <td className="">{obj?.project_name}</td>
                                                    <td className="text-center">{obj?.start_date}</td>
                                                    <td className="text-center">{obj?.end_date}</td>
                                                    <td className="text-center">{obj?.remaining_hours}</td>
                                                    <td className="text-center">
                                                        <a onClick={(e) => {
                                                            e.preventDefault()
                                                            setSwitchResource(true)
                                                        }} className="btn btn-sm  bg-light-purple text-purple border-0 " title="switch Project"><i className="bi bi-toggle2-off me-2"></i>Switch Project</a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </Table>

                                {projectAvailability?.length == 0 ? (
                                    <p className="text-purple fw-bold text-center mt-3">🎉 Yay! You have access to this resource for the entire allocated duration! 🎊</p>
                                ) : null}
                                <div className="text-end mt-4">
                                    <button className=" btn ms-md-2 btn-save btn-label"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onAssignMember();
                                        }}
                                    ><i className="fas fa-plus me-1 label-icon"></i> Assign Resource</button>
                                </div>
                            </div>
                        </div>
                    </ResizableBox>
                </Draggable>
            </div>
            <div className={`note-sticky-form ${switchResource ? "d-block" : "d-none"}`}>
                <Draggable
                    handle={`${noDrag ? ".modal-header" : ""}`}
                    cancel={`${noDrag ? ".modal-body" : ""}`}
                >
                    <ResizableBox
                        width={boxWidth2}
                        style={{
                            position: "fixed",
                            top: "16%",
                            right: "10%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                            maxHeight: "fit-content",
                            transform: "translate(-50%, -50%)"
                        }}
                    >
                        <div className="modal-header mb-3">
                            <h5 className="modal-title mt-0">Resource Detail</h5>
                            <a onClick={() => setSwitchResource(false)}><i className="dripicons-cross text-danger"></i></a>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={switchProject}>
                                <div className="col-md-12">
                                    <Label>
                                        Select Member
                                    </Label>
                                    <Select
                                        placeholder="Select Assignee"
                                        className="basic-multi-select "
                                        classNamePrefix=" select"
                                        options={allStaff}
                                        value={newStaff}
                                        onChange={(e) => setNewStaff(e)}
                                    />
                                </div>
                                <div className="text-end mt-4">
                                    <button className=" btn ms-md-2 btn-save btn-label"
                                    ><i className="bi bi-toggle2-off me-1 label-icon"></i> Switch Project</button>
                                </div>
                            </form>
                        </div>
                    </ResizableBox>
                </Draggable>
            </div>
        </div>
    )
}

export default ResourceSelection
