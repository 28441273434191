import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import NewPagination from "components/Common/Newpagination"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../assets/css/Work/projects.css"
import ViewTasks from "./ViewProject/ViewTasks"
import Draggable, { DraggableCore } from "react-draggable"
import { ResizableBox } from "react-resizable"
import Swal from "sweetalert2"
import { fixedProjectColumns } from "utils/columns/Developement/projectColunmn"
import AddTask from "./common/AddTask"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { useNavigate } from "react-router-dom"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import configure from "configure"
import ProjectOverview from "./EditProject/ProjectOverview"
import Notes from "./EditProject/Notes"
import Milestones from "./EditProject/Milestones"
import ActivityLog from "./EditProject/ActivityLog"
import Credential from "./EditProject/Credential"

function TableContainer({
  columns,
  data,
  roles,
  setAccessDenied,
  allData,
  setData,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const [status, setStatus] = useState("in-progress")
  useEffect(() => {
    setData(allData?.filter(obj => obj.status == "1"))
  }, [allData])
  const navigate = useNavigate()
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  const handleAddProject = () => {
    const permission = checkPermissionsByModule(roles, 25, 3)
    if (permission) {
      navigate(`${configure.appUrl}work/add-project`)
    } else {
      setAccessDenied(true)
    }
  }

  return (
    <div>
      <div id="support-header" className="mb-4 align-items-center row">
        <div className="pr-0 support-status-part general-status-part col-md-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all")
                  setData(allData)
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "not" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("not")
                  setData(allData?.filter(obj => obj.status == "0"))
                }}
              >
                <h4>{allData?.filter(obj => obj.status == "0")?.length}</h4>
                <h4>Not Started</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "in-progress" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("in-progress")
                  setData(allData?.filter(obj => obj.status == "1"))
                }}
              >
                <h4>{allData?.filter(obj => obj.status == "1")?.length}</h4>
                <h4>In Progress</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "hold" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("hold")
                  setData(allData?.filter(obj => obj.status == "2"))
                }}
              >
                <h4>{allData?.filter(obj => obj.status == "2")?.length}</h4>
                <h4>On Hold</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "cancel" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("cancel")
                  setData(allData?.filter(obj => obj.status == "3"))
                }}
              >
                <h4>{allData?.filter(obj => obj.status == "3")?.length}</h4>
                <h4>Cancelled</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "finish" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("finish")
                  setData(allData?.filter(obj => obj.status == "4"))
                }}
              >
                <h4>{allData?.filter(obj => obj.status == "4")?.length}</h4>
                <h4>Finished</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pl-0 support-action-part col-md-3">
          <div className="text-md-end">
            <a
              className="s btn text-white me-2 btn-save btn-label"
              onClick={handleAddProject}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Project
            </a>
            <button
              className="s btn btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={e => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-fixed-project-header" : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="col-12 p-0 d-flex flex-wrap">
                        <div
                          className={`${
                            index == 0 ? "col-6" : "col-10"
                          } d-flex ${
                            index == 0
                              ? "justify-content-end"
                              : "justify-content-start"
                          }`}
                        >
                          {column.render("Header")}
                        </div>
                        <div
                          className={`${
                            index == 0 ? "col-6" : "col-2"
                          } d-flex justify-content-end`}
                        >
                          {column.isShortIcon ? (
                            <span
                              onClick={() =>
                                column.getSortByToggleProps().onClick()
                              }
                              style={{
                                position: index != 0 ? "" : "",
                                right: index != 0 ? "0" : "",
                              }}
                              className="d-flex"
                            >
                              <i
                                title="Ascending"
                                className={`bi bi-arrow-up ms-1 info-icon ${
                                  column.isSorted && !column.isSortedDesc
                                    ? "text-dark"
                                    : ""
                                }`}
                              ></i>
                              <i
                                title="Descending"
                                className={`bi bi-arrow-down info-icon ${
                                  column.isSorted && column.isSortedDesc
                                    ? "text-dark"
                                    : ""
                                }`}
                              ></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const startDate = moment()
              const deliveryDateBefore = moment(row.original.deliverydatebefore)
              const diffInDays = deliveryDateBefore.diff(startDate, "days")
              const totalDays = row.original.estimatedhours
              const deliveryDate = new Date(row.original.deliverydatebefore)
              const today = new Date()
              const differenceInDays = Math.ceil(
                (deliveryDate - today) / (1000 * 60 * 60 * 24)
              )
              let percentage
              if (differenceInDays >= totalDays) {
                percentage = 100
              } else if (differenceInDays <= 0) {
                percentage = 0
              } else {
                const percentComplete =
                  ((totalDays - differenceInDays) / totalDays) * 100
                percentage = Math.min(Math.max(percentComplete, 0), 100)
              }
              const conditionClass =
                percentage >= 0 && percentage < 25
                  ? "condition-25s"
                  : percentage >= 25 && percentage < 50
                  ? "condition-50s"
                  : percentage >= 50 && percentage < 75
                  ? "condition-75s"
                  : percentage >= 75 && percentage <= 100
                  ? "condition-100s"
                  : ""

              const isConditiondid = row.original.pwd >= 0 && diffInDays < 0
              const isbothCondition = row.original.pwd < 0 && diffInDays < 0
              const pauseproject =
                row?.original?.pause_status == 1 ? "bg-pause-project" : ""

              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={`${isConditiondid && conditionClass} ${
                    isbothCondition && "condition-100s "
                  } ${pauseproject}`}
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const FixedProjects = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("7")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [allData, setAllData] = useState([])
  const [data, setData] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [addTask, setAddTask] = useState(false)
  const userId = localStorage.getItem("auth_id")
  const token = secureLocalStorage.getItem("auth_token")
  const roles = useSelector(state => state?.Roles?.apiData)
  const [projectDetail, setProjectDetail] = useState({})
  const [projectId, setProjectId] = useState("")
  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false), setAddTask(false)
  })

  //meta title
  document.title = "Projects | Zithas Technologies"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  const onChangeStatusProject = async (id, type, status) => {
    const permission = checkPermissionsByModule(roles, 25, 4)
    if (permission) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `${
          type == 1
            ? `You want to ${status == 0 ? "Play Project" : "Pause Project"}`
            : "You want to change status."
        }`,
        showCancelButton: true,
      }).then(async result => {
        if (result.isConfirmed) {
          setLoading(true)
          const formEntries = {
            user_id: localStorage.getItem("auth_id"),
            role_id: secureLocalStorage.getItem("auth_role"),
            project_id: id,
            status: status,
            type: type,
            project_type: 1,
          }
          const response = await FetchPostData(
            toggleLoading,
            "changeProjectStatus",
            "",
            formEntries
          )
          if (response.data?.success) {
            Swal.fire({
              icon: "success",
              title: "Project Status Updated Successfully",
            }).then(data => {
              setData(response?.data?.data?.project2)
              setAllData(response?.data?.data?.project2)
            })
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  // On Delete project
  const onDelete = async id => {
    const permission = checkPermissionsByModule(roles, 25, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Project!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const formDatas = {
            project_id: id,
            user_id: localStorage?.getItem("auth_id"),
          }
          const response = await FetchPostData(
            toggleLoading,
            "delete_project",
            secureLocalStorage.getItem("auth_token"),
            formDatas
          )
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Project deleted successfully",
            })
            setData(response?.data?.data?.project2)
            setAllData(response?.data?.data?.project2)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const handleAdd = id => {
    const permission = checkPermissionsByModule(roles, 25, 3)
    if (permission) {
      setProjectId(id)
      setAddTask(true)
    } else {
      setAccessDenied(true)
    }
  }
  const onViewPorject = async obj => {
    const res = await fetchData(toggleLoading, `projectView/${obj.id}`, token)
    if (res?.success) {
      setProjectDetail(res?.data?.project[0])
      setmodal_fullscreen(true)
    }
  }
  const columns1 = useMemo(
    () =>
      fixedProjectColumns(
        onChangeStatusProject,
        onDelete,
        handleAdd,
        onViewPorject
      ),
    []
  )
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const fetchProjects = async () => {
    const formEntries = {
      user_id: userId,
      role_id: secureLocalStorage.getItem("auth_role"),
      type: 1,
    }
    const res = await FetchPostData(
      toggleLoading,
      "projects",
      token,
      formEntries
    )
    if (res?.data?.success) {
      setData(res?.data?.data?.project2)
      setAllData(res?.data?.data?.project2)
    }
  }
  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns1}
                      data={data}
                      allData={allData}
                      roles={roles}
                      setAccessDenied={setAccessDenied}
                      setData={setData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Task  */}
      <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Add Task</h5>
              <button
                type="button"
                onClick={() => {
                  setAddTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <AddTask projectId={projectId} />
          </ResizableBox>
        </Draggable>
      </div>

      <Modal size="xl" isOpen={modal_fullscreen} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="col-6 d-flex align-items-center">
              <h5 className=" modal-title mt-0">View Project Details</h5>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Milestones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Credential
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="7">
                    <ProjectOverview projectDetail={projectDetail} />
                  </TabPane>
                  <TabPane tabId="3">
                    <ViewTasks
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                      projectDetail={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Notes
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                      edit={false}
                      projectId={projectDetail?.id}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Milestones
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                      edit={false}
                      projectId={projectDetail?.id}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <ActivityLog
                      verticalActiveTab={verticalActiveTab}
                      type={"edit"}
                      projectId={projectDetail?.id}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <Credential projectDetail={projectDetail} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default FixedProjects
