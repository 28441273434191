import React, { useEffect, useState } from "react"
import { Form, Label, Row } from "reactstrap"
import { preConditionSupport, priorityOption } from "utils/common-options"
import Select from "react-select"
import { useParams } from "react-router-dom"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"

const CreateTestCase = ({
  type,
  task,
  taskOption,
  closeModal,
  caseDetail,
  updatedData,
  taskIds,
}) => {
  const [formRows, setFormRows] = useState([{}])
  const [stepForm, setStepsForm] = useState([{}])
  const [testForm, setTestForm] = useState([{}])
  const [taskId, setTaskId] = useState([])
  const [testDescription, setTestDescription] = useState("")
  const [expectedResult, setExpectedResult] = useState("")
  const [serverity, setSeverity] = useState([])
  const [comments, setComments] = useState("")
  const [isLoading, setLoading] = useState(false)
  const param = useParams()
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const onAddFormRow = type => {
    if (type == 1) {
      setFormRows([...formRows, {}])
    } else if (type == 2) {
      setStepsForm([...stepForm, {}])
    } else if (type == 3) {
      setTestForm([...testForm, {}])
    }
  }
  const onDeleteFormRow = (index, type) => {
    if (type == 1) {
      const newInputs = [...formRows]
      newInputs.splice(index, 1)
      setFormRows(newInputs)
    } else if (type == 2) {
      const stepInput = [...stepForm]
      stepInput.splice(index, 1)
      setStepsForm(stepInput)
    } else if (type == 3) {
      const testInput = [...testForm]
      testInput.splice(index, 1)
      setTestForm(testInput)
    }
  }

  const onChangeFormRow = (index, newValue, type) => {
    if (type == 1) {
      const updatedSelectedValues = [...formRows]
      updatedSelectedValues[index].condition = newValue
      setFormRows(updatedSelectedValues)
    } else if (type == 2) {
      const updatedRows = [...stepForm]
      updatedRows[index].value = newValue
      setStepsForm(updatedRows)
    } else if (type == 3) {
      const updatedRows = [...testForm]
      updatedRows[index].field = newValue
      setTestForm(updatedRows)
    } else if (type == 4) {
      const updatedRows = [...testForm]
      updatedRows[index].value = newValue
      setTestForm(updatedRows)
    }
  }

  const onHandleTestCase = async e => {
    e.preventDefault()
    const formEntries = {
      testcase_id: caseDetail?.id,
      user_id: localStorage.getItem("auth_id"),
      task_id: taskId?.value,
      project_id: param?.id,
      testcase_description: testDescription,
      expected_result: expectedResult,
      severity: serverity?.value,
      comments: comments,
      preconditions: formRows?.map(obj => obj?.condition?.value)?.join("@#"),
      test_steps: stepForm?.map(obj => obj?.value)?.join("@#"),
      test_data_field: testForm?.map(obj => obj?.field)?.join("@#"),
      test_data_value: testForm?.map(obj => obj?.value)?.join("@#"),
    }
    const res = await FetchPostData(
      toggleLoading,
      `${type == "edit" ? "editTestCases" : "addTestCases"}`,
      token,
      formEntries
    )
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: `Test Case ${type == "edit" ? "Edited" : "Added"} Successfully`,
      }).then(data => {
        setFormRows([{}])
        setStepsForm([{}])
        setTestForm([{}])
        setTaskId([])
        setTestDescription("")
        setExpectedResult("")
        setSeverity([])
        setComments("")
        closeModal(false)
        updatedData(res?.data?.data)
      })
    }
  }

  useEffect(() => {
    if (type == "edit") {
      setTaskId(taskOption?.find(obj => obj?.value == caseDetail?.task_id))
      setTestDescription(caseDetail?.description)
      setExpectedResult(caseDetail?.expected_result)
      setSeverity(
        priorityOption?.find(obj => obj?.value == caseDetail?.severity)
      )
      setComments(caseDetail?.comments)
      const steps = caseDetail?.test_steps?.split("@#")
      const fields = caseDetail?.test_data_field?.split("@#")
      const values = caseDetail?.test_data_value?.split("@#")
      const preconditions = caseDetail?.preconditions?.split("@#")
      setFormRows(
        preconditions?.map(obj => ({
          condition: preConditionSupport?.find(con => con.value == obj),
        }))
      )
      setTestForm(
        fields?.map((obj, i) => ({
          field: obj,
          value: values[i],
        }))
      )
      setStepsForm(
        steps?.map(obj => ({
          value: obj,
        }))
      )
    }
    if (task == false) {
      console.log(taskIds)
      setTaskId(taskIds)
    }
  }, [type, caseDetail, taskOption, taskIds ,task])

  return (
    <div>
      {isLoading && <Loader />}
      <div className="modal-body pe-3">
        <form onSubmit={onHandleTestCase}>
          <div className="d-flex justify-content-between mt-3 col-md-12">
            <div className="test-case-width ">
              {task ? (
                <div className="col-md-12 mb-4 ">
                  <Label htmlFor="formrow-firstname-Input">
                    Task <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Task"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={taskOption}
                    value={taskId}
                    onChange={e => setTaskId(e)}
                  />
                </div>
              ) : null}
              <div
                className="col-md-12 d-flex flex-column pe-3 "
                style={{
                  scrollbarWidth: "none",
                  maxHeight: "550px",
                  overflow: "auto",
                }}
              >
                <div className="mt-3 mb-4">
                  <div className="col-12">
                    <label htmlFor="">Test Case Description <span className="text-danger">*</span></label>
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter Testcase Decription"
                      value={testDescription}
                      onChange={e => setTestDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center px-0">
                    <label htmlFor="">Preconditions</label>
                    <div className="text-end mb-3">
                      <a
                        className="project-info pe-2 ps-2 bg-purple"
                        onClick={e => {
                          e.preventDefault()
                          onAddFormRow(1)
                        }}
                      >
                        <i className="fas fa-plus me-2"></i> Preconditions
                      </a>
                    </div>
                  </div>
                  {formRows?.map((person, key) => {
                    return (
                      <div key={key} className="mb-2 ps-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="contact-form d-flex col-md-11">
                            <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                              <Select
                                placeholder="Select Severity"
                                className="basic-multi-select"
                                classNamePrefix=" select"
                                options={preConditionSupport}
                                value={person?.condition}
                                onChange={e => onChangeFormRow(key, e, 1)}
                              />
                            </div>
                          </div>
                          <div className=" contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                            <div className="text-end">
                              <button
                                className="btn btn-sm btn-danger ms-2"
                                title="Remove"
                                onClick={e => {
                                  e.preventDefault()
                                  onDeleteFormRow(key, 1)
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center px-0">
                    <label htmlFor="">Test Steps <span className="text-danger">*</span></label>
                    <div className="text-end mb-3">
                      <a
                        className="project-info pe-2 ps-2  bg-purple"
                        onClick={e => {
                          e.preventDefault()
                          onAddFormRow(2)
                        }}
                      >
                        <i className="fas fa-plus me-2"></i> Test Steps
                      </a>
                    </div>
                  </div>
                  {stepForm?.map((person, key) => {
                    return (
                      <div key={key} className="mb-2 ps-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="contact-form d-flex col-md-11">
                            <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                              <input
                                type="text"
                                name="task_name"
                                placeholder="Enter Steps"
                                className="form-control "
                                value={person.value || ""}
                                onChange={e =>
                                  onChangeFormRow(key, e.target.value, 2)
                                }
                              />
                            </div>
                          </div>
                          <div className="contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                            <div className="text-end">
                              <button
                                className="btn btn-sm btn-danger ms-2"
                                title="Remove"
                                onClick={e => {
                                  e.preventDefault()
                                  onDeleteFormRow(key, 2)
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="info_box_1"></div>
            <div className="test-case-width ">
              <div
                className="row pt-2"
                style={{
                  scrollbarWidth: "none",
                  maxHeight: "550px",
                  overflow: "auto",
                }}
              >
                <div className="mb-3 border-bottom pb-4 mb-4">
                  <div className="d-flex justify-content-between align-items-center px-0 mb-2">
                    <label htmlFor="">Test Data</label>
                    <div className="text-end ">
                      <a
                        className="project-info pe-2 ps-2 bg-purple mb-2"
                        onClick={e => {
                          e.preventDefault()
                          onAddFormRow(3)
                        }}
                      >
                        <i className="fas fa-plus me-2"></i> Test Data
                      </a>
                    </div>
                  </div>
                  {testForm?.map((person, key) => {
                    return (
                      <div key={key} className="mb-2 ps-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="contact-form d-flex col-md-11">
                            <div className="contact-column edit-contact-column mb-md-0 col-md-4">
                              <input
                                type="text"
                                name="task_name"
                                placeholder="Enter Field Name"
                                className="form-control "
                                value={person.field || ""}
                                onChange={e =>
                                  onChangeFormRow(key, e.target.value, 3)
                                }
                              />
                            </div>
                            <div className="contact-column edit-contact-column mb-md-0 col-md-8">
                              <input
                                type="text"
                                name="task_name"
                                placeholder="Enter Field Value"
                                className="form-control "
                                value={person.value || ""}
                                onChange={e =>
                                  onChangeFormRow(key, e.target.value, 4)
                                }
                              />
                            </div>
                          </div>
                          <div className="contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                            <div className="text-end">
                              <button
                                className="btn btn-sm btn-danger ms-2"
                                title="Remove"
                                onClick={e => {
                                  e.preventDefault()
                                  onDeleteFormRow(key, 3)
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="col-md-8 pb-4 mb-4 border-bottom">
                  <Label htmlFor="formrow-firstname-Input">
                    Case Expected Results <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  Case Expected Results"
                      value={expectedResult}
                      onChange={e => setExpectedResult(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 pb-4 mb-4 border-bottom">
                  <Label htmlFor="formrow-firstname-Input">
                    Severity <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Severity"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={priorityOption}
                    value={serverity}
                    onChange={e => setSeverity(e)}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">
                    Comments/Notes <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter Comments/Notes"
                      value={comments}
                      onChange={e => setComments(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 text-end">
            <button className=" btn btn-save btn-label">
              <i className="fas fa-save me-2 label-icon"></i>Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateTestCase
