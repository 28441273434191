import React, { useState } from 'react'
import { Card, Col, Form, Input, Label, Row } from 'reactstrap'
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Dropzone from "react-dropzone"
import { formatBytes } from "utils/common-helpers"
import axios from 'axios'
import Swal from 'sweetalert2'
import configure from 'configure'
import Loader from 'pages/Separate/Loader'
import bug from "../../../../assets/images/flags/bug-icon.png"
import Suggestion from "../../../../assets/images/flags/sugg-icon.png"
import High from "../../../../assets/images/flags/high.png"
import Medium from "../../../../assets/images/flags/medium.png"
import Low from "../../../../assets/images/flags/low.png"
import { Tooltip } from 'antd'

const TicketHelp = ({ closeModal }) => {
    const [selectedFiles1, setselectedFiles1] = useState([])
    const [title, setTitle] = useState('')
    const [type, setType] = useState('0')
    const [priority, setPriority] = useState('low')
    const [description, setDescription] = useState('')
    const [isLoading, setLoading] = useState(false)
    function handleAcceptedFiles1(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles1(files)
    }

    const onAddTicket = async e => {
        e.preventDefault();
        if (type.length != 0 && priority != 0) {
            if (description?.length > 40) {
                Swal.fire({
                    icon: "warning",
                    title: "Are You Sure?",
                    text: "You want to report this Bug or Suggestion?",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Report it!',
                    cancelButtonText: 'No, cancel!',
                }).then(async result => {
                    if (result?.isConfirmed) {
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("user_id", localStorage.getItem("auth_id"))
                        formData.append("subject", title)
                        formData.append("description", description)
                        formData.append("type", type)
                        formData.append("priority", priority)
                        if (selectedFiles1?.length != 0) {
                            for (let i = 0; i < selectedFiles1.length; i++) {
                                formData.append("files[]", selectedFiles1[i])
                            }
                        }
                        try {
                            const res = await axios({
                                method: "post",
                                url: `${configure.apiUrl}/addSupportTicket`,
                                data: formData,
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            setLoading(false)
                            if (res?.data?.success) {
                                Swal.fire({
                                    icon: "success",
                                    text: "Bug Reported Successfully",
                                }).then(data => {
                                    setTitle('')
                                    setDescription('')
                                    setPriority([])
                                    setType([])
                                    setselectedFiles1([])
                                    closeModal(false)
                                })
                            }
                        } catch (error) {
                            console.log(error)
                            setLoading(false)
                        }
                    }
                })

            } else {
                Swal.fire({
                    icon: "warning",
                    text: "Description must be at least 40 characters long",
                })
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Kindly Complete All Required Fields"
            })
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="modal-body p-2">
                <Form
                    className="row d-flex justify-content-center align-items-center"
                    onSubmit={onAddTicket}
                >
                    <div className="col-md-12 d-flex flex-column new-task-modal mt-4 ">
                        <div className='row'>
                            <div className="col-sm-10 ps-2  mb-4">
                                <Label htmlFor="formrow-firstname-Input">
                                    Title <span className="text-danger">*</span>
                                </Label>
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2 ps-2 mb-4">
                                <Label htmlFor="formrow-firstname-Input">Type</Label>
                                <div className='d-flex gap-4 mt-2'>
                                    <Tooltip color={"#752b97"} title={"Bug"}>
                                        <a className={`${type == '0' ? 'blinking-icon' : ''}`} onClick={() => setType('0')}>
                                            <img src={bug} alt='No Img' className='' width={25} height={25} />
                                        </a>
                                    </Tooltip>
                                    <Tooltip color={"#752b97"} title={"Suggestions"}>
                                        <a className={`${type == '1' ? 'blinking-icon' : ''}`} onClick={() => setType('1')}>
                                            <img src={Suggestion} alt='No Img' className='' width={25} height={25} />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-sm-3 ps-2 mb-4">
                                <Label htmlFor="formrow-firstname-Input">
                                    Priority <span className="text-danger">*</span>
                                </Label>
                                <div className='d-flex gap-3 mt-2'>
                                    <Tooltip color={"#752b97"} title={"High"}>
                                        <a className={`${priority == 'high' ? 'blinking-icon' : ''}`} onClick={() => setPriority('high')}>
                                            <img src={High} alt='No Img' className='' width={25} height={25} />
                                        </a>
                                    </Tooltip>
                                    <Tooltip color={"#752b97"} title={"Medium"}>
                                        <a className={`${priority == 'medium' ? 'blinking-icon' : ''}`} onClick={() => setPriority('medium')}>
                                            <img src={Medium} alt='No Img' className='' width={25} height={25} />
                                        </a>
                                    </Tooltip>
                                    <Tooltip color={"#752b97"} title={"Low"}>
                                        <a className={`${priority == 'low' ? 'blinking-icon' : ''}`} onClick={() => setPriority('low')}>
                                            <img src={Low} alt='No Img' className='' width={25} height={25} />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-8 d-flex justify-content-start align-items-center gap-3 mb-4">
                                <div className="col-md-5 col-sm-12">
                                    <label htmlFor="">Attachment</label>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles1(acceptedFiles)
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div
                                                className="dz-message needsclick p-0"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="d-flex px-2 custom-file-upload align-items-center">
                                                    <div className="">
                                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h6 className="mb-0 ms-2">Upload Files</h6>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                <div
                                    className={` dropzone-previews ${selectedFiles1.length > 0 ? "d-block" : "d-none"
                                        }`}
                                    id="file-previews"
                                >
                                    <label className="mb-1">Uploaded Files</label>
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-0">
                                            <Row className="align-items-center justify-content-evenly">
                                                {selectedFiles1?.map((f, i) => {
                                                    return (
                                                        <Col key={i} className="col-auto">
                                                            <img
                                                                data-dz-thumbnail=""
                                                                height="70"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={f.name}
                                                                src={f.preview}
                                                            />
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-12 ps-2 mb-4">
                                <label htmlFor="">Description</label>
                                <div className="no-drag">
                                    <CKEditor
                                        style={{ height: "500px" }}
                                        editor={ClassicEditor}
                                        data={description ? description : ""}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setDescription(data)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='text-end'>
                        <button className=" btn btn-save btn-label">
                            <i className="fas fa-save me-2 label-icon"></i>
                            Submit
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default TicketHelp
