import React, { useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import ProjectSprint from "./ProjectSprint"


const ProjectSprintIndex = () => {
    document.title = "Future Plan | Zithas Crm"
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className="page-content mb-4">
                <Container fluid>
                    <Breadcrumbs title="Digital Marketing" breadcrumbItem="Future Plan" />
                    <Row>
                        <Col lg={12} className="p-0">
                            <Card>
                                <CardBody>
                                    <ProjectSprint />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProjectSprintIndex
