import moment from "moment"
import React from "react"
import { issueType, ticketStatus } from "utils/common-options"
import ZithasLogo from "../../../assets/images/main-zithas.png"
import configure from "configure"
import bug from "../../../assets/images/flags/bug-icon.png"
import Suggestion from "../../../assets/images/flags/sugg-icon.png"
import { addMultiples } from "utils/common-helpers"

export const earthTicketsColumns = (onReply) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center",
    },
    {
        Header: "Added By",
        title: "type_name",
        accessor: "added_by",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center",
        Cell: cellProps => {
            const icon = cellProps.row?.original?.type == 0 ? bug : Suggestion
            return (
                <div>
                    <img src={`${cellProps.row?.original?.user_pic != null ? `${configure.imgUrl}${cellProps.row?.original?.user_pic}` : ZithasLogo}`} alt="" className="img-fluid assignee-img" title={cellProps.row?.original?.user_name}></img>
                    {icon != null ? (
                        <img src={icon} alt="" className="img-fluid assignee-img ms-2" ></img>
                    ) : null}
                </div>
            )
        },
    },
    {
        Header: "Title",
        title: "Task Name",
        accessor: "subject",
        isInfoIcon: false,
        isShortIcon: true,
        className: "stask_name_box",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "sstatus_box ",
        Cell: cellProps => {
            return (
                <div className="custom-select-wrapper">
                    {ticketStatus?.find((obj) => obj?.value == cellProps.row?.original?.status)?.label}
                </div>
            )
        },
    },
    {
        Header: "Priority",
        title: "Priority",
        accessor: "priority",
        isInfoIcon: false,
        isShortIcon: true,
        className: "prioritys_col_box text-capitalize text-center",
        Cell: cellProps => {
            const cellValue = cellProps.value
            let style = {}

            if (cellValue === "high") {
                style = { color: "red" }
            } else if (cellValue === "medium") {
                style = { color: "blue" }
            } else if (cellValue === "low") {
                style = { color: "green" }
            }

            return (
                <div style={style}>
                    {cellValue}
                </div>
            )
        },
    },
    {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "screated_at_col-box",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "slast_reply_col-box ",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "msupport_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        onClick={() => onReply(cellProps?.row?.original)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Reply Ticket"
                    >
                        <i className="bi bi-reply-fill"></i>
                    </a>
                </div>
            )
        },
    },
]

export const projectCoinsColumns = (handleSendTesting) => [
    {
        Header: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: " col-md-6",
    },
    {
        Header: "Total Hrs",
        accessor: "total_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: " col-md-1 text-center ",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{addMultiples(obj?.used_dev_hrs, obj?.used_kam_hrs, obj?.used_qa_hrs)}/{obj?.total_hrs}</div>)
        }
    },
    {
        Header: "Dev Hrs",
        accessor: "dev_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: " col-md-1 text-center ",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{obj?.used_dev_hrs}/{obj?.dev_hrs}</div>)
        }
    },
    {
        Header: "KAM Hrs",
        accessor: "kam_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{obj?.used_kam_hrs}/{obj?.kam_hrs} </div>)
        }
    },
    {
        Header: "QA Hrs",
        accessor: "qa_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{obj?.used_qa_hrs}/{obj?.qa_hrs} </div>)
        }
    },
    {
        Header: "Tickets",
        accessor: "total_tickets",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
    },
    {
        Header: "Tickets Queries",
        accessor: "tickets_queries",
        className: " text-center  col-md-1",
    },
    {
        Header: "Round",
        accessor: "rounds",
        className: "stask_name_box",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{obj?.rounds || '-'} </div>)
        }
    },
    {
        Header: "Start Date",
        accessor: "startdate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{moment(obj?.startdate).format('DD-MM-YYYY')} </div>)
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{moment(obj?.enddate).format('DD-MM-YYYY')} </div>)
        }
    },
    {
        Header: "Tasks",
        accessor: "total_tasks",
        isInfoIcon: false,
        isShortIcon: true,
        className: "stask_name_box",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (<div>{obj?.dev_complete_tasks}/{obj?.total_tasks}</div>)
        }
    },
    {
        Header: "Action",
        accessor: "subject",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center  col-md-1",
        Cell: cellProps => {
            const obj = cellProps?.row?.original
            return (
                <a
                    className="btn btn-sm btn-outline-success me-1"
                    title="Send For Testing"
                    onClick={() => handleSendTesting(obj?.id)}
                >
                    <i className="fas fa-arrow-right"></i>
                </a>
            )
        }
    },
]