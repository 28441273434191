import moment from "moment"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import secureLocalStorage from "react-secure-storage"
import { Row, Col, Card, CardBody, Modal } from "reactstrap"
import { fetchData } from "utils/common-fetchData"

const TicketLog = ({ verticalActiveTab, ticket_id }) => {
  const [old_data, setOldData] = useState({})
  const [new_data, setNewData] = useState({})
  const [viewData, setViewData] = useState(false)
  const labelobj = Object.keys(old_data)
  const oldValue = Object.values(old_data)
  const newValue = Object.values(new_data)
  const [logdata, setLogData] = useState([])
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const toggleLoading = loading => {
    setLoading(loading)
  }
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetchData(
        toggleLoading,
        `supportActivityLog/${ticket_id}`,
        token
      )
      if (res?.success) {
        console.log(res)
        setLogData(res?.data)
      }
    }
    if (verticalActiveTab == 3) {
      fetchProjects()
    }
  }, [verticalActiveTab, ticket_id])

  return (
    <div>
      {isLoading && <Loader />}
      <div className="px-4">
        <h5 className="text-dark mb-2">Activity log</h5>
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="ticket-history-box">
                    <ul className="verti-timeline list-unstyled">
                      {logdata?.map((status, key) => {
                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                  status.id === 3 ? "bx-fade-right" : ""
                                }`}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h5>{status.description}</h5>
                                    <p className="text-muted">
                                      At{" "}
                                      {moment(
                                        status.created_at.slice(0, 10)
                                      ).format("DD-MM-YYYY")}{" "}
                                      By {status.alias_name}
                                    </p>
                                  </div>
                                  {status.new_data != null ? (
                                    <div className="ml-30">
                                      <a
                                        className="btn btn-sm activity-log-btn text-info"
                                        onClick={() => {
                                          setOldData(
                                            JSON.parse(status.old_data)
                                          )
                                          setNewData(
                                            JSON.parse(status.new_data)
                                          )
                                          setViewData(true)
                                        }}
                                      >
                                        <i className="fas fa-eye"></i> View
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <p className="timing">
                                {status.created_at.slice(10)}
                              </p>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>Fields</th>
                  <th>Old Data</th>
                  <th>New Data</th>
                </tr>
              </thead>
              <tbody>
                {labelobj?.map((items, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{items}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: oldValue[index],
                          }}
                        />
                      </td>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: newValue[index],
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TicketLog
