import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../../assets/images/Digital Marketing/editEventImg.jpg"
import secureLocalStorage from "react-secure-storage";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import moment from "moment";
import Loader from "pages/Separate/Loader";
import { addTimes, adjustTime, calculateQtyWithinTime, calculateTotalTime, FilteredArray, getNextMonthStartEnd, updateTimeForDate } from "utils/common-helpers";
import Swal from "sweetalert2";
import Select from "react-select"
import { ResizableBox } from "react-resizable";
import DraggablePop from "react-draggable";

const PlanningCalendar = () => {
  document.title = "Monthly Planning | Zithas Crm";
  const [event, setEvent] = useState({});
  const [activities, setActivities] = useState([])
  const [filterActivities, setFilterActivities] = useState([])
  const [allActivities, setAllActivities] = useState([])
  const [activityName, setActiviyName] = useState('');
  const [addTask, setAddTask] = useState(false)
  const [projectOptions, setProjectOptions] = useState([])
  const [serviceOptions, setServiceOption] = useState([])
  const [projectId, setProjectId] = useState([])
  const [service, setService] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [activityOption, setActivityOption] = useState([])
  const [digitalActivities, setDigitalActivities] = useState([])
  const [extendedData, setExtendedData] = useState({});
  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (modal) {
        setModal(false)
      }
    }
  })

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [eventsList, setEvents] = useState([]);
  const [modalData, setModalData] = useState({})
  const [eventData, setEventData] = useState({});
  const [qty, setQty] = useState('');
  const [addModal, setAddModal] = useState(false)
  const [changeKey, setChangeKey] = useState(false)

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);


  const handleEventClick = (arg, dragging) => {
    const event = arg.event;
    const data = event?._def?.extendedProps;
    setActiviyName(event?.title)
    setQty(data?.quantity)
    const dat = moment(event.start).format('YYYY-MM-DD');
    const htmlString = customDateHtml[dat];
    const timeMatch = htmlString ? htmlString.match(/(\d{2}:\d{2})/) : null;
    const time = timeMatch ? timeMatch[0] : "00:00";
    setExtendedData({
      ...data,
      occupied: time
    })
    if (dragging == false) {
      setIsEdit(true);
      toggle();
    }
  };


  const onDrag = event => {
    event.preventDefault();
  };


  const onDrop = async (event) => {
    const { date, draggedEl } = event;
    const categoryId = draggedEl.id;
    const quantity = draggedEl.getAttribute('data-quantity');
    const estTime = draggedEl.getAttribute('data-est');
    const type = draggedEl.getAttribute('type');
    if (!draggedEl || !draggedEl.classList.contains('external-event')) {
      return;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currentTime = new Date();

    const modifiedDate = new Date(
      year,
      month,
      day,
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    );
    const mainDate = moment(date).format('YYYY-MM-DD')
    const time = customDateHtml?.find((obj) => obj?.date == mainDate)?.time
    const newEvent = {
      title: draggedEl.innerText || draggedEl.getAttribute('title'),
      start: modifiedDate,
      className: draggedEl.className,
      id: categoryId,
      quantity: quantity,
      est_time: estTime,
      actual_time: calculateTotalTime(estTime, quantity),
      occupied: time
    };
    const formEntries = {
      staff_id: localStorage.getItem('auth_id'),
      project_id: projectId?.value,
      activity_id: categoryId,
      type: service?.value,
      quantity: quantity,
      start_date: mainDate,
      user_id: localStorage.getItem('auth_id'),
      className: draggedEl.className,
    }
    setActiviyName(draggedEl.innerText || draggedEl.getAttribute('title'))
    setQty(calculateQtyWithinTime(time, estTime, quantity))
    setModalData(formEntries)
    setEventData(newEvent)
    if (isDragging == false) {
      setAddModal(true)
    }
  };

  const [customDateHtml, setCustomDateHtml] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [date, setDate] = useState('')
  const token = secureLocalStorage.getItem('auth_id')
  const toggleLoading = (load) => {
    setLoading(load);
  }

  const handleAddActivity = async (e) => {
    e.preventDefault();
    if (qty != 0) {
      const formEntries = { ...modalData, quantity: qty, est_hrs: calculateTotalTime(eventData?.est_time, qty), task_type: 4 }
      const res = await FetchPostData(toggleLoading, "addDigitalProjectTask", token, formEntries)
      if (res?.data?.success) {
        const updateData = res?.data?.data?.map((obj) => ({
          title: obj?.name,
          start: obj?.date,
          id: obj?.id,
          task_id: obj?.id,
          activity_id: obj?.activity_id,
          quantity: obj?.quantity,
          est_time: obj?.est_hrs,
          actual_time: obj?.est_hrs,
          className: obj?.className,
          staff_id: obj?.staff_id
        }))
        setEvents(updateData);
        setCustomDateHtml(updateTimeForDate(customDateHtml, modalData?.start_date, calculateTotalTime(eventData?.est_time, qty)))
        setActivities(FilteredArray(allActivities, res?.data?.data))
        setFilterActivities(FilteredArray(allActivities, res?.data?.data))
        setChangeKey(!changeKey)
        setAddModal(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "⚠️ Quantity cannot be zero! Please update it. ✨"
      })
    }
  }


  const fetchProjectList = async () => {
    const userId = localStorage.getItem('auth_id')
    const res = await fetchData(toggleLoading, `digitalProjectByUserIdDate/${userId}/1`, token);
    if (res?.success) {
      const getServiceArray = (assigneeString) => {
        return assigneeString ? assigneeString.split(',').map((id) => id.trim()) : [];
      };
      const output = res?.data.map((project) => {
        const services = [];

        if (getServiceArray(project.seo_assignee).includes(userId.toString())) {
          services.push({ label: 'SEO', value: '1' });
        }
        if (getServiceArray(project.smm_assignee).includes(userId.toString())) {
          services.push({ label: 'SMM', value: '2' });
        }
        if (getServiceArray(project.ppc_assignee).includes(userId.toString())) {
          services.push({ label: 'PPC', value: '3' });
        }
        if (getServiceArray(project.em_assignee).includes(userId.toString())) {
          services.push({ label: 'EM', value: '4' });
        }
        return {
          label: project.project_name,
          value: project.id,
          service: services
        };
      });
      setProjectOptions(output)
    }
  }

  useEffect(() => {
    fetchProjectList()
  }, [])

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);

  const onGetActivities = async (e) => {
    if (service?.length != 0 && projectId?.length != 0) {
      const user_id = localStorage.getItem('auth_id')
      const formEntries = {
        project_id: projectId?.value,
        user_id: user_id,
        type: service?.value
      }
      const res = await FetchPostData(toggleLoading, `digitalActivitiesByProject`, token, formEntries);
      if (res?.data?.success) {
        const details = res?.data?.data
        setCustomDateHtml(details?.availability?.original?.data)
        setAllActivities(details?.activities)
        setFilterActivities(FilteredArray(details?.activities, details?.assign_activities))
        setActivities(FilteredArray(details?.activities, details?.assign_activities))
        setEvents(details?.assign_activities?.map((obj) => ({
          title: obj.name,
          start: obj?.date,
          id: obj.id,
          task_id: obj.id,
          activity_id: obj?.activity_id,
          quantity: obj?.quantity,
          est_time: obj?.est_hrs,
          actual_time: obj?.est_hrs,
          className: obj?.className,
          staff_id: obj?.staff_id,
        })))
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please select Mandatory Field",
      })
    }
  }

  const handleBlur = (e) => {
    const convertToMinutes = (timeStr) => {
      const [hours, minutes] = timeStr.split(":").map(Number);
      return (hours * 60) + minutes;
    };
    const plannedTime = "08:00";
    const enteredTime = addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00");
    const plannedTimeInMinutes = convertToMinutes(plannedTime);
    const enteredTimeInMinutes = convertToMinutes(enteredTime);
    if (enteredTimeInMinutes > plannedTimeInMinutes) {
      Swal.fire({
        icon: "warning",
        title: "⏰ Time Exceeded",
        text: "Your total working hours have been exceeded. Please ensure to review and adjust accordingly.",
        confirmButtonText: "Okay",
        showCancelButton: true,
        confirmButtonText: '✅ Confirm',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          setQty(addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00"))
        }
      })
    }
  };

  const handleGetActivities = async () => {
    const res = await fetchData(toggleLoading, "digitalActivities", token);
    if (res?.success) {
      setAddTask(true)
      setActivityOption(res?.data?.map((obj) => ({
        value: obj?.id,
        label: obj?.activity,
        est_time: obj?.est_time,
        service: obj?.service
      })))
    }
  }

  const onHandleAddActivity = async (e) => {
    e.preventDefault();
    const fromEntries = {
      staff_id: localStorage.getItem('auth_id'),
      project_id: projectId?.value,
      activity_id: digitalActivities?.value,
      type: service?.value,
      quantity: qty,
      start_date: date,
      user_id: localStorage.getItem('auth_id'),
      className: 'bg-seo external-event fc-event',
      est_hrs: calculateTotalTime(digitalActivities?.est_time, qty),
      task_type: 4
    }
    const newEvent = {
      title: digitalActivities?.label,
      start: date,
      className: 'bg-seo external-event fc-event',
      id: digitalActivities?.value,
      quantity: qty,
      est_time: digitalActivities?.est_time,
      actual_time: calculateTotalTime(digitalActivities?.est_time, qty),
    };
    const res = await FetchPostData(toggleLoading, "addDigitalProjectTask", token, fromEntries)
    if (res?.data?.success) {
      Swal.fire({
        icon: 'success',
        title: "Extra Activity Added Successfully",
      }).then(() => {
        setEvents((prevEvents) => [...prevEvents, newEvent]);
        setCustomDateHtml(updateTimeForDate(customDateHtml, fromEntries?.start_date, newEvent?.actual_time))
        setActivities(FilteredArray(allActivities, res?.data?.data))
        setFilterActivities(FilteredArray(allActivities, res?.data?.data))
        setChangeKey(!changeKey)
        setAddTask(false)
      })
    }
  }

  const onDragEdit = async (info, oldStart) => {
    const { staff_id, activity_id, task_id, actual_time } = info?.extendedProps;
    const formEntries = {
      staff_id: staff_id,
      project_id: projectId?.value,
      activity_id: activity_id,
      user_id: staff_id,
      task_id: task_id,
      start_date: moment(info?.start).format('YYYY-MM-DD'),
      type: service?.value,
      est_hrs: actual_time
    }
    const res = await FetchPostData(toggleLoading, "editDigitalProjectTask", token, formEntries);
    if (res?.data?.success) {
      setIsDragging(false);
      setCustomDateHtml(adjustTime(customDateHtml, moment(info?.start).format('YYYY-MM-DD'), moment(oldStart).format('YYYY-MM-DD'), actual_time))
    }
  }

  const onSendApproval = async () => {
    if (service?.length != 0 && projectId?.length != 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: "You want to send activities for approval",
        icon: 'warning',
        showCancelButton: true,
      }).then(async (isOkay) => {
        if (isOkay?.isConfirmed) {
          const fromEntries = {
            project_id: projectId?.value,
            user_id: localStorage.getItem('auth_id'),
            type: service?.value,
            task_id: eventsList?.map((obj) => obj?.task_id)?.join(',')
          }
          const res = await FetchPostData(toggleLoading, "sendTaskForApprove", token, fromEntries);
          if (res?.data?.success) {
            Swal.fire({
              icon: 'success',
              title: "Activity Sent for Approval",
            }).then(() => {
              const details = res?.data?.data
              setChangeKey(!changeKey)
              setEvents(details?.assign_activities?.map((obj) => ({
                title: obj.name,
                start: obj?.date,
                id: obj.id,
                task_id: obj.id,
                activity_id: obj?.activity_id,
                quantity: obj?.quantity,
                est_time: obj?.est_hrs,
                actual_time: obj?.est_hrs,
                className: obj?.className,
                staff_id: obj?.staff_id,
              })))
            })
          }
        }
      })
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please select Mandatory Field",
      })
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Row>
          <div className="col-12 d-flex justify-space-between align-items-center">
            <div className="">
              <button className="btn text-white me-0 btn-primary btn-label"
                onClick={() => handleGetActivities()}
              >
                <i className="bx bx-plus me-2 icon-size label-icon"></i>Extra Activities
              </button>
            </div>
            <div className="d-flex gap-2 col-md-7 align-items-center justify-content-end">
              <div className="ms-2 col-5">
                <Select
                  placeholder="Select Project "
                  className="basic-multi-select "
                  classNamePrefix="select"
                  options={projectOptions}
                  value={projectId}
                  onChange={(e) => {
                    setProjectId(e)
                    setService([])
                    setServiceOption(e.service)
                  }}
                />
              </div>
              <div className="ms-2 col-2">
                <Select
                  placeholder="Service "
                  className="basic-multi-select "
                  classNamePrefix="select"
                  options={serviceOptions}
                  value={service}
                  onChange={(e) => setService(e)}
                />
              </div>
              <button className="btn  btn-save me-1" onClick={(e) => onGetActivities()}>
                <i className="fas fa-filter search-icon label-icon"></i>
              </button>
              <button className="btn text-white me-0 btn-success btn-label"
                onClick={(e) => onSendApproval(e)}
              >
                <i className="bx bx-list-check me-2 icon-size label-icon"></i>Send For Approval
              </button>
            </div>
          </div>
          <Row className="mt-4 pt-2 p-0">
            <Col lg={4}>
              <Card>
                <div className="pt-0" >
                  <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "auto", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                    <div className="search-box mb-3 me-2 ms-2">
                      <input id="search-bar-0" type="text" className="s form-control rounded " placeholder=" Search Activities ..."
                        onChange={(e) => {
                          setSearchValue(e.target.value)
                          const searchValue = e.target.value.toLowerCase();
                          const filteredActivities = filterActivities.filter((activity) => activity.activity.toLowerCase().includes(searchValue));
                          setActivities(filteredActivities)
                        }}
                        value={searchValue}
                      />
                      <span className=""><i className="bx bx-search-alt search-icon ms-2"></i></span>
                      <a className=""><i className="bx  bx-x-circle search-icon ms-2 text-danger cursor-pointer"
                        onClick={() => {
                          setSearchValue('')
                          setActivities(filterActivities)
                        }} style={{ left: "90%" }}></i></a>
                    </div>
                    {activities &&
                      activities.map((category, i) => {
                        const bgColor = category?.service_type == 1 ? "bg-seo" : category?.service_type == 2 ? "bg-smm" : category?.service_type == 3 ? "bg-ppc" : category?.service_type == 4 ? "bg-em" : "";
                        return (
                          <div className="d-flex justify-space-between align-items-center" key={i} >
                            <div
                              className={`${bgColor} external-event fc-event text-white w-100 d-flex justify-space-between align-items-center`}
                              id={category?.id}
                              data-quantity={category?.quantity}
                              data-est={category?.est_time}
                              data-type={category?.service_type}
                              draggable
                              onDrag={event => onDrag(event, category)}
                            >
                              <span>
                                <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                {category.activity}
                              </span>

                              <span className="badge bg-light text-dark ms-2"> {category.quantity}</span>
                            </div>
                          </div>
                        )
                      }
                      )}
                    {activities?.length == 0 ? (
                      <p className="text-center text-purple fw-bold mt-3">🎉 All pending tasks have been planned for future! <br /> 🌟 You can now ask Your KAM to Approve. 🚀</p>
                    ) : null}
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={8} className="pe-0 ps-0">
              <div className="show-date">
                <FullCalendar
                  key={changeKey}
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  weekends={true}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                  }}
                  initialDate={currentDate}
                  events={eventsList}
                  eventContent={(arg) => {
                    const { event } = arg;
                    return (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div
                          className="text-start "
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            maxWidth: "100%",
                          }}
                        >
                          {event.title} - <span className="badge bg-light text-dark ms-2">{event?.extendedProps?.quantity}</span>
                        </div>
                      </div>
                    );
                  }}
                  dayCellContent={(cell) => {
                    const { date } = cell;
                    const time = customDateHtml?.find((obj) => obj?.date == moment(date).format('YYYY-MM-DD'))?.time || "00:00";

                    return (
                      <div className="fc-day-cell-content w-100 justify-space-between align-items-center" style={{ flexDirection: "initial" }}>
                        <div className="time-header mb-0">{time} hrs</div>
                        <div className="fc-day-number">{date.getDate()}</div>
                      </div>
                    );
                  }}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  eventClick={handleEventClick}
                  drop={onDrop}
                  eventDragStart={(e) => {
                    setIsDragging(true)
                    handleEventClick(e, true)
                  }}
                  eventDrop={(info) => {
                    if (isDragging && info.oldEvent.start !== info.event.start) {
                      onDragEdit(info.event, info.oldEvent.start);
                    }
                    setIsDragging(false);
                  }}
                />
              </div>
              <Modal
                isOpen={addModal}
                size="lg"
                centered
              >
                <ModalHeader toggle={() => setAddModal(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                  Add Activity
                </ModalHeader>
                <ModalBody className="p-4">
                  <Form
                    onSubmit={(e) => {
                      handleAddActivity(e)
                    }}
                  >
                    <Row>
                      <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                        <div className="text-center">
                          <img
                            src={editEventImg}
                            alt="Addepe"
                            style={{ width: "90%" }}
                          />
                        </div>
                      </div>
                      <div className="col-7 d-flex flex-column justify-content-center align-items-start pl-0">
                        <div className="d-flex gap-2 col-md-12 mb-5">
                          <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                            <h5>Est Time</h5>
                            <h5>{eventData?.est_time} Hrs </h5>
                          </a>
                          <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                            <h5>Engaged Time</h5>
                            <h5>{eventData?.occupied} Hrs</h5>
                          </a>
                          <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                            <h5>Time Taken</h5>
                            <h5>
                              {addTimes(calculateTotalTime(eventData?.est_time || "00:00", qty), eventData?.occupied || "00:00")} Hrs</h5>
                          </a>
                        </div>
                        <div className="mb-3 col-12">
                          <Label className="form-label">Activity Name</Label>
                          <Input
                            disabled
                            name="title"
                            type="text"
                            value={activityName?.replace(/\d+/g, "")}
                          />
                        </div>
                        <div className="row col-md-12 align-items-end">
                          <div className="col-4">
                            <Label className="form-label">Total Quantity</Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter Quantity"
                              value={eventData?.quantity}
                              disabled

                            />
                          </div>
                          <div className="col-4">
                            <Label className="form-label">Quantity</Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter Quantity"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row className="">
                      <Col className="col-12 text-end">
                        <button type="submit" className="btn btn-success" id="btn-save-event">
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Row>

        <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
          <DraggablePop>
            <ResizableBox
              width={800}
              style={{
                position: "fixed",
                top: "10%",
                right: "2%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header ">
                <h5 className="modal-title mt-0">Add Extra Activity</h5>
                <button
                  type="button"
                  onClick={() => {
                    setAddTask(false)
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body ">
                <Form className="row d-flex justify-content-center align-items-center" onSubmit={onHandleAddActivity}>
                  <div className="row mt-4">
                    <div className="col-md-8 ps-0 mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Project <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Project "
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={projectOptions}
                        value={projectId}
                        onChange={(e) => {
                          setProjectId(e)
                          setService([])
                          setServiceOption(e.service)
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-3 pe-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Services <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Service "
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={serviceOptions}
                        value={service}
                        onChange={(e) => setService(e)}
                      />
                    </div>
                    <div className="col-md-6 ps-0 mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Activities <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Activities "
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={activityOption?.filter((obj) => obj?.service == service?.value)}
                        value={digitalActivities}
                        onChange={(e) => {
                          setDigitalActivities(e)
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Quantity <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="Enter Quantity"
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Date <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="date"
                          className="form-control"
                          placeholder="Enter Title"
                          value={date}
                          min={moment(getNextMonthStartEnd()?.startDate).format('YYYY-MM-DD')}
                          max={moment(getNextMonthStartEnd()?.endDate).format('YYYY-MM-DD')}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-2 text-end">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setAddTask(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </Form>
              </div>
            </ResizableBox>
          </DraggablePop>
        </div>

      </div>
    </React.Fragment>
  );
};

export default PlanningCalendar;
