import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "components/Common/Breadcrumb"
import Swal from "sweetalert2"
import AllTestCase from "./AllTestCase"
import CaseOverview from "./CaseOverview"
import { useParams } from "react-router-dom"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"

const ProjectTestIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("0")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const [projectDetail, setProjectDetails] = useState({})
  const [members, setMembers] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [round, setRound] = useState([])
  const [roundDetail, setRoundDetail] = useState({})


  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })
  document.title = "Support | Zithas Technologies"

  const params = useParams()
  const toggleLoading = loading => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchProjectOverview = async () => {
      const res = await fetchData(
        toggleLoading,
        `projectOverviewData/${params?.id}`,
        token
      )
      if (res?.success) {
        setProjectDetails(res?.data?.project_data)
        setMembers(res?.data?.resources)
        const response = await fetchData(
          toggleLoading,
          `roundsData/${params?.id}`,
          token
        )
        if (response?.data) {
          setRound(response?.data)
        }
      }
    }
    fetchProjectOverview()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumb
            title={"Create Test Cases"}
            breadcrumbItem={"Test Cases"}
          />
          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "0",
                    })}
                    onClick={() => {
                      setverticalActiveTab("0")
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                {round?.map((obj, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === obj?.id,
                        })}
                        onClick={() => {
                          setverticalActiveTab(obj?.id)
                          setRoundDetail(obj)
                        }}
                      >
                       Round {obj.round}
                      </NavLink>
                    </NavItem>
                  )
                })}
                {round?.length == 0 ? (
                  <p className="mb-0 text-purple fw-bold text-center mt-3">
                    ✨ Generate Rounds to Test Your Project 🚀 <br /> Elevate
                    Your Workflow with Ease! 💡
                  </p>
                ) : null}
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-lead">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-md-11 d-flex justify-content-start align-items-center">
                        <h5 className="mb-2 text-purple fw-bold">
                          {projectDetail?.project_name}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="border-primary  d-flex gap-3">
                        <h5 className="mb-0 f-15">
                          Servity High :{" "}
                          <span className="text-primary">{Number(projectDetail?.high_fail_count) + Number(projectDetail?.high_pass_count)}</span>
                        </h5>
                        <h5 className="mb-0 f-15">
                          Medium : <span className="text-primary">{Number(projectDetail?.medium_fail_count) + Number(projectDetail?.medium_pass_count)}</span>
                        </h5>
                        <h5 className="mb-0 f-15">
                          Low : <span className="text-primary">{Number(projectDetail?.low_fail_count) +
                          Number(projectDetail?.low_pass_count)}</span>
                        </h5>
                      </div>
                      <div className="ms-4 border-left border-primary ps-2 d-flex gap-3">
                        <h5 className="mb-0 f-15">
                          Total Tasks :{" "}
                          <span className="text-primary">
                            {projectDetail?.total_tasks}
                          </span>
                        </h5>
                      </div>
                      <div className="ms-4 border-left border-primary ps-2 d-flex gap-3">
                        <h5 className="mb-0 f-15">
                          Dev Complete :{" "}
                          <span className="text-primary">{projectDetail?.dev_complete_tasks}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId={"0"}>
                    <CaseOverview projectDetail={projectDetail} members={members} />
                  </TabPane>
                  {round?.map((obj, i) => {
                    return (
                      <TabPane tabId={obj?.id} key={i}>
                        <AllTestCase verticalActiveTab={verticalActiveTab} roundDetail={roundDetail} />
                      </TabPane>
                    )
                  })}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectTestIndex
