import React, { useEffect, useState } from "react"
import {
  Table,
  Row,
  Col,
  Input,
  Modal,
  Form,
  Label,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import Select from "react-select"
import packageImg from "../../../assets/images/department.jpg"
// common 
import NewPagination from "components/Common/Newpagination"
import { settingsColumns } from "utils/columns/Digital Marketing/settingColumns"
import { DigitalServiceOptions, DigitalServiceOptionsDrop, FieldTypeOptions, TimeOption, TypeDmOptions } from "utils/common-options"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"

function TableContainer({
  columns,
  datas,
  selectedtype,
  activityOptions,
  servicesOptions,
  selectedService,
  setSelectedService,
  toggleLoading
}) {
  const [status, setStatus] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [formRows, setFormRows] = useState([{}])
  const [data, setData] = useState([])

  useEffect(() => {
    setData(datas)
  }, [datas])

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const navigate = useNavigate()

  useEffect(() => {
    setPageSize(50)
  }, [])

  // Api Integration

  const [activityName, setActivityName] = useState('');
  const [estTime, setEstTime] = useState([])
  const [activityType, setActivityType] = useState([])
  const [fieldType, setFieldType] = useState(null)
  const [values, setValues] = useState(['']);
  const [activityService, setActivityService] = useState([])
  const [checkingReport, setCheckingReport] = useState(false)
  const handleInputChange = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;  // Update value for the specific index
    setValues(newValues);
  };

  const onAddActivity = async (e, index) => {
    e.preventDefault();
    if (estTime?.length != 0 && activityType?.length != 0 && activityService?.length != 0) {
      const token = secureLocalStorage?.getItem('auth_token')
      const formEntries = {
        user_id: localStorage.getItem('auth_id'),
        activity: activityName,
        est_time: estTime?.value,
        activity_type: activityType?.value,
        field_type: fieldType?.value,
        columns: values.join(','),
        service: activityService?.value,
        checking_report: checkingReport
      }
      const response = await FetchPostData(toggleLoading, "addDigitalActivities", token, formEntries);
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Activity Added Successfully",
        }).then(() => {
          setStatus(false)
          setData(response?.data?.data)
          setActivityName('')
          setEstTime(null)
          setActivityType(null)
          setFieldType(null)
          setValues([])
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill all required fields",
      })
    }
  }



  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <button
          onClick={() => {
            setStatus(true)
          }}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2"></i>
          <span>Package</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={5}>
          <div className="d-flex gap-2 align-items-center">
            <select
              className="border-radius form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <div className="d-flex gap-3">
              {DigitalServiceOptions?.map((obj, i) => {
                return (
                  <a key={i} className={`fs-6 ${obj?.text}-color`}>
                    <i className={`${obj?.icon} ${obj?.text}-color`}></i> -{" "}
                    {obj?.text}
                  </a>
                )
              })}
            </div>
          </div>
        </Col>

        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className="border-radius btn btn-purple"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Activity
            </a>
            <a
              className="border-radius btn text-white ms-md-2 btn-red"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center"></i>Cancel
            </a>
          </div>
        </Col>
      </Row>

      <Table className="table table-bordered" {...getTableProps()}>
        <thead
          className={`${fixedContent && page.length >= 5 ? "fixed-package-header" : "fix-table-header"
            } `}
        >
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                const classNames = column.className
                return (
                  <th
                    key={index}
                    className={`col-t-${index + 1} ${classNames} ${index != 0 && "customShortIcon"}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div
                    >
                      {column.render("Header")}
                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${column.isSorted &&
                              !column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                        </span>
                      )}

                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        <tbody className="fix-support-table-body" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            let backgroundStyle = {}
            const priority = row.original.priority_col

            return (
              <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    className={`t-col-${index + 1} ${cell.column.className} ${priority == "High" ? "highPriority" : ""
                      }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {data?.length == 0 ? (
        <p className="text-center text-purple fw-bold">🔍 Hmm... couldn't find any data! Time to add something amazing</p>
      ) : null}


      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>

      <Modal
        isOpen={status}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Activity</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddActivity}>
            <Row>
              <div className="col-5">
                <img src={packageImg} className="img-fluid" />
              </div>
              <div className="col-7 d-flex align-items-center justify-content-center flex-column gap-2">
                <div className="col-12 d-flex align-items-start justify-content-start gap-3">
                  <div className="mb-3 col-9">
                    <Label htmlFor="formrow-firstname-Input">Activity Name <span className="text-danger"> *</span></Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Activity Name"
                      value={activityName}
                      onChange={(e) => setActivityName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3 col-2">
                    <Label htmlFor="formrow-firstname-Input">Services <span className="text-danger"> *</span></Label>
                    <Select
                      placeholder="Service"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      options={DigitalServiceOptionsDrop}
                      value={activityService}
                      onChange={(e) => { 
                        setActivityService(e) 
                        setActivityType([])
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex align-items-start justify-content-start">
                  <div className="mb-3 col-5">
                    <Label htmlFor="formrow-firstname-Input">Estimated Time For One Activity <span className="text-danger">*</span></Label>
                    <Select
                      placeholder="Select time"
                      className="basic-multi-select border-radius col-11"
                      classNamePrefix="select"
                      options={TimeOption}
                      value={estTime}
                      onChange={(e) => setEstTime(e)}
                    />
                  </div>

                  <div className="mb-3 col-3 me-3">
                    <Label htmlFor="formrow-firstname-Input">Activity Type <span className="text-danger">*</span></Label>
                    <Select
                      placeholder="Select Type"
                      className="basic-multi-select border-radius"
                      classNamePrefix="select"
                      options={TypeDmOptions?.filter((obj) => obj?.service == activityService?.value)}
                      value={activityType}
                      onChange={(selectedOption) => setActivityType(selectedOption)}
                    />
                  </div>

                  {
                    activityType ? (
                      activityType?.value !== "checking-report" ? (
                        <div className="mb-3 col-3">
                          <Label htmlFor="formrow-firstname-Input">Select Field Type <span className="text-danger"> *</span></Label>
                          <Select
                            placeholder="Field Type"
                            className="basic-multi-select border-radius"
                            classNamePrefix="select"
                            options={FieldTypeOptions}
                            value={fieldType}
                            onChange={(e) => setFieldType(e)}
                          />
                        </div>
                      ) : (
                        <div className="mb-3 col-3">
                          <Label htmlFor="formrow-firstname-Input">Services <span className="text-danger"> *</span></Label>
                          <Select
                            placeholder="Select Service"
                            className="basic-multi-select border-radius"
                            classNamePrefix="select"
                            options={servicesOptions}
                            value={selectedService}
                            onChange={selectedOption => setSelectedService(selectedOption)}
                          />
                        </div>
                      )
                    ) : ""
                  }
                </div>

                <div className="col-12 d-flex align-items-center justify-content-between border-bottom">
                  {fieldType != null && (
                    <div className="mt-0 text-danger">
                      <p className="text-danger">* {FieldTypeOptions?.find((obj) => obj?.value == fieldType?.value)?.message}</p>
                    </div>
                  )}
                   <div className="d-flex align-items-center me-3">
                    <h6 className="text-purple mb-0">Checking Report</h6>
                    <div className="form-switch form-switch-md ps-5">
                      <input type="checkbox" className="form-check-input" id="customSwitchsizemd" value={checkingReport} onChange={(e) => setCheckingReport(!checkingReport)} />
                    </div>
                  </div>
                </div>
                {
                  selectedtype?.value !== "checking-report" ? (

                    selectedtype?.value == "Checking" ? (
                      <div className="col-12 d-flex flex-column justify-content-start align-items-start mt-3">
                        <Label >Select Activity <span className="text-danger"> *</span></Label>
                        <Select
                          placeholder="Select Activity"
                          className="basic-multi-select border-radius col-12 mb-3"
                          classNamePrefix="select"
                          options={activityOptions}
                        />
                      </div>
                    ) : (
                      <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary setting_package_box">
                        <div className="col-12 d-flex justify-content-between align-items-center mb-3 mt-3">
                          <h5 className="mb-0 pr-10">Add Column</h5>
                          <div>
                            <button className="btn btn-purple btn-label me-2"
                              onClick={e => {
                                e.preventDefault()
                                onAddFormRow()
                              }}>
                              <i className="fas fa-plus me-2 icon-size label-icon"></i>
                              Column
                            </button>

                          </div>
                        </div>
                        {formRows?.map((person, key) => {
                          return (
                            <div key={key} className="">
                              <div className="col-12 d-flex justify-content-start align-items-center my-2">
                                <div className="col-11 contact-column edit-contact-column mb-md-0 ">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Enter Column Name"
                                    name="task_name"
                                    onChange={(e) => handleInputChange(e, key)}
                                  />
                                </div>

                                <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                                  <button
                                    onClick={e => {
                                      e.preventDefault()
                                      onDeleteFormRow(key)
                                    }}
                                    className={`btn btn-red`}
                                  >
                                    <i className={`fas fa-trash-alt me-0`}></i>
                                  </button>
                                </div>
                              </div>
                              {key !== formRows.length - 1 ? (
                                <div className=""></div>
                              ) : null}
                            </div>
                          )
                        })}
                      </div>
                    )
                  ) : ""
                }
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"
              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setStatus(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const ActivityDm = ({ activity }) => {

  const [edit_status, setEditStatus] = useState(false)
  const [selectedtype, setSelectedtype] = useState("")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [formRows, setFormRows] = useState([])
  const [selectedService, setSelectedService] = useState()

  const [activityName, setActivityName] = useState('')
  const [estTime, setEstTime] = useState(null)
  const [activityType, setActivityType] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [activityId, setActivityId] = useState('')
  const [activityService, setActivityService] = useState([])
  const [checkingReport, setCheckingReport] = useState(false)

  const onEditActivity = (id, obj) => {
    setActivityName(obj?.activity)
    setActivityId(id)
    setEstTime(TimeOption?.find((iten) => iten?.value == obj?.est_time))
    setActivityType(TypeDmOptions?.find((iten) => iten?.value == obj?.activity_type))
    setFieldType(FieldTypeOptions?.find((item) => item?.value == obj?.field_type))
    setActivityService(DigitalServiceOptionsDrop?.find((ser) => Number(ser?.value) == obj?.service))
    setCheckingReport(obj?.checking_report)
    if (obj?.cloumns != undefined) {
      setFormRows(obj?.cloumns?.split(','))
    }
    setEditStatus(true)

  }

  const onEditAction = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('auth_token');
    const formEntries = {
      user_id: localStorage.getItem('auth_id'),
      activity: activityName,
      est_time: estTime?.value,
      activity_type: activityType?.value,
      field_type: fieldType?.value,
      activity_id: activityId,
      columns: formRows.join(','),
      service: activityService?.value,
      checking_report: checkingReport
    }
    const response = await FetchPostData(toggleLoading, "editDigitalActivities", token, formEntries);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Activity Edited Successfully",
      }).then(() => {
        setEditStatus(false)
        setData(response?.data?.data)
      })
    }
  }

  const onDeleteActivity = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this Activity!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetchData(toggleLoading, `deleteDigitalActivities/${id}`, secureLocalStorage.getItem('auth_token'))
        if (response?.success) {
          Swal.fire({
            icon: "success",
            text: "Activity Deleted Successfully",
          })
          setData(response?.data)
        }
      }
    })
  }
  const columns = useMemo(
    () => settingsColumns(onDeleteActivity, onEditActivity), [])
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const handleInputChange = (e, index) => {
    const newValues = [...formRows];
    newValues[index] = e.target.value;  // Update value for the specific index
    setFormRows(newValues);
  };
  const onAddFormRow = () => {
    setFormRows([...formRows, []])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  useEffect(() => {
    setData(activity)
  }, [activity])

  const servicesOptions = [
    { label: "SEO", value: "SEO" },
    { label: "SMM", value: "SMM" },
    { label: "EM", value: "EM" },
    { label: "PPC", value: "PPC" },
  ]

  const activityOptions = [
    { label: "Design report section in reports modules and add option as per discussion", value: "Design report section in reports modules and add option as per discussion" },
    { label: "Design the dashboard tabs and also design element selection in that particular dashboard", value: "Design the dashboard tabs and also design element selection in that particular dashboard" },
    { label: "Add Help module to the client customer and add video in that section", value: "Add Help module to the client customer and add video in that section" },
    { label: "Design the dashboard tabs and also design element selection in that particular dashboard", value: "Design the dashboard tabs and also design element selection in that particular dashboard" },
    { label: "Design the dashboard tabs and also design element selection in that particular dashboard", value: "Design the dashboard tabs and also design element selection in that particular dashboard" },
  ]


  return (
    <div>
      {loading && (<Loader />)}
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              columns={columns}
              datas={data}
              selectedtype={selectedtype}
              setSelectedtype={setSelectedtype}
              activityOptions={activityOptions}
              servicesOptions={servicesOptions}
              setSelectedService={setSelectedService}
              selectedService={selectedService}
              toggleLoading={toggleLoading}
            />
          </div>
        </Col>

        {/* Edit Activity  */}
        <Modal
          isOpen={edit_status}
          toggle={() => {
            tog_center()
          }}
          centered
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Activity</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onEditAction}>
              <Row>
                <div className="col-5">
                  <img src={packageImg} className="img-fluid" />
                </div>
                <div className="col-7 d-flex align-items-center justify-content-center flex-column gap-2">
                  <div className="col-12 d-flex align-items-start justify-content-start gap-3">
                    <div className="mb-3 col-9">
                      <Label htmlFor="formrow-firstname-Input">Activity Name <span className="text-danger"> *</span></Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Activity Name"
                        value={activityName}
                        onChange={(e) => setActivityName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-2">
                      <Label htmlFor="formrow-firstname-Input">Services <span className="text-danger"> *</span></Label>
                      <Select
                        placeholder="Service"
                        className="basic-multi-select border-radius"
                        classNamePrefix="select"
                        options={DigitalServiceOptionsDrop}
                        value={activityService}
                        onChange={(e) => setActivityService(e)}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex align-items-start justify-content-start">
                    <div className="mb-3 col-5">
                      <Label htmlFor="formrow-firstname-Input">Estimated Time For One Activity <span className="text-danger">*</span></Label>
                      <Select
                        placeholder="Select time"
                        className="basic-multi-select border-radius col-11"
                        classNamePrefix="select"
                        options={TimeOption}
                        value={estTime}
                        onChange={(e) => setEstTime(e)}
                      />
                    </div>

                    <div className="mb-3 col-3 me-3">
                      <Label htmlFor="formrow-firstname-Input">Activity Type <span className="text-danger">*</span></Label>
                      <Select
                        placeholder="Select Type"
                        className="basic-multi-select border-radius"
                        classNamePrefix="select"
                        options={TypeDmOptions?.filter((obj) => obj?.service == activityService?.value)}
                        value={activityType}
                        onChange={(selectedOption) => setActivityType(selectedOption)}
                      />
                    </div>
                    {
                      activityType ? (
                        activityType?.value !== "checking-report" ? (
                          <div className="mb-3 col-3">
                            <Label htmlFor="formrow-firstname-Input">Select Field Type <span className="text-danger"> *</span></Label>
                            <Select
                              placeholder="Field Type"
                              className="basic-multi-select border-radius"
                              classNamePrefix="select"
                              options={FieldTypeOptions}
                              value={fieldType}
                              onChange={(e) => setFieldType(e)}
                            />
                          </div>
                        ) : (
                          <div className="mb-3 col-3">
                            <Label htmlFor="formrow-firstname-Input">Services <span className="text-danger"> *</span></Label>
                            <Select
                              placeholder="Select Service"
                              className="basic-multi-select border-radius"
                              classNamePrefix="select"
                              options={servicesOptions}
                              value={selectedService}
                              onChange={selectedOption => setSelectedService(selectedOption)}
                            />
                          </div>
                        )


                      ) : ""
                    }

                  </div>

                  <div className="col-12 d-flex align-items-start justify-content-between border-bottom">
                    {fieldType != null && (
                      <div className="mt-0 text-danger">
                        <p className="text-danger">* {FieldTypeOptions?.find((obj) => obj?.value == fieldType?.value)?.message}</p>
                      </div>
                    )}
                    <div className="d-flex align-items-center me-3">
                      <h6 className="text-purple mb-0">Checking Report</h6>
                      <div className="form-switch form-switch-md ps-5">
                        <input type="checkbox" className="form-check-input" id="customSwitchsizemd" 
                        checked={checkingReport == true} onChange={(e) => setCheckingReport(e.target.checked)} />
                      </div>
                    </div>
                  </div>

                  {
                    selectedtype?.value !== "checking-report" ? (

                      selectedtype?.value == "checking" ? (
                        <div className="col-12 d-flex flex-column justify-content-start align-items-start mt-3">
                          <Label >Select Activity <span className="text-danger"> *</span></Label>
                          <Select
                            placeholder="Select Activity"
                            className="basic-multi-select border-radius col-12 mb-3"
                            classNamePrefix="select"
                            options={activityOptions}
                          />
                        </div>
                      ) : (
                        <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary setting_package_box">
                          <div className="col-12 d-flex justify-content-between align-items-center mb-3 mt-3">
                            <h5 className="mb-0 pr-10">Add Column</h5>
                            <div>
                              <button className="btn btn-purple btn-label me-2"
                                onClick={e => {
                                  e.preventDefault()
                                  onAddFormRow()
                                }}>
                                <i className="fas fa-plus me-2 icon-size label-icon"></i>
                                Column
                              </button>

                            </div>
                          </div>
                          {formRows?.map((person, key) => {
                            return (
                              <div key={key} className="">
                                <div className="col-12 d-flex justify-content-start align-items-center my-2">
                                  <div className="col-11 contact-column edit-contact-column mb-md-0 ">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Enter Column Name"
                                      name="task_name"
                                      value={person}
                                      onChange={(e) => handleInputChange(e, key)}
                                    />
                                  </div>
                                  <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                                    <button
                                      onClick={e => {
                                        e.preventDefault()
                                        onDeleteFormRow(key)
                                      }}
                                      className={`btn btn-red`}
                                    >
                                      <i className={`fas fa-trash-alt me-0`}></i>
                                    </button>
                                  </div>
                                </div>
                                {key !== formRows.length - 1 ? (
                                  <div className=""></div>
                                ) : null}
                              </div>
                            )
                          })}

                        </div>

                      )

                    ) : ""
                  }
                </div>
              </Row>
              <div className="border-bottom my-3"></div>
              <div className="text-end">
                <button className=" btn btn-purple btn-label"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setEditStatus(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default ActivityDm
