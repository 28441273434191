import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, Table, Modal, Label, Form } from "reactstrap"
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
} from "react-table"
// assets
import Loader from "pages/Separate/Loader"
import NewPagination from "components/Common/Newpagination"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import { approvalTimerColumns, DashboardSwipesColumns } from "utils/columns/Dashboard/dashboardColumns"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import { customGlobalFilter } from "utils/common-helpers"

function TableContainer({ columns, data, members, toggleLoading }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            globalFilter: customGlobalFilter,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => {
        setPageSize(10)
    }, [])
    // Render the UI for your table

    const [fixedHeading, setFixedHeading] = useState(false)


    document.addEventListener("scroll", () => {
        if (window.scrollY > 40) {
            setFixedHeading(true)
        } else {
            setFixedHeading(false)
        }
    })


    return (
        <div>
            <Row>
                <Col xl="12">
                    <div className="card-body ">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="mb-3 text-purple fs-5  fw-bold">Timer Approval</div>
                        </div>
                        <Row className="mb-2 justify-content-between">
                            <div className="mb-3 mb-md-0 d-flex justify-space-between align-items-center">
                                <div className="col-1 me-md-5">
                                    <select
                                        className="border-radius form-select"
                                        style={{ width: "100px" }}
                                        value={pageSize}
                                        onChange={e => setPageSize(e.target.value)}
                                    >
                                        {[10, 25, 50, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-10 d-flex justify-content-end align-items-center">
                                    <div className="col-4 cusrtom_search_box">
                                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                                            <div className="d-flex">
                                                <div className="position-relative w-100">
                                                    <label
                                                        htmlFor="search-bar-0"
                                                        className="search-label w-100 mb-0"
                                                    >
                                                        <span id="search-bar-0-label" className="sr-only">
                                                            Search this table
                                                        </span>
                                                        <input
                                                            onChange={e => setGlobalFilter(e.target.value)}
                                                            id="search-bar-0"
                                                            type="text"
                                                            className="border-radius form-control rounded h-100 w-100"
                                                            placeholder={`Search...`}
                                                            value={globalFilter || ""}
                                                        />
                                                    </label>
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="position-relative table-responsive">
                            <Table className="table table-bordered mt-4" {...getTableProps()}>
                                <thead className={`fixed_view_payment_table`}>
                                    {headerGroups.map((headerGroup, i) => (
                                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column, index) => (
                                                <th
                                                    key={index}
                                                    className={`t-col-${index + 1} ${column.className}`}
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <span className="flex-grow-1 text-center header-text">
                                                            {column.render("Header")}
                                                        </span>
                                                        {column.isShortIcon && (
                                                            <span
                                                                onClick={() =>
                                                                    column.getSortByToggleProps().onClick()
                                                                }
                                                                className="d-flex align-items-center ms-2"
                                                            >
                                                                <i
                                                                    title="Ascending"
                                                                    className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                                <i
                                                                    title="Descending"
                                                                    className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody
                                    className="fix-introducer-header"
                                    {...getTableBodyProps()}
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        const rowBackgroundColor =
                                            row?.original?.leave_type == 2
                                                ? "bg-light-red2"
                                                : "bg-light-blue2"

                                        return (
                                            <tr
                                                className={`row-hover ${rowBackgroundColor}`}
                                                {...row.getRowProps()}
                                                key={i}
                                            >
                                                {row.cells.map((cell, index) => {
                                                    return (
                                                        <td
                                                            key={index}
                                                            className={`t-col-${index + 1} ${cell.column.className
                                                                }`}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>

                        <Row className="justify-content-md-end justify-content-center align-items-center">
                            <NewPagination
                                gotoPage={gotoPage}
                                canPreviousPage={canPreviousPage}
                                previousPage={previousPage}
                                canNextPage={canNextPage}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                pageSize={pageSize}
                                page={page}
                                data={data}
                                nextPage={nextPage}
                            />
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const ApprovalTimer = () => {
    const [modal_Access, setAccessDenied] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const token = secureLocalStorage.getItem('auth_token')

    const toggleLoading = loading => {
        setLoading(loading)
    }

    const getData = async () => {
        const user_id = localStorage.getItem("auth_id")
        const response = await fetchData(
            toggleLoading,
            `approveTaskTimerData/20`,
            token,
        )
        if (response?.success) {
            setData(response.data)
        }
    }
    useEffect(() => {
        if (data?.length == 0) {
            getData()
        }
    }, [])

    const onApprove = (type, id) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to  ${type == 1 ? "Approve" : "Disapprove"} this request?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            cancelButtonText: "No, cancel!",
        }).then(async result => {
            if (result?.isConfirmed) {
                const formEntries = {
                    timer_id: id,
                    user_id: localStorage.getItem('auth_id'),
                    type: type,
                }
                const res = await FetchPostData(toggleLoading, 'approveTaskTimerDetails', token, formEntries);
                if (res?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        title: `Timer ${type == 1 ? "Approve" : "Disapprove"} Successfully`,
                    }).then(() => {
                        setData(res?.data?.data)
                    })
                }
            }
        })
    }


    const column1 = useMemo(
        () => approvalTimerColumns(onApprove),
        []
    )

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="">
                <div>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <div className="responsive-table">
                                    <TableContainer
                                        columns={column1}
                                        data={data}
                                        toggleLoading={toggleLoading}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <AccessDeniedPopup
                modal_Access={modal_Access}
                setAccessDenied={setAccessDenied}
            />
        </React.Fragment>
    )
}

export default ApprovalTimer
