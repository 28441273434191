import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Container,
  Modal,
} from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "../../../../assets/css/Finance/Style/PayrollManagement/payroll_management.css";
import NewPagination from "components/Common/Newpagination";
import Draggable, { DraggableCore } from "react-draggable";
import { Resizable, ResizableBox } from "react-resizable";
import Select from "react-select";
import leaveImg2 from "../../../../assets/images/LeaveDetails.jpg";
import { LeaveManagementColumns } from "utils/columns/Human Resource/payrollColumns";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Loader from "pages/Separate/Loader";
import ApplyLeave from "./ApplyLeave";
import moment from "moment";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { checkPermissionsByModule } from "utils/common-helpers";

function TableContainer({
  columns,
  data,
  allData,
  boxWidth,
  toggleLoading,
  updateData,
  setData,
}) {
  const [fixedContent, setFixedContent] = useState(false);
  const [formPopup, setFormPopup] = useState(false);
  const [applyLeave, setApplyLeave] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const token = secureLocalStorage.getItem("auth_token");
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      if (setApplyLeave) {
        setApplyLeave(false);
      }
    }
  });
  const navigate = useNavigate();
  document.addEventListener("scroll", () => {
    if (window.scrollY > 300) {
      setFixedContent(true);
    } else {
      setFixedContent(false);
    }
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  // Functionality to export table data to CSV //
  const excelColumns = [];
  columns.forEach((column) => {
    excelColumns.push(column.Header);
  });
  const generalData = useSelector((state) => state?.GeneralData?.generalData);
  const [date, setDate] = useState("");
  const [staff, setStaff] = useState([]);

  const onCloseModal = (modal) => {
    setApplyLeave(modal);
  };

  const onGetDetail = async () => {
    setApplyLeave(true);
    const userId = localStorage.getItem("auth_id");
    const res = await fetchData(
      toggleLoading,
      `leavesRelatedData/${userId}`,
      token
    );
    if (res?.success) {
      setUserDetail(res?.data);
    }
  };

  const filterData = (array, employeeFilter, createdAtFilter) => {
    return array.filter((item) => {
      const matchesEmployee = employeeFilter
        ? item.employee == employeeFilter
        : true;
      const matchesCreatedAt = createdAtFilter
        ? moment(item.created_at).format("YYYY-MM-DD") == createdAtFilter
        : true;
      return matchesEmployee && matchesCreatedAt;
    });
  };

  const role = useSelector((state) => state?.Roles?.apiData);
  const permission = checkPermissionsByModule(role, 40, 5);

  return (
    <div>
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => onGetDetail()}
          className="btn btn-purple  fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>Apply</span>
        </button>
        <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </a>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {50}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <button
            onClick={() => onGetDetail()}
            className=" btn btn-save btn-label me-3"
            style={{ height: "70%" }}
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            Apply Leave
          </button>
          {permission ? (
            <div>
              <button
                onClick={() => setFormPopup(!formPopup)}
                className="btn btn-outline-secondary  me-3"
              >
                <i className="fas fa-filter"></i>
              </button>
            </div>
          ) : null}
          <div className="">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className;
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        {index === 0 && <span></span>}
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                        </span>
                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-leave-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
      {/* Filter Popup  */}
      {formPopup ? (
        <div id="salary-filter">
          <div className="leave-popup-form p-4">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row className="align-items-end">
                <Row className="d-flex pe-0 me-0 ">
                  <div className="col-md-4 mt-2">
                    <label htmlFor="">Date</label>
                    <input
                      className="form-control"
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="">Employee name</label>
                    <Select
                      placeholder="Select Employee"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={generalData?.staff}
                      value={staff}
                      onChange={(e) => setStaff(e)}
                    />
                  </div>
                  <div className="d-flex col-md-4 mb-1 align-items-end justify-content-end">
                    <div className="mr-10">
                      <button
                        className="btn  btn-purple"
                        onClick={(e) => {
                          setData(filterData(allData, staff?.value, date));
                        }}
                      >
                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                        Filter
                      </button>
                    </div>
                    <button
                      className="btn  btn-warning mr-10"
                      onClick={(e) => {
                        setDate("");
                        setStaff([]);
                        setData(allData);
                      }}
                    >
                      Clear
                    </button>
                    <div className="">
                      <a
                        className="btn  btn-red"
                        onClick={() => setFormPopup(false)}
                      >
                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                        Close
                      </a>
                    </div>
                  </div>
                </Row>
              </Row>
            </Form>
            <i className="bi bi-triangle-fill pop-icon"></i>
          </div>
        </div>
      ) : null}

      {/* // Apply Leave  */}
      <div className={`note-sticky-form ${applyLeave ? "d-block" : "d-none"}`}>
        <Draggable handle=".modal-header" cancel=".modal-body">
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "5%",
              right: "7%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Apply Leave</h5>
            </div>

            <ApplyLeave
              onCloseModal={onCloseModal}
              updateData={updateData}
              userDetail={userDetail}
            />
          </ResizableBox>
        </Draggable>
      </div>
    </div>
  );
}

const LeaveManagement = () => {
  const [isLoading, setLoading] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState(false);
  const [boxWidth, setBoxWidth] = useState(0);
  const token = secureLocalStorage.getItem("auth_token");
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [staffDetail, setStaffDetail] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [reasons, setReasons] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [leaveSlots, setLeaveSlots] = useState([]);
  const [allLeave, setAllLeave] = useState([]);
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState({});
  const [slotId, setSlotId] = useState("");
  const [addReasons, setAddReasons] = useState(false);
  const [type, setType] = useState("");

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth;
      setBoxWidth(viewportWidth * 0.85);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      if (setLeaveDetails) {
        setLeaveDetails(false);
      }
    }
  });

  const onLeaveDetail = async (id, staff_id, obj, type) => {
    const res = await fetchData(toggleLoading, `LeaveSlots/${id}`, "");
    if (res?.success) {
      setStaffDetail(obj);
      setLeaveDetails(true);
      setLeaveId(id);
      setDetails(res?.data);
      setReason(res?.data?.leaveslots[0]?.comment);
      setLeaveSlots(res?.data?.leaveslots);
      setAllLeave(res?.data?.leaveslots);
      setStaffId(staff_id);
      setType(type);
    }
  };
  const columns = useMemo(() => LeaveManagementColumns(onLeaveDetail), []);
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  const fetchLeaves = async () => {
    const userid = localStorage.getItem("auth_id");
    const roleId = secureLocalStorage.getItem("auth_role");
    const res = await fetchData(
      toggleLoading,
      `leavesData/${userid}/${roleId}`,
      token
    );
    if (res?.success) {
      setData(res?.data);
      setAllData(res?.data);
    }
  };
  useEffect(() => {
    fetchLeaves();
  }, []);
  const updateData = (update) => {
    if (update) {
      fetchLeaves();
    }
  };

  const onGetHisory = async () => {
    const res = await fetchData(toggleLoading, `LeaveHistory/${staffId}`, "");
    if (res?.success) {
      setLeaveSlots(res?.data?.leaveslots);
      setShowHistory(true);
    }
  };

  const onChangeStatus = async (id, status, disapprove) => {
    const formEnties = {
      data_id: id,
      status: status,
      type: 1,
      leave_reason: disapprove ? reasons : "",
    };
    const res = await FetchPostData(
      toggleLoading,
      `approveList`,
      token,
      formEnties
    );
    if (res?.data?.success) {
      setAddReasons(false);
      setReasons("");
      Swal.fire({
        icon: "success",
        text: `Leave ${
          status == "Cancel" ? "Cancelled" : "Approved"
        }  Successfully`,
      }).then(async (data) => {
        const response = await fetchData(
          toggleLoading,
          `LeaveSlots/${leaveId}`,
          ""
        );
        if (response?.success) {
          setLeaveSlots(response?.data?.leaveslots);
          setAllLeave(response?.data?.leaveslots);
        }
      });
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Payroll Management"}
            breadcrumbItem={"Leave Management"}
          />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={data}
                    boxWidth={boxWidth}
                    setBoxWidth={setBoxWidth}
                    toggleLoading={toggleLoading}
                    updateData={updateData}
                    allData={allData}
                    setData={setData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={addReasons} size="md" className="" centered>
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">Add Reason</h5>
            <button
              type="button"
              onClick={() => {
                setAddReasons(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body pe-4 ">
            <form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                onChangeStatus(slotId, "Unapproved", true);
              }}
            >
              <div className="col-md-12 ">
                <label>Reason</label>
                <textarea
                  className="form-control"
                  placeholder="Reason for Disapproval"
                  value={reasons}
                  onChange={(e) => setReasons(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-3 mt-3">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i> save
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={leaveDetails} size="xl" className="" centered>
          <div className="modal-header pe-4">
            <div className="d-flex gap-3 col-md-9 justify-content-between">
              <h5 className="modal-title mt-0">Leave Details</h5>
              <div className="d-flex gap-3">
                <h5 className="text-purple fw-bold mb-0">
                  <i className="bx bxs-user me-2"></i> {staffDetail?.name}
                </h5>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setLeaveDetails(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body pe-4 my-3">
            <Form className="row d-flex justify-content-center align-items-center">
              <div className="col-md-5 d-flex flex-column justify-content-end align-items-center ps-3 gap-4">
                <div className="text-center">
                  <img src={leaveImg2} alt="Addepe" style={{ width: "70%" }} />
                </div>
              </div>

              <div className="col-md-7 d-flex flex-column justify-content-start  gap-3">
                <div className="col-md-12 d-flex flex-row mb-3 mt-2 gap-3">
                  <div className="col-md-12 d-flex justify-content-start align-items-center gap-3">
                    <h5 className="mb-0">Leave Balance :</h5>
                    <div className="d-flex justify-content-between align-items-center gap-3 col-md-9">
                      <div className="d-flex gap-3 justify-content-start align-items-center">
                        <span className="mb-0 px-3 py-1  bg-primary rounded text-light">
                          <b>
                            EL : <span>{details?.earned_leave}</span>
                          </b>
                        </span>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                          <b>
                            CL : <span>{details?.casual_leave}</span>
                          </b>
                        </span>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                          <b>
                            SL : <span>{details?.sick_leave}</span>
                          </b>
                        </span>
                      </div>
                      <div>
                        <span className="mb-0 px-3 py-1 rounded  bg-primary text-light me-2">
                          <b>
                            Total : <span>{leaveSlots?.length}</span>
                          </b>
                        </span>
                        <span
                          className="mb-0 px-3 py-1 rounded  bg-success text-light cursor-pointer"
                          onClick={() => {
                            if (showHistory) {
                              setLeaveSlots(allLeave);
                              setShowHistory(false);
                            } else {
                              onGetHisory();
                            }
                          }}
                        >
                          <b>
                            <i
                              className={
                                showHistory ? "fab fa-rev" : "fa fa-history"
                              }
                            ></i>
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {showHistory == false && (
                  <div className="col-12 mb-3">
                    <label className="">Reasons</label>
                    <textarea
                      className="form-control"
                      value={reason}
                      disabled
                    />
                  </div>
                )}
                <div className="d-flex flex-column gap-1">
                  <h5 className="mb-0 ps-1 mb-2">
                    {showHistory ? "Leave History" : "Leave Slots"} :{" "}
                  </h5>
                  <div
                    className={`table-responsive col-md-12 ${
                      showHistory
                        ? "leave-history-slots"
                        : "leave-history-table"
                    }`}
                  >
                    <Table className="table mb-0">
                      <thead
                        className="bg-white"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <tr className=" ">
                          <th className="header text-center col-1">
                            Leave Date
                          </th>
                          <th className="header col-1">Type</th>
                          <th className="header col-1">Status</th>
                          {type == 1 ? (
                            <th
                              className="text-center header"
                              style={{ width: "10%" }}
                            >
                              {showHistory ? "Reason" : "Action"}
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody className="">
                        {leaveSlots?.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row" className="text-center">
                                {moment(obj?.leave_dates).format("DD-MM-YYYY")}
                              </th>
                              <td>
                                {obj?.leave_name}{" "}
                                {obj?.medical_certificate != null ? (
                                  <a
                                    className="ms-1"
                                    href={obj?.medical_certificate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-download"></i>
                                  </a>
                                ) : null}
                              </td>
                              <td>{obj?.leave_status}</td>
                              {type == 1 ? (
                                <td
                                  className="word-wrap text-center"
                                  style={{ width: "10%" }}
                                >
                                  {showHistory ? (
                                    obj?.comment
                                  ) : (
                                    <>
                                      {obj?.status != 1 ? (
                                        <>
                                          <a
                                            className="btn btn-sm btn-outline-primary me-1 lh-1 p-1"
                                            title="Approve"
                                            onClick={() =>
                                              onChangeStatus(
                                                obj?.slots_id,
                                                "Approved"
                                              )
                                            }
                                          >
                                            <i className="fas fa-thumbs-up "></i>
                                          </a>
                                          <a
                                            className="btn btn-sm btn-outline-danger me-1 lh-1 p-1"
                                            title="Disapprove"
                                            onClick={() => {
                                              setSlotId(obj?.slots_id);
                                              setAddReasons(true);
                                            }}
                                          >
                                            <i className="fas fa-thumbs-down "></i>
                                          </a>
                                          <a
                                            className="btn btn-sm btn-outline-secondary me-1 lh-1 p-1"
                                            title="Cancel"
                                            onClick={() =>
                                              onChangeStatus(
                                                obj?.slots_id,
                                                "Cancel"
                                              )
                                            }
                                          >
                                            <i className="fas fa-times"></i>
                                          </a>
                                        </>
                                      ) : (
                                        <>{obj?.leave_reason || "-"}</>
                                      )}
                                    </>
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LeaveManagement;
