import React, { useEffect, useRef, useState } from "react"
import { Table, Row, Col, Input, Modal, Form, Label } from "reactstrap"
import { Link, useFetcher, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Accordion, Card, Button } from "reactstrap"
import Swal from "sweetalert2"
import sampleImg from "../../../assets/images/credential/img-5.avif"
import configure from "configure"
import "./ViewTopic.css"
import "../../../assets/css/KnowledgeBase/Index.css"

// common
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import EditTopic from "../EditTopic/EditTopic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import AddTopic from "../AddTopic/AddTopic"
import AddTopics from "../AddTopic/Addtopics"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import MyEditorIndex from "../AddTopic/MyEditorIndex"
import TinyEditor from "../TinyEditor"
import NewPagination from "components/Common/Newpagination"
import { fetchData } from "utils/common-fetchData"

function TableContainer({
  setTopicName,
  columns,
  data,
  setData,
  isDropdown,
  setTopicData,
  navigate,
  row,
  setRow,
  editIdsData,
  setEditIdsData,
  setLoading,
  viewData,
  setViewData,
  cateId,
  subCateId,
  verticalActiveTab,
  content,
  setAccessDenied,
}) {
  const [status, setStatus] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [fixedContent, setFixedContent] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)
  const [description, setDescription] = useState("")
  const [error, setError] = useState(false)
  const [topicTitle, setTopicTitle] = useState("")
  const [editor, setEditor] = useState(description)

  useEffect(() => {
    setTopicTitle(editIdsData?.topic_name)
    setDescription(editIdsData?.description)
  }, [row])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  // Render the UI for your table

  const handleSubmitEdit = async e => {
    e.preventDefault()
    if (editor.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      const authId = localStorage.getItem("auth_id")
      formData.append("user_id", authId)
      formData.append("category_id", editIdsData?.cate_id)
      formData.append(
        "subcategory_id",
        editIdsData?.sub_id ? editIdsData?.sub_id : ""
      )
      formData.append("topic_id", editIdsData?.topic_id)
      formData.append("topic_name", topicTitle)
      formData.append("description", editor)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/editKnowledgeTopics`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          setTopicData(response.data.data.topics)
          Swal.fire({
            icon: "success",
            text: "Topic Edited Successfully.",
          }).then(() => {
            setRow(1)
            setTopicTitle("")
            setDescription("")
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      setError(true)
    }
  }
  const handleInputChange = e => {
    setSearchTerm(e.target.value)
  }

  const highlightText = (htmlContent, highlight) => {
    if (!highlight.trim()) {
      return htmlContent
    }

    // Escape special characters in the highlight string for use in the regular expression
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    const regex = new RegExp(`(${escapedHighlight})`, "gi")

    // Create a DOM parser
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, "text/html")

    // Function to highlight text nodes
    const highlightNode = node => {
      if (node.nodeType === Node.TEXT_NODE) {
        const matches = node.textContent.match(regex)
        if (matches) {
          const span = document.createElement("span")
          span.innerHTML = node.textContent.replace(
            regex,
            '<span class="highlight">$1</span>'
          )
          node.replaceWith(...span.childNodes)
        }
      } else {
        node.childNodes.forEach(child => highlightNode(child))
      }
    }

    doc.body.childNodes.forEach(child => highlightNode(child))
    return doc.body.innerHTML
  }

  const highlightedContent = highlightText(content, searchTerm)

  const containerRef = useRef(null)

  useEffect(() => {
    // Scroll to the first highlighted element
    if (containerRef.current) {
      const firstHighlight = containerRef.current.querySelector(".highlight")
      if (firstHighlight) {
        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [highlightedContent])

  const onAddTopic = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 95)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          if (cateId == "" && subCateId == "") {
            Swal.fire({
              icon: "warning",
              text: "There are no categories available, so you cannot add a topic.",
            })
          } else {
            setRow(4)
          }
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      // Find the element with id "blogContent"
      const blogContent = document.getElementById("blogContent")

      if (blogContent) {
        // Find all <table> elements inside blogContent
        const tables = blogContent.querySelectorAll("table")
        // Loop through each <table> element and add the class
        tables.forEach(table => {
          table.classList.add("table-border-stripped")
        })
      }
    })
  }, [description, row])

  return (
    <React.Fragment>
      {row == 1 ? (
        <div className="px-2">
          <div>
            <div
              className={`flex-column fixed-buttons pt-2 ${
                fixedContent ? "d-flex" : "d-none"
              }`}
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onAddTopic()
                }}
                className="btn btn-save btn-label pe-0 fix-btn"
              >
                <i className="fas fa-plus me-2 label-icon"></i>
                <span>Add</span>
              </button>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-red btn-label pe-0 fix-btn"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                <span>Cancel</span>
              </a>
            </div>

            <Row className="mb-2">
              <Col lg={2}>
                <select
                  className=" form-select"
                  style={{ width: "100px" }}
                  value={pageSize}
                  onChange={e => setPageSize(e.target.value)}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="me-4">
                  <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                          Search this table
                        </span>
                        <input
                          onChange={e => setGlobalFilter(e.target.value)}
                          id="search-bar-0"
                          type="text"
                          className=" form-control rounded h-100"
                          placeholder={` Search...`}
                          value={globalFilter || ""}
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    className=" btn btn-save btn-label"
                    onClick={e => {
                      e.preventDefault()
                      // handleAddTopic(true)
                      onAddTopic()
                    }}
                    to="#"
                    // to={`${configure.appUrl}addtopic`}
                  >
                    <i className="fas fa-plus me-2 icon-size label-icon"></i>
                    Add Topic
                  </Link>
                  <a
                    className=" btn text-white ms-md-2 btn-red btn-label"
                    onClick={() => navigate(-1)}
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    Cancel
                  </a>
                </div>
                {/* </div> */}
              </Col>
            </Row>
            <Table
              className="table table-bordered mt-4 Knowledge-header"
              {...getTableProps()}
            >
              <thead
                className={`${fixedContent ? "fixed-setting-header" : ""}`}
              >
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={`col-md-${
                          index === 0 ? "1" : index === 1 ? "9" : "2"
                        }`}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr className="row-hover" {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            className={`col-md-${
                              index === 0 ? "1" : index === 1 ? "9" : "2"
                            }`}
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPag={canPreviousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
              />
            </Row>
          </div>

          {/* Add Category Modal */}
          {/* <Modal isOpen={status} size="sm" className="bd-custom-md-2" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Category</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row">
            <div className="mb-3 col-md-3">
              <Label htmlFor="formrow-firstname-Input">Number</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter No. "
              />
            </div>
            <div className="mb-3 col-md-9">
              <Label htmlFor="formrow-firstname-Input"> Category Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Category Name"
              />
            </div>
            <div className="mt-20">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal> */}
        </div>
      ) : // edit row
      row == 2 ? (
        <div className="">
          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="12 pe-0">
              {/* {isLoading && <Loader />} */}
              <form onSubmit={handleSubmitEdit}>
                <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
                  <div
                    className={`flex-column fixed-buttons pt-2 ${
                      fixButtons ? "d-flex" : "d-none"
                    }`}
                  >
                    <button className="btn btn-purple fix-btn btn-label pe-0">
                      <i className="fas fa-save me-2 label-icon"></i>
                      <span>Save</span>
                    </button>
                    <a
                      onClick={() => {
                        setRow(1)
                        setTopicName(null)
                        setEditIdsData(null)
                      }}
                      className="btn btn-red fix-btn btn-label pe-0"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      <span>Cancel</span>
                    </a>
                  </div>
                  <Row className="bg-white shadow mb-3">
                    <div className="col-md-12 mb-3 text-end">
                      <button
                        onClick={e => editLead(e)}
                        className=" btn ms-md-2 btn-save btn-label"
                      >
                        <i className="fas fa-save me-1 label-icon"></i> save
                      </button>
                      <a
                        onClick={e => {
                          e.preventDefault()
                          setTopicName(null)
                          setRow(1)
                        }}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                    <div className="row d-flex flex-column gap-4 p-0">
                      <div className="col-md-12">
                        <div className=" d-flex flex-column ">
                          <label htmlFor="">
                            Edit Topic <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="tex"
                            name="edittopic"
                            placeholder="Enter Topic Name"
                            className="form-control"
                            required
                            value={topicTitle}
                            onChange={e => setTopicTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 edit-descrip">
                        <div className="d-flex justify-space-between">
                          <label htmlFor="">
                            Description <span className="text-danger">*</span>
                          </label>
                          <span
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              paddingTop: "7px",
                            }}
                          >
                            {error ? "Please enter description" : null}
                          </span>
                        </div>
                        <TinyEditor
                          description={description}
                          setError={setError}
                          setDescription={setDescription}
                          placeholder={"Start typings..."}
                          editor={editor}
                          setEditor={setEditor}
                          setLoading={setLoading}
                          row={row}
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      ) : row == 3 ? (
        <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => {
                setTopicName(null)
                setRow(1)
              }}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row className="bg-white px-2 py-4 shadow gap-5 " id="viewBox">
            <div className="topNav d-flex flex-row">
              <div className="search-box col-md-10">
                <label htmlFor="search-bar-0" className="search-label w-100">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100 w-100 topic-search-input"
                    placeholder={` Search here...`}
                    width={100}
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </label>
                <i className="bx bx-search-alt topic-search-icon fa-lg text-muted"></i>
              </div>
              <div className="col-md-2 text-end">
                <a
                  onClick={e => {
                    setTopicName(null)
                    e.preventDefault()
                    setRow(1)
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
            <div className="">
              <div
                id="blogContent"
                className="knowledgebase_view"
                ref={containerRef}
                dangerouslySetInnerHTML={{ __html: highlightedContent }}
              />
              <div />
            </div>
          </Row>
        </div>
      ) : row == 4 ? (
        <div>
          <AddTopics
            cateId={cateId}
            subCateId={subCateId}
            setRow={setRow}
            setData={setData}
            isDropdown={isDropdown}
            verticalActiveTab={verticalActiveTab}
            setTopicData={setTopicData}
            row={row}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

const KnowledgeBaseReact = ({
  verticalActiveTab,
  topicData,
  cateId,
  subCateId,
  setTopicData,
  isDropdown,
  row,
  setRow,
  navItemData,
  setTopicName,
}) => {
  const [modal_Access, setAccessDenied] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editIdsData, setEditIdsData] = useState(null)
  const [viewData, setViewData] = useState("")
  const [content, setContent] = useState()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    let filterData
    if (isDropdown) {
      filterData = topicData?.filter(item => {
        return item.subcategory == verticalActiveTab
      })
    } else {
      filterData = topicData?.filter(item => {
        return item.category == verticalActiveTab && item.subcategory == null
      })
    }
    setData(filterData)
  }, [verticalActiveTab, topicData, isDropdown, row])

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: "center" }}>ID</div>,
        accessor: "id",
        Cell: cellProps => {
          return (
            <div style={{ textAlign: "center" }}>
              {cellProps.row.original.topic_id}
            </div>
          )
        },
      },
      {
        Header: "Topic Name",
        accessor: "topic_name",
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Actions</div>,
        accessor: "action",
        Cell: cellProps => {
          const deleteTopic = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 95)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    icon: "warning",
                    text: "Are You Sure ?",
                    showCancelButton: true,
                    confirmButtonText: "Yes delete it!",
                  }).then(async willOkay => {
                    if (willOkay.isConfirmed) {
                      setLoading(true)
                      const roleId = localStorage.getItem("auth_role")
                      const authId = localStorage.getItem("auth_id")
                      const formData = new FormData()
                      formData.append("role_id", roleId)
                      formData.append("user_id", authId)
                      formData.append("topic_id", cellProps.row.original.topic_id)

                      try {
                        const response = await axios({
                          method: "post",
                          data: formData,
                          url: `${configure.apiUrl}/deleteKnowledgeTopics`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                        if (response) {
                          setLoading(false)
                          Swal.fire({
                            icon: "success",
                            text: "Topic deleted successfully",
                          }).then(() => {
                            setTopicData(response?.data?.data?.topics)
                          })
                        }
                      } catch (error) {
                        setLoading(false)
                        console.log(error)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          const editTopic = id => {
            setRow(2)
            const data2 = {
              sub_id: cellProps.row.original.subcategory,
              cate_id: cellProps.row.original.category,
              topic_id: cellProps.row.original.topic_id,
              topic_name: cellProps.row.original.topic_name,
              description: cellProps.row.original.description,
            }
            setTopicName(cellProps.row.original.topic_name)
            setEditIdsData(data2)
          }
          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 95)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  editTopic(id)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          const toggleLoading = loading => {
            setLoading(loading)
          }
          const onViewData = async id => {
            const response = await fetchData(
              toggleLoading,
              `topicData/${id}`,
              ""
            )
            if (response?.length != 0) {
              const data = response[0]
              setContent(data?.description)
              setViewData(data?.description)
              setTopicName(data?.topic_name)
              setRow(3)
            }
          }
          return (
            <div className="text-center">
              <Link
                to="#"
                onClick={e => {
                  onEditPermission({ id: cellProps.row.original })
                  e.preventDefault()
                  // navigate(`${configure.appUrl}edittopic`, {
                  //   state: {
                  //     editIds: {
                  //       sub_id: cellProps.row.original.subcategory,
                  //       cate_id: cellProps.row.original.category,
                  //       topic_id: cellProps.row.original.topic_id,
                  //     },
                  //   },
                  // })
                }}
                // to={`${configure.appUrl}edittopic`}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <Link
                onClick={() => {
                  onViewData(cellProps.row.original?.topic_id)
                }}
                // to={`${configure.appUrl}viewtopic`}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-eye"></i>
              </Link>
              <Link
                onClick={() => deleteTopic()}
                className="btn btn-sm action-btn btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div>
      {loading && <Loader />}
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              setTopicName={setTopicName}
              columns={columns}
              data={data}
              setData={setData}
              isDropdown={isDropdown}
              setTopicData={setTopicData}
              navigate={navigate}
              row={row}
              setRow={setRow}
              editIdsData={editIdsData}
              setEditIdsData={setEditIdsData}
              setLoading={setLoading}
              viewData={viewData}
              setViewData={setViewData}
              cateId={cateId}
              subCateId={subCateId}
              verticalActiveTab={verticalActiveTab}
              content={content}
              setAccessDenied={setAccessDenied}
            />
          </div>
        </Col>
      </Row>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </div>
  )
}

export default KnowledgeBaseReact
