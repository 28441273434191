import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import NewPagination from "components/Common/Newpagination";
import { Draggable } from "devextreme-react";
import React, { useEffect, useMemo, useState } from "react";
import { ResizableBox } from "react-resizable";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import viewTaskImg from "../../../assets/images/Project/viewTask.jpg";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap";
import {
  suppprtColumns,
  TaskTabelColumns,
} from "utils/columns/Dashboard/dashboardColumns";
import sideImg from "../../../assets/images/profile.png";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { allocationType, resaonOtption } from "utils/common-options";
import secureLocalStorage from "react-secure-storage";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import Loader from "pages/Separate/Loader";
import { useTimer } from "pages/WorkModule/Projects/Project Timer/TimerContext";
import moment from "moment";
import Swal from "sweetalert2";
import { calculateTimeDifference } from "utils/common-helpers";

function TableContainer({ columns, data, setAccessDenied, setEditTask }) {
  const [fixedContent, setFixedContent] = useState(false);
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true);
    } else {
      setFixedContent(false);
    }
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;
  useEffect(() => setPageSize(50), []);

  const [name_modal, setNameModal] = useState(false);
  const [title, setTitle] = useState("");

  return (
    <div>
      <Row>
        <div className="col-md-12 my-2 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-headers" : ""
            } fix-project-headers`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true);
                          setTitle(column.Header);
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-headers" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              let rowColor;
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green";
                  break;
                case "Awaiting Feedback":
                  rowColor = "bg-red";
                  break;
                case "Complete":
                  rowColor = "bg-yellow";
                  break;
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  );
}

const TaskTable = ({ verticalActiveTab }) => {
  const [taskDetails, setTaskDetails] = useState({});
  const token = secureLocalStorage.getItem("auth_token");
  const [viewTaskHistory, setViewTaskHistory] = useState(false);
  const toogleLoading = (loading) => {
    setLoading(loading);
  };
  const onInfo = (obj) => {
    setTaskDetails(obj);
    setTaskInfo(true);
  };
  const onReply = () => {
    setTicketReply(true);
  };
  const onAddHours = async (id) => {
    const res = await fetchData(toogleLoading, `taskTimerDetails/${id}`, token);
    if (res?.success) {
      setViewTaskHistory(true);
      setTimingLog(res?.data);
      setTaskId(id);
    }
  };
  const { handleStartStop } = useTimer();
  const onStartTimer = async (obj, isRunnings) => {
    const date = new Date();
    const formEntries = {
      staff_id: localStorage.getItem("auth_id"),
      task_id: obj?.id,
    };
    const requestData = {
      ...formEntries,
      start_time: !isRunnings
        ? moment(date).format("YYYY-MM-DD HH:mm:ss")
        : null,
      end_time: isRunnings ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null,
      type: !isRunnings ? 1 : 2,
      timer_id: isRunnings ? localStorage.getItem("timerId") : null,
    };

    const res = await FetchPostData(
      toogleLoading,
      "startStopTaskTimer",
      token,
      requestData
    );
    if (res?.data?.success) {
      if (!isRunnings) {
        localStorage.setItem("task_id", obj.id);
        localStorage.setItem("timerId", res?.data?.data?.id);
        localStorage.setItem("start_time", date.toISOString());
      } else {
        localStorage.removeItem("task_id");
        localStorage.removeItem("timerId");
        localStorage.removeItem("start_time");
      }
      handleStartStop(obj.id);
    } else {
      Swal.fire({
        title: "⏱️ Timer already rockin'!",
        text: "Oops! Another timer is on the loose. Should we chase it down and turn off the existing one instead? 🚀😎",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Stop Timers! 🔥",
        cancelButtonText: "Nah, let it roll! 😜",
      }).then(async (confirm) => {
        if (confirm.isConfirmed) {
          const requestData = {
            ...formEntries,
            end_time: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            type: 2,
            timer_id: res?.data?.message,
          };
          const response = await FetchPostData(
            toogleLoading,
            "startStopTaskTimer",
            token,
            requestData
          );
          if (response?.data?.success) {
            localStorage.removeItem("task_id");
            localStorage.removeItem("timerId");
            localStorage.removeItem("start_time");
            Swal.fire({
              icon: "success",
              title: "Timer Turned Off Successfully!",
            });
          }
        }
      });
    }
  };
  const onChangeStatus = async (id, status, project_id) => {
    const updateStatus = async () => {
      const formEntries = {
        user_id: localStorage.getItem("auth_id"),
        task_id: id,
        project_id: project_id,
        status: status,
        type:verticalActiveTab
      };
      const response = await FetchPostData(
        toogleLoading,
        "changeTaskStatus",
        "",
        formEntries
      );
      if (response.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Task Status Updated Successfully",
        });
      }
    };

    if (status == 2) {
      const swalInstance = Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Surely, you've done your unit tests, right? 🤔🛠️",
        showCancelButton: true,
        confirmButtonText: "Yes, I'm sure!",
        cancelButtonText: "No, Cancel",
        allowOutsideClick: false,
        confirmButtonDisabled: true,
        cancelButtonDisabled: true,
      });

      // Wait for 5 seconds and enable the buttons
      setTimeout(() => {
        swalInstance.update({
          confirmButtonDisabled: false, // Enable confirm button
          cancelButtonDisabled: false, // Enable cancel button
        });
      }, 5000); // 5000 ms = 5 seconds

      // If confirmed, update the status
      swalInstance.then((result) => {
        if (result.isConfirmed) {
          updateStatus();
        }
      });
    } else {
      // Directly update the status if it's not 2
      updateStatus();
    }
  };

  const columns = useMemo(
    () => TaskTabelColumns(onInfo, onAddHours, onChangeStatus, onStartTimer),
    []
  );
  const support = useMemo(() => suppprtColumns(onReply), []);
  const [taskInfo, setTaskInfo] = useState(false);
  const [ticketReply, setTicketReply] = useState(false);
  const [selectedFiles1, setselectedFiles1] = useState([]);
  const [addHours, setAddHours] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [startTimer, setStartTimer] = useState("");
  const [endTimer, setEndTimer] = useState("");
  const [reason, setReason] = useState([]);
  const [timerId, setTimerId] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [data, setData] = useState([]);
  const replyMessage = [{}, {}, {}, {}];
  const [timingLog, setTimingLog] = useState([]);
  const [type, setType] = useState(false);

  useEffect(() => {
    const onGetTodayTask = async () => {
      const userId = localStorage.getItem("auth_id");

      const res = await fetchData(
        toogleLoading,
        `todayYesterdayTasks/${userId}/${verticalActiveTab}`,
        token
      );
      if (res?.success) {
        setData(res?.data);
      }
    };
    onGetTodayTask();
  }, [verticalActiveTab]);

  const onChangeTimer = async (e) => {
    e.preventDefault();
    if (reason?.length != 0) {
      const formEntries = {
        start_time: moment(startTimer).format("YYYY-MM-DD hh:mm:ss"),
        end_time: moment(endTimer).format("YYYY-MM-DD hh:mm:ss"),
        reason: reason?.value,
        timer_id: timerId,
        type: type ? 1 : 0,
        reason_type: reason?.value == "6" ? 1 : 0,
        task_id: taskId,
        user_id: localStorage.getItem("auth_id"),
      };
      const res = await FetchPostData(
        toogleLoading,
        "updateTaskTimerDetails",
        token,
        formEntries
      );
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Task Timer Updated Successfully",
        }).then(() => {
          setStartTimer("");
          setEndTimer("");
          setReason([]);
          setAddHours(false);
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Select Reason",
      });
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="">
        <Row>
          <Col lg={12} className="p-0">
            <Card>
              <CardBody className="pt-0">
                <div className="responsive-table">
                  <TableContainer
                    columns={verticalActiveTab == 4 ? support : columns}
                    data={data}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div
          className={`note-sticky-form position-relative ${
            taskInfo ? "d-block" : "d-none"
          }`}
        >
          <Draggable bounds="parent">
            <ResizableBox
              width={900}
              style={{
                position: "absolute",
                top: "-200px",
                right: "2%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="justify-content-between  d-flex mb-3">
                <h5 className="modal-title mt-0">Task Info</h5>
                <button
                  type="button"
                  onClick={() => {
                    setTaskInfo(false);
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>

              <div className="">
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={taskDetails?.title}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Milestone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={`Milestone - ${taskDetails?.milestone_no} - ${
                          allocationType?.find(
                            (obj) => obj?.value == taskDetails?.milestone_type
                          )?.label
                        }`}
                        disabled
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={`${taskDetails?.estimate_dev_days} hrs`}
                        disabled
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                        value={taskDetails?.probability}
                        disabled
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                        value={taskDetails?.priority}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-md-12 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Project Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                        value={taskDetails?.project_name}
                        disabled
                      />
                    </div>
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={taskDetails?.description}
                        onReady={(editor) => {}}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ResizableBox>
          </Draggable>
        </div>
        <div
          className={`note-sticky-form position-relative ${
            addHours ? "d-block" : "d-none"
          }`}
        >
          <Draggable bounds="parent">
            <ResizableBox
              width={550}
              style={{
                position: "absolute",
                top: "-200px",
                right: "2%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="justify-content-between  d-flex mb-3">
                <h5 className="modal-title mt-0">Change Time</h5>
                <button
                  type="button"
                  onClick={() => {
                    setAddHours(false);
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>

              <div className="mt-4">
                <p className="fw-bold text-purple mb-0">
                  Total Time : {calculateTimeDifference(startTimer, endTimer)}
                </p>
                <form onSubmit={onChangeTimer}>
                  <div className="col-md-12 row align-items-center new-task-modal ">
                    <div className="col-md-6 mt-4 mb-3">
                      <label>Start Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        placeholder="Enter Time"
                        value={startTimer}
                        onChange={(e) => setStartTimer(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mt-4 mb-3">
                      <label>End Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        placeholder="Enter Time"
                        value={endTimer}
                        onChange={(e) => setEndTimer(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12 mb-5">
                      <label>Reasons</label>
                      <Select
                        placeholder="Select Time"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={resaonOtption?.filter(
                          (obj) => obj?.value != 6
                        )}
                        value={reason}
                        onChange={(e) => setReason(e)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-end ">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i> save
                    </button>
                  </div>
                </form>
              </div>
            </ResizableBox>
          </Draggable>
        </div>
        {/* Task History  */}
        <div
          className={`note-sticky-form position-relative ${
            viewTaskHistory ? "d-block" : "d-none"
          }`}
        >
          <Draggable bounds="parent">
            <ResizableBox
              width={1100}
              height={600}
              style={{
                position: "fixed",
                top: "-200px",
                right: "2%",
                zIndex: "1055",
                backgroundColor: "#fff",
                padding: 0,
              }}
            >
              <div className="modal-header pe-4">
                <h5 className="modal-title mt-0">View Task History</h5>
                <div className="d-flex gap-2 align-items-center">
                  <a
                    className="btn btn-sm btn-primary me-1 d-flex align-items-center"
                    onClick={() => {
                      setType(true);
                      setAddHours(true);
                      setViewTaskHistory(false);
                    }}
                  >
                    <i className="bx bx-plus fs-6"></i>
                    Add
                  </a>
                  <button
                    type="button"
                    onClick={() => {
                      setViewTaskHistory(false);
                    }}
                    className="btn-modal-close"
                  >
                    <span aria-hidden="true" className="btn-modal-span">
                      &times;
                    </span>
                  </button>
                </div>
              </div>

              <div className="modal-body pe-4 my-2">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
                    <div className="text-center">
                      <img
                        src={viewTaskImg}
                        alt="Addepe"
                        style={{ width: "80%" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 viewPackage-box">
                    <Table bordered hover responsive size="" striped>
                      <thead>
                        <tr>
                          <th className="text-center task-col-1">ID</th>
                          <th className="task-col-2 text-center">Start Time</th>
                          <th className="task-col-3 text-center">End Time</th>
                          <th className="task-col-3 text-center">Cal. Time</th>
                          <th className="task-col-2 text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timingLog?.map((obj, i) => {
                          const currentTime = new Date();
                          return (
                            <tr key={i}>
                              <td
                                scope="row"
                                className="text-center task-col-1"
                              >
                                {i + 1}
                              </td>
                              <td className="task-col-2 text-center">
                                {moment(obj?.start_time)?.format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )}{" "}
                              </td>
                              <td className="task-col-2 text-center">
                                {obj?.end_time != null
                                  ? moment(obj?.end_time)?.format(
                                      "DD-MM-YYYY hh:mm:ss a"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {calculateTimeDifference(
                                  obj?.start_time,
                                  obj?.end_time != null
                                    ? obj?.end_time
                                    : currentTime
                                )}
                              </td>
                              <td className="task-col-4 text-center">
                                <a
                                  className="btn btn-sm btn-outline-primary me-1"
                                  onClick={() => {
                                    setAddHours(true);
                                    setViewTaskHistory(false);
                                    setTimerId(obj?.id);
                                    setStartTimer(obj?.start_time);
                                    setEndTimer(obj?.end_time);
                                  }}
                                >
                                  <i className="bx bx-pen fs-6"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </ResizableBox>
          </Draggable>
        </div>
        <Modal
          isOpen={ticketReply}
          centered
          size="xl"
          className="custom_AllActivity_popup"
        >
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">Reply Ticket </h5>
            <button
              type="button"
              onClick={() => {
                setTicketReply(false);
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="px-3 py-4 bg-white">
            <div className="row">
              <div className="col-md-6">
                <div className="mt-4 ticket-history-box">
                  <ul className="verti-timeline list-unstyled notes-box">
                    {replyMessage?.map((obj, i) => {
                      return (
                        <li className="event-list position-relative" key={i}>
                          <div className="event-timeline-dot connected-dot">
                            <img
                              src={sideImg}
                              className="msgUserImg me-3"
                              alt="User Avatar"
                            />
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                              <div className="d-flex justify-content-start align-items-center">
                                <div>
                                  <h6 className="d-inline me-2">
                                    Ticket Created By Janvi and assigned to
                                    Mohit
                                  </h6>{" "}
                                  at 01-01-2023 12:00:00
                                </div>
                              </div>
                              <i className="bi bi-triangle-fill note-icon"></i>
                              <div className="Features col-12 my-3">
                                Create View Projects Task Section popups, Days
                                Remaining popup, Delete popup, View Task popup,
                                and changes in Edit and add Project design.
                              </div>
                              <p className="mb-1">
                                Attachment:
                                <a
                                  className="text-light badge bg-primary ms-2"
                                  title="Click To Open"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Click here
                                </a>
                                <a
                                  className="text-light badge bg-primary ms-2"
                                  title="Click To Open"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Click here
                                </a>
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                  <div className="col-12 ps-2">
                    <label htmlFor="">Description</label>
                    <div className="no-drag">
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 d-flex justify-content-start align-items-center gap-3">
                      <div className="col-md-8 col-sm-8 mt-3">
                        <label htmlFor="">Attachment</label>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles1(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="dz-message needsclick p-0"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="d-flex px-2 custom-file-upload align-items-center">
                                <div className="">
                                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h5 className="mb-0 ms-2">Upload Files</h5>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {/* file display here */}
                      <div
                        className={`mt-3 dropzone-previews ${
                          selectedFiles1.length > 0 ? "d-block" : "d-none"
                        }`}
                        id="file-previews"
                      >
                        <label className="mb-1">Uploaded Files</label>
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-0">
                            <Row className="align-items-center justify-content-evenly">
                              {selectedFiles1?.map((f, i) => {
                                return (
                                  <Col key={i} className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex gap-3 justify-content-end align-items-end">
                      <div className="my-2 text-end">
                        <button className=" btn btn-save btn-label">
                          <i className="fas fa-save me-2 label-icon"></i>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TaskTable;
