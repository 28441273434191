import React, { useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { developmentTaskStatus, DigitalServiceOptions, DigitalServiceOptionsDrop, RelatedToOption, resaonOtption } from "utils/common-options";
import moment from "moment";
import { Link } from "react-router-dom";
import configure from "configure";
import Swal from "sweetalert2";
import sideImg from "../../../assets/images/profile.png"
import ProposalPdf from "pages/Sales/Proposal/ProposalPdf";
import indiaFlag from "../../../assets/images/flags/india.png"
import ukFlag from "../../../assets/images/flags/uk.png"
import { calculateTimeDifference, trimProjectName } from "utils/common-helpers";
import { useTimer } from "pages/WorkModule/Projects/Project Timer/TimerContext";
import bug from "../../../assets/images/bug.png"

export const generalDashboardColumns = (handleEditTask, onDelete, handleChangeStatus, onManagePlan, handleAddHour, followChangeStatus, onAddTask) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id",
        Cell: cellProps => {
            const [status, setStatus] = useState(cellProps?.row?.original?.plan_day);
            useEffect(() => {
                setStatus(cellProps?.row?.original?.plan_day)
            }, [cellProps?.row?.original])
            return (
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        <a
                            className="color-grey me-1"
                            title="Plan My Day"
                            onClick={() => {
                                onManagePlan(cellProps.row.original?.id, cellProps?.row?.original?.plan_day == 0 ? 1 : 0, cellProps?.row?.original?.type)
                                setStatus(status == 0 ? 1 : 0)
                            }}
                        >
                            <i className={`${status == 1 ? "bi bi-brightness-high-fill color-orange" : "bi bi-brightness-high "} fs-4`}></i>
                        </a>
                    </span>
                    <span>{cellProps.row.original.id}</span>
                </div>
            )
        },
    },
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg-com-status text-center",
        Cell: cellProps => {
            const [isChecked, setIsChecked] = useState(cellProps.row.original.checked || false);
            const handleCheckboxChange = async (e, id) => {
                const checked = e.target.checked;
                setIsChecked(checked);
                const userConfirmed = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to change the status?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, change it!",
                    cancelButtonText: "No, cancel",
                });

                if (userConfirmed.isConfirmed) {
                    handleChangeStatus(e, cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id);
                    setIsChecked(false);
                } else {
                    setIsChecked(false);
                }
            };


            return (
                <div className="d-flex align-items-center gap-3 justify-content-center">
                    <span>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</span>
                    <img
                        src={`${cellProps?.row?.original?.img ? `${configure?.imgUrl}${cellProps?.row?.original?.img}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <div className="text-end" id="customCheckbox" >
                        {
                            cellProps.row.original.type === 1 ? (
                                <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox mt-2im general-status"
                                    id={`customCheck-${cellProps.row.id}`}
                                    value="complete"
                                    disabled={
                                        cellProps.row.original.added_by != localStorage.getItem("auth_id")
                                    }
                                    checked={isChecked}
                                    onChange={(e) => handleCheckboxChange(e, cellProps.row.original.id)}
                                />
                            ) : (
                                <div className="text-center">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                            followChangeStatus({
                                                user_id: localStorage.getItem("auth_id"),
                                                followup_id: cellProps.row.original.id,
                                            })
                                        }
                                    >
                                        <i className="fas fa-check"></i>
                                    </button>
                                </div>
                            )
                        }
                    </div >
                </div>
            )
        }
    },
    {
        Header: "Related",
        accessor: "related",
        isInfoIcon: false,
        isShortIcon: false,
        className: "dg-related",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <>
                    {priorty}
                    <a className={`fs-3 ms-2 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>
                    </a>
                </>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_task_name",
        Cell: cellProps => {
            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div>
                    <strong> {shownSubTask?.length != 0 && shownSubTask != null ? (
                        <i className="bx bx-subdirectory-right fs-5"></i>
                    ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`}</strong>  {cellProps?.row?.original?.title}
                    <div className="ms-4">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_end_date",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.enddate != null ? moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY') : "-"}</div>
            )
        }
    },
    {
        Header: "Spent Hrs",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_last_update",
        Cell: cellProps => {
            return (
                <div className="">
                    {cellProps?.row?.original?.spent_hours || "-"}
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "activity-action",
        Cell: cellProps => {
            return (
                <>
                    {cellProps?.row?.original?.type == 1 ? (
                        <div className="d-flex justify-content-center">
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Activity"
                                onClick={() => handleEditTask(cellProps?.row?.original, "view")}
                            >
                                <i className="fas fa-eye "></i>
                            </a>

                            <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => handleAddHour(
                                cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id)}>
                                <i className="bi bi-alarm-fill"></i>
                            </a>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        className="dropdown-item"
                                        title="Edit Activity"
                                        onClick={() => handleEditTask(cellProps?.row?.original, "edit")}
                                    >
                                        <i className="fas fa-pen pr-10"></i>
                                        Edit Activity
                                    </a>
                                    <a title="Delete Activity" className="dropdown-item" onClick={() => onDelete(cellProps?.row?.original?.id)}>
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Activity
                                    </a>


                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </div>
                    ) : (
                        <div className="d-flex ">
                            <Link title="view lead" className="btn btn-sm btn-outline-secondary me-1" to={`${configure.appUrl}sales/edit-lead/${cellProps.row.original?.related_id}`} >
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                            <a title="Add Task" className="btn btn-sm btn-outline-secondary me-1 "
                                onClick={() => onAddTask(cellProps.row.original?.related_id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id)}>
                                <i className="fas fa-clipboard-list"></i>
                            </a>
                        </div>
                    )}
                </>

            )
        },
    },
]

export const contractDashboardColumns = (onApproveStatus, onViewContract) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center col-md-1",
    },
    {
        Header: "Assigned",
        accessor: "assign",
        className: "col-1",
        Cell: cellProps => {
            return (
                <div className="text-center d-flex align-items-center">
                    <img
                        src={`${configure.imgUrl}${cellProps.row.original.img}`}
                        alt=""
                        className="assigned-img mx-1"
                        width="20"
                        height="20"
                    />
                    {cellProps.row.original.location != null ? (
                        <img
                            src={cellProps.row.original.location == 1 ? indiaFlag : ukFlag}
                            className=" mx-1 location-img"
                            alt="flag"
                            width="20"
                            height="15"
                        />
                    ) : null}
                </div>
            )
        },
        isInfoIcon: true,

    },
    {
        Header: "Company",
        accessor: "company_name",
        isInfoIcon: true,
        className: " col-md-5",
        Cell: cellProps => {
            return (
                <div>
                    {cellProps?.row?.original?.cust_company_name || cellProps?.row?.original?.lead_company_name || cellProps?.row?.original?.intro_company_name}
                </div>
            )
        }
    },
    {
        Header: "Amount",
        accessor: "value",
        isInfoIcon: true,
        className: "text-center col-md-1",
        Cell: cellProps => {
            return (
                <div>
                    {cellProps.row.original.value != null ? (
                        <>
                            {cellProps.row.original.currency_symbol || "-"}{" "}
                            {cellProps.row.original.value}
                        </>
                    ) : (
                        "-"
                    )}
                </div>
            )
        },
    },
    {
        Header: "Duration",
        accessor: "duration",
        isInfoIcon: true,
        className: "col-md-1",
        Cell: cellProps => {
            const startDate = new Date(cellProps.row.original.startdate)
            const endDate = new Date(cellProps.row.original.enddate)
            const timeDifference = endDate - startDate
            const daysDifference = Math.floor(
                timeDifference / (1000 * 60 * 60 * 24)
            )
            return <div>{daysDifference} days</div>
        },
    },
    {
        Header: "Sent Date",
        accessor: "startdate",
        isInfoIcon: true,
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <div>
                    {cellProps.row.original?.startdate != null
                        ? moment(cellProps.row.original?.startdate).format("DD-MM-YYYY")
                        : "-"}
                </div>
            )
        },
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: true,
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <div>
                    {cellProps.row.original?.enddate != null
                        ? moment(cellProps.row.original?.enddate).format("DD-MM-YYYY")
                        : "-"}
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "col-md-1",
        Cell: cellProps => {

            return (
                <div className="d-flex">
                    <a
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                        onClick={() => onViewContract(cellProps?.row?.original?.id)}
                    >
                        <i className="fas fa-eye "></i>
                    </a>
                    <a className="btn btn-sm btn-outline-primary me-1" title="Approve" onClick={
                        () => onApproveStatus(cellProps?.row?.original?.id, 1, 5)
                    } >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]

export const invoiceDashboardColumns = (onApproveZtpl, onViewinvoice) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center id-col tab-1",

    },
    {
        Header: "Date",
        accessor: "invoice_date",
        isInfoIcon: false,
        className: "text-center date-col tab-2",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.invoice_date).format('DD-MM-YYYY')}</div>
            )
        }

    },
    {
        Header: "Invoice No.",
        accessor: "invoice_number",
        grow: false,
        className: "prf_invoice_box text-center tab-3",
        isInfoIcon: true,
    },

    {
        Header: "Customer",
        accessor: "company",
        isInfoIcon: true,
        className: "customer-col tab-4",
    },
    {
        Header: "Amount",
        accessor: "total",
        isInfoIcon: true,
        className: "text-center amount-col tab-6",
        Cell: cellProps => {
            return (
                <div>{parseFloat(cellProps?.row?.original?.total).toFixed(2)}</div>
            )
        }

    },
    {
        Header: "Due Date",
        accessor: "due_date",
        isInfoIcon: false,
        className: "text-center due_date-col tab-5",

    },
    {
        Header: "Action",
        accessor: "action",
        className: "text-center col-md-1",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    <a
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                        onClick={() => onViewinvoice(cellProps?.row?.original?.id)}
                    >
                        <i className="fas fa-eye "></i>
                    </a>
                    <a className="btn btn-sm btn-outline-primary me-1" title="Approve" onClick={
                        () => onApproveZtpl(cellProps?.row?.original?.id, 1, 3)
                    } >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]
export const ProposalDashboardColumns = (onApproveStatus) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center col-md-1",
    },
    {
        Header: "Assigned",
        accessor: "assign",
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <div className="text-center d-flex align-items-center">
                    <img
                        src={`${configure.imgUrl}${cellProps.row.original.img}`}
                        alt=""
                        className="assigned-img mx-1"
                        height="20"
                        width="20"
                    />
                    {cellProps.row.original.location != null ? (
                        <img
                            src={cellProps.row.original.location == 1 ? indiaFlag : ukFlag}
                            className=" mx-1 location-img"
                            alt="flag"
                            width="20"
                            height="15"
                        />
                    ) : null}
                </div>
            )
        },
        isInfoIcon: true,
    },
    {
        Header: "Client Name",
        accessor: "companyname",
        isInfoIcon: true,
    },
    {
        Header: "Proposal Title",
        accessor: "proposaltitile",
        isInfoIcon: true,
        Cell: cellProps => {
            return (
                <span className="text-dark">
                    {cellProps.row.original.proposaltitile}
                </span>
            )
        },
    },
    {
        Header: "Price",
        accessor: "price",
        isInfoIcon: true,
        className: "",
        Cell: cellProps => {
            const array = JSON.parse(cellProps.row.original?.costbreakdown) || []
            const totalSum = array?.reduce((accumulator, item) => {
                const totalValue = parseFloat(item.totalcost) // Convert "total" to a number
                if (!isNaN(totalValue)) {
                    return accumulator + totalValue
                }
                return accumulator // Skip items with invalid "total" values
            }, 0)
            return (
                <div>
                    {cellProps.row.original.currency} {parseFloat(totalSum).toFixed(2) || 0}
                </div>
            )
        },
    },
    {
        Header: "Created At",
        accessor: "created_at",
        isInfoIcon: true,
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <div>
                    {moment(cellProps.row.original.created_at).format("DD-MM-YYYY") || "-"}
                </div>
            )
        },
    },
    {
        Header: "Open Till",
        accessor: "opentill",
        isInfoIcon: true,
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <span>
                    {moment(cellProps.row.original.opentill).format("DD-MM-YYYY")}
                </span>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "col-md-1",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    <ProposalPdf proposal_id={cellProps.row.original.id} />
                    <a className="btn btn-sm btn-outline-primary me-1" title="Approve" onClick={
                        () => onApproveStatus(cellProps?.row?.original?.id, 1, 6)
                    } >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]

export const leavesDashboardColumns = (onLeaveDetail) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lemp-id",
    },
    {
        Header: "Employee Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "col-md-3",
    },
    {
        Header: "Reason",
        accessor: "leave_reason",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lreason_box text-start",
    },
    {
        Header: "Applied On",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center lapplied_on_box",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "end_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_code-box text-center",
    },
    {
        Header: "Total Days",
        accessor: "leave_days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "lemployee_code-box text-center",
    },

    {
        Header: "Action",
        accessor: "action",
        className: "col-1",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    <a
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                        onClick={() => onLeaveDetail(cellProps?.row?.original?.id, cellProps?.row?.original?.employee)}
                    >
                        <i className="fas fa-eye "></i>
                    </a>
                </div>
            )
        },
    },

]

export const todayPlanClolumns = (onEditTask, onDeleteTask, handleStatus, FollowupStatus, onAddHours, onFollowTask) => [
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_status-id text-center",
        Cell: cellProps => {
            const [isChecked, setIsChecked] = useState(cellProps.row.original.checked || false);
            const handleCheckboxChange = async (e, id) => {
                const checked = e.target.checked;
                setIsChecked(checked);
                const userConfirmed = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to change the status?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, change it!",
                    cancelButtonText: "No, cancel",
                });

                if (userConfirmed.isConfirmed) {
                    handleStatus(e, cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id);
                    setIsChecked(false);
                } else {
                    setIsChecked(false);
                }
            };


            return (
                <div className="d-flex align-items-center gap-3 justify-content-start">
                    <img
                        src={`${cellProps?.row?.original?.added_user ? `${configure?.imgUrl}${cellProps?.row?.original?.added_user}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <div className="text-end" id="customCheckbox" >
                        {
                            cellProps.row.original.type != 'followup' ? (
                                <>
                                    {cellProps.row.original?.status != "complete" ? (
                                        <input
                                            type="checkbox"
                                            className="form-check-input custom-checkbox mt-2im general-status"
                                            id={`customCheck-${cellProps.row.id}`}
                                            value="complete"
                                            disabled={
                                                cellProps.row.original.added_by != localStorage.getItem("auth_id")
                                            }
                                            checked={isChecked}
                                            onChange={(e) => handleCheckboxChange(e, cellProps.row.original.id)}
                                        />
                                    ) : null}
                                </>
                            ) : (
                                <div className="text-center">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                            followChangeStatus({
                                                user_id: localStorage.getItem("auth_id"),
                                                followup_id: cellProps.row.original.id,
                                            })
                                        }
                                    >
                                        <i className="fas fa-check"></i>
                                    </button>
                                </div>
                            )
                        }
                    </div >
                </div>
            )
        }
    },
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'pl_id',
        Cell: cellProps => {
            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}
                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }

            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div className="text-capitalize">{priorty}
                    <strong>
                        {shownSubTask?.length != 0 && shownSubTask != null ? (
                            <i className="bx bx-subdirectory-right fs-5"></i>
                        ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong>
                    {cellProps.row?.original?.title || "-"}
                    <div className="ms-5">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },

    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Today's time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            return (
                <>
                    {cellProps?.row?.original?.type != 'followup' ? (
                        <div className="d-flex justify-content-center">
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Activity"
                                onClick={() => onEditTask(cellProps?.row.original, "view")}
                            >
                                <i className="fas fa-eye "></i>
                            </a>
                            <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => onAddHours(cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id)}>
                                <i className="bi bi-alarm-fill"></i>
                            </a>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        className="dropdown-item"
                                        title="Edit Activity"
                                        onClick={() => onEditTask(cellProps?.row.original, "edit")}
                                    >
                                        <i className="fas fa-pen pr-10"></i>
                                        Edit Activity
                                    </a>
                                    <a title="Delete Activity" className="dropdown-item"
                                        onClick={() => onDeleteTask(cellProps?.row.original.id)}
                                    >
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Activity
                                    </a>


                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </div>
                    ) : (
                        <div className="d-flex gap-1">
                            <Link title="view lead" className="btn btn-sm btn-outline-secondary me-1" to={`${configure.appUrl}sales/edit-lead/${cellProps.row.original?.related_id}`} >
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                            <a title="Add Task" className="btn btn-sm btn-outline-secondary me-1 "
                                onClick={() => onFollowTask(cellProps.row.original?.related_id)}>
                                <i className="fas fa-clipboard-list"></i>
                            </a>
                        </div>
                    )}
                </>
            )
        }

    },
]

export const YesterDayPlanClolumns = (onAddHours) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'pl_id',
        Cell: cellProps => {

            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}

                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {

            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div className="text-capitalize">
                    <strong>
                        {shownSubTask?.length != 0 && shownSubTask != null ? (
                            <i className="bx bx-subdirectory-right fs-5"></i>
                        ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong>
                    {cellProps.row?.original?.title || "-"}
                    <div className="ms-5">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <div>
                    {priorty}
                </div>
            )
        }
    },
    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Today's Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => onAddHours(cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id)}>
                    <i className="bi bi-alarm-fill"></i>
                </a>
            )
        }
    },
]

export const TeamsPlanClolumns = (onEditTask) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'tl_id',
        Cell: cellProps => {

            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.added_user ? `${configure?.imgUrl}${cellProps?.row?.original?.added_user}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}

                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {
            return (
                <div>
                    <strong>{` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong> {cellProps?.row?.original?.title}
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <div>
                    {priorty}
                </div>
            )
        }
    },
    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Actual Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_id",
        Cell: cellProps => {
            return (
                <div className="">
                    <a className="btn btn-sm btn-outline-secondary me-1" title="View Activity" onClick={() => onEditTask(cellProps?.row.original, "view")}>
                        <i className="fas fa-eye " ></i>
                    </a>
                </div>
            )
        }
    },

]

export const TaskTabelColumns = (onInfo, onAddHours, onChangeStatus, onStartTimer) => [
    {
        Header: "Task ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        className: "project_id text-center"
    },
    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        className: "col-6",
        Cell: cellProps => {
            const data = cellProps?.row?.original
            return (
                <div>
                    <span title={data?.project_name}>{trimProjectName(data?.project_name)}</span> - {data?.title}
                </div>
            )
        }
    },
    {
        Header: "Hours",
        accessor: "estimate_dev_days",
        isInfoIcon: false,
        className: "col-1 text-center",
    },
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        className: "col-1 text-center",
        Cell: cellProps => {
            return (
                <div className="custom-select-wrapper">
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row.original.status}
                        onChange={(e) =>
                            onChangeStatus(cellProps?.row?.original?.id, e?.target?.value, cellProps?.row?.original?.project_id)}
                    >
                        {developmentTaskStatus?.map((obj, i) => {
                            return (
                                <option value={obj?.value} key={i} className="" disabled={obj?.value == 0 || obj?.value == 1}>
                                    {obj?.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "total_time_spent",
        isInfoIcon: false,
        className: "col-1 text-center",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "col-1",
        Cell: cellProps => {
            const { isRunning, currentTaskId } = useTimer();
            const data = cellProps?.row?.original

            return (
                <div className="d-flex justify-content-center">
                    <a className={`btn btn-sm ${isRunning && currentTaskId == data.id ? "btn-outline-danger" : "btn-outline-success"} me-1`}
                        title="Start / Stop Timer"
                        onClick={() => onStartTimer(data, isRunning)}
                    >
                        <i className="fa fa-stopwatch"></i>
                    </a>
                    <a className="btn btn-sm btn-outline-secondary me-1"
                        title="Info"
                        onClick={() => onInfo(data)}
                    >
                        <i className="fa fa-info"></i>
                    </a>
                    <a className="btn btn-sm btn-outline-primary me-1"
                        title="Info"
                        onClick={() => onAddHours(data?.id)}
                    >
                        <i className="bx bxs-timer fs-5"></i>
                    </a>
                </div>
            )
        },
    },
]
export const DashboardSwipesColumns = (onDisapporve, onApprove) => [
    {
        Header: "ID",
        accessor: "id",
        className: "db_swipe_id",
        isShortIcon: true,
    },
    {
        Header: "Swipe & Issues",
        accessor: "db_swipe_name",
        className: "db_swipe_name",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-start gap-1 db_swipe_name_box">
                    <img
                        src={cellProps.row.original?.avatar != null ? `${configure?.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}
                        alt="user Image"
                        className="user_img_da img-fluid cursor-pointer me-2"
                        title={cellProps.row.original?.name}
                    />
                    <div className="dahboard_swipe">
                        {cellProps.row.original?.leave_type == 1 ? (
                            <span className="me-3">{moment(cellProps.row.original?.date).format('DD-MM-YYYY')} {cellProps.row?.original?.time}</span>
                        ) : cellProps.row.original?.leave_type == 2 ? (
                            <span className="me-3">{cellProps.row.original?.amount} {cellProps.row.original?.type == 0 ? "Days" : "Amount"} </span>
                        ) : null}

                        {cellProps.row.original.reason}

                    </div>
                </div>
            )
        },
    },
    {
        Header: "Date",
        accessor: "db_swipe_date",
        className: "db_swipe_date",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "db_swipe_actions text-center",
        className: "db_swipe_actions text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-primary me-1"
                        title="Approve"
                        onClick={() => onApprove(cellProps?.row?.original?.leave_type, cellProps?.row?.original?.id)}
                    >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                    <a className="btn btn-sm btn-outline-danger me-1"
                        title="Disapprove"
                        onClick={() => onDisapporve(cellProps?.row?.original?.leave_type, cellProps?.row?.original?.id)}
                    >
                        <i className="dripicons-cross icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]


export const projectsColumnsDash = () => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: "Due Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "due_date_box text-center",
        Cell: cellProps => {
            return (
                <>
                    {cellProps.row.original.enddate
                        ? moment(cellProps.row.original.enddate).format("DD-MM-YYYY")
                        : "-"}
                </>
            )
        },
    },
    {
        Header: "P.W.D",
        title: "Pending Working Days",
        accessor: "pwd",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pwd_box text-center",
    },
    {
        Header: "Days",
        title: "Last Updated Days",
        accessor: "days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "projects_days_box text-center",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div className="" style={{ width: "100%" }}>
                    {cellProps.row.original.status}
                </div>
            )
        },
    },
    {
        Header: "D.I.D",
        title: "Days Remaining For Project Delivery",
        accessor: "did_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "did_box text-center",
        Cell: cellProps => {
            const startDate = moment()
            const deliveryDateBefore = moment(
                cellProps.row.original.deliverydatebefore
            )
            const differenceInDays = deliveryDateBefore?.diff(startDate, "days")
            return <>{differenceInDays ? differenceInDays : ""}</>
        },
    },
]

export const DigitalTaskColumns = (onGetDetails) => [
    {
        Header: "Project",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        className: "text-center task_ids",
        Cell: cellProps => {
            const row = cellProps?.row?.original
            const outputString = row?.project_name.split(" ").map(word => word[0]).join("");
            return (
                <div>
                    <a className="cursor-pointer color-grey d-flex align-items-center justify-content-center text-uppercase" title={cellProps?.row?.original?.project_name}>
                        {outputString}
                        <i className={`${DigitalServiceOptions?.find((obj) => obj.id == row?.type)?.icon} ms-3 fs-4 ${DigitalServiceOptions?.find((obj) => obj.id == row?.type)?.text}-color`}></i>
                    </a>
                </div>
            )
        }
    },
    {
        Header: "Activity Name",
        title: "Task Name",
        accessor: "name",
        isInfoIcon: false,
        className: "col-md-8"
        // className: "task_name_box",
    },

    {
        Header: "Completed Qty",
        accessor: "actual_qty",
        isInfoIcon: false,
        className: "plan-qtys text-center",

    },
    {
        Header: "Plan Qty",
        accessor: "quantity",
        isInfoIcon: false,
        className: "plan-qty text-center",
    },
    {
        Header: "Target Qty",
        accessor: "target_qty",
        isInfoIcon: false,
        className: "plan-qty targ-qty text-center",
        Cell: cellProps => {
            return (
                <div>
                    {cellProps?.row?.original?.finished_qty}/{cellProps?.row?.original?.total_quantity}
                </div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action text-center",
        Cell: cellProps => {
            const rowIndex = cellProps.row.original;
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-primary me-1"
                        title="Add Activity"
                        onClick={() => onGetDetails(rowIndex?.activity_id, rowIndex)}
                    >
                        <i className="fa fa-plus"></i>
                    </a>
                </div>
            )
        },
    },
]


export const projectApprovalColumns = () => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center col-md-1",
    },
    {
        Header: "Type",
        accessor: "assign",
        className: "col-md-2",
        isInfoIcon: true,
        Cell: cellProps => {
            return (
                <div>Digital Marketing -
                    {DigitalServiceOptionsDrop?.find((obj) => obj?.value == cellProps?.row?.original?.ptype)?.label}
                </div>
            )
        }
    },
    {
        Header: "Project Name",
        accessor: "project_name",
        isInfoIcon: true,
    },
    {
        Header: "Start Date",
        accessor: "start_date",
        className: "col-md-1",
        isInfoIcon: true,
    },
    {
        Header: "End Date",
        accessor: "end_date",
        className: "col-md-1",
        isInfoIcon: true,
    },
    {
        Header: "Action",
        accessor: "action",
        className: "col-md-1",
        Cell: cellProps => {
            const data = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-center">
                    <Link to={`${configure.appUrl}initial-project-plan/${data?.project_id}/${data?.ptype}#rollout`}
                        title="View"
                        className="btn btn-sm btn-outline-secondary me-1"
                    >
                        <i className="fas fa-eye "></i>
                    </Link>
                </div>
            )
        },
    },
]
export const suppprtColumns = (onReply) =>
    [
        {
            Header: "ID",
            accessor: "id",
            title: "ID",
            isInfoIcon: false,
            isShortIcon: true,
            className: "s_id",
            Cell: cellProps => {
                let priorty;
                if (cellProps.row.original?.priority == "high") {
                    priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                    ></i>
                } else if (cellProps.row.original?.priority == "medium") {
                    priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                    ></i>
                } else if (cellProps.row.original?.priority == "low") {
                    priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                    ></i>
                }
                return (
                    <div className="d-flex gap-1">
                        {priorty} {cellProps.row.original?.id}
                    </div>
                )
            }
        },
        {
            Header: "Assign",
            title: "assign",
            accessor: "assign_name",
            isInfoIcon: false,
            isShortIcon: true,
            className: "assigneds_name_box",
            Cell: cellProps => {
                return (
                    <div className="text-center d-flex gap-1  justify-content-center align-items-center">
                        <img
                            src={sideImg}
                            alt=""
                            className="img-fluid assignee-img"
                            title="User Image"
                        />
                        <img
                            src={bug}
                            alt=""
                            className="img-fluid assignee-img"
                            title="Bug"
                        />
                    </div>
                )
            },
        },
        {
            Header: "Project",
            title: "Customer",
            accessor: "customer",
            isInfoIcon: false,
            isShortIcon: true,
            className: "scustomer_box",
        },
        {
            Header: "Title",
            title: "Task Name",
            accessor: "task_name",
            isInfoIcon: false,
            isShortIcon: true,
            className: "stask_name_box",
        },

        {
            Header: "Status",
            title: "Status",
            accessor: "status_col",
            isInfoIcon: false,
            isShortIcon: true,
            className: "sstatus_box",
            Cell: cellProps => {
                return (
                    <div className="custom-select-wrapper" style={{ width: "100%" }}>
                        <select
                            className={`form-select custom-select`}
                            onSelect={e => setSelectStatus(e.target.value)}
                        >
                            <option value="" className="">
                                - Select -
                            </option>
                            <option value="notanswered" className="" selected>
                                Not Answered
                            </option>
                            <option value="open" className="">
                                Open
                            </option>
                            <option value="inprogress" className="">
                                In Progress
                            </option>
                            <option value="answered" className="">
                                Answered
                            </option>
                            <option value="onhol`d" className="">
                                on Hold
                            </option>
                            <option value="Closed" className="">
                                Closed
                            </option>
                        </select>
                    </div>
                )
            },
        },
        {
            Header: "Created At",
            title: "created at",
            accessor: "created_at",
            isInfoIcon: false,
            isShortIcon: true,
            className: "screated_at_col-box",
        },
        {
            Header: "Last Reply",
            title: "Last Reply",
            accessor: "last_reply_col",
            isInfoIcon: false,
            isShortIcon: true,
            className: "slast_reply_col-box ",
        },
        {
            Header: "Action",
            accessor: "action",
            className: "msupport_action",
            Cell: cellProps => {
                return (
                    <div className="d-flex justify-content-center">
                        <a className="btn btn-sm btn-outline-success me-1"
                            title="Start / Stop Timer"
                        >
                            <i className="fa fa-stopwatch"></i>
                        </a>
                        <a className="btn btn-sm btn-outline-secondary me-1"
                            title="Start / Stop Timer"
                            onClick={() => onReply()}
                        >
                            <i className="bi bi-reply-fill"></i>
                        </a>
                    </div>
                )
            }
        }
    ]

export const approvalTimerColumns = (onApprove) => [
    {
        Header: "ID",
        accessor: "timer_id",
        className: "db_swipe_id",
        isShortIcon: true,
    },
    {
        Header: "Task Name",
        accessor: "db_swipe_name",
        className: "col-4",
        isShortIcon: true,
        Cell: cellProps => {
            const name = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-start gap-1 db_swipe_name_box align-items-center">
                    <img className="user_img_da img-fluid cursor-pointer me-2" src={`${configure.imgUrl}/${name?.avatar}`} alt="Image" /> <span title={name?.project_name}>{trimProjectName(name?.project_name)}</span> - {name?.title}
                </div>
            )
        },
    },
    {
        Header: "Reason",
        accessor: "db_swipe_daste",
        className: "col-4",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div>{resaonOtption?.find((obj) => obj?.value == cellProps?.row?.original?.reason)?.label}</div>
            )
        }
    },
    {
        Header: "Actual Time",
        accessor: "ostart",
        className: "db_swipe_actions",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.status == 3 ? "0 hrs 0 min" : calculateTimeDifference(cellProps?.row?.original?.ostart, cellProps?.row?.original?.oend)}</div>
            )
        }
    },
    {
        Header: "Updated Time",
        accessor: "oend",
        className: "db_swipe_actions",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div>{calculateTimeDifference(cellProps?.row?.original?.nstart, cellProps?.row?.original?.nend)}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "db_swipe_actions text-center",
        className: "db_swipe_actions text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-primary me-1"
                        title="Approve"
                        onClick={() => onApprove(1, cellProps?.row?.original?.timer_id, cellProps?.row?.original?.status)}
                    >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                    <a className="btn btn-sm btn-outline-danger me-1"
                        title="Disapprove"
                        onClick={() => onApprove(2, cellProps?.row?.original?.timer_id, cellProps?.row?.original?.status)}
                    >
                        <i className="dripicons-cross icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]