import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import Select from "react-select"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import ReactSelect from "react-select"
import editEventImg from "../../../assets/images/Digital Marketing/editEventImg.jpg"
import Switch from "react-switch"
import "@fullcalendar/bootstrap/main.css"
import "../../../assets/css/Work/digitalMarketing.css"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import userImg from "../../../assets/images/users/AdminImg.png"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"
import { DigitalServiceOptionsDrop } from "utils/common-options"

const TeamsCalendar = props => {
  // meta title
  document.title = "Weekly Planning | Zithas Crm"
  const [event, setEvent] = useState({})

  // Static data

  const edit_status_options = [
    { label: "Pending", value: "Pending" },
    { label: "Done", value: "Done" },
  ]
  // events validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "bg-danger",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        setEvents(prevEvents =>
          prevEvents.map(e => (e.id === event.id ? updateEvent : e))
        )
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        setEvents(prevEvents => [...prevEvents, newEvent])
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
    },
  })

  // category validation
  const categoryValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: values => {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.event_category
          ? values.event_category + " text-white"
          : "bg-danger text-white",
      }
      // save new event
      setEvents(prevEvents => [...prevEvents, newEvent])
      toggleCategory()
    },
  })

  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [currentStatusPopup, setCurrentStatusPopup] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)
  const [editStatus, setEditStatus] = useState(0)
  const [customSwitch, setCustomSwitch] = useState(true)
  const [customSwitch2, setCustomSwitch2] = useState(true)
  const [customSwitch3, setCustomSwitch3] = useState(true)
  const [updateData, setUpdateData] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState(false)

  const [isCollapse, setIsCollapse] = useState(true)
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          fontWeight: "bold",
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {/* {" "}
        NO */}
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          fontWeight: "bold",
          color: "#fff",
          paddingRight: 2,
          paddingLeft: 4,
        }}
      >
        {/* {" "}
        YES */}
      </div>
    )
  }

  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (currentStatusPopup) {
        setCurrentStatusPopup(false)
      }
      if (modal) {
        setModal(false)
      }
    }
  })

  // popup upate width
  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight
      setBoxHeight(viewportHeight * 0.9)
    }
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
      // setFilterBoxWidth(viewportWidth * 72)
    }
    updateWidth()
    updateHeight()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth, updateHeight)
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(true)
      }, 500)
    }
  }, [modal, event])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
    }
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    // toggle()
  }

  const handleEventClick = arg => {
    const event = arg.event
    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    })
    setIsEdit(true)
    // toggle()
  }



  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const draggedElclass = draggedEl.className
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      }
    }
    setModal(true)
  }

  const customButtonStyles = {
    backgroundColor: "#28a745",
    color: "white",
  }
  const toggleLoading = load => {
    setLoading(load)
  }

  const token = secureLocalStorage.getItem("auth_token")
  const [customDateHtml, setCustomDateHtml] = useState([])
  const [events, setEvents] = useState([])
  const [membersOption, setMemberOption] = useState([])
  const [projectOption, setProjectOption] = useState([])
  const [memberId, setMemberId] = useState([])
  const [projectId, setProjectId] = useState([{ value: 0, label: "All Projects" }])
  const [changeKey, setChangeKey] = useState(false)
  const [showActivities, setShowActivies] = useState([])
  const [dateRange, setDateRange] = useState('')
  useEffect(() => {
    const getData = async () => {
      const res = await fetchData(
        toggleLoading,
        `digitalProjectAndUsers`,
        token
      )
      if (res?.success) {
        setMemberOption(
          res?.data?.users?.map((obj) => ({
            value: obj.id,
            label: obj.name
          }))

        )
        setProjectOption([
          { value: 0, label: "All Projects" },
          ...res?.data?.projects?.map((obj) => ({
            value: obj.id,
            label: obj.project_name,
            assignee: [
              obj.seo_assignee,
              obj.smm_assignee,
              obj?.em_assignee,
              obj?.ppc_assignee
            ]
              .filter(assignee => assignee != null)
              .join(', ')
          }))
        ])

      }
    }
    getData()
  }, [])

  const getTaskData = async info => {
    const range = `${moment(info.view.currentStart)?.format('MMM DD')}-${moment(info.view.currentEnd)?.format('DD YYYY')}`
    setDateRange(range)
    const formEntries = {
      user_id: memberId?.value,
      project_id: projectId?.map((obj) => obj?.value)?.join(','),
      start_date: moment(info.view.currentStart)?.format("YYYY-MM-DD"),
      end_date: moment(info.view.currentEnd)?.format("YYYY-MM-DD"),
    }
    const res = await FetchPostData(
      toggleLoading,
      "getDigitalProjectTasksByUser",
      token,
      formEntries
    )
    if (res?.data?.success) {
      setCustomDateHtml(res?.data?.data?.availability?.original?.data)
      const event = res?.data?.data?.tasks?.map(obj => {
        return {
          id: obj?.id,
          icon: obj?.avatar,
          title: obj?.name,
          projectName: obj?.project_name,
          start: moment(obj?.date).format('YYYY-MM-DD'),
          className: `${obj?.type == 1 ? "bg-seo" : obj?.type == 2 ? "bg-smm" : obj?.type == 3 ? "bg-ppc" : obj?.type == 4 ? "bg-me" : ''} text-white`,
          count: obj?.quantity,
          staff_id: obj?.staff_id,
          project_id: obj?.project_id,
          task_id: obj?.id,
          type: obj?.type,
          activity_id: obj?.activity_id
        }
      })
      setEvents(event)
      setShowActivies(res?.data?.data?.tasks)
    } else {
      setEvents([])
    }
  }

  const onEventDrop = async (info) => {
    const { event } = info;
    const formEntries = {
      staff_id: event.extendedProps?.staff_id,
      project_id: event.extendedProps?.project_id,
      activity_id: event.extendedProps?.activity_id,
      user_id: localStorage.getItem('auth_id'),
      task_id: event.extendedProps?.task_id,
      type: event.extendedProps.type,
      start_date: moment(event?.start).format('YYYY-MM-DD'),
    }
    const res = await FetchPostData(toggleLoading, "editDigitalProjectTask", token, formEntries);
    if (res?.data?.success) {
      setChangeKey(!changeKey)
    }
  }



  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <Row>
          <Col className="col-12">
            <Row>
              <Col lg={12}>
                <div className=" col-12 d-flex justify-space-between">
                  <div className="col-6 d-flex justify-content-start align-items-start gap-5">
                    <h4>Teams Weekly Plan</h4>
                    <div className="d-flex justify-content-start align-items-center gap-4">
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <span
                          className="bg-seo border-black"
                          style={{
                            padding: "6px",
                          }}
                        ></span>
                        - <span>SEO</span>
                      </div>

                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <span
                          className="bg-smm border-black"
                          style={{
                            padding: "6px",
                          }}
                        ></span>
                        - <span>SMM</span>
                      </div>

                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <span
                          className="bg-ppc border-black"
                          style={{
                            padding: "6px",
                          }}
                        ></span>
                        - <span>PPC</span>
                      </div>

                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <span
                          className="bg-em border-black"
                          style={{
                            padding: "6px",
                          }}
                        ></span>
                        - <span>EM</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <div className="col-5 mb-3">
                      <Select
                        placeholder="Select Member"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={membersOption}
                        value={memberId}
                        onChange={selectedOption => {
                          setMemberId(selectedOption)
                        }
                        }
                      />
                    </div>
                    <div className="col-6 mb-3 ms-2">
                      <Select
                        placeholder="Select Project "
                        className="basic-multi-select "
                        classNamePrefix="select"
                        isMulti="true"
                        options={projectOption}
                        value={projectId}
                        onChange={(e) => setProjectId(e)}
                      />
                    </div>
                    <div className="ms-2">
                      <a
                        className="btn btn-sm  me-1 bg-purple text-white"
                        style={{ borderRadius: "5px" }}
                        onClick={() => {
                          setChangeKey(!changeKey)
                        }}
                      >
                        <i className="bi bi-funnel-fill fs-5"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="customFullcalendar"></div>
                <FullCalendar
                  key={changeKey}
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  initialView="dayGridWeek"
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  customButtons={{
                    currentStatus: {
                      text: "Current Status",
                      className: "btn-current-status",
                      style: customButtonStyles,
                      click: () => setCurrentStatusPopup(true),
                    },
                  }}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right:
                      "dayGridMonth,dayGridWeek,dayGridDay,currentStatus",
                  }}
                  datesSet={getTaskData}
                  events={events}
                  eventContent={arg => {
                    const { event } = arg
                    return (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div
                          className="text-center d-flex"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            maxWidth: "100%",
                            fontWeight: "700",
                          }}
                        >
                          {event.extendedProps.icon && (
                            <img
                              src={`${configure.imgUrl}${event.extendedProps.icon}`}
                              alt="User Icon"
                              className="me-1"
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                          {event.extendedProps.projectName} -
                          <span className="bg-white text-dark  act-qty">
                            {event.extendedProps.count}
                          </span>
                        </div>
                        <div
                          className="text-start"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            maxWidth: "100%",
                          }}
                        >
                          {event.title}
                        </div>
                      </div>
                    )
                  }}
                  dayCellContent={cell => {
                    const { date } = cell
                    const time =
                      customDateHtml?.find(
                        obj => obj?.date == moment(date).format("YYYY-MM-DD")
                      )?.time || "00:00"
                    return (
                      <div className="fc-day-cell-content">
                        <div className="time-header">{time} hrs</div>
                      </div>
                    )
                  }}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  drop={onDrop}
                  eventDrop={onEventDrop}
                />

                {/* New/Edit event modal */}
                <Modal
                  isOpen={modal}
                  className={`customEditActModal ${props.className}`}
                  size="xl"
                  centered
                >
                  <ModalHeader
                    toggle={toggle}
                    tag="h5"
                    className=" py-3 px-4 d-flex  border-bottom-0"
                  >
                    {isEdit ? "Edit Activity" : "Add Activity"}
                    {isEdit && (
                      <h5 className="mt-2 ms-4 text-purple">
                        User :{" "}
                        <span className="text-primary fw-bold">
                          {" "}
                          <strong>Rahul Ovhal</strong>
                        </span>
                      </h5>
                    )}
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                          <div className="text-center">
                            <img
                              src={editEventImg}
                              alt="Addepe"
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>

                        <div className="col-7 d-flex flex-column mb-3">
                          <div className="col-12 d-flex justify-content-start align-items-center gap-2">
                            <div className="col-5">
                              <Label className="form-label">
                                Activity Name
                              </Label>
                              <Input
                                disabled
                                name="title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="Activity 1"
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-2">
                              <label htmlFor="planned_quantity">Planned</label>
                              <input
                                type="text"
                                name="planned_quantity"
                                className="form-control"
                                value={3}
                              // style={{width : "80%"}}
                              ></input>
                            </div>
                            <div className="col-2">
                              <label htmlFor="working_quantity">Working</label>
                              <input
                                type="text"
                                name="working_quantity"
                                className="form-control"
                                value={3}
                              // style={{width : "80%"}}
                              ></input>
                            </div>
                            <div className="col-3 pe-2">
                              <label htmlFor="working_quantity">Status</label>
                              <ReactSelect
                                placeholder="Select Status"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={edit_status_options}
                                // isMulti="true"
                                value={editStatus}
                                onChange={selectedOption =>
                                  setEditStatus(selectedOption)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-start align-items-center gap-2 mt-4">
                            <div className="col-8">
                              <label htmlFor="activity_link">
                                Activity Link
                              </label>
                              <input
                                type="text"
                                name="activity_link"
                                className="form-control"
                                defaultValue="https://skote-h-light.react.themesbrand.com/calendar"
                              ></input>
                            </div>
                            <div className="col-4 d-flex flex-column justify-content-center align-items-center">
                              <label htmlFor="activity_link">Status</label>
                              <div className="d-flex justify-content-center align-items-center gap-1">
                                <h6>Done</h6>

                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-0 mb-sm-12 mb-2 ms-0"
                                  // onColor="#38a4f8"
                                  onColor="#556ee6"
                                  onChange={() => {
                                    setCustomSwitch(!customSwitch)
                                  }}
                                  checked={!customSwitch}
                                />
                                <h6>Pending</h6>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-start align-items-center gap-2 mt-4">
                            <div className="col-8">
                              {/* <label htmlFor="activity_link">Activity Link</label> */}
                              <input
                                type="text"
                                name="activity_link"
                                className="form-control"
                                defaultValue="https://skote-h-light.react.themesbrand.com/calendar"
                              ></input>
                            </div>
                            <div className="col-4 d-flex flex-column justify-content-center align-items-center">
                              {/* <label htmlFor="activity_link">Status</label> */}
                              <div className="d-flex justify-content-center align-items-center gap-1">
                                <h6>Done</h6>

                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-0 mb-sm-12 mb-2 ms-0"
                                  // onColor="#38a4f8"
                                  onColor="#556ee6"
                                  onChange={() => {
                                    setCustomSwitch2(!customSwitch2)
                                  }}
                                  checked={!customSwitch2}
                                />
                                <h6>Pending</h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 d-flex justify-content-start align-items-center gap-2 mt-4">
                            <div className="col-8">
                              {/* <label htmlFor="activity_link">Activity Link</label> */}
                              <input
                                type="text"
                                name="activity_link"
                                className="form-control"
                                defaultValue="https://skote-h-light.react.themesbrand.com/calendar"
                              ></input>
                            </div>
                            <div className="col-4 d-flex flex-column justify-content-center align-items-center">
                              <div className="d-flex justify-content-center align-items-center gap-1">
                                <h6>Done</h6>

                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-0 mb-sm-12 mb-2 ms-0"
                                  onColor="#556ee6"
                                  onChange={() => {
                                    setCustomSwitch3(!customSwitch3)
                                  }}
                                  checked={!customSwitch3}
                                />
                                <h6>Pending</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <div className="text-end my-3 me-0 pe-0">
                        <button className=" btn btn-primary btn-label">
                          <i className="fas fa-save me-2 label-icon"></i>
                          Save
                        </button>
                        {!!isEdit && (
                          <button
                            type="button"
                            className="btn btn-red me-2 ms-2 btn-label"
                            onClick={() => setDeleteModal(true)}
                          >
                            <i className="dripicons-trash me-2 icon-center label-icon"></i>
                            Delete
                          </button>
                        )}
                      </div>
                    </Form>
                  </ModalBody>
                </Modal>

                {/* Current Status  */}
                <Modal
                  size="xl"
                  isOpen={currentStatusPopup}
                  className="modal-fullscreen"
                >
                  <div className="modal-header mb-0">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <div className="col-6 d-flex align-items-center">
                        <h5 className="modal-title mt-0">Current Status</h5>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => setCurrentStatusPopup(false)}
                      className="ms-4"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        color: "red",
                      }}
                    >
                      <span
                        aria-hidden="true"
                        style={{
                          color: "#bf1e30",
                          fontSize: "28px",
                          lineHeight: "1px",
                        }}
                      >
                        &times;
                      </span>
                    </button>
                  </div>

                  <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
                    <Row className="gx-4 justify-content-between flex-column flex-md-row">
                      <div
                        className={`sidebar-containerr ${fixedSideTab ? "credtabFix" : ""
                          }`}
                        style={isIndex ? { zIndex: "2" } : {}}
                      >
                        <div className="position-relative mt-5">
                          <div>
                            <SimpleBar
                              style={{ height: "70vh" }}
                              className={`${fixedSideTab && "fix-simplebar"}`}
                            >
                              <Nav
                                pills
                                className={`credtabFix-ul ${fixedSideTab && ""
                                  }  flex-column px-1 py-1 h-100`}
                              >
                                {DigitalServiceOptionsDrop?.map((obj, i) => {
                                  return (
                                    <NavItem
                                      className={classnames({
                                        "d-flex flex-column gap-2 mb-2": true,
                                        active: verticalActiveTab == obj?.value,
                                      })}
                                      key={i}
                                    >
                                      <NavLink
                                        style={{ cursor: "pointer", width: "70px" }}
                                        className={classnames({
                                          "text-center crendential": true,
                                          active: verticalActiveTab == obj?.value,
                                        })}
                                        onClick={() => {
                                          setverticalActiveTab(obj?.value)
                                        }}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleLeave}
                                      >
                                        {" "}
                                        {obj?.label}
                                        <span className="link_name border-start border-white border-2">
                                          {obj?.name}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  )
                                })}
                              </Nav>
                            </SimpleBar>
                          </div>
                        </div>
                      </div>

                      <Col
                        md={isCollapse ? "12" : "10"}
                        className={`z-1 pe-0 credential-table ${isCollapse && "width-96 ps-0"
                          }`}
                      >
                        <div className="card p-4 h-100">
                          <div className="col-12 d-flex flex-column gap-2">
                            <div className="col-12 d-flex mb-3">
                              <div className="col-7 text-end pe-5 pt-2">
                                <h4>{dateRange}</h4>
                              </div>
                            </div>

                            <div className="col-12 d-flex justify-content-center align-items-center">
                              <div className="col-12 d-flex flex-column px-5">
                                <TabContent
                                  activeTab={verticalActiveTab}
                                  className="text-muted mt-4 mt-md-0"
                                >
                                  {DigitalServiceOptionsDrop?.map((obj, i) => {
                                    return (
                                      <TabPane tabId={obj?.value} key={i}>
                                        <table className="table table-responsive  table-bordered">
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                className="sr_col text-center"
                                              >
                                                Sr No.
                                              </th>
                                              <th
                                                scope="col"
                                                className="col-1"
                                              >
                                                Project Name
                                              </th>

                                              <th
                                                scope="col"
                                                className="activity_col"
                                              >
                                                Activity Name
                                              </th>
                                              <th
                                                scope="col"
                                                className="totalQty_col text-center"
                                              >
                                                Planned Qty
                                              </th>
                                              <th
                                                scope="col"
                                                className="complQty_col text-center"
                                              >
                                                Completed Qty
                                              </th>
                                              <th
                                                scope="col"
                                                className="complQty_col text-center  bg-red"
                                              >
                                                Target Qty
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {showActivities?.map((obj, i) => {
                                              if (obj?.type == verticalActiveTab) {
                                                const outputString = obj?.project_name.split(" ").map(word => word[0]).join("");
                                                return (
                                                  <tr key={i}>
                                                    <td
                                                      scope="row"
                                                      className="text-center"
                                                    >
                                                      {i + 1}
                                                    </td>
                                                    <td>
                                                      <a title={obj?.project_name}>
                                                        {outputString}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      {obj?.name}
                                                    </td>
                                                    <td className="text-center">{obj?.quantity}</td>
                                                    <td className="text-center">{obj?.actual_qty}</td>
                                                    <td className="text-center  bg-red">{obj?.finished_qty}/{obj?.total_quantity}</td>
                                                  </tr>
                                                )
                                              }
                                            })}
                                          </tbody>
                                        </table>
                                        {showActivities?.filter((obj) => obj?.type == verticalActiveTab)?.length == 0 ? (
                                          <p className="text-center text-purple mt-3 ">No data is Available</p>
                                        ) : null}
                                      </TabPane>
                                    )
                                  })}
                                </TabContent>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal>

                {/* Category modal */}
                <Modal
                  isOpen={modalcategory}
                  toggle={toggleCategory}
                  className={props.className}
                  centered
                >
                  <ModalHeader toggle={toggleCategory} tag="h5">
                    Add Event
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        categoryValidation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">Event Name</Label>
                            <Input
                              name="title"
                              type="text"
                              placeholder="Insert Event Name"
                              onChange={categoryValidation.handleChange}
                              onBlur={categoryValidation.handleBlur}
                              value={categoryValidation.values.title || ""}
                              invalid={
                                categoryValidation.touched.title &&
                                  categoryValidation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {categoryValidation.touched.title &&
                              categoryValidation.errors.title ? (
                              <FormFeedback type="invalid">
                                {categoryValidation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">Category</Label>
                            <Input
                              type="select"
                              name="category"
                              onChange={categoryValidation.handleChange}
                              onBlur={categoryValidation.handleBlur}
                              value={categoryValidation.values.category || ""}
                              invalid={
                                categoryValidation.touched.category &&
                                  categoryValidation.errors.category
                                  ? true
                                  : false
                              }
                            >
                              <option value="bg-danger">Danger</option>
                              <option value="bg-success">Success</option>
                              <option value="bg-primary">Primary</option>
                              <option value="bg-info">Info</option>
                              <option value="bg-dark">Dark</option>
                              <option value="bg-warning">Warning</option>
                            </Input>
                            {categoryValidation.touched.category &&
                              categoryValidation.errors.category ? (
                              <FormFeedback type="invalid">
                                {categoryValidation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col className="col-6">
                          <button type="button" className="btn btn-danger">
                            Delete
                          </button>
                        </Col>
                        <Col className="col-6 text-end">
                          <button
                            type="button"
                            className="btn btn-light me-1"
                            onClick={toggleCategory}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="btn-save-event"
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

TeamsCalendar.propTypes = {
  className: PropTypes.string,
}

export default TeamsCalendar
