import React, { useEffect, useRef, useState } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import gantt from 'dhtmlx-gantt';
import { Card } from 'reactstrap';
import "../../assets/css/GanttChart.css"
import { toggleFullscreens } from 'utils/common-helpers';
import { ResizableBox } from 'react-resizable';
import ProjectView from './ProjectView';
import Draggable from 'react-draggable';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { fetchData, FetchPostData } from 'utils/common-fetchData';
import secureLocalStorage from 'react-secure-storage';
import Loader from 'pages/Separate/Loader';
import AddTask from 'pages/WorkModule/Projects/common/AddTask';
import viewImg from "../../assets/images/Inventory/viewImg.jpg"

const GanttChart = () => {
    const ganttContainer = useRef(null);
    const ganttView = useRef(null);
    const [projectView, setProjectView] = useState(false);
    const [boxWidth, setBoxWidth] = useState(0)
    const [noDrag, setNoDrag] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [addTask, setAddTask] = useState(false);

    const token = secureLocalStorage.getItem('auth_token')
    const [isLoading, setLoading] = useState(false)
    const [projectOption, setProjectOption] = useState([])
    const [projectId, setProjectId] = useState([])
    const [ganthData, setGanttData] = useState([])
    const [gantView, setGantView] = useState(false)
    const [taskProjectId, setTaskProjectId] = useState('');
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.88)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    useEffect(() => {
        gantt.plugins({
            marker: true,
            tooltip: true
        });

        gantt.config.grid_resize = true;
        gantt.config.show_errors = false;
        gantt.init(ganttContainer.current);
        gantt.config.row_height = 40;

        gantt.config.columns = [{
            "name": "text",
            "tree": true,
            "width": 176,
            "resize": true,
            template: function (task) {
                if (task.type === "project") {
                    return `<div class="project-side text-white">${task.text}</div>`;
                } else if (task.type === "milestone") {
                    return `<div class="milestione-side">${task.text}</div>`;

                }
                return task.text;
            },

        },
        {
            "name": "start_date",
            "align": "center",
            "resize": true,
            "width": 100,
            template: function (task) {
                if (task.type === "project") {
                    return `<div class="project-side text-white">${moment(task.start_date).format('DD-MM-YYYY')}</div>`;
                } else if (task.type === "milestone") {
                    return `<div class="milestione-side">${moment(task.start_date).format('DD-MM-YYYY')}</div>`;
                }
                return moment(task.start_date).format('DD-MM-YYYY');
            },
        },
        {
            "name": "duration",
            "align": "center",
            "width": 70,
            template: function (task) {
                if (task.type === "project") {
                    return `<div class="project-side text-white">${task.duration}</div>`;
                } else if (task.type === "milestone") {
                    return `<div class="milestione-side">${task.duration}</div>`;

                }
                return task.duration;
            },
        },
        ];

        gantt.attachEvent("onTaskDblClick", function (id, e) {
            return false;
        });
        gantt.templates.tooltip_text = function (start, end, task) {
            if (task.type == 'project') {
                return `<span><b>Project:</b> ${task.text}<br></span>
                        <span><b>Progress:</b> ${Math.round(task?.progress * 100)}%</span>
                        <span><b>Start:</b> ${moment(task.start_date).format('DD-MM-YYYY')}</span>
                        <span><b>End:</b> ${moment(task?.end_date).format('DD-MM-YYYY')}</span>
                        <span><b>Total Hours:</b> ${task?.total_hours} Hrs</span>`;
            } else if (task.type == 'milestone') {
                return `<span><b>Milestone:</b> ${task.text}<br></span>
                        <span><b>Progress:</b> ${Math.round(task?.progress * 100)}%</span>
                        <span><b>Start:</b> ${moment(task.start_date).format('DD-MM-YYYY')}</span>
                        <span><b>End:</b> ${moment(task?.end_date).format('DD-MM-YYYY')}</span>`;
            }
            return `<span><b>Task:</b> ${task.text}<br></span>
                        <span><b>Start:</b> ${moment(task.start_date).format('DD-MM-YYYY')}</span>
                        <span><b>End:</b> ${moment(task?.end_date).format('DD-MM-YYYY')}</span>`;

        };

        gantt.templates.task_class = (start, end, task) => {
            if (task.type === 'project') return 'gantt-project';
            if (task.type === 'milestone') return 'gantt-milestone';
            if (task.type === 'task' && task.id === 23) return 'gantt-task extra-task';
            if (task.type === 'task') return 'gantt-task';
        };


        gantt.templates.task_cell_class = (task, date) => {
            if (task && task.original_end_date) {
                const taskEndDate = new Date(task.end_date);
                const originalEndDate = new Date(task.original_end_date);
                originalEndDate.setDate(originalEndDate.getDate() + 1);

                if (taskEndDate.getTime() > originalEndDate.getTime()) {
                    const currentDate = new Date(originalEndDate);
                    while (currentDate < taskEndDate) {
                        if (date.toDateString() === currentDate.toDateString()) {
                            return "marked-end-date-cell";
                        }
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                }
            }
            return "";
        };


        // Configure Gantt chart settings
        gantt.config.lightbox.sections = [
            { name: "description", height: 70, map_to: "text", type: "textarea", focus: true },
            { name: "time", type: "duration", map_to: "auto" },
        ];

        gantt.config.scale_height = 40;
        gantt.config.scales = [
            { unit: "month", format: "%F, %Y" },
            { unit: "day", step: 1, format: "%j, %D" },
        ];
        gantt.attachEvent("onTaskClick", function (id, e) {
            const task = gantt.getTask(id);
            if (task.type === "milestone") {
                if (e.target.closest('.gantt_add') != null) {
                    setAddTask(true);
                    setTaskProjectId(task.project_id);
                }
            }
            return false;
        });
        gantt.config.drag_progress = false
        gantt.config.drag_project = false;
        gantt.config.drag_links = false;
        gantt.parse({
            data: ganthData,
        });

        const projectTasks = ganthData[0] || [];
        gantt.addMarker({
            start_date: new Date(projectTasks?.start_date), // Ensure valid date
            css: "start-project",
            text: `Start`,
        });
        gantt.addMarker({
            start_date: new Date(projectTasks?.end_date), // Ensure valid date
            css: "end-project",
            text: `End`,
        });

        return () => gantt.clearAll();
    }, [gantView]);

    const handleClose = (status) => {
        setProjectView(status);
    }

    const toggleSidebar = () => {
        if (isCollapsed) {
            gantt.config.grid_width = 350;
        } else {
            gantt.config.grid_width = 0;
        }
        setIsCollapsed(!isCollapsed);
        gantt.render();
    };


    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    useEffect(() => {
        const fetchProjects = async () => {
            const userId = localStorage.getItem('auth_id')
            const res = await fetchData(toggleLoading, `allProjectsByUserId/${userId}`, token);
            if (res?.success) {
                setProjectOption(res?.data?.project2?.map((obj) => ({
                    label: obj?.project_name,
                    value: obj?.id
                })))
            }
        }
        fetchProjects()
    }, [])

    const onFilterData = async () => {
        if (projectId?.length != 0) {
            const formEntries = {
                project_id: projectId?.value
            }
            const res = await FetchPostData(toggleLoading, `ganttChartData`, token, formEntries);
            if (res?.data) {
                setGanttData(transformData(res?.data))
                setGantView(!gantView)
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please select a Project."
            })
        }
    }

    const handleClick = (edit) => {
        setIsEdit(edit);
        if (edit) {
            gantt.config.drag_project = true;
            gantt.config.drag_links = true;

            const actionColumnExists = gantt.config.columns.some(col => col.name === "action");
            if (!actionColumnExists) {
                gantt.config.columns = [
                    ...gantt.config.columns,
                    {
                        name: "action",
                        min_width: 44,
                        max_width: 44,
                        template: (task) => {
                            if (task.type == "milestone") {
                                return `<div class="gantt_add"></div>`;
                            }
                            return '';
                        }
                    }
                ];
            }
        } else {
            gantt.config.drag_mode = false;
            gantt.config.drag_project = false;
            gantt.config.drag_links = false;
            gantt.config.columns = gantt.config.columns.filter(
                (col) => col.name !== "action"
            );
            if (!isEdit && isCollapsed) {
                toggleSidebar();
            }
        }

        gantt.render();
    };
    function transformData(inputArray) {
        let output = [];
        let currentId = 1; // Unique ID counter

        inputArray.forEach(project => {
            // Add the project to the output
            const projectId = currentId++;
            output.push({
                // ...project,
                id: projectId,
                text: project.text,
                progress: project.progress,
                open: true,
                type: project.type,
                start_date: new Date(project?.start_date),
                end_date: new Date(project?.end_date),
                total_hours: project?.total_hours
            });

            project.milestones.forEach(milestone => {
                const milestoneId = currentId++;
                output.push({
                    ...milestone,
                    id: milestoneId,
                    text: `Milestone ${milestone?.milestone_no}`, // Remove HTML tags
                    parent: projectId,
                    progress: milestone.progress,
                    open: true,
                    type: milestone.type,
                });

                milestone.tasks.forEach(task => {
                    output.push({
                        ...task,
                        id: currentId++,
                        text: task.text,
                        parent: milestoneId,
                        start_date: task.start_date ? task.start_date.split('-').reverse().join('-') : null,    
                        duration: 1,
                        progress: task.progress,
                        open: true,
                        type: task.type,

                    });
                });
            });
        });
        return output;
    }

    const getUpdate = (calling) => {
        if (calling) {
            setAddTask(false)
            onFilterData()
        }
    }
    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="page-content">
                <div ref={ganttView} className="bg-white" style={{ width: '100%', height: '100%', minHeight: "600px" }}>
                    <Card className='mb-0'>
                        <div className='d-flex justify-content-between p-3 align-items-center pb-2'>
                            <div className='d-flex gap-3 align-items-center'>
                                <h5 className="text-purple fw-bold font-size-18 mb-0">
                                    Project Gantt Chart
                                </h5>
                            </div>
                            <div className='d-flex  align-items-center justify-content-end col-md-7' >
                                <div className="col-md-12 me-3">
                                    <Select
                                        placeholder="Select Project"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        options={projectOption}
                                        value={projectId}
                                        onChange={(e) => setProjectId(e)}
                                    />

                                </div>
                                <a className={`project-info  me-2 edit-press bg-purple`} onClick={() => onFilterData()}>
                                    <i className="bx bx-filter-alt fs-6"></i>
                                </a>
                                <a className={`project-info  me-2 ${isEdit ? "edit-press bg-purple" : "bg-primary"}`} onClick={() => {
                                    if (projectId?.length != 0) {
                                        handleClick(!isEdit)
                                    } else {
                                        Swal.fire({
                                            icon: "warning",
                                            text: "Please Select Project & Filter to Edit the Gantt Chart View"
                                        })
                                    }
                                }}>
                                    {isEdit ? "Save" : "Edit"}
                                </a>
                                <a className="fs-6 p-2" title='Project Info' onClick={() => setProjectView(true)}><i className="fas fa-info"></i></a>
                                <a className='fs-3 p-2' title='Full Width' onClick={toggleSidebar}><i className={`mdi ${isCollapsed ? "mdi-aspect-ratio" : "mdi-aspect-ratio"}`}></i></a>
                                <a className="p-2" title='FullScreen' onClick={() => toggleFullscreens(ganttView)}><i className="bi bi-arrows-move fs-5"></i></a>
                            </div>
                        </div>
                    </Card>
                    <div className='position-relative'>
                        <div ref={ganttContainer} id="gantt_here" className={`project-gantt ${isEdit == false ? "task-none" : ''} ${ganthData?.length == 0 ? 'd-none' : ''}`} style={{ width: '100%', height: '100%', minHeight: "600px" }}></div>
                    </div>
                    {ganthData?.length == 0 ? (
                        <Card>
                            <div className='d-flex flex-column align-items-center justify-content-center p-3'>
                                <div className='col-md-4 d-flex flex-column align-items-center justify-content-center'>
                                    <img src={viewImg} alt='Image' width={300} />
                                    <p className='text-purple fw-bold text-center lh-base'>🚀 "Your Gantt chart is waiting! Apply project filters to unlock a clear view of your tasks and milestones. 📊✨"</p>
                                </div>
                            </div>
                        </Card>
                    ) : null}
                </div>
                <div
                    className={`note-sticky-form ${projectView ? "d-block" : "d-none"
                        }`}
                >
                    <Draggable
                        handle={`${noDrag ? ".modal-header" : ""}`}
                        cancel={`${noDrag ? ".modal-body" : ""}`}
                    >
                        <ResizableBox
                            width={boxWidth}
                            style={{
                                position: "fixed",
                                top: "1%",
                                left: "4%",
                                zIndex: "1055",
                                backgroundColor: "#fff",
                                maxHeight: "fit-content",
                            }}
                        >
                            <ProjectView handleClose={handleClose} projectOptions={projectOption} projectId={projectId} projectView={projectView} element={false} />
                        </ResizableBox>
                    </Draggable>
                </div>
            </div>

            <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
                <Draggable>
                    <ResizableBox
                        width={900}
                        style={{
                            position: "fixed",
                            top: "10%",
                            right: "2%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                        }}
                    >
                        <div className="modal-header pe-4">
                            <h5 className="modal-title mt-0">Add Task</h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setAddTask(false)
                                }}
                                className="btn-modal-close"
                            >
                                <span aria-hidden="true" className="btn-modal-span">
                                    &times;
                                </span>
                            </button>
                        </div>

                        <AddTask projectId={taskProjectId || null} getUpdate={getUpdate} />
                    </ResizableBox>
                </Draggable>
            </div>
        </div >
    );
};

export default GanttChart;
