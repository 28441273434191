import React, { useEffect, useState } from "react"
import { Card, CardBody, Modal, Table } from "reactstrap"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import zithasLogo from "../../../assets/images/main-zithas.png"
import leaveImg2 from "../../../assets/images/LeaveDetails.jpg"
import Select from "react-select"
import { qaTaskList, TimeOption } from "utils/common-options"
import { Radio } from "antd"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import configure from "configure"
import Swal from "sweetalert2"
import { calculateCompleteTime } from "utils/common-helpers"

const ProjectTesting = () => {
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const [addTask, setAddTask] = useState(false)
    const [showTasks, setShowTask] = useState(false)
    const [qaOption, setQaOption] = useState([])
    const [taskDetails, setTaskDetails] = useState({})
    const token = secureLocalStorage.getItem('auth_token')
    const [task_name, setTaskName] = useState([])
    const [date, setDate] = useState('')
    const [hours, setHours] = useState([])
    const [assign, setAssign] = useState([])
    const [existing, setExisting] = useState('')
    const [allTask, setAllTask] = useState([])
    const [type, setType] = useState('')
    const [taskId, setTaskId] = useState('')
    const [projectOption, setProjectOption] = useState([])
    const [taskType, setTaskType] = useState(false)
    const [projectId, setProjectId] = useState([])

    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    const onSearchNotification = e => {
        e.preventDefault()
        const searchTerm = e.target.value.toLowerCase()
        const filteredData = allData.filter(
            obj =>
                obj.title.toLowerCase().includes(searchTerm) ||
                obj.message.toLowerCase().includes(searchTerm) ||
                obj.module.toLowerCase().includes(searchTerm) ||
                obj.id == searchTerm
        )
        setData(filteredData)
    }
    useEffect(() => {
        const fetchProjectList = async () => {
            const res = await fetchData(
                toggleLoading,
                `sendProjectTestingData`,
                token
            )
            if (res?.success) {
                setData(res?.data?.projects)
                setAllData(res?.data?.projects)
                setQaOption(res?.data?.tester?.map((obj) => ({
                    value: obj.id,
                    label: obj.alias_name
                })))
            }
        }
        fetchProjectList()
    }, [])
    const onGetTasks = async (id, obj) => {
        const res = await fetchData(toggleLoading, `qaTasks/${id}`, token);
        if (res?.success) {
            setAllTask(res?.data)
            setTaskDetails(obj)
            setShowTask(true)
        }
    }

    const onHandleTask = async (e) => {
        e.preventDefault();
        if (task_name?.length != 0 && assign?.length != 0 && hours?.length != 0) {
            const formEntries = {
                task_id: taskId,
                project_id: taskType ? projectId?.value : taskDetails?.project_id,
                task_name: task_name?.value,
                date: date,
                assign: assign?.value,
                hours: hours?.value,
                round_no: taskDetails?.round_no || 0,
                existing: taskDetails?.round_no != 0 ? existing : 1
            }
            const res = await FetchPostData(toggleLoading, `${type == "edit" ? "editQaTasks" : "addQaTasks"}`, token, formEntries)
            if (res?.data?.success) {
                Swal?.fire({
                    icon: 'success',
                    title: `QA Task ${type == "edit" ? 'Edited' : "Added"} Successfully`,
                }).then((data) => {
                    setAddTask(false);
                    setTaskType(false)
                    setTaskName([])
                    setDate('')
                    setHours([])
                    setAssign([])
                    if (type == 'add') {
                        setData(res?.data?.data?.projects)
                        setAllData(res?.data?.projects)
                    } else {
                        setAllTask(res?.data?.data)
                    }
                })
            }else{
                Swal?.fire({
                    icon: 'warning',
                    title:"Oops! Existing Round is not completed Yet."
                })
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Please Fill All the Fields',
            })
        }
    }

    const onDeletTask = (id) => {
        Swal.fire({
            text: "you want to delete this issue!",
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const formEntries = {
                    task_id: id,
                    project_id: taskDetails?.id,
                }
                const res = await FetchPostData(toggleLoading, 'deleteQaTasks', token, formEntries);
                if (res?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Task Deleted Successfully",
                    }).then((data) => {
                        setAllTask(res?.data?.data)
                    })
                }
            }
        })
    }

    const fetchProjects = async () => {
        const userId = localStorage.getItem('auth_id')
        const res = await fetchData(toggleLoading, `allProjectsByUserId/${userId}`, token);
        if (res?.success) {
            setAddTask(true)
            setTaskType(true)
            setExisting('0')
            setType('add')
            setProjectOption(res?.data?.project2?.map((obj) => ({
                label: obj?.project_name,
                value: obj?.id
            })))
        }
    }

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div>
                <Card>
                    <CardBody>
                        <div className="mb-4 d-flex justify-content-between">
                            <div className="d-flex gap-2 col-md-4 align-items-center">
                                <p className="text-purple fs-5 mb-0 fw-bold ">
                                    QA Task Allotment
                                </p>
                            </div>
                            <div className="d-flex gap-2 col-md-4 align-items-center">
                                <button className=" btn btn-save btn-label" onClick={(e) => fetchProjects()}>
                                    <i className="fas fa-plus me-2 label-icon"></i>     Task
                                </button>
                                <input
                                    id="search-bar-0"
                                    type="text"
                                    className="border-radius form-control rounded h-100"
                                    placeholder="Search..."
                                    onChange={e => onSearchNotification(e)}
                                />
                            </div>
                        </div>
                        <div className="responsive-table mb-4 ">
                            <Table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-center header">#</th>
                                        <th className="header col-md-6">Project Name</th>
                                        <th className="header col-md-1">QA Hours</th>
                                        <th className="header col-md-1">QA Tasks</th>
                                        <th className="header col-md-1 text-center">Start Date</th>
                                        <th className="header col-md-1 text-center">End Date</th>
                                        <th className="header col-md-1 text-center">Total Tasks</th>
                                        <th className="header text-center ">Dev Complete</th>
                                        <th className="header col-md-1 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((obj, i) => {
                                        const hours = (time) => {
                                            const hour = time?.split(":")[0];
                                            return parseInt(hour);
                                        }
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">
                                                    <img
                                                        src={`${configure.imgUrl}${obj?.avatar}`}
                                                        width={30}
                                                        height={30}
                                                        alt="User"
                                                        title={obj?.alias_name}
                                                        className="object-fit-cover rounded-circle"
                                                    />
                                                </td>
                                                <td className="">{obj?.project_name}</td>
                                                <td className="text-center">{obj?.qa_used_hrs || 0}/{obj?.qa_hours} </td>
                                                <td>{obj?.qa_tasks?.original?.data?.length || 0}</td>
                                                <td className="text-center">
                                                    {moment(obj?.startdate).format("DD-MM-YYYY")}
                                                </td>
                                                <td className="text-center">
                                                    {moment(obj?.enddate).format("DD-MM-YYYY")}
                                                </td>
                                                <td className="text-center">{obj?.dev_tasks	}</td>
                                                <td className="text-center">{obj?.total_tasks}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <a
                                                            className="btn btn-sm btn-outline-secondary me-1"
                                                            title="Create Task"
                                                            onClick={() => {
                                                                setTaskDetails(obj)
                                                                setType('add')
                                                                setAddTask(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-plus"></i>
                                                        </a>
                                                        <a
                                                            className="btn btn-sm btn-outline-secondary me-1"
                                                            title="Create Task"
                                                            onClick={() => {
                                                                onGetTasks(obj?.id, obj)
                                                            }}
                                                        >
                                                            <i className="fas fa-list"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <Modal isOpen={addTask} size="lg" className="" centered>
                {loading && <Loader />}
                <div className="modal-header pe-4">
                    <h5 className="modal-title mt-0">Create Task for QA</h5>

                    <button
                        type="button"
                        onClick={() => {
                            setAddTask(false)
                            setTaskType(false)
                        }}
                        className="btn-modal-close"
                    >
                        <span aria-hidden="true" className="btn-modal-span">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body pe-4 ">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-5 d-flex flex-column justify-content-end align-items-center ps-3 gap-4">
                            <div className="text-center">
                                <img src={leaveImg2} alt="Addepe" style={{ width: "70%" }} />
                            </div>
                        </div>
                        <div className="col-md-7 d-flex flex-column justify-content-start  gap-3">
                            <form className="row" onSubmit={onHandleTask}>
                                {!taskType ? (
                                    <div className="d-flex gap-3 mb-3">
                                        <a className="d-flex gap-3 align-items-center general-box ">
                                            <h5 className="text-purple mb-0">Total Hours : </h5>
                                            <h5 className="text-primary mb-0">{taskDetails?.qa_hours}</h5>
                                        </a>
                                        <a className="d-flex gap-3 align-items-center general-box ">
                                            <h5 className="text-purple mb-0">Used Hours : </h5>
                                            <h5 className="text-primary mb-0">15</h5>
                                        </a>
                                    </div>
                                ) : null}
                                {taskType ? (
                                    <div className="col-md-12 pb-3">
                                        <label htmlFor="formrow-firstname-Input" className="form-label">
                                            Project  <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            placeholder="Select Project"
                                            className="basic-multi-select"
                                            classNamePrefix=" select"
                                            options={projectOption}
                                            value={projectId}
                                            onChange={(e) => setProjectId(e)}
                                        />
                                    </div>
                                ) : null}
                                <div className="col-md-12 pb-3">
                                    <label htmlFor="formrow-firstname-Input" className="form-label">
                                        Task Name <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        placeholder="Select Task"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        options={taskType ? qaTaskList?.filter((obj) => obj?.type != 2) : qaTaskList}
                                        value={task_name}
                                        onChange={(e) => setTaskName(e)}
                                    />
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label htmlFor="formrow-firstname-Input" className="form-label">
                                        Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={date}
                                        required
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 ps-2">
                                    <label htmlFor="formrow-firstname-Input">
                                        QA Assigne <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        placeholder="Select QA"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        options={qaOption}
                                        value={assign}
                                        onChange={(e) => setAssign(e)}
                                    />
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label htmlFor="formrow-firstname-Input" className="form-label">
                                        Select Time <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        placeholder="Select Time"
                                        className="basic-multi-select"
                                        classNamePrefix=" select"
                                        options={TimeOption}
                                        value={hours}
                                        onChange={(e) => setHours(e)}
                                    />
                                </div>
                                {!taskType ? (
                                    <>
                                        {type == 'add' ? (
                                            <div className="col-md-12 pb-3">
                                                {taskDetails?.round_no != 0 ? (
                                                    <>
                                                        <span className="text-purple">Currently, you are in Round 1. Would you like to proceed with the same round or initiate a new one?</span>
                                                        <div className="mt-2">
                                                            <Radio.Group
                                                                name="radiogroup"
                                                                value={existing}
                                                                options={[
                                                                    { value: 0, label: 'Existing' },
                                                                    { value: 1, label: 'New' },
                                                                ]}
                                                                onChange={(e) => setExisting(e.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <span className="text-purple">The first round will be initiated upon the creation of this task.</span>
                                                )}
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                                <div className="my-2 text-end">
                                    <button className=" btn btn-save btn-label">
                                        <i className="fas fa-save me-2 label-icon"></i>Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={showTasks} size="xl" className="" centered>
                {loading && <Loader />}
                <div className="modal-header pe-4">
                    <h5 className="modal-title mt-0">QA Task List</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setShowTask(false)
                        }}
                        className="btn-modal-close"
                    >
                        <span aria-hidden="true" className="btn-modal-span">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body pe-4 ">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-4 d-flex flex-column justify-content-end align-items-center ps-3 gap-4">
                            <div className="text-center">
                                <img src={leaveImg2} alt="Addepe" style={{ width: "90%" }} />
                            </div>
                        </div>
                        <div className="col-md-8 d-flex flex-column justify-content-start  gap-3">
                            <div className="d-flex gap-3">
                                <a className="d-flex gap-3 align-items-center general-box "><h5 className="text-purple mb-0">Total Hours : </h5><h5 className="text-primary mb-0">{taskDetails?.qa_hours} Hrs</h5></a>
                                <a className="d-flex gap-3 align-items-center general-box "><h5 className="text-purple mb-0">Used Hours : </h5><h5 className="text-primary mb-0">{calculateCompleteTime(allTask, 'hours')}</h5></a>
                            </div>
                            <div className="responsive-table mb-4 leave-history-slots">
                                <Table className="table table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="header">Id</th>
                                            <th className="header col-md-7">Tasks</th>
                                            <th className="header col-md-1">Hours</th>
                                            <th className="header col-md-1">Date</th>
                                            <th className="header col-md-1">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allTask?.reverse()?.map((obj, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{obj?.id}</td>
                                                    <td>
                                                        <img
                                                            src={`${configure.imgUrl}${obj?.avatar}`}
                                                            width={30}
                                                            height={30}
                                                            title={obj?.alias_name}
                                                            className="object-fit-cover rounded-circle me-2"
                                                        />
                                                        {obj?.task_name}</td>
                                                    <td>{obj?.hours}</td>
                                                    <td>{moment(obj?.date).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        {/* {i == 0 ? ( */}
                                                        <div className="d-flex">
                                                            <a title="Edit task" className="btn btn-sm btn-outline-secondary me-2" onClick={(e) => {
                                                                setTaskName(qaTaskList?.find((list) => list?.value == obj?.task_name))
                                                                setHours(TimeOption?.find((list) => list?.value == obj?.hours))
                                                                setDate(obj?.date)
                                                                setAssign(qaOption?.find((list) => list?.value == obj?.assign))
                                                                setTaskId(obj?.id)
                                                                setType('edit')
                                                                setAddTask(true)
                                                            }}>
                                                                <i className="fas fa-pen"></i>
                                                            </a>
                                                            <a title="Delete Task" className="btn btn-sm btn-outline-danger" onClick={() => onDeletTask(obj?.id)}>
                                                                <i className="fas fa-trash"></i>
                                                            </a>
                                                        </div>
                                                        {/* ) : null} */}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                {allTask?.length == 0 ? (
                                    <p className="mt-2 text-purple text-center fw-bold"> Oops! Data Not Found</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default ProjectTesting
