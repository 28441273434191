import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import BasicProjectDetails from "./BasicProjectDetails"
import Milestones from "./Milestones"
import Tasks from "./Tasks"
import Notes from "./Notes"
import ActivityLog from "./ActivityLog"
import Loader from "pages/Separate/Loader"
import { useParams } from "react-router-dom"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import Credential from "./Credential"
import ProjectOverview from "./ProjectOverview"
import AddTask from "../common/AddTask"
import moment from "moment"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
// components

const EditProject = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [addTask, setAddTask] = useState(false)
  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setAddTask(false)
  })
  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const params = useParams()
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const [projectDetail, setProjectDetail] = useState({})
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const getProjectDetails = async () => {
    const res = await fetchData(
      toggleLoading,
      `projectView/${params.id}`,
      token
    )
    if (res?.success) {
      setProjectDetail(res?.data?.project[0])
    }
  }
  useEffect(() => {
    if (verticalActiveTab == 1) {
      getProjectDetails()
    }
  }, [verticalActiveTab])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Work"} breadcrumbItem={"Edit Project"} />
          <Row className="py-4 shadow mb-3 bg-white me-0 d-flex justify-space-between align-items-center">
            <div className="col-md-11 d-flex justify-content-start align-items-center">
              <h4 className="mb-0">
                <b>Project Title - </b> {projectDetail?.project_name}
              </h4>
              <div className="ms-4 border-left border-primary ps-2">
                <h5 className="mb-0">
                  Total Allocated hrs :{" "}
                  <span className="text-primary">{projectDetail?.total_hrs}</span>
                </h5>
              </div>
              <div className="ms-4 border-left border-primary ps-2">
                <h5 className="mb-0">
                  Deadline Date :{" "}
                  <span className="text-primary">
                    {moment(projectDetail?.enddate).format("DD MMM, YYYY") ||
                      "-"}
                  </span>
                </h5>
              </div>
            </div>
            <div className="col-md-1 text-end">
              <a
                onClick={() => setAddTask(true)}
                className="s btn text-white me-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
              </a>
            </div>
          </Row>
          <Row className="justify-content-between d-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                 ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Project Overview
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                    disabled={projectDetail?.roll_out == 0}
                  >
                    Milestones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                    disabled={projectDetail?.roll_out == 0}
                  >
                    Tasks
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Credential
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-project">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ProjectOverview projectDetail={projectDetail} />
                  </TabPane>

                  <TabPane tabId="7">
                    <BasicProjectDetails
                      verticalActiveTab={verticalActiveTab}
                      projectDetails={projectDetail}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <Tasks
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Notes
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                      edit={true}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Milestones
                      verticalActiveTab={verticalActiveTab}
                      toggleLoading={toggleLoading}
                      projectDetail={projectDetail}
                      edit={true}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <Credential projectDetail={projectDetail}/>
                  </TabPane>

                  <TabPane tabId="5">
                    <ActivityLog
                      projectDetail={projectDetail}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Add Task</h5>
              <button
                type="button"
                onClick={() => {
                  setAddTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <AddTask projectId={params?.id} />
          </ResizableBox>
        </Draggable>
      </div>
    </React.Fragment>
  )
}

export default EditProject
