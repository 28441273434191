import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import TaskScreen from './TaskScreen';
import SimpleBar from "simplebar-react"
import classNames from 'classnames';
import { fetchData, FetchPostData } from 'utils/common-fetchData';
import secureLocalStorage from 'react-secure-storage';
import Loader from 'pages/Separate/Loader';
import { trimProjectName } from 'utils/common-helpers';
import { off } from 'devextreme/events';
const ResourceIndex = () => {
    const [verticalActiveTab, setverticalActiveTab] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [projectOption, setProjectOption] = useState([])
    const [projectDetails, setProjectDetail] = useState({})
    const token = secureLocalStorage.getItem("auth_token")
    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    const [isIndex, setIsIndex] = useState(false)
    const handleHover = () => {
        setIsIndex(true)
    }
    const handleLeave = () => {
        setIsIndex(false)
    }
    const fetchProjects = async (type) => {
        const user_id = localStorage.getItem('auth_id')
        const roleId = secureLocalStorage.getItem('auth_role')
        const res = await fetchData(
            toggleLoading,
            `unplanProjects/${user_id}/${roleId}`,
            token
        )
        if (res?.success) {
            const projects = res?.data?.projects
            if (type != 2) {
                setverticalActiveTab(projects[0]?.id)
                setProjectDetail(projects[0])
            }
            setProjectOption(projects)
        }
    }
    useEffect(() => {
        fetchProjects()
    }, [])

    const onUpdateDetails = (call, type) => {
        if (call) {
            fetchProjects(type);
        }
    }
    return (
        <React.Fragment>
            {isLoading && (<Loader />)}
            <div className="page-content mb-4">
                <Container fluid >
                    <Breadcrumbs
                        title={"Project Plan"}
                        breadcrumbItem={"Development"}
                    />
                    <div id="credentialPage">
                        <Row >
                            <div
                                className={`px-0 sidebar-containerr `}
                                style={isIndex ? { zIndex: "2" } : {}}
                            >
                                <div className="position-relative">
                                    <div>
                                        <SimpleBar
                                            style={{ height: "70vh" }}
                                        >
                                            <Nav
                                                pills
                                                className={`credtabFix-ul  flex-column px-1 py-1 h-100s`}
                                            >
                                                {projectOption?.map((obj, i) => {
                                                    return (
                                                        <NavItem
                                                            className={classNames({
                                                                "d-flex flex-column mb-2 dm-planing-h": true,
                                                                active: verticalActiveTab == obj.id,
                                                            })}
                                                            key={i}
                                                        >
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classNames({
                                                                    "text-center crendential": true,
                                                                    active: verticalActiveTab == obj.id,
                                                                })}
                                                                onClick={() => {
                                                                    setProjectDetail(projectOption?.find((pro) => pro?.id == obj.id))
                                                                    setverticalActiveTab(obj.id)
                                                                    onUpdateDetails(true, 2)
                                                                }}
                                                                onMouseEnter={handleHover}
                                                                onMouseLeave={handleLeave}
                                                            >
                                                                {trimProjectName(obj?.project_name)}
                                                                <span className="link_name border-start border-white border-2" style={{ width: "250px" }}>
                                                                    {obj?.project_name}
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })}
                                            </Nav>
                                        </SimpleBar>
                                    </div>
                                </div>
                            </div>
                            <Col md={"11"}
                                className={`z-1 pe-0 credential-table width-94 ps-0`}>
                                <TaskScreen projectDetails={projectDetails} toggleLoading={toggleLoading} onUpdate={onUpdateDetails} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ResourceIndex;