import React, { useEffect, useState } from "react"
import { Container, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import Loader from "pages/Separate/Loader"
const Credential = ({ projectDetail }) => {
  //   All useState
  const [isLoading, setLoading] = useState(false)
  const [custType, setCustType] = useState("")
  const [gitProject, setGitProject] = useState("New")
  const [ztplDetails, setZtplDetails] = useState("New")
  const [localServer, setLocalServer] = useState("New")
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  const [detailsFromCred, setDetailsFromCred] = useState(null)
  const [detailsFromCred2, setDetailsFromCred2] = useState(null)

  const [allZtplCredOption, setAllZtplCredOption] = useState([])
  const [all192CredOption, setAll192CredOption] = useState([])

  const [projectFromGitOption, setProjectFromGitOption] = useState([])
  const [projectFromGit, setProjectFromGit] = useState(null)
  const [projectFromGitName, setProjectFromGitName] = useState("")
  const [gitRepositoryLink, setGitRepositoryLink] = useState("")
  const [gitDemoLink, setGitDemoLink] = useState("")
  const [gitIcodeLink, setGitIcodeLink] = useState("")

  // erver Details states
  const [serverFolderName, setServerFolderName] = useState("")
  const [server192Link, setServer192Link] = useState("")
  const [serverZTPLLink, setServerZTPLLink] = useState("")
  const [zhost, setZhost] = useState("ftp.ztpl.net")
  const [zusername, setZusername] = useState("")
  const [zpassword, setZpassword] = useState("")
  const [zdatabasename, setZdatabasename] = useState("")
  const [zdbusername, setZdbusername] = useState("")
  const [zdbpassword, setZdbpassword] = useState("")

  // 192 Details
  const [host, setHost] = useState("192.168.0.2")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [databasename, setDatabasename] = useState("")
  const [dbusername, setDbusername] = useState("")
  const [dbpassword, setDbpassword] = useState("")
  const handleZtplDfc = selectedOption => {
    setDetailsFromCred(selectedOption)
    setZusername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setZpassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setZdatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setZdbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setZdbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const handle192Dfc = selectedOption => {
    setDetailsFromCred2(selectedOption)
    setUsername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setPassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setDatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setDbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setDbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const handleGitPeoject = selectedOption => {
    setProjectFromGit(selectedOption)
    setGitRepositoryLink(
      selectedOption.gitreposatirylink ? selectedOption.gitreposatirylink : ""
    )
    // setGitDemoLink(selectedOption.demolink)
    // setGitIcodeLink(selectedOption.icode)
  }

  const handleFolder = e => {
    setServerFolderName(e.target.value)
    if (e.target.value) {
      setServer192Link(`http://192.168.0.2/${e.target.value}`)
      setServerZTPLLink(`https://ztpl.net/${e.target.value}`)
    } else {
      setServer192Link("")
      setServerZTPLLink("")
    }
  }

  useEffect(() => {
    setGitRepositoryLink(projectDetail?.gitreposatirylink)
    setServerFolderName(projectDetail?.folder_name)
    setServer192Link(`http://192.168.0.2/${projectDetail?.folder_name}`)
    setServerZTPLLink(`https://ztpl.net/${projectDetail?.folder_name}`)

    setGitDemoLink(projectDetail?.demolink)
    setGitIcodeLink(projectDetail?.icode)

    setZhost(projectDetail?.zhostlink)
    setZdatabasename(projectDetail?.zdatabasename)
    setZdbusername(projectDetail?.zdbusername)
    setZusername(projectDetail?.zusername)
    setZpassword(projectDetail?.zpassword)
    setZdbpassword(projectDetail?.zdbpassword)

   setHost(projectDetail?.hostlink)
   setUsername(projectDetail?.username)
   setPassword(projectDetail?.password)
   setDatabasename(projectDetail?.databasename)
   setDbusername(projectDetail?.dbusername)
   setDbpassword(projectDetail?.dbpassword)
  },[])


  return (
    <React.Fragment>
      <div className=" mb-3">
        <Container fluid>
          <Form className="px-0">
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="col-md-12 d-flex justify-content-between">
                  <div className="col-md-6 d-flex align-items-center">
                    <h5 className=" text-dark mb-0">
                      Project Credential Details
                    </h5>
                  </div>
                  {/* <div className="col-md-6 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        navigate(-1)
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div> */}
                </div>
              </Row>
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">
                      Git Details (if yes then it will update on git)
                    </h5>
                    <div className="col-12 mb-3 mb-md-0">
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitYes"
                          onInput={e => {
                            setCustType("Yes")
                            setGitProject("New")
                            setProjectFromGit(null)
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="Yes"
                        />
                        <label htmlFor="gitYes" className="ms-2">
                          Yes
                        </label>
                      </span>
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitNo"
                          defaultChecked={true}
                          onInput={e => {
                            setProjectFromGitName("")
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="No"
                        />
                        <label htmlFor="gitNo" className="ms-2">
                          No
                        </label>
                      </span>
                    </div>
                    {custType === "Yes" ? (
                      <div className="col-12 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            type="radio"
                            id="newCheck"
                            defaultChecked={true}
                            onInput={e => {
                              setProjectFromGit(null)
                              setGitProject(e.target.value)
                            }}
                            name="type1"
                            value="New"
                          />
                          <label htmlFor="newCheck" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => {
                              setProjectFromGitName("")
                              setGitProject(e.target.value)
                            }}
                            id="existing"
                            name="type1"
                            value="Existing"
                          />
                          <label htmlFor="existing" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {custType === "Yes" && gitProject === "New" ? (
                      <div className="col-md-12 mt-2 mb-3 mb-md-0">
                        <label htmlFor="">Project Name (for git)</label>
                        <input
                          type="text"
                          placeholder="Enter Project Name"
                          className="form-control "
                          value={projectFromGitName}
                          onChange={e => setProjectFromGitName(e.target.value)}
                          disabled={gitProject == "New"}
                        />
                      </div>
                    ) : (
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Project from Git <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Project"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={projectFromGitOption}
                        value={projectFromGit}
                        onChange={selectedOption =>
                          handleGitPeoject(selectedOption)
                        }
                      />
                    </div>
                   )} 

                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Links</h5>
                      <div className="col-md-12 d-flex flex-column gap-3">
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Git Repository Link</label>
                          <input
                            type="text"
                            placeholder="Enter Git Repository Link"
                            className="form-control "
                            value={gitRepositoryLink}
                            onChange={e => setGitRepositoryLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Demo Link</label>
                          <input
                            type="text"
                            placeholder="Enter Demo Link"
                            className="form-control "
                            value={gitDemoLink}
                            onChange={e => setGitDemoLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Icode Link</label>
                          <input
                            type="text"
                            placeholder="Enter Icode Link"
                            className="form-control "
                            value={gitIcodeLink}
                            onChange={e => setGitIcodeLink(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3 pb-4">
                  <Row className="bg-white d-flex flex-column gap-4 px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-space-between align-items-end">
                      <div className="col-md-3 mt-2  mb-md-0 d-flex justify-content-start align-items-start">
                        <h5 className="mb-3">Server Details</h5>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        Enter Folder Name for creating folder on 192 & ZTPL{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="Enter Folder Name"
                        className="form-control "
                        value={serverFolderName}
                        onChange={e => handleFolder(e)}
                      />
                    </div>

                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        192 server Link <span className="text-danger">*</span>
                      </label>
                      <input
                        readOnly
                        required
                        type="text"
                        placeholder="Enter 192 server Link"
                        className="form-control"
                        value={server192Link}
                        onChange={e => setServer192Link(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        ZTPL Link <span className="text-danger">*</span>
                      </label>
                      <input
                        readOnly
                        required
                        type="text"
                        placeholder="Enter ZTPL Link"
                        className="form-control"
                        value={serverZTPLLink}
                        onChange={e => setServerZTPLLink(e.target.value)}
                      />
                    </div>
                  </Row>
                </div>
              </Row>
              {/* Database forms */}
              <Row className="px-0">
                {/* .. ZTPL Details  */}
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">ZTPL Details</h5>
                    <div className="row">
                      <div className="col-md-6 mb-2 d-flex">
                        <div className="col-md-12 mb-3 mb-md-0">
                          <span className="me-4 ">
                            <input
                              defaultChecked={true}
                              type="radio"
                              id="new"
                              onInput={e => setZtplDetails(e.target.value)}
                              name="ztpl"
                              value="New"
                            />
                            <label htmlFor="new" className="ms-2">
                              New
                            </label>
                          </span>
                          <span>
                            <input
                              type="radio"
                              onInput={e => setZtplDetails(e.target.value)}
                              id="existing2"
                              name="ztpl"
                              value="existing"
                            />
                            <label htmlFor="existing2" className="ms-2">
                              {" "}
                              Existing
                            </label>
                          </span>
                        </div>
                      </div>
                      {ztplDetails === "existing" && (
                      <div className="col-md-6 mb-md-0 pe-0">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="">
                            Details from credentials{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Select
                            placeholder="Select Details"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            styles={colourStyles}
                            options={allZtplCredOption}
                            value={detailsFromCred}
                            onChange={selectedOption =>
                              handleZtplDfc(selectedOption)
                            }
                          />
                        </div>
                      </div>
                      )} 
                    </div>

                    <div className="col-md-6 border-end">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Host Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Enter Host Link"
                          className="form-control "
                          value={zhost}
                          onChange={e => setZhost(e.target.value)}
                          disabled={ztplDetails == "New"}
                        />
                      </div>
                      {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zusername}
                          onChange={e => setZusername(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={zpassword}
                          onChange={e => setZpassword(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={zdatabasename}
                          onChange={e => setZdatabasename(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zdbusername}
                          onChange={e => setZdbusername(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Database Password"
                          className="form-control "
                          value={zdbpassword}
                          onChange={e => setZdbpassword(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                    </div>
                  </Row>
                </div>

                {/* 192 Details */}
                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">192 Details</h5>
                    <div className="row">
                      <div className="col-md-6 mb-2 d-flex">
                        <div className="col-md-12 mb-3 mb-md-0">
                          <span className="me-4 ">
                            <input
                              type="radio"
                              id="new1"
                              defaultChecked={true}
                              onInput={e => setLocalServer(e.target.value)}
                              name="local"
                              value="New"
                            />
                            <label htmlFor="new1" className="ms-2">
                              New
                            </label>
                          </span>
                          <span>
                            <input
                              // defaultChecked={true}
                              type="radio"
                              onInput={e => setLocalServer(e.target.value)}
                              id="existing5"
                              name="local"
                              value="existing"
                            />
                            <label htmlFor="existing5" className="ms-2">
                              {" "}
                              Existing
                            </label>
                          </span>
                        </div>
                      </div>

                      {localServer === "existing" && (
                      <div className="col-md-6 mb-md-0">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="">
                            Details from credentials{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Select
                            placeholder="Select Details"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            styles={colourStyles}
                            options={all192CredOption}
                            value={detailsFromCred2}
                            onChange={selectedOption =>
                              handle192Dfc(selectedOption)
                            }
                          />
                        </div>
                      </div>
                       )} 
                      {/* {localServer == "New" ? (
                        <div className="col-md-6 mb-3 d-flex justify-content-end align-items-end">
                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onFtpCreated2}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-person-fill-add label-icon"></i>{" "}
                              Create FTP
                            </button>
                          </div>
                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onDbCreated2}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-database-fill-add label-icon"></i>{" "}
                              Create DB
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div className="col-md-6 border-end">
                      {localServer == "New" ? (
                        <>
                          <div className="col-md-12 mt-3 mb-4 mb-md-0">
                            <label htmlFor="192host">
                              FTP Host Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              required
                              readOnly
                              id="192host"
                              type="text"
                              placeholder="Host Link"
                              className="form-control "
                              value={host}
                              onChange={e => setHost(e.target.value)}
                              disabled={localServer == "New"}

                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-md-12 mt-3 mb-4 mb-md-0">
                          <label htmlFor="">
                            FTP Host Name <span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            readOnly
                            type="text"
                            placeholder="Enter Host Link"
                            className="form-control "
                            value={host}
                            onChange={e => setHost(e.target.value)}
                          

                          />
                        </div>
                      )}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={databasename}
                          onChange={e => setDatabasename(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={dbusername}
                          onChange={e => setDbusername(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Database Password"
                          className="form-control "
                          value={dbpassword}
                          onChange={e => setDbpassword(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                    </div>

                    {/* </div> */}
                  </Row>
                </div>
              </Row>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Credential
