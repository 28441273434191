import React, { useState } from 'react'
import applyLeaveImg from "../../../../assets/images/applyforleave.jpg"
import Swal from 'sweetalert2'
import { Form } from 'reactstrap'
import { FetchPostData } from 'utils/common-fetchData'
import secureLocalStorage from 'react-secure-storage'
import Loader from 'pages/Separate/Loader'
import { getDaysBetweenDates } from 'utils/common-helpers'

const ApplyLeave = ({ onCloseModal, updateData, userDetail }) => {
    const [halfDay, setHalfDay] = useState('')
    const [leaveBegins, setLeaveBegins] = useState('')
    const [leaveEnd, setLeaveEnd] = useState('')
    const [leaveType, setLeaveType] = useState('')
    const [reason, setReason] = useState('')
    const [comments, setComments] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [file, setFile] = useState('')
    const token = secureLocalStorage.getItem('auth_token')
    const leave_options = [
        { label: "Select Leave Type", value: "" },
        { label: "Sick Leave", value: "1" },
        { label: "Earned Leave", value: "2" },
        { label: "Casual Leave", value: "3" },
    ]
    const Reason_options = [
        { label: "Select Reason", value: "1" },
        { label: "Annual Leave", value: "2" },
        { label: "Maternity Leave", value: "3" },
        { label: "Other Leave", value: "4" },
    ]
    const handleDateChange = e => {
        const date = new Date(e.target.value)
        const today = new Date()
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(today.getDate() - 8)
        if (date >= sevenDaysAgo && date <= today) {
            Swal.fire({
                title: "Earned Leave & Casual Leave apply before 1 week",
                text: "It would be high chance that your leave will be disapprove as you are not applying 1 week before.",
                icon: "warning",
                confirmButtonText: "Understood",
            })
        }
        setLeaveBegins(e.target.value)
    }

    const handleLeaveTypeChange = e => {
        if (userDetail?.casual_leave == 0 && e.target.value == "3") {
            Swal.fire({
                title: "Insufficient Casual Leave Balance",
                text: "Oops! It seems you dont have enough casual leave balance for this request. Please check your balance and try again. Thank you!",
                icon: "warning",
                confirmButtonText: "Continue",
            })
        } else if (userDetail?.sick_leave == 0 && e.target.value == "1") {
            Swal.fire({
                title: "Insufficient Sick Leave Balance",
                text: "Oops! It seems you dont have enough sick leave balance for this request. Please check your balance and try again. Thank you!",
                icon: "warning",
                confirmButtonText: "Continue",
            })
        } else if (userDetail?.earned_leave == 0 && e.target.value == "2") {
            Swal.fire({
                title: "Insufficient Earned Leave Balance",
                text: "Oops! It seems you dont have enough earned leave balance for this request. Please check your balance and try again. Thank you!",
                icon: "warning",
                confirmButtonText: "Continue",
            })
        }
        setLeaveType(e.target.value)
    }
    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    const onAddLeave = async (e) => {
        e.preventDefault();
        const formEntires = {
            user_id: localStorage.getItem('auth_id'),
            half_day: halfDay == true ? 1 : 0,
            leave: leaveType,
            file: file,
            start_date: leaveBegins,
            end_date: leaveEnd,
            reason: comments
        }
        const res = await FetchPostData(toggleLoading, 'addLeaveData', token, formEntires);
        if (res?.data?.success) {
            Swal.fire({
                icon: "success",
                text: "Leave Request Added Successfully",
            }).then((data) => {
                setHalfDay('')
                setLeaveBegins('')
                setLeaveEnd('')
                setReason('')
                setComments('')
                onCloseModal(false)
                updateData(true)
            })
        }
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="modal-body pe-4 my-3">
                <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddLeave}>
                    <div className="col-md-5 d-flex flex-column justify-content-end align-items-center ps-3 gap-4 pe-0">
                        <div className="text-center">
                            <img
                                src={applyLeaveImg}
                                alt="applyLeaveImg"
                                style={{ width: "80%" }}
                            />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column justify-content-start  gap-4 ps-0">
                        <div className="col-md-12 d-flex flex-row mb-3 mt-1 gap-3">
                            <div className="col-md-12 d-flex justify-content-start align-items-center gap-3">
                                <h5 className="mb-0">Leave Balance :</h5>
                                <div className="d-flex gap-3 justify-content-start align-items-center">
                                    <span className="mb-0 px-3 py-1  bg-primary rounded text-light">
                                        <b>
                                            {" "}
                                            EL : <span>{userDetail?.earned_leave}</span>
                                        </b>
                                    </span>
                                    <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                                        <b>
                                            CL : <span>{userDetail?.casual_leave}</span>
                                        </b>
                                    </span>
                                    <span className="mb-0 px-3 py-1 rounded  bg-primary text-light">
                                        <b>
                                            SL : <span>{userDetail?.sick_leave}</span>
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* Row 1 */}
                        <div className="col-12 d-flex gap-3 ">
                            <div className="col-4">
                                <label htmlFor="">Employee Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={userDetail?.name}
                                />
                            </div>
                            <div className="col-3">
                                <label htmlFor="">Employee Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={userDetail?.employee_code}
                                    disabled
                                />
                            </div>
                            <div className="col-2">
                                <label htmlFor="">Punch Id</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={userDetail?.punch_id}
                                />
                            </div>
                            <div className="col-2">
                                <label htmlFor="">Half Day</label><br />
                                <input
                                    type="checkbox"
                                    className="ms-2"
                                    onChange={(e) => {
                                        setHalfDay(e.target.checked)
                                        if (e.target.checked) {
                                            Swal.fire({
                                                title: "Half Day Timing Rule!",
                                                html: `1) If you are taking 1st half day leave, you need to reach office between 12:00 PM to 12:30 PM.<br>2) If you are taking 2nd half day leave, you need to leave office between 2:30 PM to 3:00 PM (Lunch Break allowed for 15 mins only)`,
                                                icon: "info",
                                                confirmButtonText: "Understood",
                                            })
                                        }
                                    }}
                                ></input>
                            </div>
                        </div>

                        {/* Row 2 */}
                        <div className="d-flex flex-column ">
                            <div className="col-md-12 d-flex gap-2 ">
                                <div className="col-3">
                                    <label htmlFor="">
                                        {" "}
                                        Leave to begin on{" "}
                                        <span className="text-danger">*</span>{" "}
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={leaveBegins}
                                        required
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="">
                                        {" "}
                                        Leave Ends on <span className="text-danger">
                                            *
                                        </span>{" "}
                                    </label>
                                    <input type="date"
                                        className="form-control"
                                        value={leaveEnd}
                                        onChange={(e) => setLeaveEnd(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-3">
                                    <label htmlFor="">
                                        Type of leave <span className="text-danger">*</span>{" "}
                                    </label>
                                    <select
                                        className="border-radius form-select"
                                        id="leave-type"
                                        required
                                        value={leaveType}
                                        onChange={handleLeaveTypeChange}
                                    >
                                        {leave_options.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-3">
                                    <label htmlFor="">
                                        Number Of Days <span className="text-danger">*</span>{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "70%" }}
                                        value={getDaysBetweenDates(leaveEnd, leaveBegins) || 0}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Row 3 */}
                        <div className="col-md-12 d-flex gap-2 ">
                            <div className="col-6">
                                <label htmlFor="">
                                    Reason <span className="text-danger">*</span>{" "}
                                </label>
                                <select
                                    className="border-radius form-select"
                                    id="reason-type"
                                    value={reason}
                                    required
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                    {Reason_options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {leaveType == "1" ? (
                                <div className="col-6">
                                    <label htmlFor=""> Medical Certificate </label>
                                    <input type="file" className="form-control"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </div>
                            ) : null}
                        </div>

                        {/* Row 4 */}
                        <div className="col-md-12 d-flex gap-2">
                            {reason != '' ? (
                                <div className="col-12">
                                    <label htmlFor="">
                                        {" "}
                                        Comment <span className="text-danger">*</span>{" "}
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        rows="4"
                                        value={comments}
                                        required
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-12 text-end mt-2">
                        <button className=" btn btn-save btn-label" type="submit">
                            <i className="fas fa-save me-2 label-icon"></i>
                            Submit
                        </button>
                        <a className=" btn ms-md-2 btn-red btn-label" onClick={() => onCloseModal(false)}>
                            <i className="dripicons-cross me-2 icon-center label-icon"></i>
                            CANCEL
                        </a>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ApplyLeave
