import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "components/Common/Breadcrumb"
import TestOverview from "./TestOverview"
import CaseTask from "./CaseTask"
import CaseMilestones from "./CaseMilestones"
import TestCases from "./TestCases"

const CaseIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Support | Zithas Technologies"

  const sidebars = [
    {
      id: "1",
      text: "Test Cases",
    },
    {
      id: "2",
      text: "Overview",
    },
    {
      id: "4",
      text: "Milestone & Scopes",
    },
    {
      id: "3",
      text: "Task List",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumb
            title={"Create Test Cases"}
            breadcrumbItem={"Test Cases"}
          />
          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                {sidebars?.map((obj, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === obj?.id,
                        })}
                        onClick={() => {
                          setverticalActiveTab(obj?.id)
                        }}
                      >
                        {obj.text}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-lead">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <TestCases verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="2">
                    <TestOverview verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="4">
                    <CaseMilestones verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="3">
                    <CaseTask verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CaseIndex
