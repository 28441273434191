import moment from "moment";
import Loader from "pages/Separate/Loader";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Container } from "reactstrap";
import { fetchData } from "utils/common-fetchData";
import { issueType, locationOptions, ticketStatus } from "utils/common-options";

const TicketOverview = ({ verticalActiveTab, ticket_id }) => {
  const token = secureLocalStorage.getItem("auth_token");
  const [isLoading, setLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetchData(
        toggleLoading,
        `ticketDetailData/${ticket_id}`,
        token
      );
      if (res?.success) {
        console.log(res);
        setTicketDetails(res?.data[0]);
      }
    };
    if (verticalActiveTab == 2) {
      fetchProjects();
    }
  }, [verticalActiveTab, ticket_id]);
  return (
    <div>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          <div className="row">
            <div className="col-lg-12 ps-0">
              <div className="card">
                <div className="card-body pt-0">
                  <h5 className="text-dark mb-4">Tickets Overview</h5>
                  <div className="d-flex">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-purple fw-bold font-size-18">
                        {ticketDetails?.subject}
                      </h5>
                      <p>
                        <strong>Project</strong> : {ticketDetails?.project_name}{" "}
                        ,<strong>Status</strong> :{" "}
                        {
                          ticketStatus?.find(
                            (obj) => obj?.value == ticketDetails?.status
                          )?.label
                        }
                      </p>
                    </div>
                  </div>
                  <h5 className="font-size-15 mt-4">Ticket Description :</h5>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: ticketDetails?.description,
                    }}
                  />

                  <div className="d-flex gap-4 mt-5 ">
                    <div className="text-muted  ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Assigne</span> :
                        {ticketDetails?.alias_name}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Location</span> :
                        {
                          locationOptions?.find(
                            (obj) => obj?.value == ticketDetails?.location
                          )?.label
                        }
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        <span className="text-purple fw-bold">Type</span> :{" "}
                        {
                          issueType?.find(
                            obj => obj.value == ticketDetails?.type
                          )?.label
                        }
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p className="text-capitalize">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold ">Priority</span> :
                        {ticketDetails?.priority}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Related</span> :
                        {ticketDetails?.related}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Not Answered
                        </span>{" "}
                        : {ticketDetails?.notAnswered}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Answered</span> :
                        {ticketDetails?.answered}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Closed</span> :
                        {ticketDetails?.closed}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Created At</span>{" "}
                        :{" "}
                        {moment(ticketDetails?.created_at).format("DD-MM-YYYY")}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Last Reply</span>{" "}
                        :{" "}
                        {moment(ticketDetails?.last_reply).format("DD-MM-YYYY")}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Files</span> :{" "}
                        {ticketDetails?.ticket_files?.map((obj, i) => (
                          <a
                            className="text-light badge bg-primary me-2"
                            title="Click To Open"
                            style={{ cursor: "pointer", fontSize: "0.8rem" }}
                            key={i}
                            target="_blank"
                            href={obj.link}
                            rel="noreferrer"
                          >
                            Click Here
                          </a>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default TicketOverview;
