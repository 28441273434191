import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../../assets/images/Digital Marketing/editEventImg.jpg"
import { milestoneType } from "utils/common-options";
import Select from "react-select"
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Loader from "pages/Separate/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { calculateTaskFit, calculateTwoTimes, updateTimeForDate } from "utils/common-helpers";

const ResourcePlanning = ({ projectDetail, currentStep }) => {
    // meta title
    document.title = "Project Planning | Zithas Crm";
    const token = secureLocalStorage.getItem('auth_token')
    const [event, setEvent] = useState({});
    const [milestoneOption, setMilestoneOption] = useState([])
    const [milestone, setMilestone] = useState([])
    const [staffOption, setStaffOption] = useState([])
    const [staffId, setStaffId] = useState([])
    const [customDateHtml, setCustomDateHtml] = useState([])
    const [taskList, setTaskList] = useState([])
    const [taskName, setTaskName] = useState('')
    const [allEvents, setAllEvents] = useState([])
    const [error, setError] = useState('');
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9])$/;
    document.addEventListener("keydown", e => {
        if (e.key == "Escape") {
            if (modal) {
                setModal(false)
            }
        }
    })
    const [modal, setModal] = useState(false);
    const [hrs, setHrs] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [modalData, setModalData] = useState({})
    const [eventData, setEventData] = useState({});
    const [addModal, setAddModal] = useState(false)
    const [changeKey, setChangeKey] = useState(false)
    useEffect(() => {
        new Draggable(document.getElementById("external-events"), {
            itemSelector: ".external-event",
        });
    }, []);

    useEffect(() => {
        if (!modal && !isEmpty(event) && !!isEdit) {
            setTimeout(() => {
                setEvent({});
                setIsEdit(false);
            }, 500);
        }
    }, [modal, event]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setEvent(null);
        } else {
            setModal(true);
        }
    };
    const onDrop = async (event) => {
        const { date, draggedEl } = event;
        const taskId = draggedEl.id;
        const estTime = draggedEl.getAttribute('data-hrs');
        if (!draggedEl || !draggedEl.classList.contains('external-event')) {
            return;
        }
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const currentTime = new Date();
        const modifiedDate = new Date(
            year,
            month,
            day,
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds()
        );
        const mainDate = moment(date).format('YYYY-MM-DD')
        const time = customDateHtml?.find((obj) => obj?.date == mainDate)?.time
        const newEvent = {
            title: draggedEl.innerText || draggedEl.getAttribute('title'),
            date: modifiedDate,
            className: draggedEl.className,
            task_id: taskId,
            est_time: estTime,
            occupied: time
        };
        const formEntries = {
            staff_id: staffId?.value,
            project_id: params?.id,
            task_id: taskId,
            date: mainDate,
            user_id: localStorage.getItem('auth_id'),
            className: draggedEl.className,
        }
        setTaskName(draggedEl.innerText || draggedEl.getAttribute('title'))
        setHrs(calculateTaskFit(estTime, time))
        setModalData(formEntries)
        setEventData(newEvent)
        setAddModal(true)
    };
    const [isLoading, setLoading] = useState(false)

    const params = useParams()
    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    const fetchStaffMile = async () => {
        const res = await fetchData(toggleLoading, `getMilestonesOfProject/${params?.id}`, token);
        if (res?.success) {
            const mileOption = res?.data?.milestones?.original?.data
            setStaffOption(res?.data?.resources?.map((obj) => ({
                label: obj?.alias_name,
                value: obj?.user_id,
                type: obj?.type
            })))
            setMilestoneOption(mileOption?.map((obj) => ({
                value: obj.id,
                label: `Milestone - ${obj?.milestone_no} - ${milestoneType?.find((mile) => mile?.value == obj.milestone_type)?.type}`,
                type: obj.milestone_type
            })))
        }
    }
    useEffect(() => {
        fetchStaffMile();
    }, [])

    const onGetTasks = async () => {
        if (milestone?.length != 0 && staffId?.length != 0) {
            const formEntries = {
                staff_id: staffId?.value || '',
                milestone_id: milestone?.value || '',
                project_id: params?.id,
                milestone_id: milestone?.value || ''
            }
            const res = await FetchPostData(toggleLoading, `getMilestonesDataById`, token, formEntries);
            if (res?.data?.success) {
                const details = res?.data?.data
                setCustomDateHtml(details?.availability?.original?.data)
                setTaskList(details?.tasks)
                setAllEvents(details?.assign_tasks?.map((obj) => ({
                    start: obj?.date,
                    ...obj
                })))
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please Select Mandatory Fields"
            })
        }
    }

    const handleAddTask = async (e, type, data, date, id) => {
        if (type != 1) {
            e.preventDefault();
        }

        if (timeRegex.test(hrs)) {
            const formEntries = { ...modalData, hrs, milestone_id: milestone?.value }
            const editEntries = { milestone_id: milestone?.value, type: type, hrs: data?.estimate_dev_days, staff_id: data?.user_id, date, task_id: id }
            const res = await FetchPostData(toggleLoading, "assignTaskToUser", token, type == 1 ? editEntries : formEntries)
            if (res?.data?.success) {
                const details = res?.data?.data
                setCustomDateHtml(details?.availability?.original?.data)
                setTaskList(details?.tasks)
                setAllEvents(details?.assign_tasks?.map((obj) => ({
                    start: obj?.date,
                    ...obj
                })))
                setAddModal(false)
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Invalid Time Format. Please use HH:MM format"
            })
        }
    }

    const onDragEdit = async (info) => {
        const { estimate_dev_days } = info?.extendedProps;
        const formEntries = {
            staff_id: staffId?.value,
            project_id: params?.id,
            date: moment(info?.start).format('YYYY-MM-DD'),
            hrs: estimate_dev_days,
            task_id: info?.id

        }
        const res = await FetchPostData(toggleLoading, "updateTaskToDate", token, formEntries);
        if (res?.data?.success) {
            setCustomDateHtml(res?.data?.data?.availability?.original?.data)
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <Card className="mb-3">
                <CardBody>
                    <div className="">
                        <Row>
                            <div className="d-flex gap-3 align-items-center col-md-12 mb-4 justify-content-end">
                                <div className="col-md-2">
                                    <Select
                                        placeholder="Select Milestone"
                                        className="basic-multi-select "
                                        classNamePrefix=" select"
                                        options={milestoneOption}
                                        value={milestone}
                                        onChange={(e) => {
                                            setStaffId([])
                                            setMilestone(e)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Select
                                        placeholder="Select Members"
                                        className="basic-multi-select "
                                        classNamePrefix=" select"
                                        options={milestone?.type == 5 ? staffOption : staffOption?.filter((obj) => obj?.type == milestone?.type)}
                                        value={staffId}
                                        onChange={(e) => setStaffId(e)}
                                    />
                                </div>
                                <div>
                                    <a className="btn btn-sm  me-1 bg-purple text-white" style={{ borderRadius: "5px" }}
                                        onClick={() => {
                                            onGetTasks();
                                        }}>
                                        <i className="bi bi-funnel-fill fs-5"></i>
                                    </a>
                                </div>
                            </div>
                            <Col className="col-12">
                                <Row>
                                    <Col lg={4}>
                                        <Card>
                                            <CardBody className="pt-0" >
                                                <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "scroll", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                                                    <br />
                                                    {taskList &&
                                                        taskList.map((category, i) => {
                                                            if (category?.remaining_dev_hr != '00:00') {
                                                                return (
                                                                    <div className="d-flex justify-space-between align-items-center" key={i}>
                                                                        <div
                                                                            className={`external-event fc-event text-white w-100 d-flex justify-space-between align-items-center`}
                                                                            draggable
                                                                            onDrag={event => onDrag(event, category)}
                                                                            data-hrs={category?.remaining_dev_hr}
                                                                            id={category?.id}
                                                                        >
                                                                            <span>
                                                                                <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                                                                {category.title}
                                                                            </span>

                                                                            <span className="badge bg-light text-dark ms-2">{category.remaining_dev_hr}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        )}

                                                    {taskList?.length == 0 ? (
                                                        <p className="fw-bold">Please Select the Milestone & Members</p>
                                                    ) : null}

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={8} className="pe-0 ps-0">
                                        <div className="show-date">
                                            <FullCalendar
                                                plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                                slotDuration={"00:15:00"}
                                                handleWindowResize={true}
                                                themeSystem="bootstrap"
                                                weekends={true}
                                                // hiddenDays={[0]}
                                                headerToolbar={{
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                                                }}
                                                validRange={{
                                                    start: moment(projectDetail?.startdate).format('YYYY-MM-DD'),
                                                    end: moment(projectDetail?.enddate).format('YYYY-MM-DD'),
                                                }}
                                                events={allEvents}
                                                eventContent={(arg) => {
                                                    const { event } = arg;
                                                    return (
                                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                                            <div
                                                                className="text-start "
                                                                style={{
                                                                    wordWrap: "break-word",
                                                                    wordBreak: "break-word",
                                                                    whiteSpace: "normal",
                                                                    maxWidth: "100%",
                                                                }}
                                                            >
                                                                {event.title} - <span className="badge bg-light text-dark ms-2">{event?.extendedProps?.estimate_dev_days}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                                dayCellContent={(cell) => {
                                                    const { date } = cell;
                                                    const time = customDateHtml?.find((obj) => obj?.date == moment(date).format('YYYY-MM-DD'))?.time || "00:00";

                                                    return (
                                                        <div className="fc-day-cell-content w-100 justify-space-between align-items-center" style={{ flexDirection: "initial" }}>
                                                            <div className="time-header mb-0">{time} hrs</div>
                                                            <div className="fc-day-number">{date.getDate()}</div>
                                                        </div>
                                                    );
                                                }}
                                                drop={onDrop}
                                                editable={true}
                                                droppable={true}
                                                selectable={true}
                                                eventReceive={(info) => {
                                                    onDragEdit(info.event);
                                                }}
                                            />
                                        </div>

                                        <Modal
                                            isOpen={addModal}
                                            size="lg"
                                            centered
                                        >
                                            <ModalHeader toggle={() => setAddModal(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                                                Assign Task
                                            </ModalHeader>
                                            <ModalBody className="p-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        handleAddTask(e)
                                                    }}
                                                >
                                                    <Row>
                                                        <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                                            <div className="text-center">
                                                                <img
                                                                    src={editEventImg}
                                                                    alt="Addepe"
                                                                    style={{ width: "90%" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-7 d-flex flex-column justify-content-center align-items-start pl-0">
                                                            <div className="d-flex gap-2 col-md-12 mb-5">
                                                                <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                    <h5>Est Time</h5>
                                                                    <h5>{eventData?.est_time} Hrs </h5>
                                                                </a>
                                                                <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                    <h5>Engaged Time</h5>
                                                                    <h5>{eventData?.occupied} Hrs</h5>
                                                                </a>
                                                                <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                    <h5>Time Taken</h5>
                                                                    <h5>
                                                                        {calculateTwoTimes(eventData?.occupied || "00:00", hrs || "00:00")}
                                                                        Hrs</h5>
                                                                </a>
                                                            </div>
                                                            <div className="mb-3 col-12">
                                                                <Label className="form-label">Activity Name</Label>
                                                                <Input
                                                                    disabled
                                                                    name="title"
                                                                    type="text"
                                                                    value={taskName?.replace(/\d+/g, "")}
                                                                />
                                                            </div>
                                                            <div className="row col-md-12 align-items-end">
                                                                <div className="col-4">
                                                                    <Label className="form-label">Total Hours</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Total hours"
                                                                        value={eventData?.est_time}
                                                                        disabled

                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <Label className="form-label">Hours</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter hours"
                                                                        value={hrs}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            setHrs(e.target.value)
                                                                            if (timeRegex.test(inputValue)) {
                                                                                setError('');
                                                                            } else {
                                                                                setError('* Invalid time format. Please enter in HH:MM format.');
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {error && <span className="text-danger mt-2">{error}</span>}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="">
                                                        <Col className="col-12 text-end">
                                                            <button type="submit" className="btn btn-success" id="btn-save-event">
                                                                Save
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </ModalBody>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};



export default ResourcePlanning;
