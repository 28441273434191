import { DatePicker } from "antd"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import secureLocalStorage from "react-secure-storage"
import Select from "react-select"
import Swal from "sweetalert2"
import { FetchPostData } from "utils/common-fetchData"
import { daysOption, ResourceType } from "utils/common-options"

const ProjectOverview = ({ projectDetails, onUpdate }) => {
    const sprintOption = [
        {
            value: 'weekly',
            label: 'Weekly Sprint'
        },
        {
            value: 'biweekly',
            label: 'Bi-weekly Sprint'
        },
        {
            value: 'month',
            label: 'Once in a Month'
        },
    ]
    const calculatePercentage = (hours, totalHours) => {
        return totalHours > 0
            ? Math.floor((Number(hours) / Number(totalHours)) * 100)
            : 0
    }
    const [sprintType, setSprintType] = useState([])
    const [sprint, setSprint] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [hours, setHours] = useState([]);
    const [scopeHrs, setScopeHrs] = useState(false)
    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    useEffect(() => {
        setSprintType(sprintOption?.find((obj) => obj?.value == projectDetails?.sprint_type))
        if (sprintType?.value == "month") {
            setSprint(projectDetails?.sprint)
        } else {
            setSprint(daysOption?.find((obj) => obj?.value == projectDetails?.sprint))
        }
        setHours(projectDetails?.scope_hrs?.split(',') || [])
    }, [projectDetails])
    const token = secureLocalStorage.getItem('auth_token')
    const onSetSprint = async (e) => {
        e.preventDefault();
        const formEntries = {
            project_id: projectDetails?.id,
            sprint: sprintType?.value == "month" ? sprint : sprint?.value || '',
            sprint_type: sprintType?.value || ''
        }
        const res = await FetchPostData(toggleLoading, 'updateProjectSprint', token, formEntries);
        if (res?.data?.success) {
            Swal.fire({
                icon: 'success',
                text: 'Sprint Details Updated Successfully',
            }).then(() => {
                onUpdate(true)
            })
        }
    }
    const scopeTotal = Number(projectDetails?.designing) + Number(projectDetails?.development) + Number(projectDetails?.testing)
    const handleChange = (index, value) => {
        const updatedHours = [...hours];
        updatedHours[index] = value;
        setHours(updatedHours);
        const totalHours = updatedHours
            .map(hour => parseFloat(hour) || 0)
            .filter(hour => hour > 0)
            .reduce((acc, hour) => acc + hour, 0);
        if (scopeTotal < totalHours) {
            setScopeHrs(true)
        } else {
            setScopeHrs(false)
        }
    };

    const OnAddScopeHours = async (e) => {
        e.preventDefault();
        const formEntries = {
            project_id: projectDetails?.id,
            status: hours?.map((hour) => hour).join(','),
            type: 5
        }
        const response = await FetchPostData(
            toggleLoading,
            "updateFreezeStatus",
            secureLocalStorage?.getItem("auth_token"),
            formEntries
        )
        if (response?.data?.success) {
            Swal.fire({
                icon: 'success',
                text: 'Scope Hours Updated Successfully',
            }).then(() => {
                onUpdate(true)
            })
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-purple fw-bold font-size-18">
                                        {projectDetails?.project_name}
                                    </h5>
                                    <p>
                                        <strong>Project Type</strong> : {
                                            ResourceType?.find(
                                                obj => obj?.value == projectDetails?.project_type
                                            )?.label
                                        } ,{" "}
                                        <strong>Customer</strong> : Global Biotech
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-4 ">
                                <div className="text-muted  ">
                                    <p>
                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                        <span className="text-purple fw-bold">Project Belongs</span>{" "}
                                        : {projectDetails?.belongs_to}
                                    </p>
                                    <p>
                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                        <span className="text-purple fw-bold">Resource Type</span>:{' '}
                                        {ResourceType?.find((obj) => obj?.value == projectDetails?.billingtype)?.label}
                                    </p>
                                    <p className="mb-0">
                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                        <span className="text-purple fw-bold">
                                            Project Industry
                                        </span>
                                        : {projectDetails?.industry}
                                    </p>
                                </div>
                                <div className="info_box_1"></div>
                                <div className="text-muted ">
                                    <p>
                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                        <span className="text-purple fw-bold">
                                            Key Account Manager
                                        </span>{" "}
                                        : {projectDetails?.kam_name}
                                    </p>
                                    <p>
                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                        <span className="text-purple fw-bold">Scrum Master</span>:
                                        {projectDetails?.scrum_mastername}
                                    </p>
                                </div>
                            </div>

                            <div className="task-dates row mt-4">
                                <div className="col-3 col-sm-3">
                                    <div className="mt-4">
                                        <h5 className="font-size-14">
                                            <i className="bx bx-calendar me-1 text-primary"></i> Start
                                            Date
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {moment(projectDetails?.startdate).format(
                                                "DD MMM, YYYY"
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3 col-sm-3">
                                    <div className="mt-4">
                                        <h5 className="font-size-14">
                                            <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                                            Est. End Date
                                        </h5>
                                        <p className="text-muted mb-0"> {moment(projectDetails?.enddate).format(
                                            "DD MMM, YYYY"
                                        ) || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-3 col-sm-3">
                                    <div className="mt-4">
                                        <h5 className="font-size-14">
                                            <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                                            Number OF Weeks
                                        </h5>
                                        <p className="text-muted mb-0">{projectDetails?.no_of_weeks} Weeks</p>
                                    </div>
                                </div>
                                <div className="col-3 col-sm-3">
                                    <div className="mt-4">
                                        <h5 className="font-size-14">
                                            <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                                            Delivery Before Date
                                        </h5>
                                        <p className="text-muted mb-0">{moment(projectDetails?.deliverydatebefore).format(
                                            "DD MMM, YYYY"
                                        ) || "-"}</p>
                                    </div>
                                </div>
                            </div>
                            {secureLocalStorage.getItem("auth_role") == 9 ||
                                secureLocalStorage.getItem("auth_role") == 10 ||
                                secureLocalStorage.getItem("auth_role") == 22 ||
                                secureLocalStorage.getItem("auth_role") == 4 ||
                                secureLocalStorage.getItem("auth_role") == 11 ||
                                secureLocalStorage.getItem("auth_role") == 1 ||
                                secureLocalStorage.getItem("auth_role") == 18 ? (
                                <>
                                    <p className="mt-4 pt-3 mb-0">
                                        <span className="text-purple fw-bold">
                                            Payment Schedule
                                        </span>
                                    </p>
                                    <div className="task-dates row ">
                                        {projectDetails?.payment_schedule?.map((obj, i) => {
                                            const dates = projectDetails?.completion_dates?.split(',')[i]
                                            return (
                                                <div className="col-3 col-sm-3" key={i}>
                                                    <div className="mt-3">
                                                        <h5 className="font-size-14">
                                                            <i className="bx bx-calendar me-1 text-primary"></i>{" "}
                                                            {obj}
                                                        </h5>
                                                        <p className="text-muted mb-0">
                                                            {moment(dates).format(
                                                                "DD MMM, YYYY"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className="responsive-table">
                                <form className="mb-4" onSubmit={OnAddScopeHours}>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-3">Allocate Scope Hours</h5>
                                        <button className="btn btn-save border-radius ms-3">Save</button>
                                    </div>
                                    <label htmlFor="">
                                        Scopes <span className="text-danger">*</span>
                                    </label>
                                    {projectDetails?.project_scope?.map((obj, i) => {
                                        return (
                                            <div className="row align-items-center mt-2" key={i}>
                                                <div className="col-md-10">
                                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                    <span className="mb-0 text-purple fs-6">{obj}</span>
                                                </div>
                                                <div className="col-2">
                                                    <div >
                                                        <input type="number" placeholder="Hours"
                                                            className="form-control text-center "
                                                            value={hours[i] || ''}
                                                            onChange={(e) => handleChange(i, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="row align-items-center mt-2 bg-light-purple pt-2 pb-2" >
                                        <div className="col-md-10">
                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                            <span className="mb-0 text-purple fs-6">Total Hours</span>
                                        </div>
                                        <div className="col-2">
                                            <div className={scopeHrs ? 'blinking' : ''}>
                                                <input type="text" placeholder="Hours"
                                                    className="form-control text-center "
                                                    disabled
                                                    value={Number(projectDetails?.designing) + Number(projectDetails?.development) + Number(projectDetails?.testing)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body bg-light-purple">
                            <div className="responsive-table">
                                <form className="mb-4" onSubmit={onSetSprint}>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-3">Actual Hours Details</h5>
                                        <button className="btn btn-save border-radius ms-3">Save</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 mt-3">
                                            <label htmlFor="">
                                                Sprint Type <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                placeholder="Select Sprint Type"
                                                className="basic-multi-select"
                                                classNamePrefix=" select"
                                                options={sprintOption}
                                                value={sprintType}
                                                onChange={(e) => setSprintType(e)}
                                            />
                                        </div>
                                        <div className="col-6 mt-3">
                                            {sprintType?.value == 'weekly' || sprintType?.value == 'biweekly' ? (
                                                <>
                                                    <label htmlFor="">
                                                        Select Day <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        placeholder="Select Day"
                                                        className="basic-multi-select"
                                                        classNamePrefix=" select"
                                                        options={daysOption}
                                                        value={sprint}
                                                        onChange={(e) => setSprint(e)}
                                                    />
                                                </>
                                            ) : sprintType?.value == "month" ? (
                                                <>
                                                    <label htmlFor="">
                                                        Select Date <span className="text-danger">*</span>
                                                    </label>
                                                    <DatePicker
                                                        id="datepicker"
                                                        format="DD"
                                                        picker="date"
                                                        className="cutome-picker"
                                                        // value={sprint}
                                                        onChange={(e) => setSprint(e?.$D)}
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-bordered mb-0  table-hover table">
                                <thead className="">
                                    <tr>
                                        <th>Allocation Name</th>
                                        <th className="">Approx %</th>
                                        <th className="text-center col-3">
                                            Hours
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="col-6">Planning</td>
                                        <td>{calculatePercentage(projectDetails?.planning, projectDetails?.total_hrs)} %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.planning}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-6">Designing</td>
                                        <td>{calculatePercentage(projectDetails?.designing, projectDetails?.total_hrs)} %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.designing}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-6">Development </td>
                                        <td>{calculatePercentage(projectDetails?.development, projectDetails?.total_hrs)} %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.development}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-6">Testing </td>
                                        <td>{calculatePercentage(projectDetails?.testing, projectDetails?.total_hrs)} %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.testing}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-6">Deployment </td>
                                        <td>{calculatePercentage(projectDetails?.deployment, projectDetails?.total_hrs)} %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.deployment}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr className="bg-light-purple">
                                        <td className="col-6">
                                            <strong className="text-purple fs-5">Total Hours</strong>
                                        </td>
                                        <td>100 %</td>
                                        <td className="col-2">
                                            <input
                                                required=""
                                                type="text"
                                                placeholder="Hours.."
                                                className="form-control text-center "
                                                name="estimatedtime"
                                                value={projectDetails?.total_hrs}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body ">
                            <h5 className="mb-3">Project Description </h5>
                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: projectDetails?.description }} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProjectOverview
