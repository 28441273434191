import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import classNames from "classnames"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import LeaveManagement from "./LeaveManagement"
import SalarySheet from "./SalarySheet"
import BankDetails from "./BankDetails"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import SalaryDone from "../../../../assets/images/Expenses/Upload_Bill.jpg"

const StepNavigationStep = ({ step, currentStep, onStepChange }) => {
  const classes = classNames({
    active: step.id === currentStep,
    complete: currentStep > step.id,
  })

  return (
    <li
      className={classes}
      onClick={() => {
        onStepChange(step.id)
      }}
    >
      <div className="step">
        <i className={step.icon_class}></i>
      </div>
      <div className="caption hidden-xs hidden-sm">
        <span className="fs-5">{step.title}</span>
      </div>
    </li>
  )
}

const StepNavigation = ({ steps, currentStep, onStepChange }) => {
  return (
    <ol className="step-indicator">
      {steps.map(step => (
        <StepNavigationStep
          key={step.id}
          step={step}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
      ))}
    </ol>
  )
}

const SalaryRoutes = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [leaveLength, setLeaveLength] = useState("")
  const [stepData, setStepData] = useState({})
  const steps = [
    { id: 1, title: "Leave Applied", icon_class: "bi bi-1-circle-fill" },
    { id: 2, title: "Salary Sheet", icon_class: "bi bi-2-circle-fill" },
    { id: 3, title: "Complete", icon_class: "bi bi-3-circle-fill" },
  ]
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const stepChanged = step => {
    setCurrentStep(step)
  }
  const token = secureLocalStorage.getItem("auth_token")
  const stepChange = async step => {
    const res = await fetchData(toggleLoading, `changeStepData/${step}`, token)
    if (res?.success) {
      return true
    }
  }

  const onChangeStep = async e => {
    if (currentStep == 1) {
      if (leaveLength == 0) {
        Swal.fire({
          icon: "warning",
          title: "Confirmation Required",
          text: "Are you sure you want to proceed? This will trigger the salary calculation. Please ensure all details are reviewed before continuing.",
          showCancelButton: true,
          confirmButtonText: "Yes Move to Next!",
        }).then(async result => {
          if (result.isConfirmed) {
            const get = await stepChange(1)
            if (stepData?.current_step == 0) {
              if (get) {
                const res = await fetchData(
                  toggleLoading,
                  "salaryCalculation",
                  token
                )
                if (res?.success) {
                  setCurrentStep(2)
                }
              }
            } else {
              setCurrentStep(2)
            }
          }
        })
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please take action of pending Leaves Or Issues",
        })
      }
    } else if (currentStep == 2) {
      if (leaveLength != 0) {
        const get = await stepChange(2)
        if (get) {
          setCurrentStep(3)
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please Generate Salary Sheet before proceeding",
        })
      }
    }
  }

  const getLength = length => {
    setLeaveLength(length)
  }
  const fetchCurrentStatus = async () => {
    const res = await fetchData(toggleLoading, "currentStepData", token)
    if (res?.success) {
      setStepData(res?.data)
      if (res?.data?.salaryprocess_finish_status) {
        setCurrentStep("finish")
      } else {
        if (res?.data?.current_step == 0) {
          setCurrentStep(1)
        } else if (res?.data?.current_step == 1) {
          setCurrentStep(2)
        } else if (res?.data?.current_step == 2) {
          setCurrentStep(3)
        }
      }
    }
  }

  useEffect(() => {
    fetchCurrentStatus()
  }, [])

  const onCompleteProcess = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You are about to complete the process. Please confirm.",
      showCancelButton: true,
      confirmButtonText: "Yes Complete it!",
    }).then(async data => {
      if (data?.isConfirmed) {
        const res = await fetchData(
          toggleLoading,
          "completeSalaryProcess",
          token
        )
        if (res?.success) {
          Swal.fire({
            icon: "success",
            title: "Salary Process completed for this Month",
          }).then(data => {
            fetchCurrentStatus()
          })
        }
      }
    })
  }

  return (
    <React.Fragment>
      <div className="">
        {isLoading && <Loader />}
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form action="">
                  <div className="d-flex justify-content-between align-items-center wizard">
                    <h5 className="text-purple fw-bold mb-0">
                      Salary Proccess
                    </h5>
                    <div className="col-6 d-flex gap-2 justify-content-end align-items-center">
                      {currentStep !== 3 && currentStep != "finish" && (
                        <a
                          className="btn text-white text-uppercase me-0 btn-save btn-label "
                          onClick={() => onChangeStep()}
                        >
                          <i className="fas fa-save label-icon"></i>
                          {currentStep == 1
                            ? "Calculate Salary & Next"
                            : "Verify & Continue"}
                        </a>
                      )}
                      {currentStep == 3 && stepData?.final_process == 0 && (
                        <a
                          className="btn text-white text-uppercase me-0 btn-success btn-label "
                          onClick={() => onCompleteProcess()}
                        >
                          <i className="fas fa-check label-icon"></i>
                          Complete Salary Process
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="container-fluid">
                    <StepNavigation
                      steps={steps}
                      currentStep={currentStep}
                      onStepChange={stepChanged}
                    />
                  </div>
                </form>
              </CardBody>
            </Card>

            {currentStep === 1 ? (
              <LeaveManagement className="mt-0" getLength={getLength} />
            ) : currentStep === 2 ? (
              <SalarySheet
                className="mt-0"
                stepData={stepData}
                getLength={getLength}
              />
            ) : currentStep === 3 ? (
              <BankDetails className="mt-0" stepData={stepData} />
            ) : currentStep === "finish" ? (
              <div>
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <img src={SalaryDone} alt="Salary Done" width={260} />
                      <h5 className="text-purple fw-bold mb-3 lh-lg">
                        📊 The salary for the previous month has been processed.
                        <br />
                        Please stay tuned for the next month's cycle. 💼
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default SalaryRoutes
