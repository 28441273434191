import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import SalaryRoutes from "./SalaryRoutes"

const SalaryIndex = () => {
  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="m-auto">
              <div className="salary-process-container">
                <SalaryRoutes className="mb-0" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default SalaryIndex
