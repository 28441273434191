import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import imgMilestone from "../../../../assets/images/Project/AddMilestone.jpg";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import NewPagination from "components/Common/Newpagination";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import { milestoneColumns } from "utils/columns/Developement/projectColunmn";
import { milestoneType } from "utils/common-options";
import Swal from "sweetalert2";
import { checkPermissionsByModule } from "utils/common-helpers";
import { useSelector } from "react-redux";

const Milestones = ({
  verticalActiveTab,
  toggleLoading,
  projectDetail,
  edit,
  projectId,
}) => {
  const [editMilestoneSidebar, setEditMilestoneSidebar] = useState(false);
  const roles = useSelector((state) => state?.Roles?.apiData);
  const params = useParams();
  const token = secureLocalStorage.getItem("auth_token");
  const [datas, setData] = useState([]);
  const [description, setDescription] = useState("");
  const [type, setType] = useState([]);
  const [hours, setHours] = useState("");
  const [scope, setScope] = useState([]);
  const [scopeOption, setScopeOption] = useState([]);
  const [mile_id, setMileId] = useState("");

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") setEditMilestoneSidebar(false);
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMilestone = async () => {
      const res = await fetchData(
        toggleLoading,
        `projectMilestones/${edit ? params?.id : projectId}`,
        token
      );
      if (res?.success) {
        setScopeOption(
          projectDetail?.project_scope?.map((obj) => ({
            label: obj,
            value: obj,
          }))
        );
        setData(res?.data);
      }
    };
    if (verticalActiveTab == 2) {
      fetchMilestone();
    }
  }, [verticalActiveTab]);

  const onEdit = (obj) => {
    setMileId(obj.id);
    setEditMilestoneSidebar(true);
    setDescription(obj?.description);
    setType(milestoneType?.find((item) => item?.value == obj?.milestone_type));
    setHours(obj?.milestone_days);
    const scope = obj?.scope?.split("@#");
    setScope(scopeOption?.filter((item) => scope?.includes(item?.value)));
  };

  const onDelete = async (id) => {
    const permission = checkPermissionsByModule(roles, 25, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Milestone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await fetchData(
            toggleLoading,
            `deleteProjectmilestone/${id}/${params?.id}`,
            token
          );
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: "Milestone Deleted Successfully",
            });
            setData(response?.data);
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  // table columns
  const columns = useMemo(() => milestoneColumns(onEdit, edit, onDelete), []);
  const data = useMemo(() => datas, [datas]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;
  useEffect(() => setPageSize(50), []);

  const onEditMiletsone = async (e) => {
    e.preventDefault();
    if (description?.length >= 80) {
      if (description != "" && type?.length != 0 && scope?.length != 0) {
        const formEntries = {
          milestone_id: mile_id,
          user_id: localStorage.getItem("auth_id"),
          description: description,
          milestone_type: type?.value || "",
          milestone_hours: hours,
          scope: scope?.map((obj) => obj?.value)?.join("@#"),
          project_id: projectDetail?.id,
        };
        const res = await FetchPostData(
          toggleLoading,
          "editProjectMilestone",
          token,
          formEntries
        );
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Milestone Edited Successfully",
          }).then(() => {
            setEditMilestoneSidebar(false);
            setDescription("");
            setType([]);
            setHours("");
            setScope([]);
            setData(res?.data?.data);
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: "Milestone Number is already exists!",
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please Fill All the Fields",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      });
    }
  };

  return (
    <div className="me-3">
      <div className="moveUp mt-4 ">
        <Row>
          <Col>
            <select
              className="border-radius me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <Col className="d-flex justify-content-end align-items-start">
            <div>
              <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      id="search-bar-0"
                      type="text"
                      className="border-radius form-control rounded h-100"
                      placeholder={`Search...`}
                      value={globalFilter || ""}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <a
              onClick={() => navigate(-1)}
              className="border-radius btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </a>
          </Col>
        </Row>

        <Row>
          <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
            <div className="text-center">
              <img src={imgMilestone} alt="Addepe" style={{ width: "90%" }} />
            </div>
          </div>
          <div className="position-relative col-md-8 d-flex justify-content-end align-items-center ps-3">
            <Table className="table table-bordered mt-3" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={`t-col-${index + 1} ${column.className}`}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {column.isInfoIcon ? (
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr className="row-hover" {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`t-col-${index + 1} ${
                              cell.column.className
                            }`}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {page.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Row>
      </div>
      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Edit Milestone  */}
      <div
        className={`note-sticky-form ${
          editMilestoneSidebar ? "d-block" : "d-none"
        }`}
      >
        <Draggable>
          <ResizableBox
            width={700}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <form onSubmit={onEditMiletsone}>
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Milestone</h5>
                </div>
                <div className="col-12 for-task">
                  <label htmlFor="">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={milestoneType}
                      value={type}
                      onChange={(e) => setType(e)}
                    />
                  </div>
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Milestone Hours <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Milestone Hours"
                      className="form-control"
                      value={hours}
                      onChange={(e) => setHours(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 mt-3 notes">
                  <label htmlFor="">
                    Scope <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Scope"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    isMulti={true}
                    options={scopeOption}
                    value={scope}
                    onChange={(e) => setScope(e)}
                  />
                </div>

                <div className="text-end mt-3">
                  <button className="btn btn-purple border-radius me-2">
                    <i className="fas fa-save me-2"></i>Save
                  </button>
                  <a
                    onClick={() => {
                      setDescription("");
                      setScope([]);
                      setType([]);
                      setHours("");
                      setEditMilestoneSidebar(false);
                    }}
                    className="btn btn-secondary text-uppercase border-radius"
                  >
                    <i className="dripicons-cross me-1 icon-center"></i>Close
                  </a>
                </div>
              </Row>
            </form>
          </ResizableBox>
        </Draggable>
      </div>
    </div>
  );
};

export default Milestones;
