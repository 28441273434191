import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import configure from "configure"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import viewNotes from "../../../../assets/images/Project/viewNotes.jpg"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"
import Swal from "sweetalert2"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import Dropzone from "react-dropzone"

const Notes = ({ verticalActiveTab, toggleLoading, edit, projectId }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [viewNote, setViewNote] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  const navigate = useNavigate()
  const params = useParams()
  const token = secureLocalStorage.getItem("auth_token")
  const roles = useSelector(state => state?.Roles?.apiData)
  const [notedate, setNotedate] = useState("")
  const [noteDescription, setNoteDescription] = useState("")
  const [noteId, setNoteId] = useState("")
  const [file, setFile] = useState("")

  const fetchNotes = async () => {
    const res = await fetchData(
      toggleLoading,
      `projectNotes/${edit ? params.id : projectId}`,
      token
    )
    if (res?.success) {
      setDatas(res?.data?.notes)
    }
  }
  useEffect(() => {
    if (verticalActiveTab == 4) {
      fetchNotes()
    }
  }, [verticalActiveTab])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setNotesSidebar(false), setEditNotesSidebar(false)
  })

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const onAddNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("project_id", params?.id)
    formData.append("description", noteDescription)
    formData.append("date", notedate)
    if (selectedFiles1?.length != 0) {
      for (let i = 0; i < selectedFiles1.length; i++) {
        formData.append("files[]", selectedFiles1[i])
      }
    }
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/addProjectNotes`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          title: "Note Edited Successfully",
        }).then(() => {
          setNoteDescription("")
          setNotedate("")
          setNotesSidebar(false)
          setDatas(res?.data?.data.notes)
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const onDeleteNotes = async id => {
    const permission = checkPermissionsByModule(roles, 101, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Note!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const formDatas = {
            project_id: params?.id,
            user_id: localStorage?.getItem("auth_id"),
            note_id: id,
          }
          const response = await FetchPostData(
            toggleLoading,
            "deleteProjectNotes",
            secureLocalStorage.getItem("auth_token"),
            formDatas
          )
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Project deleted successfully",
            }).then(() => {
              setDatas(response?.data?.data.notes)
            })
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const editNotes = async e => {
    e.preventDefault()
    const formEntries = {
      note_id: noteId,
      user_id: localStorage.getItem("auth_id"),
      date: notedate,
      description: noteDescription,
      project_id: params?.id,
    }
    const res = await FetchPostData(
      toggleLoading,
      "editProjectNotes",
      token,
      formEntries
    )
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Note Edited Successfully",
      }).then(() => {
        setEditNotesSidebar(false)
        setDatas(res?.data?.data?.notes)
        setNoteDescription("")
        setNotedate("")
      })
    }
  }

  const pinnedNote = async (id, status) => {
    const formEntries = {
      user_id: localStorage.getItem("auth_id"),
      project_id: params?.id,
      note_id: id,
      status: status,
    }
    const response = await FetchPostData(
      toggleLoading,
      "pinProjectNotes",
      token,
      formEntries
    )
    if (response?.data?.success) {
      setDatas(response.data.data?.notes)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus me-2"></i>
              <span>Note</span>
            </a>

            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between">
            <h5 className="text-dark">Project Notes</h5>
            {edit ? (
              <div className="text-end">
                <a
                  onClick={() => setNotesSidebar(true)}
                  className=" btn btn-save btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                  Note
                </a>
                <a
                  onClick={() => navigate(-1)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            ) : null}
          </div>

          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              {data?.map((obj, key) => {
                return (
                  <li className="event-list position-relative" key={key}>
                    <div className="event-timeline-dot">
                      <img
                        src={`${configure.imgUrl}${obj?.img}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className={`flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10 ${obj?.pin_status == 1 ? "bg-light-green" : ""}`}>
                        <div className=" d-flex justify-content-between ">
                          <div>
                            <p className="text-muted ">
                              <h6 className="d-inline me-2">By {obj.name}</h6>{" "}
                              At{" "}
                              {moment(obj?.created_at).format(
                                "DD-MM-YYYY , HH:mm:ss"
                              )}
                              <span className="pl-15">
                                Got in touch with this lead at{" "}
                                {moment(obj?.date).format(
                                  "MMM Do YYYY , HH:mm:ss"
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="me-4">  
                            <a
                              className="btn btn-sm edit-delete-btn text-success me-2"
                              onClick={() => {
                                edit
                                  ? pinnedNote(
                                      obj?.id,
                                      obj?.pin_status == 0 ? 1 : 0
                                    )
                                  : ""
                              }}
                            >
                              <i
                                className={` ${
                                  obj.pin_status == 1
                                    ? "bi bi-pin-angle-fill"
                                    : " bi bi-pin-angle"
                                }`}
                                title="Pin Note"
                              ></i>
                            </a>
                            {edit ? (
                              <>
                                <a
                                  className="btn btn-sm edit-delete-btn text-success me-2"
                                  onClick={() => {
                                    setNoteDescription(obj?.description)
                                    setNotedate(obj?.date)
                                    setNoteId(obj?.id)
                                    setEditNotesSidebar(true)
                                  }}
                                >
                                  <i
                                    className="fas fa-edit"
                                    title="Edit Note"
                                  ></i>
                                </a>
                                <a
                                  className="btn btn-sm edit-delete-btn text-success me-2"
                                  onClick={() => {
                                    setNoteDescription(obj?.description)
                                    setViewNote(true)
                                  }}
                                >
                                  <i
                                    className="fas fa-eye"
                                    title="View Note"
                                  ></i>
                                </a>
                                <button
                                  className="btn btn-sm edit-delete-btn text-danger"
                                  onClick={e => onDeleteNotes(obj?.id)}
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    title="Delete Note"
                                  ></i>
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <i className="bi bi-triangle-fill note-icon"></i>
                        <div className="Features" />
                        <p
                          className="mb-2"
                          dangerouslySetInnerHTML={{ __html: obj?.description }}
                        />
                        {obj?.files?.length != 0 ? (
                          <div>
                            Attachments :
                            {obj?.files?.map((img, i) => {
                              return (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="activity-log-btn text-info"
                                  href={img?.file_name}
                                  key={i}
                                >
                                  <i className="fas fa-eye pr-10"></i>View
                                </a>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            {data.length == 0 ? <p>Note is Not Addded</p> : null}
          </div>

          {/* Add Notes  */}
          <div
            className={`note-sticky-form ${
              notesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={700}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <form onSubmit={onAddNotes}>
                  <Row className="align-items-center ">
                    <div className="col-12 d-flex justify-content-between">
                      <h5>Add New Note</h5>
                      <div className="text-end">
                        <button className="btn btn-purple  me-2">
                          <i className="fas fa-plus me-2"></i>Add Note
                        </button>
                        <a
                          onClick={() => setNotesSidebar(false)}
                          className="btn btn-red text-uppercase"
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>
                          Close
                        </a>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={noteDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setNoteDescription(data)
                        }}
                      />
                    </div>

                    <div className="col-md-5 mt-3 notes">
                      <label htmlFor="touch">
                        Got in touch with this lead{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={notedate}
                        onChange={e => setNotedate(e.target.value)}
                        className="form-control "
                        required
                      />
                    </div>

                    <div className="col-md-5 mt-3">
                      <label htmlFor="">Attachment</label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles1(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick p-0"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex px-2 custom-file-upload align-items-center">
                              <div className="">
                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5 className="mb-0 ms-2">File</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      className={`col-md-4 mt-3 dropzone-previews ${
                        selectedFiles1.length > 0 ? "d-block" : "d-none"
                      }`}
                      id="file-previews"
                    >
                      <label className="mb-1">File Name</label>
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-1">
                          <Row className="align-items-center justify-content-evenly">
                            {selectedFiles1?.map((f, i) => {
                              return (
                                <Col key={i} className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </form>
              </ResizableBox>
            </Draggable>
          </div>

          <div
            className={`note-sticky-form ${
              editNotesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={700}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <form onSubmit={editNotes}>
                  <Row className="align-items-center ">
                    <div className="col-12 d-flex justify-content-between">
                      <h5>Edit Note</h5>
                      <div className="text-end">
                        <button className="btn btn-purple  me-2">
                          <i className="fas fa-plus me-2"></i>Edit Note
                        </button>
                        <a
                          onClick={() => {
                            setNoteDescription("")
                            setNotedate("")
                            setEditNotesSidebar(false)
                          }}
                          className="btn btn-red text-uppercase "
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>
                          Close
                        </a>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={noteDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setNoteDescription(data)
                        }}
                      />
                    </div>
                    <div className="col-md-5 mt-3 notes">
                      <label htmlFor="touch">
                        Got in touch with this lead{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        value={notedate}
                        onChange={e => setNotedate(e.target.value)}
                        className="form-control "
                        required
                      />
                    </div>
                  </Row>
                </form>
              </ResizableBox>
            </Draggable>
          </div>
        </div>
      </div>

      {/* View Notes Modal  */}
      <Modal Modal isOpen={viewNote} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Notes</h5>
          <button
            type="button"
            onClick={() => {
              setViewNote(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={viewNotes} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column ">
              <div className="col-12">
                <label htmlFor="">Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={noteDescription}
                  config={{
                    isReadOnly: true,
                  }}
                />
              </div>
              <div
                className={`col-md-6 mt-3 dropzone-previews`}
                id="file-previews"
              >
                <label className="mb-1">Uploaded Files</label>
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-1">
                    <Row className="align-items-center justify-content-evenly">
                      <Col className="col-md-6">
                        {/* <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded"
                          alt="filename.pdf"
                        /> */}
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>

              <div className="my-4 text-center">
                <a
                  onClick={() => setViewNote(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Notes
