import React, { useEffect, useState } from "react"
import { Form, Input, Label } from "reactstrap"
import {
  issueType,
  locationOptions,
  priorityOption,
} from "utils/common-options"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"

const AddTickets = ({ projectId, actionType, updateData, ticketDetails, closeModal }) => {
  const [location, setLocation] = useState([])
  const [title, setTitle] = useState("")
  const [type, setType] = useState([])
  const [description, setDescription] = useState("")
  const [priority, setPriority] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [unit_test , setUnitTest] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const handleClick = async e => {

    e.preventDefault()
    const formEntries = {
      location: location?.value || "",
      subject: title,
      priority: priority?.value || "",
      description: description,
      user_id: localStorage.getItem('auth_id'),
      project_id: projectId,
      ticket_id: ticketDetails?.id,
      unit_test:unit_test,
      type:type?.value
    }
    const response = await FetchPostData(
      toggleLoading,
      `${"editSupportTicket"}`,
      token,
      formEntries
    )
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        title: `Ticket ${
          actionType == "add" ? "Added" : "Edited"
        } Successfully`,
      }).then(data => {
        updateData(response?.data?.data?.tickets)
        closeModal(false)
      })
    }
  }

  useEffect(() => {
    if (actionType == "edit") {
      setLocation(
        locationOptions?.find(obj => obj?.value == ticketDetails?.location)
      )
      setTitle(ticketDetails?.subject)
      setType(issueType?.find(obj => obj?.value == ticketDetails?.issue_type))
      setDescription(ticketDetails?.description)
      setPriority(
        priorityOption?.find(obj => obj?.value == ticketDetails?.priority)
      )
      console.log(ticketDetails)
    }
  }, [ticketDetails])

  return (
    <div>
      {isLoading && <Loader />}
      <div className="modal-body pe-4">
        <Form
          className="row d-flex justify-content-center align-items-center"
          onSubmit={handleClick}
        >
          <div className="col-md-12 d-flex flex-column new-task-modal mt-4 ticket-history-box">
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
              <div className="col-sm-3 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Location <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select Location"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={locationOptions}
                  value={location}
                  onChange={e => setLocation(e)}
                />
              </div>
              <div className="col-sm-9 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Title <span className="text-danger">*</span>
                </Label>
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
              <div className="col-sm-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">Type</Label>
                <Select
                  placeholder="Select Type"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={issueType}
                  value={type}
                  onChange={e => setType(e)}
                />
              </div>
              <div className="col-sm-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Priority <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select Priority"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={priorityOption}
                  value={priority}
                  onChange={e => setPriority(e)}
                />
              </div>
              <div className=" me-2 ms-4">
                <label
                  // htmlFor="customSwitchsizemd"
                  className="form-label mb-3"
                >
                  Missing Unit Test
                </label>
                <div className="form-switch form-switch-md mb-2 ps-5  ">
                  <input
                    title="Intial Days"
                    type="checkbox"
                    className="form-check-input"
                    // id="customSwitchsizemd"
                    onChange={(e) => {
                      setUnitTest(!unit_test)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
              <div className="col-12 ps-2">
                <label htmlFor="">Description</label>
                <div className="no-drag">
                  <CKEditor
                    style={{ height: "500px" }}
                    editor={ClassicEditor}
                    data={description ? description : "<p></p>"}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex justify-space-between align-items-center">
                <div className="col-md-8 ps-2 d-flex flex-column gap-2">
                  <Label htmlFor="formrow-firstname-Input mb-0">Image</Label>
                  <div className="d-flex text-start gap-2 flex-wrap pb-2">
                    {ticketDetails?.files?.map((obj, i) => {
                      return (
                        <a
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                          key={i}
                          target="_blank"
                          href={obj.file_name}
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      )
                    })}
                  </div>
                </div>

                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Submit
                  </button>
                  <a
                    // onClick={() => setTicket(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddTickets
