import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import cleaningImg from "../../assets/images/cleaning-service.png"
import userImg from "../../assets/images/profile.png"
import CreatableSelect from "react-select/creatable"
import { Scheduler, View, Editing } from "devextreme-react/scheduler"
import moment from "moment"
import timezone from "moment-timezone"
import "../../assets/css/Calender/Devcalender.css"
import "../../assets/css/Calender/Calender.css"
import axios from "axios"
import configure from "configure"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"
import { useParams } from "react-router-dom"
import cleaningIndustryImg from "../../assets/images/cleaning-industry.png"
import ITLandingPage from "../../assets/images/office.png"
import property from "../../assets/images/property.png"
import propertyDeal from "../../assets/images/deal.png"
import domicilary from "../../assets/images/domicilary.png"
import residential from "../../assets/images/residential-logo.png"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

// for Documentation
// https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/Scheduler/SimpleArray/MaterialBlueLight/

export function copyToClipBoard(e) {
  if (e !== null) {
    window.navigator.clipboard.writeText(e)
  }
}

const CalenderIndex2 = () => {
  const [currentDate, setCurrentDate] = useState(moment())
  const [scrolled, setScrolled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isImp, setIsImp] = useState(0)
  const [Addmeeting, setAddmeeting] = useState(false)
  const [allBookingData, setAllBookingData] = useState([])
  const [meetingPopupEdit, setMeetingPopupEdit] = useState(false)
  const [meetingPopupEditData, setMeetingPopupEditData] = useState({
    id: null,
    title: null,
    main_title: null,
    assign_Name: null,
    date: null,
    link: null,
    timeslot: null,
    classNames: null,
    text: null,
    startDate: null,
    endDate: null,
    type: null,
    name: null,
    emails: null,
    isJoinButton: false,
    avatar: null,
    subject: null,
  })

  // Add meeting states

  const [addTitle, setAddTitle] = useState("")
  const [crrlocation, setCrrlocation] = useState(null)
  const [addAttendees, setAddAttendees] = useState(null)
  const [addDate, setAddDate] = useState("")
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
  const [addDescription, setAddDescription] = useState("")
  const [meetingFor, setMeetingFor] = useState(null)
  const [meetingForSub, setMeetingForSub] = useState([{}])
  const [meetingForSubset, setMeetingForSubset] = useState(null)
  const [modal_Access, setAccessDenied] = useState(false)
  const [copy, setCopy] = useState(false)
  const [ismeetingFor, setIsmeetingFor] = useState(false)
  const [meetingForLabel, setMeetingForLabel] = useState("")
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    label: "Europe/London",
    value: "Europe/London",
  })

  const { id } = useParams()

  // ...............................................................

  const schedulerRef = useRef(null)
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }
  const customStyles2 = {
    control: provided => ({
      ...provided,
      paddingLeft: "2.5rem", // Add padding to the left of the control
    }),
    input: provided => ({
      ...provided,
      marginLeft: "0", // Add padding to the left of the input cursor
    }),
  }

  const meetingForOption = [
    // { value: 1, label: "Project", type: 1 },
    { value: 2, label: "Lead", type: 2 },
    { value: 3, label: "Introducer", type: 3 },
    { value: 4, label: "Customer", type: 4 },
    { value: 5, label: "Other", type: 0 },
  ]

  useEffect(() => {
    if (id) {
      const [type, paramid] = id?.split("-")
      const meeting = { value: 2, label: type, type: 2 }
      setMeetingFor(meeting)
      setIsmeetingFor(true)
      handleMeetingFor(meeting, paramid)
    }
  }, [])

  const location = [
    { value: "India", label: "India" },
    { value: "UK", label: "UK" },
  ]
  const Attendees = [
    { value: "Julius", label: "Julius", email: "julius@zithas.com", authId: 1 },
    { value: "Yesha", label: "Yesha", email: "yesha@zithas.com", authId: 2 },
    { value: "Eva", label: "Eva", email: "eva@zithas.com", authId: 3 },
    { value: "Janvi", label: "Janvi", email: "janvi@zithas.com", authId: 4 },
    { value: "Manav", label: "Manav", email: "manavk@zithas.com", authId: 5 },
  ]
  const [timeSlotsOption, setTimeSlotsOption] = useState([])
  const [timeSlots, setTimeSlots] = useState([
    {
      id: 1,
      value: "09:00 AM-09:30 AM",
      label: "09:00 AM - 09:30 AM",
      from: "09:00 AM",
    },
    {
      id: 2,
      value: "09:30 AM-10:00 AM",
      label: "09:30 AM - 10:00 AM",
      from: "09:30",
    },
    {
      id: 3,
      value: "10:00 AM-10:30 AM",
      label: "10:00 AM - 10:30 AM",
      from: "10:00 AM",
    },
    {
      id: 4,
      value: "10:30 AM-11:00 AM",
      label: "10:30 AM - 11:00 AM",
      from: "10:30 AM",
    },
    {
      id: 5,
      value: "11:00 AM-11:30 AM",
      label: "11:00 AM - 11:30 AM",
      from: "11:00 AM",
    },
    {
      id: 6,
      value: "11:30 AM-12:00 PM",
      label: "11:30 AM - 12:00 PM",
      from: "11:30 AM",
    },
    {
      id: 7,
      value: "12:00 PM-12:30 PM",
      label: "12:00 PM - 12:30 PM",
      from: "12:00 PM",
    },
    {
      id: 8,
      value: "12:30 PM-01:00 PM",
      label: "12:30 PM - 01:00 PM",
      from: "12:30 PM",
    },
    {
      id: 9,
      value: "01:00 PM-01:30 PM",
      label: "01:00 PM - 01:30 PM",
      from: "01:00 PM",
    },
    {
      id: 10,
      value: "01:30 PM-02:00 PM",
      label: "01:30 PM - 02:00 PM",
      from: "01:30 PM",
    },
    {
      id: 11,
      value: "02:00 PM-02:30 PM",
      label: "02:00 PM - 02:30 PM",
      from: "02:00 PM",
    },
    {
      id: 12,
      value: "02:30 PM-03:00 PM",
      label: "02:30 PM - 03:00 PM",
      from: "02:30 PM",
    },
    {
      id: 13,
      value: "03:00 PM-03:30 PM",
      label: "03:00 PM - 03:30 PM",
      from: "03:00 PM",
    },
    {
      id: 14,
      value: "03:30 PM-04:00 PM",
      label: "03:30 PM - 04:00 PM",
      from: "03:30 PM",
    },
    {
      id: 15,
      value: "04:00 PM-04:30 PM",
      label: "04:00 PM - 04:30 PM",
      from: "04:00 PM",
    },
    {
      id: 16,
      value: "04:30 PM-05:00 PM",
      label: "04:30 PM - 05:00 PM",
      from: "04:30 PM",
    },
  ])
  useEffect(() => {
    setTimeSlotsOption(timeSlots)
  }, [])
  const toggleaddmeeting = () => {
    setAddmeeting(!Addmeeting)
    setCrrlocation(null)
    setAddTitle("")
    setAddDescription("")
    setSelectedAttendees([])
    setAddAttendees(null)
    setIsImp(0)
    setMeetingFor(null)
    setMeetingForSub([{}])
    setMeetingForSubset(null)
    setIsmeetingFor(false)
    setMeetingForLabel("")
  }
  useEffect(() => {
    if (schedulerRef.current) {
      const schedulerInstance = schedulerRef.current.instance
      const handleAddAppointmentButtonClick = () => {
      }

      const customizeAppointmentCollector = () => {
        schedulerInstance.on("contentReady", () => {
          // const collector = schedulerInstance._appointmentPopup
          //   ._wrapper()
          //   .find(".dx-scheduler-appointment-collector")
          // if (collector.length > 0) {
          //   const addButton = collector.find(".dx-button")
          //   addButton.off("click").on("click", handleAddAppointmentButtonClick)
          // }
        })
      }

      customizeAppointmentCollector()
    }
  }, [])

  const toolTipData = {
    image: "../../assets/images/credentials.png",
    text: "test",
    year: "2024",
    director: "test",
    duration: "2year",
  }

  document.title = "Calender | Zithas Crm"

  const handlePropertyChange = useCallback(e => {
    if (e.name === "currentDate") {
      setCurrentDate(e.value)
    }
  }, [])
  function handleAppointmentClick(obj) {
    obj.component._isReady = false
    obj.cancel = true
    const tempAddclass = document.getElementsByClassName(
      "dx-scheduler-appointment-popup"
    )
    const startDate = moment(obj.appointmentData.startDate._d).format(
      "DD-MM-YYYY"
    )
    const endDate = moment(obj.appointmentData.endDate._d).format("DD-MM-YYYY")
    const tempValue = obj.appointmentData.emails.split(",")
    const localEmail = localStorage.getItem("auth_email")
    let isInTempValue = tempValue.includes(localEmail)
    const myEmail = localStorage.getItem("auth_email")
    const emails = obj.appointmentData.emails.split(",")
    const isMyEmail = emails.includes(myEmail)
    let names
    let avatars
    let mergedAvatars
    if (obj.appointmentData.avtar !== null) {
      names = obj.appointmentData.name?.split(",")
      avatars = obj.appointmentData.avtar?.split(",")
      mergedAvatars = names?.map((name, index) => ({
        id: index + 1,
        avatar: avatars[index],
        name: name,
      }))
    } else {
      mergedAvatars = staffData?.map((item, index) => ({
        id: index + 1,
        avatar: item.avatar,
        name: item.label,
      }))
    }
    const dataEdit = {
      id: obj.appointmentData.id,
      title: obj.appointmentData.title,
      main_title: obj.appointmentData.main_title,
      date: obj.appointmentData.date,
      link: obj.appointmentData.link,
      timeslot: obj.appointmentData.timeslot,
      classNames: obj.appointmentData.classNames,
      text: obj.appointmentData.text,
      startDate: startDate,
      endDate: endDate,
      name: obj.appointmentData.name,
      emails: obj.appointmentData.emails,
      isJoinButton: isMyEmail,
      avatar: mergedAvatars,
      subject: obj.appointmentData.subject,
    }

    setMeetingPopupEditData(dataEdit)
    setMeetingPopupEdit(true)
  }
  const appointmentRender = data => {
    // const [startTime, endTime] = data.data.appointmentData.timeslot.split("-")
    // Parse start and end times to get hours and minutes
    // const [startHours, startMinutes] = startTime.trim().split(":")
    const avtar = data.data.appointmentData.avtar?.split(",")
    const avtarname = data.data.appointmentData.name?.split(",")
    const mergedArray = avtar?.map((_, index) => {
      return {
        avtar: avtar[index],
        avtarname: avtarname[index],
      }
    })
    const myEmail = localStorage.getItem("auth_email")
    const emails = data.data.appointmentData.emails.split(",")
    const isMyEmail = emails.includes(myEmail)
    const tempValue = data.data.appointmentData.is_imp != 1 && isMyEmail
    return (
      <div
        id={`${
          data.data.appointmentData.is_imp != 1 &&
          isMyEmail &&
          "standard-bg-color"
        }`}
        className={`border-start border-2 border-white text-content-default h-100 overflow-y-visible 
          ${data.data.appointmentData.is_imp == 1 ? "bg-danger" : ""}`}
        // `meet-content-type-${data.data.appointmentData.type}`
      >
        <div className="hover-container ">
          <div className="d-flex justify-content-between">
            <div className="">
              <span style={{ fontSize: "11px" }}>
                {/* {data.data.appointmentData.name} */}
                {data.data.appointmentData.subject !== null
                  ? data.data.appointmentData.subject?.slice(0, 12)
                  : data.data.appointmentData.name?.slice(0, 12)}
                &nbsp; {/* {`(${startHours}:${startMinutes})`} */}
              </span>
            </div>
            {/* {data.data.appointmentData.type?.typeOf == "" && ( */}
            <div>
              <span>
                {mergedArray?.slice(0, 5).map((ob, i) => {
                  return (
                    <img
                      key={i + 1}
                      className="rounded-circle bg-secondary"
                      // src={`${configure.imgUrl}${ob}`}
                      src={
                        ob.avtar && ob.avtar !== "-"
                          ? `${configure.imgUrl}${ob.avtar}`
                          : userImg
                      }
                      width={17}
                      height={17}
                      title={ob.avtarname}
                      alt=""
                      style={{ marginLeft: "-5px" }}
                    />
                  )
                })}
              </span>
            </div>
            {/* )} */}
            {(data.data.appointmentData.type == 1 ||
              data.data.appointmentData.type == 2 ||
              data.data.appointmentData.type == 3 ||
              data.data.appointmentData.type == 5 ||
              data.data.appointmentData.type == 7 ||
              data.data.appointmentData.type == 9 ||
              data.data.appointmentData.type == 11 ||
              data.data.appointmentData.type == 13 ||
              data.data.appointmentData.type == 15 ||
              data.data.appointmentData.type == 17) && (
              <div className="d-flex justify-content-between ">
                <span>
                  <span>
                    {data.data.appointmentData.type == 1 && (
                      <i
                        className="bi bi-prescription2 fs-6"
                        title="Healthcare"
                      ></i>
                    )}
                    {data.data.appointmentData.type == 2 && (
                      <i className="bi bi-globe fs-6" title="Website"></i>
                    )}
                    {data.data.appointmentData.type == 3 && (
                      <img
                        src={cleaningImg}
                        alt="Cleaning services"
                        className="img-fluid white-image"
                        title="Cleaning services"
                      />
                    )}
                    {data.data.appointmentData.type == 5 && (
                      <i
                        className="bi bi-prescription fs-6"
                        title="Private-healthcare"
                      ></i>
                    )}
                    {data.data.appointmentData.type == 7 && (
                      <img
                        src={cleaningIndustryImg}
                        alt="Cleaning Industry"
                        className="img-fluid"
                        title="Cleaning Industry"
                      />
                    )}
                    {data.data.appointmentData.type == 9 && (
                      <img
                        src={ITLandingPage}
                        alt="Dedicated Developers - UK Landing Page"
                        className="img-fluid"
                        width={20}
                        height={20}
                        title="Dedicated Developers"
                      />
                    )}
                    {data.data.appointmentData.type == 11 && (
                      <img
                        src={property}
                        alt="property-consultant"
                        className="img-fluid"
                        width={20}
                        height={20}
                        title="property-consultant"
                      />
                    )}
                    {data.data.appointmentData.type == 13 && (
                      <img
                        src={propertyDeal}
                        alt="property-consultant-services"
                        className="img-fluid"
                        width={20}
                        height={20}
                        title="property-consultant-services"
                      />
                    )}
                    {data.data.appointmentData.type == 15 && (
                      <img
                        src={domicilary}
                        alt="domicilary-care"
                        className="img-fluid"
                        title="domicilary-care"
                        width={20}
                        height={20}
                      />
                    )}
                    {data.data.appointmentData.type == 17 && (
                      <img
                        src={residential}
                        alt="residential-care"
                        className="img-fluid"
                        title="residential-care"
                        width={20}
                        height={20}
                      />
                    )}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  const AppointmentTooltip = e => {
    return null
  }

  const timezonemoment = timezone.tz.guess()
  // const fetchFilterPerson = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       // url: `https://api.zithas.com/api/staff`,
  //       url: `${configure.apiUrl}/teamUsers`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       console.log("teamFilterOption", response)
  //       const optionFilter = response.data.data.credentials?.map(item => {
  //         return {
  //           value: item.name,
  //           label: item.name,
  //           email: item.email,
  //           auth_id: item.user_id,
  //         }
  //       })
  //       const additional = {
  //         value: "All Meetings",
  //         label: "All Meetings",
  //         email: "All-Meetings",
  //         type: "all",
  //         auth_id: null,
  //       }
  //       const updateOptionFilter = [...optionFilter, additional]
  //       setFilterData(updateOptionFilter)
  //       setLoading(false)
  //     })
  //   } catch (err) {
  //     setLoading(false)
  //   }
  // }

  const fetchBookings = () => {
    setLoading(true)
    axios
      .get(`${configure.apiUrl}/bookings`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setLoading(false)
        const meetData = res.data.data
          .filter(item => item.join_url !== null && item.time_slot !== null)
          .map(ob => {
            const [startTime, endTime] = ob.time_slot.split("-")
            const [startHours, startMinutes, startPeriod] = startTime
              .trim()
              .split(/:|\s/)
            const [endHours, endMinutes, endPeriod] = endTime
              .trim()
              .split(/:|\s/)
            const convertTo24Hour = (hours, period) => {
              if (period === "AM") {
                return hours === "12" ? "00" : hours.padStart(2, "0")
              } else if (period === "PM") {
                return hours === "12"
                  ? "12"
                  : (parseInt(hours, 10) + 12).toString()
              }
              return hours
            }
            const startHours24 = convertTo24Hour(startHours, startPeriod)
            const endHours24 = convertTo24Hour(endHours, endPeriod)

            const startDate = moment(ob.booking_date)
            startDate.set({
              hour: parseInt(startHours24),
              minute: parseInt(startMinutes),
              second: 0,
              millisecond: 0,
            })

            const endDate = moment(ob.booking_date)
            endDate.set({
              hour: parseInt(endHours24),
              minute: parseInt(endMinutes),
              second: 0,
              millisecond: 0,
            })
            let tempEmails
            if (
              ob.type == 1 ||
              ob.type == 2 ||
              ob.type == 3 ||
              ob.type == 4 ||
              ob.type == 5 ||
              ob.type == 6 ||
              ob.type == 7 ||
              ob.type == 8 ||
              ob.type == 9 ||
              ob.type == 10 ||
              ob.type == 11 ||
              ob.type == 12 ||
              ob.type == 13 ||
              ob.type == 14 ||
              ob.type == 15 ||
              ob.type == 16 ||
              ob.type == 17 ||
              ob.type == 18
            ) {
              tempEmails = `admin@zithas.com,julius@zithas.com,eva@zithas.co.uk,eva@zithas.com,edward@zithas.co.uk,eba@ebassociates.co.uk,${ob.email}`
            } else {
              tempEmails = ob.email
            }

            return {
              id: ob.id,
              title: ob.subject
                ? ob.subject
                : `Initial Meeting with ${ob.name}`,
              main_title: "Microsoft Teams Meeting",
              assign_Name: "Julius",
              date: ob.booking_date,
              link: ob.join_url,
              timeslot: ob.time_slot != null ? ob.time_slot : "",
              classNames: "bg-calender-content h-100",
              startDate: startDate,
              endDate: endDate,
              type: ob.type,
              name: ob.name,
              is_imp: ob.is_imp,
              emails: tempEmails,
              avtar: ob.images,
              subject: ob.subject,
            }
          })
        setAllBookingData(meetData)
      })
      .catch(err => console.log(err))
  }
  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        // url: `https://api.zithas.com/api/staff`,
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("staff", response)
        // email
        const allStaffOption = response.data.data.map(obj => {
          return {
            id: obj.id,
            name: obj.name,
            email: obj.email,
            avatar: obj.avatar,
          }
        })
        setAllStaffDatas(allStaffOption)
        const staffOption = response.data.data
          .filter(item => {
            const emails = [
              "julius@zithas.com",
              // "yesha@zithas.co.uk",
              // "yesha@zithas.com",
              "eva@zithas.co.uk",
              "eva@zithas.com",
              // "ryan.davies@zithas.co.uk",
              // "rayan@zithas.com",
              "edward@zithas.co.uk",
              "eba@ebassociates.co.uk",
            ]
            return emails.includes(item.email)
          })
          .map(obj => {
            return {
              value: obj.name,
              label: obj.name,
              email: obj.email,
              avatar: obj.avatar !== null ? obj.avatar : "-",
            }
          })
        // console.log(staffOption)
        const optionFilter = response.data.data
          .filter(item => {
            const emails = [
              "julius@zithas.com",
              "yesha@zithas.co.uk",
              "yesha@zithas.com",
              "eva@zithas.co.uk",
              "eva@zithas.com",
              "ryan.davies@zithas.co.uk",
              "rayan@zithas.com",
              "edward@zithas.co.uk",
              "eba@ebassociates.co.uk",
              "support@zithas.com",
            ]
            return emails.includes(item.email)
          })
          .map((obj, i) => {
            return {
              value: obj.name,
              label: obj.name,
              email: obj.email,
              type: i + 1,
              auth_id: obj.id,
            }
          })
        const additional = {
          value: "All Meetings",
          label: "All Meetings",
          email: "All-Meetings",
          type: "all",
          auth_id: null,
        }

        const updateOptionFilter = [...optionFilter, additional]
        // setFilterData(updateOptionFilter)

        setStaffDatas(staffOption)
        const attendOption = response.data.data
          .filter(item => {
            const emails = [
              "julius@zithas.com",
              "yesha@zithas.co.uk",
              "yesha@zithas.com",
              "eva@zithas.co.uk",
              "eva@zithas.com",
              "ryan.davies@zithas.co.uk",
              "rayan@zithas.com",
              "edward@zithas.co.uk",
              "eba@ebassociates.co.uk",
            ]

            return emails.includes(item.email)
          })
          .map(obj => {
            return {
              value: obj.name,
              label: obj.name,
              email: obj.email,
              avatar: obj.avatar !== null ? obj.avatar : "-",
            }
          })
        setAttendOption(attendOption)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const checkElement = () => {
    const scrollTimeIndicator = document.querySelector(
      ".dx-scheduler-date-time-indicator"
    )

    if (scrollTimeIndicator) {
      const viewportHeight = window.innerHeight
      const rect = scrollTimeIndicator.getBoundingClientRect()
      const scrollPosition =
        rect.top - viewportHeight / 2 + rect.height / 2 - -70

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      })
    }
  }
  useEffect(() => {
    checkElement()
    // setTimeout(checkElement, 200)
  }, [])

  useEffect(() => {
    fetch()
    const meeDatat = async () => {
      setLoading(true)
      try {
        const response = await axios({
          method: "get",
          url: `${configure.apiUrl}/teamUsers`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(async response => {
          setLoading(false)
          const optionFilter = response.data.data.credentials?.map(item => {
            return {
              value: item.name,
              label: item.name,
              email: item.email,
              auth_id: item.user_id,
            }
          })
          const additional = {
            value: "All Meetings",
            label: "All Meetings",
            email: "All-Meetings",
            type: "all",
            auth_id: null,
          }
          const updateOptionFilter = [...optionFilter, additional]
          setFilterData(updateOptionFilter)

          const authId = localStorage.getItem("auth_id")
          const isAuth = updateOptionFilter?.some(itms => {
            return itms.auth_id == authId
          })
          const getSet = updateOptionFilter?.filter(itms => {
            return itms.auth_id == authId
          })
          if (isAuth) {
            setFilterDataOption(getSet[0])
            setLoading(true)
            try {
              setLoading(true)
              const formData = new FormData()
              formData.append("user_id", authId)
              const response = await axios({
                method: "post",
                url: `${configure.apiUrl}/fetchTeamMeetings`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              })
              if (response) {
                // response.data.value
                const meetData = response.data?.map((teamItem, index) => {
                  const optionFilter = allStaffData
                    ?.filter(item => {
                      const emails = teamItem.attendees.map(
                        attenName => attenName.emailAddress.address
                      )
                      return emails.includes(item.email)
                    })
                    .map((obj, i) => {
                      return {
                        value: obj.name,
                        avtar: obj.avatar,
                        email: obj.email,
                        type: i + 1,
                        auth_id: obj.id,
                      }
                    })
                  const startDa =
                    teamItem.originalStartTimeZone == "India Standard Time"
                      ? moment(teamItem.start.dateTime).tz("Asia/Kolkata")
                      : teamItem.start.dateTime
                  const endDa =
                    teamItem.originalStartTimeZone == "India Standard Time"
                      ? moment(teamItem.end.dateTime).tz("Asia/Kolkata")
                      : teamItem.end.dateTime
                  return {
                    id: index + 1,
                    title: teamItem.subject
                      ? teamItem.subject
                      : `Initial Meeting with ${teamItem.organizer}`,
                    main_title: teamItem.location.displayName,
                    // main_title: "Microsoft Teams Meeting",
                    assign_Name: teamItem.organizer,
                    date: moment(teamItem.start.dateTime),
                    link: teamItem.onlineMeeting?.joinUrl,
                    // timeslot: `${moment
                    //   .utc(teamItem.start.dateTime)
                    //   .utcOffset("+05:30")
                    //   .format("hh:mm a")} : ${moment
                    //   .utc(teamItem.end.dateTime)
                    //   .utcOffset("+05:30")
                    //   .format("hh:mm a")}`,
                    timeslot: `${moment(startDa).format(
                      "hh:mm a"
                    )} : ${moment(endDa).format("hh:mm a")}`,
                    classNames: "bg-calender-content h-100",
                    startDate: startDa,
                    endDate: endDa,
                    // startDate: moment
                    //   .utc(teamItem.start.dateTime)
                    //   .utcOffset("+05:30"),
                    // endDate: moment.utc(teamItem.end.dateTime).utcOffset("+05:30"),
                    type: teamItem.type,
                    name: teamItem.attendees
                      .map(attenName => attenName.emailAddress.name)
                      .toString(),
                    is_imp: teamItem.importance == "high" ? 1 : 0,
                    emails: teamItem.attendees
                      .map(attenName => attenName.emailAddress.address)
                      .toString(),
                    avtar: optionFilter
                      ?.map(avt => {
                        return avt.avtar
                      })
                      .toString(),
                    subject: teamItem.subject,
                  }
                })
                const getImp = response.data.map(itttt => itttt.sensitivity)
                setAllBookingData(meetData)
                setLoading(false)
              }
            } catch (error) {
              setLoading(false)
              console.log(error)
            }
          } else {
            fetchBookings()
          }
        })
      } catch (err) {
        setLoading(false)
      }
    }
    // fetchTeamUsers()
    meeDatat()
  }, [])

  const handleAppointmentDblClick = obj => {
    // console.log("click handleAppointmentDblClick")
    obj.cancel = true
    const clickedAppointmentData = obj.appointmentData
    // Perform any action based on the clicked appointment data
    setMeetingPopupEdit(true)
    const startDate = moment(obj.appointmentData.startDate).format("DD-MM-YYYY")
    const endDate = moment(obj.appointmentData.endDate).format("DD-MM-YYYY")
    const myEmail = localStorage.getItem("auth_email")
    const emails = obj.appointmentData.emails.split(",")
    const isMyEmail = emails.includes(myEmail)
    let names = obj.appointmentData.name?.split(",")
    let avatars = obj.appointmentData.avtar?.split(",")
    let mergedAvatars = names.map((name, index) => ({
      id: index + 1,
      avatar: avatars[index],
      name: name,
    }))
    const dataEdit = {
      id: obj.appointmentData.id,
      title: obj.appointmentData.title,
      main_title: obj.appointmentData.main_title,
      date: obj.appointmentData.date,
      link: obj.appointmentData.link,
      timeslot: obj.appointmentData.timeslot,
      classNames: obj.appointmentData.classNames,
      text: obj.appointmentData.text,
      startDate: startDate,
      endDate: endDate,
      name: obj.appointmentData.name,
      emails: obj.appointmentData.emails,
      isJoinButton: isMyEmail,
      avatar: mergedAvatars,
      subject: obj.appointmentData.subject,
    }
    setMeetingPopupEditData(dataEdit)
  }

  const aryIanaTimeZones = [
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Jerusalem",
    "Asia/Kolkata",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay",
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
  ]
  const timeZonesWithLabelValue = aryIanaTimeZones.map(timeZone => ({
    label: timeZone,
    value: timeZone,
  }))

  // useEffect(() => {
  //   const checkElement = () => {
  //     const scrollTimeIndicator = document.querySelector(
  //       ".dx-scheduler-date-time-indicator"
  //     )
  //     if (scrollTimeIndicator) {
  //       const viewportHeight = window.innerHeight
  //       const rect = scrollTimeIndicator.getBoundingClientRect()
  //       const scrollPosition = rect.top - viewportHeight / 2 + rect.height / 2
  //       window.scrollTo({
  //         top: scrollPosition,
  //         behavior: "smooth",
  //       })
  //     }
  //   }
  //   checkElement()
  //   setTimeout(checkElement, 200)
  // }, [])

  const customizeTooltip = tooltipInfo => {
    return (
      <div className="custom-collector text-white">
        {tooltipInfo.appointmentCount} more
      </div>
    )
  }
  const tooltipfn = e => {
    // console.log("tooltipfn", e)
  }
  const handleChange = selectedOption => {
    setSelectedTimeSlot(selectedOption)
  }
  const handleChangeAttendees = selectedOption => {
    setAddAttendees([...selectedOption])
  }

  const handleMeetingForSubset = obj => {
    setMeetingForSubset(obj)
  }
  const handleMeetingFor = async (meeting, paramid) => {
    setMeetingFor(meeting)
    setMeetingForSub([{}])
    if (meeting.type == 0) {
      setIsmeetingFor(false)
      setMeetingForLabel("")
    }
    if (meeting.type == 4) {
      setLoading(true)
      setMeetingForLabel(meeting.label)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_customer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const modifyArray = response.data.data.map((ob, i) => {
          // const data = JSON.parse(ob.person_details)
          // console.log(data)
          const convertData = {
            value: i + 1,
            label: ob.company,
            formDataName: "customer_id",
            leadId: ob.id,
          }
          return convertData
        })
        setIsmeetingFor(true)
        setMeetingForSub(modifyArray)
        setLoading(false)
      }
    }
    if (meeting.type == 3) {
      setLoading(true)
      setMeetingForLabel(meeting.label)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const modifyArray = response.data.data.map((ob, i) => {
          // const data = JSON.parse(ob.person_details)
          // console.log(data)
          const convertData = {
            value: i + 1,
            label: ob.company,
            formDataName: "introducer",
            leadId: ob.id,
          }
          return convertData
        })
        setIsmeetingFor(true)
        setMeetingForSub(modifyArray)
        setLoading(false)
      }
    }
    if (meeting.type == 2) {
      setLoading(true)
      setMeetingForLabel(meeting.label)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("location", "")
      formData.append("asigned", "")
      formData.append("status", "")
      formData.append("source", "")
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const modifyArray = response.data.data.map((ob, i) => {
          const data = JSON.parse(ob.person_details)
          const temparrName = data[0]?.person_name
            ? data[0]?.person_name
            : "N/A"

          const convertData = {
            value: 2,
            label: `${ob.company ? ob.company : "N/A"} - ${temparrName}`,
            // label: data[0]?.person_name,
            formDataName: "lead_id",
            leadId: ob.id,
          }
          return convertData
        })
        setIsmeetingFor(true)
        setMeetingForSub(modifyArray)
        if (paramid !== null) {
          const filterarray = modifyArray.filter(obj => {
            return obj.leadId == paramid
          })
          setMeetingForSubset(filterarray)
        }
        setLoading(false)
      }
    }
    // if (meeting.type == 1) {
    //   setLoading(true)
    //   setMeetingForLabel(meeting.label)
    //   const formData = new FormData()
    //   formData.append("user_id", localStorage.getItem("auth_id"))
    //   formData.append("location", "")
    //   formData.append("asigned", "")
    //   formData.append("status", "")
    //   formData.append("source", "")
    //   const response = await axios({
    //     method: "post",
    //     url: `${configure.apiUrl}/lead`,
    //     data: formData,
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   if (response) {
    //     console.log("lead", response)
    //     const modifyArray = response.data.data.map((ob, i) => {
    //       const data = JSON.parse(ob.person_details)
    //       console.log(data)
    //       const convertData = {
    //         value: i + 1,
    //         label: data[0]?.person_name,
    //         formDataName: "lead",
    //         leadId: ob.id,
    //       }
    //       return convertData
    //     })
    //     setIsmeetingFor(true)
    //     setMeetingForSub(modifyArray)
    //     setLoading(false)
    //   }
    // }
  }

  const dataCellComponent = async e => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("module_id", 67)
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          const selectedDate = moment(e.cellData.startDate)
          const startFromTime = moment(selectedDate).format("hh:mm A")
          const startEndTime = moment(e.cellData.endDate).format("hh:mm A")
          const setDate = moment(selectedDate).format("YYYY-MM-DD")
          setAddDate(setDate)
          if (setDate == moment().format("YYYY-MM-DD")) {
            // const location = [
            //   { value: "India", label: "India" },
            //   { value: "UK", label: "UK" },
            // ]

            if (crrlocation != null) {
              let todaySlots
              if (crrlocation.value == "India") {
                todaySlots = timeSlots.filter(slot => {
                  return moment()
                    .add(30, "minute")
                    .isBefore(moment(slot.from, "hh:mm A"))
                })
              } else {
                todaySlots = timeSlots.filter(slot => {
                  const currentLondonTime = new Date().toLocaleString("en-US", {
                    timeZone: "Europe/London",
                  })
                  let londonTime = new Date(currentLondonTime)
                  londonTime.setMinutes(londonTime.getMinutes() + 30)
                  const slotTime = parseTimeString(slot.from)
                  return londonTime < slotTime
                })
              }
              setTimeSlotsOption(todaySlots)
            } else {
              setTimeSlotsOption([])
            }

            // setTimeSlots(todaySlots)
          }

          if (selectedDate >= new Date()) {
            setAddmeeting(true)
          } else {
            Swal.fire({
              icon: "warning",
              text: "You cannot book a meeting for a date that is in the past.",
            })
          }

          const matchingSlot = timeSlots.filter(slot => {
            const temp = `${startFromTime} - ${startEndTime}`
            return slot.label.trim() == temp.trim()
          })
          if (matchingSlot.length !== 0) {
            setSelectedTimeSlot(matchingSlot[0])
          } else {
            setSelectedTimeSlot(null)
          }
        } else {
          setAccessDenied(true)
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // const editingOptions = {
  //   allowDragging: false,
  //   allowResizing: false,
  // }

  // Add Meeting Integration
  const handleAddMeeting = async e => {
    e.preventDefault()

    if (addAttendees !== null && selectedTimeSlot !== null) {
      let tempArray
      let tempName
      let tempAvatar

      if (addAttendees !== null && selectedAttendees !== null) {
        tempArray = [...addAttendees, ...selectedAttendees]
        tempName = [...addAttendees, ...selectedAttendees]
        tempAvatar = [...addAttendees, ...selectedAttendees]
      } else {
        tempArray = addAttendees
        tempName = addAttendees
        tempAvatar = addAttendees
      }
      setLoading(true)
      const tempAvtar = tempAvatar?.map(field => field.avatar).toString()
      const additionalAvtar = localStorage.getItem("auth_avt")
      const additionalEmail = localStorage.getItem("auth_email")
      const additionalName = localStorage.getItem("auth_name")
      const attenEmails = tempArray.map(field => field.email).toString()
      const updatedAttenEmails = additionalEmail + "," + attenEmails
      const attenName = tempName.map(field => field.value).toString()
      const updatedAttenNames = additionalName + "," + attenName
      const updatedAttenAvtar = additionalAvtar + "," + tempAvtar
      const formData = new FormData()

      if (id) {
        const [type, paramid] = id?.split("-")
        formData.append("lead_id", paramid)
        formData.append("type", `${type}`)
      } else {
        formData.append("lead_id", meetingFor?.type)
        formData.append("type", meetingFor?.label)
        formData.append(
          meetingForSubset?.formDataName,
          meetingForSubset?.leadId
        )
      }

      formData.append("date", addDate)
      formData.append("time", selectedTimeSlot.value)
      formData.append("time_id", selectedTimeSlot.id)
      formData.append("content", addDescription)
      formData.append("names", updatedAttenNames)
      formData.append("subject", addTitle)
      formData.append("emails", updatedAttenEmails)
      formData.append("images", updatedAttenAvtar)
      formData.append("is_imp", isImp)
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("location", crrlocation.value)
      formData.append("timezone", selectedTimeZone.value)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/createMeetings`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response.data.success) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Your meeting has been successfully booked.",
          }).then(() => {
            setAddmeeting(false)
          })
          const meetData = response.data.data
            .filter(item => item.join_url !== null && item.time_slot !== null)
            .map(ob => {
              const [startTime, endTime] = ob.time_slot.split("-")
              const [startHours, startMinutes, startPeriod] = startTime
                .trim()
                .split(/:|\s/)
              const [endHours, endMinutes, endPeriod] = endTime
                .trim()
                .split(/:|\s/)
              const convertTo24Hour = (hours, period) => {
                if (period === "AM") {
                  return hours === "12" ? "00" : hours.padStart(2, "0")
                } else if (period === "PM") {
                  return hours === "12"
                    ? "12"
                    : (parseInt(hours, 10) + 12).toString()
                }
                return hours
              }
              const startHours24 = convertTo24Hour(startHours, startPeriod)
              const endHours24 = convertTo24Hour(endHours, endPeriod)

              const startDate = moment(ob.booking_date)
              startDate.set({
                hour: parseInt(startHours24),
                minute: parseInt(startMinutes),
                second: 0,
                millisecond: 0,
              })

              const endDate = moment(ob.booking_date)
              endDate.set({
                hour: parseInt(endHours24),
                minute: parseInt(endMinutes),
                second: 0,
                millisecond: 0,
              })
              let tempEmails
              if (
                ob.type == 1 ||
                ob.type == 2 ||
                ob.type == 3 ||
                ob.type == 4 ||
                ob.type == 5 ||
                ob.type == 6 ||
                ob.type == 7 ||
                ob.type == 8 ||
                ob.type == 9 ||
                ob.type == 10 ||
                ob.type == 11 ||
                ob.type == 12 ||
                ob.type == 13 ||
                ob.type == 14 ||
                ob.type == 15 ||
                ob.type == 16 ||
                ob.type == 17 ||
                ob.type == 18
              ) {
                tempEmails = `admin@zithas.com,julius@zithas.com,eva@zithas.co.uk,eva@zithas.com,edward@zithas.co.uk,eba@ebassociates.co.uk,${ob.email}`
              } else {
                tempEmails = ob.email
              }
              return {
                id: ob.id,
                title: ob.subject
                  ? ob.subject
                  : `Initial Meeting with ${ob.name}`,
                main_title: "Microsoft Teams Meeting",
                assign_Name: "Julius",
                date: ob.booking_date,
                link: ob.join_url,
                timeslot: ob.time_slot != null ? ob.time_slot : "",
                classNames: "bg-calender-content h-100",
                startDate: startDate,
                endDate: endDate,
                type: ob.type,
                name: ob.name,
                is_imp: ob.is_imp,
                emails: tempEmails,
                avtar: ob.images,
                subject: ob.subject,
              }
            })
          setAllBookingData(meetData)
          setAddTitle("")
          setCrrlocation(null)
          setSelectedTimeZone({
            label: "Europe/London",
            value: "Europe/London",
          })
          setIsImp(0)
          setAddAttendees(null)
          setAddDate("")
          selectedTimeSlot(null)
          setSelectedAttendees([])
          setMeetingFor(null)
          meetingForSubset(null)
          setLoading(false)
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            text: "Something went wrong please try again.",
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please ensure all mandatory fields are filled out before scheduling the meeting.",
      })
    }
  }
  // ...............................................................
  const [options, setOptions] = useState([])
  const [selectedAttendees, setSelectedAttendees] = useState([])

  const handleSelectChange = selectedOptions => {
    setSelectedAttendees(selectedOptions || [])
  }

  const handleCreate = inputValue => {
    const newOption = {
      value: "-",
      label: inputValue,
      email: inputValue,
      avatar: "-",
    }
    setOptions([...options, newOption])
    setSelectedAttendees([...selectedAttendees, newOption])
  }
  function parseTimeString(timeStr) {
    // Assuming timeStr is in "hh:mm A" format
    const [time, period] = timeStr.split(" ")
    let [hours, minutes] = time.split(":")

    hours = parseInt(hours)
    minutes = parseInt(minutes)

    if (period === "PM" && hours < 12) {
      hours += 12
    } else if (period === "AM" && hours === 12) {
      hours = 0
    }

    const date = new Date()
    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)

    return date
  }
  const handleChangeLocation = option => {
    setCrrlocation(option)
  }
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD")
    if (addDate == currentDate) {
      if (crrlocation != null) {
        let todaySlots
        if (crrlocation.value == "India") {
          todaySlots = timeSlots.filter(slot => {
            return moment()
              .add(30, "minute")
              .isBefore(moment(slot.from, "hh:mm A"))
          })
        } else {
          todaySlots = timeSlots.filter(slot => {
            const currentLondonTime = new Date().toLocaleString("en-US", {
              timeZone: "Europe/London",
            })
            let londonTime = new Date(currentLondonTime)
            londonTime.setMinutes(londonTime.getMinutes() + 30)
            const slotTime = parseTimeString(slot.from)
            return londonTime < slotTime
          })
        }
        setTimeSlotsOption(todaySlots)
      }
    } else {
      setTimeSlotsOption(timeSlots)
    }
  }, [crrlocation, addDate])
  // get staff
  const [staffData, setStaffDatas] = useState(null)
  const [allStaffData, setAllStaffDatas] = useState(null)
  const [attendOption, setAttendOption] = useState(null)
  const [filterData, setFilterData] = useState(null)
  const [filterDataOption, setFilterDataOption] = useState(null)
  const [staffSelectedoption, setStaffSelectedoption] = useState(null)

  const handleChangeStaff = option => {
    setStaffSelectedoption(option)
  }
  const handleChangeFilter = async option => {
    setFilterDataOption(option)
    setLoading(true)
    if (option.type == "all") {
      try {
        const response = await axios({
          method: "get",
          url: `${configure.apiUrl}/bookings`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          const staffOption = response.data.data.filter(item => {
            let itemEmails = item.email.split(",").map(email => email.trim())
            const emails = option.email.split(",")
            let matches = emails.some(email => itemEmails.includes(email))
            if (option.type != "all") {
              return matches
            } else {
              return item
            }
          })
          const meetData = staffOption
            .filter(item => item.join_url !== null && item.time_slot !== null)
            .map(ob => {
              const [startTime, endTime] = ob.time_slot.split("-")
              const [startHours, startMinutes, startPeriod] = startTime
                .trim()
                .split(/:|\s/)
              const [endHours, endMinutes, endPeriod] = endTime
                .trim()
                .split(/:|\s/)
              const convertTo24Hour = (hours, period) => {
                if (period === "AM") {
                  return hours === "12" ? "00" : hours.padStart(2, "0")
                } else if (period === "PM") {
                  return hours === "12"
                    ? "12"
                    : (parseInt(hours, 10) + 12).toString()
                }
                return hours
              }
              const startHours24 = convertTo24Hour(startHours, startPeriod)
              const endHours24 = convertTo24Hour(endHours, endPeriod)

              const startDate = moment(ob.booking_date)
              startDate.set({
                hour: parseInt(startHours24),
                minute: parseInt(startMinutes),
                second: 0,
                millisecond: 0,
              })

              const endDate = moment(ob.booking_date)
              endDate.set({
                hour: parseInt(endHours24),
                minute: parseInt(endMinutes),
                second: 0,
                millisecond: 0,
              })
              let tempEmails
              if (
                ob.type == 1 ||
                ob.type == 2 ||
                ob.type == 3 ||
                ob.type == 4 ||
                ob.type == 5 ||
                ob.type == 6 ||
                ob.type == 7 ||
                ob.type == 8 ||
                ob.type == 9 ||
                ob.type == 10 ||
                ob.type == 11 ||
                ob.type == 12 ||
                ob.type == 13 ||
                ob.type == 14 ||
                ob.type == 15 ||
                ob.type == 16 ||
                ob.type == 17 ||
                ob.type == 18
              ) {
                tempEmails = `admin@zithas.com,julius@zithas.com,eva@zithas.co.uk,eva@zithas.com,edward@zithas.co.uk,eba@ebassociates.co.uk,${ob.email}`
              } else {
                tempEmails = ob.email
              }
              return {
                id: ob.id,
                title: ob.subject
                  ? ob.subject
                  : `Initial Meeting with ${ob.name}`,
                main_title: "Microsoft Teams Meeting",
                assign_Name: "Julius",
                date: ob.booking_date,
                link: ob.join_url,
                timeslot: ob.time_slot != null ? ob.time_slot : "",
                classNames: "bg-calender-content h-100",
                startDate: startDate,
                endDate: endDate,
                type: ob.type,
                name: ob.name,
                is_imp: ob.is_imp,
                emails: tempEmails,
                avtar: ob.images,
                subject: ob.subject,
              }
            })
          setAllBookingData(meetData)
          setLoading(false)
        }
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    } else {
      try {
        const formData = new FormData()
        formData.append("user_id", option.auth_id)
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/fetchTeamMeetings`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          // response.data.value
          const meetData = response.data?.map((teamItem, index) => {
            const optionFilter = allStaffData
              ?.filter(item => {
                const emails = teamItem.attendees.map(
                  attenName => attenName.emailAddress.address
                )
                return emails.includes(item.email)
              })
              .map((obj, i) => {
                return {
                  value: obj.name,
                  avtar: obj.avatar,
                  email: obj.email,
                  type: i + 1,
                  auth_id: obj.id,
                }
              })
            const startDa =
              teamItem.originalStartTimeZone == "India Standard Time"
                ? moment(teamItem.start.dateTime).tz("Asia/Kolkata")
                : teamItem.start.dateTime
            const endDa =
              teamItem.originalStartTimeZone == "India Standard Time"
                ? moment(teamItem.end.dateTime).tz("Asia/Kolkata")
                : teamItem.end.dateTime


            return {
              id: index + 1,
              title: teamItem.subject
                ? teamItem.subject
                : `Initial Meeting with ${teamItem.organizer}`,
              main_title: teamItem.location.displayName,
              // main_title: "Microsoft Teams Meeting",
              assign_Name: teamItem.organizer,
              date: moment(teamItem.start.dateTime),
              link: teamItem.onlineMeeting?.joinUrl,
              // timeslot: `${moment
              //   .utc(teamItem.start.dateTime)
              //   .utcOffset("+05:30")
              //   .format("hh:mm a")} : ${moment
              //   .utc(teamItem.end.dateTime)
              //   .utcOffset("+05:30")
              //   .format("hh:mm a")}`,
              timeslot: `${moment(startDa).format("hh:mm a")} : ${moment(
                endDa
              ).format("hh:mm a")}`,
              classNames: "bg-calender-content h-100",
              startDate: startDa,
              endDate: endDa,
              // startDate: moment
              //   .utc(teamItem.start.dateTime)
              //   .utcOffset("+05:30"),
              // endDate: moment.utc(teamItem.end.dateTime).utcOffset("+05:30"),
              type: teamItem.type,
              name: teamItem.attendees
                .map(attenName => attenName.emailAddress.name)
                .toString(),
              is_imp: teamItem.importance == "high" ? 1 : 0,
              emails: teamItem.attendees
                .map(attenName => attenName.emailAddress.address)
                .toString(),
              avtar: optionFilter
                ?.map(avt => {
                  return avt.avtar
                })
                .toString(),
              subject: teamItem.subject,
            }
          })
          setAllBookingData(meetData)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }
  const getTodayDate = () => {
    const today = new Date()
    let month = String(today.getMonth() + 1)
    let day = String(today.getDate())
    const year = today.getFullYear()

    // Add leading zeros if needed
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return `${year}-${month}-${day}`
  }
  // function parseTimeString(timeStr) {
  //   // Assuming timeStr is in "hh:mm A" format
  //   const [time, period] = timeStr.split(" ");
  //   let [hours, minutes] = time.split(":");

  //   hours = parseInt(hours);
  //   minutes = parseInt(minutes);

  //   if (period === "PM" && hours < 12) {
  //     hours += 12;
  //   } else if (period === "AM" && hours === 12) {
  //     hours = 0;
  //   }

  //   const date = new Date();
  //   date.setHours(hours);
  //   date.setMinutes(minutes);
  //   date.setSeconds(0);

  //   return date;
  // }

  // const todaySlots = timeSlots.filter((slot) => {
  //   const currentLondonTime = new Date().toLocaleString("en-US", {
  //     timeZone: "Europe/London",
  //   });
  //   let londonTime = new Date(currentLondonTime);
  //   londonTime.setMinutes(londonTime.getMinutes() + 30);
  //   const slotTime = parseTimeString(slot.from);
  //   return londonTime < slotTime;
  // });

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content dx-viewport-calender-2 margin-top-calender-header py-0">
        <Container fluid style={{ maxWidth: "100vw" }}>
          {/* <Breadcrumbs title={"Calender"} breadcrumbItem={"Calender"} /> */}
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody className="p-0">
                  <div className="position-fixed filter-select-calender">
                    <ReactSelect
                      // onChange={e => onComapanyChg(e)}
                      value={filterDataOption}
                      placeholder="Choose Meeting Filter"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={filterData}
                      onChange={handleChangeFilter}
                    />
                  </div>
                  <div className={`shadow-sm ${scrolled && "fixed-calender"}`}>
                    <Scheduler
                      ref={schedulerRef}
                      timeZone={timezonemoment}
                      currentDate={currentDate}
                      onOptionChanged={handlePropertyChange}
                      textExpr="title"
                      defaultCurrentView="week"
                      recurrenceRuleExpr="recurrence"
                      dataSource={allBookingData}
                      onAppointmentClick={handleAppointmentClick}
                      appointmentComponent={appointmentRender}
                      showAllDayPanel={false}
                      appointmentTooltipKeyFn={tooltipfn}
                      onAppointmentDblClick={handleAppointmentDblClick}
                      appointmentCollectorKeyFn={customizeTooltip}
                      onCellClick={dataCellComponent}
                      appointmentDragging={false}
                      // dropDownAppointmentRender={() => {
                      //   return null
                      // }}
                      // dropDownAppointmentComponent={() => {
                      //   return null
                      // }}
                      // dropDownAppointmentKeyFn={() => {
                      //   return null
                      // }}
                      // appointmentDragging={false}
                      // allowUpdating={false}
                      // editing={{ allowDragging: false, allowResizing: false }}

                      // ..................................................................
                      // showCurrentTimeIndicator={true}
                      // shadeUntilCurrentTime={true}
                      // allDayExpr="dayLong"
                      // appointmentTooltipComponent={AppointmentTooltip}
                      // appointmentCollectorKeyFn={customizeTooltip}
                      // appointmentCollectorComponent={customizeTooltip}
                      // appointmentCollectorRender={customizeTooltip}
                      // appointmentCollectorTemplate={customizeTooltip}
                      // dataCellComponent={dataCellComponent}
                      // dateCellKeyFn={dataCellComponent}
                      // dateCellRender={dataCellComponent}
                      // dateCellTemplate={dataCellComponent}
                    >
                      <View
                        type="day"
                        startDayHour={0}
                        endDayHour={24}
                        useNative={false}
                      />
                      <View
                        type="week"
                        startDayHour={0}
                        endDayHour={24}
                        useNative={false}
                      />
                      <View type="month" />
                      <Editing allowDragging={false} />
                    </Scheduler>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/*Show model popup */}
      <Modal
        isOpen={meetingPopupEdit}
        size={"md"}
        centered
        className="bg-transparent"
      >
        <ModalBody className="p-0">
          {/* meeting-container */}
          <div className="">
            <div className="shadow-lg px-3 pb-4 rounded">
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-muted text-start py-3 fs-5">
                  {/* <span>My Calendar</span> */}
                </div>
                <div>
                  {/* <button
                      type="button"
                      onClick={() => {
                        setMeetingPopupEdit(false)
                      }}
                      className="ms-4"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <span
                        aria-hidden="true"
                        style={{
                          color: "#bf1e30",
                          fontSize: "28px",
                          lineHeight: "1px",
                        }}
                      >
                        {" "}
                        &times;{" "}
                      </span>
                    </button> */}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="fs-5 text-start text-dark pb-2 fw-bold">
                  {meetingPopupEditData.subject != null
                    ? meetingPopupEditData.subject
                    : meetingPopupEditData?.title
                        ?.replace(/-/g, "")
                        ?.replace(/,+$/, "")}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setMeetingPopupEdit(false)
                  }}
                  className="ms-4"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <span
                    aria-hidden="true"
                    style={{
                      color: "#bf1e30",
                      fontSize: "28px",
                      lineHeight: "1px",
                    }}
                  >
                    {" "}
                    &times;{" "}
                  </span>
                </button>
              </div>
              <div className="text-start text-dark pb-2 fs-6">
                {meetingPopupEditData?.startDate} &nbsp;&nbsp;
                {meetingPopupEditData?.timeslot}
              </div>
              <div className="d-flex py-2">
                {meetingPopupEditData.isJoinButton && (
                  <button className="btn btn-meeting px-4 ">
                    <a
                      className="nav-link p-0"
                      href={`${meetingPopupEditData?.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join
                    </a>
                  </button>
                )}
                <div
                  className={`d-flex align-items-center ${
                    meetingPopupEditData.isJoinButton && "ps-3"
                  }`}
                >
                  {/* {meetingPopupEditData.name
                    ?.replace(/-/g, "")
                    ?.replace(/,+$/, "")} */}
                  {meetingPopupEditData.avatar?.map((ob, i) => {
                    return (
                      <img
                        key={ob.id}
                        width={20}
                        height={20}
                        className="rounded-circle bg-secondary"
                        title={ob.name}
                        alt=""
                        src={
                          (ob.name || ob.avatar) !== "-"
                            ? `${configure.imgUrl}${ob.avatar}`
                            : userImg
                        }
                      />
                    )
                  })}
                </div>
              </div>
              <div className="border border-bottom my-2"></div>
              <div className="">
                <div className="d-flex align-items-center text-dark gap-2">
                  <span>
                    <i className="bi bi-geo-alt fs-5"></i>
                  </span>
                  <span className="fs-5 text-muted">
                    {meetingPopupEditData?.main_title}
                  </span>
                </div>
              </div>
              <div className="border border-bottom my-2"></div>
              <div className="pb-2">
                <div className="d-flex align-items-center text-dark gap-2">
                  <span>
                    <i className="bi bi-link fs-5 text-link"></i>
                  </span>

                  <span className="fs-5 text-link">
                    {meetingPopupEditData?.link?.slice(0, 40)}...{" "}
                  </span>
                  {copy && (
                    <span className="py-2 px-3 copied-msg text-white">
                      Copied !
                    </span>
                  )}
                  <button
                    className="btn p-0 ms-auto border-0"
                    onClick={() => {
                      copyToClipBoard(meetingPopupEditData?.link)
                      setCopy(true)
                      setTimeout(() => {
                        setCopy(false)
                      }, 1000)
                    }}
                    id="copyButton"
                    value={meetingPopupEditData?.link}
                  >
                    <span className="ps-2 ">
                      <i className="bi bi-clipboard fs-5 text-link"></i>
                    </span>
                  </button>
                </div>
                <div className="border border-bottom my-2"></div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Add meeting */}
      <Modal
        isOpen={Addmeeting}
        toggle={toggleaddmeeting}
        size="lg"
        centered
        className="meetingModal z-3"
      >
        <button
          onClick={toggleaddmeeting}
          id="modalToggle"
          className="modalToggle btn shadow-sm border-0"
          style={{ zIndex: "99999" }}
        >
          <i className="fas fa-times"></i>
        </button>
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody className="modal-body border-0">
          <form onSubmit={handleAddMeeting}>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div className="btn-meeting p-2 px-3 border-radius">
                  <i className="bi bi-calendar2 fs-5"></i>
                </div>
                <div>
                  <h4 className="mb-0 ps-2">New Meeting</h4>
                </div>
              </div>
              <div className="me-2">
                <button className="btn-meeting btn px-4 py-2">Create</button>
              </div>
            </div>

            <div>
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <label>Title</label>
                      <div className="d-flex align-items-center mb-3">
                        <i className="bi bi-pencil-square position-absolute ps-3 fs-5 text-link"></i>
                        <input
                          value={addTitle}
                          onChange={e => setAddTitle(e.target.value)}
                          type="text"
                          placeholder="Add Title"
                          className="form-control ps-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <label htmlFor="">Select Location</label>
                        <ReactSelect
                          // onChange={e => onComapanyChg(e)}
                          value={crrlocation}
                          placeholder="Select Location"
                          className="basic-multi-select w-100 z-1"
                          classNamePrefix="select"
                          styles={colourStyles}
                          options={location}
                          onChange={handleChangeLocation}
                        />
                      </div>
                      <div className="col-md-5">
                        <label>Select TimeZone</label>
                        <ReactSelect
                          // onChange={e => onComapanyChg(e)}
                          defaultValue="Europe/London"
                          value={selectedTimeZone}
                          placeholder="Select Location"
                          className="basic-multi-select w-100 z-1"
                          classNamePrefix="select"
                          styles={colourStyles}
                          options={timeZonesWithLabelValue}
                          onChange={selectedOption =>
                            setSelectedTimeZone(selectedOption)
                          }
                        />
                        {/* <div className="">
                          <select
                            defaultValue="Europe/London"
                            className="bg-transparent border-0 w-100"
                            value={selectedTimeZone.value}
                            required
                            onChange={e =>
                              setSelectedTimeZone({
                                value: e.target.value,
                                label: e.target.value,
                              })
                            }
                          >
                            <option value={"Europe/London"}>
                              Europe/London
                            </option>
                            {timeZonesWithLabelValue.map((ob, index) => (
                              <option key={index} value={ob.value}>
                                {ob.label}
                              </option>
                            ))}
                          </select>
                        </div> */}
                      </div>
                      <div className="col-md-2">
                        <div className=" mt-2 d-flex justify-content-center">
                          <div className="meetToggle">
                            <label className="mb-2">Important</label>
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                // defaultChecked={cellProps.row.original.login_access == "1"}
                                defaultChecked={isImp == 1 ? true : false}
                                onChange={e =>
                                  setIsImp(e.target.checked ? 1 : 0)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="Attendees">
                        <label>
                          Attendees <span className="text-danger">*</span>{" "}
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          <i className="bi bi-person-add position-absolute ps-3 fs-4 text-link z-2"></i>
                          {/* <input
                      value={addAttendees}
                      onChange={e => setAddAttendees(e.target.value)}
                      type="text"
                      placeholder="Add required attendees"
                      className="form-control ps-5"
                    /> */}
                          <ReactSelect
                            // onChange={e => onComapanyChg(e)}
                            value={addAttendees}
                            placeholder="Select attendees"
                            className="basic-multi-select w-100 z-1"
                            classNamePrefix="select"
                            isMulti
                            styles={customStyles2}
                            options={attendOption}
                            // options={Attendees}
                            onChange={handleChangeAttendees}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-center mb-3">
                          <div className="w-50 ">
                            <label>
                              Date <span className="text-danger">*</span>{" "}
                            </label>
                            <input
                              type="date"
                              required
                              value={addDate}
                              className="form-control"
                              onChange={e => setAddDate(e.target.value)}
                              min={getTodayDate()}
                            />
                          </div>
                          {/* <i className="bi bi-clock fs-5 text-link ps-3"></i> */}
                          <div className="w-50 ps-3">
                            <label>
                              TimeSlots <span className="text-danger">*</span>{" "}
                            </label>

                            <ReactSelect
                              // onChange={e => onComapanyChg(e)}
                              value={selectedTimeSlot}
                              placeholder="Select TimeSlots"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={colourStyles}
                              options={timeSlotsOption}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex flex-column">
                        <div className="mb-3">
                          <label htmlFor="extraEmail">Add Another Emails</label>
                          <CreatableSelect
                            id="attendees"
                            isMulti
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectedAttendees}
                            onChange={handleSelectChange}
                            onCreateOption={handleCreate}
                            isClearable
                            styles={colourStyles}
                            placeholder="Add more emails..."
                          />
                        </div>
                        <div className="row mb-3">
                          <div className="col-6">
                            <label>
                              Meeting With{" "}
                              <span className="text-danger">*</span>{" "}
                            </label>
                            <ReactSelect
                              // onChange={e => onComapanyChg(e)}
                              value={meetingFor}
                              placeholder="Select..."
                              className="basic-multi-select w-100 z-1"
                              classNamePrefix="select"
                              styles={colourStyles}
                              options={meetingForOption}
                              onChange={handleMeetingFor}
                            />
                          </div>
                          {ismeetingFor && (
                            <div className="col-6">
                              <label>
                                Select {meetingForLabel}{" "}
                                <span className="text-danger">*</span>{" "}
                              </label>
                              <ReactSelect
                                // onChange={e => onComapanyChg(e)}
                                value={meetingForSubset}
                                placeholder={`select ${meetingForLabel}`}
                                className="basic-multi-select w-100 z-1"
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={meetingForSub}
                                onChange={handleMeetingForSubset}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label>Description</label>
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={addDescription}
                    onChange={handleEditorChange}
                  /> */}
                  <div>
                    <textarea
                      name=""
                      id=""
                      className="w-100 form-control"
                      rows={5}
                      value={addDescription}
                      onChange={e => setAddDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div>
                  <div className="me-2 d-flex justify-content-end">
                    <button className="btn-meeting btn px-4 py-2">
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default CalenderIndex2
