import React, { useMemo, useEffect, useState } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Modal, Form, Label, Input, Card, CardBody } from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ResizableBox } from "react-resizable"
import Draggable from "react-draggable"
import NewPagination from "components/Common/Newpagination"
import Select from "react-select"
import { intialMilestoneClomuns } from "utils/columns/Developement/planningColumns"
import { milestoneType } from "utils/common-options"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"

const AddMilestoneScreen = ({ projectDetails }) => {
  const [milestoneSidebar, setMilestoneSidebar] = useState(false)
  const [editMilestoneSidebar, setEditMilestoneSidebar] = useState(false)
  const [viewMileStone, setViewMileStone] = useState(false)
  const [noDrag, setNoDrag] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [scopeOption, setScopeOption] = useState([])
  const [allocated, setAllocatedHours] = useState([])
  const [datas, setData] = useState([])
  const [mile_id, setMileId] = useState('')
  const [mile_no, setMileNumber] = useState('')
  const token = secureLocalStorage.getItem('auth_token')
  const roles = useSelector(state => state?.Roles?.apiData)
  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25;
    if (window.scrollY > scrollThreshold) {
      // if (window.scrollY > 68) {
      setFixedContent(true)

    } else {
      setFixedContent(false)
    }
  })

  const toggloading = (loading) => {
    setLoading(loading)
  }
  const data = useMemo(() => datas, [datas])
  useEffect(() => {
    const fetchMilestone = async () => {
      const res = await fetchData(toggloading, `projectMilestones/${projectDetails?.id}`, token);
      if (res?.success) {
        setScopeOption(projectDetails?.project_scope?.map((obj) => ({
          label: obj,
          value: obj
        })))
        setData(res?.data)
      }
    }
    fetchMilestone();
  }, [])

  const onDelete = async id => {
    const permission = checkPermissionsByModule(roles, 88, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Milestone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await fetchData(
            toggloading,
            `deleteProjectmilestone/${id}/${projectDetails?.id}`,
            token,
          )
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: "Milestone Deleted Successfully",
            })
            setData(response?.data)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }

  const onEdit = (obj) => {
    setDescription(obj?.description || "")
    const scopes = obj?.scope?.split('@#')
    setScope(scopeOption?.filter((scp) => scopes?.includes(scp.value)))
    setHours(obj?.milestone_days)
    setMileNumber(obj?.milestone_no)
    setType(milestoneType?.find((mil) => mil?.value == obj?.milestone_type))
    setMileId(obj?.id)
    setEditMilestoneSidebar(true)
  }
  const onView = (obj) => {
    setDescription(obj?.description || "")
    const scopes = obj?.scope?.split('@#')
    setScope(scopeOption?.filter((scp) => scopes?.includes(scp.value)))
    setHours(obj?.milestone_days)
    setMileNumber(obj?.milestone_no)
    setType(milestoneType?.find((mil) => mil?.value == obj?.milestone_type))
    setMileId(obj?.id)
    setViewMileStone(true)
  }

  // table columns
  const columns = useMemo(
    () => intialMilestoneClomuns(onDelete, onEdit, onView),
    [scopeOption]
  )


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [description, setDescription] = useState('')
  const [type, setType] = useState([])
  const [hours, setHours] = useState('')
  const [scope, setScope] = useState([])

  const onAddMiletsone = async (e) => {
    e.preventDefault();
    if (description?.length >= 80) {
      if (description != '' && type?.length != 0 && scope?.length != 0) {
        const formEntries = {
          user_id: localStorage.getItem("auth_id"),
          description: description,
          milestone_type: type?.value || '',
          milestone_hours: hours,
          scope: scope?.map((obj) => obj?.value)?.join('@#'),
          project_id: projectDetails?.id,
        }
        const res = await FetchPostData(toggloading, 'addProjectMilestone', token, formEntries);
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Milestone Added Successfully"
          }).then(() => {
            setMilestoneSidebar(false)
            setDescription('')
            setType([])
            setHours('')
            setScope([])
            setData(res?.data?.data)
          })

        } else {
          Swal.fire({
            icon: "warning",
            text: "Milestone Number is already exists!"
          })
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please Fill All the Fields"
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      })
    }
  }
  const onEditMiletsone = async (e) => {
    e.preventDefault();
    if (description?.length >= 80) {
      if (description != '' && type?.length != 0 && scope?.length != 0) {
        const formEntries = {
          milestone_id: mile_id,
          user_id: localStorage.getItem("auth_id"),
          description: description,
          milestone_type: type?.value || '',
          milestone_hours: hours,
          scope: scope?.map((obj) => obj?.value)?.join('@#'),
          project_id: projectDetails?.id,
        }
        const res = await FetchPostData(toggloading, 'editProjectMilestone', token, formEntries);
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Milestone Edited Successfully"
          }).then(() => {
            setEditMilestoneSidebar(false)
            setDescription('')
            setType([])
            setHours('')
            setScope([])
            setData(res?.data?.data)
          })
        } else {
          Swal.fire({
            icon: "warning",
            text: "Milestone Number is already exists!"
          })
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please fill all the fields"
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      })
    }
  }


  return (
    <>
      {isLoading && (<Loader />)}
      <Card>
        <CardBody>
          <div className="moveUp">
            <Row>
              <div className="col-3">
                <select
                  className="border-radius me-4 form-select"
                  style={{ width: "100px" }}
                  value={pageSize}
                  onChange={e => setPageSize(e.target.value)}
                >
                  {[10, 25, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <Col className="d-flex justify-content-end align-items-start">
                <div>
                  <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                          Search this table
                        </span>
                        <input
                          onChange={e => setGlobalFilter(e.target.value)}
                          id="search-bar-0"
                          type="text"
                          className="border-radius form-control rounded h-100"
                          placeholder={`Search...`}
                          value={globalFilter || ""}
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => setMilestoneSidebar(true)}
                  className="btn btn-purple border-radius ms-3"
                >
                  <i className="fas fa-plus me-2"></i>
                  Milestone
                </button>
              </Col>
            </Row>
            <div className="position-relative">
              <Table className="table table-bordered mt-3" {...getTableProps()}>
                <thead
                  // className={`${(fixedContent && page.length >= 10) ? "fixed_addMile_header" : ""
                  // }`}
                  className={`${(fixedContent && page.length >= 10) ? "fixed_addMile_header" : "fixed-table-header"
                    }`}
                >
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className
                            } customShortIcon custom-text`}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                          {column.isShortIcon && (
                            <span
                              onClick={() =>
                                column.getSortByToggleProps().onClick()
                              }
                              className="ms-2 cursor-pointer customApprovalIcon"
                            >
                              <i
                                title="Ascending"
                                className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                              <i
                                title="Descending"
                                className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                            </span>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody className="fix-table-body" {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row-hover" {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${cell.column.className
                                }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  {page.length === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPag={canPreviousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
              />
            </Row>
          </div>
        </CardBody>
      </Card>

      {/* Add Milestone */}
      <div
        className={`note-sticky-form ${milestoneSidebar ? "d-block" : "d-none"
          }`}
      >
        <Draggable>
          <ResizableBox
            width={700}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <form onSubmit={onAddMiletsone}>

              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Add Milestone</h5>
                </div>
                <div className="col-12 for-task">
                  <label htmlFor="">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onReady={editor => { }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={milestoneType}
                      value={type}
                      onChange={(e) => {
                        setType(e)
                        if (e.value == 1) {
                          setAllocatedHours(Number(projectDetails?.designing) || 0)
                        } else if (e.value == 2) {
                          setAllocatedHours(Number(projectDetails?.development) || 0)
                        } else if (e.value == 3) {
                          setAllocatedHours(Number(projectDetails?.testing) || 0)
                        } else if (e.value == 4) {
                          setAllocatedHours(Number(projectDetails?.deployment) || 0)
                        } else {
                          setAllocatedHours(0)
                        }
                      }}
                    />
                  </div>
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Milestone Hours <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Milestone Hours"
                      className="form-control"
                      value={hours}
                      required
                      onChange={(e) => {
                        setHours(e.target.value)
                      }}
                    />
                  </div>
                  <div className="col-3 mt-3 notes">
                    <label htmlFor="">
                      Allocated Hours <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Milestone Hours"
                      className="form-control"
                      value={allocated - Number(hours) < 0 ? 0 : allocated - Number(hours)}
                      disabled

                    />
                  </div>
                </div>
                <div className="col-12 mt-3 notes">
                  <label htmlFor="">
                    Scope <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Scope"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    isMulti={true}
                    options={scopeOption}
                    value={scope}
                    closeMenuOnSelect={false}
                    onChange={(e) => setScope(e)}
                  />
                </div>

                <div className="text-end mt-3">
                  <button className="btn btn-purple border-radius me-2">
                    <i className="fas fa-save me-2"></i>Save
                  </button>
                  <a
                    onClick={() => setMilestoneSidebar(false)}
                    className="btn btn-secondary text-uppercase border-radius"
                  >
                    <i className="dripicons-cross me-1 icon-center"></i>Close
                  </a>
                </div>
              </Row>
            </form>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Edit Milestone  */}
      <div
        className={`note-sticky-form ${editMilestoneSidebar ? "d-block" : "d-none"
          }`}
      >
        <Draggable>
          <ResizableBox
            width={700}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <form onSubmit={onEditMiletsone}>
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Milestone</h5>
                </div>
                <div className="col-12 for-task">
                  <label htmlFor="">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description || ''}
                    onReady={editor => { }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data)
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={milestoneType}
                      value={type}
                      onChange={(e) => setType(e)}
                    />
                  </div>
                  <div className="col-4 mt-3 notes">
                    <label htmlFor="">
                      Milestone Hours <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Milestone Hours"
                      className="form-control"
                      value={hours}
                      onChange={(e) => setHours(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 mt-3 notes">
                  <label htmlFor="">
                    Scope <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Scope"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    isMulti={true}
                    closeMenuOnSelect={false}
                    options={scopeOption}
                    value={scope}
                    onChange={(e) => setScope(e)}
                  />
                </div>

                <div className="text-end mt-3">
                  <button className="btn btn-purple border-radius me-2">
                    <i className="fas fa-save me-2"></i>Save
                  </button>
                  <a
                    onClick={() => {
                      setDescription('')
                      setScope([])
                      setType([])
                      setHours('')
                      setAllocatedHours(0)
                      setEditMilestoneSidebar(false)

                    }}
                    className="btn btn-secondary text-uppercase border-radius"
                  >
                    <i className="dripicons-cross me-1 icon-center"></i>Close
                  </a>
                </div>
              </Row>
            </form>
          </ResizableBox>
        </Draggable>
      </div>

      {/* View Milestone  */}
      <div
        className={`note-sticky-form ${viewMileStone ? "d-block" : "d-none"}`}
      >
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            // width={900}
            width={700}
            style={{
              position: "fixed",
              top: "16%",
              right: "18%",
              // alignItems:"center",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="modal-header mb-3">
              <h5 className="modal-title mt-0">View Milestone</h5>
              <button
                type="button"
                onClick={() => {
                  setViewMileStone(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-3 justify-content-start align-items-end">
                    <div className="col-md-8 ps-2">
                      <Label htmlFor="formrow-firstname-Input">Title</Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          disabled
                          type="text"
                          className="form-control"
                          value={`Milestone - ${mile_no}`}
                          onDoubleClick={() => setNoDrag(true)}
                          onMouseLeave={() => setNoDrag(false)}
                          onMouseEnter={() => setNoDrag(true)}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ps-2">
                      <Label htmlFor="formrow-firstname-Input">Hours</Label>
                      <input
                        disabled
                        type="text"
                        className="form-control "
                        placeholder=""
                        value={hours}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ps-2 mb-3">
                    <Label htmlFor="formrow-firstname-Input">Scope</Label>
                    {scope?.map((obj, i) => {
                      return (
                        <p key={i} className="mb-1">
                          <i className="mdi mdi-chevron-right text-primary me-1"></i>
                          <span className="text-purple">{obj.value}</span>
                        </p>
                      )
                    })}
                  </div>
                  <div className="col-md-12 d-flex mb-3 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <div
                        className="no-drag"
                        onDoubleClick={() => setNoDrag(true)}
                        onMouseLeave={() => setNoDrag(false)}
                        onMouseEnter={() => setNoDrag(true)}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            isReadOnly: true,
                          }}
                          data={description || ''}
                          onReady={editor => {
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>
    </>
  )
}

export default AddMilestoneScreen
