import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import "@fullcalendar/bootstrap/main.css"
import "../../../assets/css/Work/digitalMarketing.css"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import userImg from "../../../assets/images/users/AdminImg.png"
import Breadcrumb from "components/Common/Breadcrumb"
import { ResizableBox } from "react-resizable"
import Draggable from "react-draggable"
import { Link } from "react-router-dom"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"
import { convertToMinutes, trimProjectName } from "utils/common-helpers"

const PmResourcePlanIndex = props => {
  // meta title
  document.title = "Weekly Planning | Zithas Crm"
  const [event, setEvent] = useState({})

  const [modal, setModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [eventsList, setEvents] = useState([])
  const [currentStatusPopup, setCurrentStatusPopup] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState([])
  const [selectedProject, setSelectedProject] = useState([])
  const [isCollapse, setIsCollapse] = useState(true)
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [currentProject, setCurrentProject] = useState(false)
  const [staffOption, setStaffOption] = useState([])
  const [projectOption, setProjectOption] = useState([])

  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }

  document.addEventListener("keydown", e => {
    if (e.key == "Escape") {
      if (currentStatusPopup) {
        setCurrentStatusPopup(false)
      }
      if (modal) {
        setModal(false)
      }
    }
  })


  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
    }
  }

  const allTasks = [
    {
      id: 1,
      type: "Fixed",
      task: "Integrate api for swipe & issue crud and approve , disapprove api also",
      est: "5:00:00",
      actual: "04:53:00",
      status: 0
    },
    {
      id: 2,
      type: "Fixed",
      task: "IAdd reason while adding time taken for activity and show like subtask for particular assignee",
      est: "4:00:00",
      actual: "02:55:00",
      status: 1
    },
    {
      id: 3,
      type: "Fixed",
      task: "Design privacy policy page in website and make it live for playstore app",
      est: "3:00:00",
      actual: "05:00:00",
      status: 2,

    },
    {
      id: 4,
      type: "Fixed",
      task: "Integrate api for activity in digital marketing all actions",
      est: "6:00:00",
      actual: "05:45:00",
      status: 0
    },
    {
      id: 5,
      type: "Fixed",
      task: "	Optimize the edit staff api cause its too loading and not giving proper user experience",
      est: "4:00:00",
      actual: "05:30:00",
      status: 2
    },
    {
      id: 6,
      type: "Fixed",
      task: "Design the Digital marketing overview and weekly plan as per discussion",
      est: "3:00:00",
      actual: "02:10:00",
      status: 0
    },
    {
      id: 7,
      type: "Fixed",
      task: "Integrate api for editing roles and permission and provide data according to requirement",
      est: "5:00:00",
      actual: "04:53:00",
      status: 1
    },
    {
      id: 1,
      type: "Fixed",
      task: "Integrate api for swipe & issue crud and approve , disapprove api also",
      est: "5:00:00",
      actual: "04:53:00",
      status: 0
    },
    {
      id: 2,
      type: "Fixed",
      task: "IAdd reason while adding time taken for activity and show like subtask for particular assignee",
      est: "4:00:00",
      actual: "02:55:00",
      status: 1
    },
    {
      id: 3,
      type: "Fixed",
      task: "Design privacy policy page in website and make it live for playstore app",
      est: "3:00:00",
      actual: "05:00:00",
      status: 2,

    },
    {
      id: 4,
      type: "Fixed",
      task: "Integrate api for activity in digital marketing all actions",
      est: "6:00:00",
      actual: "05:45:00",
      status: 0
    },
    {
      id: 5,
      type: "Fixed",
      task: "	Optimize the edit staff api cause its too loading and not giving proper user experience",
      est: "4:00:00",
      actual: "05:30:00",
      status: 2
    },
    {
      id: 6,
      type: "Fixed",
      task: "Design the Digital marketing overview and weekly plan as per discussion",
      est: "3:00:00",
      actual: "02:10:00",
      status: 0
    },
    {
      id: 7,
      type: "Fixed",
      task: "Integrate api for editing roles and permission and provide data according to requirement",
      est: "5:00:00",
      actual: "04:53:00",
      status: 1
    },
  ]

  const customButtonStyles = {
    backgroundColor: '#28a745',
    color: 'white',
  };

  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem('auth_token')
  const [totalHours, setTotalHours] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  const onViewPorject = async obj => {
    const res = await fetchData(toggleLoading, `allProjectRelatedData`, token)
    if (res?.success) {
      setStaffOption(res?.data?.developers?.map((item) => ({
        value: item?.id,
        label: item?.alias_name
      })))
      const projects = res?.data?.projects?.map((obj) => ({
        value: obj?.id,
        label: obj?.project_name,
        ...obj
      }))
      setProjectOption(projects)
      setverticalActiveTab(projects[0]?.id)

    }
  }
  useEffect(() => {
    onViewPorject()
  }, [])

  function sumTimes(timesArray) {
    const totalMinutes = timesArray.reduce((total, { estimate_dev_days }) => {
      if (!estimate_dev_days || !/^(\d{2}):(\d{2})$/.test(estimate_dev_days)) {
        return total;
      }
      const [hours, minutes] = estimate_dev_days.split(':').map(Number);
      return total + hours * 60 + minutes;
    }, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }
  function filterByDateRange(array, startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return array.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= start && itemDate <= end;
    });
  }
  function getClassForTimeComparison(time1, time2) {
    const time1InMinutes = convertToMinutes(time1);
    const time2InMinutes = convertToMinutes(time2);
    if (time2InMinutes > time1InMinutes) {
      return 'bg-red'; // Add the bg-red class if time2 is greater than time1
    }
    return ''; // Return empty if no background color is needed
  }


  const getAllTasks = async () => {
    const formEntries = {
      staff_id: selectedStaff?.value,
      project_id: selectedProject?.map((obj) => obj?.value)?.join(','),
    }
    const res = await FetchPostData(toggleLoading, 'allTasksByProjectStaff', token, formEntries);
    if (res?.data?.success) {
      setEvents(res?.data?.data)
      const filterData = filterByDateRange(res?.data?.data, startDate, endDate)
      setTotalHours(sumTimes(filterData))
    }
  }

  useEffect(() => {
    const filterData = filterByDateRange(eventsList, startDate, endDate)
    setTotalHours(sumTimes(filterData))
  }, [eventsList, startDate])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumb title="Development Projects" breadcrumbItem="Weekly Plan" />
          <div className="card p-4 h-100">
            <Row>
              <Col className="col-12">
                <Row>
                  <Col lg={12}>
                    <div className=" col-12 d-flex justify-space-between">
                      <div className="col-6 d-flex justify-content-start align-items-start gap-5">
                        <div className="d-flex justify-content-start align-items-center gap-4">
                          <a onClick={() => { setCurrentProject(true) }} className="text-purple fw-bold">Total Weekly Hours : {totalHours || "0"} Hrs <i className="fas fa-info-circle fs-5 text-purple ms-1"></i></a>
                          <div className="d-flex justify-content-center align-items-center gap-1">
                            <span
                              className="bg-primary border-black"
                              style={{
                                padding: "6px",
                              }}
                            ></span>
                            - <span>Fixed</span>
                          </div>
                          <div className="d-flex justify-content-center align-items-center gap-1">
                            <span
                              className="bg-warning border-black"
                              style={{
                                padding: "6px",
                              }}
                            ></span>
                            - <span>Dedicated</span>
                          </div>
                          <div className="d-flex justify-content-center align-items-center gap-1">
                            <span
                              className="bg-success border-black"
                              style={{
                                padding: "6px"
                              }}
                            ></span>
                            - <span>Maintenance</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-6 d-flex justify-content-end">
                        <div className="col-5 mb-3">
                          <Select
                            placeholder="Select Member"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            options={staffOption}
                            value={selectedStaff}
                            onChange={e => {
                              setSelectedStaff(e)
                              setSelectedProject([])
                            }
                            }
                          />
                        </div>
                        {selectedStaff ? (
                          <div className="col-6 mb-3 ms-2">
                            <Select
                              placeholder="Select Project "
                              className="basic-multi-select "
                              classNamePrefix="select"
                              isMulti={true}
                              options={projectOption.filter(item => item.assignees.some(ass => ass.developer_id == selectedStaff.value))}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }),
                              }}
                              onChange={(e) => {
                                setSelectedProject(e);
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="ms-2">
                          <a
                            className="btn btn-sm  me-1 bg-purple text-white"
                            style={{ borderRadius: "5px" }}
                            onClick={() => getAllTasks()}
                          >
                            <i className="bi bi-funnel-fill fs-5"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <FullCalendar
                      plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                      initialView="dayGridWeek"
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      customButtons={{
                        currentStatus: {
                          text: "Current Status",
                          className: "btn-current-status",
                          style: customButtonStyles,
                          click: () => setCurrentStatusPopup(true),
                        },
                      }}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay,currentStatus",
                      }}
                      events={eventsList}
                      eventContent={arg => {
                        const { event } = arg
                        return (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div
                              className="text-center"
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                                maxWidth: "100%",
                                fontWeight: "700",
                              }}
                            >
                              <img
                                src={`${configure.imgUrl}/${event.extendedProps.avatar}`}
                                alt="User Icon"
                                className="me-1"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                              {event.extendedProps.projectName} -
                              <span
                                className="bg-white text-dark ms-2"
                                style={{
                                  fontWeight: "600",
                                  borderRadius: "5px",
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                  fontSize: "11px",
                                }}
                              >
                                {event.extendedProps.estimate_dev_days}
                              </span>
                            </div>
                            <div
                              className="text-start"
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                                maxWidth: "100%",
                              }}
                            >
                              {event.title}
                            </div>
                          </div>
                        )
                      }}
                      editable={false}
                      droppable={false}
                      selectable={false}
                      datesSet={(arg) => {
                        const { start, end } = arg;
                        setStartDate(moment(start)?.format('YYYY-MM-DD'))
                        setEndDate(moment(end)?.format('YYYY-MM-DD'))
                      }}
                    />

                    <Modal
                      size="xl"
                      isOpen={currentStatusPopup}
                      className="modal-fullscreen"
                    >
                      <div className="modal-header mb-0">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: "100%" }}
                        >
                          <div className="col-6 d-flex align-items-center">
                            <h5 className="modal-title mt-0">Current Status</h5>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => setCurrentStatusPopup(false)}
                          className="ms-4"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            color: "red",
                          }}
                        >
                          <span
                            aria-hidden="true"
                            style={{
                              color: "#bf1e30",
                              fontSize: "28px",
                              lineHeight: "1px",
                            }}
                          >
                            &times;
                          </span>
                        </button>
                      </div>

                      <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
                        <Row className="gx-4 justify-content-between flex-column flex-md-row">
                          <div
                            className={`sidebar-containerr ${fixedSideTab ? "credtabFix" : ""
                              }`}
                            style={isIndex ? { zIndex: "2" } : {}}
                          >
                            <div className="position-relative mt-5">
                              <div>
                                <SimpleBar
                                  style={{ height: "70vh" }}
                                  className={`${fixedSideTab && "fix-simplebar"}`}
                                >
                                  <Nav
                                    pills
                                    className={`credtabFix-ul ${fixedSideTab && ""
                                      }  flex-column px-1 py-1 h-100`}
                                  >
                                    {projectOption?.map((obj, i) => {
                                      return (
                                        <NavItem
                                          className={classnames({
                                            "d-flex flex-column gap-2 mb-2": true,
                                            active: verticalActiveTab == obj?.id,
                                          })}
                                          key={i}
                                        >
                                          <NavLink
                                            style={{ cursor: "pointer", width: "70px" }}
                                            className={classnames({
                                              "text-center crendential": true,
                                              active: verticalActiveTab == obj?.id,
                                            })}
                                            onClick={() => {
                                              setverticalActiveTab(obj?.id)
                                            }}
                                            onMouseEnter={handleHover}
                                            onMouseLeave={handleLeave}
                                          >
                                            {trimProjectName(obj?.project_name)}
                                            <span className="link_name border-start border-white border-2">
                                              {obj?.project_name}
                                            </span>
                                          </NavLink>
                                        </NavItem>
                                      )
                                    })}
                                  </Nav>
                                </SimpleBar>
                              </div>
                            </div>
                          </div>
                          <Col
                            md={isCollapse ? "12" : "10"}
                            className={`z-1 pe-0 credential-table ${isCollapse && "width-96 ps-0"
                              }`}
                          >
                            <div className="card p-4 h-100">
                              <div className="col-12 d-flex flex-column gap-2">
                                <div className="d-flex gap-3 justify-content-between px-4 mb-4 align-items-center">
                                  <div className="d-flex gap-3 text-center  col-md-8">
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status ">
                                      <h5>{projectOption?.find((obj) => obj?.id == verticalActiveTab)?.total_estimated_time} </h5>
                                      <h5>Total Project Hours</h5>
                                    </a>
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status">
                                      <h5>{projectOption?.find((obj) => obj?.id == verticalActiveTab)?.total_used_time} </h5>
                                      <h5>Used Project Hours</h5>
                                    </a>
                                    <a className="text-purple pt-2 px-2 rounded work-status cr-status">
                                      <h5>{moment(projectOption?.find((obj) => obj?.id == verticalActiveTab)?.endDate).format('DD-MM-YYYY')} </h5>
                                      <h5>Project Deadline Date</h5>
                                    </a>
                                    <a className="text-danger pt-2 px-2 rounded work-status cr-status bg-red">
                                      <h5>{projectOption?.find((obj) => obj?.id == verticalActiveTab)?.affected_time}</h5>
                                      <h5>Affected Total Hours</h5>
                                    </a>
                                    <a className="text-danger pt-2 px-2 rounded work-status cr-status bg-red">
                                      <h5>{projectOption?.find((obj) => obj?.id == verticalActiveTab)?.days_late}</h5>
                                      <h5>Project Affected Days</h5>
                                    </a>
                                  </div>
                                </div>

                                <div className="col-12 d-flex justify-content-center align-items-center">
                                  <div className="col-12 d-flex flex-column px-4">
                                    <TabContent
                                      activeTab={verticalActiveTab}
                                      className="text-muted mt-4 mt-md-0"
                                    >
                                      {projectOption?.map((obj, i) => {
                                        return (
                                          <TabPane tabId={obj?.id} key={i}>
                                            <table className="table table-responsive  table-bordered ">
                                              <thead>
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="sr_col text-center"
                                                  >
                                                    Sr No.
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="activity_col"
                                                  >
                                                    Task Name
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="totalQty_col text-center"
                                                  >
                                                    Est. Time
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="complQty_col text-center"
                                                  >
                                                    Actual Time
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {eventsList?.map((obj, i) => {
                                                  if (obj?.project_id == verticalActiveTab) {
                                                    return (
                                                      <tr key={i} className={`${obj?.status == 2 ? "bg-green" : ""} `}>
                                                        <td className="text-center">{i + 1}</td>
                                                        <td className=""><img src={`${configure?.imgUrl}/${obj?.avatar}`} alt="" className="img-fluid assignee-img me-2" title="Admin" />
                                                          {obj?.title}</td>
                                                        <td className="text-center">{obj?.estimate_dev_days || '00:00'} </td>
                                                        <td className="text-center">{obj?.total_time_spent}</td>
                                                      </tr>
                                                    )
                                                  }
                                                })}
                                              </tbody>
                                            </table>
                                          </TabPane>
                                        )
                                      })}

                                    </TabContent>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div
        className={`note-sticky-form ${currentProject ? "d-block" : "d-none"
          }`}
      >
        <Draggable>
          <ResizableBox
            width={1200}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <Row className="align-items-center justify-content-between">
              <div className="col-12 d-flex justify-content-between">
                <h5>Current Week Projects</h5>
                <a><i className="fas fa-times text-danger" onClick={() => setCurrentProject(false)}></i></a>
              </div>
              <div className="mt-3">
                <div className="text-purple fw-bold general-box col-md-3 text-center">Total Developement Hours : {totalHours || "0"}  Hrs</div>
                <table className="table table-responsive  table-bordered mt-3">
                  <thead>
                    <tr>
                      <th scope="col">
                        Sr No.
                      </th>
                      <th scope="col">
                        Project Name
                      </th>
                      <th scope="col">
                        Total hours
                      </th>
                      <th scope="col">
                        Current Milestone
                      </th>
                      <th scope="col">
                        Assignee
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectOption?.map((obj, i) => {
                      const filterData = filterByDateRange(eventsList, startDate, endDate)
                      const tasks = filterData?.filter((data) => data.project_id == obj?.value)
                      const uniqueMilestones = [...new Set(tasks.map(task => task.milestone_no))];
                      const result = uniqueMilestones.map(milestone => `milestone ${milestone}`).join(', ');
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{obj?.label}</td>
                          <td>{sumTimes(tasks)} Hours</td>
                          <td>{result || '-'}</td>
                          <td>
                            {obj?.assignees?.map((ass, i) => {
                              return (
                                <img src={`${configure.imgUrl}/${ass?.avatar}`} alt="" className="img-fluid assignee-img me-2" title={ass?.alias_name} key={i} />
                              )
                            })}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Row>
          </ResizableBox>
        </Draggable>
      </div>

    </React.Fragment >
  )
}



export default PmResourcePlanIndex
