import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"

import AddTask from "./AddTask"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import { milestoneType } from "utils/common-options"

const AddTaskIndex = ({ projectDetails }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [allTasks, setAllTasks] = useState([])
  const token = secureLocalStorage.getItem('auth_token')
  const userId = localStorage.getItem('auth_id')
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 105) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  useEffect(() => {
    const fetchMilestone = async () => {
      const res = await fetchData(toggleLoading, `projectMilestones/${projectDetails?.id}`, token);
      if (res?.success) {
        setData(res?.data)
        setverticalActiveTab(res?.data[0]?.id)
        const tasks = await fetchData(
          toggleLoading,
          `allUnplannedTasks/${projectDetails?.id}`,
          token
        )
        if (tasks) {
          setAllTasks(tasks)
        }
      }
    }
    fetchMilestone();
  }, [])

  const onTaskUpdate = (task) => {
    setAllTasks(task)
  }


  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Card>
        <CardBody>
          <div className=" mb-4">
            <div id="credentialPage">
              <Row
                className={`justify-content-between di-flex align-items-top `}
                id="cred"
              >
                <div
                  className={`px-0 development_sidebar  ${fixedSideTab && "credtabFix"
                    }`}
                  style={isIndex ? { zIndex: "2" } : {}}
                >
                  <div className="position-relative">
                    <div>
                      <SimpleBar
                        style={{ height: "70vh" }}
                        className={`${fixedSideTab && "fix-simplebar"}`}
                      >
                        <Nav
                          pills
                          className={`credtabFix-ul pmDevSidebar ${fixedSideTab && ""
                            }  flex-column px-0 py-0 h-100`}
                        >
                          {data?.map((obj, i) => {
                            return (
                              <NavItem
                                className={classnames({
                                  "d-flex flex-column": true,
                                  active: verticalActiveTab == obj?.id,
                                })}
                                style={{ height: "45px" }}
                                key={i}
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    "text-center crendential": true,
                                    active: verticalActiveTab == obj?.id,
                                  })}
                                  onClick={() => {
                                    setverticalActiveTab(obj?.id)
                                  }}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                >
                                  M{obj.milestone_no} - {milestoneType?.find((mil) => mil.value == obj?.milestone_type)?.text}
                                  <span className="link_name border-start border-white border-2">
                                    Milestone {obj.milestone_no} - {milestoneType?.find((mil) => mil.value == obj?.milestone_type)?.type}
                                  </span>
                                </NavLink>
                              </NavItem>
                            )
                          })}
                        </Nav>
                      </SimpleBar>
                    </div>
                  </div>
                </div>
                <Col
                  md={"10"}
                  className={`z-1 pe-0 DevTask_table`}
                >
                  <div className="cards p-0 mb-0 h-100 ">
                    <AddTask verticalActiveTab={verticalActiveTab} toggleLoading={toggleLoading} projectDetails={projectDetails} allTasks={allTasks} onTaskUpdate={onTaskUpdate}/>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AddTaskIndex