import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import DMTaskTimer from "pages/WorkModule/Digital Marketing/Task Switch/DMTaskTimer"
import ProjectTimer from "pages/WorkModule/Projects/Project Timer/ProjectTimer"
import { useTimer } from "pages/WorkModule/Projects/Project Timer/TimerContext"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"
import { FetchPostData } from "utils/common-fetchData"
import SupportTimer from "pages/WorkModule/Support/Timer/SupportTimer"

const TimerChange = () => {
  const { handleStartStop, formattedTime, currentTaskId, isRunning } = useTimer();
  const onStopTimer = e => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ",
      text: "Do you want to stop",
      showCancelButton: true,
      confirmButtonText: "Yes, Stop!",
      cancelButtonText: "No, Keep it running",
    }).then(async ifYes => {
      if (ifYes.isConfirmed) {
        const date = new Date()
        const token = secureLocalStorage.getItem("auth_token")
        const formEntries = {
          staff_id: localStorage.getItem("auth_id"),
          task_id: currentTaskId,
          end_time: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          type: 2,
          timer_id: localStorage.getItem("timerId")
        }
        const res = await FetchPostData(
          () => { },
          "startStopTaskTimer",
          token,
          formEntries
        )
        if (res?.data?.success) {
          handleStartStop(currentTaskId, formattedTime)
        }
      }
    })
  }

  return (
    <div>
      <div className="d-flex gap-2 text-white align-items-center">
        {secureLocalStorage.getItem('auth_role') == 15 ? (
          <DMTaskTimer />
        ) : null}
        {secureLocalStorage?.getItem('auth_role') == 1 || secureLocalStorage?.getItem('auth_role') == 5 || secureLocalStorage?.getItem('auth_role') == 18 || secureLocalStorage?.getItem('auth_role') == 19 ? (
          <>
            <ProjectTimer />
            <span className="mb-0"># {currentTaskId}</span>
            <span className="mb-0">{formattedTime}</span>
            {isRunning ? (
              <a onClick={onStopTimer} className="text-danger">
                <i className="far fa-stop-circle "></i>
              </a>
            ) : null}
          </>
        ) : null}
        {secureLocalStorage.getItem('auth_role') == 20 ? (
          <SupportTimer />
        ) : null}
      </div>

    </div>
  )
}

export default TimerChange
