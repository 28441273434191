import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import NewPagination from "components/Common/Newpagination"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { salarySheetColumns } from "utils/columns/Human Resource/payrollColumns"
import * as XLSX from "xlsx"
import moment from "moment"

function TableContainer({
  columns,
  data,
  toggleLoading,
  setData,
  stepData,
  selectAll,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const navigate = useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  const token = secureLocalStorage.getItem("auth_token")

  const onGenerateSheet = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to Generate Salary Sheet ",
      showCancelButton: true,
      confirmButtonText: "Yes Generate !",
    }).then(async result => {
      if (result?.isConfirmed) {
        const res = await fetchData(toggleLoading, "generateSalarySheet", token)
        if (res?.success) {
          Swal?.fire({
            icon: "success",
            text: "Salary Sheet Generated Successfully",
          }).then(data => {
            setData(res?.data?.original?.data)
          })
        }
      }
    })
  }

  const totalSalary = data.reduce((accumulator, item) => {
    return accumulator + parseFloat(item.salary)
  }, 0)

  const onDownloadSheet = async () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to Download Salary Sheet & Save the Data ?",
      showCancelButton: true,
      confirmButtonText: "Yes Download it !",
    }).then(async result => {
      if (result?.isConfirmed) {
        const date = new Date()
        const formEntries = {
          user_ids: data
            ?.filter(obj => obj?.pending_status == 1)
            ?.map(obj => obj?.user_id)
            ?.join(","),
          month: moment(date).subtract(1, "months").format("MM"),
          year: moment(date)?.format("YYYY"),
        }
        const res = await FetchPostData(
          toggleLoading,
          "updatePendingSalaryLog",
          token,
          formEntries
        )
        if (res?.data?.success) {
          const workbook = XLSX.utils.book_new()
          const filterData = data?.filter(obj => obj?.pending_status == 0)
          const modifyArr = filterData?.map((list, index) => {
            return {
              ["Sr No."]: index + 1,
              Name: list?.name,
              ["Total Days"]: list.wd,
              ASL: list.sick_leave,
              USL: list.dsl,
              AEL: list.approved_leave,
              UEL: list.unapproved_leave,
              ACL: list.emergency_leave,
              UCL: list.dcl,
              UIL: list.uninformed_leave,
              ["Late Punch"]: list.late_punch,
              ["Total Leaves"]: list.total_leave,
              ["Worked Days"]: list.gwd,
              Amount: list.salary,
            }
          })
          const worksheet = XLSX.utils.json_to_sheet(modifyArr)
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
          XLSX.writeFile(
            workbook,
            `Salary Sheet-${moment(date).format("DD-MM-YYYY")}.xlsx`
          )
        }
      }
    })
  }

  return (
    <div>
      <Row className="m-0 d-flex justify-space-between align-items-center mb-4">
        <div className="d-flex gap-3 align-items-center col-md-6 p-0">
          <select
            className=" form-select "
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <a
            className={`d-flex gap-3 align-items-center general-box bg-purple`}
          >
            <h5 className="text-purple mb-0">Total Amount : </h5>
            <h5 className="text-primary mb-0">₹ {Number(totalSalary).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0} </h5>
          </a>
        </div>
        <div className="d-flex col-md-6 p-0 align-items-center justify-content-end gap-2">
          {stepData?.sheet_status == 1 && data?.length != 0 ? (
            <button
              className="btn text-white me-0 btn-primary btn-label"
              onClick={() => onDownloadSheet()}
            >
              <i className="bx bx-download me-2 icon-size label-icon"></i>
              Salary Sheet
            </button>
          ) : null}
          {stepData?.sheet_status == 0 && data?.length == 0 ? (
            <button
              className="btn text-white me-0 btn-success btn-label"
              onClick={() => onGenerateSheet()}
            >
              <i className="bx bx-list-check me-2 icon-size label-icon"></i>
              Generate Salary Sheet
            </button>
          ) : null}
          <div className="d-flex">
            <div className="search-box my-xxl-0 d-inline-block d-flex flex-row justify-content-center align-items-center">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label mb-0">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100 "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...(column.isShortIcon
                        ? column.getHeaderProps(column.getSortByToggleProps())
                        : {})}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                        </span>
                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-salary-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const { did_col: did, pwd_col: pwd } = row.original
              const status = row.original.status_name

              let backgroundStyle = {}

              const priority = row.original.priority_col

              return (
                <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} ${
                        priority == "High" ? "highPriority" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>

        {stepData?.sheet_status == 0 && data?.length == 0 ? (
          <div className="text-center text-purple fw-bold">
            Generate a salary sheet to display the computed data
          </div>
        ) : null}
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const SalarySheet = ({ stepData, getLength }) => {
  document.title = "Salary Sheet | Zithas Crm"
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [data, setData] = useState([])
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const [selectAll, setSelectAll] = useState(false)
  const statusChange = async id => {
    setData(data =>
      data.map(item =>
        item.id == id
          ? { ...item, pending_status: item.pending_status === 0 ? 1 : 0 }
          : item
      )
    )
  }

  const columns = useMemo(() => salarySheetColumns(statusChange), [data])

  const fetchSalarySheet = async () => {
    const res = await fetchData(toggleLoading, "salarySheetData", token)
    if (res?.success) {
      setData(res?.data)
      getLength(res?.data)
    }
  }

  useEffect(() => {
    if (stepData?.sheet_status == 1) {
      fetchSalarySheet()
    }
  }, [stepData])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Row>
        <Col lg={0}>
          <Card>
            <CardBody>
              <div className="responsive-table">
                <TableContainer
                  columns={columns}
                  data={data}
                  toggleLoading={toggleLoading}
                  setData={setData}
                  stepData={stepData}
                  selectAll={selectAll}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default SalarySheet
