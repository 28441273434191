import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import moment from "moment"
import {
  calculateCompleteTime,
  getDaysBetweenDates,
} from "utils/common-helpers"
const CaseOverview = ({ projectDetail, members }) => {
  return (
    <React.Fragment>
      <div className=" mb-3">
        <Container fluid>
          <div className="row">
            <div className="col-lg-12 ps-0">
              <div className="card">
                <div className="card-body">
                  <h5 className="font-size-15">Project Description :</h5>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: projectDetail?.description,
                    }}
                  />

                  <div className="d-flex gap-4 mt-5 ">
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Total Test Cases
                        </span>{" "}
                        : {projectDetail?.testcase}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Total Tickets
                        </span>
                        : {projectDetail?.total_tickets}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Not Answered
                        </span>{" "}
                        : {projectDetail?.notanswered}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">Answered</span>:
                        {projectDetail?.answered}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Team Members
                        </span>
                        :{" "}
                        {members?.map(obj => {
                          return (
                            <span key={obj.alias_name}>{obj.alias_name}, </span>
                          )
                        })}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Testing Allocated Hours
                        </span>
                        : {projectDetail?.testing} Hrs
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Testing Used Hours
                        </span>
                        :{" "}
                        {calculateCompleteTime(
                          projectDetail?.qa_uesd_hrs || [],
                          "time"
                        )}{" "}
                        Hrs
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold ">
                          Severity High
                        </span>
                        :{" "}
                        {Number(projectDetail?.high_fail_count) +
                          Number(projectDetail?.high_pass_count)}{" "}
                        (
                        <span className=" text-success me-1">
                          Pass : {projectDetail?.high_pass_count}{" "}
                        </span>
                        /
                        <span className="text-danger  ms-1">
                          Fail : {projectDetail?.high_fail_count}
                        </span>
                        )
                      </p>

                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Severity Medium
                        </span>
                        :  {Number(projectDetail?.medium_fail_count) +
                          Number(projectDetail?.medium_pass_count)} (
                        <span className=" text-success me-1">Pass : {projectDetail?.medium_pass_count} </span>/
                        <span className="text-danger  ms-1">Fail : {projectDetail?.medium_fail_count}</span>)
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Severity Low
                        </span>
                        : {Number(projectDetail?.low_fail_count) +
                          Number(projectDetail?.low_pass_count)} (
                        <span className=" text-success me-1">Pass : {projectDetail?.low_pass_count} </span>/
                        <span className="text-danger  ms-1">Fail : {projectDetail?.low_fail_count}</span>)
                      </p>
                    </div>
                  </div>

                  <div className="task-dates row mt-4">
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar me-1 text-primary"></i>{" "}
                          Start Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.startdate).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Est. End Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.enddate).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          No. Of Days
                        </h5>
                        <p className="text-muted mb-0">
                          {getDaysBetweenDates(
                            projectDetail?.startdate,
                            projectDetail?.enddate
                          )}{" "}
                          Days
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3 ps-0">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Delivery Before Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.deliverydatebefore).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CaseOverview
