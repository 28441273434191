import React from "react"
import bug from "../../../assets/images/flags/bug-icon.png"
import Suggestion from "../../../assets/images/flags/sugg-icon.png"
import moment from "moment"
import { ticketStatus } from "utils/common-options"

export const developerTicketColumn = (onReply, onChangeStatus) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "s_id",
    },
    {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "stype_name_box",
        Cell: cellProps => {
            const icon = cellProps.row?.original?.type == 0 ? bug : Suggestion
            return (
                <div className="text-center d-flex gap-1 flex-wrap justify-content-center align-items-center type_img">
                    {icon != null ? (
                        <img src={icon} alt="" className="img-fluid assignee-img ms-2" ></img>
                    ) : '-'}
                </div>
            )
        },
    },
    {
        Header: "Title",
        title: "Task Name",
        accessor: "subject",
        isInfoIcon: false,
        isShortIcon: true,
        className: "stask_name_box",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "sstatus_box",
        Cell: cellProps => {

            return (
                <div className="custom-select-wrapper">
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row?.original?.status}
                        onChange={(e) => onChangeStatus(e.target.value, cellProps.row?.original?.id)}
                    >
                        {ticketStatus?.map((obj, i) => {
                            return (
                                <option value={obj?.value} className="" key={i}>
                                    {obj?.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Priority",
        title: "Priority",
        accessor: "priority",
        isInfoIcon: false,
        isShortIcon: true,
        className: "prioritys_col_box ",
        Cell: cellProps => {
            const cellValue = cellProps.value
            let style = {}

            if (cellValue === "High") {
                style = { color: "red" }
            } else if (cellValue === "Medium") {
                style = { color: "blue" }
            } else if (cellValue === "Low") {
                style = { color: "green" }
            }

            return (
                <div style={style}>
                    {cellValue}
                </div>
            )
        },
    },
    {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "screated_at_col-box",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "slast_reply_col-box ",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "msupport_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        onClick={() => onReply(cellProps?.row?.original)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Reply Ticket"
                    >
                        <i className="bi bi-reply-fill"></i>
                    </a>
                </div>
            )
        },
    },
]