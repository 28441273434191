import React, { useEffect, useState } from "react"
import { Container, Form, Label } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import NewPagination from "components/Common/Newpagination"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Select from "react-select"
import viewTaskImg from "../../../../../assets/images/Project/viewTask.jpg"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { developmentHours, priorityOption } from "utils/common-options"
import { taskCaseColumns } from "utils/columns/Support/projectTicketColumn"
import CreateTestCase from "./CreateTestCase"

function TableContainer({ columns, data, setData, allData }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  const [status, setStatus] = useState("all")

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-4 align-items-center">
        <Col md={9} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-2 rounded proposal-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all")
                  setData(allData)
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-red pt-2 px-2 rounded proposal-status ${
                  status == "0" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("0")
                  setData(allData?.filter(obj => obj.status == "0"))
                }}
              >
                <h4> {allData.filter(obj => obj.status == "0")?.length}</h4>
                <h4>Pending</h4>
              </a>
              <a
                className={`text-yellow pt-2 px-2 rounded proposal-status ${
                  status == "2" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("2")
                  setData(allData?.filter(obj => obj.status == "2"))
                }}
              >
                <h4> {allData.filter(obj => obj.status == "2")?.length}</h4>
                <h4>Dev Complete</h4>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead className={`fix-project-header`}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowColor
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green"
                  break
                case "Awaiting Feedback":
                  rowColor = "bg-red"
                  break
                case "Complete":
                  rowColor = "bg-yellow"
                  break
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const CaseTask = ({ verticalActiveTab }) => {
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [viewTask, setViewTask] = useState(false)
  const [testCase, setTestCase] = useState(false)
  const userId = localStorage.getItem("auth_id")
  const token = secureLocalStorage.getItem("auth_token")
  const [taskDetail, setTaskDetails] = useState({})
  const [boxWidth, setBoxWidth] = useState(0)
  const [taskId, setTaskId] = useState(0)
  const param = useParams()
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.9)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  useEffect(() => {
    if (verticalActiveTab == 3) {
      fetchTasks()
    }
  }, [verticalActiveTab])

  const toggleLoading = loading => {
    setLoading(loading)
  }

  const fetchTasks = async () => {
    const res = await fetchData(
      toggleLoading,
      `taskData/${userId}/${param.id}`,
      token
    )
    if (res) {
      setData(res)
      setAllData(res)
    }
  }
  const onVeiwTask = obj => {
    setViewTask(true)
    setTaskDetails(obj)
  }
  const onCreateTask = obj => {
    setTaskId({ label: obj?.title, value: obj?.id })
    setTestCase(true)
  }
  const columns = useMemo(() => taskCaseColumns(onVeiwTask, onCreateTask), [])
  const closeModal = modal => {
    setTestCase(modal)
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      allData={allData}
                      setData={setData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Task Detaisl  */}
      <div className={`note-sticky-form ${viewTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={1000}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">View Task Details</h5>
              <button
                type="button"
                onClick={() => {
                  setViewTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
                  <div className="text-center">
                    <img
                      src={viewTaskImg}
                      alt="Addepe"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-md-8 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={taskDetail?.title}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-6 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Milestone <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Milestone"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        value={{ label: taskDetail?.milestone, value: 0 }}
                      />
                    </div>
                    <div className="col-md-6 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        value={developmentHours?.find(
                          obj => obj?.value == taskDetail?.estimation_days
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-6 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                        value={taskDetail?.probability || ""}
                      />
                    </div>
                    <div className="col-md-6 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        value={priorityOption?.find(
                          obj => obj?.value == taskDetail?.priority
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12">
                      <label htmlFor="">Description</label>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: taskDetail?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <div className={`note-sticky-form ${testCase ? "d-block" : "d-none"}`}>
        <Draggable handle=".modal-header" cancel=".modal-body">
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Create Test Case</h5>
              <button
                type="button"
                onClick={() => {
                  setTestCase(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <CreateTestCase
              task={false}
              closeModal={closeModal}
              taskIds={taskId}
              updatedData={() => {}}
            />
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default CaseTask
