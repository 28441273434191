import React, { useEffect, useMemo, useState } from "react"
import { Card, Col, Row, Table } from "reactstrap"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { customGlobalFilter } from "utils/common-helpers"
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
} from "react-table"
import NewPagination from "components/Common/Newpagination"
import { projectCoinsColumns } from "utils/columns/ElementsColumns/supportColumns"

function TableContainer({ columns, data, allData, setData }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            globalFilter: customGlobalFilter,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => {
        setPageSize(10)
    }, [])
    // Render the UI for your table

    const [fixedHeading, setFixedHeading] = useState(false)
    const [status, setStatus] = useState('1')
    document.addEventListener("scroll", () => {
        if (window.scrollY > 40) {
            setFixedHeading(true)
        } else {
            setFixedHeading(false)
        }
    })


    return (
        <div>
            <Row>
                <Col xl="12">
                    <div className="card-body ">
                        <Row className="mb-2 justify-content-between">
                            <div className="mb-3 mb-md-0 d-flex justify-space-between align-items-center">
                                <div className="col-6  d-flex align-items-center gap-3">
                                    <div className="text-purple fs-5  fw-bold">Project Coins</div>
                                    <a className={`d-flex gap-3 align-items-center general-box ${status == 1 ? 'bg-purple' : ''}`}
                                        onClick={() => {
                                            setStatus(1)
                                            setData(allData?.filter((obj) => obj?.project_type == 1))
                                        }}>
                                        <h5 className="text-purple mb-0">Fixed : </h5><h5 className="text-primary mb-0">
                                            {allData?.filter((obj) => obj?.project_type == 1)?.length}
                                        </h5>
                                    </a>
                                    <a className={`d-flex gap-3 align-items-center general-box ${status == 2 ? 'bg-purple' : ''} `}
                                        onClick={() => {
                                            setStatus(2)
                                            setData(allData?.filter((obj) => obj?.project_type == 2))
                                        }}>
                                        <h5 className="text-purple mb-0">Dedicate : </h5><h5 className="text-primary mb-0">
                                            {allData?.filter((obj) => obj?.project_type == 2)?.length}
                                        </h5>
                                    </a>
                                    <a className={`d-flex gap-3 align-items-center general-box ${status == 3 ? 'bg-purple' : ''} `}
                                        onClick={() => {
                                            setStatus(3)
                                            setData(allData?.filter((obj) => obj?.project_type == 3))
                                        }}>
                                        <h5 className="text-purple mb-0">Maintenance : </h5><h5 className="text-primary mb-0">
                                            {allData?.filter((obj) => obj?.project_type == 3)?.length}
                                        </h5>
                                    </a>
                                </div>

                                <div className="col-5 d-flex justify-content-end align-items-center">
                                    <div className="col-6">
                                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                                            <div className="d-flex">
                                                <div className="position-relative w-100">
                                                    <label
                                                        htmlFor="search-bar-0"
                                                        className="search-label w-100 mb-0"
                                                    >
                                                        <span id="search-bar-0-label" className="sr-only">
                                                            Search this table
                                                        </span>
                                                        <input
                                                            onChange={e => setGlobalFilter(e.target.value)}
                                                            id="search-bar-0"
                                                            type="text"
                                                            className="border-radius form-control rounded h-100 w-100"
                                                            placeholder={`Search...`}
                                                            value={globalFilter || ""}
                                                        />
                                                    </label>
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="position-relative table-responsive">
                            <Table className="table table-bordered mt-4" {...getTableProps()}>
                                <thead className={`fixed_view_payment_table`}>
                                    {headerGroups.map((headerGroup, i) => (
                                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column, index) => (
                                                <th
                                                    key={index}
                                                    className={` ${column.className}`}
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody
                                    className="fix-introducer-header"
                                    {...getTableBodyProps()}
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row)

                                        return (
                                            <tr
                                                className={`row-hover`}
                                                {...row.getRowProps()}
                                                key={i}
                                            >
                                                {row.cells.map((cell, index) => {
                                                    return (
                                                        <td
                                                            key={index}
                                                            className={`t-col-${index + 1} ${cell.column.className
                                                                }`}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>

                        <Row className="justify-content-md-end justify-content-center align-items-center">
                            <NewPagination
                                gotoPage={gotoPage}
                                canPreviousPage={canPreviousPage}
                                previousPage={previousPage}
                                canNextPage={canNextPage}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                pageSize={pageSize}
                                page={page}
                                data={data}
                                nextPage={nextPage}
                            />
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const ProjectTickets = () => {
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const token = secureLocalStorage.getItem('auth_token')

    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    useEffect(() => {
        const fetchProjectCoins = async () => {
            const res = await fetchData(
                toggleLoading,
                `projectCoinsData/${localStorage.getItem('auth_id')}`,
                token
            )
            if (res?.success) {
                setData(res?.data?.filter((obj) => obj?.project_type == '1'))
                setAllData(res?.data)
            }
        }
        fetchProjectCoins()
    }, [])
    const handleSendTesting = (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are You Sure?",
            html: "You are sending this project for the first time. Please ensure the following before proceeding:<br><br><strong>1. The developer has completed unit testing ?</strong><br><strong>2. The functional flow has been thoroughly reviewed ?</strong>",
            showCancelButton: true,
            confirmButtonText: 'Yes, Send For QA',
        }).then(async (data) => {
            if (data?.isConfirmed) {
                const formEntries = {
                    project_id: id,
                    user_id: localStorage.getItem('auth_id')
                }
                const res = await FetchPostData(toggleLoading, 'sendProjectTesting', token, formEntries);
                if (res?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Project Sent For Testing",
                    })
                }
            }
        })
    }

    const column1 = useMemo(
        () => projectCoinsColumns(handleSendTesting),
        []
    )
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="">
                <div>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <div className="responsive-table">
                                    <TableContainer
                                        columns={column1}
                                        data={data}
                                        toggleLoading={toggleLoading}
                                        allData={allData}
                                        setData={setData}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProjectTickets
