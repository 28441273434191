import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import Loader from "pages/Separate/Loader"
import zithasLogg from "../../../assets/images/main-zithas.png"
import ReplyIndex from "pages/WorkModule/Support/Tickets/Reply/ReplyIndex"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"
import moment from "moment"

const QueriesTickets = () => {
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const [replyTiket, setReplyTiket] = useState(false)
    const [replyWidth, setReplyWidth] = useState(0)
    const [ticketDetails, setTicketDetails] = useState({})
    const token = secureLocalStorage.getItem('auth_token')
    const toggleLoading = (loading) => {
        setLoading(loading)
    }
    const onSearchNotification = e => {
        e.preventDefault()
        const searchTerm = e.target.value.toLowerCase()
        const filteredData = allData.filter(
            obj =>
                obj.title.toLowerCase().includes(searchTerm) ||
                obj.message.toLowerCase().includes(searchTerm) ||
                obj.module.toLowerCase().includes(searchTerm) ||
                obj.id == searchTerm
        )
        setData(filteredData)
    }
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setReplyWidth(viewportWidth * 0.98)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    useEffect(() => {
        const fetchProjectList = async () => {
            const res = await fetchData(
                toggleLoading,
                `queryTickets`,
                token
            )
            if (res?.success) {
                setData(res?.data)
                setAllData(res?.data)
            }
        }
        fetchProjectList()
    }, [])
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div>
                <Card>
                    <CardBody>
                        <div className="mb-4 d-flex justify-content-between">
                            <div className="d-flex gap-2 col-md-4 align-items-center">
                                <p className="text-purple fs-5 mb-0 fw-bold ">
                                    Queries Tickets
                                </p>
                            </div>
                            <div className="d-flex gap-2 col-md-3 align-items-center">
                                <input
                                    id="search-bar-0"
                                    type="text"
                                    className="border-radius form-control rounded h-100"
                                    placeholder="Search..."
                                    onChange={e => onSearchNotification(e)}
                                />
                            </div>
                        </div>
                        <div className="responsive-table mb-4">
                            <Table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th className="header  col-md-1">Ticket Id</th>
                                        <th className="header">Assign</th>
                                        <th className="header">Type</th>
                                        <th className="header col-md-4">Project Name</th>
                                        <th className="header col-md-5">Title</th>
                                        <th className="header">Priority</th>
                                        <th className="header col-md-1">Created At</th>
                                        <th className="header text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((obj, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{obj?.id}</td>
                                                <td className="text-center">
                                                    <img src={zithasLogg} alt="No Img" title="Admin" width={30} height={30} className="object-fit-cover rounded-circle" /> </td>
                                                <td>Bug</td>
                                                <td>{obj?.project_name} </td>
                                                <td>{obj?.subject} </td>
                                                <td className="text-capitalize">{obj?.priority} </td>
                                                <td>{moment(obj?.created_at).format('DD-MM-YYYY')}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <a
                                                            className="btn btn-sm btn-outline-secondary me-1"
                                                            title="Reply Ticket"
                                                            onClick={(e) => {
                                                                setReplyTiket(true)
                                                                setTicketDetails(obj)
                                                            }}
                                                        >
                                                            <i className="bi bi-reply-fill"></i>
                                                        </a>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            {data?.length == 0 ? (
                                <p className="text-center text-purple fw-bold mt-3">
                                    🎉 All Quiet for Now 🚀
                                </p>
                            ) : null}
                        </div>
                    </CardBody>
                </Card>
            </div>

            {/* Reply Ticket  */}
            <div className={`note-sticky-form ${replyTiket ? "d-block" : "d-none"}`}>
                <Draggable
                // handle={`${noDrag ? ".modal-header" : ""}`}
                // cancel={`${noDrag ? ".modal-body" : ""}`}
                >
                    <ResizableBox
                        width={replyWidth}
                        style={{
                            position: "fixed",
                            top: "5%",
                            right: "0.5%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                            maxHeight: "fit-content",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <div className="modal-header pe-4 mb-3">
                            <h5 className="modal-title mt-0">Reply Ticket </h5>
                            <span
                                aria-hidden="true"
                                className="btn-modal-span cursor-pointer"
                                onClick={() => setReplyTiket(false)}
                            >
                                &times;
                            </span>
                        </div>

                        <div className="modal-body pe-4 ">
                            <ReplyIndex
                                ticketDetails={ticketDetails}
                                replyTiket={replyTiket}
                            />
                        </div>
                    </ResizableBox>
                </Draggable>
            </div>
        </React.Fragment>
    )
}

export default QueriesTickets
