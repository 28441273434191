import React from "react"
import { developmentHours, milestoneType } from "utils/common-options"

export const intialMilestoneClomuns = (onDelete, onEdit, onView) => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "mil_id",
    },
    {
        Header: "Milestone",
        accessor: "milestone",
        isShortIcon: true,
        className: "mil_name",
        Cell: cellProps => {
            const data = cellProps.row?.original
            return <div>Milestone {data?.milestone_no} - {milestoneType?.find((obj) => obj?.value == data?.milestone_type)?.type}</div>
        },
    },
    {
        Header: "Hours",
        accessor: "milestone_days",
        isShortIcon: true,
        className: "mile_hrs text-center",
    },
    {
        Header: "Scope",
        accessor: "scope",
        isShortIcon: true,
        className: "mile_desc",
        Cell: cellProps => {
            const scopes = cellProps?.row?.original?.scope?.split('@#')
            return (
                <div>
                    {scopes?.slice(0, 2).map((obj, i) => {
                        return (
                            <span key={i}>{obj} {i < scopes.length - 1 ? ', ' : ''}</span>
                        )
                    })}
                </div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "mile_action",
        Cell: cellProps => {
            const data = cellProps.row?.original
            return (
                <div>
                    <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => onEdit(data)}
                        title="Edit"
                    >
                        <i className="fas fa-pen "></i>
                    </button>
                    <button
                        onClick={() => onView(data)}
                        title="View"
                        className="btn btn-sm btn-outline-secondary ms-2"
                    >
                        <i className="fas fa-eye "></i>
                    </button>
                    <button
                        onClick={() => onDelete(data?.id)}
                        className="btn btn-sm btn-danger ms-2"
                        title="Delete"
                    >
                        <i className="fas fa-trash-alt "></i>
                    </button>
                </div>
            )
        },
    },
]
export const initialTaskColumns = (onEdit, onDeleteTask) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "t_id",
    },
    {
        Header: "Title",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "t_name",
    },
    {
        Header: "Est Hrs",
        accessor: "estimated_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "t_est_hrs",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.estimation_days} Hours</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priority",
        isInfoIcon: false,
        isShortIcon: true,
        className: "t_est_hrs",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps?.row?.original?.priority}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "t_action text-center",
        Cell: cellProps => {
            const data = cellProps.row?.original
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Task"
                        onClick={(e) => onEdit(data)}
                    >
                        <i className="fas fa-pen "></i>
                    </a>
                    <a
                        className="btn btn-sm btn-danger ms-2"
                        title="Delete Task"
                        onClick={(e) => onDeleteTask(data?.id)}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                </div>
            )
        },
    },
]

export const initailCompleteClomuns = () => [
    {
        Header: "ID",
        accessor: "id",
        isShortIcon: "true",
        className: "com_id",
    },
    {
        Header: "Title",
        accessor: "title",
        isShortIcon: "true",
        className: "com_title",
    },
    {
        Header: "Milestone",
        accessor: "milestone",
        className: "com_milestone",
        isShortIcon: "true",
        Cell: cellProps => {
            return (
                <div>
                    {/* {milestoneType?.find((obj) => obj?.value == cellProps?.row?.original?.milestone_type)?.type} - */}
                    Milestone {cellProps?.row?.original?.milestone_no}
                </div>
            )
        }
    },
    {
        Header: "Est. Hrs",
        accessor: "estimated_days",
        isShortIcon: "true",
        className: "com_es_hrs text-center",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.estimation_days} Hours</div>
            )
        }
    },
]