import React from 'react';
import sideImg from "../../../assets/images/profile.png"
import configure from 'configure';
export const monthlyApprovalColumn = (packageActivity) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "com_id",
    },
    {
        Header: "Activity Name",
        accessor: "name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_name",
    },
    {
        Header: "Pkg. Quantity",
        accessor: "quantitys",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            const packageQty = packageActivity?.find((obj) => obj?.id == cellProps?.row?.original?.activity_id)?.quantity
            return (
                <div>
                    {packageQty || "-"}
                </div>
            )
        }
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
    },
    {
        Header: "Date",
        accessor: "date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
    },
    {
        Header: "Assign",
        accessor: "a_assign",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            return (
                <div className=" d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.avatar != null ? `${configure.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}`}
                        alt=""
                        className="img-fluid"
                        style={{ borderRadius: "100%", width: "25px", height: "25px" }}
                        title={cellProps?.row?.original?.alias_name}
                    />
                </div>
            )
        },
    },
    {
        Header: "Total Est. Hrs",
        accessor: "est_hrs",
        isInfoIcon: false,
        isShortIcon: true,
        className: "a_kam_hrs",
        Cell: cellProps => {
            const data = cellProps.row.original
            return (
                <div>{data?.est_hrs} </div>
            )
        }
    },
]