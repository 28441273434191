import React, { useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
import "../../assets/css/Editor/Tiny.css"
import axios from "axios"
import configure from "configure"
import { identity } from "lodash"
const TinyEditor = ({
  description,
  setDescription,
  setError,
  setEditor,
  editor,
  setLoading,
  row,
}) => {
  // const [videoUrl, setVideoUrl] = useState("")
  // const handleEditorChange = (content, editor) => {
  //   // setEditor(editor)
  //   const Description = editor.getContent()
  //   // setDescription(Description)
  //   setError(false)
  // }
  const editorRef = useRef(null)
  const handleEditorChange = (content, editor) => {
    const Description = editor.getContent()
    setEditor(content)
    setError(false)
  }

  const handleEditorInit = (evt, editor) => {
    editor.ui.registry.addButton("image", {
      icon: "image",
      tooltip: "Insert image",
      onAction: function () {
        const input = document.createElement("input")
        input.setAttribute("type", "file")
        input.setAttribute("accept", "image/*")
        input.onchange = async function () {
          const file = this.files[0]
          if (file) {
            try {
              const imageUrl = await uploadFile(file)
              editor.insertContent(`<img src="${imageUrl}" alt="${file.name}">`)
            } catch (error) {
              console.error(error)
            }
          }
        }
        input.click()
      },
    })
    editor.ui.registry.addButton("video", {
      icon: "embed",
      tooltip: "Insert video",
      onAction: function () {
        const input = document.createElement("input")
        input.setAttribute("type", "file")
        input.setAttribute("accept", "video/*")
        input.onchange = async function () {
          const file = this.files[0]
          if (file) {
            try {
              const fileUrl = await uploadFile(file)
              editor.insertContent(
                `<video controls><source src="${fileUrl}" type="${file.type}"></video>`
              )
            } catch (error) {
              console.error(error)
            }
          }
        }
        input.click()
      },
    })
    editor.ui.registry.addButton("pdf", {
      icon: "export-pdf",
      // icon: "document-properties",
      tooltip: "Insert pdf",
      onAction: function () {
        const input = document.createElement("input")
        input.setAttribute("type", "file")
        input.setAttribute("accept", "pdf")
        input.onchange = async function () {
          const file = this.files[0]
          if (file) {
            try {
              const fileUrl = await uploadFile(file)
              editor.insertContent(
                `<a href="${fileUrl}" target="_blank">${file.name}</a>`
              )
            } catch (error) {
              console.error(error)
            }
          }
        }
        input.click()
      },
    })
    editor.ui.registry.addButton("excel", {
      icon: "export-word",
      tooltip: "Insert excel",
      onAction: function () {
        const input = document.createElement("input")
        input.setAttribute("type", "file")
        input.setAttribute("accept", ".xls,.xlsx,.csv")
        input.onchange = async function () {
          const file = this.files[0]
          if (file) {
            try {
              const fileUrl = await uploadFile(file)
              editor.insertContent(
                `<a href="${fileUrl}" download="${file.name}">${file.name}</a>`
              )
            } catch (error) {
              console.error(error)
            }
          }
        }
        input.click()
      },
    })
    // Custom Border button
    editor.ui.registry.addButton("border", {
      icon: "border-style",
      tooltip: "Set border",
      onAction: function () {
        editor.execCommand(
          "mceInsertContent",
          false,
          `<span style="border: 2px solid #000;">${editor.selection.getContent({
            format: "html",
          })}</span>`
        )
      },
    })
    editor.ui.registry.addButton("boxShadow", {
      icon: "shadow", // You can choose an appropriate icon from TinyMCE's icon set
      tooltip: "Add box shadow",
      onAction: function () {
        const selectedContent = editor.selection.getContent({
          format: "html",
        })
        const boxShadowStyle = "box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);"
        const wrappedContent = `<span style="${boxShadowStyle}">${selectedContent}</span>`

        editor.execCommand("mceInsertContent", false, wrappedContent)
      },
    })
  }
  const uploadFile = async file => {
    setLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/uploadVideo`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        return response.data.data
      }
    } catch (error) {
      setLoading(false)
      console.error("Upload failed", error)
    }
    // let imgLink =
    //   "hhttps://videos.pexels.com/video-files/6003986/6003986-uhd_1440_2560_30fps.mp4";
    // return imgLink;
  }
  useEffect(() => {
    let notifications = document.querySelectorAll(".tox-notification__dismiss")
    if (notifications) {
      notifications.forEach(function (button, index) {
        setTimeout(function () {
          button.click()
        }, index * 1) // Adjust the delay (in milliseconds) between each close action
      })
    }
    if (row == 2 || row == 4) {
      window.addEventListener("tox-notifications-container", function () {
        setTimeout(function () {
          let notifications = document.querySelectorAll(
            ".tox-notification__dismiss"
          )
          notifications.forEach(function (button, index) {
            setTimeout(function () {
              button.click()
            }, index * 10) // Adjust the delay (in milliseconds) between each close action
          })
        }, 2000) // Adjust the initial delay before starting to close notifications
      })
    }
  }, [row])
  useEffect(() => {
    const closeNotifications = () => {
      let notifications = document.querySelectorAll(
        ".tox-notification__dismiss"
      )
      notifications.forEach((button, index) => {
        setTimeout(() => {
          button.click()
        }, index * 10)
      })
    }
    const checkLoadAndCloseNotifications = () => {
      let container = document.querySelector(".tox-notifications-container")
      if (container) {
        closeNotifications()
      } else {
        setTimeout(checkLoadAndCloseNotifications, 100)
      }
    }
    checkLoadAndCloseNotifications()
    return () => {}
  }, [])

  return (
    <div className="col-md-12" id="tiny">
      <Editor
        initialValue={description}
        // placeholder="Start typing.."
        cloudChannel="7-dev"
        apiKey="w6fz9u4rgsouz7jqcyy4lflpolp0tjwkx1bzoc70ntetxwys"
        onInit={handleEditorInit}
        init={{
          height: 800,
          menubar: true,
          plugins: [
            "a11ychecker",
            "accordion",
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "spellchecker",
            "checklist",
            "markdown",
            "export",
            "link",
            "preview",
            "lists",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "table",
            "wordcount",
            "code",
            "pagebreak",
            "codesample",
            "textcolor",
            "importpdf",
            "importexcel",
            "emoticons",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic forecolor backcolor | fontselect fontsizeselect |" +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table | image | video | pdf | excel | border | boxShadow ",
          fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
          // link_target_list: [
          //   { title: "Open in a new window", value: "_blank" },
          //   { title: "Open in this window", value: "_self" },
          //   { title: "None", value: "" },
          // ],
          browser_spellcheck: true,
          link_class_list: [
            { title: "None", value: "" },
            { title: "External Link", value: "ext_link" },
            { title: "Internal Support Link", value: "int_sup_link" },
            { title: "Internal Marketing Link", value: "int_mark_link" },
            { title: "Other Internal Link", value: "int_other_link" },
          ],
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  )
}

export default TinyEditor
