import NewPagination from "components/Common/Newpagination"
import { Draggable } from "devextreme-react"
import React, { useEffect, useMemo, useState } from "react"
import { ResizableBox } from "react-resizable"
import { useGlobalFilter, usePagination, useTable } from "react-table"
import { Card, CardBody, Col, Form, Row, Table } from "reactstrap"
import secureLocalStorage from "react-secure-storage"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import Loader from "pages/Separate/Loader"
import { supportTaskColumns } from "utils/columns/Support/projectTicketColumn"
import { developmentHours, TimeOption } from "utils/common-options"
import Select from "react-select"
import AddTime from "../../../../assets/images/AddTime.jpg"
import Swal from "sweetalert2"
import moment from "moment"

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [name_modal, setNameModal] = useState(false)
  const [title, setTitle] = useState("")

  return (
    <div>
      <Row>
        <div className="col-md-12 my-2 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-headers" : ""
            } fix-project-headers`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-headers" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const rowColor = row.original.status == "1"
              return (
                <tr
                  className={`row-hover ${rowColor ? "bg-green" : ""}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const SupportTask = ({ verticalActiveTab }) => {
  const token = secureLocalStorage.getItem("auth_token")
  const [data, setData] = useState([])
  const [addTime, setAddTime] = useState(false)
  const [taskDetail, setTaskDetail] = useState({})
  const [allTime, setAllTime] = useState([])
  const [time, setTime] = useState([])
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const onAddTime = async obj => {
    const formEntries = {
      user_id: localStorage.getItem("auth_id"),
      task_id: obj.id,
    }
    const res = await FetchPostData(
      toggleLoading,
      "qaTaskTimer",
      token,
      formEntries
    )
    if (res?.data?.success) {
      setAllTime(res?.data?.data)
      setTaskDetail(obj)
      setAddTime(true)
    }
  }
  const changeStatus = async (status, id) => {
    const formEntries = {
      status: status,
      task_id: id,
      user_id: localStorage.getItem("auth_id"),
      type: verticalActiveTab,
    }
    const res = await FetchPostData(
      toggleLoading,
      "changeStatusQaTasks",
      token,
      formEntries
    )
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "Status changed successfully",
      }).then(data => {
        setData(res?.data?.data)
      })
    }
  }
  const columns = useMemo(
    () => supportTaskColumns(onAddTime, changeStatus),
    [verticalActiveTab]
  )
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTask = async () => {
      const userId = localStorage.getItem("auth_id")
      const res = await fetchData(
        toggleLoading,
        `todayYesterdayQaTasks/${userId}/${verticalActiveTab}`,
        token
      )
      if (res?.success) {
        setData(res?.data)
      }
    }
    fetchTask()
  }, [verticalActiveTab])

  const onHandleTime = async () => {
    if (time?.length != 0) {
      const formEntries = {
        user_id: localStorage.getItem("auth_id"),
        task_id: taskDetail?.id,
        project_id: taskDetail?.project_id,
        type: verticalActiveTab,
        time: time?.value,
      }
      const res = await FetchPostData(
        toggleLoading,
        "addQaTaskTimer",
        token,
        formEntries
      )
      if (res?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Time Added Successfully",
        }).then(data => {
          setTime([])
          setAllTime(res?.data?.data)
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Select Time",
      })
    }
  }
  return (
    <div>
      {isLoading && <Loader />}
      <div className="">
        <Row>
          <Col lg={12} className="p-0">
            <Card>
              <CardBody className="pt-0">
                <div className="responsive-table">
                  <TableContainer columns={columns} data={data} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div
          className={`note-sticky-form position-relative ${
            addTime ? "d-block" : "d-none"
          }`}
        >
          <Draggable bounds="parent">
            <ResizableBox
              width={900}
              style={{
                position: "absolute",
                bottom: "10%",
                right: "2%",
                zIndex: "1060",
                backgroundColor: "#fff",
              }}
            >
              <div className="justify-content-between  d-flex mb-3">
                <h5 className="modal-title mt-0">Task Timer</h5>
                <button
                  type="button"
                  onClick={() => {
                    setAddTime(false)
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body pe-4">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                    <div className="text-center">
                      <img
                        src={AddTime}
                        alt="Addepe"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-column justify-space-between  gap-5 mb-4">
                    <div className="">
                      <div className="col-12 d-flex justify-space-between align-items-center">
                        <div className="col-7 d-flex gap-2 ">
                          <div className="col-md-8">
                            <label htmlFor="">
                              Select Time <span className="text-danger">*</span>
                            </label>
                            <Select
                              placeholder="Select Time"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={TimeOption}
                              menuPortalTarget={document.body}
                              value={time}
                              onChange={e => setTime(e)}
                              styles={{
                                menuPortal: base => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="text-end mt-4">
                          <button
                            className=" btn btn-save btn-label"
                            onClick={onHandleTime}
                          >
                            <i className="fas fa-save me-2 label-icon"></i>
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive col-md-12 mt-4 ms-3">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th className="header time-taken-user">Id</th>
                              <th className="text-center header col-1">Date</th>
                              <th className="text-center header col-1">
                                Time Taken
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allTime?.map((obj, i) => {
                              return (
                                <tr key={i}>
                                  <td className="">{obj?.id}</td>
                                  <td className="text-center">
                                    {moment(obj?.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td className="text-center">{obj?.time}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ResizableBox>
          </Draggable>
        </div>
      </div>
    </div>
  )
}

export default SupportTask
