import React, { useEffect, useState } from 'react'
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import TaskTable from 'components/HorizontalLayout/Timer/TaskTable'
import configure from 'configure'
import classNames from "classnames"
import TimerTickets from 'pages/WorkModule/Support/Timer Tickets/TimerTickets'


const ProjectTimer = () => {
    const [boxWidth, setBoxWidth] = useState(0)
    const [changeTask, setChangeTask] = useState(false)
    const [verticalActiveTab, setverticalActiveTab] = useState('1')
    const [isIndex, setIsIndex] = useState(false)
    const handleHover = () => {
        setIsIndex(true)
    }
    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setBoxWidth(viewportWidth * 0.95)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    const handleLeave = () => {
        setIsIndex(false)
    }
    const ProjectList = [
        {
            id: "1",
            label: "Today's Tasks",
            text: "Today's",
        },
        {
            id: "2",
            label: "Yesterday's Tasks",
            text: "Yesterday's",
        },
        {
            id: "3",
            label: "Pening Tasks",
            text: "Pending",
        },
        {
            id: "4",
            label: "Support Tickets",
            text: "Support",
        },
    ]

    return (
        <div>
            <a onClick={() => setChangeTask(true)} className="pe-3 text-white" title="Switch Task">
                <i className="fa fa-stopwatch"></i>
            </a>
            <Modal size="xl" isOpen={changeTask} className="modal-fullscreen">

                <div className="modal-header mb-0" style={{
                    border: "none",
                    padding: "10px 35px 15px 15px"
                }}>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            width: "100%",
                        }}
                    >
                        <h5 className="col-6 modal-title ">Today's Overview</h5>

                        <div className="d-flex justify-content-end align-items-center">
                            <Link className="s btn text-white me-0 btn-primary btn-label"
                                to={`${configure.appUrl}complete_sprint/development`}
                            >

                                <i className="bx bx-run me-2 icon-size label-icon"></i>
                                Complete Sprint
                            </Link>
                            <a
                                type="button"
                                onClick={() => setChangeTask(false)}
                                className="ms-4 mt-2 text-danger fs-3 lh-1 "
                            >
                                &times;
                            </a>
                        </div>
                    </div>
                </div>
                <div className="modal-body  d-flex col-12">
                    <div className={`px-0 col-1  dm-overview-width`}
                        style={isIndex ? { zIndex: "2" } : {}}
                    >
                        <div className="position-relative">
                            <div>
                                <SimpleBar
                                    style={{ height: "55vh" }}
                                    className={`overflow-visible`}
                                >
                                    <Nav pills className={` flex-column px-1 py-1 h-100`}>
                                        {ProjectList?.map((obj, i) => {
                                            return (
                                                <NavItem
                                                    className={classNames({
                                                        "d-flex flex-column gap-2 mb-2": true,
                                                        active: verticalActiveTab == obj.id,
                                                    })}
                                                    key={i}
                                                >
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classNames({
                                                            "text-center crendential": true,
                                                            active: verticalActiveTab == obj.id,
                                                            "bg-danger-active": obj?.id == 3
                                                        })}
                                                        onClick={(e) => setverticalActiveTab(obj.id)}
                                                        onMouseEnter={handleHover}
                                                        onMouseLeave={handleLeave}
                                                    >
                                                        {obj.text}
                                                        <span className="link_name border-start border-white border-2">
                                                            {obj.label}
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })}
                                    </Nav>
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <div className="form-container col-11 ">
                        <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted mt-4 mt-md-0"
                        >
                            {ProjectList?.map((obj, i) => {
                                if (obj?.id != 4) {
                                    return (
                                        <TabPane tabId={obj?.id} key={i}>
                                            <TaskTable verticalActiveTab={verticalActiveTab} />
                                        </TabPane>
                                    )
                                } 
                                else if (obj?.id == 4) {
                                    return (
                                        <TabPane tabId={obj?.id} key={i}>
                                            <TimerTickets key={i} verticalActiveTab={verticalActiveTab} />
                                        </TabPane>
                                    )
                                }
                            })}
                        </TabContent>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ProjectTimer
