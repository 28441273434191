export const priorityOption = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
]

export const RelatedToOption = [
    {
        label: "Other", value: "other", text: "OT", icon: "mdi mdi-dots-horizontal-circle-outline", color: "color-grey", id: 5, related: [
        ],
        role: 1
    },
    {
        label: "Sales", value: "sales", text: "SA", id: 3, icon: "mdi mdi-chart-bar", color: "text-success", related: [
            { label: "Lead", value: "lead" },
            { label: "Introducer", value: "introducer" },
            { label: "Proposal", value: "proposal" },
            { label: "Contract", value: "contract" },
            { label: "Marketing", value: "marketing" },
        ],
        role: 2
    },
    {
        label: "Work", value: "work", text: "WK", id: 4, icon: "dripicons-suitcase", color: "text-brown", related: [
            { label: "Resource Plan", value: "resource-plan" },
            { label: "Digital Marketing Project", value: "digital-project" },
            { label: "Development Project", value: "development-project" },
            { label: "Support", value: "support" },
            { label: "Icode", value: "Icode" },
        ],
        role: 4
    },
    {
        label: "Human Resource", value: "human-resource", text: "HR", id: 1, icon: "dripicons-user-group", color: "text-purple", related: [
            { label: "Staff", value: "staff" },
            { label: "Freelancer", value: "freelancer" },
            { label: "Payroll Management", value: "payroll-management" },
            { label: "List of Holidays", value: "list-of-holiday" },
            { label: "Festival", value: "festival" }
        ],
        role: 7
    },
    {
        label: "Finance", value: "finance", text: "FN", id: 2, icon: "fas fa-coins", color: "text-warning", related: [
            { label: "Expense", value: "expense" },
            { label: "Credit Card", value: "credit-card" },
            { label: "Invoice", value: "invoice" },
            { label: "GST", value: "gst" },
            { label: "TDS", value: "tds" },
        ],
        // role: 8
        role: 1
    },

]
export const GeneralTaskWork = [
    { label: "All", value: "all" },
    { label: "Not Started", value: "not-started" },
    { label: "In Progress", value: "in-progress" },
    { label: "Complete", value: "complete" },
]

export const TimeOption = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "0.05", value: "0:05" },
    { label: "0.10", value: "0:10" },
    { label: "0.15", value: "0:15" },
    { label: "0.30", value: "0:30" },
    { label: "0.45", value: "0:45" },
    { label: "1.00", value: "1:00" },
    { label: "1.15", value: "1:15" },
    { label: "1.30", value: "1:30" },
    { label: "1.45", value: "1:45" },
    { label: "2.00", value: "2:00" },
    { label: "2.15", value: "2:15" },
    { label: "2.30", value: "2:30" },
    { label: "2.45", value: "2:45" },
    { label: "3.00", value: "3:00" },
    { label: "5.00", value: "5:00" },
    { label: "8.00", value: "8:00" },
]

export const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: "white",
    }),
    menu: base => ({
        ...base,
        zIndex: 1200,
    }),
}

export const StaffOption = [

    {
        "value": 1,
        "label": "Admin"
    },
    {
        "value": 20,
        "label": "Janvi Bhagat"
    },
    {
        "value": 21,
        "label": "Zain Marchawali"
    },
    {
        "value": 44,
        "label": "Rahul Ovhal"
    },
    {
        "value": 46,
        "label": "zainual mukeshbhai marchawala"
    },
    {
        "value": 67,
        "label": "Mohit Kapadnis"
    },
    {
        "value": 68,
        "label": "All"
    },

]

export const ChanceOptions = [
    {
        value: "15",
        label: "15 Days"
    },
    {
        value: "30",
        label: "30 Days"
    },
    {
        value: "60",
        label: "60 Days"
    },
    {
        value: "90",
        label: "90 Days"
    }
]

export const DigitalServiceOptions = [
    {
        id: "1",
        text: "SEO",
        name: "Search Engine Optimization",
        icon: "bx bx-search-alt"
    },
    {
        id: "2",
        text: "SMM",
        name: "Social Media Marketing",
        icon: "bx bxs-megaphone"
    },
    {
        id: "3",
        text: "PPC",
        name: "Pay Per Click",
        icon: "mdi mdi-cursor-default-click"
    },
    {
        id: "4",
        text: "EM",
        name: "Email Marketing",
        icon: "mdi mdi-email-check"
    }
]

export const recurringTypeOptions = [
    {
        value: 'daily',
        label: 'Daily'
    },
    {
        value: 'weekly',
        label: 'Weekly'
    },
    {
        value: 'monthly',
        label: 'Monthly'
    },
]

export const daysOption = [
    {
        value: 'monday',
        label: 'Monday'
    },
    {
        value: 'tuesday',
        label: 'Tuesday'
    },
    {
        value: 'wednesday',
        label: 'Wednesday'
    },
    {
        value: 'thursday',
        label: 'Thursday'
    },
    {
        value: 'friday',
        label: 'Friday'
    },
]


export const ModuleOptions = [

    {
        "value": 'sales',
        "label": "Sales"
    },
    {
        "value": 'marketing',
        "label": "Marketing"
    },
    {
        "value": 'work',
        "label": "Work"
    },
    {
        "value": "human-resource",
        "label": "Human Resource"
    },
    {
        "value": 'audit-logs',
        "label": "Audit Logs"
    },
    {
        "value": 'finance',
        "label": "Finance"
    },
    {
        "value": 'report',
        "label": "Report"
    },


]

export const DigitalServiceOptionsDrop = [
    {
        value: "1",
        label: "SEO",
        name: "Search Engine Optimization",
        icon: "bx bx-search-alt",
        tabId: "3"
    },
    {
        value: "2",
        label: "SMM",
        name: "Social Media Marketing",
        icon: "bx bxs-megaphone",
        tabId: "7"
    },
    {
        value: "3",
        label: "PPC",
        name: "Pay Per Click",
        icon: "mdi mdi-cursor-default-click",
        tabId: "8"
    },
    {
        value: "4",
        label: "EM",
        name: "Email Marketing",
        icon: "mdi mdi-email-check",
        tabId: "9"
    }
]


export const ResourceType = [
    { label: "Fixed Rate", value: "1" },
    { label: "Project Hours", value: "2" },
    { label: "Dedicated Resource", value: "3" },
]
export const projectStatus = [
    { label: "Not Started", value: "0" },
    { label: "In Progress", value: "1" },
    { label: "On Hold", value: "2" },
    { label: "Cancelled", value: "3" },
    { label: "Finished", value: "4" },
]
export const OverviewOption = [
    {
        id: 1,
        label: "Today's",
        text: "Today's Task",
    },
    {
        id: 2,
        label: "Yesterday's",
        text: "Yesterday's Task",
    },
    {
        id: 3,
        label: "Pending ",
        text: "Pending Task",
    },
    {
        id: 4,
        label: "Audit",
        text: "Audit Task",
    },
]

export const TypeDmOptions = [
    { label: "On Page", value: "on-page", service: "1" },
    { label: "Off Page", value: "off-page", service: "1" },
    { label: "Audit", value: "audit", service: "1" },
    { label: "Report", value: "report", service: "1" },
    { label: "Checking", value: "checking", service: "1" },
    { label: "Checking Report", value: "checking-report", service: "1" },
    { label: "Research", value: "research", service: "2" },
    { label: "Planning", value: "planning", service: "2" },
    { label: "Scheduling", value: "scheduling", service: "2" },
    { label: "Monitoring", value: "monitoring", service: "2" },
    { label: "Report", value: "Report", service: "2" },
    { label: "Checking Report", value: "checking-report", service: "2" },
    { label: "Planning", value: "planning", service: "3" },
    { label: "Campaign Management", value: "campaign-management", service: "3" },
    { label: "Monitoring", value: "monitoring", service: "3" },
    { label: "Report", value: "report", service: "3" },
    { label: "Checking Report", value: "checking-report", service: "3" },
    { label: "Research and Analysis", value: "Research and Analysis", service: "4" },
    { label: "Data Gathering", value: "data-gathering", service: "4" },
    { label: "Planning", value: "Planning", service: "4" },
    { label: "Designing & Scheduling", value: "designing-scheduling", service: "4" },
    { label: "Monitoring & Optimization", value: "monitoring-optimization", service: "4" },

]

export const FieldTypeOptions = [
    { label: "Input Field", value: "input", message: "In this field type, 3 field will be shown." },
    { label: "Text Area", value: "textarea", message: "In this field type, Text Area will be shown." },
    { label: "Multiple", value: "multiple", message: "In this field type, Multiple fields will be shown and you can add more." },
]

export const developmentProjectType = [
    { label: "Regular Project", value: "1" },
    { label: "Dedicated Resource", value: "2" },
    { label: "Maintenance Project", value: "3" },
]
export const developmentHours = [
    { label: "01.00", value: "1:00" },
    { label: "01.30", value: "1:00" },
    { label: "02.00", value: "2:00" },
    { label: "02.30", value: "2:00" },
    { label: "03.00", value: "3:00" },
    { label: "03.30", value: "3:00" },
    { label: "04.00", value: "4:00" },
    { label: "04.30", value: "4:00" },
    { label: "05.00", value: "5:00" },
    { label: "05.30", value: "5:00" },
    { label: "06.00", value: "6:00" },
    { label: "06.30", value: "6:00" },
    { label: "07.00", value: "7:00" },
    { label: "07.30", value: "7:00" },
    { label: "08.00", value: "8:00" },
    { label: "08.30", value: "8:00" },
    { label: "09.00", value: "9:00" },
    { label: "09.30", value: "9:00" },
    { label: "10.00", value: "10:00" },
    { label: "10.30", value: "10:00" },
    { label: "11.00", value: "11:00" },
    { label: "11.30", value: "11:00" },
    { label: "12.00", value: "12:00" },
]
export const milestoneType = [
    { label: "Milestone - Design", value: "1", type: "Design", text: "DS" },
    { label: "Milestone - Development", value: "2", type: "Development", text: "DE" },
    { label: "Milestone - Testing", value: "3", type: "Testing", text: "T" },
    { label: "Milestone - Deployment", value: "4", type: "Deployment", text: "DP" },
    { label: "Milestone - UAT", value: "5", type: "UAT", text: "U" },
]
export const allocationType = [
    {
        value: '1',
        label: 'Designing',
    },
    {
        value: '2',
        label: 'Development',
    },
    {
        value: '3',
        label: 'Testing',
    },
    {
        value: '4',
        label: 'Deployment',
    },
]
export const taskStatus = [
    {
        value: "1",
        label: "In Progress"
    },
    {
        value: "2",
        label: "Move to Test"
    },
    {
        value: "3",
        label: "Awaiting Feedback"
    },
    {
        value: "4",
        label: "Complete"
    },
]
export const developmentTaskStatus = [
    { label: "Pending", value: "0" },
    { label: "In Progress", value: "1" },
    { label: "Dev complete", value: "2" },
    { label: "Understanding", value: "3" },
    { label: "Awaiting Clients' Feedback", value: "5" },
]

export const resaonOtption = [
    {
        value: '1',
        label: "Missed Start – Apologies! I forgot to start the timer."
    },
    {
        value: '2',
        label: "Missed Stop – My mistake! I forgot to turn off the timer."
    },
    {
        value: '3',
        label: "Power Outage – Timer updates on hold until the lights return."
    },
    {
        value: '4',
        label: "Connectivity Issue – Network hiccup, timer update delayed."
    },
    {
        value: '5',
        label: "System Glitch – Technical issues preventing timer update."
    },
    {
        value: '6',
        label: "Project Adjustment"
    },
]

export const ticketStatus = [
    { label: "Not Answered", value: "0" },
    { label: "Open", value: "1" },
    { label: "In Progress", value: "2" },
    { label: "Answered", value: "3" },
    { label: "on Hold", value: "4" },
    { label: "Closed", value: "5" },
    { label: "Queries", value: "6" },
]

export const qaTaskList = [
    {
        value: "Create Test cases for Project",
        label: "Create Test cases for Project",
        type: 1
    },
    {
        value: "Test the project according to the test cases",
        label: "Test the project according to the test cases",
        type: 2
    },
]
export const testCaseStatus = [
    {
        value: "pass",
        label: "Pass"
    },
    {
        value: "fail",
        label: "Fail"
    }
]
export const issueType = [
    {
        value: "0",
        label: "Bug"
    },
    {
        value: "1",
        label: "Suggestion"
    },
]
export const preConditionSupport = [
    {
        value: "Network connection should be available",
        label: "Network connection should be available",
    },
    {
        value: "The website should be hosted on a staging server",
        label: "The website should be hosted on a staging server",
    },
    {
        value: "The website should be fully loaded without errors",
        label: "The website should be fully loaded without errors",
    },
]
export const locationOptions = [
    { label: "India", value: "1" },
    { label: "UK", value: "2" },
]


export const DashboardElement = [
    {
        label: "Profile",
        value: 1
    },
    {
        label: "Favorite Leads",
        value: 2
    },
    {
        label: "Lead Potentials",
        value: 8
    },
    {
        label: "Absentees",
        value: 3
    },
    {
        label: "Document Approval",
        value: 9
    },
    {
        label: "Swipe & Issues",
        value: 6
    },
    {
        label: "My Activity",
        value: 10
    },
    {
        label: "Teams Activity",
        value: 5
    },
    {
        label: "Project Overview",
        value: 7
    },
    {
        label: "Leaves Approval",
        value: 11
    },
    {
        label: "Work Duration",
        value: 12
    },
    {
        label: "Git Pushed Pending",
        value: 13
    },
    {
        label: "Clear Logins",
        value: 14
    },
    {
        label: "Flaws & Gaps",
        value: 15
    },
    {
        label: "Notifications",
        value: 16
    },
    {
        label: "Flaws & Gap List",
        value: 17
    },
    {
        label: "Overdraft Report",
        value: 18
    },
    {
        label: "Timer Approval",
        value: 19
    },
    {
        label: "Project Coins",
        value: 20
    },
    {
        label: "QA Task Allotment",
        value: 21
    },
    {
        label: "Queries Tickets",
        value: 22
    },
    {
        label: "Earth Zai Tickets",
        value: 23
    },
    {
        label: "Estimated Salary",
        value: 24
    },
]