import React, { useMemo, useEffect, useState } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Modal, Card, CardBody } from "reactstrap"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import NewPagination from "components/Common/Newpagination"
import { fetchData } from "utils/common-fetchData"
import { initailCompleteClomuns } from "utils/columns/Developement/planningColumns"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"


const CompleteProcess = ({ projectDetails }) => {
  const [milestoneSidebar, setMilestoneSidebar] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem('auth_token')
  const userId = localStorage.getItem('auth_id')
  const [datas, setData] = useState([])
  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchTask = async () => {
      const res = await fetchData(toggleLoading, `allUnplannedTasks/${projectDetails?.id}`, token);
      if (res) {
        setData(res)
      }
    }
    fetchTask();
  }, [])

  // table columns
  const columns = useMemo(() => initailCompleteClomuns(), [])
  const data = useMemo(() => datas, [datas])

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  return (
    <>
      {loading && (<Loader />)}
      <Card>
        <CardBody>
          <div className="moveUp">
            <Row>
              <Col className="col-3">
                <select
                  className="border-radius me-4 form-select"
                  style={{ width: "100px" }}
                  value={pageSize}
                  onChange={e => setPageSize(e.target.value)}
                >
                  {[10, 25, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
              <Col className="d-flex justify-content-end align-items-start col-9 pe-0">
                <div>
                  <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                          Search this table
                        </span>
                        <input
                          onChange={e => setGlobalFilter(e.target.value)}
                          id="search-bar-0"
                          type="text"
                          className="border-radius form-control rounded h-100"
                          placeholder={`Search...`}
                          value={globalFilter || ""}
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="position-relative">
              <Table className="table table-bordered mt-3" {...getTableProps()}>
                <thead
                  className={`${(fixedContent && page.length >= 10) ? "fixed_complete_process_headers"
                    : "fixed-table-header"
                    } `}
                >
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className
                            } customShortIcon custom-text`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {column.render("Header")}
                          {column.isShortIcon && (
                            <span
                              onClick={() =>
                                column.getSortByToggleProps().onClick()
                              }
                              className="ms-2 cursor-pointer customApprovalIcon"
                            >
                              <i
                                title="Ascending"
                                className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                              <i
                                title="Descending"
                                className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                                  }`}
                              ></i>
                            </span>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row-hover" {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${cell.column.className
                                }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  {page.length === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPag={canPreviousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
              />
            </Row>
          </div>
        </CardBody>
      </Card>

      {/* Add Milestone */}
      <div
        className={`notes-sidebar miles-sidebar ${milestoneSidebar ? "show-notes-sidebar" : ""
          }`}
      >
        <Row className="align-items-center justify-content-between">
          <div className="col-12 d-flex justify-content-between">
            <h5>Add Milestone</h5>
          </div>
          <div className="col-12 for-task">
            <label htmlFor="">
              Description <span className="text-danger">*</span>
            </label>
            <CKEditor
              editor={ClassicEditor}
              data="<p></p>"
              onReady={editor => { }}
              onChange={(event, editor) => {
                const data = editor.getData()
              }}
            />
          </div>
          <div className="col-md-4 mt-3 notes">
            <label htmlFor="">
              Milestone Hours <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Milestone Hours"
              className="form-control border-radius"
            />
          </div>

          <div className="text-end">
            <button className="btn btn-purple border-radius me-2">
              <i className="fas fa-save me-2"></i>Save
            </button>
            <a
              onClick={() => setMilestoneSidebar(false)}
              className="btn btn-secondary text-uppercase border-radius"
            >
              <i className="dripicons-cross me-1 icon-center"></i>Close
            </a>
          </div>
        </Row>
      </div>
    </>
  )
}

export default CompleteProcess
