import React, { useEffect, useState } from "react"
import { Container, Table, Row, Col, Card, CardBody, Form, Label, Modal } from "reactstrap"
import classNames from "classnames"
import AddMilestoneScreen from "./AddMilestoneScreen"
import { useNavigate, Link } from "react-router-dom"
import AddTaskIndex from "./AddTaskIndex"
import CompleteProcess from "./CompleteProcess"
import "../../../assets/css/Work/developmentSection.css"
import uploadFileImg from "../../../assets/images/uploadFileImg.jpg"
import ProjectOverview from "../Project Plan/Planning Steps/ProjectOverview"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import configure from "configure"


const StepNavigationStep = ({ step, currentStep, onStepChange }) => {
  const classes = classNames({
    active: step.id === currentStep,
    complete: currentStep > step.id,
  });

  return (
    <li className={classes} onClick={() => {
      if (currentStep >= step.id) {
        onStepChange(step.id)
      }
    }}>
      <div className="step">
        <i className={step.icon_class}></i>
      </div>
      <div className="caption hidden-xs hidden-sm">
        <span className="fs-5">{step.title}</span>
      </div>
    </li>
  );
};

const StepNavigation = ({ steps, currentStep, onStepChange }) => {
  return (
    <ol className="step-indicator ">
      {steps.map(step => (
        <StepNavigationStep
          key={step.id}
          step={step}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
      ))}
    </ol>
  );
};



const TaskScreen = ({ projectDetails, toggleLoading, onUpdate }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [isFreez, setIsFreez] = useState(false);
  const [isImportFile, setisImportFile] = useState(false);


  const steps = [
    { id: 1, title: "Project Overview", icon_class: "bi bi-1-circle-fill" },
    { id: 2, title: "Add Milestone", icon_class: "bi bi-2-circle-fill" },
    { id: 3, title: "Add Task", icon_class: "bi bi-3-circle-fill" },
    { id: 4, title: "Complete", icon_class: "bi bi-4-circle-fill" },
  ]

  const stepChanged = step => {
    setCurrentStep(step)
  }

  const navigate = useNavigate()
  const token = secureLocalStorage.getItem('auth_token')
  const onChangeFreeze = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `you want to ${isFreez ? "Freeze" : "UnFreeze"} project`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async result => {
      if (result.isConfirmed) {
        const formEntrie = {
          project_id: projectDetails?.id,
          is_freeze: isFreez
        }
        const res = await FetchPostData(toggleLoading, 'updateFreezeStatus', token, formEntrie);
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            text: `Project ${isFreez ? "Freeze" : "UnFreeze"} successfully`
          }).then(() => {
            setIsFreez(!isFreez)
          })
        }
      }
    }
    )

  }

  const onCommonChange = async (type, field) => {
    const formEntries = {
      project_id: projectDetails?.id,
      status: field,
      type: type
    }
    const response = await FetchPostData(
      toggleLoading,
      "updateFreezeStatus",
      secureLocalStorage?.getItem("auth_token"),
      formEntries
    )
    return response?.data?.success
  }

  const onSendforRole = async (type) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You Want to Send for ${type == 1 ? 'Approval' : 'Role Out'}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, send it!",
      cancelButtonText: "No, cancel!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await onCommonChange(type == 1 ? 6 : 4);
        if (res) {
          Swal.fire({
            icon: 'success',
            text: `Project sent for ${type == 1 ? 'Approval' : 'Role Out'} successfully`
          }).then(() => {
            onUpdate(true)
          })
        }
      }
    })
  }

  const onLetsRoll = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Let's Roll Project",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, send it!",
      cancelButtonText: "No, cancel!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await onCommonChange(3);
        if (res) {
          Swal.fire({
            icon: 'success',
            text: 'Project Rolled Out Successfully!',
          }).then(() => {
            onUpdate(true)
            navigate(`${configure.appUrl}work/projects`)
          })
        }
      }
    })
  }

  const onChangingStep = (e) => {
    if (currentStep == 1) {
      if (projectDetails?.scope_hrs == null || projectDetails?.sprint == null || projectDetails?.sprint_type == null) {
        Swal.fire({
          icon: "warning",
          text: "Please Fill Project Scope Hours or Sprint type.",
        })
      } else {
        setCurrentStep(2)
        onCommonChange(2, 2)
      }
    } else if (currentStep == 2) {
      onCommonChange(2, 3)  
      setCurrentStep(3)
    } else if (currentStep == 3) {
      onCommonChange(2, 4)
      setCurrentStep(4)
    }
  }

  useEffect(() =>{
    if(projectDetails?.step_no == 0){
      setCurrentStep(1)
    }else{
      setCurrentStep(projectDetails?.step_no)
    }
  } ,[projectDetails])

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <form action="">
                    <div className='d-flex justify-content-between align-items-center wizard'>
                      <h5 className='mb-0 fw-bold col-6 d-flex justify-content-start align-items-center gap-2 f-15'>
                        <span className='text-purple'>Total Hours: {projectDetails?.total_hrs} Hrs</span>
                        <span className=''>|</span>
                        <span className='text-success'>Used Hours: {projectDetails?.usedHrs} Hrs</span>
                        <span className=''>|</span>
                        <span className='text-primary'>Balanced Hours: {projectDetails?.balanceHrs} Hrs</span>
                      </h5>

                      <div className='col-6 d-flex gap-2 justify-content-end align-items-center'>
                        {localStorage?.getItem('auth_id') == 32 || localStorage?.getItem('auth_id') == 1 || localStorage?.getItem('auth_id') == 4 ? (
                          <>
                            <div className="d-flex justify-content-center align-items-center  ms-4">
                              {

                                !isFreez ? <h6 className="text-danger f-15 mt-2">Freeze</h6> : <h6 className="text-success f-15 mt-2">UnFreeze</h6>
                              }
                              <div>
                                <div className="form-switch form-switch-md ps-5">
                                  <input type="checkbox" className="form-check-input" id="customSwitchsizemd"
                                    onChange={(e) => {
                                      setIsFreez(e.target.checked)
                                    }}
                                    checked={isFreez}
                                  />
                                </div>
                              </div>
                            </div>
                            {projectDetails?.send_for_approve == 1 && projectDetails?.roll_out != 1 && (localStorage?.getItem('auth_id') == 1 || localStorage?.getItem('auth_id') == 32) ? (
                              <button
                                type="button"
                                className="btn btn-save border-radius ms-3"
                                onClick={() => onLetsRoll()}
                              >
                                Let's Roll
                              </button>
                            ) : null}
                          </>
                        ) : null}
                        {currentStep != 4 ? (
                          <a className=" btn text-white text-uppercase me-0 btn-save btn-label border-radius" onClick={(e) => onChangingStep(e)}>
                            <i className="fas fa-save  label-icon"></i>
                            Save & Next
                          </a>
                        ) : null}
                        {(localStorage?.getItem('auth_id') == 1 || localStorage?.getItem('auth_id') == 4 || localStorage?.getItem('auth_id') == 46) && projectDetails?.send_for_approve == 0 && projectDetails?.kam_approve == 1 ? (
                          <button
                            type="button"
                            className="btn btn-success border-radius "
                            onClick={() => onSendforRole(2)}
                          >
                            Send For Let's Roll
                          </button>
                        ) : null}
                        {(secureLocalStorage?.getItem('auth_role') == 9 || secureLocalStorage?.getItem('auth_role') == 10 || secureLocalStorage?.getItem('auth_role') == 22 || localStorage?.getItem('auth_id') == 1) && projectDetails?.kam_approve != 1 ? (
                          <button
                            type="button"
                            className="btn btn-success border-radius "
                            onClick={() => onSendforRole(1)}
                          >
                            Send For Approval
                          </button>
                        ) : null}
                      </div>

                    </div>
                    <div className="container-fluid">
                      <StepNavigation steps={steps} currentStep={currentStep} onStepChange={stepChanged} />
                    </div>
                  </form>
                </CardBody>
              </Card>

              {currentStep === 1 ? (
                <ProjectOverview projectDetails={projectDetails} onUpdate={onUpdate} />
              ) : currentStep === 2 ? (
                <AddMilestoneScreen projectDetails={projectDetails} />
              ) : currentStep === 3 ? (
                <AddTaskIndex projectDetails={projectDetails} />
              ) : currentStep === 4 ? (
                <CompleteProcess projectDetails={projectDetails} />
              ) : null}

            </Col>
          </Row>
        </Container>
      </div>

      {/* Import File popup */}
      <Modal isOpen={isImportFile} size="md" className="" centered>
        <div className="modal-header  d-flex justify-space-between">

          <h5 className="col-6 modal-title mt-0">Upload File</h5>
          <button
            type="button"
            onClick={() => {
              setisImportFile(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>

        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <img src={uploadFileImg} className="img-fluid" style={{ width: "90%" }} />
            </div>
            <div className="col-12 d-flex justify-contnet-center align-items-center flex-column mb-4 gap-3">

              <div className="col-12 ">
                <input type="file" className="form-control" />
              </div>
              <div className="col-12 text-start">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-upload me-2 label-icon"></i>
                  Upload File
                </button>
                <a className="btn ms-md-2 btn-primary btn-label"
                >
                  <i className='bi bi-download me-2 icon-center label-icon'></i>Download Sample
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>


    </React.Fragment>
  )
}

export default TaskScreen