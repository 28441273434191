import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import editEventImg from "../../../../assets/images/Digital Marketing/editEventImg.jpg"
import "@fullcalendar/bootstrap/main.css";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import Loader from "pages/Separate/Loader";
import secureLocalStorage from "react-secure-storage";
import { calculateTaskFit, calculateTwoTimes, getWeekStartAndEnd } from "utils/common-helpers";
import moment from "moment";
import Swal from "sweetalert2";

const ProjectSprint = ({ resource, key }) => {
    document.title = "Future Plan | Zithas Crm";
    const [event, setEvent] = useState({});
    const [isLoading, setLoading] = useState(false)
    const [hrs, setHrs] = useState('');
    document.addEventListener("keydown", e => {
        if (e.key == "Escape") {
            if (modal) {
                setModal(false)
            }
        }
    })
    const [error, setError] = useState('');
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9])$/;
    const [modal, setModal] = useState(false);
    const [selectedDay, setSelectedDay] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [eventsList, setEvents] = useState([]);
    const token = secureLocalStorage.getItem('auth_token')
    const [sprintStatus, setSprintStatus] = useState(false)
    const [mainAllActivity, setMainAllActivity] = useState([])
    const [customDateHtml, setCustomDateHtml] = useState([])
    const toggleLoading = (loading) => {
        setLoading(loading);
    }
    useEffect(() => {
        new Draggable(document.getElementById("external-events"), {
            itemSelector: ".external-event",
        });
    }, []);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setEvent(null);
        } else {
            setModal(true);
        }
    };

    const handleDateClick = arg => {
        const clickedElement = arg.dayEl;
        if (clickedElement.querySelector('.resource-hours')) {
            return;
        }
        const date = arg["date"];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(
            year,
            month,
            day,
            currentHour,
            currentMin,
            currentSec
        );
        const modifiedData = { ...arg, date: modifiedDate };

        setSelectedDay(modifiedData);
        toggle();
    };

    const handleEventClick = arg => {
        const event = arg.event;
        setEvent({
            id: event.id,
            title: event.title,
            title_category: event.title_category,
            start: event.start,
            className: event.classNames,
            category: event.classNames[0],
            event_category: event.classNames[0],
        });
        setIsEdit(true);
        toggle();
    };

    const onDrag = event => {
        event.preventDefault();
    };
    const [modalData, setModalData] = useState({})
    const [eventData, setEventData] = useState({});
    const [addModal, setAddModal] = useState(false)
    const [taskName, setTaskName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [keyChange, setKeyChange] = useState(false)


    const onDrop = async (event) => {
        const { date, draggedEl } = event;
        const taskId = draggedEl.id;
        const estTime = draggedEl.getAttribute('data-hrs');
        const projectId = draggedEl.getAttribute('data-projectId');
        const milestone = draggedEl.getAttribute('data-milestone');
        if (!draggedEl || !draggedEl.classList.contains('external-event')) {
            return;
        }
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const currentTime = new Date();
        const modifiedDate = new Date(
            year,
            month,
            day,
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds()
        );
        const mainDate = moment(date).format('YYYY-MM-DD')
        const time = customDateHtml?.find((obj) => obj?.date == mainDate)?.time
        const newEvent = {
            title: draggedEl.innerText || draggedEl.getAttribute('title'),
            date: modifiedDate,
            className: draggedEl.className,
            task_id: taskId,
            est_time: estTime,
            occupied: time,
            milestone: milestone
        };
        const formEntries = {
            staff_id: localStorage.getItem('auth_id'),
            project_id: projectId,
            task_id: taskId,
            date: mainDate,
            user_id: localStorage.getItem('auth_id'),
            className: draggedEl.className,
            milestone: milestone
        }
        setTaskName(draggedEl.innerText || draggedEl.getAttribute('title'))
        setHrs(estTime)
        // setHrs(calculateTaskFit(estTime, time))
        setModalData(formEntries)
        setEventData(newEvent)
        setAddModal(true)
    };

    const getTaskData = async (info) => {
        setStartDate(moment(info.view.currentStart)?.format('YYYY-MM-DD'))
        setEndDate(moment(info.view.currentEnd)?.format('YYYY-MM-DD'))
        const formEntries = {
            user_id: localStorage.getItem('auth_id'),
            start_date: moment(info.view.currentStart)?.format('YYYY-MM-DD'),
            end_date: moment(info.view.currentEnd)?.format('YYYY-MM-DD'),
            sprint_start_date: getWeekStartAndEnd()?.startOfWeek
        }
        const res = await FetchPostData(toggleLoading, 'sprintData', token, formEntries);
        if (res?.data?.success) {
            const details = res?.data?.data
            setSprintStatus(details?.sprint_status)
            setCustomDateHtml(details?.availability?.original.data)
            setMainAllActivity(details?.pending_task)
            setEvents(details?.plannedtasks)
        }
    }

    const handleAddTask = async (e, type, data, date, id) => {
        e.preventDefault();
        if (timeRegex.test(hrs)) {
            const formEntries = { ...modalData, hrs, process_type: 1, start_date: startDate, end_date: endDate, sprint_start_date: getWeekStartAndEnd()?.startOfWeek, type: 1 }
            const editEntries = { type: type, hrs: data?.estimate_dev_days, staff_id: data?.user_id, date, task_id: id }
            const res = await FetchPostData(toggleLoading, "assignTaskToUser", token, type == 1 ? editEntries : formEntries)
            if (res?.data?.success) {
                const details = res?.data?.data
                setCustomDateHtml(details?.availability?.original.data)
                setMainAllActivity(details?.pending_task)
                setEvents(details?.plannedtasks)
                setAddModal(false)
            }
        } else {
            Swal.fire({
                icon: "warning",
                text: "Invalid Time Format. Please use HH:MM format"
            })
        }
    }

    const onCompleteSprint = async (e) => {
        e.preventDefault()
        if (sprintStatus) {
          Swal.fire({
            icon: "success",
            title: "🎉 Hurrah! You're Up to Date with Your Sprint!"
          })
        } else {
          Swal.fire({
            icon: "warning",
            title: "Are you sure ?",
            text: "You want to Complete your Sprint !",
            showCancelButton: true,
            confirmButtonText: "Yes, complete it!"
          }).then(async (result) => {
            if (result.isConfirmed) {
              const formEntries = {
                user_id: localStorage.getItem('auth_id'),
                start_date: getWeekStartAndEnd()?.startOfWeek,
                end_date: getWeekStartAndEnd()?.endOfWeek
              }
              const res = await FetchPostData(toggleLoading, "completeProjectsSprint", token, formEntries)
              if (res?.data?.success) {
                Swal.fire({
                  icon: "success",
                  title: "Sprint completed successfully!"
                }).then(() => {
                  navigate(`${configure.appUrl}dashboard`)
                })
              }
            }
          })
        }
      }
    

    return (
        <div action="">
            {isLoading && (<Loader />)}
            <div className="d-flex justify-content-between align-items-center wizard">
                <div className="col-12 d-flex gap-2 justify-space-between align-items-center">
                    <div className="col-6 d-flex justify-content-start gap-5 align-items-center">
                        <div className="d-flex justify-content-start align-items-center">
                            <h4>Future Plan</h4>
                        </div>
                        <div className="d-flex justify-content-start align-items-center gap-4">
                            <div className="d-flex justify-content-center align-items-center gap-1">
                                <span
                                    className="bg-primary border-black"
                                    style={{
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                    }}
                                ></span>
                                - <span>Fixed</span>
                            </div>

                            <div className="d-flex justify-content-center align-items-center gap-1">
                                <span
                                    className="bg-warning border-black"
                                    style={{
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                    }}
                                ></span>
                                - <span>Dedicated</span>
                            </div>

                            <div className="d-flex justify-content-center align-items-center gap-1">
                                <span
                                    className="bg-success border-black"
                                    style={{
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                    }}
                                ></span>
                                - <span>Maintenance</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 pe-2 d-flex justify-content-end align-items-center">
                        <button className="s btn text-white me-0 btn-success btn-label ms-2"
                            disabled={mainAllActivity?.length != 0}
                            onClick={(e) => onCompleteSprint(e)}
                        >

                            <i className="bx bx-run me-2 icon-size label-icon"></i>
                            Complete Sprint
                        </button>
                    </div>
                </div>
            </div>
            <div className=" mt-4">
                <div className="">
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col lg={4}>
                                    <Card>
                                        <CardBody className="pt-0" >
                                            <div id="external-events" className="mt-0" style={{ height: "860px", overflowY: "scroll", scrollbarWidth: "thin", scrollbarColor: "#8796ce #f0f0f0" }}>
                                                <br />
                                                <p className="text-muted ps-2">
                                                    Drag and drop your activity or click in the calendar
                                                </p>
                                                {mainAllActivity &&
                                                    mainAllActivity.map((category, i) => (
                                                        <div className="d-flex justify-space-between align-items-center" key={i}>
                                                            <div
                                                                className={`${category.type} external-event fc-event text-white w-100 d-flex justify-space-between align-items-center`}
                                                                key={"cat-" + i}
                                                                draggable
                                                                onDrag={event => onDrag(event, category)}
                                                                data-hrs={category?.estimate_dev_days}
                                                                id={category?.id}
                                                                data-milestone={category?.milestone}
                                                                data-projectId={category?.project_id}
                                                            >
                                                                <span>
                                                                    <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                                                                    {category.title}
                                                                </span>
                                                                <span className="badge bg-light text-dark ms-2">{category.estimate_dev_days}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={8}>
                                    <FullCalendar
                                        key={key}
                                        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                        initialView="dayGridWeek"
                                        slotDuration={"00:15:00"}
                                        handleWindowResize={true}
                                        themeSystem="bootstrap"
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: "title",
                                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                                        }}
                                        events={eventsList}
                                        eventContent={(arg) => {
                                            const { event } = arg;
                                            return (
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div
                                                        className="text-start "
                                                        style={{
                                                            wordWrap: "break-word",
                                                            wordBreak: "break-word",
                                                            whiteSpace: "normal",
                                                            maxWidth: "100%",
                                                        }}
                                                    >
                                                        {event.title} - <span className="badge bg-light text-dark ms-2">{event?.extendedProps?.estimate_dev_days}</span>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        dayCellContent={(cell) => {
                                            const { date } = cell;
                                            const time = customDateHtml?.find((obj) => obj?.date === moment(date).format('YYYY-MM-DD'))?.time || "00:00";
                                            return (
                                                <div className="fc-day-cell-content">
                                                    <div className="time-header">{time} hrs</div>
                                                </div>
                                            );
                                        }}
                                        editable={true}
                                        droppable={true}
                                        selectable={true}
                                        dateClick={handleDateClick}
                                        eventClick={handleEventClick}
                                        drop={onDrop}
                                        datesSet={getTaskData}
                                    />

                                    <Modal
                                        isOpen={addModal}
                                        size="lg"
                                        centered
                                    >
                                        <ModalHeader toggle={() => setAddModal(false)} tag="h5" className="py-3 px-4 border-bottom-0">
                                            Assign Task
                                        </ModalHeader>
                                        <ModalBody className="p-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    handleAddTask(e)
                                                }}
                                            >
                                                <Row>
                                                    <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                                        <div className="text-center">
                                                            <img
                                                                src={editEventImg}
                                                                alt="Addepe"
                                                                style={{ width: "90%" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-7 d-flex flex-column justify-content-center align-items-start pl-0">
                                                        <div className="d-flex gap-2 col-md-12 mb-5">
                                                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                <h5>Est Time</h5>
                                                                <h5>{eventData?.est_time} Hrs </h5>
                                                            </a>
                                                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                <h5>Engaged Time</h5>
                                                                <h5>{eventData?.occupied} Hrs</h5>
                                                            </a>
                                                            <a className="text-purple pt-2 px-2 rounded work-status cr-status text-center col-md-4">
                                                                <h5>Time Taken</h5>
                                                                <h5>
                                                                    {calculateTwoTimes(eventData?.occupied || "00:00", hrs || "00:00")}
                                                                    Hrs</h5>
                                                            </a>
                                                        </div>
                                                        <div className="mb-3 col-12">
                                                            <Label className="form-label">Activity Name</Label>
                                                            <Input
                                                                disabled
                                                                name="title"
                                                                type="text"
                                                                value={taskName?.replace(/\d+/g, "")}
                                                            />
                                                        </div>
                                                        <div className="row col-md-12 align-items-end">
                                                            <div className="col-4">
                                                                <Label className="form-label">Total Hours</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Total hours"
                                                                    value={eventData?.est_time}
                                                                    disabled

                                                                />
                                                            </div>
                                                            {/* <div className="col-4">
                                                            <Label className="form-label">Hours</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter hours"
                                                                disabled
                                                                value={hrs}
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    setHrs(e.target.value)
                                                                    if (timeRegex.test(inputValue)) {
                                                                        setError('');
                                                                    } else {
                                                                        setError('* Invalid time format. Please enter in HH:MM format.');
                                                                    }
                                                                }}
                                                            />
                                                        </div> */}
                                                            {error && <span className="text-danger mt-2">{error}</span>}
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row className="">
                                                    <Col className="col-12 text-end">
                                                        <button type="submit" className="btn btn-success" id="btn-save-event">
                                                            Save
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
};

export default ProjectSprint;
