import React, { useEffect, useState } from "react";
import { Container, Form, Input, Label } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Table, Row, Col, Card, CardBody } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import Select from "react-select";
import ReactSelect from "react-select";
import Draggable, { DraggableCore } from "react-draggable";
import { Resizable, ResizableBox } from "react-resizable";
import NewPagination from "components/Common/Newpagination";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import { projectTaskColumns } from "utils/columns/Developement/projectColunmn";
import Swal from "sweetalert2";
import { checkPermissionsByModule } from "utils/common-helpers";
import { useSelector } from "react-redux";
import {
  developmentHours,
  milestoneType,
  priorityOption,
} from "utils/common-options";

function TableContainer({ columns, data, allData }) {
  const [fixedContent, setFixedContent] = useState(false);
  const navigate = useNavigate();
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true);
    } else {
      setFixedContent(false);
    }
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;
  useEffect(() => setPageSize(50), []);

  return (
    <div>
      <Row className="mb-4 align-items-center">
        <Col md={9} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a className="text-green pt-2 px-2 rounded proposal-status">
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a className="text-red pt-2 px-2 rounded proposal-status">
                <h4>
                  {allData.filter((obj) => obj.status == "not-started")?.length}
                </h4>
                <h4>Not Started</h4>
              </a>
              <a className="text-yellow pt-2 px-2 rounded proposal-status">
                <h4>
                  {allData.filter((obj) => obj.status == "Inprocess")?.length}
                </h4>
                <h4>In Progress</h4>
              </a>
            </div>
          </div>
        </Col>
        <Col
          md={3}
          className="d-flex align-items-center justify-content-end pl-0"
        >
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className="s btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
          // className={`${
          //   fixedContent ? "fix-task-header" : ""
          // } fix-project-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              let rowColor;
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green";
                  break;
                case "Awaiting Feedback":
                  rowColor = "bg-red";
                  break;
                case "Complete":
                  rowColor = "bg-yellow";
                  break;
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  );
}

const Tasks = ({ verticalActiveTab, toggleLoading }) => {
  const [formRows, setFormRows] = useState([{}]);
  const userId = localStorage.getItem("auth_id");
  const token = secureLocalStorage.getItem("auth_token");
  const params = useParams();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const onAddFormRow = () => {
    setFormRows([...formRows, {}]);
  };
  const onChangeFormRow = (index, value) => {
    const updatedRows = [...formRows];
    updatedRows[index].value = value;
    const updatedValues = updatedRows
      .map((row) => row.value)
      .filter((value) => value !== "");
    setFormRows(updatedValues);
  };
  const onDeleteFormRow = (index) => {
    const newInputs = [...formRows];
    newInputs.splice(index, 1);
    setFormRows(newInputs);
  };
  useEffect(() => {
    if (verticalActiveTab == 3) {
      fetchTasks();
    }
  }, [verticalActiveTab]);

  const fetchTasks = async () => {
    const res = await fetchData(
      toggleLoading,
      `taskData/${userId}/${params?.id}`,
      token
    );
    if (res) {
      setData(res);
      setAllData(res);
    }
  };
  const [modal_Access, setAccessDenied] = useState(false);
  const [timerStatus, setTimerStatus] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [title, setTitle] = useState("");
  const [milestone, setMilestone] = useState([]);
  const [estTime, setEstTime] = useState([]);
  const [probability, setProbability] = useState([]);
  const [priority, setPriority] = useState([]);
  const [assign, setAssign] = useState([]);
  const [description, setDescription] = useState("");
  const [task_id, setTaskId] = useState("");
  const roles = useSelector((state) => state?.Roles?.apiData);
  const [staffOption, setStaffOption] = useState([]);
  const [milestoneOption, setMilestoneOption] = useState([]);
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") setEditTask(false), setTimerStatus(false);
  });
  const onEditTask = async (obj) => {
    const res = await fetchData(
      toggleLoading,
      `getMilestonesOfProject/${obj?.project_id}`,
      token
    );
    if (res?.success) {
      const mileOption = res?.data?.milestones?.original?.data;
      const staffOption = res?.data?.resources?.map((staff) => ({
        label: staff?.alias_name,
        value: staff?.user_id,
        type: staff?.type,
      }));
      const milestones = mileOption?.map((mile) => ({
        value: mile.id,
        label: `Milestone - ${mile?.milestone_no} - ${
          milestoneType?.find((miles) => miles?.value == mile.milestone_type)
            ?.type
        }`,
        type: mile.milestone_type,
      }));
      setStaffOption(staffOption);
      setMilestoneOption(milestones);
      setEditTask(true);
      setTaskId(obj?.id);
      setTitle(obj?.title);
      setMilestone(milestones?.find((mile) => mile?.value == obj?.milestone));
      setProbability(obj?.probability);
      setEstTime(
        developmentHours?.find((e) => e.value == obj?.estimation_days)
      );
      setPriority(priorityOption?.find((pri) => pri?.value == obj?.priority));
      setDescription(obj.description);
      setAssign(staffOption?.filter((e) => e.value == obj?.assign));
      setFormRows(obj?.subtitle?.split("@#"));
    }
  };
  const onDeleteTask = (id) => {
    const permission = checkPermissionsByModule(roles, 25, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Task!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formDatas = {
            task_id: id,
            project_id: params?.id,
            user_id: localStorage?.getItem("auth_id"),
          };
          const response = await FetchPostData(
            toggleLoading,
            "deleteTask",
            token,
            formDatas
          );
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Task deleted successfully",
            });
            setData(response?.data?.data);
            setAllData(response?.data?.data);
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };
  const onChangeStatus = async (id, status) => {
    const permission = checkPermissionsByModule(roles, 25, 4);
    if (permission) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `${"You want to change status."}`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formEntries = {
            user_id: localStorage.getItem("auth_id"),
            task_id: id,
            project_id: params?.id,
            status: status,
          };
          const response = await FetchPostData(
            toggleLoading,
            "changeTaskStatus",
            "",
            formEntries
          );
          if (response.data?.success) {
            Swal.fire({
              icon: "success",
              title: "Task Status Updated Successfully",
            }).then((data) => {
              setData(response?.data?.data);
              setAllData(response?.data?.data);
            });
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };
  const columns = useMemo(
    () => projectTaskColumns(onEditTask, onDeleteTask, onChangeStatus),
    []
  );
  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1200,
    }),
  };

  const handleEditTask = async (e) => {
    e.preventDefault();
    if (description?.length >= 50) {
      if (priority?.length != 0 && est_hour?.length != 0) {
        e.preventDefault();
        const formEntries = {
          task_id: task_id,
          project_id: params?.id,
          user_id: userId,
          title: title,
          milestone: milestone?.value || "",
          estimation_hrs: estTime?.value || "",
          probability: probability,
          priority: priority?.value || "",
          assign: assign?.value || "",
          description: description,
          subtitle: formRows?.map((obj) => obj)?.join("@#"),
        };
        const res = await FetchPostData(
          toggleLoading,
          "editTask",
          token,
          formEntries
        );
        if (res?.data?.success) {
          Swal.fire({
            icon: "success",
            title: "Task Edited Successfully",
          }).then(() => {
            setData(res?.data?.data);
            setAllData(res?.data?.data);
            setEditTask(false);
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Please Fill all the Fields",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "🙇‍♂️ Oops ! Kindly provide a detailed description. 📋",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setEditTask={setEditTask}
                      allData={allData}
                      setData={setData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`note-sticky-form ${editTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Edit Task</h5>
              <button
                type="button"
                onClick={() => {
                  setEditTask(false);
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form
                className="row d-flex justify-content-center align-items-center"
                onSubmit={handleEditTask}
              >
                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setEditTask(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Milestone <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Milestone"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={milestoneOption}
                        value={milestone}
                        onChange={(e) => setMilestone(e)}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={developmentHours}
                        value={estTime}
                        onChange={(e) => setEstTime(e)}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                        value={probability}
                        onChange={(e) => setProbability(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={priorityOption}
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-md-12 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Assigned <span className="text-danger">*</span>
                      </Label>
                      <ReactSelect
                        placeholder="Select Staff"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={staffOption}
                        value={assign}
                        onChange={(e) => setAssign(e)}
                      />
                    </div>
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        data={description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                      />
                    </div>
                  </div>
                  <Row className="bg-white px-2 py-2 shadow mb-3">
                    <div className="d-flex justify-content-end align-items-end px-0">
                      <div className="text-end mb-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            onAddFormRow();
                          }}
                          className="btn btn-purple  "
                        >
                          <i className="fas fa-plus me-2"></i>
                          Add Subtask
                        </button>
                      </div>
                    </div>
                    {formRows?.map((person, key) => {
                      return (
                        <div key={key} className="">
                          <div className="d-flex">
                            <div className="contact-form d-flex col-md-11">
                              <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                <label htmlFor="">Subtask Name</label>
                                <input
                                  type="text"
                                  name="task_name"
                                  placeholder="Enter Subtask Name"
                                  className="form-control "
                                  value={person}
                                  onChange={(e) =>
                                    onChangeFormRow(key, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                              <div className="text-end">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onDeleteFormRow(key);
                                  }}
                                  className="btn btn-red"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {key !== formRows.length - 1 ? (
                            <div className="border-bottom my-3"></div>
                          ) : null}
                        </div>
                      );
                    })}
                  </Row>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default Tasks;
