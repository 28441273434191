import React from "react"
import moment from "moment"
import configure from "configure"
import { Link } from "react-router-dom"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { developmentTaskStatus, projectStatus } from "utils/common-options"

export const fixedProjectColumns = (onChangeStatusProject, onDelete, handleAdd, onViewPorject) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: "Due Date",
        title: "Due Date",
        accessor: "due_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "due_date_box text-center",
        Cell: cellProps => {
            return (
                <>
                    {cellProps.row.original.enddate
                        ? moment(cellProps.row.original.enddate).format("DD-MM-YYYY")
                        : "-"}
                </>
            )
        },
    },
    {
        Header: "P.W.D",
        title: "Pending Working Days",
        accessor: "pwd",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pwd_box text-center",
    },
    {
        Header: "Days",
        title: "Last Updated Days",
        accessor: "days",
        isInfoIcon: false,
        isShortIcon: true,
        className: "projects_days_box text-center",
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div className="" style={{ width: "100%" }}>
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row.original.status}
                        onChange={(e) =>
                            onChangeStatusProject(cellProps?.row?.original?.id, 2, e?.target?.value)}
                    >
                        {projectStatus?.map((list, index) => {
                            return (
                                <option key={index} value={list.value} className="">
                                    {list.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        },
    },
    {
        Header: "D.I.D",
        title: "Days Remaining For Project Delivery",
        accessor: "did_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "did_box text-center",
        Cell: cellProps => {
            const startDate = moment()
            const deliveryDateBefore = moment(
                cellProps.row.original.deliverydatebefore
            )
            const differenceInDays = deliveryDateBefore?.diff(startDate, "days")
            return <>{differenceInDays ? differenceInDays : ""}</>
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
            const row = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-center">
                    {cellProps.row.original?.roll_out == 1 ? (
                        <Link
                            to={`${configure.appUrl}work/projects-plan/${row?.id}`}
                            className="btn btn-sm  bg-light-purple text-purple border-0 pt-2 pb-2 pe-2 ps-2"
                            title="View Project"
                            style={{ color: "#752a97" }}
                        >
                            Resource Plan
                        </Link>
                    ) : (
                        <>
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Project"
                                onClick={() => onViewPorject(row)}
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                            <Link to={`${configure.appUrl}work/edit-project/${row?.id}`}
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="Edit Project"
                            >
                                <i className="fas fa-pen "></i>
                            </Link>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        title="Add Task"
                                        className="dropdown-item"
                                        onClick={() => handleAdd(row?.id)}
                                    >
                                        <i className="bi bi-list-check pr-10"></i> Add Task
                                    </a>
                                    <a
                                        title={row?.pause_status == 0 ? "Pause Task" : "Play Project"}
                                        className="dropdown-item"
                                        onClick={() => onChangeStatusProject(row?.id, 1, row?.pause_status == 0 ? 1 : 0)}
                                    >
                                        <i className={row?.pause_status == 0 ? "bi bi-pause-circle-fill pr-10" : "bi bi-play-circle-fill pr-10"}></i>
                                        {row?.pause_status == 0 ? "Pause Project" : "Play Project"}
                                    </a>
                                    <a
                                        title="Delete Task"
                                        className="dropdown-item"
                                        onClick={() => onDelete(row?.id)}
                                    >
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Project
                                    </a>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    )}
                </div>
            )
        },
    },
]

export const dedicatedProjectColumns = (onChangeStatusProject, onDelete, handleAdd, onViewPorject) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
    },
    {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
            return (
                <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
            )
        },
    },
    {
        Header: 'Due Date',
        accessor: "due_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: `projects_days_box text-center`,
        Cell: cellProps => {
            return (
                <>
                    {cellProps.row.original.enddate
                        ? moment(cellProps.row.original.enddate).format("DD-MM-YYYY")
                        : ""}
                </>
            )
        },
    },
    {
        Header: "Days",
        title: "Last Updated Days",
        accessor: "days",
        isInfoIcon: false,
        isShortIcon: true,
        className: `projects_days_box text-center`,
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
            return (
                <div className="" style={{ width: "100%" }}>
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row.original.status}
                        onChange={(e) =>
                            onChangeStatusProject(cellProps?.row?.original?.id, 2, e?.target?.value)}
                    >
                        {projectStatus?.map((list, index) => {
                            if (list?.value != 0 || list?.value != 1 || list?.value != 3) {
                                return (
                                    <option key={index} value={list.value} className="">
                                        {list.label}
                                    </option>
                                )
                            }
                        })}
                    </select>
                </div>
            )
        },
    },

    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
            const row = cellProps?.row?.original
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="View Project"
                        onClick={() => onViewPorject(row)}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                    <Link to={`${configure.appUrl}work/edit-project/${row?.id}`}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Project"
                    >
                        <i className="fas fa-pen "></i>
                    </Link>
                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                title="Add Task"
                                className="dropdown-item"
                                onClick={() => handleAdd(row?.id)}
                            >
                                <i className="bi bi-list-check pr-10"></i> Add Task
                            </a>
                            <a
                                title={row?.pause_status == 0 ? "Pause Task" : "Play Project"}
                                className="dropdown-item"
                                onClick={() => onChangeStatusProject(row?.id, 1, row?.pause_status == 0 ? 1 : 0)}
                            >
                                <i className={row?.pause_status == 0 ? "bi bi-pause-circle-fill pr-10" : "bi bi-play-circle-fill pr-10"}></i>
                                {row?.pause_status == 0 ? "Pause Project" : "Play Project"}
                            </a>

                            {/* <Link
                                title="Add Task"
                                className="dropdown-item"
                                onClick={() => handleAdd(row?.id)}
                            >
                                <i className="fas fa-cogs pr-10"></i> Move Project
                            </Link> */}
                            <a
                                title="Delete Task"
                                className="dropdown-item"
                                onClick={() => onDelete(row?.id)}
                            >
                                <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                Delete Project
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]

export const projectTaskColumns = (onEditTask, onDeleteTask, onChangeStatus) => [
    {
        Header: "Sr No.",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        className: "text-center task_id",
    },
    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        className: "task_name_box",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    {/* <img src={`${configure.imgUrl}/${cellProps?.row?.original?.avatar}`} className="img-fluid assignee-img" /> */}
                    {cellProps?.row?.original?.title}
                </div>
            )
        }
    },
    {
        Header: "Milestone",
        title: "Milestone",
        accessor: "milestone",
        isInfoIcon: false,
        className: "milestone_box text-center",
        Cell: cellProps => {
            return (
                <div>Milestone - {cellProps?.row?.original?.milestone_no}</div>
            )
        }
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        className: "status_box text-center",
        Cell: cellProps => {
            return (
                <div className="custom-select-wrapper">
                    <select
                        className={`form-select custom-select`}
                        value={cellProps.row.original.status}
                        onChange={(e) =>
                            onChangeStatus(cellProps?.row?.original?.id, e?.target?.value, cellProps?.row?.original?.project_id)}
                    >
                        {developmentTaskStatus?.map((obj, i) => {
                            return (
                                <option value={obj?.value} key={i} className="" disabled={obj?.value == 0 || obj?.value == 1} >
                                    {obj?.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "days_col",
        isInfoIcon: false,
        className: "days_box text-center",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.total_time_spent || '00:00:00'}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "task_action text-center",
        Cell: cellProps => {
            const data = cellProps.row.original
            return (
                <div className="d-flex justify-content-center">
                    {/* <a
                        className="btn btn-sm btn-outline-success me-1"
                        title="Start / Stop Timer"
                    >
                        <i className="fa fa-stopwatch"></i>
                    </a> */}

                    <a
                        onClick={() => onEditTask(data)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Task"
                    >
                        <i className="fas fa-pen "></i>
                    </a>
                    <a
                        className="btn btn-sm btn-outline-danger me-1"
                        title="Delete Task"
                        onClick={() => onDeleteTask(data?.id)}
                    >
                        <i className="fas fa-trash-alt text-danger"></i>
                    </a>
                </div>
            )
        },
    },
]

export const viewProjectTask = (onVeiwTask, onTimingLog) => [
    {
        Header: "Sr No.",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        className: "text-center task_id",
    },
    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        className: "task_name_box",
        Cell: cellProps => {
            return (
                <div className="d-flex">
                    {/* <img src={`${configure.imgUrl}/${cellProps?.row?.original?.avatar}`} className="img-fluid assignee-img" /> */}
                    {cellProps?.row?.original?.title}
                </div>
            )
        }
    },
    {
        Header: "Milestone",
        title: "Milestone",
        accessor: "milestone",
        isInfoIcon: false,
        className: "milestone_box text-center",
        Cell: cellProps => {
            return (
                <div>Milestone - {cellProps?.row?.original?.milestone_no}</div>
            )
        }
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        className: "status_box text-center",
        Cell: cellProps => {
            return (
                <div className="custom-select-wrapper">
                    {developmentTaskStatus?.find((obj) => obj?.value == cellProps?.row?.original?.status)?.label}
                </div>
            )
        },
    },
    {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "days_col",
        isInfoIcon: false,
        className: "days_box text-center",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.total_time_spent || '00:00:00'}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "task_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Task History"
                        onClick={() => onVeiwTask(cellProps?.row?.original)}
                    >
                        <i className="fas fa-eye "></i>
                    </a>
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="View Task"
                        onClick={() => onTimingLog(cellProps?.row?.original?.id)}
                    >
                        <i className="fas fa-info"></i>
                    </a>
                </div>
            )
        },
    },
]

export const milestoneColumns = (onEdit, edit, onDelete) => {
    const columns = [
        {
            Header: "ID",
            accessor: "id",
            isInfoIcon: false,
            className: "text-center",
        },
        {
            Header: "Milestone",
            accessor: "milestone",
            isInfoIcon: false,
            Cell: cellProps => {
                const data = cellProps.row?.original;
                return <div>Milestone {data?.milestone_no}</div>;
            },
        },
        {
            Header: "Hours",
            accessor: "milestone_days",
            isInfoIcon: false,
            className: "col-1 text-center",
        },
        {
            Header: "Scopes",
            accessor: "scope",
            isInfoIcon: false,
            className: "col-7",
            Cell: cellProps => {
                const scopes = cellProps?.row?.original?.scope?.split('@#');
                return (
                    <div>
                        {scopes?.map((obj, i) => {
                            return (
                                <span key={i}>
                                    {obj} {i < scopes.length - 1 ? ', ' : ''}
                                </span>
                            );
                        })}
                    </div>
                );
            },
        },
    ];

    if (edit) {
        columns.push({
            Header: "Action",
            accessor: "action",
            Cell: cellProps => {
                return (
                    <div>
                        <button
                            className="btn btn-sm btn-outline-secondary"
                            title="Edit Milestone"
                            onClick={() => onEdit(cellProps?.row?.original)}
                        >
                            <i className="fas fa-pen"></i>
                        </button>

                        <button className="btn btn-sm btn-danger ms-2" title="Delete" onClick={() => onDelete(cellProps?.row?.original?.id)}>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>
                );
            },
        });
    }

    return columns;
};
