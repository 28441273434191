import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import JsPDF, { jsPDF } from "jspdf"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Modal, Form, Canvas } from "reactstrap"

// canvas
import SignatureCanvas from "react-signature-canvas"

// images
import zithasLogo from "../../assets/images/zithas-dark-logo.png"
import sign from "../../assets/images/zithas-signature.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"
import html2pdf from "html2pdf.js"
import Swal from "sweetalert2"

const EditDocument = () => {
  const [fixedRightSec, setFixedRightSec] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [attachmentModal, setAttachmentModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")
  const [description, setDescription] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [date, setDate] = useState("")
  const [createdAt, setCreatedAt] = useState("")
  const [symbol, setCurrencySymbol] = useState("")
  const [signature, setSignature] = useState("")
  const [contact, setContact] = useState("")
  const [approve, setApprove] = useState("")
  const [docData, setDocData] = useState([])
  const [attachment, setAttachment] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const params = useParams()
  const navigate = useNavigate()
  const pdfRef = useRef()
  const signatureRef = useRef(null)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasDocumentPrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const generatePDF = async () => {
    if (signature == null || approve == 0) {
      Swal.fire({
        icon: "warning",
        text: "Cannot Download Unsigned OR Unapproved Document!",
      })
    } else {
      setLoading(true)

      // Select the content to be converted to PDF
      const content = pdfRef.current

      const opt = {
        margin: 0.38,
        filename: "Contract-Document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      }

      setTimeout(() => setLoading(false), 2000)
      html2pdf().from(content).set(opt).save()
    }
  }

  const handleClear = () => {
    signatureRef.current.clear()
  }
  const handleSave = async e => {
    e.preventDefault()
    const signatureImage = signatureRef.current.toDataURL()
    setSignature(signatureImage)
    if (signatureRef.current.isEmpty()) {
      Swal.fire({
        icon: "warning",
        text: "Please add your signature.",
      })
    } else {
      Swal.fire({
        icon: "success",
        text: "Signature added successfully.",
      }).then(() => {
        setmodal_center(false)
      })
    }
  }
  const handleAttachmentSave = async e => {
    e.preventDefault()
    if (attachment?.length > 0) {
      Swal.fire({
        icon: "success",
        text: "Signature image added successfully.",
      }).then(() => {
        setAttachmentModal(false)
      })
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please add your signature image.",
      })
    }
  }

  const handleToBase24 = e => {
    if (
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png"
    ) {
      const image_data = new FileReader()
      image_data.addEventListener("load", () => {
        setAttachment(image_data.result)
        setSignature(image_data.result)
      })
      image_data.readAsDataURL(e.target.files[0])
    } else {
      Swal.fire("", "Only JPG and PNG images can upload!", "warning")
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const staffId = localStorage.getItem("auth_id")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewApproveLetter/${staffId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)

        const filterData = response.data.data.filter(item => {
          return item.id == params.id
        })
        const templateData = filterData[0].new_template
        setDescription(templateData)
        setCreatedAt(filterData[0]?.created_at)
        setDate(filterData[0]?.created_at)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleSubmitDoc = () => {
    if (signature) {
      setLoading(true)
      const signatureDoc = new JsPDF("portrait", "pt", "a3")
      signatureDoc
        .html(document.querySelector("#zithasDocumentPrint"))
        .then(async () => {
          // contract.save("zithas.pdf")
          const pdfBlob = signatureDoc.output("blob")
          const staffId = localStorage.getItem("auth_id")
          const formData = new FormData()
          formData.append("staff_id", staffId)
          formData.append("letter_id", params.id)
          formData.append("name", name)
          formData.append("email", email)
          formData.append("signature", pdfBlob, "zithas.pdf")
          try {
            const response = await axios.post(
              `${configure.apiUrl}/signLetter`,
              formData,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            if (response.data.success == true) {
              Swal.fire({
                icon: "success",
                text: "Thanks For signining letter",
              }).then(() => {
                setName("")
                setEmail("")
                setAttachmentModal(false)
                navigate(`${configure.appUrl}profile`)
              })
            }
            setLoading(false)
          } catch (error) {
            console.log(error)
            setLoading(false)
          }
        })
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please add your signature.",
      })
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Edit Document"} />

          <Row>
            <div className="col-md-9 ps-0">
              <div
                id="zithasDocumentPrint"
                ref={pdfRef}
                className="bg-white p-3 shadow document"
              >
                <div className="pb-2 text-center">
                  <img src={zithasLogo} height="60" alt="zithasLogo" />
                </div>
                <div>
                  <div>
                    <div
                      className="Features document-content"
                      dangerouslySetInnerHTML={{
                        __html: description
                          ?.replaceAll("{", "")
                          .replaceAll("}", ""),
                      }}
                    />
                  </div>

                  <h5 className="fw-bold text-decoration-underline">
                    Date : {moment(date).format("DD-MM-YYYY")}
                  </h5>
                  <Row className="mt-4 justify-space-between">
                    <div className="col-4">
                      {signature == null ? (
                        <div className="sign-box">Signature</div>
                      ) : (
                        <img src={sign} height="50" className="mb-2" />
                      )}
                      <h5>Zithas Signature</h5>
                    </div>

                    <div className="col-4">
                      {signature != null ? (
                        <>
                          <img src={signature} height="50" className="mb-2" />
                          <h5>{contact != "undefined" && contact} Signature</h5>
                        </>
                      ) : null}
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12 pe-md-0 order-md-2 order-1 mb-md-0 mb-3">
              <div className={`${fixedRightSec ? "fixed-right-section" : ""}`}>
                {/* buttons */}
                <div className="col-12 text-md-end text-center">
                  <button
                    onClick={() => printContract()}
                    type="button"
                    className="btn btn-sm btn-success border-radius me-1"
                  >
                    <i className="fas fa-print me-1"></i> Print
                  </button>
                  <button
                    onClick={() => setmodal_center(true)}
                    type="button"
                    className="btn btn-sm btn-primary border-radius me-1"
                  >
                    <i className="fas fa-file-signature me-1"></i> Signature
                  </button>
                  <button
                    type="submit"
                    onClick={() => handleSubmitDoc()}
                    className="btn btn-sm btn-save border-radius "
                  >
                    <i className="fas fa-save me-1"></i> Submit
                  </button>
                </div>
                {/* contract data */}
                <div className="bg-white p-3 mt-4 shadow">
                  <h5 className="mb-4 text-dark">Document Details :</h5>
                  <h6 className="mb-3">
                    Created at:{" "}
                    {createdAt ? moment(createdAt).format("DD-MM-YYYY") : " -"}
                  </h6>
                  <h6>Note: {note != null ? note : " -"}</h6>
                </div>

                <div className="bg-white p-3 mt-4 shadow ">
                  <p className="text-danger">
                    * If you encounter any issues while signing the document
                    please sign on a piece of paper and scan the signature then
                    upload the scan here.
                  </p>

                  <div>
                    <label htmlFor="">Attachment</label>

                    <div>
                      <button
                        onClick={() => setAttachmentModal(true)}
                        type="button"
                        className="btn btn-sm btn-primary border-radius py-2 w-100"
                      >
                        <i className="fas fa-file-signature me-1"></i> Attach
                        Your Signature
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      {/* Signature Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Signature & Confirmation Of Identity
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleSave}>
            <Row>
              {/* <div className="col-md-5 mb-3">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="">Email Address </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  onChange={e => setEmail(e.target.value)}
                />
              </div> */}
              <div className="mb-3">
                <label htmlFor="">Signature</label>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    // width: 760,
                    // height: 300,
                    className: "sigCanvas border w-100 signatureHeight",
                  }}
                  ref={signatureRef}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <a className="btn btn-red btn-label me-2" onClick={handleClear}>
                <i className="bi bi-x-circle label-icon"></i>
                Clear Signature
              </a>
              <button className="btn btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>
                Add
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* Attachment Modal */}
      <Modal isOpen={attachmentModal} centered size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Attach Your Signature</h5>
          <button
            type="button"
            onClick={() => {
              setAttachmentModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAttachmentSave}>
            <Row>
              {/* <div className="col-md-6 mb-3">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  onChange={e => setName(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="">Email Address </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div> */}
              <div className="mb-3">
                <label htmlFor="">Signature Attachment</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleToBase24}
                />
              </div>

              {attachment && (
                <div>
                  <img src={attachment} alt="" height={"80px"} />
                </div>
              )}
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-save btn-label" type="submit">
                <i className="fas fa-save me-2 label-icon"></i>
                Add
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </React.Fragment>
  )
}

export default EditDocument
