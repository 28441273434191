import React, { useEffect, useState } from "react"
import "../../../../assets/css/Human Resource/SwipeAndIssues.css"

import { Form, Row, Col, Card, CardBody } from "reactstrap"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import chequeImg from "../../../../assets/images/cheque.jpg"
import ReportImg from "../../../../assets/images/ReportImg.jpg"
import oppImg from "../../../../assets/images/opportunity1.jpg"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import * as XLSX from "xlsx"
import moment from "moment"
import HTMLDocx from "html-docx-js/dist/html-docx"

const BankDetails = ({ stepData }) => {
  document.title = "Bank Sheet | Zithas Crm"
  const [chequeNo, setChequeNo] = useState("")
  const [addCheque, setAddCheque] = useState(false)
  const [bankLetter, setBankLetter] = useState(false)
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const fetchSalarySheet = async () => {
    const res = await fetchData(toggleLoading, "salarySheetData", token)
    if (res?.success) {
      setData(res?.data)
    }
  }

  useEffect(() => {
    if (stepData?.cheque_status == 1) {
      fetchSalarySheet()
    }
  }, [stepData])

  const handleChequeSave = async e => {
    e.preventDefault()
    const res = await fetchData(toggleLoading, `addChequeNo/${chequeNo}`, token)
    if (res?.success) {
      Swal.fire({
        icon: "success",
        title: "Cheque No. Added Successfully",
      }).then(data => {
        setChequeNo("")
        setAddCheque(false)
        fetchSalarySheet()
      })
    }
  }
  const onDownLoadBankSheet = () => {
    const workbook = XLSX.utils.book_new()
    const filterData = data?.filter(obj => obj?.pending_status == 0)
    const date = new Date()
    const modifyArr = filterData?.map((list, index) => {
      return {
        ["Bulk Transaction Type"]: "NA",
        ["External Ref No"]: index + 1,
        ["Debit Account Number"]: "257202882277",
        ["Amount"]: list.salary,
        ["Beneficiary Name"]: list.name,
        ["Beneficiary Bank Name"]: list.account_name,
        ["Beneficiary Account Number"]: list.account_no,
        ["IFSC"]: list.ifsc_code,
        ["Purpose 1"]: `Salary ${moment(date)
          .subtract(1, "months")
          .format("MMM")}-${moment(date).format("YY")}`,
        ["Purpose 2"]: `Salary ${moment(date)
          .subtract(1, "months")
          .format("MMM")}-${moment(date).format("YY")}`,
        ["Cheque Number"]: list.cheque_no,
      }
    })
    const worksheet = XLSX.utils.json_to_sheet(modifyArr)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, `ZS Sheet-${moment(date).format("MMMM-YY")}.xlsx`)
  }
  const totalSalary = data.reduce((accumulator, item) => {
    return accumulator + parseFloat(item.salary)
  }, 0)

  const downloandBankLetter = () => {
    Swal.fire({
      icon: "warning",
      title: "Confirmation Needed",
      text: "Please ensure the Bank Sheet is downloaded, as after that whole process is completed",
      showCancelButton: true,
      confirmButtonText: "Yes Complete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await fetchData(toggleLoading, "finalSalaryProcess", token)
        if (res?.success) {
          Swal.fire({
            icon: "success",
            title: "Salary Processed Successfully Completed",
          }).then(isResult => {
            const htmlContent = `
            <html>
              <body style="font-family: Calibri, sans-serif;">
                <div class="" style="text-align:right;font-weight:bold;"> 
                  Date : ${moment(new Date())?.format("DD-MM-YYYY")}
                </div>
                <div>
                  <p class="mb-0 text-black" style="margin-bottom:0px;font-weight:bold;" >To,</p>
                  <p class="mb-0  text-black" style="margin-bottom:0px;font-weight:bold;" >Branch Manager,</p>
                  <p class="mb-0  text-black" style="margin-bottom:0px;font-weight:bold;" >IndusInd Bank Ltd,</p>
                  <p class="mb-0  text-black" style="margin-bottom:0px;font-weight:bold;" >Gotri Road, Vadodara,</p>
                </div>
                <p class="text-center  text-black mt-3" style="text-align:center;margin-top:15px;font-weight:bold">
                  Subject: Request you to transfer salary as per attached sheet.
                </p>
                <p class="text-black">Dear Sir,</p>
                <p class="text-black">
                  As per the above subject, we request you to transfer the salary
                  to my employee accounts as per the attached sheet.
                </p>
                <p class="text-black">
                  And, kindly debit this amount from my current account name
                  ZITHAS TECHNOLOGIES PVT. LTD.
                </p>
                <p class="text-black">Current A\C No: 257202882277</p>
                <p class="text-black">Amount INR : ${totalSalary}/- </p>
                <p class="text-black">Cheque No: ${data[0]?.cheque_no}</p>
                <p class="text-black">Thank You,</p>
                <p class="mt-5 text-black" style="margin-top:30px;">Yours Faithfully</p>
              </body>
            </html>
          `
            const converted = HTMLDocx.asBlob(htmlContent)
            const date = new Date()
            const link = document.createElement("a")
            link.href = URL.createObjectURL(converted)
            link.download = `ZS Sheet-${moment(date).format("MMMM-YY")}.docx` // Set the name of the Word file
            link.click()
            window.location.reload()
          })
        }
      }
    })
  }

  return (
    <div>
      {isLoading && <Loader />}
      <Row>
        <Col lg={0}>
          <Card>
            <CardBody>
              <div className="justify-content-center row mb-4 mt-4">
                <Col md={5} className="">
                  <div className="">
                    <CardBody className="d-flex flex-column justify-content-between p-6">
                      <h4 className="text-center text-purple font-bold">
                        Bank Salary Sheet
                      </h4>
                      <div className="text-center">
                        <img
                          src={oppImg}
                          alt="Cheque Statement"
                          className="object-cover  mx-auto mt-2"
                          width={250}
                        />
                      </div>
                      <div className="d-flex gap-2 align-items-center mx-auto mt-4">
                        <button
                          onClick={() => {
                            if (stepData?.cheque_status == 0) {
                              setAddCheque(true)
                            } else {
                              onDownLoadBankSheet()
                            }
                          }}
                          className="btn btn-save text-white text-uppercase btn-label bg-purple "
                          disabled={stepData?.final_process == 0}
                        >
                          <i className="fas fa-download me-2 icon-size label-icon"></i>
                          Cheque Statement
                        </button>
                        {stepData?.cheque_status == 1 ? (
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => {
                              setAddCheque(true)
                              setChequeNo(data[0]?.cheque_no)
                            }}
                          >
                            <i className="fas fa-pen me-2"></i>Cheque
                          </button>
                        ) : null}
                      </div>
                      <div>
                        <p className="mt-4 text-center text-purple ">
                          {" "}
                          Cheque Number:{" "}
                          {stepData?.cheque_status == 0
                            ? "Not Added Yet"
                            : data[0]?.cheque_no}
                        </p>
                      </div>
                    </CardBody>
                  </div>
                </Col>
                <Col md={5} className="border-left">
                  <div className="">
                    <CardBody>
                      <h4 className="text-center text-purple font-bold mb-4">
                        Download Bank Letter
                      </h4>
                      <div className="text-center">
                        <img
                          src={ReportImg}
                          alt="Empty Statement"
                          className="object-cover"
                          width={250}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-save text-white btn-label bg-green mx-auto mt-4"
                          disabled={
                            stepData?.cheque_status == 0 ||
                            stepData?.final_process == 0
                          }
                          onClick={() => {
                            downloandBankLetter()
                          }}
                        >
                          <i className="fas fa-download me-2 icon-size label-icon"></i>
                          Bank Letter & Complete Process
                        </button>
                      </div>
                    </CardBody>
                  </div>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Your existing cheque modal */}
      <div className={`note-sticky-form ${addCheque ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "30%",
              right: "30%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0"> Cheque Number</h5>
            </div>
            <div className="modal-body pe-4">
              <Form
                onSubmit={handleChequeSave}
                className="row d-flex justify-content-center align-items-center"
              >
                <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
                  <div className="text-center">
                    <img
                      src={chequeImg}
                      alt="Cheque"
                      style={{ width: "90%" }}
                    />
                  </div>
                </div>

                <div className="col-md-7 d-flex flex-column ">
                  <div className="col-12 d-flex flex-column mb-3 ">
                    <label htmlFor="chequeNo">
                      Cheque No. <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={chequeNo}
                      placeholder="Enter Cheque No."
                      onChange={e => setChequeNo(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mt-4 text-end">
                    <button type="submit" className="btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setAddCheque(false)}
                      className="btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <div className={`note-sticky-form ${bankLetter ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={800}
            style={{
              position: "fixed",
              top: "30%",
              right: "30%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0"> Bank Letter</h5>
              <button
                type="button"
                className="btn-modal-close"
                onClick={() => setBankLetter(false)}
              >
                <span aria-hidden="true" className="btn-modal-span">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body pe-4 mt-3">
              <div className="text-right text-black">
                Date : {moment(new Date())?.format("DD-MM-YYY")}
              </div>
              <div>
                <p className="mb-0 text-black">To,</p>
                <p className="mb-0  text-black">Branch Manager,</p>
                <p className="mb-0  text-black">IndusInd Bank Ltd,</p>
                <p className="mb-0  text-black">Gotri Road, Vadodara,</p>
              </div>
              <p className="text-center  text-black mt-3">
                Subject: Request you to transfer salary as per attached sheet.
              </p>
              <p className="text-black">Dear Sir,</p>
              <p className="text-black">
                As per the above subject, we request you to transfer the salary
                to my employee accounts as per the attached sheet.
              </p>
              <p className="text-black">
                And, kindly debit this amount from my current account name
                ZITHAS TECHNOLOGIES PVT. LTD.
              </p>
              <p className="text-black">Current A\C No: 257202882277</p>
              <p className="text-black">Amount INR : {totalSalary}/- </p>
              <p className="text-black">Cheque No: {data[0]?.cheque_no}</p>
              <p className="text-black">Thank You,</p>

              <p className="mt-5 text-black">Yours Faithfully</p>
            </div>
          </ResizableBox>
        </Draggable>
      </div>
    </div>
  )
}

export default BankDetails
