import React from "react";
import { Container } from "reactstrap";
import { Progress } from "antd";
import { ResourceType } from "utils/common-options";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
const ProjectOverview = ({ projectDetail }) => {
  return (
    <React.Fragment>
      <div className=" mb-3">
        <Container fluid>
          <div className="row">
            <div className="col-lg-8 ps-0">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-purple fw-bold font-size-18">
                        {projectDetail?.project_name}
                      </h5>
                      <p>
                        <strong>Project Type</strong> :{" "}
                        {
                          ResourceType?.find(
                            (obj) => obj?.value == projectDetail?.project_type
                          )?.label
                        }
                        ,<strong>Customer</strong> : {projectDetail?.customer1}
                      </p>
                    </div>
                  </div>
                  <h5 className="font-size-15 mt-4">Project Description :</h5>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: projectDetail?.description,
                    }}
                  />

                  <div className="d-flex gap-4 mt-5 ">
                    <div className="text-muted  ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Project Belongs
                        </span>{" "}
                        : {projectDetail?.location}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Resource Type
                        </span>
                        :{" "}
                        {
                          ResourceType?.find(
                            (obj) => obj?.value == projectDetail?.billingtype
                          )?.label
                        }
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        <span className="text-purple fw-bold">
                          Project Industry
                        </span>{" "}
                        : {projectDetail?.industry_name}
                      </p>
                    </div>
                    <div className="info_box_1"></div>
                    <div className="text-muted ">
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Key Account Manager
                        </span>{" "}
                        : {projectDetail?.kam_name}
                      </p>
                      <p>
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                        <span className="text-purple fw-bold">
                          Scrum Master
                        </span>
                        : {projectDetail?.scrum_mastername}
                      </p>
                    </div>
                  </div>

                  <div className="task-dates row mt-4">
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar me-1 text-primary"></i>{" "}
                          Start Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.startdate).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Est. End Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.enddate).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          No. Of Days
                        </h5>
                        <p className="text-muted mb-0">45 Days</p>
                      </div>
                    </div>
                    <div className="col-3 col-sm-3 ps-0">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          <i className="bx bx-calendar-check me-1 text-primary"></i>{" "}
                          Delivery Before Date
                        </h5>
                        <p className="text-muted mb-0">
                          {moment(projectDetail?.deliverydatebefore).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {secureLocalStorage.getItem("auth_role") == 9 ||
                  secureLocalStorage.getItem("auth_role") == 10 ||
                  secureLocalStorage.getItem("auth_role") == 22 ||
                  secureLocalStorage.getItem("auth_role") == 4 ||
                  secureLocalStorage.getItem("auth_role") == 11 ||
                  secureLocalStorage.getItem("auth_role") == 1 ||
                  secureLocalStorage.getItem("auth_role") == 18 ? (
                    <>
                      <p className="mt-4 pt-3 mb-0">
                        <span className="text-purple fw-bold">
                          Payment Schedule
                        </span>
                      </p>
                      <div className="task-dates row ">
                        {projectDetail?.payment_schedule?.map((obj, i) => {
                          const dates =
                            projectDetail?.completion_dates?.split(",")[i];
                          return (
                            <div className="col-3 col-sm-3" key={i}>
                              <div className="mt-3">
                                <h5 className="font-size-14">
                                  <i className="bx bx-calendar me-1 text-primary"></i>{" "}
                                  {obj}
                                </h5>
                                <p className="text-muted mb-0">
                                  {moment(dates).format("DD MMM, YYYY")}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 pe-0">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-3">Actual Hours Details</h5>
                    </div>
                    <table className="table table-bordered mb-0  mt-3 table-hover table">
                      <thead className="">
                        <tr>
                          <th>Allocation Name</th>
                          <th className="text-center">Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-6">
                            Planning -{" "}
                            <span className="text-purple fw-bold">
                              ({projectDetail?.planning} Hrs)
                            </span>
                            <Progress
                              percent={100}
                              size="small"
                              className="mt-2"
                            />
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="col-6">
                            Designing -{" "}
                            <span className="text-purple fw-bold">
                              ({projectDetail?.designing} Hrs)
                            </span>
                            <Progress
                              percent={80}
                              size="small"
                              className="mt-2"
                            />
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="col-6">
                            Development -{" "}
                            <span className="text-purple fw-bold">
                              ({projectDetail?.development} Hrs)
                            </span>
                            <Progress
                              percent={30}
                              size="small"
                              className="mt-2"
                            />
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="col-6">
                            Testing -{" "}
                            <span className="text-purple fw-bold">
                              ({projectDetail?.testing} Hrs)
                            </span>
                            <Progress
                              percent={0}
                              size="small"
                              className="mt-2"
                            />
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="col-6">
                            Deployment -{" "}
                            <span className="text-purple fw-bold">
                              ({projectDetail?.deployment} Hrs)
                            </span>
                            <Progress
                              percent={0}
                              size="small"
                              className="mt-2"
                            />
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value="10"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-light-purple">
                          <td className="col-6">
                            <strong className="text-purple fs-5">
                              Total Hours
                            </strong>
                          </td>
                          <td className="col-2">
                            <input
                              required=""
                              type="text"
                              placeholder="Hours.."
                              className="form-control text-center "
                              name="estimatedtime"
                              value={projectDetail?.total_hrs}
                              disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProjectOverview;
