import { Progress, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { fetchData } from 'utils/common-fetchData'
import secureLocalStorage from 'react-secure-storage'
import Loader from 'pages/Separate/Loader'
import { addDaysToDate, getDaysBetweenDates, getUniqueByField } from 'utils/common-helpers'
import moment from 'moment'
import { milestoneType, ResourceType } from 'utils/common-options'
import viewImg from "../../assets/images/Inventory/viewImg.jpg"
import configure from 'configure'

const ProjectView = ({ handleClose, projectOptions, projectId, projectView, element }) => {
    const [isLoading, setLoading] = useState(false);
    const token = secureLocalStorage.getItem('auth_id')
    const [project, setProject] = useState(projectId)
    const [projectDetails, setProjectDetails] = useState({})
    const [milestiones, setMiletsones] = useState([])

    const toggleLoading = (loading) => {
        setLoading(loading);
    }
    const onChangeProject = async (e) => {
        const res = await fetchData(toggleLoading, `allProjectRelatedDataById/${e.value}`, token);
        if (res?.success) {
            setProject(e)
            setProjectDetails(res?.data)
            setMiletsones(res?.data?.milestones)
        }
    }

    useEffect(() => {
        if (projectId?.length != 0 && projectView) {
            onChangeProject(projectId)
        }
    }, [projectId, projectView])



    return (
        <div>
            {isLoading && <Loader />}
            
            <div className="d-flex gap-5 align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-purple fw-bold font-size-18">
                        {projectDetails?.project_name}
                    </h5>
                    {project?.length != 0 ? (
                        <p>
                            <strong>Project Type</strong> : {ResourceType.find((item) => item?.value == projectDetails?.billingtype)?.type} ,{" "}
                            <strong>Customer</strong> : {projectDetails?.customer}
                        </p>
                    ) : null}
                </div>
                <div className="col-md-5 me-3">
                    <Select
                        placeholder="Select Project"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={projectOptions}
                        value={project}
                        onChange={(e) => onChangeProject(e)}
                    />
                </div>
                {/* <div> */}
                    {projectDetails?.affected_time != '00:00' && projectDetails?.affected_time != undefined ? (
                        <a className="text-danger pt-2 px-2 rounded work-status cr-status bg-red text-center" style={{ width: "16%" }}><h5>{projectDetails?.affected_time} Hrs</h5><h5>Total OverRun Time</h5></a>
                    ) : null}
                {/* </div> */}
                {element != true ? (
                    <button type="button" className="btn-modal-close"><span aria-hidden="true" className="btn-modal-span" onClick={() => handleClose(false)}>
                        ×
                    </span></button>
                ) : null}

            </div>
            {project?.length != 0 ? (
                <>
                    <div className="row  mt-3">
                        {milestiones?.map((obj, i) => {
                            const assignees = getUniqueByField(obj?.assignees, 'alias_name')
                            return (
                                <div className="col-md-3 " key={i}>
                                    <div className="milestone-box bg-light-purple">
                                        <h5 className="mb-4 text-black font-size-15">
                                            Milestone {obj?.miletsone_no} - {milestoneType?.find((item) => item?.value == obj?.milestone_type)?.type}
                                        </h5>
                                        <p className="mb-2">
                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                            <span className="text-purple fw-bold">
                                                Allocated Hours
                                            </span>
                                            : {obj?.total_time_taken} Hrs
                                        </p>
                                        <p className='bg-used-hrs'>
                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                            <span className="text-purple fw-bold">
                                                Used Hours
                                            </span>
                                            : {obj?.total_time_spent} Hrs
                                        </p>
                                        <p className="d-flex align-items-center gap-2">
                                            <span className="text-purple fw-bold">
                                                <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                Member Assigned
                                            </span>
                                            :
                                            {assignees?.map((item, i) => {
                                                return (
                                                    <Tooltip
                                                        color={"#556de6"}
                                                        title={item?.alias_name}
                                                        key={i}
                                                    >
                                                        <img
                                                            src={`${configure.imgUrl}/${item?.avatar}`}
                                                            className="assignee-img"
                                                            alt="Assignee"
                                                            title=""
                                                        />
                                                    </Tooltip>
                                                )
                                            })}
                                        </p>
                                        <div className="text-center">
                                            <Progress
                                                type="dashboard"
                                                steps={10}
                                                percent={obj?.time_spent_percentage}
                                                trailColor="rgba(0, 0, 0, 0.06)"
                                                strokeWidth={20}
                                                size={120}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="mt-5">
                        <h5 className="text-black">Whole Project Overview</h5>
                        <div className="d-flex gap-4 mt-4 ">
                            <div className="text-muted  ">
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                    <span className="text-purple me-1 fw-bold">
                                        Total Allocated Hours
                                    </span>
                                    : {projectDetails?.total_estimated_time} Hrs
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-purple me-1 fw-bold">
                                        Total Used Hours
                                    </span>
                                    : {projectDetails?.total_used_time} Hrs
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-purple me-1 fw-bold">
                                        Start Date
                                    </span>{" "}
                                    : {moment(projectDetails?.startdate).format('DD MMM, YYYY')}
                                </p>
                            </div>
                            <div className="info_box_1"></div>
                            <div className="text-muted ">
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-purple me-1 fw-bold">
                                        Est. End Date
                                    </span>
                                    : {moment(projectDetails?.enddate).format('DD MMM, YYYY')}
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-purple me-1 fw-bold">
                                        No. OF Days
                                    </span>
                                    : {getDaysBetweenDates(projectDetails?.startdate, projectDetails?.enddate)} Days
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-purple me-1 fw-bold">
                                        Delivery Before Date
                                    </span>
                                    : {moment(projectDetails?.deliverydatebefore).format('DD MMM, YYYY')}
                                </p>
                            </div>
                            <div className="info_box_1"></div>
                            <div className="text-muted ">
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-danger me-1 fw-bold">
                                        Affected Total Hours
                                    </span>
                                    : {projectDetails?.affected_time} Hrs
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-danger me-1 fw-bold">
                                        Affected Est. End Date
                                    </span>
                                    : {addDaysToDate(projectDetails?.enddate, projectDetails?.days_late)}
                                </p>
                                <p>
                                    <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                    <span className="text-danger me-1 fw-bold">
                                        Affected No. OF Days
                                    </span>
                                    : {projectDetails?.days_late} Days
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='d-flex flex-column align-items-center justify-content-center p-3'>
                    <div className='col-md-4 d-flex flex-column align-items-center justify-content-center'>
                        <img src={viewImg} alt='Image' width={300} />
                        <p className='text-purple fw-bold text-center lh-base'>🚀 Your Project Overview is ready! Apply project filters to explore timelines, progress, and its details with ease. 📊✨</p>
                    </div>
                </div >
            )}
        </div >
    )
}

export default ProjectView
