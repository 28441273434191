import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { useSelector } from "react-redux"
import { Routes, Route, useLocation } from "react-router-dom"
import { layoutTypes } from "./constants/layout"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./App.css"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import Authenticate from "routes/Authenticate"
import NotFound from "pages/WrongUrl/NotFound"

import "devextreme/dist/css/dx.light.css"
import InactivityDetector from "components/InactivityDetector"
import FloatingBtn from "pages/Reports/FloatingBtn"
import { checkPermissionsByModule } from "utils/common-helpers"
import NotificationComponent from "utils/NotificationComponent"
import Help from "pages/WorkModule/Support/Help/Help"
fakeBackend()


const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))
  const Layout = getLayout(layoutType)
  const roles = useSelector(state => state?.Roles?.apiData)
  const permission = checkPermissionsByModule(roles, 96, 3)
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  {route.module_id != undefined ? (
                    <Authenticate module={route.module_id} type={route.type}>
                      <Layout>{route.component}</Layout>
                    </Authenticate>
                  ) : (
                    <Layout>{route.component}</Layout>
                  )}
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          )
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {permission ? (
        <FloatingBtn />
      ) : null}
          <Help/>
      <NotificationComponent userId={localStorage.getItem('auth_id')} />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
