import Loader from "pages/Separate/Loader";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Card, CardBody } from "reactstrap";
import { fetchData } from "utils/common-fetchData";
import SalaryImg from "../../../assets/images/TDSInvoice.jpg";

const EstimateSalary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = secureLocalStorage.getItem("auth_token");
  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  useEffect(() => {
    const getEstimatedSalary = async () => {
      const response = await fetchData(
        toggleLoading,
        `salaryComponentData`,
        token
      );
      if (response) {
        setData(response);
      }
    };
    getEstimatedSalary();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Card>
          <CardBody>
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Estimated Salary</p>
            </div>
            <div className="text-center">
              <img src={SalaryImg} alt="Salary" width={100} />
              <p className="text-center  text-purple">
                Previous Month Estimated Salary : <br /> ₹{" "}
                <span className="fw-bold">
                  {Math.floor(data?.total_final_salary).toLocaleString() || 0} -
                  {Math.floor(data?.total_max_salary).toLocaleString() || 0} /-{" "}
                </span>
                <br />
              </p>
              <p className="text-center  text-purple">
                Total working Days :{" "}
                <span className="fw-bold mt-3">{data?.working_days}</span>
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default EstimateSalary;
