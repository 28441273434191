import React, { useEffect, useState } from "react"
// import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Select from "react-select"

import sampleImg from "../../../assets/images/credential/img-5.avif"

import "../Knowledge.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import classnames from "classnames"

import Profile from "pages/Sales/Lead/EditLead/Profile"
import Notes from "pages/Sales/Lead/EditLead/Notes"
import FollowUp from "pages/Sales/Lead/EditLead/FollowUp"
import ActivityLog from "pages/Sales/Lead/EditLead/ActivityLog"


import axios from "axios"
import Loader from "pages/Separate/Loader"
import { useParams, useNavigate } from "react-router-dom"
import configure from "configure"
import moment from "moment/moment"
// components

const ViewTopic = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  // Created at 
  const [created_at, setCreatedAt] = useState("")

  //edit data
  const [data, setDatas] = useState([])
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  let url = window.location.toString()

  useEffect(() => {
    fetch2()
    fetchCreatedOn()
  }, [])

  const params = useParams()

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [assigned_data, setAssignData] = useState([])
  const [industry_option, setIndustryOption] = useState([])

  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  const fetch2 = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/all_lead_data`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLeadData(response.data.data?.lead)
        setNoteData(response.data.data?.lead_note)
        setLogData(response.data.data.lead_activity?.reverse())
        setFollowData(response.data.data?.lead_followup_data)
        setAssignData(response.data.data?.assigned)
        setIndustryOption(
          response.data.data?.industry?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Fetching created on Date and time 
  const fetchCreatedOn = async () =>{
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/lead/${params.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
          const data = response.data.data?.map(items => {
            setCreatedAt(items.created_at) })
          })
      }catch (err) {
        console.log(err)
      }
    }
  

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Add Topic  | Zithas Technologies"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const timeDifference = new Date() - new Date(created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"View Topic"} />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              {/* <Nav

                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Follow Up
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      fetch2()
                      toggleVertical("3")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav> */}
                <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white  ${
                  fixedSideTab ? "fixed-side-tab" : ""
                } h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    React JS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    JavaScript
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    HTML
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    CSS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    WordPress
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Laravel
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Dot Net
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Node JS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "9",
                    })}
                    onClick={() => {
                      toggleVertical("9")
                    }}
                  >
                    Angular JS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "10",
                    })}
                    onClick={() => {
                      toggleVertical("10")
                    }}
                  >
                    Vue JS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "11",
                    })}
                    onClick={() => {
                      toggleVertical("11")
                    }}
                  >
                    Bootstrap
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "12",
                    })}
                    onClick={() => {
                      toggleVertical("12")
                    }}
                  >
                    Tailwind
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "13",
                    })}
                    onClick={() => {
                      toggleVertical("13")
                    }}
                  >
                    Material UI
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
            {/* {isLoading && <Loader />} */}

              <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
                {/* <Col className="company-info px-2 shadow mb-3 me-0 d-flex justify-space-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center py-2 pt-3 gap-4">
                    <div className="">
                      <h5><i className="bi bi-buildings-fill "></i> <span className="border-end border-3 border-dark pe-3"> test-healthcare</span> </h5> 
                    </div>
                    <div className="">
                      <h5><i className="bi bi-telephone-fill"></i>  <span className="border-end border-3 border-dark pe-3"> 88585858585</span>  </h5>
                    </div>
                    <div>
                      <h5><i className="bi bi-envelope-fill"></i>  <span className="border-end border-3 border-dark pe-3"> rahulo@zithas.com</span> </h5>               
                    </div>
                    <div>
                      <h5><i className="bi bi-person-fill"></i>  <span className="border-end border-3 border-dark pe-3"> Alex</span> </h5>               
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2 pt-3">
                    <h5>Created On : {moment(created_at).format("DD-MM-YYYY")} (
                      {parseFloat(daysDifference)?.toFixed(0)} days)</h5>
                  </div>
                </Col> */}
                {/* <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Profile
                      editData={lead_data[0] || []}
                      industry_option={industry_option}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Notes
                      noteData={note_data || []}
                      assigned={assigned_data || []}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <ActivityLog logdata={log_data || []} />
                  </TabPane>
                  <TabPane tabId="4">
                    <FollowUp
                      followData={follow_data || []}
                      assigned={assigned_data || []}
                    />
                  </TabPane>
                </TabContent> */}

                {/* Fixed Buttons */}
                <div
                    className={`flex-column fixed-buttons pt-2 ${
                    fixButtons ? "d-flex" : "d-none"
                    }`}
                >
                    <button className="btn btn-purple fix-btn btn-label pe-0">
                    <i className="fas fa-save me-2 label-icon"></i>
                    <span>Save</span>
                    </button>
                    <a
                    onClick={() => navigate(-1)}
                    className="btn btn-red fix-btn btn-label pe-0"
                    >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    <span>Cancel</span>
                    </a>
                </div>
                <Row className="bg-white px-2 py-4 shadow gap-5 " id="viewBox">
                    <div className="topNav d-flex flex-row">
                        <div className="search-box col-md-10">
                            {/* <div className="position-relative"> */}
                                <label htmlFor="search-bar-0" className="search-label w-100">
                                <span id="search-bar-0-label" className="sr-only">
                                    Search this table
                                </span>
                                <input
                                    // onChange={e => setGlobalFilter(e.target.value)}
                                    id="search-bar-0"
                                    type="text"
                                    className="border-radius form-control rounded h-100 w-100 topic-search-input"
                                    placeholder={` Search here...`}
                                    width={100}
                                    // value={globalFilter || ""}
                                />
                                </label>
                                <i className="bx bx-search-alt topic-search-icon fa-lg text-muted"></i>
                            {/* </div> */}
                        </div>
                        <div className="col-md-2 text-end">
                            {/* <button
                                onClick={e => editLead(e)}
                                className=" btn ms-md-2 btn-save btn-label"
                            >
                                <i className="fas fa-save me-1 label-icon"></i> save
                            </button> */}
                            <a
                                onClick={e => {
                                e.preventDefault()
                                navigate(-1)
                                }}
                                className=" btn ms-md-2 btn-red btn-label"
                            >
                                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                CANCEL
                            </a>
                        </div>
                    </div>
                    {/* Image  */}
                    <div className="row d-flex flex-column gap-4">
                        <h3 className="text-start text-uppercase">How to Install React</h3>
                        <div className="col-md-12">
                            <div className="w-90 text-center">
                            <img src={sampleImg} alt="smple image" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h4>Installation</h4> 
                            <div className="blog-description px-4">

                                <p>
                                    React has been designed from the start for gradual adoption. You can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to an HTML page, or start a complex React-powered app, this section will help you get started.
                                </p>    
                                <h5>
                                Try React locally
                                </h5>
                                <p>To try React locally on your computer, download this HTML page. Open it in your editor and in your browser!</p>   
                                <h5>
                                    Start a new React project 
                                </h5>   
                                <p>If you want to build an app or a website fully with React, start a new React project.</p>
                                <h5>
                                    Add React to an existing project 
                                </h5>   
                                <p>If want to try using React in your existing app or a website, add React to an existing project.</p>
                            </div>  
                             
                            <h4>Installation</h4> 
                            <div className="blog-description px-4">

                                <p>
                                    React has been designed from the start for gradual adoption. You can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to an HTML page, or start a complex React-powered app, this section will help you get started.
                                </p>    
                                <h5>
                                Try React locally
                                </h5>
                                <p>To try React locally on your computer, download this HTML page. Open it in your editor and in your browser!</p>   
                                <h5>
                                    Start a new React project 
                                </h5>   
                                <p>If you want to build an app or a website fully with React, start a new React project.</p>
                                <h5>
                                    Add React to an existing project 
                                </h5>   
                                <p>If want to try using React in your existing app or a website, add React to an existing project.</p>
                            </div>   

                            <h4>Installation</h4> 
                            <div className="blog-description px-4">

                                <p>
                                    React has been designed from the start for gradual adoption. You can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to an HTML page, or start a complex React-powered app, this section will help you get started.
                                </p>    
                                <h5>
                                Try React locally
                                </h5>
                                <p>To try React locally on your computer, download this HTML page. Open it in your editor and in your browser!</p>   
                                <h5>
                                    Start a new React project 
                                </h5>   
                                <p>If you want to build an app or a website fully with React, start a new React project.</p>
                                <h5>
                                    Add React to an existing project 
                                </h5>   
                                <p>If want to try using React in your existing app or a website, add React to an existing project.</p>
                            </div>   
                        </div>

                        {/* <div className="col-12 edit-descrip">
                        <div className="d-flex justify-space-between">
                            <label htmlFor="">
                            Description <span className="text-danger">*</span>
                            </label>
                            <span
                            className="text-danger"
                            style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                paddingTop: "7px",
                            }}
                            >
                            </span>
                            </div>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                const data = editor.getData()
                                setDescription(data)
                                }}
                            />
                        </div> */}
                    </div>
                </Row>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewTopic
