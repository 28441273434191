import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Work/projects.css"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import FixedProjects from "./FixedProjects"
import DedicatedProject from "./DedicatedProject"
import MaintenanceProject from "./MaintenanceProject"

const ProjectIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  document.title = "Projects | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }
  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Work" breadcrumbItem="Projects" />
          <div id="credentialPage">
            <Row
              className={`gx-4 justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <div
                className={`px-0 sidebar-containerr ${fixedSideTab && "credtabFix"
                  }`}
                style={isIndex ? { zIndex: "2" } : {}}
              >
                <div className="position-relative">
                  <div>
                    <SimpleBar
                      style={{ height: "60vh" }}
                      className={`${fixedSideTab && "fix-simplebar"}`}
                    >
                      <Nav
                        pills
                        className={`${fixedSideTab && "credtabFix-ul"
                          }  flex-column px-1 py-1 h-100`}
                      >
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "1",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-center crendential": true,
                              active: verticalActiveTab == "1",
                            })}
                            onClick={() => {
                              toggleVertical("1")
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {" "}
                            FP
                            <span className="link_name border-start border-white border-2">
                              Fixed Projects
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "2",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-center crendential": true,
                              active: verticalActiveTab == "2",
                            })}
                            onClick={() => {
                              toggleVertical("2")
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {" "}
                            DR
                            <span className="link_name border-start border-white border-2">
                              Dedicated Resources
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "3",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-center crendential px-0": true,
                              active: verticalActiveTab == "3",
                            })}
                            onClick={() => {
                              toggleVertical("3")
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {" "}
                            M
                            <span className="link_name border-start border-white border-2">
                              Maintenance
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <Col
                md={"10"}
                className={`z-1 pe-0 credential-table `}
              >
                <div className="card h-100">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <FixedProjects />
                    </TabPane>
                    <TabPane tabId="2">
                      <DedicatedProject projectType={verticalActiveTab}/>
                    </TabPane>
                    <TabPane tabId="3">
                      <MaintenanceProject   projectType={verticalActiveTab}/>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectIndex
