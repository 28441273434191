import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody, Form, Label, Input } from "reactstrap"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import NewPagination from "components/Common/Newpagination"
import "../../../../assets/css/Work/support.css"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import { ticketsColumns } from "utils/columns/Support/projectTicketColumn"
import ReplyIndex from "./Reply/ReplyIndex"
import secureLocalStorage from "react-secure-storage"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import AddTickets from "./AddTickets"
import Swal from "sweetalert2"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"

function TableContainer({ columns, data, setData, allData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [ticket, setTicket] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)

  const params = useParams()

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.3
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState("all")
  const handleSelectAll = () => {
    setSelectToMove(!selectToMove)
  }
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  return (
    <div>
      {isLoading && <Loader />}
      <div id="support-header" className="mb-4 align-items-center row">
        <div className="pr-0 support-status-part general-status-part col-md-10">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all")
                  setData(allData)
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "not" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("not")
                  setData(allData?.filter(obj => obj?.status == 0))
                }}
              >
                <h4>{allData?.filter(obj => obj?.status == 0)?.length}</h4>
                <h4>Not Answered</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "answered" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("answered")
                  setData(allData?.filter(obj => obj?.status == 3))
                }}
              >
                <h4>{allData?.filter(obj => obj?.status == 3)?.length}</h4>
                <h4>Answered</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "hold" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("hold")
                  setData(allData?.filter(obj => obj?.status == 4))
                }}
              >
                <h4>{allData?.filter(obj => obj?.status == 4)?.length}</h4>
                <h4>On Hold</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "finish" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("finish")
                  setData(allData?.filter(obj => obj?.status == 5))
                }}
              >
                <h4>{allData?.filter(obj => obj?.status == 5)?.length}</h4>
                <h4>Closed</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "query" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("query")
                  setData(allData?.filter(obj => obj?.status == 6))
                }}
              >
                <h4>{allData?.filter(obj => obj?.status == 6)?.length}</h4>
                <h4>Queries</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={e => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 5
                ? "fix-fixed-support-header"
                : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={`col-t-${index + 1} ${classNames} ${
                        index != 0 && "customShortIcon"
                      }`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div>
                        {column.render("Header")}
                        {column.isShortIcon ? (
                          <span
                            style={{
                              display: index == 0 ? "none" : "block",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}

                        {column.isSelect ? (
                          <span
                            className="text-center"
                            onClick={() => handleSelectAll()}
                          >
                            <input type="checkbox" />
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className="fix-support-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let backgroundStyle = {}
              const priority = row.original.priority_col

              return (
                <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} ${
                        priority == "High" ? "highPriority" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      <div className={`note-sticky-form ${ticket ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "16%",
              right: "16%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Add Ticket</h5>
              <button
                type="button"
                onClick={() => {
                  setTicket(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <AddTickets projectId={params?.id} actionType={"add"} />
          </ResizableBox>
        </Draggable>
      </div>
    </div>
  )
}

const SupportIndex = () => {
  const [replyTiket, setReplyTiket] = useState(false)
  const [viewTicketHistory, setViewTicketHistory] = useState(false)
  const [editTask, setEditTask] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [replyWidth, setReplyWidth] = useState(0)
  const [noDrag, setNoDrag] = useState(false)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [projectName, setProjectName] = useState("")
  const [ticketDetails, setTicketDetails] = useState({})
  const token = secureLocalStorage.getItem("auth_token")
  const params = useParams()
  const toggleLoading = loading => {
    setLoading(loading)
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") {
      if (replyTiket) {
        setReplyTiket(false)
      }
      if (viewTicketHistory) {
        setViewTicketHistory(false)
      }
      if (editTask) {
        setEditTask(false)
      }
    }
  })

  document.title = "Support | Zithas Technologies"
  const [isLoading, setLoading] = useState(false)

  // popup upate width
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
      setReplyWidth(viewportWidth * 0.98)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const [modal_Access, setAccessDenied] = useState(false)
  const roles = useSelector(state => state?.Roles?.apiData)
  const onReply = obj => {
    setTicketDetails(obj)
    setReplyTiket(true)
  }
  const onEditTicket = obj => {
    const permission = checkPermissionsByModule(roles, 27, 4)
    if (permission) {
      setTicketDetails(obj)
      setEditTask(true)
    } else {
      setAccessDenied(true)
    }
  }
  const onChangeStatus = async (value, id) => {
    const formEntries = {
      ticket_id: id,
      user_id: localStorage.getItem("auth_id"),
      status: value,
    }
    const res = await FetchPostData(
      toggleLoading,
      "changeTicketStatus",
      token,
      formEntries
    )
    if (res?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Ticket Status Updated Successfully",
      }).then(data => {
        setData(res?.data?.data?.tickets)
        setAllData(res?.data?.data?.tickets)
      })
    }
  }

  const onDelete = id => {
    const permission = checkPermissionsByModule(roles, 27, 5)
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this Ticket!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then(async result => {
        if (result.isConfirmed) {
          const formEntries = {
            ticket_id: id,
            user_id: localStorage.getItem("auth_id"),
            project_id: params?.id,
          }
          const response = await FetchPostData(
            toggleLoading,
            `deleteSupportTicket`,
            token,
            formEntries
          )
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Ticket Deleted Successfully",
            })
            setData(response?.data?.data?.tickets)
            setData(response?.data?.data?.tickets)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const columns1 = useMemo(
    () => ticketsColumns(onReply, onEditTicket, onChangeStatus, onDelete),
    []
  )
  useEffect(() => {
    const fetchProjects = async () => {
      const userId = localStorage.getItem("auth_id")
      const res = await fetchData(
        toggleLoading,
        `ticketsData/${userId}/${params?.id}`,
        token
      )
      if (res?.success) {
        setProjectName(res?.data?.project_name)
        setData(res?.data?.tickets)
        setAllData(res?.data?.tickets)
      }
    }
    fetchProjects()
  }, [])
  const updateData = data => {
    setData(data)
    setAllData(data)
  }
  const closeModal = () => {
    setEditTask(false)
  }
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={projectName} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns1}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setData={setData}
                      allData={allData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Edit Ticket  */}
      <div className={`note-sticky-form ${editTask ? "d-block" : "d-none"}`}>
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "16%",
              right: "16%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Edit Ticket</h5>
              <button
                type="button"
                onClick={() => {
                  setEditTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <AddTickets
              projectId={params?.id}
              actionType={"edit"}
              ticketDetails={ticketDetails}
              updateData={updateData}
              closeModal={closeModal}
            />
          </ResizableBox>
        </Draggable>
      </div>

      {/* Reply Ticket  */}
      <div className={`note-sticky-form ${replyTiket ? "d-block" : "d-none"}`}>
        <Draggable
          handle={`${noDrag ? ".modal-header" : ""}`}
          cancel={`${noDrag ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={replyWidth}
            style={{
              position: "fixed",
              top: "5%",
              right: "0.5%",
              zIndex: "1055",
              backgroundColor: "#fff",
              maxHeight: "fit-content",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="modal-header pe-4 mb-3">
              <h5 className="modal-title mt-0">Reply Ticket </h5>
              <span
                aria-hidden="true"
                className="btn-modal-span cursor-pointer"
                onClick={() => setReplyTiket(false)}
              >
                &times;
              </span>
            </div>

            <div className="modal-body pe-4 ">
              <ReplyIndex
                ticketDetails={ticketDetails}
                replyTiket={replyTiket}
              />
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default SupportIndex
