// TimerContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import swal from 'sweetalert';

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [savedElapsed, setSavedElapsed] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  // On mount, load timer data from localStorage
  useEffect(() => {
    console.log('called', seconds);
    const dataStr = localStorage.getItem('timer_data');
    if (dataStr) {
      const data = JSON.parse(dataStr);
      setCurrentTaskId(data.taskId);
      setSavedElapsed(data.savedElapsed || 0);
      if (data.startTime) {
        setStartTime(new Date(data.startTime));
        setIsRunning(true);
      } else {
        setIsRunning(false);
      }
    }
  }, []);

  // Update seconds every second when timer is running
  useEffect(() => {
    let interval;
    if (isRunning && startTime) {
      interval = setInterval(() => {
        setSeconds(prev => savedElapsed + Math.floor((new Date() - startTime) / 1000));
      }, 1000);
    } else {
      setSeconds(savedElapsed);
    }
    return () => clearInterval(interval);
  }, [isRunning, startTime, savedElapsed]);

  const handleStartStop = async (taskId) => {
    const now = new Date();
    setIsRunning(prev => !prev);
    if (isRunning) {
      if (currentTaskId != taskId) {
        const willSwitch = await swal({
          title: "Timer already running",
          text: "A timer is already running for another task. Do you want to stop it and start the timer for this task?",
          icon: "warning",
          buttons: ["Cancel", "Yes"],
          dangerMode: true,
        });
        if (willSwitch) {
          // Stop current timer for previous task and accumulate elapsed time
          setSavedElapsed(prevElapsed => {
            const runElapsed = Math.floor((now - startTime) / 1000);
            return prevElapsed + runElapsed;
          });
          // Save the accumulated value before switching tasks
          const newSaved = savedElapsed + Math.floor((now - startTime) / 1000);
          localStorage.setItem(
            'timer_data',
            JSON.stringify({ taskId: currentTaskId, startTime: null, savedElapsed: newSaved })
          );
          setCurrentTaskId(taskId);
          setSavedElapsed(0);
          setStartTime(now);
          localStorage.setItem(
            'timer_data',
            JSON.stringify({ taskId, startTime: now.toISOString(), savedElapsed: 0 })
          );
        }
        return;
      }
      setSavedElapsed(prevElapsed => {
        const runElapsed = Math.floor((now - startTime) / 1000);
        return prevElapsed + runElapsed;
      });
      const updatedElapsed = savedElapsed + Math.floor((now - startTime) / 1000);
      setStartTime(null);
      localStorage.setItem(
        'timer_data',
        JSON.stringify({ taskId, startTime: null, savedElapsed: updatedElapsed })
      );
    } else {
      if (currentTaskId === taskId) {
        setStartTime(now);
        localStorage.setItem(
          'timer_data',
          JSON.stringify({ taskId, startTime: now.toISOString(), savedElapsed })
        );
      } else {
        setCurrentTaskId(taskId);
        setSavedElapsed(0);
        setStartTime(now);
        localStorage.setItem(
          'timer_data',
          JSON.stringify({ taskId, startTime: now.toISOString(), savedElapsed: 0 })
        );
      }
    }
  };

  // Format seconds into HH:MM:SS
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}:${String(remainingSeconds).padStart(2, '0')}`;

  return (
    <TimerContext.Provider
      value={{ formattedTime, handleStartStop, isRunning, currentTaskId }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerContext;
