import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"

import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import NewPagination from "components/Common/Newpagination"
import "../../../../assets/css/Work/support.css"
import { TestCasesColumns } from "utils/columns/Support/projectTicketColumn"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"

function TableContainer({ columns, data, setAccessDenied }) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.3
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])
  const navigate = useNavigate()
  const [status, setStatus] = useState("all")

  const handleSelectAll = () => {
    setSelectToMove(!selectToMove)
  }

  return (
    <div>
      <div id="support-header" className="mb-4 align-items-center row">
        <div className="pr-0 support-status-part general-status-part col-md-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.answered, 0)}</h4>
                <h4>Not Answered</h4>
              </a>
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "not" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("not")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.open, 0)}</h4>
                <h4>Open</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "in-progress" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("in-progress")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.inprogress, 0)}</h4>
                <h4>In Progress</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "hold" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("hold")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.answered, 0)}</h4>
                <h4>Answered</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "cancel" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("cancel")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.onhold, 0)}</h4>
                <h4>On Hold</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "finish" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("finish")
                }}
              >
                <h4>{data.reduce((sum, item) => sum + item.closed, 0)}</h4>
                <h4>Closed</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pl-0 support-action-part col-md-3">
          <div className="text-md-end">
            <button
              className="s btn btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={e => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={`col-t-${index + 1} ${classNames} ${
                        index != 0 && "customShortIcon"
                      }`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div>
                        {column.render("Header")}
                        {column.isShortIcon ? (
                          <span
                            style={{
                              display: index == 0 ? "none" : "block",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}

                        {column.isSelect ? (
                          <span
                            className="text-center"
                            onClick={() => handleSelectAll()}
                          >
                            <input type="checkbox" />
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-support-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const { did_col: did, pwd_col: pwd } = row.original
              const status = row.original.status_name

              let backgroundStyle = {}

              // if (did <= 0 && pwd <= 0) {
              //   backgroundStyle = {
              //     background:
              //       "linear-gradient(90deg, rgba(190,49,8,1) 100%, rgba(242,222,9,1) 0%)",
              //   }
              // } else if (did <= 0 && pwd === 0) {
              //   backgroundStyle = {
              //     background:
              //       "linear-gradient(90deg, rgba(190,49,8,1) 75%, rgba(242,222,9,1) 25%)",
              //   }
              // } else if (did === 0 && pwd >= 0) {
              //   backgroundStyle = {
              //     background:
              //       "linear-gradient(90deg, rgba(190,49,8,1) 50%, rgba(242,222,9,1) 50%)",
              //   }
              // } else if (did >= 0 && pwd >= 0) {
              //   backgroundStyle = {
              //     background:
              //       "linear-gradient(90deg, rgba(190,49,8,1) 25%, rgba(242,222,9,1) 75%)",
              //   }
              // } else {
              //   backgroundStyle = {}
              // }
              const priority = row.original.priority_col

              return (
                <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} ${
                        priority == "High" ? "highPriority" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const TestCaseIndex = () => {
  document.title = "Support | Zithas Technologies"
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const columns1 = useMemo(() => TestCasesColumns(), [])
  const [data, setData] = useState([])
  const token = secureLocalStorage.getItem("auth_token")
  const toggleLoading = loading => {
    setLoading(loading)
  }


  useEffect(() => {
    const fetchProjects = async () => {
      const userId = localStorage.getItem("auth_id")
      const res = await fetchData(toggleLoading, `supportData/${userId}`, token)
      if (res?.success) {
        setData(res?.data)
      }
    }
    fetchProjects()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Test Cases"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns1}
                      data={data}
                      setAccessDenied={setAccessDenied}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default TestCaseIndex
