import React, { useEffect, useState } from "react"
import {
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import Select from "react-select"
import addexpense from "../../../../assets/images/Expenses/addLoan.jpg"

import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import NewPagination from "components/Common/Newpagination"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"
import { OverdraftOptions } from "utils/common-options"
import Overview from "./Overview"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { overDraftEntries } from "utils/columns/Finance/financeColumns"
import * as XLSX from "xlsx"
import moment from "moment"

function TableContainer({ columns, datas, countryOption, overDraftOptin, toggleLoading, token, interestRate  }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [formPopup, setFormPopup] = useState(false)
  const [addLoan, setAddLoan] = useState(false)
  const [location, setLocation] = useState([])
  const [type, setType] = useState(0)
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState('')
  const [boxWidth, setBoxWidth] = useState(0)
  const [selectedOverdraft, setSelectedOverdraft] = useState(null)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [mainOverDraft, setMainOverDraft] = useState([])

  useEffect(() => {
    setData(datas)
    setAllData(datas)
    setMainOverDraft(overDraftOptin)
  }, [datas, overDraftOptin])


  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setAddLoan(false), setFormPopup(false), setPaidLoan(false)
  })

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 300) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.9)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const onAddOverDraft = async (e) => {
    e.preventDefault();
    if (location?.length != 0 && selectedOverdraft != null && selectedOverdraft?.length != 0) {
      const formEntries = {
        type: type,
        location: location.value,
        overdraft: selectedOverdraft?.value,
        date: date,
        amount: amount,
      }
      const res = await FetchPostData(toggleLoading, 'addOverdraftData', token, formEntries);
      if (res?.data?.success) {
        Swal.fire({
          icon: 'success',
          text: 'Overdraft Added Successfully!'
        }).then(() => {
          setLocation([])
          setType(0);
          setSelectedOverdraft(null)
          setDate('')
          setAmount('')
          setData(res?.data?.data?.overdraft)
          setAllData(res?.data?.data?.overdraft)
          setAddLoan(false)
          setMainOverDraft(res?.data?.data?.banks?.map((obj) => ({
            value: obj.id,
            label: obj.bank_name,
            ...obj
          })))
        })
      } else {
        Swal.fire({
          icon: 'warning',
          text: res?.data?.message
        })
      }
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please fill all required fields!'
      })
    }
  }

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [overDraft, setOverDraft] = useState([]);
  const [mainType, setMainType] = useState([]);

  const filteredOptions = allData.filter((obj) => {
    const isWithinDateRange =
      (!fromDate || new Date(obj.date) >= new Date(fromDate)) &&
      (!toDate || new Date(obj.date) <= new Date(toDate));
    const isMatchingOverdraftId = overDraft.length == 0 || obj.overdraft == overDraft?.value;
    const isMatchingType = mainType.length == 0 || obj.type == parseInt(mainType?.value);
    return isWithinDateRange && isMatchingOverdraftId && isMatchingType;
  });



  const onFilterData = (e) => {
    e.preventDefault()
    setData(filteredOptions)
  }
  const TypeOption = [
    {
      value: 0,
      label: "Withdraw"
    },
    {
      value: 1,
      label: "Return"
    },
  ]

  const sumFields = (arr, field) => {
    const sum = arr.reduce((accumulator, currentValue) => {
      if (currentValue[field] !== undefined && !isNaN(Number(currentValue[field]))) {
        return accumulator + Number(currentValue[field]);
      }
      return accumulator;
    }, 0);
    const formattedSum = sum.toFixed(2);
    return parseFloat(formattedSum).toLocaleString();
  };



  const handleExport = () => {
    const workbook = XLSX.utils.book_new()

    const modifyArr = data?.map((list, index) => {
      return {
        ['Sr No.']: index + 1,
        Date: moment(list.date).format("DD-MM-YYYY"),
        ['Bank Name']: list.bank_name,
        ['OD Number']: list.account_no,
        Type: list.type == 0 ? "Withdraw" : "Return",
        Amount: list.amount,
        ['Rate (%)']: list.interest_rate

      }
    })
    const worksheet = XLSX.utils.json_to_sheet(modifyArr)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    const date = new Date();
    XLSX.writeFile(workbook, `Overdraft-${moment(date).format('DD-MM-YYYY')}.xlsx`)
  }


  return (
    <div>
      <div
        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <Link
          onClick={() => setAddLoan(true)}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Overdraft</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <div className="d-flex col-md-9 justify-flex-start align-items-center gap-2 text-purple">
              <div
                className={`col-md-2 budget_status  pt-2 px-2 me-5 rounded proposal-status text-center total-expesne`}
              >
                <h6 className="mb-3">Total Overdraft</h6>
                <h3>₹ {sumFields(mainOverDraft, 'credit_limit')}</h3>
              </div>
              <div
                className={`col-md-2 budget_status   pt-2 px-1 rounded proposal-status text-center`}
              >
                <h6 className="mb-3">Used Amount</h6>
                <h3 className={`${parseFloat(sumFields(mainOverDraft, 'credit_limit').replace(/,/g, '')) * 0.5 < parseFloat(sumFields(mainOverDraft, 'used_limit').replace(/,/g, '')) ? "text-danger" : ''}`}> ₹ {sumFields(mainOverDraft, 'used_limit')}</h3>
              </div>
              <div
                className={`col-md-2 budget_status  pt-2 px-1 rounded proposal-status text-center`}
              >
                <h6 className="mb-3">Balance Amount</h6>
                <h3> ₹ {sumFields(mainOverDraft, 'bal_limit')}</h3>
              </div>
              <div
                className={`col-md-2 budget_status  pt-2 px-2 rounded proposal-status text-center total-credit-card`}
              >
                <h6 className="mb-3">Daily Interest Amount</h6>
                <h3 className={`${interestRate > 500 ? "text-danger" : ""}`}>₹ {interestRate}</h3>
              </div>
            </div>
            <div className="col-md-3 text-end">
              <div className="">
                <button
                  className=" btn btn-save btn-label"
                  onClick={() => setAddLoan(true)}
                >
                  <i className="fas fa-plus me-2 label-icon"></i>
                  Overdraft
                </button>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col lg={6} className="">
          <Overview overDraft={mainOverDraft} toggleLoading={toggleLoading} token={token} />
        </Col>
        <Col lg={6} className="">
          <Card>
            <CardBody>
              <Row className="m-0  justify-space-between align-items-center">
                <div className="col-3 text-purple fs-5  fw-bold">OverDraft Entries</div>
                <div className="d-flex gap-2 col-md-8 align-items-center justify-content-end">
                  <div className="p-0 col-md-5 d-flex gap-2 align-items-center">
                    <button className="btn btn-sm btn-outline-success" onClick={() => handleExport()}>
                      <i className="fas fa-download text-success"></i>
                    </button>
                    <button className="btn btn-sm border-purple" onClick={() => setFormPopup(true)}>
                      <i className="fas fa-filter text-purple"></i>
                    </button>
                    <select
                      className=" form-select "
                      style={{ width: "100px" }}
                      value={pageSize}
                      onChange={e => setPageSize(e.target.value)}
                    >
                      {[10, 25, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="p-0 col-md-6">
                    <div className="search-box my-xxl-0 d-inline-block ">
                      <div className="position-relative  ">
                        <label htmlFor="search-bar-0" className="search-label mb-0">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            onChange={e => setGlobalFilter(e.target.value)}
                            id="search-bar-0"
                            type="text"
                            className=" form-control rounded h-100 "
                            placeholder={` Search...`}
                            value={globalFilter || ""}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <div className="position-relative">
                <Table className="table table-bordered mt-4" {...getTableProps()}>
                  <thead
                    className={`${fixedContent && page.length >= 10 ? "fix-header" : ""
                      } fix-introducer-header`}
                  >
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th
                            key={index}
                            className={`t-col-${index + 1} ${column.className}`}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            <div className="d-flex align-items-center">
                              <span className="flex-grow-1 text-center header-text">
                                {column.render("Header")}
                              </span>
                              {column.isShortIcon && (
                                <span
                                  onClick={() =>
                                    column.getSortByToggleProps().onClick()
                                  }
                                  className="d-flex align-items-center ms-2"
                                >
                                  <i
                                    title="Ascending"
                                    className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                      }`}
                                  ></i>
                                  <i
                                    title="Descending"
                                    className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                      }`}
                                  ></i>
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="fix-introducer-header" {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr className="row-hover" {...row.getRowProps()} key={i}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                key={index}
                                className={`t-col-${index + 1} ${cell.column.className
                                  }`}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                    {page.length == 0 && (
                      <tr className="text-center">
                        <td colSpan={8}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Row className="justify-content-md-end justify-content-center align-items-center">
                <NewPagination
                  gotoPage={gotoPage}
                  canPreviousPag={canPreviousPage}
                  canNextPage={canNextPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  page={page}
                  data={data}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {formPopup ? (
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "30%",
              right: "3%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <Form className="row d-flex justify-content-center align-items-center" onSubmit={onFilterData}>
              <div className="col-md-12 d-flex ">
                <div className="col-md-2 ps-2">
                  <Label htmlFor="formrow-firstname-Input">From Date</Label>
                  <Input type="date" required value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                </div>
                <div className="col-md-2 ps-2">
                  <Label htmlFor="formrow-firstname-Input">To Date</Label>
                  <Input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                </div>
                <div className="col-md-2 ps-2 mb-4">
                  <Label htmlFor="formrow-firstname-Input">OverDraft</Label>
                  <Select
                    placeholder="Select OverDraft"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={mainOverDraft}
                    value={overDraft}
                    onChange={(e) => setOverDraft(e)}
                  />
                </div>
                <div className="col-md-2 ps-2 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Type</Label>
                  <Select
                    placeholder="Select Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={TypeOption}
                    value={mainType}
                    onChange={(e) => setMainType(e)}
                  />
                </div>
                <div className="col-md-4 d-flex justify-content-end align-items-center gap-2">
                  <a
                    className="btn  btn-danger"
                    style={{
                      fontSize: "14px",
                      backgroundColor: "#fed36d",
                      borderColor: "#fed36d",
                      color: "black",
                    }}
                    onClick={() => {
                      setFromDate("");
                      setToDate("");
                      setOverDraft([]);
                      setMainType([]);
                      setData(allData)
                    }}
                  >
                    Clear Filters
                  </a>
                  <button className="btn  btn-save btn-label me-1">
                    <i className="fas fa-filter search-icon label-icon"></i>{" "}
                    Filter
                  </button>
                  <a
                    className="btn btn-red btn-label me-1"
                    onClick={() => setFormPopup(false)}
                  >
                    <i className="dripicons-cross label-icon"></i> Close
                  </a>
                </div>
              </div>
            </Form>
          </ResizableBox>
        </Draggable>
      ) : null}

      {/* Add Expense Modal */}
      <Modal isOpen={addLoan} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Overdraft</h5>
          <button
            type="button"
            onClick={() => {
              setAddLoan(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="" onSubmit={onAddOverDraft}>
            <Row className="justify-content-center align-items-center">
              <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                <div className="text-center">
                  <img src={addexpense} alt="Addepe" style={{ width: "90%" }} />
                </div>
              </div>

              <div className="col-md-6 d-flex flex-column ">
                <div>
                  <div className="col-5">
                    <Label>Select Type <span className="text-danger">*</span></Label>
                    <div className="d-flex gap-4">
                      <div>
                        <input
                          type="radio"
                          id="expense"
                          name="type"
                          value={type}
                          onChange={(e) => setType(0)}
                          required
                        />
                        <label htmlFor="expense" className="ms-2">
                          Withdrawal
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="invoice"
                          name="type"
                          value={type}
                          onChange={(e) => setType(1)}
                          required
                        />
                        <label htmlFor="invoice" className="ms-2">
                          Return
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-start align-items-end">
                  <div className="col-md-4 ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Location <span className="text-danger">*</span>
                    </Label>
                    <Select
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={countryOption}
                      value={location}
                      onChange={(e) => setLocation(e)}
                    />
                  </div>
                  <div className="col-md-6 ps-2 mt-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Select Overdraft<span className="text-danger"> *</span>
                    </Label>
                    <Select
                      placeholder="Select Overdraft"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={mainOverDraft?.filter((obj) => obj?.location == location?.value)}
                      onChange={(e) => {
                        setSelectedOverdraft(e)
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-start align-items-end flex-wrap">
                  {selectedOverdraft && (
                    <>
                      <div className="col-md-12 d-flex justify-content-start align-items-end ps-2 mt-4 gap-2">
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">Bank Name</span> : {selectedOverdraft?.bank_name}
                          </h6>
                        </div>
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">OD No.</span> : {selectedOverdraft?.account_no}
                          </h6>
                        </div>
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">Rate (%)</span> : {selectedOverdraft?.interest_rate}
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-start align-items-end ps-2 mt-2 gap-2">
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">Avl. Limit</span> : {Number(selectedOverdraft?.credit_limit)?.toLocaleString()}
                          </h6>
                        </div>
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">Used Amt.</span> : {Number(selectedOverdraft?.used_limit)?.toLocaleString()}
                          </h6>
                        </div>
                        <div className="col-4 text-muted">
                          <h6>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            <span className="text-purple fw-bold">Balance Amt.</span> : {Number(selectedOverdraft?.bal_limit)?.toLocaleString()}
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-12 d-flex justify-content-start align-items-end">
                  <div className="col-md-4  ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Date <span className="text-danger">*</span>
                    </Label>
                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input required type="date" className="form-control"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 ps-2 p-0 mt-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Total Amount <span className="text-danger"> *</span>
                    </Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <a
                onClick={() => {
                  setAddLoan(false)
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const OverDraft = () => {
  //meta title
  document.title = "Overdraft | Zithas Crm"

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [countryOption, setCountryOption] = useState([])
  const [overDraftOptin, setOverdraftOptin] = useState([])
  const token = secureLocalStorage.getItem('auth_token')
  const[interestRate , setInterestRate] = useState('')
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Overdraft!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await FetchPostData(toggleLoading, 'deleteOverdraftData', token, { overdraft_id: id })
        if (res?.data?.success) {
          Swal.fire({
            text: "Overdraft has been deleted successfully.",
            icon: "success",
          }).then(() => {
            setData(res?.data?.data?.overdraft)
          })
        }
      }
    })
  }

  // table columns
  const columns = useMemo(
    () => overDraftEntries(confirmDelete), []
  )


  const fetchOverDraft = async () => {
    const res = await fetchData(toggleLoading, 'overdraftData', token)
    if (res?.success) {
      setInterestRate(Number(res?.data?.interest_amount).toFixed(2))
      setCountryOption(res?.data?.location?.map((obj) => ({
        value: obj.id,
        label: obj.location
      })))
      setOverdraftOptin(res?.data?.banks?.map((obj) => ({
        value: obj.id,
        label: obj.bank_name,
        ...obj
      })))
      setData(res?.data?.overdraft)
    }
  }

  useEffect(() => {
    fetchOverDraft()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content" id="bankloan">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Overdraft"} />

          <Row>
            <Col lg={12} className="p-0">
              <div className="responsive-table">
                <TableContainer
                  columns={columns}
                  datas={data}
                  countryOption={countryOption}
                  overDraftOptin={overDraftOptin}
                  toggleLoading={toggleLoading}
                  token={token}
                  interestRate={interestRate}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OverDraft
