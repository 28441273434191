import React, { useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import TicketReply from "./TicketReply"
import TicketOverview from "./TicketOverview"
import TicketLog from "./TicketLog"
import TestScenario from "./TestScenario"

const ReplyIndex = ({ ticketDetails, replyTiket }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const sideBarOption = [
    {
      id: "1",
      text: "Reply",
    },
    {
      id: "4",
      text: "Test Case",
    },
    {
      id: "2",
      text: "Overview",
    },
    {
      id: "3",
      text: "Activity Log",
    },
  ]
  return (
    <div className="d-flex col-12">
      <div className={`col-1 `}>
        <div className="position-relative">
          <div>
            <Nav pills className={` flex-column px-1 py-1 h-100`}>
              {sideBarOption?.map((obj, i) => {
                return (
                  <NavItem
                    key={i}
                    className={classnames({
                      "d-flex flex-column gap-2 mb-2": true,
                      active: verticalActiveTab == obj.id,
                    })}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "text-center crendential": true,
                        active: verticalActiveTab == obj.id,
                      })}
                      onClick={() => {
                        setverticalActiveTab(obj?.id)
                      }}
                    >
                      {obj?.text}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
          </div>
        </div>
      </div>
      <div className="col-md-11">
        <div>
          <TabContent
            activeTab={verticalActiveTab}
            className="text-muted mt-4 mt-md-0"
          >
            <TabPane tabId="1">
              <TicketReply
                verticalActiveTab={verticalActiveTab}
                ticketDetails={ticketDetails}
                replyTiket={replyTiket}
              />
            </TabPane>
            <TabPane tabId="2">
              <TicketOverview
                verticalActiveTab={verticalActiveTab}
                ticket_id={ticketDetails?.id}
              />
            </TabPane>
            <TabPane tabId="3">
              <TicketLog
                verticalActiveTab={verticalActiveTab}
                ticket_id={ticketDetails?.id}
              />
            </TabPane>
            <TabPane tabId="4">
              <TestScenario
                verticalActiveTab={verticalActiveTab}
                caseId={ticketDetails?.testcase_id}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  )
}

export default ReplyIndex
