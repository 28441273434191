import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import PlanningCalendar from "./PlanningCalender"
import Breadcrumb from "components/Common/Breadcrumb"

const MonthlyPlanning = () => {
  document.title = "Future Plan | Zithas Crm"
  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumb title="Digital Marketing" breadcrumbItem="Monthly Planning" />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <PlanningCalendar />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MonthlyPlanning
