import React, { useRef, useState } from "react"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Select from "react-select"
import zithasLogo from "../../../../assets/images/zithasLogo.png"
import DemoSalary from "../../../../assets/pdf/DemoSalary.pdf"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import html2pdf from "html2pdf.js"
import NoImg from "../../../../assets/images/Payroll management/PunchDetails.jpg"

function TableContainer() {
  const [searchSlip, setSearchSlip] = useState(false)
  const navigate = useNavigate()
  const token = secureLocalStorage.getItem("auth_token")
  const [isLoading, setLoading] = useState(false)
  const [details, setDetails] = useState({})
  const [year, setYear] = useState([])
  const [month, setMonth] = useState([])
  const params = useParams()
  const toggleLoading = loading => {
    setLoading(loading)
  }

  const MonthOption = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ]
  function generateYearOptions(yearsCount) {
    const currentYear = new Date().getFullYear()
    const yearOptions = []

    for (let i = 0; i < yearsCount; i++) {
      const year = currentYear - i
      yearOptions.push({
        label: year.toString(),
        value: year.toString(),
      })
    }

    return yearOptions
  }

  const getDetails = async () => {
    if (year?.length != 0 && month?.length != 0) {
      const formEntries = {
        user_id: params?.id,
        year: year?.value,
        month: month?.value,
      }
      const res = await FetchPostData(
        toggleLoading,
        "salarySlipData",
        token,
        formEntries
      )
      if (res?.data?.success) {
        setDetails(res?.data?.data)
        setSearchSlip(true)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Select Year and Month !",
      })
    }
  }

  const pdfRef = useRef()
  const generatePDF = async () => {
    setLoading(true)
    const content = pdfRef.current

    const opt = {
      margin: 0.38,
      filename: `Salary-Slip-${details?.name}-${month?.label} - ${year?.label}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    }
    setTimeout(() => setLoading(false), 2000)
    html2pdf().from(content).set(opt).save()
  }
  return (
    <div>
      {isLoading && <Loader />}
      <Row className="mb-2">
        <Col md={12} className="mb-4">
          <h5 className="text-dark">Salary Slip</h5>
          <div className="d-flex justify-content-end align-items-center mt-4 gap-3">
            <div className="col-2">
              <label htmlFor="">Select Month</label>
              <Select
                placeholder="Select Month"
                className="basic-multi-select"
                classNamePrefix=" select"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({
                    ...base,
                    zIndex: 9999, // Ensure dropdown is on top
                  }),
                }}
                options={MonthOption}
                value={month}
                onChange={e => setMonth(e)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="">Select Year</label>
              <Select
                placeholder="Select Year"
                className="basic-multi-select"
                classNamePrefix=" select"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({
                    ...base,
                    zIndex: 9999, // Ensure dropdown is on top
                  }),
                }}
                options={generateYearOptions(5)}
                value={year}
                onChange={e => setYear(e)}
              />
            </div>
            <div className="mt-4">
              <a
                className="s btn text-white me-2 btn-save btn-label"
                onClick={() => getDetails()}
              >
                <i className="fas fa-search me-2 icon-size label-icon"></i>
                Search
              </a>
              {searchSlip && details?.salary_details?.length != 0 ? (
                <a
                  className="s btn text-white me-2 btn-save btn-label"
                  title="Download"
                  onClick={() => generatePDF()}
                >
                  <i className="fas fa-download me-2 icon-size label-icon"></i>
                  Download
                </a>
              ) : null}
              <button
                onClick={() => navigate(-1)}
                className="s btn btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="position-relative">
        {searchSlip === true ? (
          <Row>
            <Col md={12} className="p-0">
              {/* <Card> */}
                <CardBody>
                  {details?.salary_details?.length != 0 ? (
                    <div
                      id="salaryslip-export"
                      className="container my-4 border border-dark border-3 px-2"
                      ref={pdfRef}
                    >
                      <div className="row border-bottom border-dark border-2 mb-3">
                        <div className="col-12 text-center  py-2">
                          <h1 className="text-primary">
                            <img
                              src={zithasLogo}
                              alt="Company Logo"
                              height={55}
                            />
                          </h1>
                        </div>
                      </div>

                      <div className="row  mb-3">
                        <div className="col-12 text-center">
                          <strong>
                            FF-16, Kalpvrux Compex, Opp. GEB Substation, Gotri,
                            Vadodara, Gujarat 390021
                          </strong>
                        </div>
                        <input type="hidden" id="companyId" value="" />
                      </div>

                      <div className="row border-bottom border-dark border-2 mb-3 pb-2">
                        <div className="col-12 text-center">
                          <strong>Tel.:</strong> +91 7203882277
                          &nbsp;&nbsp;&nbsp;
                          <strong>E-mail :</strong> hr@zithas.com
                          &nbsp;&nbsp;&nbsp;
                          <strong>Website :</strong> www.zithas.com
                        </div>
                      </div>

                      <div className="row border-bottom border-dark border-2 mb-3">
                        <div className="col-12 text-center pb-2">
                          Pay Slip for the Period of{" "}
                          <strong>
                            {month?.label} - {year?.label}
                          </strong>
                        </div>
                      </div>

                      <div className="row border-bottom border-dark border-2 mb-0">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6 text-end">
                              <label>Employee Id</label>
                              <br />
                              <label>Department</label>
                              <br />
                              <label>Date Of Joining</label>
                              <br />
                              <label>Bank Acct/Cheque No</label>
                              <br />
                            </div>
                            <div className="col-6">
                              <label>: {details?.employee_code}</label>
                              <br />
                              <label>: {details?.department}</label>
                              <br />
                              <label>: 29/05/2024</label>
                              <br />
                              <label>
                                : {details?.salary_details?.cheque_no}
                              </label>
                              <br />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6 text-end">
                              <label>Name</label>
                              <br />
                              <label>Designation</label>
                              <br />
                              <label>Leaves</label>
                              <br />
                              <label>Days Worked</label>
                            </div>
                            <div className="col-6">
                              <label>
                                <strong>: {details?.name}</strong>
                              </label>
                              <br />
                              <label>: {details?.designation}</label>
                              <br />
                              <label>
                                : {details?.salary_details?.gross_leave}
                              </label>
                              <br />
                              <label>: {details?.salary_details?.gwd	}</label>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row border-bottom border-dark border-2 mb-0">
                        <div className="col-md-6 border-end border-dark border-2 py-0">
                          <div className="row">
                            <div className="col-6 py-2">
                              <strong>Earnings</strong>
                            </div>
                            <div className="col-6 py-2 text-center">
                              <strong>Amount</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="row">
                            <div className="col-6 py-2 ">
                              <strong>Deductions</strong>
                            </div>
                            <div className="col-6 py-2 text-center">
                              <strong>Amount</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row border-bottom mb-0">
                        <div className="col-md-6 border-end border-dark border-2">
                          <div className="row">
                            <div className="col-6 py-2">
                              <label>Basic Pay</label>
                              <br />
                              <label>Dearness Allowance</label>
                              <br />
                              <label>Medical Allowance</label>
                              <br />
                              <label>House Rent Allowance</label>
                              <br />
                              <label>Conveyance Allowance</label>
                            </div>
                            <div className="col-6 text-center py-2">
                              <label>
                                ₹ {Number(details?.salary_details?.basic_pay).toFixed(2) || 0}
                              </label>
                              <br />
                              <label>
                                ₹ {Number(details?.salary_details?.dearness_allowance).toFixed(2) || 0}
                              </label>
                              <br />
                              <label>
                                ₹ {Number(details?.salary_details?.medical_allowance).toFixed(2) || 0}
                              </label>
                              <br />
                              <label>
                                ₹ {Number(details?.salary_details?.house_rent_allowance).toFixed(2) || 0}
                              </label>
                              <br />
                              <label>
                                ₹ {Number(details?.salary_details?.conveyance_allowance).toFixed(2) || 0}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6 py-2">
                              <label>Employee State Insurance</label>

                              <br />
                              <label>Professional Tax</label>
                              <br />
                              <label> Deduction</label>
                            </div>
                            <div className="col-6 text-center py-2">
                              <label>
                                ₹{" "}
                                {
                                  details?.salary_details
                                    ?.employee_state_insurance
                                }
                              </label>

                              <br />
                              <label>
                                ₹ {details?.salary_details?.professional_tax}
                              </label>
                              <br />
                              <label>
                                ₹{" "}
                                {Number(details?.salary_details?.deduction).toFixed(2)}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row border-top border-dark border-2">
                        <div className="col-md-6 border-end border-dark py-2">
                          <strong>Total Earnings</strong>
                        </div>
                        <div className="col-md-6 text-end py-2 pe-3">
                          <strong>
                            ₹{" "}
                            {Number(details?.salary_details?.basic_pay) +
                              Number(
                                details?.salary_details?.dearness_allowance
                              ) +
                              Number(
                                details?.salary_details?.medical_allowance
                              ) +
                              Number(
                                details?.salary_details?.house_rent_allowance
                              ) +
                              +Number(
                                details?.salary_details?.conveyance_allowance
                              )}
                          </strong>
                        </div>
                      </div>

                      <div className="row border-top border-dark border-2">
                        <div className="col-md-6 border-end border-dark py-2">
                          <strong>Total Deductions</strong>
                        </div>
                        <div className="col-md-6 text-end py-2 pe-3">
                          <strong>
                            ₹ {Number(details?.salary_details?.deduction).toFixed(2) +
                                  Number(
                                    details?.salary_details?.professional_tax
                                  )}
                          </strong>
                        </div>
                      </div>

                      <div className="row border-top border-dark border-2 ">
                        <div className="col-md-6 border-end border-dark py-2">
                          <strong>Net Pay (Rounded)</strong>
                        </div>
                        <div className="col-md-6 text-end py-2 pe-3">
                          <strong>₹ {Number(details?.salary_details?.salary).toFixed(2)}</strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center p-3">
                          <strong>
                            THIS IS A COMPUTER GENERATED SLIP, HENCE SIGNATURE
                            IS NOT REQUIRED
                          </strong>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <img src={NoImg} alt="No Img" width={400} />
                      <h5 className="text-purple fw-bold mt-3">
                        Oops! Salary Slip is not Generated Yet
                      </h5>
                    </div>
                  )}
                </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={12} className="p-0"></Col>
          </Row>
        )}
      </div>
    </div>
  )
}

const GeneratedSalarySlip = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={"Salary Slip"}
          breadcrumbItem={"Generate Salary Slip"}
        />

        <Row>
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <TableContainer />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GeneratedSalarySlip
