import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, Table, Modal, Label, Form } from "reactstrap"
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
} from "react-table"
// assets
import Loader from "pages/Separate/Loader"
import NewPagination from "components/Common/Newpagination"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import { customGlobalFilter } from "utils/common-helpers"
import { earthTicketsColumns } from "utils/columns/ElementsColumns/supportColumns"
import ReplyIndex from "pages/WorkModule/Support/Tickets/Reply/ReplyIndex"
import Draggable from "react-draggable"
import { ResizableBox } from "react-resizable"

function TableContainer({ columns, data, allData, setData }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            globalFilter: customGlobalFilter,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => {
        setPageSize(10)
    }, [])
    const [status, setStatus] = useState('')

    return (
        <div>
            <Row>
                <Col xl="12">
                    <div className="card-body ">
                        <div className="d-flex mb-2 align-items-start gap-3">
                            <div className="mb-3 text-purple fs-5  fw-bold">Earth ZAI Tickets</div>
                            <a className={`d-flex gap-3 align-items-center general-box ${status == '0' ? 'bg-purple' : ''}`}
                                onClick={() => {
                                    if (status == '0') {
                                        setData(allData)
                                        setStatus('')
                                    } else {
                                        setData(allData?.filter((obj) => obj?.type == '0'))
                                        setStatus('0')
                                    }
                                }}>
                                <h5 className="text-purple mb-0">Bugs : </h5>
                                <h5 className="text-primary mb-0">{allData?.filter((obj) => obj?.type == '0')?.length}</h5>
                            </a>
                            <a className={`d-flex gap-3 align-items-center general-box ${status == '1' ? 'bg-purple' : ''}`} onClick={() => {
                                if (status == '1') {
                                    setData(allData)
                                    setStatus('')
                                } else {
                                    setStatus('1')
                                    setData(allData?.filter((obj) => obj?.type == '1'))
                                }
                            }}>
                                <h5 className="text-purple mb-0">Suggestions : </h5>
                                <h5 className="text-primary mb-0">{allData?.filter((obj) => obj?.type == '1')?.length}</h5>
                            </a>
                        </div>
                        <Row className="mb-2 justify-content-between">
                            <div className="mb-3 mb-md-0 d-flex justify-space-between align-items-center">
                                <div className="col-1 me-md-5">
                                    <select
                                        className="border-radius form-select"
                                        style={{ width: "100px" }}
                                        value={pageSize}
                                        onChange={e => setPageSize(e.target.value)}
                                    >
                                        {[10, 25, 50, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-10 d-flex justify-content-end align-items-center">
                                    <div className="col-4 cusrtom_search_box">
                                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                                            <div className="d-flex">
                                                <div className="position-relative w-100">
                                                    <label
                                                        htmlFor="search-bar-0"
                                                        className="search-label w-100 mb-0"
                                                    >
                                                        <span id="search-bar-0-label" className="sr-only">
                                                            Search this table
                                                        </span>
                                                        <input
                                                            onChange={e => setGlobalFilter(e.target.value)}
                                                            id="search-bar-0"
                                                            type="text"
                                                            className="border-radius form-control rounded h-100 w-100"
                                                            placeholder={`Search...`}
                                                            value={globalFilter || ""}
                                                        />
                                                    </label>
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="position-relative table-responsive">
                            <Table className="table table-bordered mt-4" {...getTableProps()}>
                                <thead className={`fixed_view_payment_table`}>
                                    {headerGroups.map((headerGroup, i) => (
                                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column, index) => (
                                                <th
                                                    key={index}
                                                    className={`t-col-${index + 1} ${column.className}`}
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <span className="flex-grow-1 text-center header-text">
                                                            {column.render("Header")}
                                                        </span>
                                                        {column.isShortIcon && (
                                                            <span
                                                                onClick={() =>
                                                                    column.getSortByToggleProps().onClick()
                                                                }
                                                                className="d-flex align-items-center ms-2"
                                                            >
                                                                <i
                                                                    title="Ascending"
                                                                    className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                                <i
                                                                    title="Descending"
                                                                    className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                        ? "text-dark"
                                                                        : ""
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody
                                    className="fix-introducer-header"
                                    {...getTableBodyProps()}
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        // const rowBackgroundColor =
                                        //     row?.original?.leave_type == 2
                                        //         ? "bg-light-red2"
                                        //         : "bg-light-blue2"

                                        return (
                                            <tr
                                                className={`row-hover `}
                                                {...row.getRowProps()}
                                                key={i}
                                            >
                                                {row.cells.map((cell, index) => {
                                                    return (
                                                        <td
                                                            key={index}
                                                            className={`t-col-${index + 1} ${cell.column.className
                                                                }`}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>

                        <Row className="justify-content-md-end justify-content-center align-items-center">
                            <NewPagination
                                gotoPage={gotoPage}
                                canPreviousPage={canPreviousPage}
                                previousPage={previousPage}
                                canNextPage={canNextPage}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                pageSize={pageSize}
                                page={page}
                                data={data}
                                nextPage={nextPage}
                            />
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const EarthTickets = () => {
    const [modal_Access, setAccessDenied] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const token = secureLocalStorage.getItem('auth_token')
    const [ticketDetails, setTicketDetails] = useState({})
    const [replyTiket, setReplyTiket] = useState(false)
    const [replyWidth, setReplyWidth] = useState(0)

    const toggleLoading = loading => {
        setLoading(loading)
    }

    useEffect(() => {
        const updateWidth = () => {
            const viewportWidth = window.innerWidth
            setReplyWidth(viewportWidth * 0.98)
        }
        updateWidth()
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    const getData = async () => {
        const res = await fetchData(
            toggleLoading,
            `earthTicketsData`,
            token
        )
        if (res?.success) {
            setData(res?.data?.tickets)
            setAllData(res?.data?.tickets)
        }

    }
    useEffect(() => {
        if (data?.length == 0) {
            getData()
        }
    }, [])

    const onReply = obj => {
        setTicketDetails(obj)
        setReplyTiket(true)
    }

    const column1 = useMemo(
        () => earthTicketsColumns(onReply),
        []
    )

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="">
                <div>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <div className="responsive-table">
                                    <TableContainer
                                        columns={column1}
                                        data={data}
                                        toggleLoading={toggleLoading}
                                        allData={allData}
                                        setData={setData}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* Reply Ticket  */}
            <div className={`note-sticky-form ${replyTiket ? "d-block" : "d-none"}`}>
                <Draggable>
                    <ResizableBox
                        width={replyWidth}
                        style={{
                            position: "fixed",
                            top: "5%",
                            right: "0.5%",
                            zIndex: "1055",
                            backgroundColor: "#fff",
                            maxHeight: "fit-content",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <div className="modal-header p-0 mb-3 border-0">
                            <h5 className="modal-title mt-0 text-dark">Reply Ticket </h5>
                            <span
                                aria-hidden="true"
                                className="btn-modal-span cursor-pointer"
                                onClick={() => setReplyTiket(false)}
                            >
                                &times;
                            </span>
                        </div>

                        <div className="modal-body  ps-0 overflow-hidden">
                            <ReplyIndex
                                ticketDetails={ticketDetails}
                                replyTiket={replyTiket}
                            />
                        </div>
                    </ResizableBox>
                </Draggable>
            </div>

            <AccessDeniedPopup
                modal_Access={modal_Access}
                setAccessDenied={setAccessDenied}
            />
        </React.Fragment>
    )
}

export default EarthTickets
