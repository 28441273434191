import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"
import { Container } from "reactstrap"
import { fetchData } from "utils/common-fetchData"
import { milestoneType } from "utils/common-options"
const CaseMilestones = ({ verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const token = secureLocalStorage.getItem("auth_token")
  const [milestones, setMiletsones] = useState([])
  const params = useParams()
  const toggleLoading = loading => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchMilstone = async () => {
      const res = await fetchData(
        toggleLoading,
        `projectMilestones/${params?.id}`,
        token
      )
      if (res?.success) {
        setMiletsones(res?.data)
      }
    }
    if (verticalActiveTab == 4) {
      fetchMilstone()
    }
  }, [verticalActiveTab])
  return (
    <div>
      {isLoading && <Loader />}
      <React.Fragment>
        <div className=" mb-3">
          <Container fluid>
            <div className="row">
              {milestones?.map((obj, i) => {
                const scopes = obj?.scope?.split("@#")
                return (
                  <div className="col-lg-12 ps-0" key={i}>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-purple fw-bold font-size-18">
                              Milestone {obj?.milestone_no} -{" "}
                              {
                                milestoneType?.find(
                                  mil => mil?.value == obj?.milestone_type
                                )?.type
                              }
                            </h5>
                            <p>
                              <strong>Milestone Hours</strong> :{" "}
                              {obj?.milestone_days} Hrs
                            </p>
                          </div>
                        </div>
                        <h5 className="font-size-15 mt-2">
                          Project Description :
                        </h5>
                        <p
                          className="text-muted"
                          dangerouslySetInnerHTML={{ __html: obj?.description }}
                        />
                        <h5 className="font-size-15 mt-4">Milestone Scope :</h5>
                        <div className="d-flex gap-4 mt-2 ">
                          <div className="text-muted  ">
                            {scopes?.map((scp, i) => {
                              return (
                                <p className="mb-2" key={i}>
                                  <i className="mdi mdi-chevron-right text-primary me-1"></i>{" "}
                                  <span className="text-purple ">{scp}</span>
                                </p>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default CaseMilestones
