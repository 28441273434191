import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import NewPagination from "components/Common/Newpagination"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../../assets/css/Finance/Style/PayrollManagement/payroll_management.css"
import { salarySlipColumns } from "utils/columns/Human Resource/payrollColumns"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"

function TableContainer({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(10), [])
  return (
    <div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        {index === 0 && <span></span>}
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                        </span>
                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-salary-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} `}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const SalarySlip = () => {
  //meta title
  document.title = "Salary Sheet | Zithas Crm"
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const columns = useMemo(() => salarySlipColumns(), [])
  const token = secureLocalStorage.getItem("auth_token")
  const [data, setData] = useState([])
  const toggleLoading = loading => {
    setLoading(loading)
  }
  const fetchList = async () => {
    const userId = localStorage.getItem("auth_id")
    const authRole = secureLocalStorage.getItem("auth_role")
    const res = await fetchData(
      toggleLoading,
      `salarySlipListingData/${authRole}/${userId}`,
      token
    )
    if (res?.success) {
      setData(res?.data)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Payroll management"}
            breadcrumbItem={"Salary Slip"}
          />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default SalarySlip
